<template>
  <div class="pre-signup">
    <div class="pre-signup-wrapper">
      <div class="header-wrapper">
        <div class="header">
          {{ t('choose') }}
        </div>
      </div>

      <div class="account-type-wrapper">
        <div class="account-types">
          <AccountType :text="t('business')" @typeClick="clickOnBusiness" />
        </div>

        <div class="account-types">
          <AccountType :text="t('personal')" @typeClick="$router.push('/p/signup')" />
        </div>

        <div class="account-types">
          <AccountType :text="t('family')" @typeClick="$router.push('/f/signup')" />
        </div>
      </div>
      <div class="back">
        <div @click="$router.push('/login')">
          {{ t('cancel') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AccountType from '@/components/register/AccountType.vue'
import type { Account } from '@/store'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PreSignupView',
  components: {
    AccountType,
  },
  mixins: [Utils],
  async created() {
    try {
      const location = await this.getLocation()
      this.setAccountProperties(location)
    } catch (error) {
      this.setAccountProperties('')
      console.error('geolocation', error)
    }
    await this.getLocation()
  },
  methods: {
    t(key: string) {
      return this.$t(`PreSignupView.${key}`)
    },
    clickOnBusiness() {
      this.setAccount({ accountType: 'ecommerce' })
      this.$router.push('/select-plan')
    },
    setAccountProperties(location: string) {
      this.setAccount({
        currency: this.getCurrencyByLocation(location),
        address: {
          country: location,
          city: '',
          postCode: '',
          street: '',
        },
      })
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.pre-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10vh 5%;
  height: 100vh;
  background-image: url('../../assets/backgrounds/choose-account-type-background.svg');
  background-size: cover;
  background-position: center;
  justify-content: center;
}

.pre-signup-wrapper {
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  padding: 64px 64px 48px 64px;
  box-shadow: 0 42px 76px rgba(0, 0, 0, 0.05), 0 27.2222px 44.5093px rgba(0, 0, 0, 0.037963),
    0 16.1778px 24.2074px rgba(0, 0, 0, 0.0303704), 0 8.4px 12.35px rgba(0, 0, 0, 0.025),
    0 3.42222px 6.19259px rgba(0, 0, 0, 0.0196296), 0 0.777778px 2.99074px rgba(0, 0, 0, 0.012037);
  border-radius: 8px;
}

.back {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #3b755f;
  cursor: pointer;
  margin-top: 36px;
}

.header-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
}

.header {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: #212121;
}

.account-type-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.account-types {
  flex: 0 1 0;
}

@media #{map-get($display-breakpoints, 'md')} {
  .pre-signup-wrapper {
    max-width: 100%;
  }

  .pre-signup {
    padding: 10vh 2%;
    min-height: 100vh;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .pre-signup-wrapper {
    height: 100%;
    width: 100%;
    min-height: 100vh;
    display: flex;

    padding: 40px 20px;
  }

  .account-type-wrapper {
    justify-content: flex-start;
    flex-direction: column;
  }

  .pre-signup {
    padding: 0;
    height: 100%;
  }

  .header {
    font-size: 32px;
  }

  .account-types {
    width: 100%;
    flex: 1 1 0;
  }
}
</style>
