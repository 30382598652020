import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60af2e13"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-stat-wrapper" }
const _hoisted_2 = { class: "stat-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatCard = _resolveComponent("StatCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalImpactsArray, ({ type, amount }, index) => {
          return (_openBlock(), _createBlock(_component_StatCard, {
            key: index,
            class: _normalizeClass(['stat-card', type]),
            label: _ctx.t(type),
            "model-value": _ctx.formatTotalAmounts(amount)
          }, null, 8, ["class", "label", "model-value"]))
        }), 128))
      ])
    ])
  ]))
}