<template>
  <div class="pop-up">
    <h2 class="overlay-header">
      {{ t('header') }}
    </h2>
    <div class="tooltip-text" v-html="t('sub_header')" />
    <p class="expect">
      {{ t('expect') }}
    </p>
    <div v-html="t('list_header')" />
    <ul class="list">
      <li v-html="t('list_1')" />
      <li v-html="t('list_2')" />
      <li v-html="t('list_3')" />
    </ul>
    <div v-html="t('cta_description')" class="cta_description" />
    <v-card-actions class="button-wrapper">
      <gs-button class="primary-button" type="primary" size="large" @click="logOut">
        {{ t('log_out') }}
      </gs-button>
    </v-card-actions>
  </div>
</template>

<script lang="ts">
import type { Dialog } from '@/store/dialog'
import { logOut } from '@api/index'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProjectRefactorLogoutDialog',
  components: {},
  methods: {
    t(key: string) {
      return this.$t(`ProjectRefactorLogoutDialog.${key}`)
    },
    logOut() {
      logOut()
    },
  },
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
    close: {
      type: Function as PropType<() => void>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.pop-up {
  background: var(--ui-white);
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: scroll;
}

div {
  width: 100%;
}

.overlay-header {
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 500;
}

.expect {
  width: 100%;
  font-weight: bold;
}

.list {
  width: 100%;
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.cta_description {
  margin-top: 10px;
}
</style>
