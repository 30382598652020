<template>
  <transition :name="transition">
    <div v-if="isVisible" class="stepper-content">
      <slot />
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StepperContent',
  props: {
    isVisible: {
      type: Boolean,
    },
    transition: {
      default: 'fade',
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.stepper-content {
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: fit-content;
  width: 100%;
}
</style>
