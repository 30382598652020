<template>
  <div class="reset">
    <div class="reset-wrapper">
      <v-form v-model="isFormValid" class="reset-form" autocomplete="on">
        <div class="logo-wrapper">
          <img class="logo" :src="require('@/assets/greenspark-logo.svg')" alt="greenspark-app" />
        </div>

        <div class="header">
          {{ t('forgot_password') }}
        </div>

        <div class="password-reset-input-wrapper">
          <v-text-field
            id="Email"
            v-model="user.email"
            class="input-field"
            name="Email"
            autocomplete="email"
            type="email"
            :label="t('email')"
            variant="outlined"
            flat
            color="#3B755F"
            @keyup.enter="resetPassword()"
            :rules="[rules.email, rules.required]"
          />
        </div>

        <div class="button-wrapper">
          <gs-button
            :disabled="!isFormValid"
            :full-width="true"
            size="large"
            @click.prevent="resetPassword"
          >
            {{ t('forgot_password') }}
          </gs-button>
        </div>
        <div
          v-if="passwordReset || passwordResetError"
          class="email-sent"
          :style="passwordResetError ? 'color: red' : ''"
        >
          {{ passwordResetError ? t('error') : t('email_sent') }}
        </div>

        <div
          class="login"
          @click="!fromAppMarketplace ? $router.push('/login') : $router.push('/login?app')"
        >
          {{ t('login') }}
        </div>
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
import { forgotPassword } from '@api/index'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ForgotPasswordView',
  mixins: [LoadCrispMixin, RulesMixin],
  data() {
    return {
      user: {
        email: '',
      },
      fromAppMarketplace: false,
      isFormValid: false,
    }
  },
  created() {
    this.routeCheck()
    this.toggleChatBubbleVisibility(true)
  },
  methods: {
    t(key: string) {
      return this.$t(`ForgotPasswordView.${key}`)
    },
    async resetPassword() {
      await forgotPassword(this.user)
    },
    routeCheck() {
      if (window.location.search === '?app') {
        this.fromAppMarketplace = true
      }
    },
  },
  computed: {
    passwordReset(): boolean {
      return this.$store.getters['getPasswordReset']
    },
    passwordResetError(): boolean {
      return this.$store.getters['getPasswordResetError']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.reset {
  height: 100vh;
  background-image: url('@/assets/backgrounds/reset-password-background.svg');
  background-size: cover;
  background-position: center;
}

.reset-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 95vh;
  padding: 0px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding: 40px;
  }
}

.reset-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 60px 60px;
  background: #f9f9f9;
  box-shadow: 0 42px 76px rgba(0, 0, 0, 0.05), 0 27.2222px 44.5093px rgba(0, 0, 0, 0.037963),
    0 16.1778px 24.2074px rgba(0, 0, 0, 0.0303704), 0 8.4px 12.35px rgba(0, 0, 0, 0.025),
    0 3.42222px 6.19259px rgba(0, 0, 0, 0.0196296), 0 0.777778px 2.99074px rgba(0, 0, 0, 0.012037);
  border-radius: 8px;

  @media #{map-get($display-breakpoints, 'xs')} {
    padding: 40px 24px;
    height: 100vh;
    justify-content: center;
  }
}

.logo-wrapper {
  max-width: 220px;

  @media #{map-get($display-breakpoints, 'xs')} {
    max-width: unset;
    height: 60px;
    display: flex;
    justify-content: center;

    .logo {
      height: 100%;
    }
  }
}

.logo {
  max-width: 100%;
}

.header {
  color: #212121;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 16px;

  @media #{map-get($display-breakpoints, 'xs')} {
    font-size: 30px;
    margin-top: 20px;
  }
}

.input-field {
  margin-bottom: 12px;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
}

.email-sent {
  font-size: 16px;
  color: #3b755f;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.button-wrapper {
  width: 100%;
}

.button {
  margin-top: 15px;
}

.login {
  cursor: pointer;
  margin-top: 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #3b755f;
}

.password-reset-input-wrapper {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
</style>
