import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e6ac24c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upgrade-card-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_upgrade_card = _resolveComponent("upgrade-card")!
  const _component_change_plan_popup = _resolveComponent("change-plan-popup")!
  const _component_LoadingOpaque = _resolveComponent("LoadingOpaque")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.upgradeCardItems, (item, a) => {
        return (_openBlock(), _createBlock(_component_upgrade_card, {
          key: a,
          "plan-type": item.planType,
          amount: item.amount,
          "plan-billing": item.planBilling ?? '',
          "plan-check-items": item.planCheckItems,
          "is-highlighted": item.isHighlighted,
          "path-to-navigate": _ctx.pathToNavigate,
          "onUpdate:pathToNavigate": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pathToNavigate) = $event)),
          "is-subscription-yearly": _ctx.isSubscriptionYearly,
          "is-current-subscription-yearly": _ctx.isCurrentSubscriptionYearly,
          "current-plan-type": _ctx.currentPlanType,
          "is-trial-period": _ctx.isTrialPeriod,
          "has-payment-details": _ctx.hasPaymentDetails,
          theme: item.planType === 'enterpriseBusiness' ? 'unpriced' : 'priced',
          onOpenChangePopup: _ctx.openChangePopup,
          onSetLoading: _ctx.setLoading
        }, null, 8, ["plan-type", "amount", "plan-billing", "plan-check-items", "is-highlighted", "path-to-navigate", "is-subscription-yearly", "is-current-subscription-yearly", "current-plan-type", "is-trial-period", "has-payment-details", "theme", "onOpenChangePopup", "onSetLoading"]))
      }), 128))
    ]),
    (_ctx.isChangePlanPopupOpen)
      ? (_openBlock(), _createBlock(_component_change_plan_popup, {
          key: 0,
          "current-plan-type": _ctx.currentPlanType,
          "selected-plan-type": _ctx.selectedPlan,
          "selected-plan-price": _ctx.selectedPlanPrice,
          "is-current-plan-yearly": _ctx.isCurrentSubscriptionYearly,
          "is-selected-plan-yearly": _ctx.isSubscriptionYearly,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isChangePlanPopupOpen = false))
        }, null, 8, ["current-plan-type", "selected-plan-type", "selected-plan-price", "is-current-plan-yearly", "is-selected-plan-yearly"]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingOpaque, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}