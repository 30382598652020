import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c455dd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upgrade-wrapper" }
const _hoisted_2 = { class: "upgrade-inner" }
const _hoisted_3 = { class: "upgrade-text-wrapper" }
const _hoisted_4 = { class: "upgrade-title" }
const _hoisted_5 = { class: "upgrade-text" }
const _hoisted_6 = {
  key: 0,
  class: "input-wrapper"
}
const _hoisted_7 = {
  for: "switch",
  class: "label"
}
const _hoisted_8 = {
  class: "label",
  for: "switch"
}
const _hoisted_9 = { class: "label-info" }
const _hoisted_10 = {
  key: 0,
  class: "comparison-button-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseNavigation = _resolveComponent("CloseNavigation")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_upgrade_card_list = _resolveComponent("upgrade-card-list")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_free_plan_card = _resolveComponent("free-plan-card")!
  const _component_features_table = _resolveComponent("features-table")!
  const _component_FrequentlyAskedQuestions = _resolveComponent("FrequentlyAskedQuestions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CloseNavigation),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.getAccountTypeIsBusiness ? _ctx.t('upgrade_title_business') : _ctx.t('upgrade_title_personal')), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.getAccountTypeIsBusiness ? _ctx.t('upgrade_text_business') : _ctx.t('upgrade_text_personal')), 1),
        (!_ctx.hideYearlyToggle && _ctx.getAccountTypeIsBusiness)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.t('monthly')), 1),
              _createVNode(_component_v_switch, {
                id: "switch",
                modelValue: _ctx.isSubscriptionYearly,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isSubscriptionYearly) = $event)),
                color: "#3B755F",
                inset: "",
                "hide-details": ""
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_8, [
                _createTextVNode(_toDisplayString(_ctx.t('yearly')) + " ", 1),
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('save')), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_upgrade_card_list, {
        "is-business": _ctx.getAccountTypeIsBusiness,
        "is-subscription-yearly": _ctx.isSubscriptionYearly,
        "path-to-navigate": _ctx.pathToNavigate,
        "onUpdate:pathToNavigate": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pathToNavigate) = $event)),
        onTurnOffYearlyToggle: _ctx.turnOffYearlyToggle
      }, null, 8, ["is-business", "is-subscription-yearly", "path-to-navigate", "onTurnOffYearlyToggle"]),
      (_ctx.getAccountTypeIsBusiness)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.getAccountTypeIsBusiness)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("button", {
                    class: "animated-link",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.scrollToTable && _ctx.scrollToTable(...args)))
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.t('compare')) + " ", 1),
                    _createVNode(_component_v_icon, { class: "arrow-icon" }, {
                      default: _withCtx(() => [
                        _createTextVNode(" mdi-arrow-down ")
                      ]),
                      _: 1
                    })
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.showFreePlanCard)
              ? (_openBlock(), _createBlock(_component_free_plan_card, { key: 1 }))
              : _createCommentVNode("", true),
            _createVNode(_Transition, { name: "fade" }, {
              default: _withCtx(() => [
                _createVNode(_component_features_table, {
                  id: "table",
                  class: "features-table"
                })
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_FrequentlyAskedQuestions, {
        "is-business": _ctx.getAccountTypeIsBusiness,
        class: "faq-accordion"
      }, null, 8, ["is-business"])
    ])
  ]))
}