import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImpactWalletView = _resolveComponent("ImpactWalletView")!
  const _component_BusinessHome = _resolveComponent("BusinessHome")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.getIfB2B2C && _ctx.$route.path !== '/your-impact')
      ? (_openBlock(), _createBlock(_component_ImpactWalletView, { key: 0 }))
      : (_openBlock(), _createBlock(_component_BusinessHome, { key: 1 }))
  ]))
}