<template>
  <svg width="34" height="89" viewBox="0 0 34 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.0172 88.668H3.9833C1.7851 88.668 0.000244141 87.056 0.000244141 85.0708V59.8455C0.000244141 58.6464 1.07742 57.668 2.41137 57.668H31.5891C32.9168 57.668 34.0002 58.6408 34.0002 59.8455V85.0708C34.0002 87.056 32.2154 88.668 30.0172 88.668Z"
      fill="#EDF5FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.4687 7.51172L31.013 16.0747C32.9168 17.7799 34.0002 20.2105 34.0002 22.7528V26.6158C34.0002 27.9303 32.9231 29.003 31.5891 29.003H2.41137C1.08368 29.003 0.000244141 27.9365 0.000244141 26.6158V22.7528C0.000244141 20.2105 1.08368 17.7861 2.98753 16.0747L12.5318 7.51172H21.4687Z"
      fill="#EDF5FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.1298 8.33594H11.2037C10.9054 8.33594 10.6667 8.07641 10.6667 7.752V2.25646C10.6667 1.19887 11.4543 0.335938 12.4327 0.335938H21.9008C22.8733 0.335938 23.6667 1.19239 23.6667 2.25646V7.752C23.6667 8.07641 23.4281 8.33594 23.1298 8.33594Z"
      fill="#69B7FF"
    />
    <path
      d="M31.5891 38.5586H2.41137C1.07974 38.5586 0.000244141 39.6274 0.000244141 40.9458V45.8567C0.000244141 47.1751 1.07974 48.2439 2.41137 48.2439H31.5891C32.9207 48.2439 34.0002 47.1751 34.0002 45.8567V40.9458C34.0002 39.6274 32.9207 38.5586 31.5891 38.5586Z"
      fill="#A6D4FF"
    />
    <path
      d="M31.5891 28.875H2.41137C1.07974 28.875 0.000244141 29.9438 0.000244141 31.2622V36.1731C0.000244141 37.4915 1.07974 38.5603 2.41137 38.5603H31.5891C32.9207 38.5603 34.0002 37.4915 34.0002 36.1731V31.2622C34.0002 29.9438 32.9207 28.875 31.5891 28.875Z"
      fill="#B5DBFF"
    />
    <path
      d="M31.5891 48.2461H2.41137C1.07974 48.2461 0.000244141 49.3149 0.000244141 50.6333V55.5442C0.000244141 56.8626 1.07974 57.9314 2.41137 57.9314H31.5891C32.9207 57.9314 34.0002 56.8626 34.0002 55.5442V50.6333C34.0002 49.3149 32.9207 48.2461 31.5891 48.2461Z"
      fill="#B5DBFF"
    />
  </svg>
</template>
