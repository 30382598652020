import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b8da12a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-wrapper" }
const _hoisted_2 = { class: "nav-row-primary" }
const _hoisted_3 = { class: "button-burger-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_BurgerMenu = _resolveComponent("BurgerMenu")!
  const _component_InvitePopUp = _resolveComponent("InvitePopUp")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isButtonVisible)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "refer-a-friend-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = true))
          }, _toDisplayString(_ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce'
            ? _ctx.t('refer_business')
            : _ctx.t('refer_friend')), 1))
        : _createCommentVNode("", true),
      (_ctx.isBusiness)
        ? (_openBlock(), _createBlock(_component_gs_button, {
            key: 1,
            size: "small",
            type: "icon",
            class: "notification-bell-button beamer-trigger-button",
            icon: "mdi-bell"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BurgerMenu, { items: _ctx.items }, null, 8, ["items"]),
        _createVNode(_component_v_dialog, {
          modelValue: _ctx.dialog,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dialog) = $event)),
          width: "600",
          persistent: "",
          fullscreen: _ctx.isMobile,
          scrollable: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InvitePopUp, {
              "progress-item": 1,
              "hide-back": true,
              onDialogClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialog = false))
            })
          ]),
          _: 1
        }, 8, ["modelValue", "fullscreen"])
      ])
    ])
  ]))
}