<template>
  <section class="connected-banks">
    <h2 class="connected-banks__title">
      {{ t('connect_account_title') }}
    </h2>
    <ul
      :class="[
        'connected-banks__list',
        { 'is-expanded': isExpanded },
        { 'is-account-expanded': isAccountExpanded !== null },
      ]"
    >
      <template v-for="({ id, name, accounts, logo }, index) in getBankAccounts">
        <li
          v-if="isVisible(index)"
          :id="id"
          :key="id"
          :class="['connected-banks__list-item', { 'is-expanded': isAccountExpanded === index }]"
        >
          <v-tooltip
            :open-on-click="isTouch"
            content-class="carbon-footprint-introduction__tooltip-content"
            location="top"
            max-width="480px"
          >
            <template v-slot:activator="{ props }">
              <div class="connected-banks__logo-wrapper" v-bind="props">
                <img
                  v-if="logo"
                  class="connected-banks__bank-logo"
                  :src="`data:image/png;base64,${logo}`"
                  height="40"
                  loading="lazy"
                />
                <p class="connected-banks__bank-name">
                  {{ name }}
                </p>
                <v-icon class="connected-banks__status"> mdi-check-circle </v-icon>
              </div>
            </template>
            <p class="mb-0">
              {{ name }}
            </p>
          </v-tooltip>
          <p class="connected-banks__accounts-label">
            {{ t('label') }}
          </p>
          <ul
            :class="[
              'connected-banks__accounts-list',
              { 'is-expanded': isAccountExpanded === index },
            ]"
          >
            <template v-for="(account, accountIndex) in accounts">
              <li
                v-if="isAccountVisible(index, accountIndex)"
                :id="account.id"
                :key="account.id"
                class="connected-banks__accounts-list-item"
              >
                {{ account.name }}
              </li>
            </template>
            <button
              v-if="accounts.length > 3"
              v-ripple
              :class="[
                'expand-bank-account-list-button',
                { 'is-expanded': isAccountExpanded === index },
              ]"
              @click="toggleExpandCardAccounts(index)"
            >
              <v-icon class="expand-bank-account-list-button__icon"> mdi-plus </v-icon>
            </button>
          </ul>
        </li>
      </template>
      <button
        id="add-bank-account-button"
        v-ripple
        class="add-bank-account-button"
        @click="connectBankAccount"
      >
        <v-icon class="add-bank-account-button__icon"> mdi-plus </v-icon>
      </button>
    </ul>
    <button v-if="isMoreButtonVisible" class="connected-banks__more-button" @click="toggleExpand">
      {{ t('more') }}
      <v-icon :class="['toggle-expand-icon', { 'is-expanded': isExpanded }]">
        mdi-menu-down
      </v-icon>
    </button>
  </section>
</template>

<script lang="ts">
import type { Bank } from '@/store/bank-accounts'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ConnectedBankAccountList',
  mixins: [Utils],
  data() {
    const isAccountExpanded: number | null = null

    return {
      isExpanded: false,
      isAccountExpanded,
    } as { isExpanded: boolean; isAccountExpanded: number | null }
  },
  computed: {
    isMoreButtonVisible() {
      // if mobile view max 1 bank account is shown
      if (this.isMobile) {
        return this.getBankAccounts.length > 1
      } else if (this.isTablet || this.isMobileLarge) {
        return this.getBankAccounts.length > 2
      } else {
        return this.getBankAccounts.length > 3
      }
    },
    getBankAccounts(): Bank[] {
      return this.$store.getters['getBankAccounts']
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`ConnectedBankAccountList.${key}`)
    },
    toggleExpand() {
      this.isAccountExpanded = null
      this.isExpanded = !this.isExpanded
    },
    toggleExpandCardAccounts(index: number) {
      this.isAccountExpanded = this.isAccountExpanded === index ? null : index
    },
    isVisible(index: number) {
      if (this.isExpanded) {
        return true
      } else if (this.isMobile) {
        return index < 1
      } else if (this.isTablet || this.isMobileLarge) {
        return index < 2
      } else {
        return index < 3
      }
    },
    isAccountVisible(bankIndex: number, accountIndex: number) {
      return this.isAccountExpanded === bankIndex || accountIndex < 3
    },
    connectBankAccount(): Promise<void> {
      return this.$store.dispatch('connectBankAccount')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

@container bank-account-card-container (width < 760px) {
  p.connected-banks__bank-name {
    font-size: 20px;
  }
}

@container bank-account-card-container (width > 760px) {
  p.connected-banks__bank-name {
    font-size: 24px;
  }
}

.connected-banks__title {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
}

.connected-banks {
  margin-bottom: 36px;
}

.connected-banks__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  gap: 16px;
  max-height: 274px;
  overflow: hidden;
  container-name: bank-account-card-container;
  container-type: inline-size;
  position: relative;
}

.connected-banks__list.is-expanded {
  max-height: 2000px;
  transition: all ease-in 0.3s;
}

.connected-banks__list.is-account-expanded {
  max-height: 2000px;
  transition: all ease-in 0.3s;
}

.connected-banks__list-item {
  width: 100%;
  border: 1px solid var(--ui-green);
  border-radius: 8px;
  padding: 24px;
  max-height: 206px;
  min-height: 206px;
  transition: all 0.3s;
}

.connected-banks__list-item.is-expanded {
  max-height: 2000px;
  transition: all ease-in 0.3s;
}

.connected-banks__bank-logo {
  width: auto;
  object-fit: cover;
  margin-right: 10px;
}

.connected-banks__bank-name {
  margin-bottom: 0;
  flex-shrink: 1;
  width: 165px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.connected-banks__status {
  font-size: 24px;
  color: var(--ui-green);
  margin-left: auto;
}

.connected-banks__logo-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 24px;
  height: 40px;
  cursor: pointer;
}

.connected-banks__accounts-label {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
}

.connected-banks__accounts-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 70px;
  overflow: hidden;
  position: relative;
}

.connected-banks__accounts-list.is-expanded {
  max-height: 2000px;
}

.connected-banks__accounts-list-item {
  border-radius: 25px;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 14px;
  color: var(--ui-white);
}

.connected-banks__accounts-list-item {
  background-color: var(--ui-black);
}

.connected-banks__accounts-list-item:last-of-type {
  margin-right: 40px;
}

.connected-banks__more-button {
  outline: none;
  background: transparent;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}

.add-bank-account-button {
  width: 100%;
  background-color: var(--ui-green);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
}

.add-bank-account-button:hover {
  opacity: 0.8;
}

.add-bank-account-button__icon {
  color: var(--ui-white);
  font-size: 32px;
}

.toggle-expand-icon {
  color: var(--font-color-primary);
  transition: all 0.3s ease-in-out;
}

.toggle-expand-icon.is-expanded {
  transform: rotate(180deg);
}

.expand-bank-account-list-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--ui-green);
  transition: all ease-in 0.3s;
  position: absolute;
  right: 0;
  bottom: 0;
}

.expand-bank-account-list-button:hover {
  opacity: 0.8;
}

.expand-bank-account-list-button.is-expanded {
  transform: rotate(45deg);
  transition: all ease-in 0.3s;
}

.expand-bank-account-list-button__icon {
  color: var(--ui-white);
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .connected-banks__list-item {
    width: calc(((100% - 64px) / 2) - 16px);
    flex: 0 0 calc(((100% - 64px) / 2) - 16px);
  }

  .add-bank-account-button {
    width: 64px;
    height: 206px;
  }

  .connected-banks__list {
    max-height: 206px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .connected-banks {
    background-color: var(--ui-white);
    padding: 26px;
    margin: 0 0 36px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
  }

  .connected-banks__title {
    font-size: 24px;
    line-height: 29px;
  }

  .add-bank-account-button {
    width: 64px;
    height: 206px;
  }

  .connected-banks__list-item {
    width: calc(((100% - 64px) / 3) - 16px);
    flex: 0 0 calc(((100% - 64px) / 3) - 16px);
  }

  .connected-banks__list {
    padding: 0;
  }
}
</style>
