<template>
  <li
    :class="[
      'news-feed-item',
      {
        'simple-background':
          feedItem?.type === 'WHY_WE_JOINED' || feedItem?.type === 'IMPACT_TARGET',
      },
      { highlight: feedItem?.type === 'CAMPAIGN' },
      { 'margin-offset': feedItem?.type === 'ONE_OFF' || feedItem?.byImpactType === 'plastic' },
    ]"
  >
    <div class="feed-item-title-wrapper">
      <template v-if="feedItem?.byImpactType && feedItem.byImpactType !== 'all'">
        <svg :class="['feed-item-icon', feedItem.byImpactType]">
          <use :href="'#' + feedItem.byImpactType + '-icon'" />
        </svg>
      </template>
      <template v-else>
        <GreenSparkLogo
          v-if="iconName === 'GreenSparkLogo'"
          class="feed-item-icon material-icons-outlined"
        />
        <v-icon v-else class="feed-item-icon material-icons-outlined" :icon="iconName" />
      </template>
      <h4 class="feed-item-title">
        {{ feedItem?.type === 'GOAL' ? t('did_you_know') : feedItem?.title }}
      </h4>
    </div>
    <div
      :class="[
        'content-wrapper',
        { business: feedItem?.type === 'WHY_WE_JOINED' || feedItem?.type === 'IMPACT_TARGET' },
      ]"
    >
      <p class="feed-item-text">
        {{ feedItem?.description }}
      </p>
      <template v-if="feedItem?.image">
        <img
          :src="feedItem?.image"
          alt="feed item image"
          height="100"
          width="100"
          loading="lazy"
          class="feed-item-image"
        />
      </template>
      <template v-if="feedItem?.videoURL">
        <div class="feed-video-wrapper">
          <iframe
            class="feed-video"
            :src="`${feedItem?.videoURL}?h=fb4c767926&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          />
        </div>
      </template>
    </div>
    <div class="cms-content">
      <slot name="cms-content" />
    </div>
    <template v-if="isImpactActionType">
      <button class="feed-item-link" @click.prevent="openOrderDetails">
        {{ feedItem?.link?.label }}
      </button>
    </template>
    <template v-else>
      <a v-if="feedItem?.link" :href="feedItem?.link?.url" target="_blank" class="feed-item-link">
        {{ feedItem?.link?.label }}</a
      >
    </template>
  </li>
</template>

<script lang="ts">
import type { FeedItem } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import GreenSparkLogo from '@/components/icons/GreenSparkLogo.vue'

export default defineComponent({
  name: 'NewsFeedItem',
  components: { GreenSparkLogo },
  computed: {
    isImpactActionType(): boolean {
      return (
        this.feedItem?.type === 'ORDER_CREATED' ||
        this.feedItem?.type === 'PER_COMPANY_REVIEW' ||
        this.feedItem?.type === 'PER_PRODUCT_REVIEW' ||
        this.feedItem?.type === 'PER_SUBSCRIBER' ||
        this.feedItem?.type === 'ONE_OFF' ||
        this.feedItem?.type === 'REFERRAL' ||
        this.feedItem?.type === 'SUBSCRIPTION'
      )
    },
    iconName(): string {
      if (this.feedItem?.byImpactType) {
        switch (this.feedItem.byImpactType) {
          case 'carbon':
            return 'cloud'
          case 'trees':
            return 'park'
          case 'plastic':
            return this.$vuetify.icons.aliases?.plastic
          case 'all':
            return 'info'
          default:
            return 'info'
        }
      } else {
        switch (this.feedItem?.type) {
          case 'ORDER_CREATED':
            return 'mdi-shopping-outline'
          case 'PROJECT_DESCRIPTION':
            return 'mdi-tree-outline'
          case 'PER_SUBSCRIBER':
            return 'mdi-email-check-outline'
          case 'PER_COMPANY_REVIEW':
            return 'mdi-message-draw'
          case 'PER_PRODUCT_REVIEW':
            return 'mdi-message-draw'
          case 'CAMPAIGN':
            return 'GreenSparkLogo'
          case 'COMPANY_UPDATE':
            return 'GreenSparkLogo'
          case 'ONE_OFF':
            return 'mdi-flash-outline'
          case 'SUBSCRIPTION':
            return 'mdi-tag-heart-outline'
          case 'REFERRAL':
            return 'mdi-bullhorn-variant-outline'
          case 'INVOICE':
            return 'mdi-receipt-text-outline'
          default:
            return 'mdi-lightbulb-outline'
        }
      }
    },
  },
  methods: {
    t(key: string, params?: { [k: string]: string }) {
      return this.$t(`NewsFeedItem.${key}`, params ?? {})
    },
    openOrderDetails() {
      this.$parent?.$parent?.$emit('open-order-details', this.feedItem?.link?.url)
    },
  },
  props: {
    feedItem: {
      type: Object as PropType<FeedItem>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.news-feed-item {
  background: url('../../assets/backgrounds/newsfeed-item-background.svg') no-repeat center/cover;
  border-radius: var(--border-radius-big);
  padding: 20px 30px;
  position: relative;
  width: 100%;
}

.news-feed-item.highlight {
  border: 2px solid var(--ui-green);
  padding: 18px 28px;
}

.news-feed-item.simple-background {
  background: var(--ui-beige);
}

.news-feed-item:not(:last-child) {
  margin-bottom: 30px;
}

.news-feed-item:not(:last-child)::after {
  background: var(--ui-beige);
  content: '';
  display: block;
  height: 30px;
  position: absolute;
  top: 100%;
  left: 20px;
  width: 8px;
}

.news-feed-item.highlight:not(:last-child)::after {
  top: calc(100% + 2px);
}

.feed-item-title-wrapper {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.feed-item-icon {
  color: var(--font-color-primary);
  margin-right: 5px;
  font-size: 24px;
  height: 24px;
  width: 24px;
}

.news-feed-item.margin-offset .feed-item-icon {
  margin-left: -6px;
}

.feed-item-title {
  font-size: 18px;
  line-height: 24px;
}

.feed-item-text {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  max-width: 35ch;
}

.content-wrapper.business .feed-item-text {
  order: 2;
  margin-bottom: 0;
}

.content-wrapper.business {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}

.content-wrapper.business .feed-item-image {
  width: 60px;
  height: auto;
  border-radius: 0;
  object-fit: contain;
  order: 1;
}

.feed-item-image {
  border-radius: var(--border-radius-big);
  height: auto;
  width: 100%;
}

.feed-item-link {
  color: var(--gray);
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-decoration: underline;
}

.feed-video-wrapper {
  padding: 100% 0 0 0;
  position: relative;
}

.feed-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: none;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .news-feed-item {
    padding: 20px 40px;
  }

  .news-feed-item.highlight {
    padding: 18px 38px;
  }

  .feed-item-link {
    max-width: 60%;
  }
}
</style>
