import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f4eef19"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submenu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubMenuTile = _resolveComponent("SubMenuTile")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_SubMenuTile, {
            header: _ctx.t('automatic'),
            text: _ctx.t('automatic_text'),
            "button-text": _ctx.t('manage'),
            onActionClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navigateToIntegrationList()))
          }, null, 8, ["header", "text", "button-text"]),
          _createVNode(_component_SubMenuTile, {
            header: _ctx.t('manual'),
            text: _ctx.t('manual_text'),
            "button-text": _ctx.t('manage'),
            onActionClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/add-impact/tailored-impact')))
          }, null, 8, ["header", "text", "button-text"])
        ])
      ]),
      _: 1
    })
  ]))
}