<template>
  <div class="total-wrapper">
    <div class="header">
      {{ t('header') }}
    </div>
    <div class="stats-wrapper">
      <div class="stat team-members">
        <div class="text-number-wrapper text-number-wrapper-beige">
          <div class="number">
            {{ formatNumberByLocale(totalEmployees) }}
          </div>
          <div class="text-big">
            {{ t('team') }}
          </div>
        </div>
      </div>

      <div v-for="({ type, amount }, index) in averageImpacts" :key="index" :class="['stat', type]">
        <div class="text-number-wrapper">
          <div class="number">
            {{ formatNumberByLocale(amount) }}
          </div>
          <div class="text-big">
            {{ t(type) }}
          </div>
        </div>
      </div>
    </div>
    <div class="per_member">
      {{ t('per_member') }}
    </div>
  </div>
</template>

<script lang="ts">
import type { OffsetType } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TeamTotals',
  mixins: [Utils],
  computed: {
    averageImpacts(): { type: OffsetType; amount: number }[] {
      return this.getTotalImpactForTeamMembers.map(({ type, amount }) => {
        return {
          type,
          amount: amount / this.totalEmployees || 0,
        }
      })
    },
    getTotalImpactForTeamMembers(): { type: OffsetType; amount: number }[] {
      return this.$store.getters['getTotalImpactForTeamMembers']
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`TeamTotals.${key}`)
    },
  },
  props: {
    totalEmployees: { required: true, type: Number, default: 0 },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.total-wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
}

.header {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: var(--ui-black);
  margin-bottom: 32px;
}

.stats-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.stat {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  width: 24%;
  border-radius: 8px;
  background: var(--ui-beige) center;
  background-size: cover;
}

.stat.trees {
  background-image: url('../../assets/gifs/comparisons/stat_tree_background.svg');
}

.stat.plastic {
  background-image: url('../../assets/gifs/comparisons/stat_plastic_background.svg');
}

.stat.carbon {
  background-image: url('../../assets/gifs/comparisons/stat_carbon_background.svg');
}

.stat.kelp {
  background-image: url('../../assets/gifs/comparisons/stat_kelp_background.svg');
  background-color: var(--ui-beige);
  margin-right: 0;
}

.team-members {
  background-color: var(--ui-green);
}

.text-number-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--ui-black);
}

.text-number-wrapper-beige {
  color: var(--ui-beige);
}

.number {
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
}

.text-big {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.text {
  text-align: center;
  font-size: 13px;
  color: var(--ui-black);
}

.stat-icon {
  width: 55px;
}

.per_member {
  margin-top: 16px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: var(--gray);
  text-align: end;
}

@media #{map-get($display-breakpoints, 'md')} {
  .stat {
    width: 49%;
    margin-top: 10px;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .stat {
    width: 100%;
    margin-top: 10px;
  }
}
</style>
