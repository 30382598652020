import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8fc329ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "header-bar"
}
const _hoisted_2 = { href: "https://www.getgreenspark.com/" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "page-content-outer-wrapper"
}
const _hoisted_5 = { class: "page-content-wrapper" }
const _hoisted_6 = {
  key: 0,
  class: "menu-bar"
}
const _hoisted_7 = { class: "menu-item-label" }
const _hoisted_8 = { class: "menu-item-label" }
const _hoisted_9 = { class: "menu-item-label" }
const _hoisted_10 = { class: "tabs-outer-wrapper" }
const _hoisted_11 = { class: "tabs-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingOpaque = _resolveComponent("LoadingOpaque")!
  const _component_BurgerMenu = _resolveComponent("BurgerMenu")!
  const _component_OverallBanner = _resolveComponent("OverallBanner")!
  const _component_OurImpact = _resolveComponent("OurImpact")!
  const _component_SupportedProjectsAggregate = _resolveComponent("SupportedProjectsAggregate")!
  const _component_PublicLedger = _resolveComponent("PublicLedger")!
  const _component_PublicImpactMobileMenu = _resolveComponent("PublicImpactMobileMenu")!
  const _component_CustomFooterV2 = _resolveComponent("CustomFooterV2")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingOpaque, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("a", _hoisted_2, [
            _createElementVNode("img", {
              class: "logo",
              src: require('@/assets/greenspark-logo.svg'),
              alt: "greenspark-logo"
            }, null, 8, _hoisted_3)
          ]),
          _createVNode(_component_BurgerMenu, { items: _ctx.items }, null, 8, ["items"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_OverallBanner),
            (!_ctx.isMobile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.menuItem === 0 ? 'menu-item-active' : 'menu-item'),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.menuItem = 0))
                  }, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('our_impact')), 1)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.menuItem === 1 ? 'menu-item-active' : 'menu-item'),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.menuItem = 1))
                  }, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t('projects')), 1)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.menuItem === 2 ? 'menu-item-active' : 'menu-item'),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.menuItem = 2))
                  }, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t('public_ledger')), 1)
                  ], 2)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                (_ctx.menuItem === 0)
                  ? (_openBlock(), _createBlock(_component_OurImpact, {
                      key: 0,
                      "total-impacts-array": _ctx.totalImpactsArray
                    }, null, 8, ["total-impacts-array"]))
                  : _createCommentVNode("", true),
                (_ctx.menuItem === 1)
                  ? (_openBlock(), _createBlock(_component_SupportedProjectsAggregate, { key: 1 }))
                  : _createCommentVNode("", true),
                (_ctx.menuItem === 2)
                  ? (_openBlock(), _createBlock(_component_PublicLedger, { key: 2 }))
                  : _createCommentVNode("", true)
              ])
            ]),
            (_ctx.isMobile)
              ? (_openBlock(), _createBlock(_component_PublicImpactMobileMenu, {
                  key: 1,
                  "menu-item": _ctx.menuItem,
                  onHomeClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.menuItem = 0)),
                  onProjectsClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.menuItem = 1)),
                  onLedgerClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.menuItem = 2))
                }, null, 8, ["menu-item"]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createBlock(_component_CustomFooterV2, {
          key: 3,
          text: _ctx.t('join')
        }, null, 8, ["text"]))
      : _createCommentVNode("", true)
  ]))
}