import type { Directive } from 'vue'
import type { Clearable } from '@/helpers/interfaces'

class ObserveIntersection {
  observer: Clearable<IntersectionObserver> = null
  // binding.value is callback function on intersection
  // binding.arg is the argument to pass to the callback function
  created = (el, binding) => {
    this.observer = new IntersectionObserver(
      (entries) => {
        this.onIntersection(entries, binding.value, binding.arg)
      },
      {
        root: null, // default is the viewport
        threshold: 0.7, // percentage of target's visible area. Triggers "onIntersection"
      },
    )
    this.observer.observe(el)
  }

  beforeUnmount = (el) => {
    if (this.observer) this.observer.unobserve(el)
  }

  onIntersection(
    entries: IntersectionObserverEntry[],
    callback: (param: unknown) => void,
    callbackArg: number,
  ) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        callback(callbackArg)
      }
    })
  }
}

const observeIntersection = <Directive<HTMLElement, string>>new ObserveIntersection()
export default observeIntersection
