<template>
  <div v-if="!loading">
    <div class="description">
      <div class="description-header">Admin Panel</div>
      <div class="description-text">Admin Panel for making data changes available for CS</div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Account } from '@/store'
import type { Project } from '@/store/projects'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AdminHome',
  components: {},
  data() {
    return {
      loading: false,
      addProject: false,
    }
  },
  async created() {
    this.loading = true
    await this.setProjectList()
    this.loading = false
  },
  methods: {
    t(key: string) {
      return this.$t(`AdminHome.${key}`)
    },
    setProjectList(): Promise<void> {
      return this.$store.dispatch('setProjectList')
    },
  },
  computed: {
    projects(): () => Project[] {
      return this.$store.getters['getApprovedProjects']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
})
</script>

<style lang="scss" scoped>
.description {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  margin-top: 35px;
  margin-bottom: 25px;
}

.description-header {
  margin-bottom: 10px;
  color: #212121;
  font-size: 22px;
  font-weight: 600;
}

.description-text {
  color: #212121;
  font-size: 16px;
  margin-top: 4px;
}

.button-add {
  margin-bottom: 20px;
}

.add-project-wrapper {
  margin-bottom: 40px;
}
</style>
