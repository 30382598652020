<template>
  <div class="data-table-wrapper">
    <ImpactSettingsPopup
      v-if="impactSettingsPopupIsOpen"
      :selected-products="selectedItems"
      @close="impactSettingsPopupIsOpen = false"
      @save="saveImpactSettings"
    />
    <div v-show="!showLoader">
      <div class="data-table-header">
        <div class="data-table-header-filters">
          <v-text-field
            v-model="search"
            append-inner-icon="mdi-magnify"
            :placeholder="t('search')"
            variant="outlined"
            hide-details
            color="green"
            class="product-search"
            @update:model-value="searchProducts()"
          />
          <v-select
            v-model="impactFilter"
            :label="t('filter_by_impact')"
            :items="impactTypes"
            multiple
            variant="outlined"
            hide-details
            color="green"
            class="product-filter"
            @update:model-value="filterProducts()"
          >
          </v-select>
        </div>
        <div v-if="selectedItems.length && items.length" class="data-table-header-actions">
          <gs-button @click.prevent="addBulkImpact" capitalized :uppercased="false">
            {{ t('add_bulk_impact') }}
            <span>({{ selectedItems.length }})</span>
          </gs-button>
          <v-dialog max-width="600">
            <template v-slot:activator="{ props: activatorProps }">
              <gs-button v-bind="activatorProps" capitalized :uppercased="false" type="monochrome">
                {{ t('clear_impact.label') }}
              </gs-button>
            </template>

            <template v-slot:default="{ isActive }">
              <v-card :title="t('clear_impact.title')">
                <v-card-text>
                  {{ t('clear_impact.description') }}
                </v-card-text>

                <v-card-actions class="pa-4">
                  <gs-button
                    @click.prevent="isActive.value = false"
                    capitalized
                    :uppercased="false"
                    full-width
                    outlined
                    class="mr-3"
                    type="monochrome"
                  >
                    {{ $t('CommonUi.cancel') }}
                  </gs-button>
                  <v-spacer></v-spacer>
                  <gs-button
                    @click.prevent="
                      () => {
                        clearImpact()
                        isActive.value = false
                      }
                    "
                    capitalized
                    :uppercased="false"
                    full-width
                    type="monochrome"
                  >
                    {{ t('clear_impact.label') }}
                  </gs-button>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </div>
      </div>
      <div v-if="(!!search || impactFilter.length) && !items.length" class="not-found">
        <v-icon color="black">mdi-magnify-expand</v-icon>
        <p class="not-found-text" v-html="t('not_found')" />
      </div>
      <v-data-table-server
        v-if="showTable"
        :key="tableRender"
        v-model="selectedItems"
        v-model:sort-by="sortBy"
        :headers="headers"
        :items="items"
        checkbox-color="#3B755F"
        show-select
        class="elevation-0 data-table"
        item-value="productId"
        v-model:items-per-page="itemsPerPage"
        :items-per-page-options="[5, 10, 20, 50]"
        color="#3B755F"
        height="423"
        :items-length="itemsCount"
        @update:options="setItems"
        @toggle-select-all="setAllSelected"
      >
        <template v-slot:[`item.image`]="{ item }">
          <img class="product-image" :src="item.image" />
        </template>

        <template v-slot:[`item.offsetType`]="{ item }">
          <div class="impact-pill-wrapper">
            <div class="impact-pill-row">
              <template v-if="getProductOffsets(item.productId).length <= 3">
                <impact-pill
                  v-for="(offset, index) in getProductOffsets(item.productId)"
                  :key="index"
                  class="ma-0"
                  :impact="{ type: offset.type, amount: offset.amount || 0 }"
                />
              </template>
              <impact-pill
                v-else
                :impact="{
                  type: 'multiple',
                  amount: getTotalProductOffset(getProductOffsets(item.productId)),
                }"
              />
            </div>
            <gs-button
              @click.prevent="addImpact(item.productId)"
              :class="{ icon: getProductOffsets(item.productId)?.length }"
              size="small"
              type="monochrome"
              outlined
              icon="mdi-plus"
              capitalized
              :uppercased="false"
            >
              {{ getProductOffsets(item.productId)?.length ? '' : t('add_impact') }}
            </gs-button>
          </div>
        </template>
      </v-data-table-server>
      <div class="product-sync">
        <p class="product-sync-title">{{ t('sync_products.title') }}</p>
        <gs-button
          @click.prevent="fetchProducts"
          type="monochrome"
          icon="mdi-sync"
          :disabled="getProductFetchStatusByIntegrationId(integrationId) === 'fetching'"
          :loading="getProductFetchStatusByIntegrationId(integrationId) === 'fetching'"
          capitalized
          :uppercased="false"
        >
          {{ t('sync_products.button') }}
        </gs-button>
      </div>
    </div>
    <LoaderBlock v-show="showLoader" />
  </div>
</template>

<script lang="ts">
import type {
  MerchantProduct,
  ProductsFetchStatusType,
  SetProductFetchStateByIntegrationIdParams,
} from '@/store'
import type {
  FetchProductsQueryFilter,
  GetMerchantProductsResponse,
  MerchantProductSearchOptions,
} from '@api/index'
import { getMerchantProducts, initiateFetchProducts } from '@api/index'
import ImpactPill from '@/components/ImpactWallet/ImpactPill.vue'
import LoaderBlock from '@/components/tools/LoaderBlock.vue'
import type {
  ActiveProductsFromAutomationsWithGlobalOffsets,
  ActiveProductsFromOtherAutomationsWithOffsets,
  Automation,
  AutomationOffset,
} from '@/store/integrations'
import type { AxiosResponse } from 'axios'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { OFFSET_TYPES, type OffsetType } from '@/helpers/interfaces'
import ImpactSettingsPopup from '@/components/onboarding/ImpactSettingsPopup.vue'

export default defineComponent({
  name: 'ProductTable',
  emits: ['selectItems', 'save'],
  components: {
    LoaderBlock,
    ImpactPill,
    ImpactSettingsPopup,
  },
  mixins: [Utils],
  data() {
    return {
      search: '',
      impactFilter: [],
      isAllSelected: false,
      items: [],
      sortBy: [],
      itemsCount: 0,
      tableRender: 0,
      itemsPerPage: 5,
      timer: null,
      headers: [
        {
          title: 'Image',
          key: 'image',
          sortable: false,
          width: '60px',
        },
        {
          title: 'Product name',
          key: 'name',
          width: '200px',
        },
        {
          title: 'Type',
          key: 'type',
          width: '100px',
        },
        {
          title: 'Impact',
          key: 'offsetType',
          width: '210px',
          sortable: false,
        },
      ],
      productListImpactSettings: [],
      impactSettingsPopupIsOpen: false,
    } as {
      search: string
      impactFilter: OffsetType[]
      sortBy: {
        key: string
        order: 'asc' | 'desc'
      }[]
      isAllSelected: boolean
      items: MerchantProduct[]
      itemsCount: number
      tableRender: number
      itemsPerPage: number
      timer: ReturnType<typeof setTimeout> | null
      headers: {
        title: string
        key: string
        sortable?: boolean
        width: string
      }[]
      productListImpactSettings: MerchantProduct[]
      impactSettingsPopupIsOpen: boolean
    }
  },
  computed: {
    areProductsFetched(): boolean {
      return (
        !!this.integrationId &&
        this.getProductFetchStatusByIntegrationId(this.integrationId) === 'ready'
      )
    },
    automation(): Automation | undefined {
      return this.automationId ? this.getAutomationById(this.automationId) : undefined
    },
    showLoader(): boolean {
      return !(this.items.length || this.search || this.impactFilter.length)
    },
    showTable(): boolean {
      return !!this.items.length
    },
    getProductFetchStatusByIntegrationId(): (
      integrationId: string | undefined,
    ) => ProductsFetchStatusType {
      return this.$store.getters['getProductFetchStatusByIntegrationId']
    },
    getAutomationById(): (automationId: string) => Automation {
      return this.$store.getters['getAutomationById']
    },
    getIntegrationIdByAutomationId(): (automationId: string) => string {
      return this.$store.getters['getIntegrationIdByAutomationId']
    },
    getActiveProductsFromOtherAutomationsWithOffsets(): (
      integrationId: string,
      automationId?: string,
    ) => ActiveProductsFromOtherAutomationsWithOffsets[] {
      return this.$store.getters['getActiveProductsFromOtherAutomationsWithOffsets']
    },
    getActiveProductsFromAutomationsWithGlobalOffsets(): (
      integrationId: string,
    ) => ActiveProductsFromAutomationsWithGlobalOffsets[] {
      return this.$store.getters['getActiveProductsFromAutomationsWithGlobalOffsets']
    },
    selectedItems: {
      get(): string[] {
        return this.selectedProducts ?? []
      },
      set(items) {
        this.$emit('selectItems', items)
      },
    },
    impactTypes() {
      return OFFSET_TYPES.map((type) => ({
        title: this.t(`impact.${type}`),
        value: type,
      }))
    },
  },
  async created() {
    this.$socket.client.on('saveProductsDone', this.onSaveProductsDone)

    if (!this.areProductsFetched) {
      await this.fetchProducts()
    } else {
      await this.setItems()
    }

    if (!this.automation) {
      this.productListImpactSettings = this.items.map((item) => ({
        ...item,
        offsets: [],
      }))
    } else {
      this.mapImpactSettingsToProductList()
    }
  },
  methods: {
    beforeDestroy() {
      this.$socket.client.off('saveProductsDone')
    },
    t(key: string, params?: Record<string, string>) {
      return this.$t(`ProductTable.${key}`, params ?? {})
    },
    onSaveProductsDone({ integrationId }) {
      this.setProductFetchState({
        integrationId,
        lastProductsFetchDate: new Date().toISOString(),
        state: 'ready',
      })
    },
    setAllSelected(payload) {
      this.isAllSelected = payload.value
    },
    delay() {
      return new Promise<void>((resolve) => setTimeout(resolve, 2000))
    },
    mapImpactSettingsToProductList() {
      const productMap: { [key: string]: AutomationOffset[] } = {}
      const productIds = this.automation?.source?.ids || []

      this.automation?.offsets.forEach((offset) => {
        const offsetProductIds = offset.source?.ids || productIds

        offsetProductIds.forEach((productId) => {
          const impact: AutomationOffset = {
            type: offset.type as OffsetType,
            amount: offset.amount || 0,
            projectId: offset.projectId,
          }

          if (!productMap[productId]) {
            productMap[productId] = []
          }

          productMap[productId].push(impact)
        })
      })

      this.productListImpactSettings = Object.keys(productMap).map((productId) => {
        const existingProduct: MerchantProduct | undefined = this.items.find(
          (item) => item.productId === productId,
        )

        if (existingProduct) {
          return {
            ...existingProduct,
            offsets: productMap[productId],
          }
        } else {
          return {
            name: '',
            type: '',
            image: '',
            productId,
            active: false,
            offsets: productMap[productId],
          }
        }
      })
    },
    async searchProducts() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(async () => {
        if (this.search.length > 2 || !this.search.length) {
          await this.setItems({}, true)
        }
      }, 200)
    },
    async fetchProducts() {
      await this.initiateFetch(true)

      do {
        await this.delay()
        await this.setItems()
      } while (!this.areProductsFetched)
    },
    async filterProducts() {
      this.setItems({}, true)
    },
    async setItems(options?: Record<string, string>, refresh = false) {
      const filters: FetchProductsQueryFilter[] = this.impactFilter.length
        ? [{ property: 'impact', operator: 'in', value: [...this.impactFilter] }]
        : []

      const transformedOptions: Partial<MerchantProductSearchOptions> = {
        ...options,
        sortBy: this.sortBy?.[0]?.key || '',
        sortDirection: this.sortBy?.[0]?.order,
        search: this.search,
        filters,
        limit: options?.itemsPerPage ? parseInt(options?.itemsPerPage) : 5,
      }
      const { data }: AxiosResponse<GetMerchantProductsResponse> = await getMerchantProducts(
        this.integrationId ?? '',
        this.automation?.id,
        transformedOptions || {},
      )

      this.items = data.data || []
      this.itemsCount = data.count

      if (this.impactFilter.length && !this.search) {
        const filteredProducts = this.productListImpactSettings.filter((product) => {
          return product.offsets.some((offset) => this.impactFilter.includes(offset.type))
        })

        if (!this.items.length) {
          this.items =
            transformedOptions.sortBy && transformedOptions.sortDirection
              ? this.sortProducts(
                  filteredProducts,
                  transformedOptions.sortBy,
                  transformedOptions.sortDirection,
                )
              : filteredProducts
        } else {
          const mergedProducts = filteredProducts.map((filteredProduct) => {
            const existingProduct = this.items.find(
              (item) => item.productId === filteredProduct.productId,
            )

            if (existingProduct) {
              return {
                ...filteredProduct,
                ...existingProduct,
              }
            }

            return filteredProduct
          })

          this.items =
            transformedOptions.sortBy && transformedOptions.sortDirection
              ? this.sortProducts(
                  mergedProducts,
                  transformedOptions.sortBy,
                  transformedOptions.sortDirection,
                )
              : mergedProducts
        }
      }

      if (refresh) this.tableRender++
    },
    async initiateFetch(eagerSave = false) {
      this.setProductFetchState({
        integrationId: this.integrationId ?? '',
        state: 'fetching',
        lastProductsFetchDate: null,
      })
      await initiateFetchProducts({
        integrationId: this.integrationId,
        eagerSave,
      })
    },
    getProductOffsets(productId: string): AutomationOffset[] {
      return (
        this.productListImpactSettings.find((item) => item.productId === productId)?.offsets || []
      )
    },
    getTotalProductOffset(offsets: AutomationOffset[]): number {
      return offsets.reduce((total, offset) => {
        return total + (offset.amount || 0)
      }, 0)
    },
    setProductFetchState(params: SetProductFetchStateByIntegrationIdParams): void {
      return this.$store.commit('setProductFetchState', params)
    },
    sortProducts(
      products: MerchantProduct[],
      sortBy: string,
      sortDirection: 'asc' | 'desc',
    ): MerchantProduct[] {
      return products.sort((a, b) => {
        const valA = a[sortBy].toLowerCase()
        const valB = b[sortBy].toLowerCase()

        if (valA < valB) {
          return sortDirection === 'asc' ? -1 : 1
        } else if (valA > valB) {
          return sortDirection === 'asc' ? 1 : -1
        }

        return 0
      })
    },
    addImpact(productId: string) {
      this.selectedItems = [productId]
      this.impactSettingsPopupIsOpen = true
    },
    addBulkImpact() {
      this.impactSettingsPopupIsOpen = true
    },
    clearImpact() {
      this.productListImpactSettings.forEach((product) => {
        if (this.selectedItems.includes(product.productId)) {
          product.offsets = []
        }
      })

      this.filterProducts()
      this.$emit('save', this.productListImpactSettings)
    },
    saveImpactSettings(data) {
      const selectedProducts = data.selectedProducts
      const offsets = data.offsets

      this.items.forEach((item) => {
        if (!selectedProducts.includes(item.productId)) return

        const existingProduct = this.productListImpactSettings.find(
          (product) => product.productId === item.productId,
        )

        if (existingProduct) {
          existingProduct.name = item.name
          existingProduct.type = item.type
          existingProduct.image = item.image

          offsets.forEach((newOffset) => {
            const existingOffset = existingProduct.offsets.find(
              (offset) => offset.type === newOffset.type,
            )

            if (existingOffset) {
              existingOffset.amount += newOffset.amount
            } else {
              existingProduct.offsets.push({ ...newOffset })
            }
          })
        } else {
          this.productListImpactSettings.push({
            ...item,
            offsets: [...offsets],
          })
        }
      })

      this.$emit('save', this.productListImpactSettings)
    },
  },
  props: {
    automationId: {
      type: String,
    },
    integrationId: {
      type: String,
    },
    selectedProducts: {
      type: Array as PropType<string[]>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.data-table {
  border: 1px solid var(--gray-light-CC);
  border-top: 0;
  font-size: 16px;

  :deep(.v-data-table-header__content) {
    font-size: 18px;
    font-weight: 700;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: 20px;
    }
  }
}

.data-table-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  background-color: var(--ui-white);
  gap: 16px;

  @media (min-width: 1050px) {
    gap: 0;
    flex-direction: row;
    align-items: center;
  }
}

.data-table-header-actions {
  display: flex;
  gap: 8px;
  align-self: flex-end;

  @media (min-width: 1050px) {
    align-self: center;
  }
}

.data-table-header-filters {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex-direction: row;
    align-items: center;
  }

  :deep(.v-field) {
    --v-field-input-padding-bottom: 8px;
    --v-field-input-padding-top: 8px;
    --v-input-control-height: 36px;
    border-radius: 8px;
  }

  :deep(.v-field__outline) {
    --v-field-border-opacity: 1;
  }
}

.product-filter,
.product-search {
  width: auto;
  min-width: 200px;

  @media (min-width: 450px) {
    width: 310px;
  }
}

.product-filter {
  :deep(.v-field__input) {
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap;
    position: static;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.impact-pill-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;

  .gs-button {
    padding-left: 4px !important;
    border-radius: 8px;
    text-wrap: nowrap;
  }

  .icon {
    padding: 0 !important;
    min-width: 28px;
    margin-left: 16px;

    :deep(.text) {
      margin-left: 0 !important;
    }
  }
}

.impact-pill-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.product-image {
  border-radius: var(--border-radius-small);
  object-fit: contain;
  object-position: center;
  width: 70px;
  height: auto;
  max-height: 140px;
}

.not-found {
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 8px;
  border: 1px solid var(--gray-light-CC);
  border-top: 0;
}

.not-found-text {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    font-size: 20px;
  }
}

.product-sync {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding-top: 32px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.product-sync-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}
</style>
