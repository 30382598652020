<template>
  <div v-if="isChecklistOpen" class="checklist-overlay">
    <div v-click-outside="close" class="checklist-wrapper">
      <button
        v-if="isMobileLarge || isMobile"
        class="close-checklist-button"
        @click.prevent="close"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </button>
      <h3 class="checklist-title">
        {{ t('title') }}
      </h3>
      <div class="progress-wrapper">
        <span class="progress-percent">{{ getProgressPercent + '%' }}</span>
        <div class="progress-bar">
          <div class="progress-value" :style="`width: ${getProgressPercent}%`" />
        </div>
      </div>
      <ul class="checklist-list">
        <li v-for="(item, index) in setupChecklist" :key="index" class="checklist-list-item">
          <button
            :class="['checklist-list-item__button', { 'is-ready': item.value }]"
            :disabled="item.value"
            @click.prevent="navigate(item.type)"
          >
            <v-icon class="checklist-list-item__icon material-icons-outlined">
              {{ getIconNane(item.type) }}
            </v-icon>
            <span>{{ t(snakeCase(item.type)) }}</span>
            <button
              class="status-button"
              :disabled="item.value"
              @click.prevent.stop="updateStatus(item.type)"
            >
              <v-icon class="status-icon" variant="plain">
                {{ item.value ? 'mdi-check-circle' : 'mdi-close' }}
              </v-icon>
            </button>
          </button>
        </li>
      </ul>
      <button
        v-if="isFreeSubscription"
        class="free-business-subscription"
        @click.prevent="openPriceComparisonPopup"
      >
        <span v-html="t('free_business_subscription')" />
      </button>
      <button class="dismiss-button" @click.prevent="dismiss">
        {{ t('dismiss') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { eventBus } from '@/main'
import type { SetupChecklistItem, ChecklistItemType } from '@/store/checklist'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { SubscriptionItem } from '@/store/subscriptions'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FloatingOnboardingChecklist',
  emits: ['hide'],
  mixins: [Utils],
  computed: {
    isFreeSubscription(): boolean {
      return this.getActiveSubscriptionItem?.product === 'freeBusiness'
    },
    isChecklistOpen(): boolean {
      return this.$store.getters['getChecklistOpenState']
    },
    hasChecklistBeenOpened(): boolean {
      return this.$store.getters['getHasChecklistBeenOpened']
    },
    setupChecklist(): SetupChecklistItem[] {
      return this.$store.getters['getSetupChecklistItems']
    },
    loading(): boolean {
      return this.$store.getters['getChecklistLoading']
    },
    getProgressPercent(): () => number {
      return this.$store.getters['getProgressPercent']
    },
    getActiveSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getActiveSubscriptionItem']
    },
  },
  async created() {
    await this.setChecklist()
  },
  methods: {
    t(key: string) {
      return this.$t(`FloatingOnboardingChecklist.${key}`)
    },
    getIconNane(itemType: ChecklistItemType) {
      switch (itemType) {
        case 'addAutomation':
          return 'mdi-hand-heart'
        case 'addIntegration':
          return 'mdi-code-block-tags'
        case 'customerEngagementPortal':
          return 'mdi-animation-outline'
        case 'addReferral':
          return 'mdi-bullhorn-variant-outline'
        case 'addWidgets':
          return 'mdi-shape-outline'
        case 'plantTrees':
          return 'mdi-pine-tree-variant-outline'
        case 'offsetTeam':
          return 'mdi-account-group'
        case 'inviteUser':
          return 'mdi-email-outline'
      }
    },
    close() {
      this.setOpenState(false)
    },
    async dismiss() {
      await this.dismissChecklist()
    },
    async updateStatus(setupChecklistItemType: ChecklistItemType) {
      await this.updateChecklist(setupChecklistItemType)
    },
    navigate(item: ChecklistItemType) {
      switch (item) {
        case 'addReferral':
          eventBus.emit('reference')
          break
        case 'addWidgets':
          if (this.$route.path !== '/shareables/badges') this.$router.push('/shareables/badges')
          break
        case 'customerEngagementPortal':
          if (this.$route.path !== '/shareables/post-purchase-engagement')
            this.$router.push('/shareables/post-purchase-engagement')
          break
        case 'addIntegration':
          if (this.$route.path !== '/add-integrations-automations?addNew=true')
            this.$router.push('/add-integrations-automations?addNew=true')
          break
        case 'addAutomation':
          if (this.$route.path !== '/add-integrations-automations')
            this.$router.push('/add-integrations-automations')
          break
        case 'offsetTeam':
          if (this.$route.path !== '/team') this.$router.push('/team')
          break
        case 'inviteUser':
          if (this.$route.path !== '/account' && this.$route.query.tab !== 'user')
            this.$router.push({ path: 'account', query: { tab: 'users' } })
          break
        case 'plantTrees':
      }
      this.setOpenState(false)
    },
    onSetupChecklistChange() {
      if (this.setupChecklist.every((item) => item.value)) this.$emit('hide')
    },
    async onIsChecklistOpenChange() {
      if (this.isChecklistOpen) await this.setChecklist()
      document.documentElement.classList.toggle('modal-open')
    },
    setChecklist(): Promise<void> {
      return this.$store.dispatch('setChecklist')
    },
    updateChecklist(item: ChecklistItemType): Promise<void> {
      return this.$store.dispatch('updateChecklist', item)
    },
    dismissChecklist(): Promise<void> {
      return this.$store.dispatch('dismissChecklist')
    },
    openPriceComparisonPopup(): Promise<void> {
      return this.$store.dispatch('openPriceComparisonPopup')
    },
    setOpenState(isChecklistOpen: boolean): void {
      return this.$store.commit('setOpenState', isChecklistOpen)
    },
    setHasChecklistBeenOpenedState(hasChecklistBeenOpened: boolean): void {
      return this.$store.commit('setHasChecklistBeenOpenedState', hasChecklistBeenOpened)
    },
  },
  watch: {
    setupChecklist: [
      {
        deep: true,
        handler: 'onSetupChecklistChange',
      },
    ],
    isChecklistOpen: [
      {
        handler: 'onIsChecklistOpenChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.checklist-overlay {
  position: fixed;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: var(--overlay-background);
  z-index: 6;
}

.checklist-wrapper {
  height: 100%;
  background-color: white;
  padding: 20px;
  overflow-y: auto;
}

.close-checklist-button {
  border-radius: var(--border-radius-small);
  border: 2px solid var(--gray-light);
  font-size: 24px;
  line-height: 24px;
  height: 30px;
  width: 30px;
  margin-bottom: 15px;

  .v-icon {
    font-size: 23px;
  }
}

.checklist-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.progress-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 5px;
}

.progress-bar {
  width: 100%;
  background-color: var(--gray-light);
  height: 15px;
  border-radius: 20px;
  overflow: hidden;
}

.progress-value {
  background-color: var(--ui-green);
  height: 100%;
}

.checklist-list {
  list-style-type: none;
  padding: 0;
}

.checklist-list-item {
  margin-bottom: 15px;
}

.checklist-list-item__button {
  border-radius: var(--border-radius-small);
  border: 2px solid var(--ui-green-light);
  padding-left: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  width: 100%;
}

.checklist-list-item__button:not(:disabled):hover {
  border-color: var(--ui-green);
  box-shadow: var(--box-shadow);
}

.checklist-list-item__button.is-ready {
  border-color: var(--ui-green);
  color: var(--ui-green);
}

.checklist-list-item__button.is-ready span {
  text-decoration: line-through;
}

.status-button {
  margin-left: auto;
  width: 35px;
  height: 58px;
  display: block;
}

.status-icon {
  font-size: 16px;
  color: var(--gray-light);
}

.checklist-list-item__button.is-ready .status-icon {
  color: var(--ui-green);
  font-size: 20px;
}

.checklist-list-item__icon {
  color: var(--font-color-primary);
}

.checklist-list-item__button.is-ready .checklist-list-item__icon {
  color: var(--ui-green);
}

.dismiss-button {
  font-size: 12px;
}

.free-business-subscription {
  background-color: var(--ui-green);
  padding: 16px;
  color: white;
  border-radius: var(--border-radius-small);
  width: 100%;
  font-size: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.free-business-subscription > span {
  display: block;
  text-align: center;
  max-width: 20ch;
}

.free-business-subscription:hover {
  opacity: 0.8;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .checklist-wrapper {
    padding: 20px 20px 10px;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius-big);
    top: 50%;
    min-height: auto;
    height: fit-content;
    max-height: calc(100vh - 40px);
    right: 100px;
    bottom: 50%;
    left: auto;
    transform: translate(0, -50%);
    width: fit-content;
    max-width: 370px;
    position: absolute;
  }

  .progress-wrapper.beige {
    margin-bottom: 0;
    margin-top: 5px;
  }

  .progress-bar {
    width: 100%;
    background-color: var(--gray-light);
    height: 15px;
    border-radius: 20px;
    overflow: hidden;
  }

  .progress-wrapper.beige .progress-bar {
    background-color: var(--gray-light-20);
  }

  .progress-value {
    background-color: var(--ui-green);
    height: 100%;
  }

  .progress-wrapper.beige .progress-value {
    background-color: var(--ui-beige);
  }

  .progress-wrapper.beige .progress-percent {
    font-size: 12px;
  }
}
</style>
