<template>
  <div class="select-trigger">
    <step-summary
      :label="t('integration')"
      :previous-answers="[getIntegrationPlatformName(platform) || '', selectedIntegration?.name]"
    />

    <section class="automation-name-wrapper">
      <div class="label-wrapper">
        <p class="label">
          {{ t('name_automation') }}
        </p>
        <p class="description">{{ t('automation_tooltip') }}</p>
      </div>

      <gs-input
        :model-value="name"
        @update:model-value="(v) => $emit('update:name', v)"
        class="automation-name-input"
        :label="t('automation_name')"
        :hide-details="false"
        :rules="[rules.required]"
        :error="inputError"
      />
    </section>
    <select-shopify-order-origin
      v-if="platform === 'shopify'"
      class="select-order-origin-wrapper"
      :model-value="shopifyOrderOrigin"
      @update:model-value="(v) => $emit('update:shopify-order-origin', v)"
      :integration-name="platform"
    />
    <section v-if="showFormImpactSource">
      <select-form-impact-source
        :model-value="formImpactSource"
        @update:model-value="(v) => $emit('update:form-impact-source', v)"
        :error="formImpactSourceError"
        :platform="platform"
      />
    </section>
    <section class="select-trigger-wrapper">
      <p class="label choose">
        {{ t('choose_trigger') }}
      </p>
      <ul class="select-trigger-list">
        <li
          v-for="(trigger, index) in triggers"
          :key="index"
          class="select-trigger-list-item"
          v-observe-intersection:[index]="setVisibleIndex"
        >
          <button
            :class="['select-trigger-button', { 'is-active': activeTrigger === trigger }]"
            @click="() => selectTrigger(trigger)"
          >
            <div class="trigger-title">
              <v-icon class="trigger-icon material-icons-outlined">
                {{ getAutomationIcon(trigger) }}
              </v-icon>
              <p class="trigger-name">
                {{ t(snakeCase(trigger)) }}
              </p>
            </div>
            <p class="trigger-description">
              {{ t(`description_${snakeCase(trigger)}`) }}
            </p>
            <p v-if="!isTriggerAllowed(trigger)" class="notification-text">
              {{
                t('notification_text', {
                  planName: getPlanName(activePaidSubscriptionItem.product) || '',
                })
              }}
            </p>
          </button>
        </li>
      </ul>
      <div v-if="triggers.length > 1" class="markers">
        <div
          v-for="(_, index) in triggers"
          :key="index"
          :class="['marker-dot', { visible: index === lastVisibleCardIndex }]"
        />
      </div>
    </section>
    <div class="form-controls">
      <gs-button
        capitalized
        muted
        outlined
        type="secondary-v2"
        size="large"
        class="form-control"
        :uppercased="false"
        icon="mdi-chevron-left"
        @click="$emit('back')"
      >
        {{ $t('CommonUi.back') }}
      </gs-button>
      <gs-button
        full-width
        capitalized
        :uppercased="false"
        type="secondary-v2"
        size="large"
        class="form-control"
        :disabled="hasFormErrors"
        @click="$emit('submit')"
      >
        {{ $t('CommonUi.next') }}
      </gs-button>
    </div>
    <CallToUpgradePopup
      v-if="isCallToUpgradePopupOpen"
      :is-premium-only="false"
      popup-type="trigger"
      @close="isCallToUpgradePopupOpen = false"
    />
  </div>
</template>

<script lang="ts">
import type {
  AutomationTrigger,
  IntegrationPlatform,
  IntegrationTriggerType,
  ShopifyOrderOrigin,
  StoreIntegrationTrigger,
} from '@/helpers/interfaces'
import { BASIC_TRIGGER_TYPES } from '@/helpers/interfaces'

import { Utils } from '@/helpers/mixins/utilsMixin'
import StepSummary from '@/components/form/StepSummary.vue'
import CallToUpgradePopup from '@/components/common/CallToUpgradePopup.vue'
import type { SubscriptionItem } from '@/store/subscriptions'
import { includes } from '@/helpers/parsers'
import type { SubscriptionType } from '@/helpers/pricing'
import SelectShopifyOrderOrigin from '@/components/integration/SelectShopifyOrderOrigin.vue'
import type { Integration, IntegrationTableData } from '@/store/integrations'
import { FORM_INTEGRATION_SOURCES } from '@/helpers/constants'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'
import type { SelectedIntegration } from '@/components/onboarding/SelectIntegration.vue'
import SelectFormImpactSource from '@/components/integration/SelectFormImpactSource.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SelectTrigger',
  components: {
    SelectShopifyOrderOrigin,
    CallToUpgradePopup,
    SelectFormImpactSource,
    StepSummary,
  },
  mixins: [Utils, RulesMixin, IntegrationsMixin],
  emits: [
    'back',
    'submit',
    'update:name',
    'update:active-trigger',
    'update:shopify-order-origin',
    'update:form-impact-source',
  ],
  data() {
    return {
      isCallToUpgradePopupOpen: false,
      formImpactSourceError: false,
      inputError: false,
      lastVisibleCardIndex: 0,
    } as {
      isCallToUpgradePopupOpen: boolean
      formImpactSourceError: boolean
      inputError: boolean
      lastVisibleCardIndex: number
    }
  },
  computed: {
    platform(): IntegrationPlatform {
      return this.selectedIntegration.platform
    },
    showFormImpactSource(): boolean {
      return includes(FORM_INTEGRATION_SOURCES, this.platform)
    },
    hasFormErrors() {
      return this.formImpactSourceError || this.inputError || !this.name || !this.activeTrigger
    },
    advancedAutomations(): boolean {
      return this.$store.getters['getAdvancedAutomationsFeatureSetting']
    },
    triggers(): AutomationTrigger[] {
      return this.$store.getters['getAvailableAutomationTriggersByPlatform'](this.platform)
    },
    activePaidSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getActivePaidSubscriptionItem']
    },
    getIntegrationById(): (integrationId: string) => Integration {
      return this.$store.getters['getIntegrationById']
    },
  },
  created() {
    this.fetchIntegrationOptions()
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`SelectTrigger.${key}`, params ?? {})
    },
    setVisibleIndex(payload: number) {
      this.lastVisibleCardIndex = payload
    },
    selectTrigger(trigger: IntegrationTriggerType | StoreIntegrationTrigger) {
      if (this.showFormImpactSource && !this.formImpactSource) {
        this.formImpactSourceError = true
        return
      }
      if (!this.isTriggerAllowed(trigger)) {
        this.isCallToUpgradePopupOpen = true
      } else {
        this.$emit('update:active-trigger', trigger)
      }
    },
    isTriggerAllowed(trigger: IntegrationTriggerType | StoreIntegrationTrigger) {
      return this.advancedAutomations || includes(BASIC_TRIGGER_TYPES, trigger)
    },
    getPlanName(plan: SubscriptionType): string | undefined {
      switch (plan) {
        case 'free':
        case 'zero':
        case 'freeBusiness':
          return 'Free'
        case 'ECOMMERCE':
        case 'ecommerce':
        case 'businessLegacy':
          return 'Ecommerce'
        case 'starterBusiness':
        case 'starterBusinessYearly':
          return 'Starter'
        case 'growthBusiness':
        case 'growthBusinessYearly':
          return 'Growth'
        case 'premiumBusiness':
        case 'premiumBusinessYearly':
          return 'Premium'
        case 'enterpriseBusiness':
          return 'Enterprise'
        default:
          console.error(`${plan} does not exist.`)
      }
    },
    fetchIntegrationOptions(): Promise<void> {
      return this.$store.dispatch('fetchIntegrationOptions')
    },
  },
  props: {
    selectedIntegration: {
      type: Object as PropType<SelectedIntegration | IntegrationTableData>,
      required: true,
    },
    activeTrigger: {
      type: String as PropType<IntegrationTriggerType | StoreIntegrationTrigger>,
      required: false,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    shopifyOrderOrigin: {
      type: String as PropType<ShopifyOrderOrigin>,
      required: false,
      default: 'all',
    },
    formImpactSource: {
      type: String as PropType<string | 'all'>,
      required: false,
      default: 'all',
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

section {
  padding: 24px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--gray-light-CC);
  }
}

section.automation-name-wrapper {
  padding-bottom: 22px;
}

.label {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
}

.label-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 24px;
  gap: 10px;

  .description {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;
  }
}

.tooltip-icon {
  color: var(--font-color-primary);
  cursor: pointer;
}

.choose.label {
  margin-bottom: 24px;
}

.automation-name-input {
  max-width: 340px;
}

.select-trigger-list {
  display: flex;
  list-style-type: none;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  gap: 32px;
  padding: 0;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
  }
}

.select-trigger-list-item {
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: unset;
  scroll-snap-align: center;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    max-width: 337px;
  }
}

.select-trigger-button {
  height: 100%;
  width: 100%;
  text-align: left;
  border-radius: var(--border-radius-small);
  border: 2px solid var(--gray);
  padding: 18px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.select-trigger-button {
  &.is-active {
    border: 2px solid var(--ui-green);
    color: var(--ui-green);
    box-shadow: var(--box-shadow);
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    &:hover {
      border: 2px solid var(--ui-green);
      color: var(--ui-green);
      box-shadow: var(--box-shadow);
    }
  }
}

.trigger-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0px;
  }

  .trigger-icon {
    height: 32px;
    width: 32px;
    font-size: 30px;
    color: inherit;
    background: url('@/assets/backgrounds/icon-background.png') center/contain no-repeat;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      height: 60px;
      width: 60px;
    }
  }

  .trigger-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0px;
  }
}

.trigger-description {
  font-size: 16px;
  line-height: 24px;
  min-height: 90px;
  margin-bottom: 0;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    font-size: 18px;
  }
}

.notification-text {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 8px;
  background-color: var(--ui-beige);
  padding: 0 6px;
  color: var(--font-color-primary);
}

.markers {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 24px 0 9px;
  gap: 16px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    display: none;
  }

  .marker-dot {
    height: 10px;
    width: 10px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid var(--gray-light-A9);
    transition: all ease 0.3s;

    &.visible {
      background-color: var(--ui-dark-gray);
      border: none;
    }
  }
}

.form-controls {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
</style>
