import type { SustainabilityGoal } from '@/helpers/interfaces'
import type { CustomIntegrationTrigger } from '@api/index'
import {
  deleteSustainabilityGoal,
  fetchAccount,
  fetchDefaults,
  fetchDomains,
  getEmailTemplates,
  getImpactActions,
  getSustainabilityGoals,
  saveSustainabilityGoal,
  updateSustainabilityGoal,
} from '@api/index'
import { formatLineBreaksToWhiteSpace } from '@/helpers/parsers'
import type { ActionContext } from 'vuex'
import type { StateInterface } from '@/store/index'
import {
  BILLING_AUTOMATION_TRIGGERS,
  FORM_AUTOMATION_TRIGGERS,
  STORE_AUTOMATION_TRIGGERS,
} from '@/helpers/interfaces'

export const TEMPLATE_TRIGGER = [
  ...STORE_AUTOMATION_TRIGGERS.filter((trigger) => trigger !== 'offsetAllProducts'),
  'offsetPerSubscription',
  'offsetPerReview',
  ...FORM_AUTOMATION_TRIGGERS,
  ...BILLING_AUTOMATION_TRIGGERS,
]

export type CustomNotificationTemplateTriggerType = CustomIntegrationTrigger['name']
export type TemplateTrigger =
  | (typeof TEMPLATE_TRIGGER)[number]
  | CustomNotificationTemplateTriggerType

export const TEMPLATE_TYPE = ['invitation', 'update', 'nudge']
export type TemplateType = (typeof TEMPLATE_TYPE)[number]

export const CUSTOM_DOMAIN_STATUSES = ['active', 'deleted', 'error'] as const
export type CustomDomainStatus = (typeof CUSTOM_DOMAIN_STATUSES)[number]

export const EMAIL_TEMPLATE_STATUSES = ['active', 'deleted', 'disabled'] as const
export type EmailTemplateStatus = (typeof EMAIL_TEMPLATE_STATUSES)[number]

export interface DnsRecord {
  valid: boolean
  type: string
  host: string
  data: string
}

export interface Dns {
  mailCname: DnsRecord
  dkim1: DnsRecord
  dkim2: DnsRecord
}

export interface DnsRecordStatus {
  valid: boolean
  reason: string
}

export interface DnsStatus {
  mailCname: DnsRecordStatus
  dkim1: DnsRecordStatus
  dkim2: DnsRecordStatus
}

export interface DomainStatus {
  status: CustomDomainStatus
  initiatedAt: Date
  initiatedBy: string
  description?: string
}

export interface DomainValidationStatus {
  valid: boolean
  dns: DnsStatus
  initiatedAt: Date
  initiatedBy: string
  description?: string
}

export interface Domain {
  id: string
  domain: string
  dns: Dns
  status?: DomainStatus
  validationStatus?: DomainValidationStatus
}

export interface DomainVerification extends Domain {
  valid: boolean
}

export interface EmailTemplateContent {
  headerImage: string
  body: string
  isFooterHidden: boolean
  isTotalImpactHidden: boolean
}

interface EmailTemplateType {
  name: string
  template: TemplateType
  trigger: TemplateTrigger[] | null
  schedule: number[] | undefined
  isScheduleCustom: boolean
  isTriggerCustom: boolean
}

export interface EmailTemplateSetting {
  from: { name: string; domain: string }
  alias: string
  subject: string
  preheader: string
  schedule: { startDate: string; endDate?: string }
}

export interface IEmailTemplateStatus {
  status: EmailTemplateStatus
  initiatedAt?: Date
  initiatedBy?: string
  description: string | null
}

export interface EmailTemplate {
  id: string
  isActive: boolean
  type: EmailTemplateType
  settings: EmailTemplateSetting
  content: EmailTemplateContent
  status: IEmailTemplateStatus
}

export interface EmailTemplateSettingsWithStatus extends EmailTemplateSetting {
  isAliasCustom: boolean
  isSubjectCustom: boolean
  isPreheaderCustom: boolean
}

export interface EmailTemplateContentWithStatus extends EmailTemplateContent {
  isHeaderImageCustom: boolean
  isBodyCustom: boolean
  isFooterHiddenCustom: boolean
  isTotalImpactHiddenCustom: boolean
}

export interface EmailTemplateWithStatus extends EmailTemplate {
  isCustom: boolean
  settings: EmailTemplateSettingsWithStatus
  content: EmailTemplateContentWithStatus
}

interface CustomerEngagementState {
  sustainabilityGoals: SustainabilityGoal[]
  sustainabilitySite: string
  hasImpactActions: boolean
  savedDomainList: Domain[]
  emailTemplateList: EmailTemplateWithStatus[]
  defaultTemplates: EmailTemplateWithStatus[]
  defaultVariables: Record<string, string>
}

export const customerEngagementModule = {
  state: () =>
    ({
      sustainabilityGoals: [],
      sustainabilitySite: '',
      hasImpactActions: false,
      savedDomainList: [],
      emailTemplateList: [],
      defaultTemplates: [],
      defaultVariables: {},
    } as CustomerEngagementState),
  mutations: {
    saveSustainabilityGoal(s, sustainabilityGoal: SustainabilityGoal) {
      s.sustainabilityGoals.push(sustainabilityGoal)
    },
    setSustainabilityGoals(s, data: SustainabilityGoal[]) {
      s.sustainabilityGoals = data
    },
    deleteSustainabilityGoal(s, targetId: string) {
      const foundIndex = s.sustainabilityGoals.findIndex((goal) => goal.targetId === targetId)
      s.sustainabilityGoals.splice(foundIndex, 1)
    },
    setSustainabilitySite(s, url: string) {
      s.sustainabilitySite = url
    },
    setImpactActionsState(s, hasImpactActions: boolean) {
      s.hasImpactActions = hasImpactActions
    },
    setDomainList(s, domainList: Domain[]) {
      s.savedDomainList = domainList
    },
    setEmailTemplateList(s, emailTemplateList: EmailTemplateWithStatus[]) {
      s.emailTemplateList = emailTemplateList
    },
    setDefaultTemplates(s, defaultTemplates: EmailTemplateWithStatus[]) {
      s.defaultTemplates = defaultTemplates
    },
    setDefaultVariables(s, defaultVariables) {
      s.defaultVariables = defaultVariables
    },
  },
  actions: {
    async setSustainabilityGoals({
      commit,
    }: ActionContext<CustomerEngagementState, StateInterface>) {
      const { data } = await getSustainabilityGoals()
      const formattedData: SustainabilityGoal[] = data.map(({ title, description, _id }) => {
        return { title, description, targetId: _id }
      })
      commit('setSustainabilityGoals', formattedData)
    },
    async setSustainabilitySite({
      commit,
    }: ActionContext<CustomerEngagementState, StateInterface>) {
      const { data } = await fetchAccount()
      commit('setSustainabilitySite', data.sustainabilitySite)
    },
    async setImpactActionsState({
      commit,
    }: ActionContext<CustomerEngagementState, StateInterface>) {
      const { data } = await getImpactActions()
      commit('setImpactActionsState', !!data.length)
    },
    async setDomainList({ commit }: ActionContext<CustomerEngagementState, StateInterface>) {
      const { data } = await fetchDomains()
      commit('setDomainList', data)
    },
    async setEmailTemplateList({ commit }: ActionContext<CustomerEngagementState, StateInterface>) {
      const { data } = await getEmailTemplates()
      const emailTemplateListWithFormattedBody = data.map((emailTemplate) => {
        return {
          ...emailTemplate,
          content: {
            ...emailTemplate.content,
            body: formatLineBreaksToWhiteSpace(emailTemplate.content.body),
          },
        }
      })
      commit('setEmailTemplateList', emailTemplateListWithFormattedBody)
    },
    async setDefaultValues({ commit }: ActionContext<CustomerEngagementState, StateInterface>) {
      const { data } = await fetchDefaults()
      const defaultsWithStatus: EmailTemplateWithStatus[] = []
      data.templates.forEach((template) => {
        defaultsWithStatus.push({
          id: '',
          isActive: false,
          isCustom: false,
          settings: {
            ...template.settings,
            schedule: {
              endDate: template.settings.schedule.endDate,
              startDate: '',
            },
            isAliasCustom: false,
            isSubjectCustom: false,
            isPreheaderCustom: false,
          },
          content: {
            ...template.content,
            isHeaderImageCustom: false,
            isBodyCustom: false,
            isFooterHiddenCustom: false,
            isTotalImpactHiddenCustom: false,
            isFooterHidden: false,
            isTotalImpactHidden: false,
          },
          type: {
            ...template.type,
            name: '',
          },
          status: {
            description: null,
            status: 'disabled',
          },
        })
      })
      commit('setDefaultTemplates', defaultsWithStatus)
      commit('setDefaultVariables', data.variables)
    },
    async updateSustainabilityGoal(
      { commit, state }: ActionContext<CustomerEngagementState, StateInterface>,
      sustainabilityGoal: SustainabilityGoal,
    ) {
      const {
        data: { title, description, _id },
      } = await updateSustainabilityGoal(sustainabilityGoal)
      const updatedArray = state.sustainabilityGoals.map((goal) => {
        if (goal.targetId === _id) {
          return { title, description, targetId: _id }
        }
        return goal
      })
      commit('setSustainabilityGoals', updatedArray)
    },
    async saveSustainabilityGoal(
      { commit }: ActionContext<CustomerEngagementState, StateInterface>,
      sustainabilityGoal: SustainabilityGoal,
    ) {
      const {
        data: { title, description, _id },
      } = await saveSustainabilityGoal(sustainabilityGoal)
      commit('saveSustainabilityGoal', { title, description, targetId: _id })
    },
    async deleteSustainabilityGoal(
      { commit }: ActionContext<CustomerEngagementState, StateInterface>,
      targetId: string,
    ) {
      const {
        data: { _id },
      } = await deleteSustainabilityGoal(targetId)
      commit('deleteSustainabilityGoal', _id)
    },
  },
  getters: {
    getCustomDomainAmount: (s: CustomerEngagementState) =>
      s.savedDomainList.filter((domain) => domain.id).length,
    getActiveEmailTemplateAmount: (s: CustomerEngagementState) => {
      return s.emailTemplateList.filter(
        (emailTemplate) => emailTemplate?.status?.status === 'active',
      ).length
    },
    getCustomActiveEmailTemplateAmount: (s: CustomerEngagementState) => {
      return s.emailTemplateList.filter(
        (emailTemplate) => emailTemplate?.status?.status === 'active' && emailTemplate.isCustom,
      ).length
    },
    getIfB2B2C: (state: CustomerEngagementState, getters): boolean => {
      return state.hasImpactActions && !getters.getAccountTypeIsBusiness
    },
    getSustainabilityGoals: (s: CustomerEngagementState): SustainabilityGoal[] =>
      s.sustainabilityGoals,
    getSustainabilitySite: (s: CustomerEngagementState): string => s.sustainabilitySite,
    getHasImpactActions: (s: CustomerEngagementState): boolean => s.hasImpactActions,
    getSavedDomainList: (s: CustomerEngagementState): Domain[] => s.savedDomainList,
    getCustomerEngagementDefaultVariables: (s: CustomerEngagementState): Record<string, string> =>
      s.defaultVariables,
    getCustomerEngagementEmailTemplateList: (
      s: CustomerEngagementState,
    ): EmailTemplateWithStatus[] => s.emailTemplateList,
    getCustomerEngagementDefaultTemplates: (
      s: CustomerEngagementState,
    ): EmailTemplateWithStatus[] => s.defaultTemplates,
  },
}
