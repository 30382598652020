<template>
  <div v-if="loaded">
    <div class="header-tooltip-wrapper">
      <div class="referral-impact-header">
        {{ t('header') }}
      </div>
    </div>

    <div class="referral-subheader">
      {{ t('funded') }}
    </div>
    <div class="referral-text">
      {{ t('funded_text') }}
    </div>

    <ReferralStats
      v-if="referralsActive"
      :referrals-count="referralsNumber"
      :impacts="relationImpact"
      type="referrals"
    />

    <div class="referral-subheader" style="margin-top: 24px">
      {{ t('total') }}
    </div>
    <div class="referral-text">
      {{ t('total_text') }}
    </div>

    <ReferralStats v-if="referralsActive" :impacts="relationImpactTotal" type="referrals" />
  </div>
</template>

<script lang="ts">
import ReferralStats from '@/components/referrals/ReferralStats.vue'
import { getOverallAccountImpactFromRelations } from '@api/index'
import type { OffsetType } from '@/helpers/interfaces'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ReferralImpact',
  emits: ['getFreshDataClick'],
  components: {
    ReferralStats,
  },
  data() {
    return {
      referralsActive: true,
      referralsNumber: 0,
      relationImpact: {} as { [key in OffsetType]: number },
      relationImpactTotal: {} as { [key in OffsetType]: number },
      loaded: false,
    }
  },
  async created() {
    await this.getOverallImpact()
    this.loaded = true
  },
  methods: {
    t(key: string) {
      return this.$t(`ReferralImpact.${key}`)
    },
    async getOverallImpact() {
      const { count, relationSumImpact, relatedUserSumImpact } =
        await getOverallAccountImpactFromRelations('referral')
      this.referralsNumber = count
      this.relationImpactTotal = relatedUserSumImpact
      this.relationImpact = relationSumImpact
    },
    async onGetFreshDataChange() {
      if (this.getFreshData) {
        this.loaded = false
        await this.getOverallImpact()
        this.loaded = true
        this.$emit('getFreshDataClick')
      }
    },
  },
  props: {
    getFreshData: {
      type: Boolean,
    },
  },
  watch: {
    getFreshData: [
      {
        handler: 'onGetFreshDataChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.header-tooltip-wrapper {
  display: flex;
  // align-items: center;
}

.referral-impact-header {
  color: #212121;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 16px;
  margin-right: 5px;
  line-height: 34px;
}

.referral-subheader {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #212121;
  margin-bottom: 6px;
}

.referral-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  color: #212121;
  margin-bottom: 18px;
  max-width: 574px;
}
</style>
