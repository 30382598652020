import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    icon: "",
    size: 35,
    class: "ml-1",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClipboard()))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_icon, {
        class: "icon",
        size: 14
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.icon), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}