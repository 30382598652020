<template>
  <li class="order-list-item">
    <button class="order-list-button" @click="openPopup">
      <img
        height="60"
        width="60"
        :src="
          impactAction?.imageURL || require('@/assets/icons/greenspark-logo-vertical-green.svg')
        "
        :alt="impactAction?.sellerUsername + 'logo'"
        class="company-logo"
        loading="lazy"
      />
      <div class="impact-wrapper">
        <p class="mb-2">
          {{ impactAction?.sellerUsername || 'Greenspark' }}
        </p>
        <div class="wrapper">
          <ImpactPill
            v-for="(impact, index) in impactAction?.action.impacts"
            :key="index"
            :impact="impact"
          />
        </div>
      </div>
    </button>
  </li>
</template>

<script lang="ts">
import type { ImpactAction } from '@/helpers/interfaces'
import ImpactPill from '@/components/ImpactWallet/ImpactPill.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LatestImpactActionsListItem',
  components: { ImpactPill },
  methods: {
    t(key: string) {
      return this.$t(`LatestImpactActionsListItem.${key}`)
    },
    tc(key: string, amount: number) {
      return this.$t(`LatestImpactActionsListItem.${key}`, amount ?? 0)
    },
    openPopup() {
      this.$parent?.$parent?.$emit('open-popup', this.impactAction)
    },
  },
  props: {
    impactAction: {
      type: Object as PropType<ImpactAction>,
    },
  },
})
</script>

<style lang="scss" scoped>
.order-list-item:not(:last-of-type) {
  margin-bottom: 20px;
}

.order-list-button {
  align-items: center;
  background-color: var(--ui-beige);
  border-radius: var(--border-radius-big);
  color: var(--font-color-primary);
  display: flex;
  padding: 10px 30px 10px 10px;
  position: relative;
  text-decoration: none;
  width: 100%;
}

.order-list-button.tailored {
  padding-top: 15px;
  padding-bottom: 15px;
}

.order-list-button:hover {
  box-shadow: var(--box-shadow-light);
}

.company-logo {
  border-radius: 50%;
  margin-right: 10px;
  object-fit: contain;
}

.order-list-button.tailored .company-logo {
  height: 60px;
  width: 60px;
  margin-left: 5px;
  margin-right: 15px;
}

.impact-wrapper {
  text-align: left;
  font-weight: 600;
}

.wrapper {
  align-items: center;
  display: flex;
  gap: 5px;
}

.order-list-button::after {
  border-top: 2px solid var(--ui-green);
  border-right: 2px solid var(--ui-green);
  content: '';
  display: block;
  height: 8px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  right: 10px;
  transform: translate(-50%, -50%) rotate(40deg);
  transition: translate linear 0.2s;
  width: 8px;
}

.order-list-button:hover::after {
  opacity: 0;
  transform: translate(calc(-50% + 10px), -50%) rotate(40deg);
  transition: all linear 0.2s;
}

.order-list-button::before {
  border-top: 2px solid var(--ui-green);
  border-right: 2px solid var(--ui-green);
  content: '';
  display: block;
  height: 8px;
  opacity: 0;
  position: absolute;
  top: 50%;
  bottom: 50%;
  right: 20px;
  transform: translate(-50%, -50%) rotate(40deg);
  width: 8px;
}

.order-list-button:hover::before {
  opacity: 1;
  transform: translate(calc(-50% + 10px), -50%) rotate(40deg);
  transition: all linear 0.2s;
}
</style>
