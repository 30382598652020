import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64c0db86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "welcome-view" }
const _hoisted_2 = { class: "welcome-card" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "content-wrapper" }
const _hoisted_5 = { class: "form-wrapper" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "create-account-title" }
const _hoisted_8 = { class: "name-wrapper" }
const _hoisted_9 = { class: "name-wrapper" }
const _hoisted_10 = { class: "text-field-wrapper" }
const _hoisted_11 = { class: "name-wrapper" }
const _hoisted_12 = { class: "text-field-wrapper" }
const _hoisted_13 = {
  key: 0,
  class: "error-message"
}
const _hoisted_14 = { class: "navigation-wrapper" }
const _hoisted_15 = { class: "checkbox-wrapper" }
const _hoisted_16 = ["href"]
const _hoisted_17 = ["href"]
const _hoisted_18 = { class: "login-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_loading, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        alt: "leaf",
        class: "card_leaf",
        height: "726",
        loading: "lazy",
        src: require('@/assets/login-image.svg'),
        width: "462"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            class: "greenspark-logo",
            height: "32",
            src: require('@/assets/greenspark-logo.svg'),
            width: "160"
          }, null, 8, _hoisted_6),
          _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.t('create_account')), 1),
          _createVNode(_component_v_form, {
            modelValue: _ctx.isFormValid,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isFormValid) = $event))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.userObject.firstName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userObject.firstName) = $event)),
                  label: _ctx.t('first_name'),
                  rules: [_ctx.rules.required],
                  color: "#3B755F",
                  flat: "",
                  variant: "outlined",
                  type: "text"
                }, null, 8, ["modelValue", "label", "rules"]),
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.userObject.lastName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userObject.lastName) = $event)),
                  label: _ctx.t('last_name'),
                  rules: [_ctx.rules.required],
                  color: "#3B755F",
                  flat: "",
                  variant: "outlined",
                  type: "text"
                }, null, 8, ["modelValue", "label", "rules"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_v_text_field, {
                    id: "Email",
                    modelValue: _ctx.userObject.email,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userObject.email) = $event)),
                    label: _ctx.t('email'),
                    rules: [_ctx.rules.required, _ctx.rules.email],
                    autocomplete: "email",
                    class: "input-field",
                    color: "#3B755F",
                    disabled: "",
                    flat: "",
                    name: "Email",
                    variant: "outlined",
                    readonly: "",
                    type: "email"
                  }, null, 8, ["modelValue", "label", "rules"])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_v_text_field, {
                    id: "Password",
                    modelValue: _ctx.userObject.password,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.userObject.password) = $event)),
                    "append-inner-icon": _ctx.showPassword ? 'mdi-eye' : 'mdi-eye-off',
                    label: _ctx.t('password'),
                    rules: [_ctx.rules.required, _ctx.rules.password],
                    type: _ctx.showPassword ? 'text' : 'password',
                    autocomplete: "password",
                    class: "input-field",
                    color: "#3B755F",
                    flat: "",
                    name: "password",
                    variant: "outlined",
                    "onClick:appendInner": _cache[4] || (_cache[4] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword))
                  }, null, 8, ["modelValue", "append-inner-icon", "label", "rules", "type"])
                ])
              ]),
              (_ctx.account.emailAlreadyUsed)
                ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.t('already_account')), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(['password-hint', { error: _ctx.hasPasswordError }])
              }, _toDisplayString(_ctx.t('password_hint')), 3)
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_v_checkbox, {
              modelValue: _ctx.checkbox,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.checkbox) = $event)),
              color: "#3B755F",
              "hide-details": "",
              style: {"margin":"0"}
            }, null, 8, ["modelValue"]),
            _createVNode(_component_i18n_t, {
              class: "mb-0 terms",
              keypath: "SignupB2B2C.accept",
              tag: "p"
            }, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  href: _ctx.TERMS_CONDITIONS_URL,
                  class: "link",
                  target: "_blank"
                }, _toDisplayString(_ctx.t('terms')), 9, _hoisted_16),
                _createElementVNode("a", {
                  href: _ctx.PRIVACY_POLICY_URL,
                  class: "link",
                  target: "_blank"
                }, _toDisplayString(_ctx.t('privacy')), 9, _hoisted_17)
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_v_btn, {
            disabled: _ctx.disabled,
            class: "button",
            color: "#3B755F",
            height: "44",
            onClick: _ctx.signup
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('create_account')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createElementVNode("p", _hoisted_18, [
            _createElementVNode("span", null, _toDisplayString(_ctx.t('login_text')), 1),
            _createVNode(_component_router_link, {
              class: "login-link ml-1",
              to: "/login"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('login_link')), 1)
              ]),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}