import type { StateInterface } from '@/store/index'
import type { ActionContext } from 'vuex'
import type {
  CreateFormQuestionRequestBody,
  FormAnswerDto,
  FormQuestionDto,
  UpdateFormAnswerDto,
  UpdateFormQuestionRequestBody,
} from '@api/index'
import { createFormQuestion, fetchFormQuestionByAutomationId, updateFormQuestion } from '@api/index'
import VueI18n from '@/i18n'
import type { Notification } from '@/store/notification'
import type { AutomationOffset, AutomationTableData } from '@/store/integrations'

export interface FormAnswer extends FormAnswerDto {
  offsets: AutomationOffset[]
}

export interface FormQuestion extends Omit<FormQuestionDto, 'answers'> {
  answers: FormAnswer[]
}

export interface CreateFormQuestionRequestPayload {
  question: string
  answers: {
    answer: string
    offsets: AutomationOffset[]
  }[]
}

export interface UpdateFormQuestionRequestPayload {
  id: string
  question: string
  answers: {
    id: string | undefined
    answer: string
    offsets: AutomationOffset[]
  }[]
}

export interface FormQuestionModuleState {
  formQuestion: FormQuestion
}

const defaultFormQuestion = { id: '', question: '', answers: [] }

export const formQuestionModule = {
  state: () =>
    ({
      formQuestion: { ...defaultFormQuestion },
    } as FormQuestionModuleState),
  mutations: {
    setFormQuestion(state: FormQuestionModuleState, formQuestion: FormQuestion) {
      state.formQuestion = formQuestion
    },
  },
  actions: {
    async loadFormQuestion(
      { commit, dispatch }: ActionContext<FormQuestionModuleState, StateInterface>,
      payload: { automationTableData: AutomationTableData },
    ) {
      commit('setLoading', true)
      try {
        const { data } = await fetchFormQuestionByAutomationId(payload.automationTableData.id)
        const answers = data.answers.map(({ id, answer }) => {
          const offsets = payload.automationTableData.offsets
            .filter(({ source }) => source?.ids?.includes(id))
            .map(({ amount, type, projectId }) => ({ amount, type, projectId }))
          return { id, answer, offsets }
        })
        const loadedFormQuestion = { ...data, answers }
        commit('setFormQuestion', loadedFormQuestion)
        return loadedFormQuestion
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      } finally {
        commit('setLoading', false)
      }
    },
    setFormQuestion(
      { commit }: ActionContext<FormQuestionModuleState, StateInterface>,
      payload: { formQuestion: FormQuestion },
    ) {
      commit('setFormQuestion', payload.formQuestion)
    },
    setFormQuestionOffsets(
      { commit, state }: ActionContext<FormQuestionModuleState, StateInterface>,
      payload: { offsets: AutomationOffset[] },
    ) {
      const answers = state.formQuestion.answers.map((answer) => {
        answer.offsets = payload.offsets.filter((offset) => offset.source?.ids.includes(answer.id))
        return answer
      })
      commit('setFormQuestion', { ...state.formQuestion, answers })
    },
    async createFormQuestion(
      { commit, dispatch }: ActionContext<FormQuestionModuleState, StateInterface>,
      payload: { createFormQuestionRequestPayload: CreateFormQuestionRequestPayload },
    ) {
      commit('setLoading', true)
      try {
        const { question, answers } = payload.createFormQuestionRequestPayload
        const requestBody: CreateFormQuestionRequestBody = {
          question,
          answers: answers.map(({ answer }) => answer),
        }
        const { data } = await createFormQuestion(requestBody)
        const answersToSave = data.answers.map(({ id, answer }) => {
          const offsets = answers.find((a) => a.answer === answer)?.offsets || []
          return { id, answer, offsets }
        })
        commit('setFormQuestion', { ...data, answers: answersToSave })
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      } finally {
        commit('setLoading', false)
      }
    },
    async updateFormQuestion(
      { commit, dispatch }: ActionContext<FormQuestionModuleState, StateInterface>,
      payload: { updateFormQuestionRequestPayload: UpdateFormQuestionRequestPayload },
    ) {
      commit('setLoading', true)
      try {
        const { id, question, answers } = payload.updateFormQuestionRequestPayload
        const requestBody: UpdateFormQuestionRequestBody = {
          question,
          answers: answers.map(({ id, answer }) => ({ id, answer } as UpdateFormAnswerDto)),
        }
        const { data } = await updateFormQuestion(id, requestBody)
        const answersToSave = data.answers.map(({ id, answer }) => {
          const offsets = answers.find((a) => a.answer === answer)?.offsets || []
          return { id, answer, offsets }
        })
        const updatedFormQuestion = { ...data, answers: answersToSave }
        commit('setFormQuestion', updatedFormQuestion)
        return updatedFormQuestion
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      } finally {
        commit('setLoading', false)
      }
    },
    resetFormQuestion({ commit }: ActionContext<FormQuestionModuleState, StateInterface>) {
      commit('setFormQuestion', { ...defaultFormQuestion })
    },
  },
  getters: {
    getFormQuestion: (state: FormQuestionModuleState): FormQuestion => state.formQuestion,
  },
}
