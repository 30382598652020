import * as Sentry from '@sentry/vue'
import App from './App.vue'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
import filters from '@/helpers/filters'
import vuetify from './plugins/vuetify'
import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
import Panel from '@/components/common/Panel.vue'
import VueObserveVisibility from 'vue3-observe-visibility'
import GsButton from '@/components/form/gs-button.vue'
import GsInput from '@/components/form/gs-input.vue'
import NavigateBack from '@/components/common/NavigateBack.vue'
import Vue3Lottie from 'vue3-lottie'
import ObserveIntersection from '@/custom-directives/observe-intersection'
import { createApp } from 'vue'
import mitt from 'mitt'

window.$crisp = window.$crisp || []

type Events = {
  planSelected: { planType: string }
  openComparisonPopup?: () => void
  reference: undefined
}

// eventBus to handle global events, notifications, listening in the App.vue
export const eventBus = mitt<Events>()
export const app = createApp(App)

const accountId = store.state.account.accountId
const socket = io(String(process.env.VUE_APP_URL), {
  query: {
    token: accountId,
  },
})

window.beamer_config = {
  product_id: `${process.env.VUE_APP_BEAMER_PRODUCT_ID}`,
  selector: '.beamer-trigger-button',
  button: false,
  lazy: false,
  alert: true,
  bounce: false,
}

app.use(VueSocketIOExt, socket)

// custom directives
app.use(VueObserveVisibility)

// global components
app.component('v-panel', Panel)
app.component('gs-button', GsButton)
app.component('gs-input', GsInput)
app.component('gs-back', NavigateBack)

app.directive('observe-intersection', ObserveIntersection)

app.mixin(filters)

Sentry.init({
  app,
  trackComponents: true,
  hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
  dsn: 'https://4253d403343f40c282f3014f2c2a6006@o991967.ingest.sentry.io/5949259',
  enabled: ['app.getgreenspark.com', 'dev.getmads.com'].includes(window.location.hostname),
  environment: process.env.VUE_APP_ENVIRONMENT,
  tracePropagationTargets: ['localhost', /^https:\/\/app\.getgreenspark\.com/, /^\//],
  integrations: [
    Sentry.browserTracingIntegration({
      router,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  // ignore errors that match any of the following
  ignoreErrors: [
    'Non-Error promise rejection captured with value: Object Not Found Matching Id',
    'Redirected when going from "/direct-signup',
    'Avoided redundant navigation to current location',
  ],
})

app.use(Vue3Lottie, { name: 'Vue3Lottie' })
app.use(store)
app.use(router)
app.use(i18n)
app.use(vuetify)
app.mount('#app')
