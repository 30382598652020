<template>
  <div v-if="loaded" class="signup">
    <div class="signup-wrapper">
      <ChoosePersonalPlan
        v-if="progressItem === 0"
        key="0"
        :account="account"
        :earth-hero-price="earthHeroPrice"
        :personal-price="personalPrice"
        :plastic-hero-price="plasticHeroPrice"
        @nextClick="progressItem++"
      />

      <AddOnsPersonal
        v-if="progressItem === 1"
        key="1"
        @backClick="progressItem--"
        @nextClick="progressItem++"
      />

      <UserInfo1
        v-if="progressItem === 2"
        key="2"
        @backClick="progressItem--"
        @nextClick="progressItem++"
      />

      <Signup1
        v-if="progressItem === 3"
        key="3"
        @backClick="progressItem--"
        @checkoutClick="checkout()"
        @setLineItemsClick="setLineItems()"
      />
      <div class="totals-wrapper">
        <TotalsPersonal
          :earth-hero-price="earthHeroPrice"
          :personal-price="personalPrice"
          :plastic-hero-price="plasticHeroPrice"
          :progress-item="progressItem"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { startCheckout } from '@api/index'
import ChoosePersonalPlan from '@/components/register/ChoosePersonalPlan.vue'
import AddOnsPersonal from '@/components/register/AddOnsPersonal.vue'
import UserInfo1 from '@/components/register/UserInfo1.vue'
import Signup1 from '@/components/register/Signup1.vue'
import TotalsPersonal from '@/components/register/TotalsPersonal.vue'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import type { Account } from '@/store'
import type { StripeURLs } from '@/store'
import type { Currency, Emptyable } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { Stripe } from '@stripe/stripe-js/dist/stripe-js'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SignupPersonalView',
  components: {
    ChoosePersonalPlan,
    AddOnsPersonal,
    UserInfo1,
    Signup1,
    TotalsPersonal,
  },
  mixins: [LoadCrispMixin, Utils],
  data() {
    return {
      progressItem: 0,
      lineItems: [],
      stripe: null,
      currency: '',
      location: null,
      personalPrice: 0,
      plasticHeroPrice: 0,
      earthHeroPrice: 0,
      loaded: false,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      sessionId: '',
    } as {
      progressItem: number
      lineItems: {
        price: string
        quantity: number
        tax_rates?: string[]
      }[]
      stripe: Stripe | null
      currency: Emptyable<Currency>
      location: string | null
      personalPrice: number
      plasticHeroPrice: number
      earthHeroPrice: number
      loaded: boolean
      publishableKey: string
      sessionId: string
    }
  },
  async created() {
    try {
      this.location = await this.getLocation()
    } catch (error) {
      this.location = ''
    }
    this.currency = this.getCurrencyByLocation(this.location)
    this.setPrices()
    this.toggleChatBubbleVisibility(true)
    this.loaded = true
  },
  methods: {
    t(key: string) {
      return this.$t(`SignupPersonalView.${key}`)
    },
    setPrices() {
      switch (this.currency) {
        case 'euro':
          this.personalPrice = 6.0
          this.plasticHeroPrice = 11.5
          this.earthHeroPrice = 23.0
          break
        case 'pound':
          this.personalPrice = 4.8
          this.plasticHeroPrice = 9.2
          this.earthHeroPrice = 18.6
          break
        case 'dollar':
          this.personalPrice = 6.5
          this.plasticHeroPrice = 12.5
          this.earthHeroPrice = 25.0
          break
      }

      this.setAccount({ currency: this.getCurrencyByLocation(this.location ?? '') })
    },
    setLineItems() {
      if (this.progressItem !== 3) {
        this.lineItems = []
      } else {
        const personal = this.account.personalPlans.personal || 0

        const plasticHero = this.account.personalPlans.plasticHero || 0

        const earthHero = this.account.personalPlans.earthHero || 0

        let treeAddon = this.account.personalPlanAddons.treeAddon
        treeAddon = treeAddon === null ? 0 : treeAddon

        let plasticAddon = this.account.personalPlanAddons.plasticAddon
        plasticAddon = plasticAddon === null ? 0 : plasticAddon

        this.lineItems = []

        if (process.env.VUE_APP_ENVIRONMENT !== 'production') {
          switch (this.account.currency) {
            case 'euro':
              //   // personal quantity
              if (
                this.account.personalPlans.personal !== ' ' &&
                this.account.personalPlans.personal !== '' &&
                this.account.personalPlans.personal !== null
              ) {
                const payload = {
                  price: 'price_1IvjAlHinkcuNuApeBcMPNfn', // price id from dashboard
                  quantity: personal,
                }
                this.lineItems.push(payload)
              }

              // plasticHero quantity
              if (this.account.personalPlans.plasticHero) {
                const payload = {
                  price: 'price_1IvjC5HinkcuNuApp0dE08EF', // price id from dashboard
                  quantity: plasticHero,
                }
                this.lineItems.push(payload)
              }

              // earthHero quantity
              if (this.account.personalPlans.earthHero) {
                const payload = {
                  price: 'price_1IvjCiHinkcuNuAptW1tjsD2', // price id from dashboard
                  quantity: earthHero,
                }
                this.lineItems.push(payload)
              }

              // treeAddon quantity
              if (this.account.personalPlanAddons.treeAddon) {
                const payload = {
                  price: 'price_1IwNrkHinkcuNuAp3Ac7Oub1', // price id from dashboard
                  quantity: treeAddon,
                }
                this.lineItems.push(payload)
              }

              // plasticAddon quantity
              if (this.account.personalPlanAddons.plasticAddon) {
                const payload = {
                  price: 'price_1IwNqNHinkcuNuApDWScFTPA', // price id from dashboard
                  quantity: plasticAddon,
                }
                this.lineItems.push(payload)
              }
              break
            case 'pound':
              //   // personal quantity
              if (
                this.account.personalPlans.personal !== ' ' &&
                this.account.personalPlans.personal !== '' &&
                this.account.personalPlans.personal !== null
              ) {
                const payload = {
                  price: 'price_1IvjAlHinkcuNuApVdw28yaP', // price id from dashboard
                  quantity: personal,
                  tax_rates: ['txr_1IkFArHinkcuNuApWZpkvxIF'],
                }
                this.lineItems.push(payload)
              }

              // plasticHero quantity
              if (this.account.personalPlans.plasticHero) {
                const payload = {
                  price: 'price_1IvjC5HinkcuNuApUt7TvYpv', // price id from dashboard
                  quantity: plasticHero,
                  tax_rates: ['txr_1IkFArHinkcuNuApWZpkvxIF'],
                }
                this.lineItems.push(payload)
              }

              // earthHero quantity
              if (this.account.personalPlans.earthHero) {
                const payload = {
                  price: 'price_1IvjCiHinkcuNuApgOwE20VI', // price id from dashboard
                  quantity: earthHero,
                  tax_rates: ['txr_1IkFArHinkcuNuApWZpkvxIF'],
                }
                this.lineItems.push(payload)
              }

              // treeAddon quantity
              if (this.account.personalPlanAddons.treeAddon) {
                const payload = {
                  price: 'price_1IwNrkHinkcuNuApEEwGUwpG', // price id from dashboard
                  quantity: treeAddon,
                  tax_rates: ['txr_1IkFArHinkcuNuApWZpkvxIF'],
                }
                this.lineItems.push(payload)
              }

              // plasticAddon quantity
              if (this.account.personalPlanAddons.plasticAddon) {
                const payload = {
                  price: 'price_1IwNqNHinkcuNuApYKUOpbJX', // price id from dashboard
                  quantity: plasticAddon,
                  tax_rates: ['txr_1IkFArHinkcuNuApWZpkvxIF'],
                }
                this.lineItems.push(payload)
              }
              break
            case 'dollar':
              //   // personal quantity
              if (
                this.account.personalPlans.personal !== ' ' &&
                this.account.personalPlans.personal !== '' &&
                this.account.personalPlans.personal !== null
              ) {
                const payload = {
                  price: 'price_1IvjAlHinkcuNuApLYzwP0C5', // price id from dashboard
                  quantity: personal,
                }
                this.lineItems.push(payload)
              }

              // plasticHero quantity
              if (this.account.personalPlans.plasticHero) {
                const payload = {
                  price: 'price_1IvjC5HinkcuNuApmiCqNzKT', // price id from dashboard
                  quantity: plasticHero,
                }
                this.lineItems.push(payload)
              }

              // earthHero quantity
              if (this.account.personalPlans.earthHero) {
                const payload = {
                  price: 'price_1IvjCiHinkcuNuApIBik99zz', // price id from dashboard
                  quantity: earthHero,
                }
                this.lineItems.push(payload)
              }

              // treeAddon quantity
              if (this.account.personalPlanAddons.treeAddon) {
                const payload = {
                  price: 'price_1IwNrkHinkcuNuApodHvgr9M', // price id from dashboard
                  quantity: treeAddon,
                }
                this.lineItems.push(payload)
              }

              // plasticAddon quantity
              if (this.account.personalPlanAddons.plasticAddon) {
                const payload = {
                  price: 'price_1IwNqNHinkcuNuAp0dCI93h8', // price id from dashboard
                  quantity: plasticAddon,
                }
                this.lineItems.push(payload)
              }
              break
          }
        } else if (process.env.VUE_APP_ENVIRONMENT === 'production') {
          switch (this.account.currency) {
            case 'euro':
              //   // personal quantity
              if (
                this.account.personalPlans.personal !== ' ' &&
                this.account.personalPlans.personal !== '' &&
                this.account.personalPlans.personal !== null
              ) {
                const payload = {
                  price: 'price_1J2FB0HinkcuNuAplGJ1NJVo', // price id from dashboard
                  quantity: personal,
                }
                this.lineItems.push(payload)
              }

              // plasticHero quantity
              if (this.account.personalPlans.plasticHero) {
                const payload = {
                  price: 'price_1J2F9JHinkcuNuApCdEunpoS', // price id from dashboard
                  quantity: plasticHero,
                }
                this.lineItems.push(payload)
              }

              // earthHero quantity
              if (this.account.personalPlans.earthHero) {
                const payload = {
                  price: 'price_1J2F7THinkcuNuApGjSBHSm6', // price id from dashboard
                  quantity: earthHero,
                }
                this.lineItems.push(payload)
              }

              // treeAddon quantity
              if (this.account.personalPlanAddons.treeAddon) {
                const payload = {
                  price: 'price_1J2Dp8HinkcuNuApntTnFoq3', // price id from dashboard
                  quantity: treeAddon,
                }
                this.lineItems.push(payload)
              }

              // plasticAddon quantity
              if (this.account.personalPlanAddons.plasticAddon) {
                const payload = {
                  price: 'price_1J2DvxHinkcuNuApAY0Zsf5a', // price id from dashboard
                  quantity: plasticAddon,
                }
                this.lineItems.push(payload)
              }
              break
            case 'pound':
              //   // personal quantity
              if (
                this.account.personalPlans.personal !== ' ' &&
                this.account.personalPlans.personal !== '' &&
                this.account.personalPlans.personal !== null
              ) {
                const payload = {
                  price: 'price_1J2FB0HinkcuNuApKNtN47KS', // price id from dashboard
                  quantity: personal,
                  tax_rates: ['txr_1J2CwcHinkcuNuApZx7IKjFV'],
                }
                this.lineItems.push(payload)
              }

              // plasticHero quantity
              if (this.account.personalPlans.plasticHero) {
                const payload = {
                  price: 'price_1J2F9IHinkcuNuApDiQh7SkK', // price id from dashboard
                  quantity: plasticHero,
                  tax_rates: ['txr_1J2CwcHinkcuNuApZx7IKjFV'],
                }
                this.lineItems.push(payload)
              }

              // earthHero quantity
              if (this.account.personalPlans.earthHero) {
                const payload = {
                  price: 'price_1J2F7THinkcuNuApyrtvGszw', // price id from dashboard
                  quantity: earthHero,
                  tax_rates: ['txr_1J2CwcHinkcuNuApZx7IKjFV'],
                }
                this.lineItems.push(payload)
              }

              // treeAddon quantity
              if (this.account.personalPlanAddons.treeAddon) {
                const payload = {
                  price: 'price_1J2Dp8HinkcuNuApLQR2nBC4', // price id from dashboard
                  quantity: treeAddon,
                  tax_rates: ['txr_1J2CwcHinkcuNuApZx7IKjFV'],
                }
                this.lineItems.push(payload)
              }

              // plasticAddon quantity
              if (this.account.personalPlanAddons.plasticAddon) {
                const payload = {
                  price: 'price_1J2DvxHinkcuNuApYWllnPD3', // price id from dashboard
                  quantity: plasticAddon,
                  tax_rates: ['txr_1J2CwcHinkcuNuApZx7IKjFV'],
                }
                this.lineItems.push(payload)
              }
              break
            case 'dollar':
              //   // personal quantity
              if (
                this.account.personalPlans.personal !== ' ' &&
                this.account.personalPlans.personal !== '' &&
                this.account.personalPlans.personal !== null
              ) {
                const payload = {
                  price: 'price_1J2FB0HinkcuNuApAM0tXrtf', // price id from dashboard
                  quantity: personal,
                }
                this.lineItems.push(payload)
              }

              // plasticHero quantity
              if (this.account.personalPlans.plasticHero) {
                const payload = {
                  price: 'price_1J2F9IHinkcuNuApjf0pMiaV', // price id from dashboard
                  quantity: plasticHero,
                }
                this.lineItems.push(payload)
              }

              // earthHero quantity
              if (this.account.personalPlans.earthHero) {
                const payload = {
                  price: 'price_1J2F7THinkcuNuAp56NCmKgK', // price id from dashboard
                  quantity: earthHero,
                }
                this.lineItems.push(payload)
              }

              // treeAddon quantity
              if (this.account.personalPlanAddons.treeAddon) {
                const payload = {
                  price: 'price_1J2Dp8HinkcuNuApp93vTG6e', // price id from dashboard
                  quantity: treeAddon,
                }
                this.lineItems.push(payload)
              }

              // plasticAddon quantity
              if (this.account.personalPlanAddons.plasticAddon) {
                const payload = {
                  price: 'price_1J2DvxHinkcuNuApKkS8lvUj', // price id from dashboard
                  quantity: plasticAddon,
                }
                this.lineItems.push(payload)
              }
              break
          }
        }

        for (let i = 0; i < this.lineItems.length; i++) {
          if (this.lineItems[i].quantity === 0) {
            this.lineItems.splice(i, 1)
            i--
          }
        }
      }
    },
    async checkout() {
      this.setLineItems()

      if (!this.account.emailAlreadyUsed && this.account.signupComplete) {
        const payload = {
          success_url: this.stripeURLs.successURLPersonal,
          cancel_url: this.stripeURLs.cancelURLPersonal,
          payment_method_types: ['card'],
          line_items: this.lineItems.filter((v) => v.quantity > 0),
          mode: 'subscription',
          client_reference_id:
            this.currentUser.firstName +
            '-' +
            this.currentUser.lastName +
            `${Math.floor(Math.random() * 11)}`,
          customer_email: this.currentUser.email,
        }

        const { data } = await startCheckout(payload)
        const stripeSession = data
        this.sessionId = stripeSession.id
        this.setSignupId(this.sessionId)

        // this.$refs.checkoutRef.redirectToCheckout()

        this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY ?? '')
        this.stripe.redirectToCheckout({ sessionId: this.sessionId })
      }
    },
    onPersonalPlanChange() {
      this.setLineItems()
    },
    onPlasticHeroPlanChange() {
      this.setLineItems()
    },
    onEarthHeroPlanChange() {
      this.setLineItems()
    },
    setSignupId(id) {
      return this.$store.commit('setSignupId', id)
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
  },
  watch: {
    'account.personalPlans.personal': [
      {
        handler: 'onPersonalPlanChange',
      },
    ],
    'account.personalPlans.plasticHero': [
      {
        handler: 'onPlasticHeroPlanChange',
      },
    ],
    'account.personalPlans.earthHero': [
      {
        handler: 'onEarthHeroPlanChange',
      },
    ],
  },
  computed: {
    stripeURLs(): StripeURLs {
      return this.$store.getters['getStripeURLs']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.signup {
  padding: 10vh 2%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url('../../assets/backgrounds/signup-background.svg');
  background-size: cover;
  background-position: center;
}

.signup-wrapper {
  max-width: 1300px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.totals-wrapper {
  margin-left: 18px;
}

.signup-form {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 20px 60px;
  background: #f9f9f9;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
}

.logo-wrapper {
  max-width: 220px;
}

.logo {
  max-width: 100%;
}

.header {
  color: #212121;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 22px;
}

.input-field {
  margin-bottom: 12px;
  width: 70%;
}

.button {
  margin-top: 15px;
}

.already-member {
  margin-top: 15px;
  font-size: 14px;
  color: #212121;
}

@media #{map-get($display-breakpoints, 'md')} {
  .totals-wrapper {
    width: 100%;
    margin: 0;
    z-index: 2;
  }

  .signup {
    padding: 40px;
    flex-direction: column;
    // min-height: unset;
    max-height: unset;
    min-height: 100vh;
  }

  .signup-wrapper {
    flex-direction: column;
    height: 100%;
  }

  .totals-wrapper {
    display: flex;
    justify-content: center;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .signup {
    padding: 0;
    flex-direction: column;
    min-height: unset;
    max-height: unset;
  }

  .signup-wrapper {
    flex-direction: column;
    // min-height: unset;
    max-height: unset;
    min-height: 100vh;
    height: 100%;
  }

  .totals-wrapper {
    margin: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
