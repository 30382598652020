<template>
  <div :class="['widget-wrapper', { 'cart-based': trigger === 'offsetPerCurrentCart' }]">
    <slot name="widget"></slot>
    <WidgetDialog :mode="dialogMode" :type="trigger" :widget-api-key="apiKey" :color="color">
      <slot name="widget" v-if="trigger !== 'banner'"></slot>
    </WidgetDialog>
  </div>
</template>
<script lang="ts">
import WidgetDialog from '@/components/shareables/WidgetDialog.vue'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { defineComponent } from 'vue'

import type { BrandColor, CurrencyCode, StoreIntegrationTrigger } from '@/helpers/interfaces'
import type { DialogMode } from '@/components/shareables/WidgetDialog.vue'
import type { PropType } from 'vue'
import type { ApiKeyResponse } from '@api/index'

export default defineComponent({
  name: 'WidgetPreview',
  components: {
    WidgetDialog,
  },
  mixins: [LoadCrispMixin],
  computed: {
    dialogMode(): DialogMode {
      switch (this.trigger) {
        case 'offsetPerProduct':
          return 'product'
        case 'banner':
          return 'banner'
        default:
          return 'widget'
      }
    },
    currency(): CurrencyCode {
      return this.$store.getters['getUserCurrencyCode']
    },
  },
  props: {
    trigger: {
      type: String as PropType<StoreIntegrationTrigger | 'offsetPerCurrentCart' | 'banner'>,
      required: false,
    },
    color: {
      type: String as PropType<BrandColor>,
      required: false,
    },
    apiKey: {
      type: Object as PropType<ApiKeyResponse>,
      required: false,
    },
    tutorialMode: {
      type: Object as PropType<DialogMode>,
      required: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.widget-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 36px 0;
  margin: 12px;
  flex: 0 1 100%;
  transition: all 0.3s ease;

  @media screen and (min-width: 620px) {
    flex: 0 1 calc(50% - 24px);
  }

  @media screen and (min-width: 1044px) {
    flex: 0 1 calc((100% / 3) - 24px);
  }

  &.cart-based .widget {
    width: auto;
  }
}

.widget-wrapper:hover {
  transform: translateY(-3px);
  transition: all 0.3s ease;
}
</style>
