<template>
  <LoaderBlock v-if="loading" />
  <v-panel v-else padding="small">
    <template v-slot:title>
      {{ t('latest_orders') }}
    </template>
    <ul class="latest-order-list">
      <LatestImpactActionsListItem
        v-for="impactAction in impactActions"
        :key="impactAction._id"
        :impact-action="impactAction"
      />
    </ul>
    <v-btn
      class="see-all button"
      height="56"
      width="100%"
      @click="$emit('impact-actions', 'impact_actions')"
    >
      {{ t('see_all') }}
    </v-btn>
  </v-panel>
</template>

<script lang="ts">
import LatestImpactActionsListItem from './LatestImpactActionsListItem.vue'
import LoaderBlock from '@/components/tools/LoaderBlock.vue'
import type { ImpactAction } from '@/helpers/interfaces'
import { getImpactActions } from '@api/index'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LatestImpactActionsList',
  emits: ['impact-actions'],
  components: {
    LatestImpactActionsListItem,
    LoaderBlock,
  },
  data() {
    const impactActions: ImpactAction[] = []

    return {
      impactActions,
      loading: false,
    }
  },
  async created() {
    this.loading = true
    const impactAction = await getImpactActions()
    this.impactActions = impactAction.data
    this.loading = false
  },
  methods: {
    t(key: string) {
      return this.$t(`LatestImpactActionsList.${key}`)
    },
  },
})
</script>

<style lang="scss" scoped>
.latest-order-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.see-all.button {
  border: 2px solid var(--ui-green);
  background-color: transparent;
  color: var(--ui-green);
}
</style>
