<template>
  <div class="submenu-tile">
    <h3 class="tile-header">
      {{ header }}
    </h3>
    <div class="text-wrapper">
      <p class="info-text">
        {{ text }}
      </p>
      <gs-button size="large" width="200" @click="actionClick">
        {{ buttonText }}
      </gs-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SubMenuTile',
  emits: ['actionClick'],
  methods: {
    t(key: string) {
      return this.$t(`SubMenuTile.${key}`)
    },
    actionClick() {
      this.$emit('actionClick')
    },
  },
  props: {
    header: { type: String as () => string },
    text: { type: String as () => string },
    buttonText: { type: String as () => string },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.submenu-tile {
  background: var(--ui-white);
  border-radius: 5px;
  padding: 20px;
  box-shadow: var(--box-shadow);
  margin: 15px 0 25px;
}

.tile-header {
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-text {
  font-size: 16px;
  text-align: center;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .tile-header {
    text-align: left;
  }

  .info-text {
    text-align: left;
    max-width: 52ch;
  }

  .text-wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
}
</style>
