<template>
  <div v-if="loaded" class="signup">
    <div class="signup-wrapper">
      <SignupPreInfo v-if="progressItem === 0" :error="errorMessage" @nextClick="progressItem++" />

      <SignupInfoPartners
        v-if="progressItem === 1"
        :relations-id="relationsId"
        :user-objects="userObject"
        @backClick="progressItem--"
      />
    </div>
  </div>
</template>

<script lang="ts">
import SignupInfoPartners from '@/components/register/SignupInfoPartners.vue'
import SignupPreInfo from '@/components/register/SignupPreInfo.vue'
import { getRelationDetailsForSignUp } from '@api/index'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SignupPartnerView',
  components: {
    SignupPreInfo,
    SignupInfoPartners,
  },
  mixins: [LoadCrispMixin],
  data() {
    return {
      progressItem: 0,
      loaded: false,
      userObject: {
        firstName: '',
        lastName: '',
        vat: '',
        companyName: '',
        name: '',
        email: '',
        password: '',
      },
      errorMessage: false,
    }
  },
  computed: {
    relationsId(): string {
      return `${this.$route.params.id}`
    },
  },
  async created() {
    await this.getInfo()
    this.loaded = true
    this.toggleChatBubbleVisibility(true)
  },
  methods: {
    t(key: string) {
      return this.$t(`SignupPartnerView.${key}`)
    },
    async getInfo() {
      try {
        const {
          data: { email, firstName, lastName },
        } = await getRelationDetailsForSignUp(this.relationsId)
        this.userObject.firstName = firstName
        this.userObject.lastName = lastName
        this.userObject.email = email
      } catch (error) {
        this.errorMessage = true
        console.error('error', error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.signup {
  padding: 10vh 2%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url('../../assets/backgrounds/signup-background.svg');
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  // margin-left: -30px;
  z-index: 4;
  display: flex;
}

.signup-wrapper {
  max-width: 1300px;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 640px;
}

.totals-wrapper {
  margin-left: 18px;
}

.signup-form {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 20px 60px;
  background: #f9f9f9;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
}

.logo-wrapper {
  max-width: 220px;
}

.logo {
  max-width: 100%;
}

.header {
  color: #212121;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 22px;
}

.input-field {
  margin-bottom: 12px;
  width: 70%;
}

.button {
  margin-top: 15px;
}

.already-member {
  margin-top: 15px;
  font-size: 14px;
  color: #212121;
}

@media #{map-get($display-breakpoints, 'md')} {
  .totals-wrapper {
    width: 100%;
    margin: 0;
    z-index: 2;
  }

  .signup {
    padding: 40px;
    flex-direction: column;
    // min-height: unset;
    max-height: unset;
    min-height: 100vh;
  }

  .signup-wrapper {
    flex-direction: column;
    height: 100%;
  }

  .totals-wrapper {
    display: flex;
    justify-content: center;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .signup {
    padding: 0;
    flex-direction: column;
    // min-height: unset;
    max-height: unset;
    min-height: 100vh;
  }

  .signup-wrapper {
    flex-direction: column;
    height: 100%;
  }

  .totals-wrapper {
    margin: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
