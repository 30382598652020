import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": !!_ctx.dialog.name,
    class: "dialog",
    persistent: _ctx.dialog.options.persistent,
    fullscreen: _ctx.dialog.options.fullscreen || _ctx.$vuetify.display.xs,
    "retain-focus": _ctx.dialog.options.retainFocus,
    width: _ctx.dialog.options.width,
    "onClick:outside": _ctx.onOutsideClick
  }, {
    default: _withCtx(() => [
      (_ctx.dialog.name)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.dialog.name), {
            key: 0,
            dialog: { ..._ctx.dialog },
            close: _ctx.closeDialog
          }, null, 8, ["dialog", "close"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["model-value", "persistent", "fullscreen", "retain-focus", "width", "onClick:outside"]))
}