import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-408f6e91"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "invoice-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "date"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.date)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.invoiceDate), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "download",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openUrl()))
    }, [
      _createTextVNode(_toDisplayString(_ctx.t('download')) + " ", 1),
      _createElementVNode("img", {
        class: "download-icon",
        src: require('@/assets/icons/download.svg'),
        alt: "plastic"
      }, null, 8, _hoisted_3)
    ])
  ]))
}