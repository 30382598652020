<script setup lang="ts">
type AlertVariant = 'success' | 'error' | 'info'

interface Props {
  text: string
  variant: AlertVariant
}

defineProps<Props>()

const icons: Record<AlertVariant, string> = {
  success: 'mdi-check-circle-outline',
  error: 'mdi-alert-circle-outline',
  info: 'mdi-information-outline',
}
</script>

<template>
  <div :class="['gs-alert', `gs-alert--${variant}`]">
    <v-icon :icon="icons[variant]"></v-icon>
    <p>{{ text }}</p>
  </div>
</template>

<style lang="scss" scoped>
.gs-alert {
  display: flex;
  gap: 6px;
  padding: 4px;
  align-items: center;
  width: 100%;

  &--success {
    background: rgba(71, 209, 143, 0.2);
  }

  &--error {
    background: rgba(255, 36, 36, 0.2);
  }

  &--info {
    background-color: var(--gray-light-CC);
  }

  p {
    color: var(--ui-dark-gray);
    font-size: 14px;
    font-weight: 700;
    margin: 0;
  }

  .v-icon {
    font-size: 16px;
  }
}
</style>
