<template>
  <section class="select-existing-integration">
    <div class="card">
      <integrations-table mode="select" @select="onSelect" />
    </div>
    <div class="card card--with-button">
      <div class="text-wrapper">
        <h3 class="add-integration-title">
          {{ t('add_integration_title') }}
        </h3>

        <p class="add-integraion-description">
          {{ t('add_integration_description') }}
          <button class="chat-button" @click="openChat()">
            {{ t('get_in_touch') }}
          </button>
        </p>
      </div>
      <gs-button size="large" href="/add-integrations-automations?addNew=true">
        {{ t('add_integration') }}
      </gs-button>
    </div>
  </section>
</template>

<script lang="ts">
import IntegrationsTable from '@/components/integration/IntegrationsTable.vue'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import type { IntegrationTableData } from '@/store/integrations'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SelectExistingIntegration',
  emits: ['integration-selected'],
  components: {
    IntegrationsTable,
  },
  mixins: [LoadCrispMixin],
  created() {
    this.toggleChatBubbleVisibility(true)
  },
  methods: {
    t(key: string) {
      return this.$t(`SelectExistingIntegration.${key}`)
    },
    onSelect(integration: IntegrationTableData) {
      this.$emit('integration-selected', integration)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.select-existing-integration {
  background-color: transparent;
}

.card {
  padding: 15px;
  background: white;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  max-width: 1080px;
  margin: 0 auto 20px;
}

.add-integration-title {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 20px;
}

.add-integraion-description {
  font-size: 18px;
  line-height: 24px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .manage-integrations {
    padding: 40px;
  }

  .manage-integrations-title {
    margin-bottom: 40px;
  }

  .card {
    padding: 40px;
    margin-bottom: 40px;
  }

  .card.card--with-button {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .chat-button {
    color: var(--ui-green);
    text-decoration: underline;
    font-size: 16px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
}
</style>
