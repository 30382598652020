<template>
  <v-panel class="panel">
    <template v-slot:title>
      {{ t('title') }}
    </template>
    <div class="content-wrapper">
      <div class="input-wrapper">
        <v-select
          v-model="selectedBrand"
          :items="selectBrandOptions"
          label="Brands"
          prepend-inner-icon="mdi-shopping-outline"
          density="compact"
          color="#3B755F"
          variant="outlined"
          clearable
          class="impact-actions-select"
          @update:model-value="filterOrders()"
          @click:clear="clearSelect()"
        />
        <v-date-input
          v-model="dateRange"
          :label="t('dates')"
          :placeholder="t('dates')"
          class="date-picker"
          max-width="320"
          multiple="range"
          clearable
          @click:clear="clearDateRange()"
          readonly
          elevation="12"
          color="#3B755F"
          variant="outlined"
          @update:model-value="filterOrders"
          prepend-icon=""
          prepend-inner-icon="mdi-calendar-range-outline"
        >
        </v-date-input>
      </div>
      <LoaderBlock v-if="loading" background-color="transparent" />
      <template v-else>
        <ul v-if="impactActions.length" class="impact-actions-list">
          <ImpactActionsItem
            v-for="impactAction in impactActions"
            :key="impactAction._id"
            :impact-action="impactAction"
          />
        </ul>
        <div v-else class="no-result-wrapper">
          <v-icon class="no-result-icon"> mdi-filter-remove-outline </v-icon>
          <h3 class="no-result-text">
            {{ t('no_result') }}
          </h3>
        </div>
      </template>

      <v-pagination
        v-if="paginationLength > 1"
        v-model="page"
        class="mt-8"
        :length="paginationLength"
        color="#3B755F"
        @update:model-value="filterOrders"
      />
    </div>
  </v-panel>
</template>

<script lang="ts">
import ImpactActionsItem from '@/components/ImpactWallet/ImpactActionsItem.vue'
import LoaderBlock from '@/components/tools/LoaderBlock.vue'
import type { ImpactAction } from '@/helpers/interfaces'
import { formatDateToLocal } from '@/helpers/parsers'
import { getImpactActionBrands, getImpactActions } from '@api/index'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImpactActions',
  components: { ImpactActionsItem, LoaderBlock },
  data() {
    const impactActions: ImpactAction[] = []
    const selectBrandOptions: unknown[] = []
    const dateRange: Date[] = []

    return {
      selectedBrand: '',
      dateRange,
      selectBrandOptions,
      impactActions,
      menu: false,
      limit: 10,
      page: 1,
      paginationLength: 0,
      loading: false,
    }
  },
  async created() {
    await this.filterOrders()
    this.selectBrandOptions = await getImpactActionBrands()
  },
  methods: {
    t(key: string) {
      return this.$t(`ImpactActions.${key}`)
    },
    tc(key: string, amount: number) {
      return this.$t(`ImpactActions.${key}`, amount ?? 0)
    },
    async filterOrders() {
      this.loading = true
      let fromDate = ''
      let toDate = ''

      if (this.dateRange.length) {
        fromDate = formatDateToLocal(this.dateRange[0])
        toDate = formatDateToLocal(this.dateRange[this.dateRange.length - 1])
      }

      const skip = (this.page - 1) * this.limit
      const sellerUserId = this.selectedBrand || ''

      const impactAction = await getImpactActions(skip, this.limit, sellerUserId, fromDate, toDate)
      this.impactActions = impactAction.data
      this.paginationLength = Math.ceil(impactAction.count / this.limit)

      this.loading = false
    },
    clearDateRange() {
      this.dateRange = []
      this.$nextTick(() => {
        this.filterOrders()
      })
    },
    clearSelect() {
      this.selectedBrand = ''
      this.$nextTick(() => {
        this.filterOrders()
      })
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.panel {
  margin-bottom: 20px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.impact-actions-list {
  list-style-type: none;
  padding: 0;
}

.no-result-wrapper {
  align-items: center;
  color: var(--ui-green-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.no-result-icon {
  color: var(--ui-green-light);
  font-size: 50px;
  margin-bottom: 20px;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .input-wrapper {
    align-items: center;
    flex-direction: row;
    gap: 20px;
  }

  .impact-actions-select {
    flex: 0 1 30%;
  }

  .no-result-wrapper {
    margin: 0 auto;
    text-align: center;
    width: 60%;
  }
}
</style>
