<template>
  <div class="carousel-wrapper">
    <v-carousel
      hide-delimiter-background
      height="100%"
      continuous
      cycle
      :show-arrows="false"
      class="carousel-card"
    >
      <v-carousel-item
        v-for="(item, index) in carouselItems"
        :key="index"
        class="carousel-card-item"
      >
        <template v-if="getIfInstanceOfQuote(item)">
          <div class="carousel-item-wrapper">
            <p class="quote">
              {{ item.quote }}
            </p>
            <img :src="item.imageUrl" alt="" class="author-picture" />
            <p class="author-name">
              {{ item.authorName }}
            </p>
            <p class="author-title">
              {{ item.authorTitle }}
            </p>
          </div>
        </template>

        <template v-else>
          <div class="carousel-project-item-wrapper">
            <img :src="item.imageUrl" alt="" class="project-picture" />
            <div class="project-content">
              <p class="project-title">
                {{ item.title }}
              </p>
              <p class="project-text">
                {{ item.text }}
              </p>
              <ul class="project-checklist">
                <li
                  v-for="(listItem, itemIndex) in item.checkList"
                  :key="itemIndex"
                  class="project-checklist-item"
                >
                  <v-icon class="checkmark"> mdi-check </v-icon>
                  {{ listItem }}
                </li>
              </ul>
            </div>
          </div>
        </template>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script lang="ts">
import { getCarouselItems } from '@api/index'
import { defineComponent } from 'vue'

export interface CarouselQuoteItem {
  quote: string
  imageUrl: string
  authorName: string
  authorTitle: string
}

export interface CarouselProjectItem {
  title: string
  text: string
  checkList: string[]
  imageUrl: string
}
export default defineComponent({
  name: 'CarouselCard',
  data() {
    const carouselItems: (CarouselQuoteItem | CarouselProjectItem)[] = []

    return {
      carouselItems,
    }
  },
  created() {
    this.carouselItems = getCarouselItems()
  },
  methods: {
    t(key: string) {
      return this.$t(`CarouselCard.${key}`)
    },
    getIfInstanceOfQuote(
      object: CarouselProjectItem | CarouselQuoteItem,
    ): object is CarouselQuoteItem {
      return 'authorName' in object
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.carousel-wrapper {
  width: 100%;
  background-color: var(--ui-green-light);
}

.carousel-card {
  position: relative;
}

.carousel-card-item {
  position: absolute;
  top: 0;
  left: 0;
  padding: 75px 20px 50px;
}

.carousel-item-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--font-color-primary);
}

.quote {
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 0;
  height: 208px;
  flex-shrink: 0;
}

.author-name {
  font-weight: 600;
  margin-bottom: 0;
  font-size: 22px;
  line-height: 26px;
}

.author-title {
  font-weight: 400;
  margin-bottom: 0;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  height: 42px;
}

.author-picture {
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
  object-fit: contain;
  flex-shrink: 0;
}

.project-picture {
  width: 100%;
  object-fit: cover;
}

.project-content {
  padding: 0 20px 20px;
  color: var(--font-color-primary);
}

.project-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 10px;
}

.project-text {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 15px;
}

.project-checklist {
  list-style-type: none;
  position: relative;
  padding: 0;
}

.project-checklist-item {
  margin-left: 28px;
  font-size: 18px;
  line-height: 24px;
}

.checkmark {
  color: inherit;
  font-size: 24px;
  height: 24px;
  width: 24px;
  position: absolute;
  left: 0;
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .quote {
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 0;
    height: 224px;
    flex-shrink: 0;
  }
}
</style>
