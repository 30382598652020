import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03e6017e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "comparison-dialog" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_features_table = _resolveComponent("features-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "close-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }),
    _createVNode(_component_features_table, { class: "features-table" })
  ]))
}