<template>
  <svg width="80" height="84" viewBox="0 0 80 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.814 26C31.0906 26 24 33.174 24 42C24 50.826 31.0906 58 39.814 58C48.5373 58 55.6279 50.8169 55.6279 42C55.6279 33.1831 48.5373 26 39.814 26Z"
      fill="#1691F8"
    />
    <path
      d="M38.118 32.8792C37.5948 33.7646 36.8099 34.3396 35.7906 34.586C32.7504 35.3162 32.3806 36.6944 32.128 38.8758C31.8032 41.7782 28.0414 45.8581 25.0645 45.8581C24.9653 45.8581 24.866 45.849 24.7668 45.8398C24.6676 45.8307 24.5593 45.8125 24.4511 45.7942C24.1534 44.5712 24 43.3025 24 41.9973C24 33.6915 30.2696 26.8553 38.2804 26.0703H38.2894L38.4879 26.837C38.5239 26.9922 39.4531 30.5974 38.118 32.8792Z"
      fill="#2DF494"
    />
    <path
      d="M55.6281 42C55.6281 44.1631 55.1951 46.235 54.4192 48.1243C54.302 48.1335 54.1847 48.1335 54.0674 48.1335C52.6601 48.1335 51.5145 47.7136 50.6484 47.2025C47.8158 45.5413 46.2552 41.9452 46.3815 39.4261C46.4266 38.4769 45.9485 38.0205 45.0283 37.2447C44.1623 36.5054 43.0978 35.5927 42.9174 33.9133C42.5836 30.6183 46.6611 28.2453 46.8325 28.1449L47.2655 27.8984H47.2746C52.2452 30.591 55.6281 35.903 55.6281 42Z"
      fill="#2DF494"
    />
    <path
      d="M45.7499 51.2652C45.5695 52.7529 43.8645 54.1494 41.3026 54.9252C39.0653 55.6006 39.1916 56.6959 39.2187 56.8146L39.5164 57.992C34.8795 57.9098 30.7389 55.8014 27.9062 52.5065L27.9965 52.0684C28.3934 50.2065 29.023 48.6385 30.3929 49.5624C32.2193 50.9484 32.2193 51.2922 32.2193 52.7964C32.2193 54.1823 32.676 54.1823 34.5024 54.1823C34.8452 54.2918 37.53 53.4291 38.612 52.3344C40.895 50.0244 42.5475 47.6782 44.2705 48.7187C45.7229 49.6041 45.8221 50.6993 45.7499 51.2652Z"
      fill="#2DF494"
    />
    <g clip-path="url(#clip0_11657_11411)">
      <path
        d="M76.9188 39.3536C76.7569 39.7814 76.3522 40.0705 75.8897 40.0936L72.1781 40.2555C72.0509 48.6267 68.2353 56.6049 61.8181 61.7849L61.0434 62.4092C60.6272 62.7446 60.0491 62.7446 59.6213 62.4324C59.1934 62.1202 59.0431 61.5536 59.2397 61.068L59.6559 60.0389C62.1534 53.9339 63.4484 47.2508 63.4369 40.6486L59.8063 40.8105C59.3206 40.8105 58.9044 40.5677 58.7078 40.1514C58.5113 39.7352 58.5806 39.238 58.8928 38.8911L66.7206 30.1267C66.9288 29.8955 67.2294 29.7567 67.5416 29.7452C67.5531 29.7452 67.5647 29.7452 67.5763 29.7452C67.8769 29.7452 68.1659 29.8608 68.3856 30.0689L76.6297 38.1164C76.965 38.4402 77.0691 38.9258 76.9072 39.3536H76.9188Z"
        fill="#F9F9F9"
      />
      <path
        d="M41.3414 65.4475L41.1796 61.8169C41.1564 61.3544 41.4224 60.915 41.8386 60.7184C42.0005 60.6491 42.1624 60.6028 42.3358 60.6028C42.6133 60.6028 42.8908 60.7069 43.1105 60.8919L51.8749 68.7197C52.1061 68.9278 52.2449 69.2284 52.2564 69.5406C52.2564 69.8528 52.1524 70.165 51.9327 70.3847L43.8852 78.6519C43.5614 78.9872 43.0758 79.0912 42.648 78.9294C42.2202 78.7675 41.9311 78.3628 41.908 77.9003L41.7461 74.1887C33.3749 74.0616 25.3968 70.2459 20.2168 63.8287L19.5924 63.0541C19.2571 62.6378 19.2455 62.0597 19.5693 61.6319C19.8814 61.2041 20.448 61.0537 20.9336 61.2503L21.9627 61.6666C28.0446 64.1525 34.7046 65.4475 41.2836 65.4475H41.353H41.3414Z"
        fill="#F9F9F9"
      />
      <path
        d="M13.2677 53.8773C13.0595 54.1085 12.7589 54.2473 12.4467 54.2589C12.1345 54.2704 11.8223 54.1548 11.6027 53.9351L3.34704 45.8876C3.01172 45.5639 2.90766 45.0782 3.06954 44.6504C3.23141 44.2226 3.6361 43.9335 4.0986 43.9104L7.81016 43.7485C7.93735 35.3773 11.753 27.3992 18.1702 22.2192L18.9448 21.5948C19.153 21.4214 19.4189 21.3404 19.6733 21.3404C19.9161 21.3404 20.1589 21.4214 20.367 21.5717C20.7948 21.8839 20.9452 22.4504 20.7486 22.936L20.3323 23.9651C17.8348 30.0701 16.5398 36.7532 16.5514 43.3554L20.182 43.1935C20.6792 43.1704 21.0839 43.4364 21.2805 43.8526C21.477 44.2689 21.4077 44.766 21.0955 45.1129L13.2677 53.8773Z"
        fill="#F9F9F9"
      />
      <path
        d="M59.0782 22.7474L58.0491 22.3312C51.9672 19.8452 45.3072 18.5502 38.7282 18.5502H38.6588L38.8207 22.1809C38.8438 22.6434 38.5779 23.0827 38.1616 23.2793C37.7454 23.4759 37.2482 23.4065 36.9013 23.0943L28.1369 15.2665C27.9057 15.0584 27.7669 14.7577 27.7554 14.4455C27.7554 14.1334 27.8594 13.8212 28.0791 13.6015L36.1266 5.35743C36.3463 5.12618 36.6469 5.01055 36.9591 5.01055C37.0979 5.01055 37.2366 5.03368 37.3754 5.09149C37.8032 5.25337 38.0922 5.65805 38.1154 6.12055L38.2772 9.83211C46.6485 9.9593 54.6266 13.7749 59.8066 20.1921L60.431 20.9668C60.7663 21.3831 60.7779 21.9612 60.4541 22.389C60.1419 22.8168 59.5754 22.9671 59.0897 22.7706L59.0782 22.7474Z"
        fill="#F9F9F9"
      />
    </g>
    <defs>
      <clipPath id="clip0_11657_11411">
        <rect width="74" height="74" fill="white" transform="matrix(1 0 0 -1 3 79)" />
      </clipPath>
    </defs>
  </svg>
</template>
