<template>
  <div class="live-page-main">
    <div class="image">
      <div class="logo-wrapper">
        <img class="logo" :src="require('@/assets/icons/main-shape.svg')" alt="logo" />
      </div>

      <div class="live-page-header">
        {{ t('header') }}
      </div>
      <div class="live-page-wrapper">
        <div class="text-wrapper">
          <div class="info-text">
            {{ t('info_text') }}
          </div>
          <div>
            <div class="button-wrapper">
              <gs-button
                v-if="account?.accountType === 'business' || account?.accountType === 'ecommerce'"
                size="large"
                type="secondary"
                @click="goToEditorPage()"
              >
                {{ t('edit') }}
              </gs-button>
              <gs-button size="large" @click="goToLivePage()">
                {{ t('view') }}
              </gs-button>
            </div>

            <div @click="onCopy" class="copy">
              {{ copied ? t('copied_link') : t('copy_link') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Account } from '@/store'
import type { User } from '@/store/users'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LivePage',
  data() {
    return {
      copied: false,
      companyNameUrl: '',
      nameUrl: '',
      copyText: '',
    }
  },
  created() {
    this.setCompanyName()
    this.setCopyText()
  },
  methods: {
    t(key: string) {
      return this.$t(`LivePage.${key}`)
    },
    setCompanyName() {
      // for business
      this.companyNameUrl = this.account?.companyName
        ? encodeURIComponent(this.account?.companyName.replace(/\s/g, '-').toLowerCase())
        : ''

      // for personal
      this.nameUrl = this.currentUser
        ? this.currentUser.firstName.replace(/\s/g, '-').toLowerCase() +
          '-' +
          this.currentUser.lastName.replace(/\s/g, '-').toLowerCase()
        : ''
    },
    goToLivePage() {
      if (this.account?.accountType === 'business' || this.account?.accountType === 'ecommerce') {
        // const route = this.$router.resolve({ name: 'LivePage' })
        window.open(
          `${process.env.VUE_APP_PUBLIC_PAGE_URL}/${this.account?.livePageId}/${this.companyNameUrl}`,
          '_blank',
        )
      } else {
        window.open(
          `${process.env.VUE_APP_PUBLIC_PAGE_URL}/${this.account?.livePageId}/${this.nameUrl}`,
          '_blank',
        )
      }
    },
    goToEditorPage() {
      window.open(
        `${process.env.VUE_APP_PUBLIC_PAGE_URL}/${this.account?.livePageId}/${this.companyNameUrl}/editor`,
        '_blank',
      )
    },
    onCopy() {
      try {
        navigator.clipboard.writeText(this.copyText)
        this.copied = true
        setTimeout(() => {
          this.copied = false
        }, 1500)
      } catch (error) {
        this.onError(error)
      }
    },
    onError(error) {
      console.error(error)
    },
    setCopyText() {
      if (this.account?.accountType === 'business' || this.account?.accountType === 'ecommerce') {
        this.copyText = `https://public.getgreenspark.com/${this.account?.livePageId}/${this.companyNameUrl}`
      } else {
        this.copyText = `https://public.getgreenspark.com/${this.account?.livePageId}/${this.nameUrl}`
      }
    },
  },
  props: {
    account: {
      type: Object as PropType<Account>,
    },
    currentUser: {
      type: Object as PropType<User>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.live-page-main {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  margin-bottom: 25px;
}

.image {
  padding: 30px;
  background: url('../../assets/backgrounds/login-background.svg') right;
  background-size: cover;
  border-radius: 8px;
}

.logo-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.live-page-header {
  margin-bottom: 10px;
  color: #212121;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
}

.live-page-wrapper {
  display: flex;
  align-items: center;
}

.image-wrapper {
  margin-left: 10px;
  margin-right: 40px;
}

.check-image {
  width: 120px;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;
}

.info-text {
  color: #212121;
  font-size: 18px;
  line-height: 22px;
  max-width: 400px;
  margin-top: 10px;
  margin-bottom: 8px;
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 35px;
}

.button {
  width: 300px;
}

.button-text {
  color: #f9f9f9;
  font-weight: 600;
  font-size: 16px;
}

.copy {
  color: #212121;
  font-size: 14px;
  margin-top: 15px;
  cursor: pointer;
  text-decoration: underline;
}

@media #{map-get($display-breakpoints, 'md')} {
  .button-wrapper {
    margin-left: 0;
    margin-top: 15px;
  }

  .button {
    width: 100%;
  }
}

@media #{map-get($display-breakpoints, 'sm')} {
  .divider-wrapper {
    display: none;
  }

  .text-wrapper {
    margin-left: 0;
  }

  .image-wrapper {
    margin: 0;
  }

  .button {
    width: 300px;
  }
}

@media #{map-get($display-breakpoints, 'xs')} {
  .image {
    padding: 10px;
  }

  .live-page-wrapper {
    flex-direction: column;
  }

  .text-wrapper {
    text-align: center;
    margin-bottom: 8px;
  }

  .button {
    width: 150px;
  }

  .info-text {
    font-size: 16px;
    line-height: 18px;
  }

  .live-page-header {
    font-size: 28px;
    line-height: 32px;
  }
}
</style>
