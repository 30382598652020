<template>
  <div class="ma-0 mt-7 api-usage">
    <template v-if="!loading">
      <h2 class="tab-title">
        {{ t('usage') }}
        <span class="usage-period">
          {{ getUsagePeriod }}
        </span>
      </h2>
      <p class="mb-8">
        {{ t('description') }}
      </p>

      <!--  Widgets api report  -->
      <div class="api-usage-row">
        <div class="section-header">
          <h3 class="section-header-title">
            {{ t(getOwnApiUsage.widgets.apiModule) }}
            <span v-if="showHelpText(getOwnApiUsage.widgets.billing)" class="limit-description">
              {{
                getHelpText(
                  getOwnApiUsage.widgets.apiModule,
                  getOwnApiUsage.widgets.limit,
                  getOwnApiUsage.widgets.billing,
                )
              }}
            </span>
          </h3>
          <p
            v-if="showUnlimitedPill(getOwnApiUsage.widgets.limit, getOwnApiUsage.widgets.billing)"
            class="unlimited-pill"
          >
            {{ t('unlimited') }}
          </p>
        </div>

        <v-progress-linear
          color="green"
          bg-color="#F2EBDB"
          rounded
          :model-value="
            getProgressValue(getOwnApiUsage.widgets.count, getOwnApiUsage.widgets.limit)
          "
          height="29"
        >
          <template v-slot>
            <strong class="counter">
              {{ getLabel(getOwnApiUsage.widgets.count, getOwnApiUsage.widgets.limit.amount) }}
              <v-icon v-if="getOwnApiUsage.widgets.limit.amount === null" style="color: inherit"
                >mdi-all-inclusive</v-icon
              >
            </strong>
          </template>
        </v-progress-linear>
      </div>

      <hr class="divider" />
      <!--  Greenspark API modules report  -->
      <div v-if="!isApiUsageAnOverallReport" class="section-header">
        <h3 class="section-header-title">
          {{ t('overall') }}
        </h3>
      </div>
      <div
        v-for="({ apiModule, billing, limit, count }, index) in getOwnApiUsage.modules"
        :key="index"
        class="api-usage-row"
      >
        <div :class="['section-header', { downsize: !isApiUsageAnOverallReport }]">
          <h3 class="section-header-title">
            {{ t(apiModule) }}
            <span v-if="showHelpText(billing)" class="limit-description">
              {{ getHelpText(apiModule, limit, billing) }}
            </span>
          </h3>
          <p v-if="showUnlimitedPill(limit, billing)" class="unlimited-pill">
            {{ t('unlimited') }}
          </p>
        </div>
        <v-progress-linear
          color="green"
          bg-color="#F2EBDB"
          rounded
          :model-value="getProgressValue(count, limit)"
          height="29"
        >
          <template v-slot>
            <strong class="counter">
              {{ getLabel(count, limit.amount) }}
              <v-icon v-if="limit.amount === null" style="color: inherit">mdi-all-inclusive</v-icon>
            </strong>
          </template>
        </v-progress-linear>
      </div>
      <hr v-if="hasVendors" class="divider" />

      <!-- Vendor API usage reports -->
      <v-expansion-panels v-if="hasVendors" v-model="expansionPanels" flat multiple>
        <v-expansion-panel
          v-for="(value, key) in getVendorApiUsageGroupedByAccountId"
          :key="key"
          class="mb-6"
        >
          <v-expansion-panel-title>
            <div class="api-usage-expansion-panel-header">
              <p class="section-header-title expansion">
                {{ key }}
              </p>
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div
              v-for="({ apiModule, limit, count, billing }, index) in value"
              :key="index"
              class="api-usage-row"
            >
              <div class="section-header">
                <h3 class="section-header-title">
                  {{ t(apiModule) }}
                  <span v-if="showHelpText(billing)" class="limit-description">
                    {{ getHelpText(apiModule, limit, billing) }}
                  </span>
                </h3>
              </div>
              <v-progress-linear
                bg-color="white"
                color="black"
                rounded
                height="29"
                :model-value="getProgressValue(count, limit)"
              >
                <template v-slot>
                  <strong :class="['counter', 'expansion']">
                    {{ getLabel(count, limit.amount) }}
                    <v-icon v-if="limit.amount === null" style="color: inherit"
                      >mdi-all-inclusive</v-icon
                    >
                  </strong>
                </template>
              </v-progress-linear>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="contact-us">
        <p class="description ma-0 mr-2">
          {{ t('increase') }}
        </p>
        <button class="contact-us-button" @click="openChat()">
          {{ t('contact_us') }}
        </button>
      </div>
    </template>
    <LoadingOpaque v-if="loading" />
  </div>
</template>

<script lang="ts">
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import LoadingOpaque from '@/components/tools/LoadingOpaque.vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type {
  ApiBilling,
  ApiModule,
  ApiUsageItem,
  ApiUsageLimit,
  OwnApiUsage,
} from '@/store/api-usage'
import type { TranslateResult } from 'vue-i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ApiUsage',
  components: {
    LoadingOpaque,
  },
  mixins: [LoadCrispMixin, Utils],
  data() {
    return {
      expansionPanels: [],
    }
  },
  computed: {
    isApiUsageAnOverallReport(): boolean {
      return this.getOwnApiUsage.modules?.some(({ apiModule }) => apiModule === 'overall')
    },
    hasVendors(): boolean {
      return Object.keys(this.getVendorApiUsageGroupedByAccountId).length > 0
    },
    getOwnApiUsage(): OwnApiUsage {
      return this.$store.getters['getOwnApiUsage']
    },
    getUsagePeriod(): string {
      return this.$store.getters['getUsagePeriod']
    },
    getVendorApiUsageGroupedByAccountId(): Record<string, ApiUsageItem[]> {
      return this.$store.getters['getVendorApiUsageGroupedByAccountId']
    },
    loading(): boolean {
      return this.$store.getters['getLoading']
    },
  },
  async created() {
    await this.setApiUsage()
  },
  methods: {
    t(key: string, params?: { [key: string]: string }) {
      return this.$t(`ApiUsage.${key}`, params ?? {})
    },
    showHelpText(billing: ApiBilling): boolean {
      // we show the help text if there is a charge fee
      return !!billing?.chargeByCall
    },
    showUnlimitedPill(limit: ApiUsageLimit, billing: ApiBilling): boolean {
      return !limit.amount && !limit.hardLimit && !billing
    },
    getHelpText(apiModule: ApiModule, limit: ApiUsageLimit, billing: ApiBilling): TranslateResult {
      // if there is a charge fee, it means that we charge for every request
      if (!!billing.chargeByCall && (!limit.amount || (!!limit.amount && limit.hardLimit))) {
        return this.t('help_text_no_limit', {
          chargeFee: this.currencyFormatter(Number(billing.chargeByCall)),
        })
      } else {
        // there is a charge fee, and there is a limit amount, and the limit is not a hard limit it means that we charge for every request above the limit
        return this.t('help_text_limit', {
          limit: this.formatNumberByLocale(limit.amount),
          chargeFee: this.currencyFormatter(Number(billing.chargeByCall)),
        })
      }
    },
    getProgressValue(count: number, limit: ApiUsageLimit): number {
      // if there is no limit (limit === null),
      // or the user has no access to the API/API module (limit === 0, and hardLimit === true) show 100% progress
      if (limit.amount === null || (limit.hardLimit && limit.amount === 0)) {
        return 100
      } else {
        // else we show the percentage of the count and the limit ratio
        return Math.round((count / limit.amount) * 100)
      }
    },
    getLabel(count: number, limit: number | null): string {
      // if there is no limit, we don't display the quotient, there is an icon instead in the template
      return limit === null ? `${count || 0} /` : `${count || 0} / ${limit}`
    },
    setApiUsage(): Promise<void> {
      return this.$store.dispatch('setApiUsage')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';
@import 'src/scss/main';

.contact-us {
  margin-top: 19px;
  display: flex;
  align-items: center;
}

.contact-us-button {
  border: 1px solid var(--ui-green);
  border-radius: var(--border-radius-big);
  color: var(--ui-green);
  padding: 4px 9px;
  line-height: 18px;
}

.limit-description {
  font-size: 12px;
  line-height: 15px;
  color: $grey;
  font-weight: 400;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  & > p {
    margin: 0;
  }
}

.unlimited-pill {
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: var(--ui-emerald-green-light);
  font-weight: 600;
}

.section-header.downsize {
  margin-bottom: 8px;
}

.section-header-title {
  font-size: 18px;
}

.section-header.downsize .section-header-title {
  font-size: 16px;
  font-weight: 400;
}

.section-header-title.expansion {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0;
}

.api-usage-row {
  margin-bottom: 20px;
}

.api-usage:deep(.theme--light.v-expansion-panels .v-expansion-panel) {
  background-color: var(--gray-light-E4);
}

.vendor-text {
  display: none;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin: 20px 0 0;
}

.section-header-title {
  display: flex;
  flex-direction: column;
}

.limit-pill-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.divider {
  margin-bottom: 20px;
  background-color: #000;
}

.tab-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.usage-period {
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 16px;
  background-color: var(--gray-light-ED);
  margin: 0;
  font-weight: 400;
}

.counter {
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  height: 29px;
  width: 100%;
  justify-content: center;
}

.counter.expansion {
  mix-blend-mode: difference;
  color: white;
}

.progress-bar-wrapper {
  height: 29px;
  width: 100%;
  background-color: var(--ui-beige);
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 20px;
}

.progress-bar-wrapper.expansion {
  background-color: white;
}

.progress-bar-value {
  position: absolute;
  left: 0;
  height: 29px;
  width: 100%;
  background-color: var(--ui-green);
}

.progress-bar-value.expansion {
  background-color: black;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .vendor-text {
    display: block;
  }

  .section-header-title {
    display: block;
  }
}

@media #{map-get($display-breakpoints, 'xs')} {
  .contact-us {
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-us-button {
    margin-top: 10px;
  }
}
</style>
