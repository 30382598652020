<template>
  <div class="tooltip">
    <v-menu
      :open-on-hover="!isTouch"
      location="top"
      offset="5 100"
      close-delay="250"
      content-class="elevation-3"
    >
      <template v-slot:activator="{ props }">
        <img
          class="icon"
          :src="require('@/assets/icons/info.svg')"
          alt="tooltip icon"
          v-bind="props"
        />
      </template>
      <div class="tooltip-content">
        <div v-if="relationsText || impactActionsText" class="text">
          <p v-if="impactActionsText" class="mb-0" v-html="impactActionsText" />
          <p v-if="relationsText" class="mb-0" v-html="relationsText" />
        </div>
        <div v-if="partnerText" class="text">
          {{ partnerText }}
        </div>
        <div v-if="transferredImpact && transferredImpact > 0" class="text">
          <strong>Transferred impact:</strong> {{ transferredText }}
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script lang="ts">
import type { TranslateResult } from 'vue-i18n'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TooltipImpact',
  mixins: [Utils],
  methods: {
    t(key: string) {
      return this.$t(`TooltipImpact.${key}`)
    },
  },
  props: {
    transferredText: { type: String as () => TranslateResult },
    partnerText: {
      type: String as PropType<string | TranslateResult>,
    },
    relationsText: {
      type: String,
    },
    impactActionsText: {
      type: String,
    },
    transferredImpact: {
      type: Number,
    },
  },
})
</script>

<style lang="scss" scoped>
.tooltip {
  line-height: 0;
}

.tooltip-content {
  width: 240px;
  min-height: 100px;
  background: #f9f9f9;
  text-align: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
}

.link {
  color: #3b755f;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

.text {
  padding-bottom: 5px;
  color: #757d82;
  font-size: 14px;
}

.small-icon {
  font-weight: 600;
  font-size: 16px;
}
</style>
