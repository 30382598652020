import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e43476d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submenu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LivePage = _resolveComponent("LivePage")!
  const _component_SubMenuTile = _resolveComponent("SubMenuTile")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_LivePage, {
            account: _ctx.account,
            "current-user": _ctx.currentUser
          }, null, 8, ["account", "current-user"]),
          (_ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce')
            ? (_openBlock(), _createBlock(_component_SubMenuTile, {
                key: 0,
                header: _ctx.t('engagement'),
                text: _ctx.t('engagement_text'),
                "button-text": _ctx.t('manage'),
                onActionClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'Post purchase engagement' })))
              }, null, 8, ["header", "text", "button-text"]))
            : _createCommentVNode("", true),
          (_ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce')
            ? (_openBlock(), _createBlock(_component_SubMenuTile, {
                key: 1,
                header: _ctx.t('badges'),
                text: _ctx.t('badges_text'),
                "button-text": _ctx.t('manage'),
                onActionClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'badges' })))
              }, null, 8, ["header", "text", "button-text"]))
            : _createCommentVNode("", true),
          (_ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce')
            ? (_openBlock(), _createBlock(_component_SubMenuTile, {
                key: 2,
                header: _ctx.t('qr'),
                text: 
            _ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce'
              ? _ctx.t('qr_text')
              : _ctx.t('qr_text_personal')
          ,
                "button-text": _ctx.t('manage'),
                onActionClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'qr' })))
              }, null, 8, ["header", "text", "button-text"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    })
  ]))
}