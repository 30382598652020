<template>
  <div>
    <loading v-if="loading" />
    <h1 style="display: none">Impact Wallet</h1>
    <h2 class="greeting-title">
      {{ t('greeting_title', { username: currentUser.firstName }) }}
    </h2>
    <p class="greeting-text">
      {{ t('greeting_text') }}
    </p>

    <div class="menu-wrapper">
      <CustomMenu :menu-items="menuItems" @menuClick="menuClick" />
    </div>

    <transition name="fade">
      <section v-if="activeMenuItem === 'updates'" class="content-wrapper">
        <div class="container-8">
          <NewsFeed @open-order-details="openImpactActionDetailPopupById" />
        </div>
        <div class="container-4">
          <LatestImpactActionsList
            class="latest-order-list"
            @open-popup="openImpactActionDetailPopup"
            @impact-actions="menuClick"
          />
        </div>
      </section>
    </transition>

    <transition name="fade">
      <section v-if="activeMenuItem === 'impact_actions'">
        <div class="container-12">
          <ImpactActions @open-popup="openImpactActionDetailPopup" />
        </div>
      </section>
    </transition>

    <transition name="fade">
      <section v-if="activeMenuItem === 'brands'">
        <div class="container-12">
          <ImpactsByBrands
            :impacts-by-brands="impactsByBrands"
            @open-popup="openBrandDetailsPopup"
          />
        </div>
      </section>
    </transition>

    <FullWidthPopup ref="popup" :popup-title="popupTitle">
      <ImpactActionDetailDialog
        v-if="isImpactActionDetailActive"
        :impact-action="selectedImpactAction"
        @open-brand-detail="openBrandDetails"
      />
      <BrandDetailDialog
        v-if="!isImpactActionDetailActive"
        :brand="selectedBrand"
        @open-order-details="openImpactActionDetail"
      />
    </FullWidthPopup>
  </div>
</template>

<script lang="ts">
import CustomMenu from '@/components/Network/CustomMenu.vue'
import NewsFeed from '@/components/ImpactWallet/NewsFeed.vue'
import LatestImpactActionsList from '@/components/ImpactWallet/LatestImpactActionsList.vue'
import FullWidthPopup from '@/components/common/FullWidthPopup.vue'
import ImpactActionDetailDialog from '@/components/ImpactWallet/ImpactActionDetailDialog.vue'
import BrandDetailDialog from '@/components/ImpactWallet/BrandDetailDialog.vue'
import ImpactActions from '@/components/ImpactWallet/ImpactActions.vue'
import ImpactsByBrands from '@/components/ImpactWallet/ImpactsByBrands.vue'
import type { ImpactAction, ImpactByBrand, MenuItem } from '@/helpers/interfaces'
import { getAccountBrands, getImpactActionById } from '@api/index'
import Loading from '@/components/tools/Loading.vue'
import type { User } from '@/store/users'
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'

export default defineComponent({
  name: 'ImpactWalletView',
  components: {
    Loading,
    CustomMenu,
    NewsFeed,
    LatestImpactActionsList,
    FullWidthPopup,
    ImpactActionDetailDialog,
    BrandDetailDialog,
    ImpactActions,
    ImpactsByBrands,
  },
  data() {
    return {
      impactsByBrands: [],
      selectedBrand: {
        companyName: '',
        imageURL: '',
        impacts: [],
        userId: '',
        profileDescription: '',
        url: '',
      },
      selectedImpactAction: {
        _id: '',
        type: 'ORDER_CREATED',
        imageURL: '',
        action: { impacts: [], createdAt: '' },
        sellerUsername: '',
        sellerUserId: '',
      },
      menuItems: [
        {
          active: true,
          value: 'updates',
          label: this.$t(`ImpactWalletView.updates`),
        },
        {
          active: false,
          value: 'impact_actions',
          label: this.$t(`ImpactWalletView.impact_actions`),
        },
      ],
      activeMenuItem: 'updates',
      isImpactActionDetailActive: false,
      loading: false,
    } as {
      impactsByBrands: ImpactByBrand[]
      selectedBrand: ImpactByBrand
      selectedImpactAction: ImpactAction
      menuItems: MenuItem[]
      activeMenuItem: string | number
      isImpactActionDetailActive: boolean
      loading: boolean
    }
  },
  computed: {
    popupTitle(): TranslateResult {
      return this.isImpactActionDetailActive
        ? ''
        : this.t('brand_details_title', { companyName: this.selectedBrand.companyName })
    },
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
    userLocale(): string {
      return this.$store.getters['getUserLocale']
    },
    getMonthsEarthPositive(): number {
      return this.$store.getters['getMonthsEarthPositive']
    },
  },
  async created() {
    this.loading = true
    const { data } = await getAccountBrands()
    this.impactsByBrands = data.data
    if (this.impactsByBrands.length)
      this.menuItems.push({ active: false, value: 'brands', label: this.t('brands') })
    this.loading = false
  },
  mounted() {
    window.addEventListener('scroll', this.setClassOnStickyMenu)
  },
  unmounted() {
    window.removeEventListener('scroll', this.setClassOnStickyMenu)
  },
  methods: {
    t(key: string, params?: { [k: string]: string }) {
      return this.$t(`ImpactWalletView.${key}`, params ?? {})
    },
    setClassOnStickyMenu() {
      const menu = document.querySelector('.menu-wrapper') as HTMLElement
      if (this.$vuetify.display.xs) {
        if (menu.getBoundingClientRect().top < 0 && !menu.classList.contains('is-pinned')) {
          menu.classList.add('is-pinned')
        } else if (menu.getBoundingClientRect().top > 0) {
          menu.classList.remove('is-pinned')
        }
      }
    },
    menuClick(payload) {
      const menu = document.querySelector('.menu-wrapper') as HTMLElement
      if (menu) {
        menu.classList.remove('is-pinned')
        menu.scrollIntoView(true)
      }
      this.menuItems.map((menuItem) => {
        if (menuItem.value === payload) {
          menuItem.active = true
          this.activeMenuItem = menuItem.value
        } else {
          menuItem.active = false
        }
      })
    },
    openImpactActionDetailPopup(impactAction: ImpactAction) {
      const popup = this.$refs.popup as HTMLElement & { isOpen: boolean }
      this.isImpactActionDetailActive = true
      this.selectedImpactAction = impactAction
      this.$nextTick(() => {
        popup.isOpen = true
      })
    },
    async openImpactActionDetailPopupById(impactActionId: string) {
      const popup = this.$refs.popup as HTMLElement & { isOpen: boolean }
      this.isImpactActionDetailActive = true
      const { data } = await getImpactActionById(impactActionId)
      this.selectedImpactAction = data
      this.$nextTick(() => {
        popup.isOpen = true
      })
    },
    openBrandDetailsPopup(impactByBrand: ImpactByBrand) {
      const popup = this.$refs.popup as HTMLElement & { isOpen: boolean }
      this.isImpactActionDetailActive = false
      popup.isOpen = true
      this.selectedBrand = impactByBrand
    },
    openBrandDetails(impactByBrand: ImpactByBrand) {
      const popup = this.$refs.popup as HTMLElement & { isOpen: boolean }
      if (impactByBrand) {
        this.selectedBrand = impactByBrand
      } else {
        popup.isOpen = false
        this.$router.push('/your-impact')
      }
      this.isImpactActionDetailActive = false
    },
    openImpactActionDetail(impactAction: ImpactAction) {
      this.isImpactActionDetailActive = true
      this.selectedImpactAction = impactAction
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.greeting-title {
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 5px;
}

.greeting-text {
  margin-bottom: 36px;
}

.top-stats {
  margin-bottom: 35px;
}

.menu-wrapper {
  overflow-y: auto;
  width: 100%;
  padding: 20px 0;
  scrollbar-width: none;
}

.menu-wrapper::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.menu-wrapper.is-pinned {
  background: white;
  box-shadow: var(--box-shadow-light);
  top: 0;
  left: 0;
  padding: 20px 10px;
  margin-left: -10px;
  position: sticky;
  width: 100vw;
  z-index: 999;
}

.content-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}

.container-4 {
  order: 1;
}

.container-8 {
  order: 2;
}

.container-12 {
  width: 100%;
}

.latest-order-list {
  display: none;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .menu-wrapper.is-pinned {
    padding: 20px 24px;
    margin-left: -24px;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .menu-wrapper {
    position: static;
    padding: 20px 0;
    margin-bottom: 15px;
  }

  .latest-order-list {
    display: block;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .container-4,
  .container-8 {
    order: initial;
  }

  .content-wrapper {
    grid-template-columns: 3fr 2fr;
    column-gap: 20px;
  }
}
</style>
