import type { UserInviteDto, UserDto } from '@api/index'
import { inviteUsers, deleteUserInvite, getUserInvites, getUsers } from '@api/index'
import type { Notification } from '@/store/notification'
import VueI18n from '@/i18n'
import type { ActionContext } from 'vuex'
import type { StateInterface } from '@/store/index'

export const TEAM_MEMBER_STATUSES = ['accepted', 'pending'] as const
export type TeamMemberStatus = (typeof TEAM_MEMBER_STATUSES)[number]

export const TEAM_MEMBER_ROLES = ['owner'] as const
export type TeamMemberRole = (typeof TEAM_MEMBER_ROLES)[number]

export class TeamMember {
  id: string
  firstName: string
  lastName: string
  email: string
  status: TeamMemberStatus
  role: TeamMemberRole
  createdAt: Date

  protected constructor(
    id: string,
    firstName: string,
    lastName,
    email: string,
    status: TeamMemberStatus,
    role: TeamMemberRole,
    createdAt: string,
  ) {
    this.id = id
    this.email = email
    this.createdAt = new Date(createdAt)
    this.firstName = firstName
    this.lastName = lastName
    this.role = role
    this.status = status
  }

  // fullName getter is needed for the vuetify data table
  get fullName() {
    return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : ''
  }
}

export class User extends TeamMember {
  constructor(userDto: UserDto) {
    super(
      userDto.userId,
      userDto.firstName,
      userDto.lastName,
      userDto.email,
      'accepted',
      'owner',
      userDto.createdAt,
    )
  }
}

export class UserInvite extends TeamMember {
  constructor(userInviteDto: UserInviteDto) {
    super(
      userInviteDto.id,
      '',
      '',
      userInviteDto.email,
      'pending',
      'owner',
      userInviteDto.createdAt,
    )
  }
}

const userInviteErrorMappings = {
  USER_WITH_EMAIL_ALREADY_EXISTS: 'UsersModule.user_already_exists',
  ALREADY_INVITED: 'UsersModule.user_already_invited',
}

interface UsersModuleState {
  teamMembers: Array<TeamMember>
  usersDataLoading: boolean
}

export const usersModule = {
  state: () =>
    ({
      teamMembers: [],
      usersDataLoading: false,
    } as UsersModuleState),
  mutations: {
    setTeamMembers(state: UsersModuleState, teamMembers: Array<TeamMember>) {
      state.teamMembers = teamMembers
    },
    addTeamMembers(state: UsersModuleState, teamMembers: Array<TeamMember>) {
      state.teamMembers = [...state.teamMembers, ...teamMembers]
    },
    removeTeamMember(state: UsersModuleState, id: string) {
      state.teamMembers = [...state.teamMembers.filter((teamMember) => teamMember.id !== id)]
    },
    setUsersDataLoading(state: UsersModuleState, loading: boolean) {
      state.usersDataLoading = loading
    },
  },
  actions: {
    async loadUsersData({ commit, dispatch }: ActionContext<UsersModuleState, StateInterface>) {
      commit('setUsersDataLoading', true)

      try {
        const [userInvitesResponse, usersResponse] = await Promise.all([
          getUserInvites(),
          getUsers(),
        ])
        const userInvites = userInvitesResponse.data.map(
          (userInviteDto) => new UserInvite(userInviteDto),
        )
        const users = usersResponse.data.map((userDto) => new User(userDto))
        commit('setTeamMembers', [...userInvites, ...users])
      } catch (error) {
        console.error(error)
        commit('setTeamMembers', [])
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      } finally {
        commit('setUsersDataLoading', false)
      }
    },
    async inviteUsers(
      { commit, dispatch, getters }: ActionContext<UsersModuleState, StateInterface>,
      emails: Array<string>,
    ) {
      commit('setUsersDataLoading', true)

      try {
        const { data } = await inviteUsers(emails)
        commit(
          'addTeamMembers',
          data.map((userInviteDto) => new UserInvite(userInviteDto)),
        )

        const shouldFetchSetupChecklist = getters.getSetupChecklist.some(
          (checklistItem) => checklistItem.type === 'inviteUser' && checklistItem.value === false,
        )

        if (shouldFetchSetupChecklist) {
          await dispatch('setChecklist', null, { root: true })
        }
      } catch (error) {
        const { message } = error.response.data
        const translateMessageKey = userInviteErrorMappings[message] || 'CommonUi.error_generic'
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t(translateMessageKey, emails.length),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
        throw error
      } finally {
        commit('setUsersDataLoading', false)
      }
    },
    async deleteUserInvite(
      { commit, dispatch }: ActionContext<UsersModuleState, StateInterface>,
      inviteId: string,
    ) {
      commit('setUsersDataLoading', true)

      try {
        await deleteUserInvite(inviteId)
        commit('removeTeamMember', inviteId)
      } catch (error) {
        console.error(error)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
        throw error
      } finally {
        commit('setUsersDataLoading', false)
      }
    },
  },
  getters: {
    getEmailByUserId: (state: UsersModuleState) => (userId: string) => {
      const user = state.teamMembers.find((teamMember) => teamMember.id === userId)
      return user ? user.email : ''
    },
    getTeamMembers: (state: UsersModuleState): Array<TeamMember> => state.teamMembers,
  },
}
