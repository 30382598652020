export const getApiUrl = (api: 'dashboard' | 'openApi'): string => {
  if (window.location.hostname === 'localhost' && process.env.VUE_APP_USE_REMOTE_API === 'false') {
    switch (api) {
      case 'dashboard':
        return 'http://localhost:3003/'
      case 'openApi':
        return 'http://localhost:3333/'
    }
  } else {
    return process.env.VUE_APP_URL as string
  }
}
