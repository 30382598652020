<template>
  <section class="carbon-footprint">
    <h1 class="carbon-footprint__title">
      {{ t('title') }}
    </h1>
    <p class="carbon-footprint__description">
      {{ t('description') }}
    </p>
    <carbon-footprint-introduction v-if="!getIfAnyBankAccountIsConnected" />
    <template v-else>
      <connected-bank-account-list />
      <iframe
        v-if="sessionId"
        id="reportIframe"
        :src="srcUrl"
        title="Connect Report"
        :height="iframeHeight"
      />
    </template>
    <loading v-if="loading" />
  </section>
</template>

<script lang="ts">
import CarbonFootprintIntroduction from '@/components/carbon-footprint/CarbonFootprintIntroduction.vue'
import ConnectedBankAccountList from '@/components/carbon-footprint/ConnectedBankAccountList.vue'
import Loading from '@/components/tools/Loading.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CarbonFootprintView',
  components: {
    Loading,
    CarbonFootprintIntroduction,
    ConnectedBankAccountList,
  },
  data() {
    return {
      iframeHeight: 0,
      srcUrl: '',
    }
  },
  async created() {
    await this.setBanks()
    if (this.getIfAnyBankAccountIsConnected) {
      await this.setSession()
    }
    this.srcUrl = `https://report.connect.earth${this.getRouteParam}?sessionId=${this.sessionId}`
    window.addEventListener('message', this.handleMessage)
  },
  methods: {
    t(key: string) {
      return this.$t(`CarbonFootprintView.${key}`)
    },
    beforeDestroy() {
      window.removeEventListener('message', this.handleMessage)
    },
    async handleMessage(event: MessageEvent) {
      const { type, payload } = event.data
      if (type === 'RESIZE_EVENT') {
        this.iframeHeight = payload.height
      }
      if (type === 'ROUTE_CHANGE_EVENT') {
        await this.setRouteParam(payload.route)
      }
    },
    onSessionIdChange() {
      this.srcUrl = `https://report.connect.earth${this.getRouteParam}?sessionId=${this.sessionId}`
    },
    setBanks(): Promise<void> {
      return this.$store.dispatch('setBanks')
    },
    setSession(): Promise<void> {
      return this.$store.dispatch('setSession')
    },
    setRouteParam(routeParam: string): Promise<void> {
      return this.$store.dispatch('setRouteParam', routeParam)
    },
  },
  watch: {
    sessionId: [
      {
        handler: 'onSessionIdChange',
      },
    ],
  },
  computed: {
    loading(): boolean {
      return this.$store.getters['getLoading']
    },
    getIfAnyBankAccountIsConnected(): boolean {
      return this.$store.getters['getIfAnyBankAccountIsConnected']
    },
    sessionId(): string {
      return this.$store.getters['getConnectEarthSessionId']
    },
    getRouteParam(): string {
      return this.$store.getters['getRouteParam']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.carbon-footprint {
  padding: 0 6px;
}

.carbon-footprint__title {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
}

.carbon-footprint__description {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 32px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .carbon-footprint__title {
    padding: 0;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 16px;
  }

  .carbon-footprint__description {
    font-size: 24px;
    line-height: 29px;
    padding: 0;
    margin-bottom: 36px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
}

#reportIframe {
  width: 100%;
  border: none;
}
</style>
