<template>
  <div class="dialog">
    <div class="input-wrapper">
      <h2 class="description">
        {{ t('description') }}
      </h2>
      <v-textarea
        v-model="description"
        variant="outlined"
        counter
        color="#3B755F"
        maxlength="240"
        class="intro-textarea"
        no-resize
        :placeholder="t('placeholder')"
        :rules="[rules.maxlength(240)]"
      />
      <div class="button-wrapper">
        <v-btn
          class="save-button"
          color="#3B755F"
          height="44"
          :loading="loading"
          :disabled="loading"
          @click="saveIntroduction"
        >
          Save
        </v-btn>
        <v-btn
          color="#F9F9F9"
          height="44"
          class="cancel-button"
          elevation="0"
          @click="navigateBack"
        >
          Cancel
        </v-btn>
      </div>
    </div>
    <DemoFeed
      :prop-feed-items="[{ ...propFeedItem, description }]"
      type="INTRODUCTION"
      class="demo-feed"
    />
  </div>
</template>

<script lang="ts">
import DemoFeed from '@/components/CustomerEngagementPortal/DemoFeed.vue'
import type { Account } from '@/store'
import type { Notification } from '@/store/notification'
import { updateAccount } from '@api/index'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import { defineComponent } from 'vue'
import type { FeedItem } from '@/helpers/interfaces'

export default defineComponent({
  name: 'IntroSettingDialog',
  components: { DemoFeed },
  mixins: [RulesMixin],
  data() {
    return {
      loading: false,
      description: '',
    }
  },
  created() {
    this.description = this.account.profileDescription
  },
  methods: {
    t(key: string) {
      return this.$t(`IntroSettingDialog.${key}`)
    },
    async saveIntroduction() {
      this.loading = true
      const { data } = await updateAccount({ profileDescription: this.description })
      this.setAccount({ profileDescription: data.profileDescription })
      this.loading = false
      this.$store.dispatch('notification/notify', { text: this.t('text_saved') } as Notification)
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
    navigateBack() {
      return this.$router.push({
        name: 'Shareables',
      })
    },
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
    propFeedItem(): FeedItem {
      return {
        type: 'INTRODUCTION',
        // TODO: language options
        title: `Why ${this.account.companyName} joined Greenspark`,
        companyName: this.account.companyName,
        url: this.$store.getters.getLivePageUrl,
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.dialog {
  display: flex;
  gap: 40px;
  width: 100%;
}

.input-wrapper {
  background-color: var(--ui-white);
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-wide);
  padding: 20px;
  flex: 1 1 100%;
}

.demo-feed {
  display: none;
}

.intro-textarea {
  max-height: none; // .v-input reset
  margin-bottom: 20px;
  width: 100%;
}

.intro-textarea:deep(textarea::placeholder) {
  color: var(--gray-light);
}

.description {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.save-button {
  position: relative;
  color: var(--ui-white);
  margin-bottom: 20px;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .dialog {
    flex-direction: column;
  }

  .demo-feed {
    display: block;
    flex: 1 0 100%;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .dialog {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
  }

  .input-wrapper {
    flex: 1 1 50%;
  }

  .demo-feed {
    display: block;
    flex: 1 0 50%;
  }

  .button-wrapper {
    justify-content: flex-end;
    flex-direction: row;
    gap: 10px;
  }

  .save-button {
    order: 2;
    width: 150px;
  }

  .cancel-button {
    order: 1;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .dialog {
    margin: 0 auto;
    max-width: 1080px;
  }

  .input-wrapper {
    flex: 1 1 55%;
  }

  .demo-feed {
    display: block;
    flex: 1 0 45%;
  }
}
</style>
