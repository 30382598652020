<template>
  <loader-block v-if="loading" />
  <v-panel v-else>
    <template v-slot:title>
      {{ t('title') }}
    </template>

    <ul class="impact-by-brands-list">
      <ImpactsByBrandsItem v-for="(brand, index) in impactsByBrands" :key="index" :brand="brand" />
    </ul>
  </v-panel>
</template>

<script lang="ts">
import ImpactsByBrandsItem from '@/components/ImpactWallet/ImpactsByBrandsItem.vue'
import type { ImpactByBrand } from '@/helpers/interfaces'
import LoaderBlock from '@/components/tools/LoaderBlock.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImpactByBrands',
  components: { LoaderBlock, ImpactsByBrandsItem },
  data() {
    return {
      loading: false,
    }
  },
  async created() {
    this.loading = true

    this.loading = false
  },
  methods: {
    t(key: string) {
      return this.$t(`ImpactsByBrands.${key}`)
    },
    tc(key: string, amount: number) {
      return this.$t(`ImpactsByBrands.${key}`, amount ?? 0)
    },
  },
  props: {
    impactsByBrands: {
      type: Array as PropType<ImpactByBrand[]>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.impact-by-brands-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  list-style-type: none;
  gap: 20px;
  padding: 0;
  width: 100%;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .impact-by-brands-list {
    grid-template-columns: repeat(2, 2fr);
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .impact-by-brands-list {
    grid-template-columns: repeat(3, 3fr);
  }
}
</style>
