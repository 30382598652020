import {
  FAMILY_SUBSCRIPTION_TYPES,
  FREE_BUSINESS_SUBSCRIPTION_TYPES,
  NEW_PAID_BUSINESS_SUBSCRIPTION_TYPES,
  PERSONAL_SUBSCRIPTION_TYPES,
  TECHNICAL_AND_BUSINESS_SUBSCRIPTION_TYPES,
  TECHNICAL_AND_PAID_BUSINESS_SUBSCRIPTION_TYPES,
} from '@/helpers/constants'

import { includes } from '@/helpers/parsers'
import type { SubscriptionType } from '@/helpers/pricing'
import type { StateInterface } from '@/store'
import store from '@/store'
import router from '@/router/index'
import { getSubscriptionItems } from '@api/index'
import type { Notification } from '@/store/notification'
import VueI18n from '@/i18n'
import type { ActionContext } from 'vuex'

export type SubscriptionItem = {
  id: string
  _id: string
  product: SubscriptionType
  amount: number
  startDate: string
  trialEndDate?: Date
  endDate?: string
  updatedAt: string
  createdAt: string
  payerUserId: string
}

export interface SubscriptionsState {
  subscriptionItems: SubscriptionItem[]
}

export const subscriptionsModule = {
  state: () =>
    ({
      subscriptionItems: [],
    } as SubscriptionsState),
  mutations: {
    setSubscriptionItems(state: SubscriptionsState, subscriptionItems: SubscriptionItem[]) {
      const activePaidSubscriptionItem = subscriptionItems.find((subscriptionItem) => {
        return (
          includes(TECHNICAL_AND_PAID_BUSINESS_SUBSCRIPTION_TYPES, subscriptionItem.product) &&
          // start date which is in the past
          new Date() >= new Date(subscriptionItem.startDate) &&
          // end date which is either null or in the future
          (!subscriptionItem.endDate || new Date() <= new Date(subscriptionItem.endDate))
        )
      })
      store.commit('setFeatureSettings', activePaidSubscriptionItem?.product)
      state.subscriptionItems = subscriptionItems
    },
  },
  actions: {
    async setSubscriptionItems(
      { commit, getters, dispatch }: ActionContext<SubscriptionsState, StateInterface>,
      withNavigation = true,
    ) {
      let subscriptionItems: SubscriptionItem[] = []
      try {
        subscriptionItems = await getSubscriptionItems()
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
      commit('setSubscriptionItems', subscriptionItems)
      if (
        getters.getAccountTypeIsBusiness &&
        !getters.hasAnyOngoingSubscriptionItem &&
        withNavigation
      ) {
        // vue router returns a Promise<Route>, this is used to turn off further execution of caller function
        return await router.replace('/finish-subscription')
      }
    },
  },
  getters: {
    getActiveSubscriptionItem: (state: SubscriptionsState) => {
      return state.subscriptionItems.find((subscriptionItem) => {
        return (
          includes(TECHNICAL_AND_BUSINESS_SUBSCRIPTION_TYPES, subscriptionItem.product) &&
          // start date which is in the past
          new Date() >= new Date(subscriptionItem.startDate) &&
          // end date which is either null or in the future
          (!subscriptionItem.endDate || new Date() <= new Date(subscriptionItem.endDate))
        )
      })
    },
    getHighestActiveSubscriptionItem: (state: SubscriptionsState) => {
      const activeSubscriptionItems = state.subscriptionItems.filter((subscriptionItem) => {
        return (
          includes(TECHNICAL_AND_BUSINESS_SUBSCRIPTION_TYPES, subscriptionItem.product) &&
          // start date which is in the past
          new Date() >= new Date(subscriptionItem.startDate) &&
          // end date which is either null or in the future
          (!subscriptionItem.endDate || new Date() <= new Date(subscriptionItem.endDate))
        )
      })
      return (
        activeSubscriptionItems.find((subscriptionItem) => subscriptionItem.product !== 'free') ||
        activeSubscriptionItems[0]
      )
    },
    getActivePaidSubscriptionItem: (state: SubscriptionsState) => {
      return state.subscriptionItems.find((subscriptionItem) => {
        return (
          includes(TECHNICAL_AND_PAID_BUSINESS_SUBSCRIPTION_TYPES, subscriptionItem.product) &&
          // start date which is in the past
          new Date() >= new Date(subscriptionItem.startDate) &&
          // end date which is either null or in the future
          (!subscriptionItem.endDate || new Date() <= new Date(subscriptionItem.endDate))
        )
      })
    },
    getOwnFreeSubscriptionItem: (state: SubscriptionsState) => {
      state.subscriptionItems
        .filter(({ payerUserId }) => payerUserId === store.state.account.accountId)
        .find((subscriptionItem) => {
          return (
            includes(FREE_BUSINESS_SUBSCRIPTION_TYPES, subscriptionItem.product) &&
            // start date which is in the past
            new Date() >= new Date(subscriptionItem.startDate) &&
            // end date which is either null or in the future
            (!subscriptionItem.endDate || new Date() <= new Date(subscriptionItem.endDate))
          )
        })
    },
    hasPaidSubscriptionItem: (state: SubscriptionsState): boolean => {
      const isBusiness = ['business', 'ecommerce'].includes(store.state.account.accountType)
      return !!state.subscriptionItems.find((subscriptionItem) => {
        return (
          includes(
            isBusiness
              ? TECHNICAL_AND_PAID_BUSINESS_SUBSCRIPTION_TYPES
              : [...PERSONAL_SUBSCRIPTION_TYPES, ...FAMILY_SUBSCRIPTION_TYPES],
            subscriptionItem.product,
          ) &&
          // start date which is in the past
          new Date() >= new Date(subscriptionItem.startDate) &&
          // end date which is either null or in the future
          (!subscriptionItem.endDate || new Date() <= new Date(subscriptionItem.endDate))
        )
      })
    },
    hasOwnPaidSubscriptionItem: (state: SubscriptionsState) => {
      const isBusiness = ['business', 'ecommerce'].includes(store.state.account.accountType)
      return !!state.subscriptionItems
        .filter(({ payerUserId }) => {
          return payerUserId === store.state.account.accountId
        })
        .find((subscriptionItem) => {
          return (
            includes(
              isBusiness
                ? TECHNICAL_AND_PAID_BUSINESS_SUBSCRIPTION_TYPES
                : [...PERSONAL_SUBSCRIPTION_TYPES, ...FAMILY_SUBSCRIPTION_TYPES],
              subscriptionItem.product,
            ) &&
            // start date which is in the past
            new Date() >= new Date(subscriptionItem.startDate) &&
            // end date which is either null or in the future
            (!subscriptionItem.endDate || new Date() <= new Date(subscriptionItem.endDate))
          )
        })
    },
    hasOwnFreeSubscriptionItem: (state: SubscriptionsState) => {
      const isBusiness = ['business', 'ecommerce'].includes(store.state.account.accountType)
      return isBusiness
        ? !!state.subscriptionItems
            .filter(({ payerUserId }) => payerUserId === store.state.account.accountId)
            .find((subscriptionItem) => {
              return (
                includes(FREE_BUSINESS_SUBSCRIPTION_TYPES, subscriptionItem.product) &&
                // start date which is in the past
                new Date() >= new Date(subscriptionItem.startDate) &&
                // end date which is either null or in the future
                (!subscriptionItem.endDate || new Date() <= new Date(subscriptionItem.endDate))
              )
            }) &&
            !state.subscriptionItems.some(
              ({ payerUserId }) => payerUserId !== store.state.account.accountId,
            )
        : true
    },
    isActiveSubscriptionOnTrial: (state: SubscriptionsState, getters): boolean => {
      return getters.getActiveSubscriptionItem?.trialEndDate
        ? new Date() <= new Date(getters.getActiveSubscriptionItem.trialEndDate) &&
            !getters.getActiveSubscriptionItem.endDate
        : false
    },
    getPendingSubscription: (state: SubscriptionsState) => {
      return state.subscriptionItems.find((subscriptionItem) => {
        return (
          includes(NEW_PAID_BUSINESS_SUBSCRIPTION_TYPES, subscriptionItem.product) &&
          // start date which is in the future
          new Date(subscriptionItem.startDate) >= new Date() &&
          // end date which is either null or in the future
          (!subscriptionItem.endDate || new Date() <= new Date(subscriptionItem.endDate))
        )
      })
    },
    hasAnyOngoingSubscriptionItem: (state: SubscriptionsState): boolean => {
      return !!state.subscriptionItems.filter((subscriptionItem) => {
        return (
          new Date() >= new Date(subscriptionItem.startDate) &&
          // end date which is either null or in the future
          (!subscriptionItem.endDate || new Date() <= new Date(subscriptionItem.endDate))
        )
      }).length
    },
    getSubscriptionItems: (state: SubscriptionsState): SubscriptionItem[] =>
      state.subscriptionItems,
  },
}
