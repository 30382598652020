<template>
  <div class="banner">
    <div class="banner-content-wrapper">
      <div class="banner-logo">
        <img :src="require('@/assets/test-logo.png')" alt="banner-image" />
      </div>
      <div class="user-name">
        {{ t('greenspark') }}
      </div>
      <div class="member-since">
        {{ t('since') }}
      </div>
      <div class="description-wrapper">
        <div class="description">
          {{ t('description') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OverallBanner',
  components: {},
  methods: {
    t(key: string) {
      return this.$t(`OverallBanner.${key}`)
    },
    openUrl() {
      window.open('https://www.getgreenspark.com/', '_self')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.banner {
  padding: 20px;
  width: 100%;
  min-height: 450px;
  border-radius: 8.73657px 8.73657px 0 0;
  background-image: url('../../assets/gifs/comparisons/banner-backgroup.svg');
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.banner-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-logo {
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

img {
  width: 150px;
  height: 100%;
  object-fit: contain;
}

.user-name {
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  color: #212121;
  text-align: center;
}

.member-since {
  margin-top: 12px;
  color: #b0b0b0;
  font-size: 14px;
  line-height: 17px;
}

.description-wrapper {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  text-align: center;
  font-size: 20px;
  color: #212121;
  width: 50%;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .banner {
    background-position: right;
  }

  .description {
    width: 80%;
  }

  .description-wrapper {
    padding: 10px 10px 30px;
  }
}

@media #{map-get($display-breakpoints, 'xs')} {
  .description {
    font-size: 17px;
  }
}
</style>
