import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed996f8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pre-signup" }
const _hoisted_2 = { class: "pre-signup-wrapper" }
const _hoisted_3 = { class: "header-wrapper" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { class: "account-type-wrapper" }
const _hoisted_6 = { class: "account-types" }
const _hoisted_7 = { class: "account-types" }
const _hoisted_8 = { class: "account-types" }
const _hoisted_9 = { class: "back" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccountType = _resolveComponent("AccountType")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('choose')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_AccountType, {
            text: _ctx.t('business'),
            onTypeClick: _ctx.clickOnBusiness
          }, null, 8, ["text", "onTypeClick"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_AccountType, {
            text: _ctx.t('personal'),
            onTypeClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/p/signup')))
          }, null, 8, ["text"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_AccountType, {
            text: _ctx.t('family'),
            onTypeClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/f/signup')))
          }, null, 8, ["text"])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/login')))
        }, _toDisplayString(_ctx.t('cancel')), 1)
      ])
    ])
  ]))
}