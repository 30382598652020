<template>
  <div class="variables-preview">
    <p>{{ t('text') }}</p>
    <v-table class="table mb-5" fixed-header>
      <thead>
        <th class="text-left">
          {{ t('variable') }}
        </th>
        <th class="text-left">
          {{ t('example') }}
        </th>
      </thead>
      <tbody>
        <tr v-for="(value, key) in defaultVariables" :key="key">
          <td class="text-body-1 text-no-wrap">
            {{ formatKey(key) }}
            <copy-button :text="formatKey(key)" />
          </td>
          <td class="text-body-1 py-4">
            {{ value }}
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script lang="ts">
import CopyButton from '@/components/common/copy-button.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'VariablesPreview',
  components: {
    CopyButton,
  },
  methods: {
    t(key: string) {
      return this.$t(`VariablesPreview.${key}`)
    },
    formatKey(key: string) {
      return '{{{' + key + '}}}'
    },
  },
  computed: {
    defaultVariables(): Record<string, string> {
      return this.$store.getters['getCustomerEngagementDefaultVariables']
    },
  },
})
</script>

<style lang="scss" scoped>
.table {
  border: 1px solid var(--gray-light);
  border-radius: 10px;
}

.table th {
  padding: 16px 16px;
  background: none !important;
}

.table tr:hover {
  background: none !important;
}
</style>
