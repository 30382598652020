<template>
  <v-card class="dialog-card">
    <v-card-title class="text-black font-weight-bold text-h5 d-flex py-6 pt-4 pb-3 align-center">
      {{ t('invite_user') }}
      <v-spacer />
      <v-btn icon @click="close" variant="plain">
        <v-icon color="black"> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="text-black text-body-1">
      {{ t('invite_user_description') }}
      <p class="plan-limit-info" v-html="planLimitInfoTextFirstLine" />
      <i18n-t keypath="InviteUserDialog.plan_limit_info_text_2" tag="div" class="plan-limit-info">
        <span>{{ tc('users', remainingSeatCount) }}</span>
        <router-link to="/upgrade-plan">
          {{ t('upgrade') }}
        </router-link>
      </i18n-t>
      <DynamicInputGroup
        v-model="inviteEmails"
        :disabled="isLoading"
        :input-rules="[rules.email]"
        :input-number-limit="inputNumberLimit"
        :input-label="inputLabel"
        @validate="onFormValidate"
      />
    </v-card-text>

    <v-card-actions>
      <gs-button
        class="primary-button"
        type="primary"
        size="large"
        :loading="isLoading"
        :disabled="!isFormValid || isLoading"
        @click="onInvite"
      >
        {{ t('invite') }}
      </gs-button>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import type { Dialog } from '@/store/dialog'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { TechnicalAndBusinessSubscriptionType } from '@/helpers/pricing'
import type { SubscriptionItem } from '@/store/subscriptions'
import type { TeamMember } from '@/store/users'
import type { Emptyable } from '@/helpers/interfaces'
import DynamicInputGroup from '@/components/common/DynamicInputGroup.vue'
import type { TranslateResult } from 'vue-i18n'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InviteUserDialog',
  components: {
    DynamicInputGroup,
  },
  mixins: [RulesMixin, Utils],
  data() {
    return {
      isLoading: false,
      inviteEmails: [],
      isFormValid: false,
      currentPlanType: '',
    } as {
      currentPlanType: Emptyable<TechnicalAndBusinessSubscriptionType>
      isLoading: boolean
      inviteEmails: string[]
      isFormValid: boolean
    }
  },
  computed: {
    inputLabel(): TranslateResult {
      return this.t('email')
    },
    remainingSeatCount(): number {
      const usedSeats = this.teamMembers.length + this.inviteEmails.length
      return this.maxTeamMemberCount - usedSeats
    },
    inputNumberLimit(): number {
      return this.maxTeamMemberCount - this.teamMembers.length
    },
    planLimitInfoTextFirstLine(): TranslateResult {
      return this.t('plan_limit_info_text_1', {
        currentPlan: this.formatPlanName(this.currentPlanType),
        maxAmount: this.tc('users', this.maxTeamMemberCount),
      })
    },
    showLimitInfoText(): boolean {
      return !this.currentPlanType?.includes('enterprise')
    },
    highestActiveSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getHighestActiveSubscriptionItem']
    },
    maxTeamMemberCount(): number {
      return this.$store.getters['getMaxTeamMemberCount']
    },
    teamMembers(): Array<TeamMember> {
      return this.$store.getters['getTeamMembers']
    },
  },
  async created() {
    this.currentPlanType = this.highestActiveSubscriptionItem
      .product as TechnicalAndBusinessSubscriptionType
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`InviteUserDialog.${key}`, params ?? {})
    },
    tc(key: string, amount: number) {
      return this.$t(`InviteUserDialog.${key}`, amount ?? 0)
    },
    onFormValidate(isValid: boolean) {
      this.isFormValid = isValid
    },
    async onInvite(): Promise<void> {
      this.isLoading = true
      try {
        await this.inviteUsers(this.inviteEmails)
        this.isLoading = false
        if (this.close) this.close()
      } catch (error) {
        // error handled in store action
        this.isLoading = false
      }
    },
    inviteUsers(emails: Array<string>): Promise<void> {
      return this.$store.dispatch('inviteUsers', emails)
    },
  },
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
    close: {
      type: Function as PropType<() => void>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.dialog-card {
  display: flex;
  flex-direction: column;
}

.primary-button {
  position: relative;
  flex: 1 1 100%;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
}

.plan-limit-info {
  margin: 16px 0;
}

.plan-limit-info:deep(p:last-child) {
  margin-bottom: 0;
}

.plan-limit-info:deep(span) {
  background: var(--ui-beige);
  padding: 0 5px 0;
  border-radius: 6px;
  font-weight: 600;
  white-space: nowrap;
}

.plan-limit-info:deep(a) {
  text-decoration: none;
  font-weight: 600;
}
</style>
