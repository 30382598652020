import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-716a923a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "manage-integrations" }
const _hoisted_2 = { class: "manage-integrations-title" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "manage-integrations-description" }
const _hoisted_5 = {
  key: 0,
  class: "card card--with-button"
}
const _hoisted_6 = { class: "text-wrapper" }
const _hoisted_7 = { class: "add-integration-title" }
const _hoisted_8 = { class: "add-integraion-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_back = _resolveComponent("gs-back")!
  const _component_integrations_table = _resolveComponent("integrations-table")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.t('title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_gs_back),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('description')), 1),
      _createVNode(_component_integrations_table, { onSelect: _ctx.onSelect }, null, 8, ["onSelect"])
    ]),
    (!_ctx.isIntegrator)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.isCustom ? _ctx.t('add_source_title') : _ctx.t('add_integration_title')), 1),
            _createElementVNode("p", _hoisted_8, [
              _createTextVNode(_toDisplayString(_ctx.isCustom
              ? _ctx.t('add_source_description')
              : _ctx.t('add_integration_description', {
                  platform: _ctx.getIntegrationPlatformName(_ctx.selectedPlatform),
                })) + " ", 1),
              _createElementVNode("button", {
                class: "chat-button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openChat()))
              }, _toDisplayString(_ctx.t('get_in_touch')), 1)
            ])
          ]),
          _createVNode(_component_gs_button, {
            size: "large",
            href: "/add-integrations-automations?addNew=true"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isCustom ? _ctx.t('add_source') : _ctx.t('add_integration')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}