import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "schedule-picker-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScheduleInput = _resolveComponent("ScheduleInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedValues, (item, index) => {
      return (_openBlock(), _createBlock(_component_ScheduleInput, {
        key: index,
        modelValue: item.value,
        "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
        "index-of-input": index,
        "disabled-range": _ctx.disabledRange,
        "current-period-settings": _ctx.selectedValues.length,
        class: "input-wrapper",
        onIncrement: _ctx.handleIncrement,
        onDecrement: _ctx.handleDecrement
      }, null, 8, ["modelValue", "onUpdate:modelValue", "index-of-input", "disabled-range", "current-period-settings", "onIncrement", "onDecrement"]))
    }), 128))
  ]))
}