<template>
  <div class="update-wrapper">
    <div class="subheader">
      {{ t('your_team') }}
    </div>

    <!-- standard -->
    <div class="plan-name-total">
      <div class="plan">
        <span class="number">{{ standardAmount }}</span>
        <span class="x">x</span>
        <div>{{ t('standard') }}</div>

        <Tooltip class="tooltip" :body="t('standard_tooltip')" />

        <div class="pending-number">({{ updatedTotalPendingStandard }} {{ t('pending') }})*</div>
      </div>

      <div class="text-field-wrapper">
        <v-text-field
          v-model.number="addStandard"
          :label="t('adjust_standard')"
          variant="outlined"
          clearable
          type="number"
          flat
          color="#3B755F"
          class="employee-input"
        />
        <div class="plus-minus">
          <div
            class="add"
            style="margin-bottom: 1px"
            @click="addStandard ? addStandard++ : (addStandard = 1)"
          >
            +
          </div>
          <div
            :class="['add', { disabled: isStandardMinusDisabled }]"
            style="margin-top: 1px"
            @click="!isStandardMinusDisabled ? addStandard && addStandard-- : null"
          >
            -
          </div>
        </div>
      </div>
    </div>

    <!-- superior -->
    <div class="plan-name-total">
      <div class="plan">
        <span class="number">{{ superiorAmount }}</span>
        <span class="x">x</span>
        <div>{{ t('superior') }}</div>

        <Tooltip class="tooltip" :body="t('superior_tooltip')" />

        <div class="pending-number">({{ updatedTotalPendingSuperior }} {{ t('pending') }})*</div>
      </div>

      <div class="text-field-wrapper">
        <v-text-field
          v-model.number="addSuperior"
          :label="t('adjust_superior')"
          variant="outlined"
          clearable
          type="number"
          flat
          color="#3B755F"
          class="employee-input"
        />
        <div class="plus-minus">
          <div
            class="add"
            style="margin-bottom: 1px"
            @click="addSuperior ? addSuperior++ : (addSuperior = 1)"
          >
            +
          </div>
          <div
            :class="['add', { disabled: isSuperiorMinusDisabled }]"
            style="margin-top: 1px"
            @click="!isSuperiorMinusDisabled ? addSuperior && addSuperior-- : null"
          >
            -
          </div>
        </div>
      </div>
    </div>

    <!-- premium -->
    <div class="plan-name-total">
      <div class="plan">
        <span class="number">{{ premiumAmount }}</span>
        <span class="x">x</span>
        <div>{{ t('premium') }}</div>

        <Tooltip class="tooltip" :body="t('premium_tooltip')" />

        <div class="pending-number">({{ updatedTotalPendingPremium }} {{ t('pending') }})*</div>
      </div>

      <div class="text-field-wrapper">
        <v-text-field
          v-model.number="addPremium"
          :label="t('adjust_premium')"
          variant="outlined"
          clearable
          type="number"
          flat
          color="#3B755F"
          class="employee-input"
        />
        <div class="plus-minus">
          <div
            class="add"
            style="margin-bottom: 1px"
            @click="addPremium ? addPremium++ : (addPremium = 1)"
          >
            +
          </div>
          <div
            :class="['add', { disabled: isPremiumMinusDisabled }]"
            style="margin-top: 1px"
            @click="!isPremiumMinusDisabled ? addPremium && addPremium-- : null"
          >
            -
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Tooltip from '@/components/tools/Tooltip.vue'
import type { Account } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UpdatePlans',
  emits: ['getUpdatedValues'],
  components: {
    Tooltip,
  },
  data() {
    return {
      addPremium: null,
      addSuperior: null,
      addStandard: null,
    } as {
      addPremium: number | null
      addSuperior: number | null
      addStandard: number | null
    }
  },
  computed: {
    isPremiumMinusDisabled(): boolean {
      return (
        !(this.premiumAmount + this.updatedTotalPendingPremium) ||
        this.getIsPremiumNotDecreasable(Number(this.addPremium) - 1)
      )
    },
    isStandardMinusDisabled(): boolean {
      return (
        !(this.standardAmount + this.updatedTotalPendingStandard) ||
        this.getIsStandardNotDecreasable(Number(this.addStandard) - 1)
      )
    },
    isSuperiorMinusDisabled(): boolean {
      return (
        !(this.superiorAmount + this.updatedTotalPendingSuperior) ||
        this.getIsSuperiorNotDecreasable(Number(this.addSuperior) - 1)
      )
    },
  },
  methods: {
    t(key: string, params?: { [k: string]: string }) {
      return this.$t(`UpdatePlans.${key}`, params ?? {})
    },
    getIsPremiumNotDecreasable(premium?: number | null) {
      return (
        -Math.abs(this.premiumAmount + Number(this.account.temporaryUpgrades.premium)) >
        Number(premium ?? this.addPremium)
      )
    },
    getIsStandardNotDecreasable(standard?: number | null) {
      return (
        -Math.abs(this.standardAmount + Number(this.account.temporaryUpgrades.standard)) >
        Number(standard ?? this.addStandard)
      )
    },
    getIsSuperiorNotDecreasable(superior?: number | null) {
      return (
        -Math.abs(this.superiorAmount + Number(this.account.temporaryUpgrades.superior)) >
        Number(superior ?? this.addSuperior)
      )
    },
    onResetInputsChange() {
      if (this.resetInputs) {
        this.addStandard = null
        this.addSuperior = null
        this.addPremium = null
      }
    },
    onAddStandardChange() {
      const amount = this.getIsStandardNotDecreasable() ? null : this.addStandard || 0
      this.$emit('getUpdatedValues', amount, 'standard')
    },
    onAddSuperiorChange() {
      const amount = this.getIsSuperiorNotDecreasable() ? null : this.addSuperior || 0
      this.$emit('getUpdatedValues', amount, 'superior')
    },
    onAddPremiumChange() {
      const amount = this.getIsPremiumNotDecreasable() ? null : this.addPremium || 0
      this.$emit('getUpdatedValues', amount, 'premium')
    },
  },
  props: {
    standardAmount: { required: true, type: Number, default: 0 },
    superiorAmount: { required: true, type: Number, default: 0 },
    premiumAmount: { required: true, type: Number, default: 0 },
    updatedTotalPendingStandard: { required: true, type: Number, default: 0 },
    updatedTotalPendingSuperior: { required: true, type: Number, default: 0 },
    updatedTotalPendingPremium: { required: true, type: Number, default: 0 },
    account: { required: true, type: Object as () => Account },
    resetInputs: { required: true, type: Boolean, default: false },
  },
  watch: {
    resetInputs: [
      {
        handler: 'onResetInputsChange',
      },
    ],
    addStandard: [
      {
        handler: 'onAddStandardChange',
      },
    ],
    addSuperior: [
      {
        handler: 'onAddSuperiorChange',
      },
    ],
    addPremium: [
      {
        handler: 'onAddPremiumChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.update-wrapper {
  padding: 25px 30px 30px;
  width: 100%;
}

.subheader {
  color: var(--ui-black);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 18px;
}

.plan-name-total {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 22px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan {
  color: var(--ui-black);
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.x {
  margin-left: 10px;
  margin-right: 10px;
}

.number {
  color: var(--ui-black);
  font-weight: 600;
  font-size: 22px;
  margin-bottom: -3px;
}

.tooltip {
  margin-left: 5px;
}

.pending-number {
  font-size: 15px;
  color: var(--gray-light-40);
  margin-left: 8px;
  font-weight: normal;
  min-width: 85px;
}

.text-field-wrapper {
  display: flex;
  width: 250px;
  min-width: 250px;
}

.plus-minus {
  min-width: 10%;
  cursor: pointer;
  height: 56px;
  margin-left: 6px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}

.add {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ui-white);
  font-weight: 600;
  background: var(--ui-green);
  font-size: 18px;
  height: 28px;
  width: 28px;
  border-radius: 50%;
}

.add.disabled {
  background: var(--gray);
  cursor: not-allowed;
}

@media screen and (max-width: 530px) {
  .plan-name-total {
    flex-direction: column;
  }

  .plan {
    margin-bottom: 18px;
  }
}
</style>
