import type { BankAccount } from '@api/index'
import {
  connectTransactionAccount,
  getBanks,
  getConnectEarthSessionId,
  initiatePlaidTransactionConnection,
} from '@api/index'
import type { Notification } from '@/store/notification'
import VueI18n from '@/i18n'
import type { ActionContext } from 'vuex'
import type { StateInterface } from '@/store/index'
export interface Bank {
  id: string
  name: string
  accounts: BankAccount[]
  logo: string | null
}
export interface ConnectEarthSession {
  sessionId: string
  expiresAt: string
  routeParam: string
}
export interface BankAccountsModule {
  banks: Bank[]
  session: ConnectEarthSession
}
export const bankAccountsModule = {
  state: () =>
    ({
      banks: [],
      session: { sessionId: '', expiresAt: '', routeParam: '' },
    } as BankAccountsModule),
  mutations: {
    setBanks(state: BankAccountsModule, banks: Bank[]) {
      state.banks = banks
    },
    addBank(state: BankAccountsModule, bank: Bank) {
      state.banks.push(bank)
    },
    setSession(state: BankAccountsModule, { sessionId, expiresAt }: ConnectEarthSession) {
      state.session.sessionId = sessionId
      state.session.expiresAt = expiresAt
    },
    setRouteParam(state: BankAccountsModule, routeParam: string) {
      state.session.routeParam = routeParam
    },
  },
  actions: {
    async setBanks({ commit, dispatch }: ActionContext<BankAccountsModule, StateInterface>) {
      commit('setLoading', true)
      try {
        const { data } = await getBanks()
        const banks = data.map(({ id, name, accounts, logo }) => {
          return {
            id,
            name,
            accounts,
            logo,
          }
        })
        commit('setBanks', banks)
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
      commit('setLoading', false)
    },
    async connectBankAccount({
      commit,
      dispatch,
      getters,
    }: ActionContext<BankAccountsModule, StateInterface>) {
      commit('setLoading', true)
      try {
        const { data } = await initiatePlaidTransactionConnection()
        const linkHandler = window.Plaid.create({
          token: data.linkToken,
          onSuccess: async (public_token) => {
            // Send the public_token to your app server.
            await dispatch('addBank', public_token)
            if (!getters.getConnectEarthSessionId) {
              await dispatch('setSession')
            }
          },
          onExit: (err) => {
            // Optionally capture when your user exited the Link flow.
            // Storing this information can be helpful for support.
            console.error('error:', err)
          },
          onEvent: () => {
            // Optionally capture Link flow events, streamed through
            // this callback as your users connect an Item to Plaid.
          },
        })
        linkHandler.open()
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
      commit('setLoading', false)
    },
    async setSession({
      commit,
      getters,
      dispatch,
    }: ActionContext<BankAccountsModule, StateInterface>) {
      commit('setLoading', true)
      try {
        const { data } = await getConnectEarthSessionId()
        commit('setSession', data)
        const fiveMinutes = 5 * 60 * 1000
        const timeout =
          new Date(getters.getConnectEarthSessionIdExpirationDate).getTime() -
          new Date().getTime() -
          fiveMinutes
        if (timeout > 0) {
          setTimeout(async () => {
            await dispatch('setSession')
          }, timeout)
        }
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
      commit('setLoading', false)
    },
    async addBank(
      { commit, dispatch }: ActionContext<BankAccountsModule, StateInterface>,
      public_token,
    ) {
      commit('setLoading', true)
      try {
        const { data: newBankAccount } = await connectTransactionAccount({
          publicToken: public_token,
        })
        commit('addBank', newBankAccount)
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
      commit('setLoading', false)
    },
    async setRouteParam({ commit }: ActionContext<BankAccountsModule, StateInterface>, routeParam) {
      if (routeParam && routeParam !== '/') {
        commit('setRouteParam', routeParam)
      }
    },
  },
  getters: {
    getIfAnyBankAccountIsConnected: (state: BankAccountsModule): boolean => {
      return !!state.banks.length
    },
    getBankAccounts: (state: BankAccountsModule): Bank[] => {
      return state.banks
    },
    getConnectEarthSessionId(state: BankAccountsModule): string {
      return state.session.sessionId
    },
    getConnectEarthSessionIdExpirationDate(state: BankAccountsModule): string {
      return state.session.expiresAt
    },
    getRouteParam(state: BankAccountsModule): string {
      return state.session.routeParam
    },
  },
}
