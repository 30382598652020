<template>
  <div class="header">
    {{ t('header') }}
  </div>
  <div class="description">
    {{ t('description') }}
  </div>
  <div class="button-wrapper">
    <gs-button
      class="mb-4"
      :type="'primary'"
      :size="'large'"
      :disabled="loading"
      :loading="loading"
      @click="goToShopifyUrl"
    >
      {{ t('continue') }}
    </gs-button>
    <gs-button
      v-if="!account.userFromMarketplace"
      class="cancel-button"
      :type="'link'"
      :loading="loading"
      :disabled="loading"
      @click="onClickCancel"
    >
      {{ t('cancel') }}
    </gs-button>
  </div>
</template>

<script lang="ts">
import type { Account } from '@/store'
import type { Integration } from '@/store/integrations'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ShopifySetupView',
  data() {
    return {
      count: 0,
      loading: false,
    }
  },
  async created() {
    while (this.shouldDisplayShopifyOverlay && this.count <= 10) {
      this.count++
      await this.delay()
      await this.setIntegrations()
    }
  },
  methods: {
    t(key: string) {
      return this.$t(`ShopifySetupView.${key}`)
    },
    goToShopifyUrl() {
      this.loading = true
      const shopifyStores = this.userStores.filter(({ platform }) => platform === 'shopify')
      if (!shopifyStores.length) return

      window.open(shopifyStores[shopifyStores.length - 1].status.url, '_self')
    },
    async onClickCancel() {
      if (this.loading) return

      const shopifyStores = this.userStores.filter(({ platform }) => platform === 'shopify')
      if (!shopifyStores.length) return

      await this.deleteIntegration(shopifyStores[shopifyStores.length - 1].id)
      await this.$router.push({ name: 'Integrations' })
    },
    delay() {
      return new Promise<void>((resolve) => setTimeout(resolve, 2000))
    },
    setAccount(): Promise<void> {
      return this.$store.dispatch('setAccount')
    },
    setIntegrations(): Promise<void> {
      return this.$store.dispatch('setIntegrations')
    },
    deleteIntegration(integrationId: string): Promise<void> {
      return this.$store.dispatch('deleteIntegration', integrationId)
    },
    setAccountToStore(account: Account): void {
      return this.$store.commit('setAccount', account)
    },
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
    userStores(): Integration[] {
      return this.$store.getters['getUserStores']
    },
    shouldDisplayShopifyOverlay(): boolean {
      return this.$store.getters['getShouldDisplayShopifyOverlay']
    },
    lastShopifyIntegration(): Integration {
      return this.$store.getters['getLastShopifyIntegration']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.header {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;
}

.description {
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .header {
    font-size: 22px;
  }

  .description {
    font-size: 16px;
  }
}
</style>
