import { dismissOnboardingSteps, getOnboardingSteps, updateOnboardingSteps } from '@api/index'
import { includes } from '@/helpers/parsers'
import type { ActionContext } from 'vuex'
import type { StateInterface } from '@/store/index'

interface Checklist {
  isChecklistOpen: boolean
  hasChecklistBeenOpened: boolean
  setupChecklist: SetupChecklistItem[]
  isDismissed: boolean
  loading: boolean
}
export const CHECKLIST_ITEMS = [
  'plantTrees',
  'addIntegration',
  'addAutomation',
  'addWidgets',
  'customerEngagementPortal',
  'offsetTeam',
  'addReferral',
  'inviteUser',
] as const
export type ChecklistItemType = (typeof CHECKLIST_ITEMS)[number]
export interface SetupChecklistItem {
  value: boolean
  type: ChecklistItemType
}

export const checklistModule = {
  state: () =>
    ({
      isChecklistOpen: false,
      hasChecklistBeenOpened: false,
      setupChecklist: [],
      isDismissed: true,
      loading: false,
    } as Checklist),
  mutations: {
    setOpenState(state: Checklist, isChecklistOpen: boolean) {
      state.isChecklistOpen = isChecklistOpen
    },
    setHasChecklistBeenOpenedState(state: Checklist, hasChecklistBeenOpened: boolean) {
      state.hasChecklistBeenOpened = hasChecklistBeenOpened
    },
    setChecklist(state: Checklist, setupChecklist: SetupChecklistItem[]) {
      state.setupChecklist = setupChecklist
    },
    setChecklistLoading(state: Checklist, loading: boolean) {
      state.loading = loading
    },
    setDismiss(state: Checklist, isDismissed: boolean) {
      state.isDismissed = isDismissed
    },
  },
  actions: {
    async setChecklist({ commit }: ActionContext<Checklist, StateInterface>) {
      commit('setChecklistLoading', true)
      const onboardingSteps = await getOnboardingSteps()
      if (onboardingSteps) {
        const isDismissed = onboardingSteps.dismiss ? onboardingSteps.dismiss.value : true
        commit('setDismiss', isDismissed)
        const checklist = Object.entries(onboardingSteps)
          .filter(([key]) => includes(CHECKLIST_ITEMS, key))
          .sort((a, b) => a[1].priority - b[1].priority)
          .map(([key, { value }]) => {
            return {
              value,
              type: key,
            }
          })
        if (checklist.length) commit('setChecklist', checklist)
        commit('setChecklistLoading', false)
      }
    },
    async updateChecklist(
      { commit }: ActionContext<Checklist, StateInterface>,
      listItem: ChecklistItemType,
    ) {
      commit('setChecklistLoading', true)
      const onboardingSteps = await updateOnboardingSteps(listItem)
      const isDismissed = onboardingSteps.dismiss?.value
      commit('setChecklistLoading', isDismissed)
      const checklist = Object.entries(onboardingSteps)
        .filter(([key]) => includes(CHECKLIST_ITEMS, key))
        .sort((a, b) => a[1].priority - b[1].priority)
        .map(([key, { value }]) => {
          return {
            value,
            type: key,
          }
        })
      commit('setChecklist', checklist)
      commit('setChecklistLoading', false)
    },
    async dismissChecklist({ commit }: ActionContext<Checklist, StateInterface>) {
      commit('setChecklistLoading', true)
      const onboardingSteps = await dismissOnboardingSteps()
      const isDismissed = onboardingSteps.dismiss?.value
      commit('setDismiss', isDismissed)
      commit('setChecklistLoading', false)
    },
  },

  getters: {
    getProgressPercent(state: Checklist) {
      const maxValue = state.setupChecklist.length
      const value = state.setupChecklist.filter(({ value }) => value).length
      return Math.round((value / maxValue) * 100)
    },
    getSetupChecklist(state: Checklist): SetupChecklistItem[] {
      return state.setupChecklist
    },
    getChecklistOpenState(state: Checklist): boolean {
      return state.isChecklistOpen
    },
    getChecklistDismissed(state: Checklist): boolean {
      return state.isDismissed
    },
    getHasChecklistBeenOpened(state: Checklist): boolean {
      return state.hasChecklistBeenOpened
    },
    getSetupChecklistItems(state: Checklist): SetupChecklistItem[] {
      return state.setupChecklist
    },
    getChecklistLoading(state: Checklist): boolean {
      return state.loading
    },
  },
}
