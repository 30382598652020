<template>
  <div>
    <div v-if="!loading">
      <h1>Delete subscription</h1>
      <span> This functionality delete the subscription of the selected account. </span>
      <v-autocomplete
        v-model="selectedAccountItem"
        v-model:search="search"
        :loading="loading"
        :items="accounts.map((account) => `${account.accountId} - ${account.companyName}`)"
        :label="'Accounts'"
        variant="outlined"
        color="#3B755F"
        item-color="#3B755F"
        class="dropdown"
        :placeholder="'Min 3 characters to search'"
        :rules="[rules.required]"
      />
      <v-text-field
        id="Reason"
        v-model="reason"
        class="input-field"
        name="Reason"
        :rules="[rules.required]"
        :label="'Reason'"
        variant="outlined"
        flat
        color="green"
        hide-details
      />
      <v-dialog v-model="dialog" width="480">
        <template v-slot:activator="{ props }">
          <gs-button
            v-bind="props"
            :disabled="loading || !selectedAccountItem || !reason"
            :loading="loading"
            :full-width="true"
            size="large"
            @click.prevent="openDialog"
          >
            Save
          </gs-button>
        </template>
        <div class="pop-up">
          <div class="close-wrapper" @click="dialog = false">
            <img
              style="width: 25px"
              class="close-icon"
              :src="require('@/assets/icons/close_icon.svg')"
              alt="close"
            />
          </div>
          <div>
            <h2>Are you sure you want to delete the following subscription?</h2>
            <div>Company name: {{ getCompanyName() }}</div>
            <div>Fist name: {{ user?.firstName }}</div>
            <div>Last name: {{ user?.lastName }}</div>
            <div>Email: {{ user?.email }}</div>
            <gs-button
              :disabled="loading || !selectedAccountItem || !reason"
              :loading="loading"
              :full-width="true"
              size="large"
              @click.prevent="deleteSubscription"
            >
              Confirm
            </gs-button>
          </div>
        </div>
      </v-dialog>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script lang="ts">
import type { AccountDto, UserDto } from '@api/index'
import { adminDeleteSubscription, fetchAllAccount, fetchAllUser } from '@api/index'
import Loading from '@/components/tools/Loading.vue'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { Notification } from '@/store/notification'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DeleteSubscriptionView',
  components: { Loading },
  mixins: [RulesMixin],
  data() {
    return {
      loading: false,
      selectedAccountItem: '',
      user: undefined,
      accounts: [],
      search: '',
      reason: '',
      dialog: false,
    } as {
      loading: boolean
      selectedAccountItem: string
      user: UserDto | undefined
      accounts: AccountDto[]
      search: string
      reason: string
      dialog: boolean
    }
  },
  methods: {
    async openDialog() {
      this.dialog = true
    },
    getCompanyName() {
      const accountId = this.selectedAccountItem.split(' - ')[0]
      return (
        (this.accounts || []).find((account) => account.accountId === accountId)?.companyName || ''
      )
    },
    async deleteSubscription() {
      this.loading = true
      try {
        const accountId = this.selectedAccountItem.split(' - ')[0]
        const account = this.accounts.find((account) => account.accountId === accountId)

        if (account) {
          await adminDeleteSubscription(account.accountId, this.reason)
          this.selectedAccountItem = ''
          this.reason = ''
          this.search = ''
          this.dialog = false
          this.accounts = []
          this.user = undefined
          this.$store.dispatch('notification/notify', { text: 'Success' } as Notification)
        }
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: 'Error',
          isError: true,
        } as Notification)
      } finally {
        this.loading = false
      }
    },
    onSearchChange() {
      if (this.search && this.search.length > 3) {
        const search = this.search.split(' - ')[0]
        fetchAllAccount(search).then((accounts) => {
          this.accounts = accounts
        })
      }
    },
    onSelectedAccountItemChange() {
      if (this.selectedAccountItem) {
        const accountId = this.selectedAccountItem.split(' - ')[0]
        fetchAllUser(accountId).then((res) => {
          this.user = res[0]
        })
      }
    },
  },
  watch: {
    search: [
      {
        handler: 'onSearchChange',
      },
    ],
    selectedAccountItem: [
      {
        handler: 'onSelectedAccountItemChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.dropdown {
  margin: 10px 0;
}

.input-field {
  margin: 10px 0;
}

.pop-up {
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--ui-white);
  min-height: 100%;
  overflow-y: scroll;
}

.close-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
  cursor: pointer;
}

.dialog-button {
  margin: 10px 0;
}
</style>
