<template>
  <div>
    <div class="description">
      <div class="description-header">
        {{ t('header') }}
      </div>
      <div class="description-text">
        {{ t('text') }}
      </div>
    </div>
    <ProjectCardList>
      <ProjectCard
        v-for="(project, a) in projects"
        :key="a"
        :title="project.name"
        :subtitle="project.description"
        :image="project.imageUrl"
        :link="project.link"
      />
    </ProjectCardList>
  </div>
</template>

<script lang="ts">
import ProjectCard from '@/components/projects/ProjectCard.vue'
import type { Account } from '@/store'
import ProjectCardList from '@/components/projects/ProjectCardList.vue'
import type { Project } from '@/store/projects'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SupportedProjects',
  components: {
    ProjectCardList,
    ProjectCard,
  },
  async created() {
    await this.setProjectList()
  },
  methods: {
    t(key: string) {
      return this.$t(`SupportedProjects.${key}`)
    },
    url(site: string) {
      switch (site) {
        case 'eden':
          window.open('https://edenprojects.org/', '_blank')
          break
        case 'unfccc':
          window.open('https://unfccc.int/', '_blank')
          break
      }
    },
    setProjectList(): Promise<void> {
      return this.$store.dispatch('setProjectList')
    },
  },
  computed: {
    projects(): Project[] {
      return this.$store.getters['getProjectsWithLink']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
})
</script>

<style lang="scss" scoped>
.description {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  margin-top: 35px;
  margin-bottom: 25px;
}

.description-header {
  margin-bottom: 10px;
  color: #212121;
  font-size: 22px;
  font-weight: 600;
}

.description-text {
  color: #212121;
  font-size: 16px;
  margin-top: 4px;
}

.shopify-annoyance {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 1px -1px 10px -1px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 1px -1px 10px -1px rgb(0 0 0 / 10%);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  margin-top: 45px;
  margin-bottom: 25px;
}

.subheader-shopify {
  margin-bottom: 10px;
  color: #212121;
  font-size: 22px;
  font-weight: 600;
}

.text-shopify {
  color: #212121;
  font-size: 16px;
}

.note {
  font-size: 12px;
  margin-top: 12px;
  color: #212121;
}
</style>
