import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-492f87cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image" }
const _hoisted_2 = { class: "outer-qr" }
const _hoisted_3 = {
  key: 0,
  class: "scan-wrapper"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "input-outer" }
const _hoisted_10 = {
  class: "input-wrapper",
  style: {"margin-bottom":"15px"}
}
const _hoisted_11 = { class: "input-label" }
const _hoisted_12 = { class: "colour-squares" }
const _hoisted_13 = {
  class: "input-wrapper align-center",
  style: {"margin-bottom":"15px"}
}
const _hoisted_14 = { class: "input-label" }
const _hoisted_15 = {
  class: "input-wrapper",
  style: {"margin-bottom":"15px"}
}
const _hoisted_16 = {
  class: "input-wrapper",
  style: {"margin-bottom":"15px"}
}
const _hoisted_17 = {
  class: "input-wrapper",
  style: {"flex-direction":"column"}
}
const _hoisted_18 = { class: "input-label" }
const _hoisted_19 = { class: "download-disclaimer mb-2" }
const _hoisted_20 = {
  key: 0,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QRCodeRenderer = _resolveComponent("QRCodeRenderer")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_call_to_upgrade_popup = _resolveComponent("call-to-upgrade-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        ref: "printMe",
        class: "qr-code-margin",
        style: _normalizeStyle(_ctx.codeColour !== '' ? `background: ${_ctx.codeColour}` : '')
      }, [
        _createVNode(_component_QRCodeRenderer, {
          text: _ctx.url,
          color: _ctx.background,
          "bg-color": _ctx.codeColour,
          size: _ctx.size
        }, null, 8, ["text", "color", "bg-color", "size"]),
        (_ctx.showFrame)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.selectedColour !== 'beige' && _ctx.selectedColour !== 'white')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "scan-text",
                    style: _normalizeStyle({
              fontSize: _ctx.scanTextFontSize(_ctx.size),
            })
                  }, _toDisplayString(_ctx.customText ? _ctx.customText : _ctx.t('scan')), 5))
                : _createCommentVNode("", true),
              (_ctx.selectedColour === 'beige')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "scan-text",
                    style: _normalizeStyle({
              fontSize: _ctx.scanTextFontSize(_ctx.size),
              color: '#F2EBDB',
            })
                  }, _toDisplayString(_ctx.customText ? _ctx.customText : _ctx.t('scan')), 5))
                : _createCommentVNode("", true),
              (_ctx.selectedColour === 'white')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "scan-text",
                    style: _normalizeStyle({
              fontSize: _ctx.scanTextFontSize(_ctx.size),
              color: '#F9F9F9',
            })
                  }, _toDisplayString(_ctx.customText ? _ctx.customText : _ctx.t('scan')), 5))
                : _createCommentVNode("", true),
              (_ctx.selectedColour === 'green')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 3,
                    class: "logo",
                    src: require('@/assets/greenspark-logo.svg'),
                    alt: "greenspark-app",
                    style: _normalizeStyle(_ctx.size < 160 ? 'max-width: 80px;' : '')
                  }, null, 12, _hoisted_4))
                : _createCommentVNode("", true),
              (_ctx.selectedColour === 'blue')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 4,
                    class: "logo",
                    src: require('@/assets/greenspark-logo-blue.svg'),
                    alt: "greenspark-app",
                    style: _normalizeStyle(_ctx.size < 160 ? 'max-width: 80px;' : '')
                  }, null, 12, _hoisted_5))
                : _createCommentVNode("", true),
              (_ctx.selectedColour === 'beige')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 5,
                    class: "logo",
                    src: require('@/assets/greenspark-logo-beige.svg'),
                    alt: "greenspark-app",
                    style: _normalizeStyle(_ctx.size < 160 ? 'max-width: 80px;' : '')
                  }, null, 12, _hoisted_6))
                : _createCommentVNode("", true),
              (_ctx.selectedColour === 'white')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 6,
                    class: "logo",
                    src: require('@/assets/greenspark-logo-white.svg'),
                    alt: "greenspark-app",
                    style: _normalizeStyle(_ctx.size < 160 ? 'max-width: 80px;' : '')
                  }, null, 12, _hoisted_7))
                : _createCommentVNode("", true),
              (_ctx.selectedColour === 'black')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 7,
                    class: "logo",
                    src: require('@/assets/greenspark-logo-black.svg'),
                    alt: "greenspark-app",
                    style: _normalizeStyle(_ctx.size < 160 ? 'max-width: 80px;' : '')
                  }, null, 12, _hoisted_8))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ], 4)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t('color')), 1),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", {
            class: "color-square",
            style: _normalizeStyle(
              _ctx.selectedColour === 'green'
                ? 'background: #3B755F; border: 1.5px solid #B0B0B0;'
                : 'background: #3B755F'
            ),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.colourSelectClick('green')))
          }, null, 4),
          _createElementVNode("div", {
            class: "color-square",
            style: _normalizeStyle(
              _ctx.selectedColour === 'blue'
                ? 'background: #2E3A8C; border: 1.5px solid #B0B0B0;'
                : 'background: #2E3A8C'
            ),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.colourSelectClick('blue')))
          }, null, 4),
          _createElementVNode("div", {
            class: "color-square",
            style: _normalizeStyle(
              _ctx.selectedColour === 'beige'
                ? 'background: #F2EBDB; border: 1.5px solid #B0B0B0;'
                : 'background: #F2EBDB'
            ),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.colourSelectClick('beige')))
          }, null, 4),
          _createElementVNode("div", {
            class: "color-square",
            style: _normalizeStyle(
              _ctx.selectedColour === 'white'
                ? 'background: #FFFFFF; border: 1.5px solid #B0B0B0;'
                : 'background: #FFFFFF'
            ),
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.colourSelectClick('white')))
          }, null, 4),
          _createElementVNode("div", {
            class: "color-square",
            style: _normalizeStyle(
              _ctx.selectedColour === 'black'
                ? 'background: #212121; border: 1.5px solid #B0B0B0;'
                : 'background: #212121'
            ),
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.colourSelectClick('black')))
          }, null, 4)
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.t('hide_frame')), 1),
        _createVNode(_component_v_checkbox, {
          modelValue: _ctx.showFrame,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showFrame) = $event)),
          color: "#3B755F",
          "hide-details": "",
          style: {"margin":"0","margin-right":"-10px"}
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_v_text_field, {
          modelValue: _ctx.customText,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.customText) = $event)),
          class: "input-field",
          label: _ctx.t('custom_text'),
          variant: "outlined",
          flat: "",
          color: "#3B755F",
          style: {"margin-top":"15px"},
          counter: "",
          maxlength: "35"
        }, null, 8, ["modelValue", "label"])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_v_select, {
          modelValue: _ctx.selectedFileFormat,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedFileFormat) = $event)),
          items: _ctx.availableFileFormats,
          label: _ctx.t('file_format'),
          density: "compact",
          color: "#3B755F",
          variant: "outlined"
        }, null, 8, ["modelValue", "items", "label"])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.t('size')), 1),
        _createVNode(_component_v_slider, {
          modelValue: _ctx.size,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.size) = $event)),
          max: _ctx.windowWidth < 550 ? '220' : '400',
          min: "120",
          color: "#3B755F",
          "track-color": "#AFC6BD",
          "track-fill-color": "#3B755F"
        }, null, 8, ["modelValue", "max"])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.t('disclaimer')), 1),
        _createVNode(_component_gs_button, {
          loading: _ctx.loading,
          disabled: _ctx.loading,
          "full-width": true,
          size: "large",
          onClick: _withModifiers(_ctx.downloadImg, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('download')), 1)
          ]),
          _: 1
        }, 8, ["loading", "disabled", "onClick"])
      ]),
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.t('error_message')), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isCallToUpgradePopupOpen)
      ? (_openBlock(), _createBlock(_component_call_to_upgrade_popup, {
          key: 0,
          onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.isCallToUpgradePopupOpen = false)),
          "is-premium-only": false
        }))
      : _createCommentVNode("", true)
  ]))
}