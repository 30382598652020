import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d55c070"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex"} }
const _hoisted_2 = { style: {"width":"300px","margin-right":"40px"} }
const _hoisted_3 = {
  key: 0,
  style: {"background":"red","color":"white"}
}
const _hoisted_4 = {
  key: 0,
  class: "button-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_autocomplete, {
      modelValue: _ctx.selectedAccountItem,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedAccountItem) = $event)),
      "search-input": _ctx.search,
      "onUpdate:searchInput": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
      loading: _ctx.loading,
      items: _ctx.accounts.map((account) => `${account.accountId} - ${account.companyName}`),
      label: 'Accounts',
      variant: "outlined",
      color: "#3B755F",
      "item-color": "#3B755F",
      class: "dropdown",
      placeholder: 'Min 3 characters to search',
      rules: [_ctx.rules.required]
    }, null, 8, ["modelValue", "search-input", "loading", "items", "rules"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.OFFSET_TYPES(), (offsetType) => {
      return (_openBlock(), _createElementBlock("div", { key: offsetType }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_v_text_field, {
              modelValue: _ctx.impacts[offsetType].amount,
              "onUpdate:modelValue": ($event: any) => ((_ctx.impacts[offsetType].amount) = $event),
              label: 'Amount',
              variant: "outlined",
              clearable: "",
              type: "number",
              flat: "",
              min: "0",
              color: "#3B755F",
              class: "employee-input"
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _createVNode(_component_gs_button, {
            class: _normalizeClass('impact-button'),
            size: "large",
            width: "300",
            loading: _ctx.loading,
            disabled: !_ctx.selectedAccountItem || !_ctx.impacts[offsetType],
            onClick: () => _ctx.openUploadModal(offsetType)
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Upload " + _toDisplayString(offsetType) + " receipts (" + _toDisplayString(_ctx.impacts[offsetType].urls.length) + ") ", 1)
            ]),
            _: 2
          }, 1032, ["loading", "disabled", "onClick"])
        ])
      ]))
    }), 128)),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, " An error occured: " + _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_v_btn, {
      class: "button",
      color: "#3B755F",
      height: "54",
      width: "100%",
      style: {"margin-bottom":"0"},
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveUpdatedUsers()))
    }, {
      default: _withCtx(() => [
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Save"))
          : _createCommentVNode("", true),
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_v_progress_circular, {
              key: 1,
              indeterminate: "",
              color: "#F9F9F9"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}