<template>
  <section class="select-shopify-order-origin">
    <p
      :class="['select-shopify-order-origin__title', { onboarding: $route.path === '/onboarding' }]"
    >
      {{ t('title') }}
    </p>

    <v-radio-group v-model="localValue" @update:model-value="input" hide-details>
      <v-radio v-for="origin in shopifyOrderOrigins" :key="origin" :value="origin">
        <template v-slot:label>
          <span class="label">
            {{ t(origin) }}
          </span>
        </template>
      </v-radio>
    </v-radio-group>
  </section>
</template>

<script lang="ts">
import type { StoreIntegrationTrigger, ShopifyOrderOrigin } from '@/helpers/interfaces'
import { SHOPIFY_ORDER_ORIGINS } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SelectShopifyOrderOrigin',
  emits: ['update:model-value', 'input'],
  data() {
    return {
      localValue: 'all',
      shopifyOrderOrigins: SHOPIFY_ORDER_ORIGINS,
    } as {
      localValue: ShopifyOrderOrigin
      shopifyOrderOrigins: typeof SHOPIFY_ORDER_ORIGINS
    }
  },
  created() {
    this.localValue = this.modelValue
    this.shopifyOrderOrigins = SHOPIFY_ORDER_ORIGINS
    this.$emit('input', this.localValue)
  },
  methods: {
    t(key: string) {
      return this.$t(`SelectShopifyOrderOrigin.${key}`)
    },
    input() {
      this.$emit('update:model-value', this.localValue)
    },
    onValueChange() {
      this.localValue = this.modelValue
    },
  },
  props: {
    modelValue: {
      default: 'all',
      type: String as PropType<ShopifyOrderOrigin>,
    },
    trigger: {
      type: Object as PropType<StoreIntegrationTrigger>,
    },
    integrationName: {
      type: String,
    },
  },
  watch: {
    modelValue: [
      {
        handler: 'onValueChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.select-shopify-order-origin__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px;
}

.select-shopify-order-origin__title.onboarding {
  font-size: 24px;
}

.label {
  font-size: 18px;
  line-height: 26px;
  color: var(--font-color-primary);
}
</style>
