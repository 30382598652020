<template>
  <div class="qr-code-main-wrapper">
    <div class="back" @click="$router.go(-1)">
      <img class="chevron" alt="chevron" :src="require('@/assets/icons/chevron_left.svg')" />
      <div>
        {{ t('back') }}
      </div>
    </div>

    <div class="content-wrapper">
      <HowItWorksUtility
        :header="`${t('how_it_works')}`"
        :subheader="
          account.accountType === 'business' || account.accountType === 'ecommerce'
            ? `${t('how_it_works1')}`
            : `${t('how_it_works1_personal')}`
        "
        :text="`${t('customise')}`"
        :text1="`${t('download')}`"
        :text2="
          account.accountType === 'business' || account.accountType === 'ecommerce'
            ? `${t('done')}`
            : `${t('done_personal')}`
        "
      />

      <FunkyBackground />
    </div>
  </div>
</template>

<script lang="ts">
import HowItWorksUtility from '@/components/shareables/HowItWorksUtility.vue'
import FunkyBackground from '@/components/shareables/FunkyBackground.vue'
import type { Account } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'QrCode',
  components: {
    HowItWorksUtility,
    FunkyBackground,
  },
  created() {
    window.scrollTo(0, 0)
  },
  methods: {
    t(key: string) {
      return this.$t(`QrCode.${key}`)
    },
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
})
</script>

<style lang="scss" scoped>
.qr-code-main-wrapper {
  animation-delay: 0.25s;
  animation: fadeInAnimation ease 0.99s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.back {
  display: flex;
  font-weight: 500;
  color: var(--ui-green);
  cursor: pointer;
  margin-bottom: 7px;
  width: 70px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 3px;
}

.chevron {
  width: 23px;
  filter: invert(39%) sepia(15%) saturate(1175%) hue-rotate(106deg) brightness(96%) contrast(87%);
}

.content-wrapper {
  background: var(--ui-white);
  border-radius: 5px;
  padding: 36px;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
}
</style>
