<template>
  <div class="add-integration-view">
    <select-integration @integration-selected="navigate" />
  </div>
</template>

<script lang="ts">
import type { SelectedIntegration } from '@/components/onboarding/SelectIntegration.vue'
import SelectIntegration from '@/components/onboarding/SelectIntegration.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IntegrationsView',
  components: {
    SelectIntegration,
  },
  methods: {
    t(key: string) {
      return this.$t(`IntegrationsView.${key}`)
    },
    async navigate(integration: SelectedIntegration) {
      await this.$router.push({
        name: 'AddIntegrationsAndAutomations',
        query: { platform: integration.platform },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.add-integration-view {
  border-radius: 10px;
  background: white;
}
</style>
