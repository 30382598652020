<script lang="ts">
import type { TranslateResult } from 'vue-i18n'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export interface ValueSelectorValue {
  value: string
  label: TranslateResult | string
}

export default defineComponent({
  name: 'ValueSelector',
  emits: ['select'],
  computed: {
    selectedValueIndex(): number {
      return this.values.findIndex((v) => v.value === this.selectedValue) || 0
    },
  },
  methods: {
    onValueChange(index: number) {
      if (index < 0) return
      this.$emit('select', this.values[index].value)
    },
  },
  props: {
    values: {
      required: true,
      type: Array as PropType<ValueSelectorValue[]>,
    },
    selectedValue: {
      required: true,
      type: String,
    },
  },
})
</script>

<template>
  <div>
    <v-chip-group
      selected-class="selected-value"
      column
      :model-value="selectedValueIndex"
      @update:model-value="onValueChange"
    >
      <v-chip v-for="v in values" :key="v.value" label class="value-chip">
        {{ v.label }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<style scoped lang="scss">
.value-chip {
  color: var(--ui-white) !important;
  background-color: var(--gray-light-A9) !important;
  margin-right: 16px !important;

  &.selected-value {
    background-color: var(--ui-black) !important;
  }
}
</style>
