<template>
  <div class="image">
    <div class="outer-qr">
      <div
        ref="printMe"
        class="qr-code-margin"
        :style="codeColour !== '' ? `background: ${codeColour}` : ''"
      >
        <QRCodeRenderer :text="url" :color="background" :bg-color="codeColour" :size="size" />
        <div v-if="showFrame" class="scan-wrapper">
          <div
            v-if="selectedColour !== 'beige' && selectedColour !== 'white'"
            class="scan-text"
            :style="{
              fontSize: scanTextFontSize(size),
            }"
          >
            {{ customText ? customText : t('scan') }}
          </div>
          <div
            v-if="selectedColour === 'beige'"
            class="scan-text"
            :style="{
              fontSize: scanTextFontSize(size),
              color: '#F2EBDB',
            }"
          >
            {{ customText ? customText : t('scan') }}
          </div>
          <div
            v-if="selectedColour === 'white'"
            class="scan-text"
            :style="{
              fontSize: scanTextFontSize(size),
              color: '#F9F9F9',
            }"
          >
            {{ customText ? customText : t('scan') }}
          </div>
          <img
            v-if="selectedColour === 'green'"
            class="logo"
            :src="require('@/assets/greenspark-logo.svg')"
            alt="greenspark-app"
            :style="size < 160 ? 'max-width: 80px;' : ''"
          />
          <img
            v-if="selectedColour === 'blue'"
            class="logo"
            :src="require('@/assets/greenspark-logo-blue.svg')"
            alt="greenspark-app"
            :style="size < 160 ? 'max-width: 80px;' : ''"
          />
          <img
            v-if="selectedColour === 'beige'"
            class="logo"
            :src="require('@/assets/greenspark-logo-beige.svg')"
            alt="greenspark-app"
            :style="size < 160 ? 'max-width: 80px;' : ''"
          />
          <img
            v-if="selectedColour === 'white'"
            class="logo"
            :src="require('@/assets/greenspark-logo-white.svg')"
            alt="greenspark-app"
            :style="size < 160 ? 'max-width: 80px;' : ''"
          />
          <img
            v-if="selectedColour === 'black'"
            class="logo"
            :src="require('@/assets/greenspark-logo-black.svg')"
            alt="greenspark-app"
            :style="size < 160 ? 'max-width: 80px;' : ''"
          />
        </div>
      </div>
    </div>

    <div class="input-outer">
      <div class="input-wrapper" style="margin-bottom: 15px">
        <div class="input-label">
          {{ t('color') }}
        </div>
        <div class="colour-squares">
          <div
            class="color-square"
            :style="
              selectedColour === 'green'
                ? 'background: #3B755F; border: 1.5px solid #B0B0B0;'
                : 'background: #3B755F'
            "
            @click="colourSelectClick('green')"
          />
          <div
            class="color-square"
            :style="
              selectedColour === 'blue'
                ? 'background: #2E3A8C; border: 1.5px solid #B0B0B0;'
                : 'background: #2E3A8C'
            "
            @click="colourSelectClick('blue')"
          />
          <div
            class="color-square"
            :style="
              selectedColour === 'beige'
                ? 'background: #F2EBDB; border: 1.5px solid #B0B0B0;'
                : 'background: #F2EBDB'
            "
            @click="colourSelectClick('beige')"
          />
          <div
            class="color-square"
            :style="
              selectedColour === 'white'
                ? 'background: #FFFFFF; border: 1.5px solid #B0B0B0;'
                : 'background: #FFFFFF'
            "
            @click="colourSelectClick('white')"
          />
          <div
            class="color-square"
            :style="
              selectedColour === 'black'
                ? 'background: #212121; border: 1.5px solid #B0B0B0;'
                : 'background: #212121'
            "
            @click="colourSelectClick('black')"
          />
        </div>
      </div>

      <div class="input-wrapper align-center" style="margin-bottom: 15px">
        <div class="input-label">
          {{ t('hide_frame') }}
        </div>
        <v-checkbox
          v-model="showFrame"
          color="#3B755F"
          hide-details
          style="margin: 0; margin-right: -10px"
        />
      </div>

      <div class="input-wrapper" style="margin-bottom: 15px">
        <v-text-field
          v-model="customText"
          class="input-field"
          :label="t('custom_text')"
          variant="outlined"
          flat
          color="#3B755F"
          style="margin-top: 15px"
          counter
          maxlength="35"
        />
      </div>

      <div class="input-wrapper" style="margin-bottom: 15px">
        <v-select
          v-model="selectedFileFormat"
          :items="availableFileFormats"
          :label="t('file_format')"
          density="compact"
          color="#3B755F"
          variant="outlined"
        />
      </div>

      <div class="input-wrapper" style="flex-direction: column">
        <div class="input-label">
          {{ t('size') }}
        </div>
        <v-slider
          v-model="size"
          :max="windowWidth < 550 ? '220' : '400'"
          min="120"
          color="#3B755F"
          track-color="#AFC6BD"
          track-fill-color="#3B755F"
        />
      </div>

      <div>
        <p class="download-disclaimer mb-2">{{ t('disclaimer') }}</p>
        <gs-button
          :loading="loading"
          :disabled="loading"
          :full-width="true"
          size="large"
          @click.prevent="downloadImg"
        >
          {{ t('download') }}
        </gs-button>
      </div>

      <div v-if="errorMessage" class="error-message">
        {{ t('error_message') }}
      </div>
    </div>
    <call-to-upgrade-popup
      v-if="isCallToUpgradePopupOpen"
      @close="isCallToUpgradePopupOpen = false"
      :is-premium-only="false"
    />
  </div>
</template>

<script lang="ts">
import QRCodeRenderer from '@/components/shareables/QRCodeRenderer.vue'
import { createLinkForQrCode } from '@api/index'
import CallToUpgradePopup from '@/components/common/CallToUpgradePopup.vue'
import type { Account } from '@/store'
import type { User } from '@/store/users'
import html2canvas from 'html2canvas'
import ImageTracer from 'imagetracerjs'
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'

type ImageFileFormat = {
  title: string
  value: 'image/png' | 'image/jpeg' | 'image/svg+xml'
}

const png: ImageFileFormat = { title: 'Image file (PNG)', value: 'image/png' }
const svg: ImageFileFormat = { title: 'Vector file (SVG)', value: 'image/svg+xml' }

const svgTracerOptions = {
  pathomit: 0,
  roundcoords: 5,
  blurdelta: 0,
  ltres: 0.05,
  qtres: 0.05,
  numberofcolors: 8,
}

export default defineComponent({
  name: 'FunkyBackground',
  components: {
    CallToUpgradePopup,
    QRCodeRenderer,
  },
  data() {
    return {
      background: '#000',
      codeColour: '#fff',
      size: 300,
      selectedColour: '',
      availableFileFormats: [png, svg],
      selectedFileFormat: png.value,
      showFrame: true,
      url: '',
      windowWidth: 0,
      errorMessage: false,
      isCallToUpgradePopupOpen: false,
      loading: false,
      customText: '',
      scanTextFontSize: (s: number): string =>
        s < 160 ? '10px' : s > 160 && s < 300 ? '16px' : '18px',
    } as {
      background: string
      codeColour: string
      size: number
      selectedColour: string
      showFrame: boolean
      url: string
      windowWidth: number
      errorMessage: boolean
      isCallToUpgradePopupOpen: boolean
      loading: boolean
      customText: string
      scanTextFontSize: (s: number) => string
      availableFileFormats: ImageFileFormat[]
      selectedFileFormat: string
    }
  },
  created() {
    const companyNameUrl = this.account?.companyName
      ? encodeURIComponent(this.account?.companyName.replace(/\s/g, '-').toLowerCase())
      : ''
    this.url = `https://app.getgreenspark.com/dashboard/${this.account.livePageId}/${companyNameUrl}`
    this.colourSelectClick('black')
    window.addEventListener('resize', this.reportWindowSize)
    this.reportWindowSize()

    if (this.windowWidth < 550) {
      this.size = 220
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize)
  },
  methods: {
    t(key: string): TranslateResult {
      return this.$t(`FunkyBackground.${key}`)
    },
    reportWindowSize(): void {
      this.windowWidth = window.innerWidth
    },
    colourSelectClick(colour): void {
      this.selectedColour = colour
      switch (colour) {
        case 'green':
          this.background = '#3B755F'
          this.codeColour = '#F9F9F9'
          break
        case 'blue':
          this.background = '#2E3A8C'
          this.codeColour = '#F9F9F9'
          break
        case 'beige':
          this.background = '#F2EBDB'
          this.codeColour = '#212121'
          break
        case 'white':
          this.background = '#FFFFFF'
          this.codeColour = '#212121'
          break
        case 'black':
          this.background = '#212121'
          this.codeColour = '#F9F9F9'
          break
      }
    },
    async downloadImg(): Promise<void> {
      if (!this.qrCode) {
        this.isCallToUpgradePopupOpen = true
        return
      }

      this.loading = true
      try {
        this.errorMessage = false
        const res = await createLinkForQrCode()

        if (res.status === 200) {
          this.url = res.data

          const canvas = await this.generateQRCodeCanvas()
          this.downloadQRCodeImage(canvas)
        } else {
          this.errorMessage = true
        }
      } catch (error) {
        console.error('error:', error)
        this.errorMessage = true
      } finally {
        this.loading = false
      }
    },
    async generateQRCodeCanvas(): Promise<HTMLCanvasElement> {
      const qrElement = this.$refs.printMe as HTMLElement
      return await html2canvas(qrElement, { logging: false, scale: 10 })
    },
    async downloadQRCodeImage(canvas: HTMLCanvasElement): Promise<void> {
      const downloadLink = document.createElement('a')

      if (this.selectedFileFormat === png.value) {
        downloadLink.href = canvas.toDataURL(this.selectedFileFormat)
      } else {
        // Getting ImageData from canvas with the helper function getImgdata().
        const imgd = ImageTracer.getImgdata(canvas)
        // Synchronous tracing to SVG string
        downloadLink.href = `data:image/svg+xml;base64,${btoa(
          ImageTracer.imagedataToSVG(imgd, svgTracerOptions),
        )}`
      }

      downloadLink.download = 'Greenspark QR-Code'
      downloadLink.click()
    },
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
    qrCode(): boolean {
      return this.$store.getters['getQrCodeFeatureSetting']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.image {
  padding: 30px;
  background: url('../../assets/backgrounds/qr-code.svg') right;
  background-size: cover;
  border-radius: 8px;
  margin-top: 48px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.outer-qr {
  height: 480px;
  width: 400px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.qr-code-margin {
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scan-wrapper {
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scan-text {
  margin-bottom: 10px;
  // font-size: 12px;
  line-height: 14px;
  color: #212121;
}

.logo {
  max-width: 150px;
}

.input-outer {
  padding: 20px 14px 20px 14px;
  background: #f9f9f9;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  margin-left: 27px;
}

.input-wrapper {
  display: flex;
  justify-content: space-between;
}

.input-label {
  font-family: Cabin;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
}

.colour-squares {
  display: flex;
}

.color-square {
  width: 16px;
  height: 16px;
  margin-left: 2px;
  margin-right: 2px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.01);
  cursor: pointer;
}

.color-square:hover {
  opacity: 0.8;
}

.error-message {
  font-size: 16px;
  color: #f9f9f9;
  font-weight: 500;
  text-align: left;
  width: 100%;
  padding: 8px 20px;
  background: red;
  margin-top: 10px;
}

.download-disclaimer {
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
  padding: 0px 8px;
  margin: 0px auto;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .image {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .input-outer {
    margin-left: 0;
    margin-bottom: 24px;
    width: 100%;
    max-width: 420px;
  }
}
</style>
