<template>
  <div
    :id="`number${elementId}`"
    :class="
      numbers && numbers < (totalRows ?? 0 - 1) && !hidePartnerImage
        ? 'table-row-outer' + ' ' + 'hideScrollBar' + ' ' + rowColour
        : numbers && numbers < (totalRows ?? 0 - 1) && hidePartnerImage
        ? 'table-row-outer' + ' ' + 'hideScrollBar' + ' ' + 'no-padding' + ' ' + rowColour
        : numbers && numbers >= (totalRows ?? 0 - 1) && hidePartnerImage
        ? 'table-row-outer' + ' ' + 'showScrollBar' + ' ' + 'no-padding' + ' ' + rowColour
        : 'table-row-outer' + ' ' + 'showScrollBar' + ' ' + rowColour
    "
  >
    <!-- Partner image -->
    <div v-if="!hidePartnerImage" class="parter-image-wrapper">
      <img :src="partnerImage" class="partner-image" alt="image" />
    </div>

    <!-- Name -->
    <div v-if="name" :style="`width: ${columnWidth}px; min-width: 115px;`" class="name">
      {{ name }}
    </div>

    <!-- Items -->
    <div
      v-for="(item, a) in labels"
      :key="a"
      class="items-outer"
      :style="
        item.customWidth
          ? `min-width: ${item.customWidth}px`
          : `width: ${columnWidth}px; min-width: 115px;`
      "
    >
      <!-- Regular labels -->
      <div v-if="item.type === 'text'">
        {{ item.text }}
      </div>

      <!-- Impact labels -->
      <div v-if="item.type === 'impact' && item.labels.length > 0" class="impact-pills">
        <div
          v-for="(impactItem, b) in item.labels"
          :key="b"
          :style="b < item.labels.length - 1 ? 'margin-bottom: 6px;' : ''"
        >
          <div :class="'pill' + ' ' + impactItem.type">
            <div>
              {{ impactItem.text }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Status -->
    <div
      :style="
        status?.customWidth
          ? `min-width: ${status?.customWidth}px`
          : `width: ${columnWidth}px; min-width: 115px;`
      "
    >
      <div v-if="status" :class="status.text">
        {{ status.text }}
      </div>
    </div>

    <QuickMenu
      v-if="quickMenuItems && quickMenuItems.length > 0"
      :quick-menu-items="quickMenuItems"
      :user-id="userId"
      @quickMenuClick="quickMenuClick"
    />
    <div v-if="quickMenuItems && quickMenuItems.length === 0" style="min-width: 24px" />
  </div>
</template>

<script lang="ts">
import QuickMenu from '@/components/tools/Table/QuickMenu.vue'
import type { MenuItem } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import { type RowLabel } from '@/components/tools/Table/MainTable.vue'

export default defineComponent({
  name: 'Row',
  emits: ['emitScroll', 'quickMenuClick'],
  components: {
    QuickMenu,
  },
  data() {
    return {
      rowColour: '',
      elementId: 0,
    }
  },
  computed: {
    partnerImage(): string {
      return !this.image ? require('@/assets/icons/partners/partner-image-alt.svg') : this.image
    },
  },
  created() {
    this.rowColour = this.numbers && this.numbers % 2 === 0 ? 'light' : 'dark'
    this.elementId = this.numbers
      ? Math.floor(Math.random() * 1000000 * (this.numbers + 1) + this.numbers)
      : 0
  },
  mounted() {
    const element = document.querySelector<HTMLDivElement>(`#number${this.elementId}`)
    element?.addEventListener('scroll', this.scrollDetect)
  },
  unmounted() {
    const element = document.querySelector<HTMLDivElement>(`#number${this.elementId}`)
    if (element) element.removeEventListener('scroll', this.scrollDetect)
  },
  methods: {
    t(key: string) {
      return this.$t(`Row.${key}`)
    },
    scrollDetect() {
      const element = document.querySelector<HTMLDivElement>(`#number${this.elementId}`)
      const left = element?.scrollLeft
      this.$emit('emitScroll', left)
    },
    setScroll() {
      const element = document.querySelector<HTMLDivElement>(`#number${this.elementId}`)
      if (element) element.scrollLeft = this.scroll ?? 0
    },
    quickMenuClick(value) {
      this.$emit('quickMenuClick', this.relationsId, this.userId, this.name, value)
    },
    onScrollElementChange() {
      this.setScroll()
    },
  },
  props: {
    userId: {
      type: String,
    },
    relationsId: {
      type: String,
    },
    image: {
      type: String as PropType<string>,
    },
    labels: {
      type: Array as PropType<RowLabel[]>,
    },
    name: {
      type: String,
    },
    status: {
      type: Object as PropType<{ customWidth?: number; text: string }>,
    },
    numbers: {
      type: Number,
    },
    columnWidth: {
      type: Number,
    },
    scroll: {
      type: Number,
    },
    totalRows: {
      type: Number,
    },
    quickMenuItems: {
      type: Array as PropType<MenuItem[]>,
    },
    hidePartnerImage: {
      type: Boolean,
    },
  },
  watch: {
    scroll: [
      {
        handler: 'onScrollElementChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.table-row-outer {
  padding-top: 12px;
  padding-bottom: 10px;
  // padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
  color: #1a252e;
  overflow: scroll;
  min-height: 70px;
}

.no-padding {
  padding-left: 18px;
  padding-right: 18px;
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.showScrollBar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
  height: 6px;
}

.showScrollBar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.light {
  background: #ffffff;
}

.dark {
  background: rgba(175, 198, 189, 0.2);
}

.parter-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
}

.partner-image {
  width: 52px;
}

.name {
  padding: 0px 5px;
  text-align: left;
  word-break: break-word;
}

.items-outer {
  padding: 0px 5px;
}

.pill {
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 19px;
  color: #f9f9f9;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: inline-block;
  text-align: center;
}

.impact-pills {
  display: flex;
  flex-direction: column;
}

.plastic {
  background: #2e3a8c;
}

.carbon {
  background: #f5c26f;
  color: #212121;
}

.trees {
  background: #3b755f;
  color: #f2ebdb;
}

.plan {
  // border: 1px solid #3B755F;
  background: #a6cfe2;
  color: #212121;
}

.subscription {
  background: #afc6bd;
  color: #212121;
}

.allowance {
  background: #f2ebdb;
  color: #212121;
}

.active {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #3b755f;
  padding: 2px 6px;
  border: 1px solid #3b755f;
  box-sizing: border-box;
  border-radius: 8px;
  text-transform: capitalize;
  display: initial;
}

.pending {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #e57066;
  padding: 2px 6px;
  border: 1px solid #e57066;
  box-sizing: border-box;
  border-radius: 8px;
  text-transform: capitalize;
  display: initial;
}

.stopped {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #212121;
  padding: 2px 6px;
  border: 1px solid #212121;
  box-sizing: border-box;
  border-radius: 8px;
  text-transform: capitalize;
  display: initial;
}

.paid {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #3b755f;
  padding: 2px 6px;
  border: 1px solid #3b755f;
  box-sizing: border-box;
  border-radius: 8px;
  text-transform: capitalize;
  display: initial;
}

.quick-menu {
  cursor: pointer;
}

.quick-menu:hover {
  opacity: 0.5;
}
</style>
