import { defineComponent } from 'vue'
import type { Account } from '@/store'
import type { User } from '@/store/users'

export const LoadCrispMixin = defineComponent({
  methods: {
    loadCrisp() {
      if (process.env.VUE_APP_CRISP_WEBSITE_ID) {
        window.$crisp = []
        window.CRISP_WEBSITE_ID = process.env.VUE_APP_CRISP_WEBSITE_ID
        const d = document
        const s = d.createElement('script')
        s.src = 'https://client.crisp.chat/l.js'
        s.async = false
        d.getElementsByTagName('head')[0].appendChild(s)
        window.$crisp.push(['do', 'chat:hide'])
      }
    },
    toggleChatBubbleVisibility(visible: boolean) {
      visible ? window.$crisp.push(['do', 'chat:show']) : window.$crisp.push(['do', 'chat:hide'])
    },
    openChat(defaultMessage = '') {
      function closeChat() {
        window.$crisp.push(['do', 'chat:hide'])
      }
      window.$crisp.push(['do', 'chat:show'])
      window.$crisp.push(['do', 'chat:open'])
      // Add users email
      if (this.currentUser.email)
        window.$crisp.push(['set', 'user:email', [this.currentUser.email]])
      // add users name and company
      if (this.currentUser.firstName && this.currentUser.lastName)
        window.$crisp.push([
          'set',
          'user:nickname',
          [
            `${this.currentUser.firstName} ${this.currentUser.lastName} ${
              this.account?.companyName ? `(${this.account?.companyName})` : ''
            }`,
          ],
        ])

      if (defaultMessage) window.$crisp.push(['set', 'message:text', [defaultMessage]])

      setTimeout(function () {
        const chatBox = document.querySelector('#crisp-chatbox') as HTMLElement
        if (chatBox) chatBox.style.transform = 'none'
      }, 170)

      window.$crisp.push(['on', 'chat:closed', closeChat])
    },
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
  },
})
