<template>
  <div class="stat">
    <div class="text-number-wrapper">
      <div class="number">
        {{ modelValue }}
      </div>
      <div>
        {{ label }}
      </div>
    </div>
    <div />
  </div>
</template>

<script lang="ts">
import type { TranslateResult } from 'vue-i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StatCard',
  components: {},
  props: {
    modelValue: { required: true, type: String },
    label: { required: true, type: String as () => TranslateResult },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.stat {
  display: flex;
  justify-content: center;
  width: 48%;
  border-radius: 7px;
  padding: 16px 20px;
  text-align: center;
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
  max-height: 90px;
}

.text-number-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
}

.number {
  color: #212121;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 3px;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .stat {
    width: 100%;
    margin-bottom: 5px;
  }
}
</style>
