<template>
  <div class="wrapper">
    <div class="content-wrapper">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~vuetify/settings';

.wrapper {
  background-image: url('@/assets/backgrounds/choose-account-type-background.svg');
  background-size: cover;
  background-position: center;
  padding: 24px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media #{map-get($display-breakpoints, 'xs')} {
    padding: 16px;
  }
}

.content-wrapper {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
  background: #f9f9f9;
  box-shadow: 0 42px 76px rgba(0, 0, 0, 0.05), 0 27.2222px 44.5093px rgba(0, 0, 0, 0.037963),
    0 16.1778px 24.2074px rgba(0, 0, 0, 0.0303704), 0 8.4px 12.35px rgba(0, 0, 0, 0.025),
    0 3.42222px 6.19259px rgba(0, 0, 0, 0.0196296), 0 0.777778px 2.99074px rgba(0, 0, 0, 0.012037);
  border-radius: 8px;

  @media #{map-get($display-breakpoints, 'xs')} {
    padding: 40 16px;
  }
}
</style>
