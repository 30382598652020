import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-225efab4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "referrals-wrapper"
}
const _hoisted_2 = { class: "select-impact" }
const _hoisted_3 = { class: "subheader" }
const _hoisted_4 = { class: "share-impact" }
const _hoisted_5 = { class: "subheader" }
const _hoisted_6 = { class: "share-icons" }
const _hoisted_7 = { class: "referral-link-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HowItWorksUtility = _resolveComponent("HowItWorksUtility")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_ReferralShareIcon = _resolveComponent("ReferralShareIcon")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_HowItWorksUtility, {
          subheader: 
        _ctx.account.accountType === 'business' || _ctx.account.accountType === 'ecommerce'
          ? `${_ctx.t('description')}`
          : `${_ctx.t('description_personal')}`
      ,
          text: `${_ctx.t('how_it_works_1')}`,
          text1: `${_ctx.t('how_it_works_2')}`,
          text2: 
        _ctx.account.accountType === 'personal' || _ctx.account.accountType === 'family'
          ? `${_ctx.t('how_it_works_3_personal')}`
          : `${_ctx.t('how_it_works_3')}`
      
        }, null, 8, ["subheader", "text", "text1", "text2"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('choose')), 1),
          _createVNode(_component_v_form, {
            modelValue: _ctx.isFormValid,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isFormValid) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                modelValue: _ctx.referralImpact,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((_ctx.referralImpact) = $event)),
                  _ctx.onTypeChange
                ],
                rules: [_ctx.rules.required],
                items: _ctx.items,
                label: _ctx.t('choose_short'),
                variant: "outlined",
                color: "green",
                "item-color": "green",
                class: "dropdown"
              }, null, 8, ["modelValue", "rules", "items", "label", "onUpdate:modelValue"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('share')), 1),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.referralSocials, (platform, index) => {
              return (_openBlock(), _createBlock(_component_ReferralShareIcon, {
                key: index,
                platform: platform,
                disabled: !_ctx.isFormValid,
                "referral-link": _ctx.referralLink
              }, null, 8, ["platform", "disabled", "referral-link"]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: "referral-link",
              style: _normalizeStyle(_ctx.isFormValid ? 'background: #AFC6BD; border: 1px solid #F2EBDB;' : '')
            }, _toDisplayString(_ctx.referralLink), 5),
            _createVNode(_component_gs_button, {
              onClick: _ctx.onCopy,
              class: "copy-button",
              disabled: !_ctx.isFormValid,
              size: "large"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(!_ctx.copied ? _ctx.t('copy') : _ctx.t('copied')), 1)
              ]),
              _: 1
            }, 8, ["onClick", "disabled"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}