<template>
  <transition name="fade">
    <!-- TODO: Change this to used v-dialog -->
    <div v-if="isOpen" id="fullWidthPopup" class="full-width-popup">
      <div class="popup-title-wrapper">
        <h2 class="popup-title">
          {{ popupTitle }}
          <span class="popup-title-info">
            {{ popupTitleInfo }}
          </span>
        </h2>
      </div>

      <button class="close-button" @click="closePopup" />
      <div class="popup-content">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import type { TranslateResult } from 'vue-i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FullWidthPopup',
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    closePopup() {
      this.isOpen = false
    },
    onPopupIsOpened() {
      if (this.isOpen) document.documentElement.classList.add('modal-open')
      else document.documentElement.classList.remove('modal-open')
    },
  },
  props: {
    popupTitle: { type: String as () => TranslateResult | string },
    popupTitleInfo: { type: String, default: '' },
  },
  watch: {
    isOpen: [
      {
        handler: 'onPopupIsOpened',
      },
    ],
  },
})
</script>

<style lang="scss">
@import '~vuetify/settings';

.full-width-popup {
  background: url('@/assets/backgrounds/full-width-popup-background.svg') no-repeat top right/cover
    #ffffff;
  height: 100%;
  left: 0;
  padding: 20px 10px;
  position: fixed;
  top: 0;
  overflow: auto;
  width: 100%;
  z-index: 1999;
}

.close-button {
  position: absolute;
  cursor: pointer;
  right: 30px;
  top: 20px;
  height: 30px;
  width: 30px;
}

.close-button::before,
.close-button::after {
  background-color: var(--ui-black);
  content: '';
  display: block;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 2px;
}

.close-button::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-button::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.popup-title-wrapper {
  align-items: baseline;
  display: flex;
  padding-right: 50px;
  margin-bottom: 40px;
}

.popup-title {
  font-size: 28px;
  line-height: 30px;
}

.popup-title-info.popup-title-info {
  color: var(--gray);
  font-size: 16px;
  line-height: 30px;
  margin: 0 0 0 5px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .full-width-popup {
    padding: 30px;
  }

  .close-button {
    top: 30px;
  }
}
</style>
