<template>
  <div>
    <ImpactWalletView v-if="getIfB2B2C && $route.path !== '/your-impact'" />
    <BusinessHome v-else />
  </div>
</template>

<script lang="ts">
import BusinessHome from '@/components/Home/BusinessHome.vue'
import type { Account } from '@/store'
import ImpactWalletView from '@/views/ImpactWalletView.vue'
import type { User } from '@/store/users'
import { isProductionEnvironment } from '@/helpers/constants'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home',
  components: {
    ImpactWalletView,
    BusinessHome,
  },
  async created() {
    if (this.account.accountType === 'admin') {
      this.$router.push('/admin/home')
    }
  },
  mounted() {
    if (isProductionEnvironment && !window.location.href.includes('dashboard')) {
      this.setUpHeap()
    }
  },
  methods: {
    t(key: string) {
      return this.$t(`HomeView.${key}`)
    },
    setUpHeap() {
      window.heap.addUserProperties({
        signupDate: this.account.signupDate,
        currency: this.account.currency,
        country: '',
        impacts: this.account.impacts,
        transferredImpacts: this.account.transferredImpacts,
      })
      if (this.account.accountType === 'business' || this.account.accountType === 'ecommerce') {
        window.heap.identify(this.account.companyName)
        const activeIntegrations = this.getIsActiveIntegration ? 1 : 0
        window.heap.addUserProperties({
          userType: 'business',
          activeIntegrations: activeIntegrations,
          employeeStandard: this.account.employeePlans.standard,
          employeeSuperior: this.account.employeePlans.superior,
          employeepremium: this.account.employeePlans.premium,
          employeeAddonTreeplanter: this.account.employeePlanAddons.treePlanter,
          employeeAddonPlasticHero: this.account.employeePlanAddons.plasticHero,
        })

        if (this.account.accountType === 'business') {
          window.heap.addUserProperties({ plan: 'business' })
        }
        if (this.account.accountType === 'ecommerce') {
          window.heap.addUserProperties({ plan: 'ecommerce' })
        }
      } else {
        const name = this.getCurrentUserFullName
        window.heap.identify(name)
        window.heap.addUserProperties({
          userType: 'personal',
          personalAddonTrees: this.account.personalPlanAddons.treeAddon,
          personalAddonPlastic: this.account.personalPlanAddons.plasticAddon,
        })

        if (this.account.accountType === 'personal') {
          window.heap.addUserProperties({ plan: 'personal' })
        }
        if (this.account.accountType === 'family') {
          window.heap.addUserProperties({ plan: 'family' })
        }
      }
    },
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
    getIfB2B2C(): boolean {
      return this.$store.getters['getIfB2B2C']
    },
    getCurrentUserFullName(): string {
      return this.$store.getters['getCurrentUserFullName']
    },
    getIsActiveIntegration(): boolean {
      return this.$store.getters['getIsActiveIntegration']
    },
  },
})
</script>

<style lang="scss" scoped></style>
