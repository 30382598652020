<template>
  <main class="success-page">
    <div class="success-card">
      <h1 class="success-title">
        {{ t('title') }}
      </h1>
      <p class="success-text">
        {{ t('text') }}
      </p>
      <p class="success-text">
        {{ t('greeting') }}
      </p>
      <gs-button :full-width="true" size="large" @click.prevent="onClick">
        {{ t('button_text') }}
      </gs-button>
    </div>
  </main>
</template>

<script lang="ts">
import { getImpactActions } from '@api/index'
import type { Account } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SuccessB2B2C',
  async mounted() {
    const impactAction = await getImpactActions()
    this.setImpactActionState(Boolean(impactAction.data.length))
  },
  methods: {
    t(key: string) {
      return this.$t(`SuccessB2B2C.${key}`)
    },
    onClick() {
      this.$router.replace('/')
    },
    setImpactActionState(state: boolean) {
      return this.$store.commit('setImpactActionsState', state)
    },
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.success-page {
  align-content: center;
  align-items: center;
  background-image: url('../../assets/backgrounds/success-background.svg');
  background-size: cover;
  display: flex;
  padding: 20px 0;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
}

.success-card {
  align-items: center;
  background: var(--ui-white);
  box-shadow: var(--box-shadow-wide);
  border-radius: var(--border-radius-big);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 25px;
  max-width: 770px;
  width: 90%;
}

.success-title {
  margin-bottom: 20px;
  text-align: center;
}

.success-text {
  width: 100%;
  text-align: center;
}

.success-text:last-of-type {
  margin-bottom: 40px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .success-card {
    padding: 40px;
  }

  .success-text {
    width: 65%;
  }
}
</style>
