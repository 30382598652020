<template>
  <div>
    <div class="totals-wrapper">
      <TeamTotals :total-employees="getTotalEmployees" />
    </div>

    <div class="updates-wrapper">
      <UpdatePlans
        :standard-amount="account.employeePlans.standard ?? 0"
        :superior-amount="account.employeePlans.superior ?? 0"
        :premium-amount="account.employeePlans.premium ?? 0"
        :total-trees="account.employeePlanAddons.treePlanter"
        :updated-total-pending-standard="updatedTotalPendingStandard"
        :updated-total-pending-superior="updatedTotalPendingSuperior"
        :updated-total-pending-premium="updatedTotalPendingPremium"
        :account="account"
        :reset-inputs="resetInputs"
        @getUpdatedValues="getUpdatedValues"
      />
      <div class="pending-note">*{{ t('pending_note_team') }}</div>
    </div>

    <div class="upgrades-wrapper">
      <Upgrades
        :tree-planter="account.employeePlanAddons.treePlanter ?? 0"
        :plastic-hero="account.employeePlanAddons.plasticHero ?? 0"
        :pending-tree-planter="updatedTotalPendingTreePlanter"
        :pending-plastic-hero="updatedTotalPendingPlastic"
        :reset-inputs="resetInputs"
        @getUpdatedValues="getUpdatedValues"
      />
      <div class="pending-note">*{{ t('pending_note_upgrade') }}</div>
    </div>

    <transition v-if="showCart" name="fade">
      <div class="cart-wrapper">
        <Cart
          :add-standard="addStandard ?? 0"
          :add-superior="addSuperior ?? 0"
          :add-premium="addPremium ?? 0"
          :add-trees="addTreePlanter ?? 0"
          :add-plastic="addPlasticHero ?? 0"
          :total-employees="getTotalEmployees"
          :number-of-plastic-heros="Number(account.employeePlanAddons.plasticHero)"
          :updated-total-pending-standard="updatedTotalPendingStandard"
          :updated-total-pending-superior="updatedTotalPendingSuperior"
          :updated-total-pending-premium="updatedTotalPendingPremium"
          :account="account"
          :window-width="windowWidth"
          :loading="loading"
          @updateSubscriptionClick="updateSubscriptions()"
          @noSubscriptionClick="dialog = true"
        />
      </div>
    </transition>

    <AddCardPopUp
      :dialog="dialog"
      :type="'teams'"
      :from-page="'teams'"
      @closeDialogClick="dialog = false"
    />

    <!-- Payment Success / Error Alert -->
    <Alert
      :alert="alert"
      :error="error"
      :show-buttons="error"
      :text="error ? t('error') : t('success')"
      @closeAlertClick=";(alert = false), (error = false)"
    />
  </div>
</template>

<script lang="ts">
import TeamTotals from '@/components/Team/TeamTotals.vue'
import UpdatePlans from '@/components/Team/UpdatePlans.vue'
import Upgrades from '@/components/Team/Upgrades.vue'
import Cart from '@/components/Team/Cart.vue'
import Alert from '@/components/tools/Alert.vue'
import AddCardPopUp from '@/components/checkout/AddCardPopUp.vue'
import type { UpdateSubscriptionProduct } from '../../api'
import { upsertTeamMembers } from '../../api'
import type { Account } from '@/store'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TeamView',
  components: {
    TeamTotals,
    Upgrades,
    UpdatePlans,
    Cart,
    Alert,
    AddCardPopUp,
  },
  mixins: [Utils],
  data() {
    return {
      addPlasticHero: null,
      addTreePlanter: null,
      addPremium: null,
      addSuperior: null,
      addStandard: null,
      updatedTotalPendingStandard: 0,
      updatedTotalPendingSuperior: 0,
      updatedTotalPendingPremium: 0,
      updatedTotalPendingTreePlanter: 0,
      updatedTotalPendingPlastic: 0,
      showCart: false,
      error: false,
      alert: false,
      dialog: false,
      loading: false,
      resetInputs: false,
    } as {
      addPlasticHero: number | null
      addTreePlanter: number | null
      addPremium: number | null
      addSuperior: number | null
      addStandard: number | null
      updatedTotalPendingStandard: number
      updatedTotalPendingSuperior: number
      updatedTotalPendingPremium: number
      updatedTotalPendingTreePlanter: number
      updatedTotalPendingPlastic: number
      showCart: boolean
      error: boolean
      alert: boolean
      dialog: boolean
      loading: boolean
      resetInputs: boolean
    }
  },
  created() {
    this.checkUser()
    this.setTotals()
  },
  methods: {
    t(key: string) {
      return this.$t(`TeamView.${key}`)
    },
    checkUser() {
      if (this.account.accountType === 'personal' || this.account.accountType === 'family') {
        this.$router.push('/')
      }
    },
    setTotals() {
      this.setPendingValues()
    },
    checkCart() {
      this.showCart = Boolean(
        (this.addStandard ||
          this.addSuperior ||
          this.addPremium ||
          this.addTreePlanter ||
          this.addPlasticHero) &&
          (this.account.employeePlans.standard || 0) +
            (this.account.temporaryUpgrades.standard || 0) +
            Number(this.addStandard) >
            -1 &&
          (this.account.employeePlans.superior || 0) +
            (this.account.temporaryUpgrades.superior || 0) +
            Number(this.addSuperior) >
            -1 &&
          (this.account.employeePlans.premium || 0) +
            (this.account.temporaryUpgrades.premium || 0) +
            Number(this.addPremium) >
            -1,
      )
    },
    setPendingValues() {
      this.updatedTotalPendingStandard = Number(this.account.temporaryUpgrades.standard)
      this.updatedTotalPendingSuperior = Number(this.account.temporaryUpgrades.superior)
      this.updatedTotalPendingPremium = Number(this.account.temporaryUpgrades.premium)
      this.updatedTotalPendingTreePlanter = Number(this.account.temporaryUpgrades.treePlanter)
      this.updatedTotalPendingPlastic = Number(this.account.temporaryUpgrades.plasticHero)
    },
    getUpdatedValues(
      value: number,
      type: 'plasticHero' | 'treePlanter' | 'standard' | 'premium' | 'superior',
    ) {
      switch (type) {
        case 'standard':
          this.addStandard = value
          this.updatedTotalPendingStandard =
            Number(value) + Number(this.account.temporaryUpgrades.standard)
          break
        case 'superior':
          this.addSuperior = value
          this.updatedTotalPendingSuperior =
            Number(value) + Number(this.account.temporaryUpgrades.superior)
          break
        case 'premium':
          this.addPremium = value
          this.updatedTotalPendingPremium =
            Number(value) + Number(this.account.temporaryUpgrades.premium)
          break
        case 'treePlanter':
          this.addTreePlanter = value
          this.updatedTotalPendingTreePlanter =
            Number(value) + Number(this.account.temporaryUpgrades.treePlanter)
          break
        case 'plasticHero':
          this.addPlasticHero = value
          this.updatedTotalPendingPlastic =
            Number(value) + Number(this.account.temporaryUpgrades.plasticHero)
          break
      }
    },
    async updateSubscriptions() {
      const products: UpdateSubscriptionProduct[] = []

      if (this.addStandard) {
        products.push({
          type: 'standard',
          amount: Number(this.addStandard),
        })
      }

      if (this.addSuperior) {
        products.push({
          type: 'superior',
          amount: Number(this.addSuperior),
        })
      }

      if (this.addPremium) {
        products.push({
          type: 'premium',
          amount: Number(this.addPremium),
        })
      }

      if (this.addPlasticHero) {
        products.push({
          type: 'plasticHero',
          amount: Number(this.addPlasticHero),
        })
      }

      if (this.addTreePlanter) {
        products.push({
          type: 'treePlanter',
          amount: Number(this.addTreePlanter),
        })
      }

      const payload = {
        products: products,
      }

      try {
        this.loading = true
        const { data: updatedUser } = await upsertTeamMembers(payload)

        this.addStandard = null
        this.addSuperior = null
        this.addPremium = null
        this.addPlasticHero = null
        this.addTreePlanter = null
        this.resetInputs = true

        this.alert = true
        this.error = false
        setTimeout(() => {
          this.alert = false
          this.error = false
          this.resetInputs = false
        }, 5000)

        this.setAccount(updatedUser)
        this.setTotals()
      } catch (e) {
        this.error = true
        this.alert = true
        setTimeout(() => {
          this.alert = false
          this.error = false
        }, 5000)
      } finally {
        this.loading = false
      }
    },
    onStandardPlanChange() {
      this.checkCart()
    },
    onSuperiorPlanChange() {
      this.checkCart()
    },
    onPremiumPlanChange() {
      this.checkCart()
    },
    onTreesPlanChange() {
      this.checkCart()
    },
    onPlasticPlanChange() {
      this.checkCart()
    },
    setAccount(account: Account): void {
      return this.$store.commit('setAccount', account)
    },
  },
  watch: {
    addStandard: [
      {
        handler: 'onStandardPlanChange',
      },
    ],
    addSuperior: [
      {
        handler: 'onSuperiorPlanChange',
      },
    ],
    addPremium: [
      {
        handler: 'onPremiumPlanChange',
      },
    ],
    addTreePlanter: [
      {
        handler: 'onTreesPlanChange',
      },
    ],
    addPlasticHero: [
      {
        handler: 'onPlasticPlanChange',
      },
    ],
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
    getTotalEmployees(): number {
      return this.$store.getters['getTotalEmployees']
    },
  },
})
</script>

<style lang="scss" scoped>
.totals-wrapper {
  display: flex;
  background: #f9f9f9;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
}

.updates-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.upgrades-wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.pending-note {
  font-size: 12px;
  color: #757d82;
  padding-left: 30px;
  margin-bottom: 3px;
}

.cart-wrapper {
  margin-top: 20px;
  display: flex;
  background: #cfcccc;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  height: 100px;
  position: fixed;
  z-index: 1;
  width: 100%;
  left: 0;
  bottom: 0;
  padding-left: 256px;
}

@media screen and (max-width: 860px) {
  .cart-wrapper {
    padding-left: 0;
    z-index: 4;
  }
}
</style>
