import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14f52e8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, {
    "open-on-click": _ctx.isTouch,
    location: "top",
    color: "black"
  }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_icon, _mergeProps(props, {
        class: "tooltip",
        color: "green"
      }), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.infoIcon), 1)
        ]),
        _: 2
      }, 1040)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _: 3
  }, 8, ["open-on-click"]))
}