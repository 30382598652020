const TemplateDialog = {
  SIMPLE: 'SimpleDialogTemplate',
  DELETE: 'DeleteConfirmationDialogTemplate',
} as const

const CustomDialog = {
  INVITE_USER: 'InviteUserDialog',
  REQUEST_INTEGRATION: 'RequestIntegrationDialog',
  PRICE_COMPARISON: 'PriceComparisonDialog',
  CANCEL_SUBSCRIPTION: 'CancelSubscriptionDialog',
  PROJECT_REFACTOR_LOGOUT: 'ProjectRefactorLogoutDialog',
} as const

type TemplateDialogKeys = keyof typeof TemplateDialog
type CustomDialogKeys = keyof typeof CustomDialog

type TemplateDialogType = (typeof TemplateDialog)[TemplateDialogKeys]
type CustomDialogType = (typeof CustomDialog)[CustomDialogKeys]

type DialogType = TemplateDialogType | CustomDialogType | ''

export const Dialogs = {
  TEMPLATE: TemplateDialog,
  CUSTOM: CustomDialog,
}

export type DialogTextColor = 'black' | 'red'

export interface DialogOptions {
  persistent: boolean
  fullscreen: boolean
  width: number
  titleColor: DialogTextColor
  descriptionColor: DialogTextColor
  retainFocus: boolean
}

export interface DialogTexts {
  title: string
  description: string
  primaryButtonText: string
  secondaryButtonText: string
  deleteCheckboxLabel: string
}

export interface DialogActions {
  onPrimaryButtonClick: (data?: object) => void
  onSecondaryButtonClick: () => void
  onCloseButtonClick: () => void
}

export interface Dialog {
  name: DialogType
  texts: DialogTexts
  actions: DialogActions
  options: DialogOptions
}

export interface DialogUpdatePayload {
  name: DialogType
  texts?: Partial<DialogTexts>
  actions?: Partial<DialogActions>
  options?: Partial<DialogOptions>
}

export interface DialogModuleState {
  dialog: Dialog
}

export const defaultDialogOptions: DialogOptions = {
  persistent: false,
  fullscreen: false,
  width: 600,
  titleColor: 'black',
  descriptionColor: 'black',
  retainFocus: true,
}

export const defaultDialogTexts: DialogTexts = {
  title: '',
  description: '',
  primaryButtonText: '',
  secondaryButtonText: '',
  deleteCheckboxLabel: '',
}

export const defaultDialogActions: DialogActions = {
  onPrimaryButtonClick: () => {
    /* no return */
  },
  onSecondaryButtonClick: () => {
    /* no return */
  },
  onCloseButtonClick: () => {
    /* no return */
  },
}

export const defaultDialog: Dialog = {
  name: '',
  texts: defaultDialogTexts,
  actions: defaultDialogActions,
  options: defaultDialogOptions,
}

export const defaultDialogModuleState: DialogModuleState = {
  dialog: defaultDialog,
}

export const dialogModule = {
  state: () => ({ ...defaultDialogModuleState } as DialogModuleState),
  mutations: {
    setDialog(state: DialogModuleState, dialog: Dialog) {
      state.dialog = dialog
    },
  },
  actions: {
    openDialog: ({ commit }, dialogUpdatePayload: DialogUpdatePayload): void => {
      commit('setDialog', {
        name: dialogUpdatePayload.name,
        texts: { ...defaultDialogTexts, ...dialogUpdatePayload.texts },
        actions: { ...defaultDialogActions, ...dialogUpdatePayload.actions },
        options: { ...defaultDialogOptions, ...dialogUpdatePayload.options },
      })
    },
    closeDialog: ({ commit }): void => {
      commit('setDialog', { ...defaultDialog })
    },
  },
  getters: {
    getCurrentDialog: (state: DialogModuleState): Dialog => state.dialog,
  },
}
