<template>
  <nav>
    <ul class="project-card-list">
      <slot />
    </ul>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProjectCardList',
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.project-card-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .project-card-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
</style>
