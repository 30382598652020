<template>
  <div class="loading-wrapper">
    <img class="loading-logo" alt="Loading ..." :src="require('@/assets/icons/main-shape.svg')" />
    <div class="progress-bar-wrapper">
      <v-progress-linear color="#3B755F" indeterminate rounded height="6" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoadingTable',
  components: {},
  methods: {
    t(key: string) {
      return this.$t(`LoadingTable.${key}`)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.loading-wrapper {
  padding: 30px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.loading-logo {
  width: 100px;
  margin-bottom: 30px;
}

.progress-bar-wrapper {
  width: 150px;
}
</style>
