<template>
  <div>
    <v-footer class="footer py-3" height="52" color="#F2EBDB" absolute>
      <span class="ml-auto text-overline">Greenspark &copy;2021</span>
    </v-footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomFooter',
  methods: {
    t(key: string) {
      return this.$t(`Footer.${key}`)
    },
  },
})
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
