<template>
  <div type="button" :class="['setup-url', { 'is-active': isActive }]">
    <!--header-->
    <div class="setup-header" role="button" @click.prevent="isCardOpen = !isCardOpen">
      <div class="text-wrapper">
        <div class="title-wrapper">
          <div class="checkmark">
            <v-icon class="checkmark-icon"> mdi-check </v-icon>
          </div>
          <p class="setup-title mb-0">
            {{ t('title') }}
          </p>
        </div>
        <p class="description">
          {{ t('description') }}
        </p>
      </div>
      <p class="set-button">Set +</p>
    </div>
    <!--body-->
    <transition name="fade">
      <div v-if="isCardOpen" class="input-wrapper">
        <v-form v-model="isUrlValid">
          <v-text-field
            v-model="url"
            :label="t('input_label')"
            :placeholder="t('input_placeholder')"
            variant="outlined"
            flat
            type="text"
            color="#3B755F"
            class="input"
            prefix="https://"
            :rules="[rules.url]"
          />
          <div class="button-wrapper">
            <v-btn
              class="save-button"
              color="#3B755F"
              height="44"
              :disabled="!isUrlValid"
              :loading="loading"
              @click="saveUrl"
            >
              {{ $t('CommonUi.save') }}
            </v-btn>
            <v-btn
              color="#FFFFFF"
              height="44"
              class="cancel-button"
              elevation="0"
              @click="isCardOpen = false"
            >
              {{ $t('CommonUi.cancel') }}
            </v-btn>
          </div>
        </v-form>
      </div>
    </transition>
  </div>
</template>
<script lang="ts">
import { updateAccount } from '@api/index'
import type { Notification } from '@/store/notification'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SustainabilitySiteSetup',
  mixins: [RulesMixin],
  data() {
    return {
      isActive: false,
      isCardOpen: false,
      isUrlValid: false,
      loading: false,
      url: '',
    }
  },
  created() {
    this.url = this.sustainabilitySite.replace('https://', '')
    this.isActive = !!this.sustainabilitySite
  },
  methods: {
    t(key: string) {
      return this.$t(`SustainabilitySiteSetup.${key}`)
    },
    async saveUrl() {
      this.loading = true
      try {
        await updateAccount({ sustainabilitySite: 'https://' + this.url })
        await this.setSustainabilitySite()
        this.isCardOpen = false
        this.isActive = true
        this.$store.dispatch('notification/notify', { text: this.t('url_saved') } as Notification)
      } catch (e) {
        this.url = this.sustainabilitySite.replace('https://', '')
        this.$store.dispatch('notification/notify', {
          text: e?.message,
          isError: true,
        } as Notification)
      }
      this.loading = false
    },
    setSustainabilitySite() {
      return this.$store.dispatch('setSustainabilitySite')
    },
  },
  computed: {
    sustainabilitySite(): string {
      return this.$store.getters['getSustainabilitySite']
    },
  },
})
</script>
<style scoped lang="scss">
@import '~vuetify/settings';

.setup-url {
  border-radius: var(--border-radius-big);
  background-color: #ffffff;
  padding: 15px 10px;
}

.setup-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.text-wrapper {
  max-width: 40ch;
  padding-right: 10px;
}

.title-wrapper {
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.checkmark {
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  height: 20px;
  margin-right: 5px;
  position: relative;
  width: 20px;
}

.setup-url.is-active .checkmark {
  background-color: var(--ui-green);
  border: none;
}

.checkmark-icon {
  color: transparent;
  font-size: 16px;
  line-height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 50%;
  right: 50%;
  transform: translate(-50%, calc(-50% + 1px));
}

.setup-title {
  font-weight: 600;
}

.description {
  color: var(--gray-light);
}

.set-button {
  flex-shrink: 0;
}

.setup-url.is-active .checkmark-icon {
  color: white;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.save-button {
  position: relative;
  color: var(--ui-white);
  margin-bottom: 20px;
}

.input-wrapper {
  padding: 20px 0;
}

.input {
  margin-bottom: 20px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .button-wrapper {
    justify-content: flex-end;
    flex-direction: row;
    gap: 10px;
  }

  .save-button {
    order: 2;
    width: 150px;
    margin-bottom: 0;
  }

  .cancel-button {
    order: 1;
  }
}
</style>
