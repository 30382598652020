import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32ad9531"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "total-wrapper" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "stats-wrapper" }
const _hoisted_4 = { class: "stat team-members" }
const _hoisted_5 = { class: "text-number-wrapper text-number-wrapper-beige" }
const _hoisted_6 = { class: "number" }
const _hoisted_7 = { class: "text-big" }
const _hoisted_8 = { class: "text-number-wrapper" }
const _hoisted_9 = { class: "number" }
const _hoisted_10 = { class: "text-big" }
const _hoisted_11 = { class: "per_member" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('header')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formatNumberByLocale(_ctx.totalEmployees)), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('team')), 1)
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.averageImpacts, ({ type, amount }, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(['stat', type])
        }, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.formatNumberByLocale(amount)), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.t(type)), 1)
          ])
        ], 2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t('per_member')), 1)
  ]))
}