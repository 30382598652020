import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "fullWidthPopup",
  class: "full-width-popup"
}
const _hoisted_2 = { class: "popup-title-wrapper" }
const _hoisted_3 = { class: "popup-title" }
const _hoisted_4 = { class: "popup-title-info" }
const _hoisted_5 = { class: "popup-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.isOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h2", _hoisted_3, [
                _createTextVNode(_toDisplayString(_ctx.popupTitle) + " ", 1),
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.popupTitleInfo), 1)
              ])
            ]),
            _createElementVNode("button", {
              class: "close-button",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args)))
            }),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}