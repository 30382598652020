import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1107227a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-content" }
const _hoisted_2 = { class: "public-top-stat-card-text amount" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "public-top-stat-card-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTooltip = _resolveComponent("BaseTooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: "public-top-stat-card",
    style: _normalizeStyle({ backgroundColor: _ctx.backgroundColor })
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icons[_ctx.currentIcon]), { class: "public-top-stat-card-icon" })),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("strong", _hoisted_2, [
        _createElementVNode("span", {
          innerHTML: _ctx.getTopStatsCartTextAmount(_ctx.amount)
        }, null, 8, _hoisted_3),
        (!!_ctx.relationImpact || !!_ctx.transferredImpact || !!_ctx.impactActionImpact)
          ? (_openBlock(), _createBlock(_component_BaseTooltip, {
              key: 0,
              class: "stat-tooltip",
              content: _ctx.tooltipContent
            }, null, 8, ["content"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.getTopStatsCartText(_ctx.type, _ctx.amount)), 1)
    ])
  ], 4))
}