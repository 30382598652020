<template>
  <div v-if="loaded" class="partners-wrapper">
    <v-form v-model="isFormValid">
      <PartnerInformation v-model:partner-credential="partnerCredential" :is-edit="isEdit" />

      <div v-if="errorMessage" class="error-message">
        {{ t('error') }}
      </div>

      <FundedImpact
        v-if="!isContactEdit"
        :account="account"
        :funding-setting="fundingSetting"
        :is-edit="isEdit"
        @resetTotals="resetTotals()"
      />
    </v-form>
    <transition name="fade">
      <!-- total -->
      <div v-if="total > 0">
        <div v-if="account.currency === 'euro'" class="total">
          <span v-if="discountTotal > 0" class="subtotal" style="margin-bottom: 2px"
            >{{ t('total_impact_discount') }} {{ currencyEuro(discountTotal) }}</span
          >
          <span
            v-if="
              discountTotal > 0 &&
              fundingSetting.fundingType !== 'oneTimeImpact' &&
              fundingSetting.fundingType !== 'oneTimeAllowance'
            "
            >{{ totalText }} {{ currencyEuro(totalPriceWithDiscount) }}</span
          >
          <span
            v-if="
              discountTotal === 0 ||
              fundingSetting.fundingType === 'oneTimeImpact' ||
              fundingSetting.fundingType === 'oneTimeAllowance'
            "
            >{{ totalText }} {{ currencyEuro(total) }}</span
          >
        </div>
        <div v-if="account.currency === 'pound'" class="total">
          <span v-if="discountTotal > 0" class="subtotal" style="margin-bottom: 2px"
            >{{ t('total_impact_discount') }} {{ currencyPound(discountTotal) }}</span
          >
          <span
            v-if="
              discountTotal > 0 &&
              fundingSetting.fundingType !== 'oneTimeImpact' &&
              fundingSetting.fundingType !== 'oneTimeAllowance'
            "
            >{{ totalText }} {{ currencyPound(totalPriceWithDiscount) }}</span
          >
          <span
            v-if="
              discountTotal === 0 ||
              fundingSetting.fundingType === 'oneTimeImpact' ||
              fundingSetting.fundingType === 'oneTimeAllowance'
            "
            >{{ totalText }} {{ currencyPound(total) }}</span
          >
        </div>
        <div v-if="account.currency === 'dollar'" class="total">
          <span v-if="discountTotal > 0" class="subtotal" style="margin-bottom: 2px"
            >{{ t('total_impact_discount') }} {{ currencyDollar(discountTotal) }}</span
          >
          <span
            v-if="
              discountTotal > 0 &&
              fundingSetting.fundingType !== 'oneTimeImpact' &&
              fundingSetting.fundingType !== 'oneTimeAllowance'
            "
            >{{ totalText }} {{ currencyDollar(totalPriceWithDiscount) }}</span
          >
          <span
            v-if="
              discountTotal === 0 ||
              fundingSetting.fundingType === 'oneTimeImpact' ||
              fundingSetting.fundingType === 'oneTimeAllowance'
            "
            >{{ totalText }} {{ currencyDollar(total) }}</span
          >
        </div>
      </div>
    </transition>

    <transition name="fade">
      <!-- sub total -->
      <div v-if="totalSubNumber > 0">
        <div v-if="account.currency === 'euro'" class="subtotal">
          <span v-if="discountTotal > 0"
            >{{ totalSubText }} {{ currencyEuro(totalPriceWithDiscount) }}</span
          >
          <span v-if="discountTotal === 0"
            >{{ totalSubText }} {{ currencyEuro(totalSubNumber) }}</span
          >
        </div>
        <div v-if="account.currency === 'pound'" class="subtotal">
          <span v-if="discountTotal > 0"
            >{{ totalSubText }} {{ currencyPound(totalPriceWithDiscount) }}</span
          >
          <span v-if="discountTotal === 0"
            >{{ totalSubText }} {{ currencyPound(totalSubNumber) }}</span
          >
        </div>
        <div v-if="account.currency === 'dollar'" class="subtotal">
          <span v-if="discountTotal > 0"
            >{{ totalSubText }} {{ currencyDollar(totalPriceWithDiscount) }}</span
          >
          <span v-if="discountTotal === 0"
            >{{ totalSubText }} {{ currencyDollar(totalSubNumber) }}</span
          >
        </div>
      </div>
    </transition>

    <div class="button-wrapper">
      <v-btn
        class="button"
        color="#3B755F"
        height="54"
        variant="flat"
        width="100%"
        :disabled="isLessThanMin || loading || !isFormValid"
        @click="handleButtonClick()"
      >
        <div v-if="!loading" class="button-text">
          {{ buttonLabel }}
        </div>
        <v-progress-circular v-if="loading" indeterminate color="#F9F9F9" />
      </v-btn>
    </div>
    <call-to-upgrade-popup
      v-if="isCallToUpgradePopupOpen"
      @close="closeUpgradePopup"
      :is-premium-only="false"
    />
  </div>
</template>

<script lang="ts">
import type { PaidBusinessMonthlySubscriptionType } from '@/helpers/constants'
import { IMPACT_LOWER_PRICE_LIMIT } from '@/helpers/constants'
import { getPlanPrice } from '@/helpers/pricing'
import FundedImpact from '@/components/Network/FundedImpact.vue'
import type { PartnerData } from '@api/index'
import { addPartner, editPartner, editPartnerContact } from '@api/index'
import type { TranslateResult } from 'vue-i18n'
import type { Impact, OffsetType } from '@/helpers/interfaces'
import CallToUpgradePopup from '@/components/common/CallToUpgradePopup.vue'
import PartnerInformation from '@/components/Network/PartnerInformation.vue'
import { Decimal } from 'decimal.js'
import type { Account } from '@/store'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export type FundingType =
  | ''
  | 'accountOnly'
  | 'monthlyImpact'
  | 'monthlyAllowance'
  | 'oneTimeImpact'
  | 'oneTimeAllowance'
export interface PartnerCredential {
  firstName: string
  lastName: string
  partnerName: string
  email: string
}

export interface FundingSetting {
  fundingType?: FundingType
  fundingLength: number | 'ongoing'
  planType?: PaidBusinessMonthlySubscriptionType
  monthlyAllowance: number
  impactArray: Impact[]
}

interface ImpactSetting {
  source: string
  fundingMonths: number | 'ongoing' | null
  subscriptions?: [{ type: string; amount: number }]
  impacts: Impact[]
  allowances: { amount: number }[]
}

export default defineComponent({
  name: 'Partners',
  emits: ['getFreshDataClick', 'dialogClick', 'showSuccessAlert', 'showErrorAlert'],
  components: {
    CallToUpgradePopup,
    PartnerInformation,
    FundedImpact,
  },
  data() {
    return {
      loaded: false,
      loading: false,
      partnerCredential: {
        firstName: '',
        lastName: '',
        partnerName: '',
        email: '',
      },
      fundingSetting: { fundingLength: 'ongoing', impactArray: [], monthlyAllowance: 0 },
      totalText: '',
      totalSubText: '',
      total: 0,
      totalSubNumber: 0,
      impactCost: 0,
      errorMessage: false,
      lessThanMin: false,
      discountAmount: 0,
      discountTotal: 0,
      totalPriceWithDiscount: 0,
      isFormValid: false,
      isCallToUpgradePopupOpen: false,
    } as {
      loaded: boolean
      loading: boolean
      partnerCredential: PartnerCredential
      fundingSetting: FundingSetting
      totalText: TranslateResult
      totalSubText: TranslateResult
      total: number
      totalSubNumber: number
      impactCost: number
      errorMessage: boolean
      lessThanMin: boolean
      discountAmount: number
      discountTotal: number
      totalPriceWithDiscount: number
      isFormValid: boolean
      isCallToUpgradePopupOpen: boolean
    }
  },
  computed: {
    buttonLabel(): TranslateResult {
      if (this.isContactEdit) {
        return this.t('save')
      }
      return this.lessThanMin
        ? this.userCurrencySymbol + IMPACT_LOWER_PRICE_LIMIT + ' ' + this.t('minimum')
        : this.isEdit && !this.lessThanMin
        ? this.t('save')
        : this.t('create')
    },
    isLessThanMin(): boolean {
      return this.total < IMPACT_LOWER_PRICE_LIMIT && !this.isContactEdit
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    userCurrencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
    partnerRewards(): boolean {
      return this.$store.getters['getPartnerRewardsFeatureSetting']
    },
    getDefaultProjectPriceByType(): (type: OffsetType) => number {
      return this.$store.getters['getDefaultProjectPriceByType']
    },
    getProjectPriceById(): (projectId: string) => number {
      return this.$store.getters['getProjectPriceById']
    },
  },
  created() {
    if (this.isEdit || this.isContactEdit) {
      this.setPartnerInfo()
      this.setFundingDropdowns()
    }
    this.loaded = true
  },
  methods: {
    t(key: string) {
      return this.$t(`Partners.${key}`)
    },
    closeUpgradePopup() {
      this.isCallToUpgradePopupOpen = false
      this.$parent?.$emit('dialogClick')
    },
    handleButtonClick() {
      if (!this.partnerRewards) {
        this.isCallToUpgradePopupOpen = true
        return
      }
      if (!this.isEdit && !this.isContactEdit) {
        return this.createPartner()
      }
      if (!this.isEdit && this.isContactEdit) {
        return this.updatePartnerContact()
      }
      return this.updatePartner()
    },
    resetTotals() {
      this.total = 0
      this.totalSubNumber = 0
      this.discountTotal = 0
      this.discountAmount = 0
      this.totalPriceWithDiscount = 0
      this.impactCost = 0
      this.fundingSetting.impactArray = []
    },
    setTotal() {
      this.total = 0
      this.totalSubNumber = 0
      this.discountAmount = 0
      this.discountTotal = 0
      this.impactCost = 0
      const discount = this.account.discounts.find(
        (discount) => discount.type === 'partnerImpactDiscount',
      )
      this.discountAmount = new Decimal(discount?.amount || 0).dividedBy(100).toDP(2).toNumber()
      let updatedTotal = new Decimal(this.total)

      if (this.fundingSetting.planType) {
        this.totalText = this.t('partner_text')
        updatedTotal = updatedTotal
          .plus(new Decimal(getPlanPrice(this.fundingSetting.planType, this.account.currency)))
          .toDP(2)
        this.total = updatedTotal.toNumber()
      }

      const allowance = new Decimal(this.fundingSetting.monthlyAllowance || 0)
      switch (this.fundingSetting.fundingType) {
        case 'monthlyImpact':
          this.totalText = this.t('partner_text')
          this.calculateImpactCost()
          updatedTotal = updatedTotal.plus(new Decimal(this.impactCost)).toDP(2)
          this.total = updatedTotal.toNumber()
          break
        case 'monthlyAllowance':
          this.totalText = this.t('partner_text')
          updatedTotal = updatedTotal.plus(allowance).toDP(2)
          this.total = updatedTotal.toNumber()
          if (this.discountAmount > 0)
            this.discountTotal += Math.trunc(this.discountAmount * allowance.toNumber() * 100) / 100
          break
        case 'oneTimeImpact':
          this.totalText = this.t('one_time_text')
          this.calculateImpactCost()
          updatedTotal = updatedTotal.plus(new Decimal(this.impactCost)).toDP(2)
          this.total = updatedTotal.toNumber()
          break
        case 'oneTimeAllowance':
          this.totalText = this.t('one_time_text')
          updatedTotal = updatedTotal.plus(allowance).toDP(2)
          this.total = updatedTotal.toNumber()
          if (this.discountAmount > 0)
            this.discountTotal += Math.trunc(this.discountAmount * allowance.toNumber() * 100) / 100
          break
      }

      if (this.discountTotal > 0)
        this.totalPriceWithDiscount = Number((this.total - this.discountTotal).toFixed(2))
    },
    calculateImpactCost() {
      this.impactCost = 0
      for (let i = 0; i < this.fundingSetting.impactArray.length; i++) {
        const type: OffsetType = this.fundingSetting.impactArray[i].type as OffsetType
        switch (type) {
          case 'trees':
            this.impactCost +=
              this.getDefaultProjectPriceByType('trees') * this.fundingSetting.impactArray[i].amount
            if (this.discountAmount > 0)
              this.discountTotal +=
                Math.trunc(
                  this.getDefaultProjectPriceByType('trees') *
                    this.discountAmount *
                    this.fundingSetting.impactArray[i].amount *
                    100,
                ) / 100
            break
          case 'plastic':
            this.impactCost +=
              this.getDefaultProjectPriceByType('plastic') *
              this.fundingSetting.impactArray[i].amount
            if (this.discountAmount > 0)
              this.discountTotal +=
                Math.trunc(
                  this.getDefaultProjectPriceByType('plastic') *
                    this.discountAmount *
                    this.fundingSetting.impactArray[i].amount *
                    100,
                ) / 100
            break
          case 'carbon':
            this.impactCost +=
              this.getDefaultProjectPriceByType('carbon') *
              this.fundingSetting.impactArray[i].amount
            if (this.discountAmount > 0)
              this.discountTotal +=
                Math.trunc(
                  this.getDefaultProjectPriceByType('carbon') *
                    this.discountAmount *
                    this.fundingSetting.impactArray[i].amount *
                    100,
                ) / 100
            break
          case 'kelp':
            throw new Error('Kelp is not supported')
          default: {
            const exhaustiveCheck: never = type
            throw new Error(exhaustiveCheck)
          }
        }
      }
    },
    setPartnerInfo() {
      if (!this.editItems) return
      const [item] = this.editItems
      this.partnerCredential.firstName = item.connectee.firstName
      this.partnerCredential.lastName = item.connectee.lastName
      this.partnerCredential.email = item.connectee.email ?? ''
      this.partnerCredential.partnerName = item.connectee.displayName
    },
    async createPartner() {
      interface PartnerRequest {
        firstName: string
        lastName: string
        displayName: string
        email: string
        impactSettings: unknown[]
      }
      try {
        this.errorMessage = false
        this.loading = true
        // create the payload with data
        const payload: PartnerRequest = {
          firstName: this.partnerCredential.firstName,
          lastName: this.partnerCredential.lastName,
          displayName: this.partnerCredential.partnerName,
          email: this.partnerCredential.email,
          impactSettings: [],
        }

        // set fundingMonths
        let fundingMonths: number | 'ongoing' | null = null
        if (
          typeof this.fundingSetting.fundingLength === 'number' &&
          this.fundingSetting.fundingLength > 0
        ) {
          fundingMonths = this.fundingSetting.fundingLength
        }
        if (this.fundingSetting.planType) {
          payload.impactSettings.push({
            subscriptions: [{ type: this.fundingSetting.planType, amount: 1 }],
            source: 'subscription',
            fundingMonths: fundingMonths,
          })
        }
        // add the impact settings to the payload
        switch (this.fundingSetting.fundingType) {
          case 'monthlyImpact':
            payload.impactSettings.push({
              impacts: this.fundingSetting.impactArray,
              source: 'monthlyImpact',
              fundingMonths: fundingMonths,
            })
            break
          case 'oneTimeImpact':
            payload.impactSettings.push({
              impacts: this.fundingSetting.impactArray,
              source: 'oneTimeImpact',
              fundingMonths: fundingMonths,
            })
            break
          case 'monthlyAllowance':
            payload.impactSettings.push({
              allowances: [{ amount: this.fundingSetting.monthlyAllowance }],
              source: 'monthlyAllowance',
              fundingMonths: fundingMonths,
            })
            break
          case 'oneTimeAllowance':
            payload.impactSettings.push({
              allowances: [{ amount: this.fundingSetting.monthlyAllowance }],
              source: 'oneTimeAllowance',
              fundingMonths: fundingMonths,
            })
            break
        }

        // send the partner to the moooonnn
        await addPartner(payload)
        this.loading = false
        this.$emit('getFreshDataClick')
        this.$emit('dialogClick')
        this.$emit('showSuccessAlert')
      } catch (error) {
        this.loading = false
        console.error('error:', error)
        if (error.response.data === 'USER_ALREADY_EXIST') {
          this.errorMessage = true
        } else {
          this.$emit('showErrorAlert')
          this.$emit('dialogClick')
        }
      }
    },
    async updatePartner() {
      try {
        this.errorMessage = false
        this.loading = true
        // create the payload with data
        const payload: { displayName: string; impactSettings: ImpactSetting[] } = {
          displayName: this.partnerCredential.partnerName,
          impactSettings: [],
        }

        // set fundingMonths
        let fundingMonths: number | 'ongoing' | null = null
        if (
          typeof this.fundingSetting.fundingLength === 'number' &&
          this.fundingSetting.fundingLength > 0
        ) {
          fundingMonths = this.fundingSetting.fundingLength
        }
        if (this.fundingSetting.planType) {
          payload.impactSettings.push({
            subscriptions: [{ type: this.fundingSetting.planType, amount: 1 }],
            source: 'subscription',
            fundingMonths: fundingMonths,
            impacts: [],
            allowances: [],
          })
        }
        // add the impact settings to the payload
        switch (this.fundingSetting.fundingType) {
          case 'monthlyImpact':
            payload.impactSettings.push({
              impacts: this.fundingSetting.impactArray,
              source: 'monthlyImpact',
              fundingMonths: fundingMonths,
              allowances: [],
            })
            break
          case 'oneTimeImpact':
            payload.impactSettings.push({
              impacts: this.fundingSetting.impactArray,
              source: 'oneTimeImpact',
              fundingMonths: fundingMonths,
              allowances: [],
            })
            break
          case 'monthlyAllowance':
            payload.impactSettings.push({
              allowances: [{ amount: this.fundingSetting.monthlyAllowance }],
              source: 'monthlyAllowance',
              fundingMonths: fundingMonths,
              impacts: [],
            })
            break
          case 'oneTimeAllowance':
            payload.impactSettings.push({
              allowances: [{ amount: this.fundingSetting.monthlyAllowance }],
              source: 'oneTimeAllowance',
              fundingMonths: fundingMonths,
              impacts: [],
            })
            break
        }
        // send the partner to the moooonnn
        await editPartner(payload, this.relationsId ?? '')
        this.loading = false
        this.$emit('getFreshDataClick')
        this.$emit('dialogClick')
        this.$emit('showSuccessAlert')
      } catch (error) {
        this.loading = false
        console.error('error:', error)
        if (error.response.data === 'USER_ALREADY_EXIST') {
          this.errorMessage = true
        } else {
          this.$emit('dialogClick')
          this.$emit('showErrorAlert')
        }
      }
    },
    async updatePartnerContact() {
      try {
        this.errorMessage = false
        this.loading = true
        await editPartnerContact(
          {
            firstName: this.partnerCredential.firstName,
            lastName: this.partnerCredential.lastName,
            displayName: this.partnerCredential.partnerName,
            email: this.partnerCredential.email,
          },
          this.relationsId ?? '',
        )
        this.loading = false
        this.$emit('getFreshDataClick')
        this.$emit('dialogClick')
        this.$emit('showSuccessAlert')
      } catch (e) {
        this.loading = false
        if (e.response.data === 'USER_ALREADY_EXIST') {
          this.errorMessage = true
        } else {
          this.$emit('dialogClick')
          this.$emit('showErrorAlert')
        }
      }
    },
    setFundingDropdowns() {
      if (!this.editItems) return
      const [item] = this.editItems
      // grab the impact settings that correspond to this user
      const impactSettings: ImpactSetting[] = item.impactSettings.filter(
        (impactSettings) => impactSettings.userId === item.connector.id,
      )

      const hasImpactOrAllowance = impactSettings.some(
        (setting) => setting.impacts.length > 0 || setting.allowances.length > 0,
      )
      for (let i = 0; i < impactSettings.length; i++) {
        switch (impactSettings[i].source) {
          case 'monthlyImpact':
            this.fundingSetting.fundingType = 'monthlyImpact'
            for (let j = 0; j < impactSettings[i].impacts.length; j++) {
              this.fundingSetting.impactArray.push({
                amount: impactSettings[i].impacts[j].amount,
                type: impactSettings[i].impacts[j].type,
              })
            }
            break
          case 'monthlyAllowance':
            this.fundingSetting.fundingType = 'monthlyAllowance'
            for (let j = 0; j < impactSettings[i].allowances.length; j++) {
              this.fundingSetting.monthlyAllowance = impactSettings[i].allowances[j].amount
            }
            break
          case 'oneTimeImpact':
            this.fundingSetting.fundingType = 'oneTimeImpact'
            for (let j = 0; j < impactSettings[i].impacts.length; j++) {
              this.fundingSetting.impactArray.push({
                amount: impactSettings[i].impacts[j].amount,
                type: impactSettings[i].impacts[j].type,
              })
            }
            break
          case 'subscription':
            if (!hasImpactOrAllowance) {
              this.fundingSetting.fundingType = 'accountOnly'
            }
            break
        }

        // set fundingMonths
        if (impactSettings[i].fundingMonths) {
          this.fundingSetting.fundingLength = Number(impactSettings[i].fundingMonths)
        } else {
          this.fundingSetting.fundingLength = 'ongoing'
        }
      }
    },
    onFunDingSettingChane() {
      this.isFormValid =
        this.isFormValid && (!!this.fundingSetting.planType || !!this.fundingSetting.fundingType)
    },
    onFundingSettingChanges() {
      this.setTotal()
    },
    onDiscountTotalChange() {},
  },
  props: {
    isEdit: { type: Boolean, required: true },
    isContactEdit: { type: Boolean, required: true },
    editItems: { type: Array as PropType<PartnerData[]> },
    relationsId: {
      type: String,
    },
  },
  watch: {
    fundingSetting: [
      {
        deep: true,
        handler: 'onFunDingSettingChane',
      },
      {
        deep: true,
        handler: 'onFundingSettingChanges',
      },
    ],
    discountTotal: [
      {
        handler: 'onDiscountTotalChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.partners-wrapper {
  width: 100%;
  overflow-y: scroll;
}

.total {
  width: 100%;
  display: flex;
  align-items: flex-end;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  margin-top: 24px;
  flex-direction: column;
}

.subtotal {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #afc6bd;
  margin-top: 5px;
}

.button-wrapper {
  margin-top: 18px;
  width: 100%;
}

.button-text {
  color: #f9f9f9;
  font-weight: 600;
  font-size: 15px;
}

.error-message {
  font-size: 16px;
  color: #f9f9f9;
  font-weight: 500;
  text-align: left;
  width: 100%;
  padding: 8px 20px;
  background: red;
  margin-bottom: 10px;
  margin-top: 18px;
}
</style>
