import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59e43416"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"margin-bottom":"40px"}
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "wrapper" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "comparison-number" }
const _hoisted_6 = { class: "comparison-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_ctx.impact)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.headerTitle), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.comparisons), ([key, comparison]) => {
            return (_openBlock(), _createElementBlock("div", {
              key: key,
              class: "impact-wrapper"
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(['comparison-animation', key])
              }, [
                _createElementVNode("img", {
                  class: _normalizeClass(['comparison-icon', key]),
                  height: "30",
                  width: "30",
                  src: require('@/assets/icons/comparisons/' + _ctx.snakeCase(key) + '.svg'),
                  alt: _ctx.snakeCase(key)
                }, null, 10, _hoisted_4)
              ], 2),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.formatTotalAmounts(comparison.value, comparison.suffix)), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.tc(`${_ctx.impactType}.${_ctx.snakeCase(key)}`, Number(comparison))), 1),
              _createVNode(_component_Tooltip, {
                class: "tooltip",
                body: _ctx.t(`${_ctx.impactType}.${_ctx.snakeCase(key)}_tooltip`),
                "link-text": 'Source',
                link: comparison.link
              }, null, 8, ["body", "link"])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}