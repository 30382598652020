import { defineComponent } from 'vue'
import type { NewPaidBusinessSubscriptionType } from '@/helpers/pricing'
import { listPartners } from '@api/index'

export type IssueType =
  | 'partners'
  | 'customerEngagementPortal'
  | 'integrations'
  | 'customDomains'
  | 'customEmailTemplates'
export interface DowngradeIssue {
  issue: IssueType
  currentAmount?: number
  maxAmount?: number
}
export const DowngradeMixin = defineComponent({
  data() {
    return {
      hasActivePartners: false,
    }
  },
  async created() {
    await this.checkActivePartners()
  },
  methods: {
    async checkActivePartners() {
      const { data } = await listPartners()
      this.hasActivePartners = data.data.some((partner) => {
        // partner relation never stopped
        return (
          partner.stoppedAt === null &&
          partner.impactSettings.some((setting) => {
            // end of the funding is ongoing, of end date is in the future
            return (
              setting.fundingEndsDate === null || new Date(setting.fundingEndsDate) > new Date()
            )
          })
        )
      })
    },
    maxAllowedIntegrations(selectedPlanType: NewPaidBusinessSubscriptionType) {
      switch (selectedPlanType) {
        case 'starterBusiness':
        case 'starterBusinessYearly':
          return 1
        case 'growthBusiness':
        case 'growthBusinessYearly':
          return 3
        case 'premiumBusiness':
        case 'premiumBusinessYearly':
          return Infinity
        case 'enterpriseBusiness':
          throw new Error('Enterprise plan does not support upgrade and downgrade')
        default:
          return 0
      }
    },
    maxCustomDomainAndTemplateAmount(selectedPlanType: NewPaidBusinessSubscriptionType) {
      switch (selectedPlanType) {
        case 'starterBusiness':
        case 'starterBusinessYearly':
          return 0
        case 'growthBusiness':
        case 'growthBusinessYearly':
          return 1
        case 'premiumBusiness':
        case 'premiumBusinessYearly':
        case 'enterpriseBusiness':
          return Infinity
        default:
          return 0
      }
    },
    reportDowngradeIssues(selectedPlanType: NewPaidBusinessSubscriptionType): DowngradeIssue[] {
      const returnValue: DowngradeIssue[] = []
      this.checkIntegrations(returnValue, selectedPlanType)
      this.checkCustomEmailTemplates(returnValue, selectedPlanType)
      this.checkCustomDomains(returnValue, selectedPlanType)
      this.checkPartners(returnValue, selectedPlanType)
      this.checkCustomerEngagementPortal(returnValue, selectedPlanType)
      return returnValue
    },
    checkIntegrations(
      downgradeIssues: DowngradeIssue[],
      selectedPlanType: NewPaidBusinessSubscriptionType,
    ): void {
      if (
        !!this.integrationsAmount &&
        this.integrationsAmount > this.maxAllowedIntegrations(selectedPlanType)
      ) {
        downgradeIssues.push({
          issue: 'integrations',
          currentAmount: this.integrationsAmount,
          maxAmount: this.maxAllowedIntegrations(selectedPlanType),
        })
      }
    },
    checkCustomDomains(
      downgradeIssues: DowngradeIssue[],
      selectedPlanType: NewPaidBusinessSubscriptionType,
    ): void {
      if (
        !(selectedPlanType === 'starterBusiness' || selectedPlanType === 'starterBusinessYearly') &&
        !!this.customDomainAmount &&
        this.customDomainAmount > this.maxCustomDomainAndTemplateAmount(selectedPlanType)
      ) {
        downgradeIssues.push({
          issue: 'customDomains',
          currentAmount: this.customDomainAmount,
          maxAmount: this.maxCustomDomainAndTemplateAmount(selectedPlanType),
        })
      }
    },
    checkCustomEmailTemplates(
      downgradeIssues: DowngradeIssue[],
      selectedPlanType: NewPaidBusinessSubscriptionType,
    ): void {
      if (
        !(selectedPlanType === 'starterBusiness' || selectedPlanType === 'starterBusinessYearly') &&
        !!this.activeCustomEmailTemplateAmount &&
        this.activeCustomEmailTemplateAmount >
          this.maxCustomDomainAndTemplateAmount(selectedPlanType)
      ) {
        downgradeIssues.push({
          issue: 'customEmailTemplates',
          currentAmount: this.activeCustomEmailTemplateAmount,
          maxAmount: this.maxCustomDomainAndTemplateAmount(selectedPlanType),
        })
      }
    },
    checkCustomerEngagementPortal(
      downgradeIssues: DowngradeIssue[],
      selectedPlanType: NewPaidBusinessSubscriptionType,
    ): void {
      if (
        (selectedPlanType === 'starterBusiness' || selectedPlanType === 'starterBusinessYearly') &&
        this.isCustomerEngagementPortalActive
      ) {
        downgradeIssues.push({ issue: 'customerEngagementPortal' })
      }
    },
    checkPartners(
      downgradeIssues: DowngradeIssue[],
      selectedPlanType: NewPaidBusinessSubscriptionType,
    ): void {
      if (
        (selectedPlanType === 'starterBusiness' || selectedPlanType === 'starterBusinessYearly') &&
        this.hasActivePartners
      ) {
        downgradeIssues.push({ issue: 'partners' })
      }
    },
  },
  computed: {
    integrationsAmount(): number {
      return this.$store.getters['getIntegrationsAmount']
    },
    customDomainAmount(): number {
      return this.$store.getters['getCustomDomainAmount']
    },
    isCustomerEngagementPortalActive(): number {
      return this.$store.getters['getActiveEmailTemplateAmount']
    },
    activeCustomEmailTemplateAmount(): number {
      return this.$store.getters['getCustomActiveEmailTemplateAmount']
    },
  },
})
