import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83947b10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-shopify-order-origin" }
const _hoisted_2 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("p", {
      class: _normalizeClass(['select-shopify-order-origin__title', { onboarding: _ctx.$route.path === '/onboarding' }])
    }, _toDisplayString(_ctx.t('title')), 3),
    _createVNode(_component_v_radio_group, {
      modelValue: _ctx.localValue,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
        _ctx.input
      ],
      "hide-details": ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shopifyOrderOrigins, (origin) => {
          return (_openBlock(), _createBlock(_component_v_radio, {
            key: origin,
            value: origin
          }, {
            label: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t(origin)), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "onUpdate:modelValue"])
  ]))
}