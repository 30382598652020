<template>
  <section class="welcome-view">
    <loading v-if="loading" />
    <div class="welcome-card">
      <img
        alt="leaf"
        class="card_leaf"
        height="726"
        loading="lazy"
        :src="require('@/assets/login-image.svg')"
        width="462"
      />
      <div class="content-wrapper">
        <div class="form-wrapper">
          <img
            class="greenspark-logo"
            height="32"
            :src="require('@/assets/greenspark-logo.svg')"
            width="160"
          />
          <h1 class="create-account-title">
            {{ t('create_account') }}
          </h1>
          <v-form v-model="isFormValid">
            <div class="name-wrapper">
              <v-text-field
                v-model="userObject.firstName"
                :label="t('first_name')"
                :rules="[rules.required]"
                color="#3B755F"
                flat
                variant="outlined"
                type="text"
              />

              <v-text-field
                v-model="userObject.lastName"
                :label="t('last_name')"
                :rules="[rules.required]"
                color="#3B755F"
                flat
                variant="outlined"
                type="text"
              />
            </div>
            <div class="name-wrapper">
              <div class="text-field-wrapper">
                <v-text-field
                  id="Email"
                  v-model="userObject.email"
                  :label="t('email')"
                  :rules="[rules.required, rules.email]"
                  autocomplete="email"
                  class="input-field"
                  color="#3B755F"
                  disabled
                  flat
                  name="Email"
                  variant="outlined"
                  readonly
                  type="email"
                />
              </div>
            </div>

            <div class="name-wrapper">
              <div class="text-field-wrapper">
                <v-text-field
                  id="Password"
                  v-model="userObject.password"
                  :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :label="t('password')"
                  :rules="[rules.required, rules.password]"
                  :type="showPassword ? 'text' : 'password'"
                  autocomplete="password"
                  class="input-field"
                  color="#3B755F"
                  flat
                  name="password"
                  variant="outlined"
                  @click:append-inner="showPassword = !showPassword"
                />
              </div>
            </div>

            <p v-if="account.emailAlreadyUsed" class="error-message">
              {{ t('already_account') }}
            </p>

            <div :class="['password-hint', { error: hasPasswordError }]">
              {{ t('password_hint') }}
            </div>
          </v-form>
        </div>
        <div class="navigation-wrapper">
          <div class="checkbox-wrapper">
            <v-checkbox v-model="checkbox" color="#3B755F" hide-details style="margin: 0" />
            <i18n-t class="mb-0 terms" keypath="SignupB2B2C.accept" tag="p">
              <a :href="TERMS_CONDITIONS_URL" class="link" target="_blank">{{ t('terms') }}</a>
              <a :href="PRIVACY_POLICY_URL" class="link" target="_blank">{{ t('privacy') }}</a>
            </i18n-t>
          </div>
          <v-btn :disabled="disabled" class="button" color="#3B755F" height="44" @click="signup">
            {{ t('create_account') }}
          </v-btn>
          <p class="login-text">
            <span>{{ t('login_text') }} </span>
            <router-link class="login-link ml-1" to="/login">
              {{ t('login_link') }}
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { createAccount, userRegistration } from '@api/index'
import type { Account } from '@/store'
import type { Customer } from '@/helpers/interfaces'
import Loading from '@/components/tools/Loading.vue'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import { PRIVACY_POLICY_URL, TERMS_CONDITIONS_URL } from '@/helpers/constants'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SignupB2B2C',
  components: { Loading },
  mixins: [RulesMixin, Utils],
  data() {
    return {
      showPassword: false,
      loading: false,
      checkbox: false,
      accountType: '',
      isFormValid: false,
      location: '',
      userObject: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
      },
      PRIVACY_POLICY_URL: '',
      TERMS_CONDITIONS_URL: '',
    }
  },
  computed: {
    disabled(): boolean {
      return !(this.checkbox && this.isFormValid)
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    authUserId(): string {
      return this.$store.getters['getAuthUserId']
    },
    customer(): Customer {
      return this.$store.getters['getCustomerData']
    },
    hasPasswordError(): boolean {
      return Boolean(this.userObject.password) && !this.rules.password(this.userObject.password)
    },
  },
  async created() {
    try {
      this.location = await this.getLocation()
    } catch (error) {
      this.location = ''
    }
    this.PRIVACY_POLICY_URL = PRIVACY_POLICY_URL
    this.TERMS_CONDITIONS_URL = TERMS_CONDITIONS_URL
    this.userObject.email = this.customer.email
    this.userObject.firstName = this.customer.firstName
    this.userObject.lastName = this.customer.lastName
  },
  methods: {
    t(key: string) {
      return this.$t(`SignupB2B2C.${key}`)
    },
    openUrl(value) {
      switch (value) {
        case 'terms':
          window.open(TERMS_CONDITIONS_URL, '_blank')
          break
        case 'privacy':
          window.open(PRIVACY_POLICY_URL, '_blank')
          break
      }
    },
    async signup() {
      this.loading = true

      const unsubscribe = this.$store.subscribe(async ({ type }) => {
        if (type === 'setAuthUser') {
          await this.createPayloadAndUser()
        }
        if (type === 'setEmailAlreadyUsed') {
          if (this.account.emailAlreadyUsed) {
            this.loading = false
            return
          }
        }
        if (type === 'setAccount') {
          await this.setOnboardingSkipped()
          await this.$router.replace('/success-personal')
          this.loading = false
          unsubscribe()
        }
      })

      await userRegistration({
        email: this.userObject.email,
        password: this.userObject.password,
        name: this.userObject.firstName + ' ' + this.userObject.lastName,
      })
    },
    async createPayloadAndUser() {
      if (!this.account.emailAlreadyUsed) {
        const livePageId = Date.now() + Math.floor(Math.random() * 1000)
        const payload = {
          user: {
            accountType: 'personal',
            email: this.userObject.email,
            firstName: this.userObject.firstName,
            lastName: this.userObject.lastName,
            address: {
              country: this.location,
            },
            accountId: this.authUserId,
            signupDate: Date.now(),
            termsAccept: this.checkbox,
            currency: this.account.currency,
            livePageId: livePageId,
            widgets: [''],
          },
          impactActionId: 'impactActionId',
        }
        const { data } = await createAccount(payload)
        this.setAccount(data)
        await this.setCurrentUser()
      }
    },
    setCurrentUser(): Promise<void> {
      return this.$store.dispatch('setCurrentUser')
    },
    setOnboardingSkipped(): Promise<void> {
      return this.$store.dispatch('setOnboardingSkipped')
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.welcome-view {
  align-content: center;
  align-items: center;
  background-image: url('../../assets/backgrounds/welcome-background.svg');
  background-size: cover;
  display: flex;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
}

.welcome-card {
  background: var(--ui-white);
  display: flex;
  overflow: hidden;
  max-width: 1150px;
  width: 100%;
}

.card_leaf {
  display: none;
  width: calc((100% / 5) * 2);
  object-fit: cover;
  height: auto;
}

.content-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.form-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.greenspark-logo {
  margin-bottom: 20px;
}

.create-account-title {
  font-size: 28px;
  margin-bottom: 15px;
  text-align: center;
}

.name-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
}

.input-label {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
  margin-bottom: 4px;
}

.input-description {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #b0b0b0;
  margin-bottom: 12px;
}

.text-field-wrapper {
  display: flex;
  width: 100%;
}

.employee-input {
  min-width: 90%;
  margin-bottom: 14px;
}

.error-message {
  font-size: 16px;
  color: #f9f9f9;
  font-weight: 500;
  text-align: left;
  width: 100%;
  padding: 8px 20px;
  background: red;
}

.password-hint.error {
  background: red;
  color: #f9f9f9;
}

.password-hint {
  text-align: left;
  width: 100%;
  padding: 8px 20px;
  background: #afc6bd;
  border-radius: 4px;
  margin-bottom: 22px;
  margin-top: 5px;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
}

.checkbox-wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.terms {
  display: flex;
  align-items: center;
  color: #212121;
  padding-top: 3px;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.navigation-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.button {
  color: var(--ui-white);
  margin-bottom: 10px;
  width: 100%;
}

.login-text {
  font-size: 14px;
  line-height: 17px;
}

.login-link {
  color: var(--ui-green);
  font-weight: 600;
  text-decoration: none;
}

.link {
  margin: 0 3px;
  color: var(--ui-green);
  font-weight: bold;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .welcome-view {
    padding: 20px 0;
  }

  .welcome-card {
    box-shadow: var(--box-shadow-wide);
    border-radius: var(--border-radius-big);
    max-width: 1150px;
    height: 100%;
    min-height: auto;
    width: 90%;
  }

  .content-wrapper {
    padding: 40px;
    min-height: auto;
  }

  .checkbox-wrapper {
    margin: 40px 0 25px 0;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .card_leaf {
    display: block;
  }

  .name-wrapper {
    flex-direction: row;
    gap: 10px;
  }

  .content-wrapper {
    width: calc((100% / 5) * 3);
  }

  .form-wrapper {
    width: 80%;
  }

  .navigation-wrapper {
    width: 80%;
  }
}
</style>
