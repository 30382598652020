<template>
  <div v-if="loaded">
    <div class="impact">
      <TotalFunding :trees="trees" :plastic="plastic" :carbon="carbon" />

      <div
        v-if="impacts && impacts.length > 0 && oneTimeImpacts && oneTimeImpacts.length > 0"
        class="plus"
      >
        +
      </div>

      <TotalFunding
        :trees="treesOneTime"
        :plastic="plasticOneTime"
        :carbon="carbonOneTime"
        :funding-type="fundingType"
      />

      <div class="button-wrapper">
        <gs-button :full-width="true" size="large" @click.prevent="goHome">
          {{ t('continue') }}
        </gs-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TotalFunding from '@/components/register/partner/TotalFunding.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NoAllowance',
  components: { TotalFunding },
  data() {
    return {
      trees: 0,
      plastic: 0,
      carbon: 0,
      treesOneTime: 0,
      plasticOneTime: 0,
      carbonOneTime: 0,
      loaded: false,
    }
  },
  created() {
    this.impacts?.map((impact) => {
      if (impact.type === 'carbon') this.carbon = impact.amount
      if (impact.type === 'trees') this.trees = impact.amount
      if (impact.type === 'plastic') this.plastic = impact.amount
    })

    this.oneTimeImpacts?.map((impact) => {
      if (impact.type === 'carbon') this.carbonOneTime = impact.amount
      if (impact.type === 'trees') this.treesOneTime = impact.amount
      if (impact.type === 'plastic') this.plasticOneTime = impact.amount
    })

    this.loaded = true
  },
  methods: {
    t(key: string) {
      return this.$t(`NoAllowance.${key}`)
    },
    goHome() {
      this.$router.push('/')
    },
  },
  props: {
    impacts: {
      type: Array as PropType<{ amount: number; type: string }[]>,
    },
    oneTimeImpacts: {
      type: Array as PropType<{ amount: number; type: string }[]>,
    },
    fundingType: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.impact {
  width: 100%;
  margin-top: 24px;
}

.plus {
  margin-bottom: 16px;
  font-size: 28px;
  font-weight: bold;
}

.button-wrapper {
  margin-top: 18px;
}
</style>
