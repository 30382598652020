<template>
  <svg width="80" height="84" viewBox="0 0 80 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.8527 77H40.9743V55.9849C40.9743 52.0933 39.9932 48.2921 38.0636 44.6895C36.1108 41.0463 33.2698 37.7687 29.6245 34.9472L30.7962 33.5206C34.6508 36.5002 37.6591 39.9763 39.7328 43.8498C41.8066 47.7143 42.8574 51.7999 42.8574 55.9849V77H42.8527Z"
      fill="#2DA86D"
    />
    <path
      d="M46.6153 77H44.748V55.3636C44.748 52.654 44.9052 49.81 45.2241 46.666C45.3859 45.0761 45.7325 43.6877 46.8973 42.0799C48.0112 40.5347 49.9062 38.7701 53.0354 36.3561L54.2001 37.7714C48.1175 42.465 47.3641 44.1401 47.0868 46.8407C46.7725 49.922 46.62 52.7077 46.62 55.3591V76.9955L46.6153 77Z"
      fill="#3B755F"
    />
    <path d="M4.104 8C4.104 8 11.0984 48.9965 32.3315 36.0517L4.104 8Z" fill="#2DA86D" />
    <path d="M4.104 8C4.104 8 45.3574 14.9509 32.3315 36.0517L4.104 8Z" fill="#3B755F" />
    <path
      d="M76.6869 13.1358C76.6869 13.1358 40.3613 19.2562 51.834 37.8339L76.6869 13.1358Z"
      fill="#2DA86D"
    />
    <path
      d="M76.6873 13.1358C76.6873 13.1358 70.5286 49.2351 51.8345 37.8339L76.6873 13.1358Z"
      fill="#3B755F"
    />
  </svg>
</template>
