<template>
  <div v-if="impact" style="margin-bottom: 40px">
    <div class="header">
      {{ headerTitle }}
    </div>
    <div class="wrapper">
      <div
        v-for="[key, comparison] in Object.entries(comparisons)"
        :key="key"
        class="impact-wrapper"
      >
        <div :class="['comparison-animation', key]">
          <img
            :class="['comparison-icon', key]"
            height="30"
            width="30"
            :src="require('@/assets/icons/comparisons/' + snakeCase(key) + '.svg')"
            :alt="snakeCase(key)"
          />
        </div>
        <div class="comparison-number">
          {{ formatTotalAmounts(comparison.value, comparison.suffix) }}
        </div>
        <div class="comparison-description">
          {{ tc(`${impactType}.${snakeCase(key)}`, Number(comparison)) }}
        </div>
        <Tooltip
          class="tooltip"
          :body="t(`${impactType}.${snakeCase(key)}_tooltip`)"
          :link-text="'Source'"
          :link="comparison.link"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Tooltip from '@/components/tools/Tooltip.vue'
import type { Account } from '@/store'
import type { TranslateResult } from 'vue-i18n'
import { getComparisons } from '@/helpers/pricing'
import type { Comparison, OffsetType } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { User } from '@/store/users'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ComparisonCardPublic',
  components: {
    Tooltip,
  },
  mixins: [Utils],
  data() {
    return {
      headerTitle: '',
    } as {
      headerTitle: TranslateResult
    }
  },
  computed: {
    comparisons(): Comparison {
      return getComparisons(this.impact ?? 0, this.impactType, this.language.locale)
    },
    name(): string {
      if (this.account.accountType === 'business' || this.account.accountType === 'ecommerce') {
        return this.account.companyName
      }
      return this.currentUser.firstName
    },
    language(): { locale: string } {
      return this.$store.getters['getUserLanguage']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
  },
  created() {
    this.setHeaderTitle()
  },
  methods: {
    t(key: string) {
      return this.$t(`ComparisonCardPublic.${key}`)
    },
    tc(key: string, count: number) {
      return this.$t(`ComparisonCardPublic.${key}`, count)
    },
    setHeaderTitle() {
      switch (this.impactType) {
        case 'trees':
          this.headerTitle = this.livePage
            ? this.t('trees.header_trees')
            : this.t('trees.private_header_trees')
          break
        case 'carbon':
          this.headerTitle = this.livePage
            ? this.t('carbon.header_co2')
            : this.t('carbon.private_header_co2')
          break
        case 'plastic':
          this.headerTitle = this.livePage
            ? this.t('plastic.header_plastic')
            : this.t('plastic.private_header_plastic')
          break
        case 'kelp':
          this.headerTitle = this.livePage
            ? this.t('kelp.header_kelp')
            : this.t('kelp.private_header_kelp')
          break
        default: {
          const exhaustiveCheck: never = this.impactType
          throw new Error(exhaustiveCheck)
        }
      }
    },
    openUrl(link: string) {
      window.open(link, '_blank')
    },
    onLocaleChange() {
      this.setHeaderTitle()
    },
  },
  props: {
    livePage: { required: true, type: Boolean },
    impact: {
      type: Number,
    },
    impactType: { type: String as () => OffsetType, default: 'trees' },
  },
  watch: {
    'language.locale': [
      {
        handler: 'onLocaleChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  color: #212121;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 24px;
}

.impact-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 300px;
  position: relative;
  border-radius: 8px;
  padding: 34px 24px;
  background-size: cover;
  width: 32%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.impact-wrapper:nth-child(3n + 1) {
  background-image: url('../../assets/gifs/comparisons/comparison-card-background.svg');
}

.impact-wrapper:nth-child(3n + 2) {
  background-image: url('../../assets/gifs/comparisons/comparison-card-middle-background.svg');
}

.impact-wrapper:nth-child(3n + 3) {
  background-image: url('../../assets/gifs/comparisons/comparison-card-right-background.svg');
}

.comparison-animation.carbonSequestered {
  margin-bottom: -10px;
}

.comparison-icon {
  height: 30px;
  width: 30px;
  max-width: 85px;
}

.comparison-icon.carbonSequestered {
  height: 40px;
  width: 40px;
  max-width: 85px;
}

.comparison-number {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
}

.comparison-description {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .header {
    font-size: 22px;
  }

  .comparison-number {
    font-size: 32px;
    text-align: center;
  }

  .comparison-description {
    font-size: 16px;
  }
}

@media #{map-get($display-breakpoints, 'xs')} {
  .wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .impact-wrapper {
    width: 100%;
    padding: 25px 20px;
    margin-bottom: 15px;
    height: 230px;
  }

  .comparison-description {
    max-width: 100%;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip {
    margin-top: 10px;
  }
}
</style>
