import type { OnboardingSteps } from '@api/index'
import { getOnboardingSteps, updateOnboardingSteps } from '@api/index'
import router from '@/router'
import type { ActionContext } from 'vuex'
import type { StateInterface } from '@/store/index'

interface Onboarding {
  isOnboardingSkipped: boolean
}

export const onboardingModule = {
  state: () =>
    ({
      isOnboardingSkipped: false,
    } as Onboarding),
  mutations: {
    setOnboardingSkipped(state: Onboarding, isOnboardingSkipped: boolean) {
      state.isOnboardingSkipped = isOnboardingSkipped
    },
  },
  actions: {
    async setOnboardingSkipped(
      { commit, state }: ActionContext<Onboarding, StateInterface>,
      withNavigation = true,
    ) {
      let onboardingSteps: OnboardingSteps | null = null
      try {
        onboardingSteps = await getOnboardingSteps()
      } catch (e) {
        console.error(e)
      }
      commit('setOnboardingSkipped', onboardingSteps ? onboardingSteps.skipOnboarding?.value : true)
      if (withNavigation && !state.isOnboardingSkipped) {
        const route = await router.replace('/onboarding')
        return route
      }
    },
    async updateOnboardingSkipped({ commit }: ActionContext<Onboarding, StateInterface>) {
      const onboardingSteps = await updateOnboardingSteps('skipOnboarding')
      commit('setOnboardingSkipped', onboardingSteps ? onboardingSteps.skipOnboarding?.value : true)
    },
  },
  getters: {
    getOnboardingSkipStatus: (state: Onboarding) => state.isOnboardingSkipped,
  },
}
