import axios, { type AxiosError, type AxiosResponse } from 'axios'
import firebase from '@/auth/firebaseConfig.js'
import { authInterceptor, tokenInterceptor } from '@/auth'
import store, {
  type Account,
  type AccountImpact,
  type AccountResponse,
  type AuthUser,
  getLocalStorageKey,
  type MerchantProduct,
  type TransferredImpact,
} from '@/store'
import type {
  AutomationTrigger,
  Currency,
  CurrencyCode,
  Customer,
  CustomIntegrationType,
  DashboardType,
  FeedItemsResponse,
  ImpactAction,
  ImpactByBrand,
  IntegrationPlatform,
  IntegrationType,
  LivePageData,
  OffsetType,
  ResponseTarget,
  SustainabilityGoal,
  TotalImpact,
} from '@/helpers/interfaces'
import * as Sentry from '@sentry/vue'
import type {
  AlertType,
  BusinessSubscriptionType,
  FamilySubscriptionType,
  PaidBusinessMonthlySubscriptionType,
  ProjectId,
} from '@/helpers/constants'
import type {
  NewPaidBusinessSubscriptionType,
  PersonalSubscriptionType,
  TechnicalAndBusinessSubscriptionType,
} from '@/helpers/pricing'
import i18n from '@/i18n'
import type {
  Domain,
  DomainVerification,
  EmailTemplate,
  EmailTemplateWithStatus,
} from '@/store/customer-engagement'
import type { CarouselProjectItem, CarouselQuoteItem } from '@/components/register/CarouselCard.vue'
import type { ChecklistItemType } from '@/store/checklist'
import type { TeamMemberStatus } from '@/store/users'
import type {
  Automation,
  AutomationModifier,
  AutomationOffset,
  IIntegrationWriteDirection,
  IIntegrationWriteDirectionTestStatus,
  Integration,
  IntegrationOptionResponse,
  IntegrationStatus,
} from '@/store/integrations'
import type { ConnectEarthSession } from '@/store/bank-accounts'
import type { ApiUsageItem } from '@/store/api-usage'
import { getApiUrl } from './helpers'
import { AUTH_TOKEN_COOKIE, clearCookie } from '@/helpers/cookie'
import type { SubscriptionItem } from '@/store/subscriptions'
import type { Notification } from '@/store/notification'

export * from './admin-api'

const stripe = axios.create({
  baseURL: getApiUrl('dashboard'),
  timeout: 100000,
  // withCredentials: true
})

const api = axios.create({
  baseURL: getApiUrl('dashboard'),
  timeout: 100000,
})

const openApi = axios.create({
  baseURL: getApiUrl('openApi'),
  timeout: 100000,
})

const publicOpenApi = axios.create({
  baseURL: getApiUrl('openApi'),
  timeout: 100000,
})

const geoLocationApi = axios.create({
  baseURL: 'https://get.geojs.io/',
  timeout: 100000,
})

function generalErrorHandler(error: AxiosError) {
  switch (error.response?.status) {
    case 301:
      store.dispatch('notification/notify', {
        text: i18n.global.t('CommonUi.old_version_alert'),
        isError: true,
        isClosable: true,
        buttonText: 'close',
      } as Notification)
      break
    default:
      return Promise.reject(error)
  }
}

// interceptor adds the token
api.interceptors.request.use(tokenInterceptor)

// interceptor adds the token
openApi.interceptors.request.use(tokenInterceptor)

api.interceptors.response.use(function (response) {
  return response
}, generalErrorHandler)

api.interceptors.response.use(function (response) {
  return response
}, authInterceptor)

openApi.interceptors.response.use(function (response) {
  return response
}, generalErrorHandler)

openApi.interceptors.response.use(function (response) {
  return response
}, authInterceptor)

geoLocationApi.interceptors.response.use(
  function (response) {
    if (!response?.data?.country) {
      Sentry.captureException(response, {
        extra: { ...JSON.parse(JSON.stringify(response)) },
      })
    }
    return response
  },
  async function (error) {
    console.error('geoLocationApi error', { ...JSON.parse(JSON.stringify(error)) })
    Sentry.captureException(error, {
      extra: { ...JSON.parse(JSON.stringify(error)) },
    })
    return Promise.reject(error)
  },
)

export const getCountryCodeByIP = async () => {
  const { data } = await geoLocationApi.get('v1/ip/country.json')
  return data?.country
}

export const startCheckout = (body: object) => {
  return stripe.post<{ id: string }>('checkout/', body)
}

export const createPaymentIntent = (
  body: object,
): Promise<AxiosResponse<{ clientSecret: string }>> => {
  return api.post('checkout/payment-intent', body)
}

export const createSetupIntent = (body?: object) => {
  return api.post('checkout/setup-intent', body)
}

export interface PaymentPayloadImpact {
  type: OffsetType
  amount: number
  projectId?: ProjectId
}

export interface PaymentPayload {
  impacts: PaymentPayloadImpact[]
}

export const payment = async (body: PaymentPayload) => {
  const res = await api.post<Account & { accountFromMarketplace?: boolean }>(
    'checkout/payment',
    body,
  )
  if (res.data) {
    res.data.userFromMarketplace = res.data.accountFromMarketplace ?? false
    delete res.data.accountFromMarketplace
  }
  return res
}

export interface InvoiceItem {
  date: number
  url: string
}

export interface InvoiceResponse {
  data: 'SHOPIFY_USER' | InvoiceItem[]
}

export const userInvoices = async (): Promise<InvoiceResponse> => {
  const res: Promise<InvoiceResponse> = api.post('checkout/invoices')
  return res
}

export interface UpdateSubscriptionProduct {
  type: 'plasticHero' | 'treePlanter' | 'standard' | 'premium' | 'superior'
  amount: number
}

export interface UpdateSubscriptionPayload {
  products: UpdateSubscriptionProduct[]
}

export interface DnsSetting {
  type: string
  host: string
  value: string
}

export const upsertTeamMembers = async (
  body: UpdateSubscriptionPayload,
): Promise<AxiosResponse<Account>> => {
  const res = await api.post('team-members', body)
  if (res.data) {
    res.data.userFromMarketplace = res.data.accountFromMarketplace
    delete res.data.accountFromMarketplace
  }
  return res
}

export const deleteSubscription = () => {
  return api.post('checkout/delete')
}

export const getStripePortal = () => {
  return api.post('checkout/portal')
}

export const getLivePage = async (id: string): Promise<AxiosResponse<LivePageData>> => {
  return api.get<LivePageData>(`dashboard/${id}`)
}

export interface ReceiptItem {
  id: string
  imageUrl: string
  receiptName: string
}

export interface Receipt {
  type: OffsetType
  date: string
  time: number
  receiptId: string
  receipts: ReceiptItem[]
}

// get data for shopify signups
export interface GetDataForShopifySignupResponse {
  email: string
  foundAccount: boolean
  publicTokens: string[]
}

export const getDataForShopifySignup = (
  body: object,
): Promise<AxiosResponse<GetDataForShopifySignupResponse>> => {
  return api.post('/dashboard/getdataforshopifysignup', body)
}

export type GetAllImpactDataResponse = {
  [key in OffsetType]: number
}

// Get totals for public Greenspark members impact overview
export const getAllImpactData = async (): Promise<GetAllImpactDataResponse> => {
  const { data } = await api.get<GetAllImpactDataResponse>('/dashboard/getallimpactdata')
  return data
}

interface CreatePaymentSubscriptionRequestBody {
  product: BusinessSubscriptionType
  paymentType?: 'addcard'
  metadata: {
    type: 'subscription' | 'customerUpgrade'
    accountId: string
  }
}

export const createSubscription = (
  body: CreatePaymentSubscriptionRequestBody,
): Promise<AxiosResponse<Subscription>> => {
  return api.post('subscription', body)
}

export const addEcommerceSubscription = (body: object) => {
  // TODO: this does not exists anymore
  return api.post('checkout/add-ecommerce-subscription', body)
}

// FIREBASE
// Register User
export const userRegistration = (user: { email: string; password: string; name: string }) => {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(user.email, user.password)
    .then((res) => {
      if (res.additionalUserInfo.isNewUser) {
        store.commit('setEmailAlreadyUsed', false)
        store.commit('setSignupComplete', true)
        const user = res.user
        store.commit('setAuthUser', user)
        store.commit('setUserId', user.uid)
      }
      res.user
        .updateProfile({
          displayName: user.name,
        })
        .then(() => {
          store.commit('setEmailAlreadyUsed', false)
          store.commit('setSignupComplete', true)
        })
    })
    .catch((error) => {
      store.commit('setEmailAlreadyUsed', true)
      store.commit('setSignupComplete', false)
      console.error('error', error)
    })
}

// Logout
export const logOut = () => {
  const account = store.state.account
  firebase
    .auth()
    .signOut()
    .then(() => {
      store.commit('setPublicToken', '')
      clearCookie(AUTH_TOKEN_COOKIE)
      if (account.userFromMarketplace) {
        window.localStorage.removeItem(getLocalStorageKey())
        indexedDB.deleteDatabase('firebase-installations-database')
        indexedDB.deleteDatabase('firebaseLocalStorageDb')
        window.location.href = '/login?app'
      } else {
        window.localStorage.removeItem(getLocalStorageKey())
        indexedDB.deleteDatabase('firebase-installations-database')
        indexedDB.deleteDatabase('firebaseLocalStorageDb')
        window.location.href = '/login'
      }
    })
    .catch((error) => console.error(error))
}

// Canceled Logout
export const canceledSubscriptionLogout = () => {
  store.commit('setAuthUser', { uid: '' })
  window.localStorage.removeItem(getLocalStorageKey())
  firebase
    .auth()
    .signOut()
    .then(() => {
      indexedDB.deleteDatabase('firebase-installations-database')
      indexedDB.deleteDatabase('firebaseLocalStorageDb')
    })
    .catch((error) => console.error(error))
}

// forgot password
export const forgotPassword = (user: { email: string }) => {
  firebase
    .auth()
    .sendPasswordResetEmail(user.email)
    .then(() => {
      store.commit('setPasswordReset', true)
      setTimeout(() => {
        store.commit('setPasswordReset', false)
      }, 5000)
    })
    .catch((error) => {
      console.error('error:', error)
      store.commit('setPasswordResetError', true)
      setTimeout(() => {
        store.commit('setPasswordResetError', false)
      }, 5000)
    })
}

export const updatePassword = (data: {
  password: string
  actionCode: string | (string | null)[]
}) => {
  const auth = firebase.auth()
  // Verify the password reset code is valid.
  auth
    .verifyPasswordResetCode(data.actionCode)
    .then((email) => {
      const accountEmail = email

      const newPassword = data.password

      // Save the new password.
      auth
        .confirmPasswordReset(data.actionCode, newPassword)
        .then(() => {
          // Password reset has been confirmed and new password updated.

          // sign-in the user directly
          auth.signInWithEmailAndPassword(accountEmail, newPassword)

          // re-direct to dashboard
          window.location.href = '/'

          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
        })
        .catch((error) => {
          console.error('error:', error)
          // Error occurred during confirmation. The code might have expired or the
          // password is too weak.
          store.commit('setPasswordReset', true)
          setTimeout(() => {
            store.commit('setPasswordReset', false)
          }, 10000)
        })
    })
    .catch((error) => {
      console.error('error:', error)
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
      store.commit('setPasswordReset', true)
      setTimeout(() => {
        store.commit('setPasswordReset', false)
      }, 10000)
    })
}

export interface AccountDto {
  companyName: string
  accountId: string
  impacts: AccountImpact[]
  transferredImpacts: TransferredImpact[]
}

export const fetchAccount = async (): Promise<AxiosResponse<AccountResponse>> => {
  const res = await api.get<AccountResponse & { accountFromMarketplace?: boolean }>('/v2/account')
  if (res.data) {
    res.data.userFromMarketplace = res.data.accountFromMarketplace ?? false
    delete res.data.accountFromMarketplace
  }
  return res
}

export const getImpactActions = async (
  skip = 0,
  limit = 4,
  brandId = '',
  fromDate?: string,
  toDate?: string,
): Promise<{
  count: number
  data: ImpactAction[]
}> => {
  const url = `impact-actions?skip=${skip}&limit=${limit}&sellerAccountId=${brandId}${
    fromDate ? '&fromDate=' + fromDate : ''
  }${toDate ? '&toDate=' + toDate : ''}`
  const { data } = await api.get<{
    count: number
    data: (ImpactAction & { sellerAccountId?: string; sellerAccountName?: string })[]
  }>(url)
  if (data && data.data) {
    data.data = data.data.map((ia) => {
      ia.sellerUserId = ia.sellerAccountId ?? ''
      ia.sellerUsername = ia.sellerAccountName ?? ''
      delete ia.sellerAccountId
      delete ia.sellerAccountName
      return ia
    })
  }
  return data
}

// RUTTER

export interface SetupRecurringChargeRequestBody {
  integrationId: string
  product?: BusinessSubscriptionType
  url?: string
}

// Rutter setup recurring charge shopify
export const setUpRecurringChargeShopify = async (
  body: SetupRecurringChargeRequestBody,
): Promise<AxiosResponse<Integration>> => {
  return await api.post('/rutter/setuprecurringchargeshopify', body)
}

export const updateAccountSubscription = async (body: {
  ['product']: NewPaidBusinessSubscriptionType | PersonalSubscriptionType | FamilySubscriptionType
}): Promise<AxiosResponse<Subscription>> => {
  return api.put('subscription', body)
}

export const getSubscriptionItems = async (): Promise<SubscriptionItem[]> => {
  const { data } = await api.get<(SubscriptionItem & { payerAccountId?: string })[]>(
    'subscription-items',
  )
  if (data) {
    return data.map((si) => {
      si.payerUserId = si.payerAccountId ?? ''
      delete si.payerAccountId
      return si
    })
  }
  return data
}

export const getSubscriptions = async (): Promise<Subscription[]> => {
  const { data } = await api.get<Subscription[]>('subscriptions')
  return data
}

export const getOnboardingSteps = async (): Promise<OnboardingSteps> => {
  const { data } = await api.get('/onboarding-steps')
  if (data) {
    data.userId = data.accountId
    delete data.accountId
  }
  return data
}
export const firebaseLogin = async (email: string, password: string): Promise<AuthUser> => {
  return new Promise((resolve, reject) =>
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userInfo) => resolve(userInfo.user))
      .catch(reject),
  )
}

// Login
export const userLogin = async (user: { email: string; password: string }) => {
  window.localStorage.removeItem(getLocalStorageKey())
  const userInfo = await firebaseLogin(user.email, user.password)
  store.commit('setLoginError', false)
  window.localStorage.removeItem(getLocalStorageKey())
  store.commit('setAuthUser', userInfo)
}

interface AccountInformation {
  user: {
    accountId: string
    livePageId: number
    accountType: string
    email: string
    firstName: string
    lastName: string
    signupDate: number
    termsAccept: boolean
    currency: Currency
    userFromMarketplace?: boolean
    address: {
      country: string
      street?: string
      postCode?: string
      city?: string
    }
    employeePlans?: {
      standard: number
      superior: number
      premium: number
    }
    personalPlans?: {
      personal?: number
      plasticHero?: number
      earthHero?: number
      positiveFamilyAdult?: number
      positiveFamilyChild?: number
      positiveFamilyPet?: number
      earthPositiveFamilyAdult?: number
      earthPositiveFamilyChild?: number
      earthPositiveFamilyPet?: number
    }
    employeePlanAddons?: {
      treePlanter: number
      plasticHero: number
    }
    personalPlanAddons?: {
      treeAddon: number
      plasticAddon?: number
    }
    companyName?: string
    widgetFolderName?: string
    vatId?: string
    widgets?: string[]
  }
  impactActionId?: string
}

export const createAccount = async (body: AccountInformation) => {
  const res = await api.post('/v2/accounts', body)
  if (res.data) {
    res.data.userFromMarketplace = res.data.accountFromMarketplace
    delete res.data.accountFromMarketplace
  }
  return res
}

export const updateAccount = async (body: object) => {
  const res = await api.put('/v2/accounts', body)
  if (res.data) {
    res.data.userFromMarketplace = res.data.accountFromMarketplace
    delete res.data.accountFromMarketplace
  }
  return res
}

// Create trackable QR code link
export const createLinkForQrCode = () => {
  return api.post('/api/create-link')
}

// Create user widgets
export const createAccountWidgets = async (body: object): Promise<AxiosResponse<Account>> => {
  const res = await api.put('api/createuserwidgets', body)
  if (res.data) {
    res.data.userFromMarketplace = res.data.accountFromMarketplace
    delete res.data.accountFromMarketplace
  }
  return res
}

// ADMIN FUNCTIONS
export const getAllReceipts = async (): Promise<Receipt[]> => {
  const { data } = await api.get<Receipt[]>('receipts')
  return data.sort((a, b) => b.time - a.time)
}

export const createReceipt = (date: string, type: OffsetType): Promise<Receipt> => {
  return api.post('/receipts', { date, type })
}

export const updateReceipt = (id: string, receipts: ReceiptItem[]): Promise<Receipt> => {
  return api.put(`/receipts/${id}`, { receipts })
}

export const deleteReceipt = (id: string): Promise<void> => {
  return api.delete(`/receipts/${id}`)
}

export const deleteReceiptItem = (id: string, itemId: string): Promise<Receipt> => {
  return api.delete(`/receipts/${id}/items/${itemId}`)
}

// Rutter check recurring charge setup shopify
export const checkRecurringChargeShopify = async (payload: { integrationId: string }) => {
  const res = await api.post('/rutter/checkrecurringchargeshopify', payload)
  if (res.data) {
    res.data.userFromMarketplace = res.data.accountFromMarketplace
    delete res.data.accountFromMarketplace
  }
  return res
}

// Add a new integration to a user, after a user connect a source on th UI
export const addIntegration = async (
  type: string,
  source: string,
  externalId: string,
): Promise<AxiosResponse<Account>> => {
  const res = await api.post('/api/add-integration', {
    type,
    source,
    externalId,
  })
  if (res.data) {
    res.data.userFromMarketplace = res.data.accountFromMarketplace
    delete res.data.accountFromMarketplace
  }
  return res
}

const mapRelation = (relation) => {
  relation.impactSettings = relation.impactSettings.map((is) => {
    is.userId = is.accountId
    delete is.accountId
    if (is.allowances) {
      is.allowances = is.allowances.map((a) => {
        if (a.claimedImpacts) {
          a.claimedImpacts = a.claimedImpacts.map((ci) => {
            ci.userId = ci.accountId
            delete ci.userId
            return ci
          })
        }
        return a
      })
    }
    return is
  })
  relation.temporaryImpacts = relation.temporaryImpacts.map((temporaryImpact) => {
    temporaryImpact.userId = temporaryImpact.accountId
    delete temporaryImpact.accountId
    return temporaryImpact
  })
  relation.totalImpacts = relation.totalImpacts.map((totalImpact) => {
    totalImpact.userId = totalImpact.accountId
    delete totalImpact.accountId
    return totalImpact
  })
  relation.stashedImpacts = relation.stashedImpacts.map((stashedImpact) => {
    stashedImpact.userId = stashedImpact.accountId
    delete stashedImpact.accountId
    return stashedImpact
  })
  return relation
}

// gets all of the referred users
export const listReferrals = async (
  sortBy: string,
  sortDirection: string,
  skip: number,
  limit: number,
  search: string,
) => {
  const res = await api.get(
    `/relations/referrals?sortBy=${sortBy}&sortDirection=${sortDirection}&skip=${skip}&limit=${limit}&search=${search}`,
  )
  if (res.data && res.data.data) {
    res.data.data = res.data.data.map((relation) => {
      return mapRelation(relation)
    })
  }
  return res
}

// gets the overall impact from referrals
export const getReferralImpact = async () => {
  return api.get('/relations/referrals/impact')
}

// get the referral impact
export const getReferralInvite = async (body: string) => {
  const res = await api.get(`/relations/referralinvite/${body}`)
  if (res.data) {
    res.data.userId = res.data.accountId
    delete res.data.accountId
  }
  return res
}

// update or create the referral impact
export const upsertReferralInvite = async (body: {
  activeImpact: { type: OffsetType; amount: number }
}) => {
  const res = await api.post('/relations/referralinvite', body)
  if (res.data) {
    res.data.userId = res.data.accountId
    delete res.data.accountId
  }
  return res
}

// PARTNER ENDPOINTS

export interface GetPartnerDataResponse {
  _id: string
  connector: {
    id: string
    displayName: string
    imageURL: string
    currency: Currency
  }
  connectee: {
    id: string
    displayName: string
    firstName: string
    lastName: string
    imageURL: string
  }
  inviteSentAt: string
  inviteAcceptedAt: string | null
  stoppedAt: string | null
  type: 'PARTNER'
  lastInviteNudge: string | null
  impactSettings: [
    {
      subscriptions: [
        {
          type: PaidBusinessMonthlySubscriptionType
          amount: number
          productId: string
          priceId: string
          taxRatesIds: string[]
          source: string
        },
      ]
      impacts: {
        type: OffsetType
        amount: number
        productId: string
        priceId: string
        taxRatesIds: string[]
      }[]
      allowances: {
        amount: number
        productId: string
        priceId: string
        taxRatesIds: string[]
        claimedAt: string
        source: string
      }[]
      userId: string
      fundingMonths: number
      fundingEndsDate: string
      source: string
    },
  ]
  temporaryImpacts: {
    type: OffsetType
    amount: number
    userId: string
    source: string
  }[]
  totalImpacts: [
    {
      type: OffsetType
      amount: number
      userId: string
    },
  ]
}

export interface PartnerData {
  _id: string
  connector: {
    id: string
    displayName: string
    imageURL: string
  }
  connectee: {
    id: string
    displayName: string
    firstName: string
    lastName: string
    imageURL: string
    email?: string
  }
  inviteSentAt: string
  inviteAcceptedAt: string | null
  stoppedAt: string | null
  type: 'PARTNER'
  lastInviteNudge: string | null
  impactSettings: [
    {
      subscriptions: [
        {
          type: PaidBusinessMonthlySubscriptionType
          amount: number
          productId: string
          priceId: string
          taxRatesIds: string[]
          source: string
        },
      ]
      impacts: {
        type: OffsetType
        amount: number
        productId: string
        priceId: string
        taxRatesIds: string[]
      }[]
      allowances: {
        amount: number
        productId: string
        priceId: string
        taxRatesIds: string[]
        claimedAt: string
        source: string
      }[]
      userId: string
      fundingMonths: number
      fundingEndsDate: string
      source: string
    },
  ]
  temporaryImpacts: {
    type: OffsetType
    amount: number
    userId: string
  }[]
  totalImpacts: [
    {
      type: OffsetType
      amount: number
      userId: string
    },
  ]
}
export interface GetAllPartnersDataResponse {
  data: PartnerData[]
  count: number
}

// gets all the partners
export const listPartners = async (
  sortBy = 'added',
  sortDirection = 'desc',
  skip = 0,
  limit = 10,
  search = '',
): Promise<AxiosResponse<GetAllPartnersDataResponse>> => {
  const res = await api.get<GetAllPartnersDataResponse>(
    `/relations/partners?sortBy=${sortBy}&sortDirection=${sortDirection}&skip=${skip}&limit=${limit}&search=${search}`,
  )
  if (res.data && res.data.data) {
    res.data.data = res.data.data.map((relation) => {
      return mapRelation(relation)
    })
  }
  return res
}

// give a reward
export const giveReward = async (id: string, body: object) => {
  return api.post(`/relations/${id}/reward`, body)
}

// gets the overall impact from partnerships
export const getPartnerImpact = async () => {
  return api.get('/relations/partners/impact')
}

interface GetPaymentsDataResponse {
  data: {
    _id: string
    allowances: {
      amount: number
      productId: string
      priceId: string
      taxRatesIds: string[]
      claimedAt: string
    }[]
    impacts: {
      type: OffsetType
      amount: number
      productId: string
      priceId: string
      taxRatesIds: string[]
    }[]
    subscriptions: [
      {
        type: PaidBusinessMonthlySubscriptionType
        amount: number
        productId: string
        priceId: string
        taxRatesIds: string[]
      },
    ]
    paidAt: string
    source: string
    userId: string
    accountId?: string
  }[]
  count: number
}

// gets the funding history of the relationship
export const listPayments = async (
  id: string,
  sortBy: string,
  sortDirection: string,
  skip: number,
  limit: number,
): Promise<AxiosResponse<GetPaymentsDataResponse>> => {
  const res = await api.get<GetPaymentsDataResponse>(
    `/relations/${id}/payments?sortBy=${sortBy}&sortDirection=${sortDirection}&skip=${skip}&limit=${limit}`,
  )
  if (res.data && res.data.data) {
    res.data.data = res.data.data.map((payment) => {
      payment.userId = payment.accountId ?? ''
      delete payment.accountId
      return payment
    })
  }
  return res
}

// stop partnership
export const stopPartnership = async (id: string) => {
  return api.delete(`/relations/${id}`)
}

// add a new partner
export const addPartner = async (body: object) => {
  const res = await api.post('/relations/partner', body)
  if (res.data) {
    res.data = mapRelation(res.data)
  }
  return res
}

// edit the partner to update their details
export const editPartner = async (body: object, relationId: string) => {
  const res = await api.put(`/relations/${relationId}/impact-settings`, body)
  if (res.data) {
    res.data = mapRelation(res.data)
  }
  return res
}

interface RelationContact {
  firstName: string
  lastName: string
  email: string
  displayName: string
}

export const editPartnerContact = async (body: RelationContact, relationId: string) => {
  const res = await api.put(`/relations/${relationId}`, body)
  res.data = mapRelation(res.data)
  return res
}

// Send email invitation reminder
export const sendReminder = async (relationId: string) => {
  const res = await api.post(`/relations/${relationId}/nudging`)
  if (res.data) {
    res.data = mapRelation(res.data)
  }
  return res
}

// get the partner data during signup
export const getRelationDetailsForSignUp = (relationsId: string) => {
  return api.get(`/dashboard/relations/${relationsId}/signup-details`)
}

// get data on a specific relation
export const getRelation = async (
  relationsId: string,
): Promise<AxiosResponse<GetPartnerDataResponse>> => {
  const res = await api.get(`relations/${relationsId}`)
  if (res.data as GetPartnerDataResponse) {
    res.data = mapRelation(res.data)
  }
  return res
}

export interface ActionsRequiredResponse {
  type: AlertType
  amount?: number
  fundingEndsDate?: string
  relationId?: string
}

export const getActionsRequired = (): Promise<AxiosResponse<ActionsRequiredResponse[]>> => {
  return api.get('relations/requires-action')
}

export interface ClaimedAllowanceRequestBody {
  impacts: {
    type: OffsetType
    amount: number
    projectId: string
  }[]
}

// claim any changes in allowance
export const claimAllowance = (payload: ClaimedAllowanceRequestBody, relationsId: string) => {
  return api.put(`relations/${relationsId}/allowance`, payload)
}

// get data for tooltip in main dashboard
export interface GetOverallAccountImpactFromRelationsResponseBase {
  count: number
  relationSumImpact: { [key in OffsetType]: number }
}

export interface GetOverallAccountImpactFromRelationsApiResponse
  extends GetOverallAccountImpactFromRelationsResponseBase {
  relatedAccountSumImpact: { [key in OffsetType]: number }
}

export interface GetOverallAccountImpactFromRelations
  extends GetOverallAccountImpactFromRelationsResponseBase {
  relatedUserSumImpact: { [key in OffsetType]: number }
}

export const getOverallAccountImpactFromRelations = async (
  type: string,
): Promise<GetOverallAccountImpactFromRelations> => {
  const {
    data: { count, relationSumImpact, relatedAccountSumImpact },
  } = await api.get<GetOverallAccountImpactFromRelationsApiResponse>(
    `relations/overall-impact?type=${type}`,
  )
  // deep copied relatedAccountSumImpact into relatedUserSumImpact for the fronted to use
  let returnValue = {} as GetOverallAccountImpactFromRelations
  const relatedUserSumImpact = { ...relatedAccountSumImpact }

  returnValue = {
    count,
    relationSumImpact,
    relatedUserSumImpact,
  }
  return returnValue
}

// check the referral
export const addReferral = async (body: object): Promise<void> => {
  await api.post('/relations/referral', body)
}

// upgrade plan shopify
export const upgradePlanShopify = async (body: object) => {
  return api.post('/shopify/upgrade-subscription', body)
}

export const getCustomer = async (impactActionId: string): Promise<AxiosResponse<Customer>> => {
  return await api.get<Customer>(`customer?id=${impactActionId}`)
}

export const getImpactActionBrands = async () => {
  const { data } = await api.get('impact-action-brands')
  return data.map((brand) => {
    return {
      title: brand.companyName,
      value: brand.accountId,
    }
  })
}

export const getImpactActionById = async (
  impactActionId: string,
): Promise<AxiosResponse<ImpactAction>> => {
  const res = await api.get<
    ImpactAction & { sellerAccountId?: string; sellerAccountName?: string }
  >(`impact-actions/${impactActionId}`)
  if (res.data) {
    res.data.sellerUserId = res.data.sellerAccountId ?? ''
    res.data.sellerUsername = res.data.sellerAccountName ?? ''
    delete res.data.sellerAccountId
    delete res.data.sellerAccountName
  }
  return res
}

export const getAccountBrands = async () => {
  const res = await api.get<{ count: number; data: (ImpactByBrand & { accountId?: string })[] }>(
    'brand-impacts',
  )
  if (res.data && res.data.data) {
    res.data.data = res.data.data.map((brand) => {
      brand.userId = brand.accountId ?? ''
      delete brand.accountId
      return brand
    })
  }
  return res
}

export const getAccountImpactsByBrands = async (
  sellerAccountId: string,
): Promise<AxiosResponse<ImpactByBrand>> => {
  const res = await api.get<ImpactByBrand & { accountId?: string }>(
    `brand-impacts/${sellerAccountId}`,
  )
  if (res.data) {
    res.data.userId = res.data.accountId ?? ''
    delete res.data.accountId
  }
  return res
}

export const getFeedItems = (skip: number, ids: string[]) => {
  return api.post<FeedItemsResponse>(`feed-items?skip=${skip}`, { ids })
}

export const initSaveProducts = async () => {
  return api.post('rutter/products')
}

export interface GetMerchantProductsResponse {
  count: number
  data: MerchantProduct[]
}

export const FETCH_PRODUCTS_QUERY_PARAMS_FILTER_OPERATORS = ['in'] as const
export type FetchProductsQueryParamsFilterOperator =
  (typeof FETCH_PRODUCTS_QUERY_PARAMS_FILTER_OPERATORS)[number]

export const FETCH_PRODUCTS_QUERY_PARAMS_FILTER_PROPERTIES = ['impact'] as const
export type FetchProductsQueryParamsFilterProperty =
  (typeof FETCH_PRODUCTS_QUERY_PARAMS_FILTER_PROPERTIES)[number]

export type FetchProductsQueryParamsFilterValue = OffsetType[]

export interface FetchProductsQueryFilter {
  property: FetchProductsQueryParamsFilterProperty
  operator: FetchProductsQueryParamsFilterOperator
  value: FetchProductsQueryParamsFilterValue
}

export interface MerchantProductSearchOptions {
  page: number
  limit: number
  search: string
  filters: FetchProductsQueryFilter[]
  sortBy: string
  sortDirection: 'asc' | 'desc'
}
export const getMerchantProducts = async (
  integrationId: string,
  automationId: string | undefined,
  options: Partial<MerchantProductSearchOptions>,
): Promise<AxiosResponse<GetMerchantProductsResponse>> => {
  const defaultOptions: MerchantProductSearchOptions = {
    page: 1,
    limit: 5,
    search: '',
    filters: [],
    sortBy: 'name',
    sortDirection: 'desc',
  }
  const filteredOptions: Partial<MerchantProductSearchOptions> = Object.fromEntries(
    Object.entries(options).filter(([, value]) => !!value),
  )
  const { page, limit, search, filters, sortBy, sortDirection } = {
    ...defaultOptions,
    ...filteredOptions,
  }

  return await api.get(
    `rutter/products?integrationId=${integrationId}&sortBy=${sortBy}&sortDirection=${sortDirection}&skip=${
      (page - 1) * limit
    }&limit=${limit}&filters=${encodeURIComponent(JSON.stringify(filters))}&search=${search}${
      automationId ? `&automationId=${automationId}` : ''
    }`,
  )
}

export const initiateFetchProducts = async (body: object) => {
  return api.post('rutter/initiate-fetch-products', body)
}

export const getSustainabilityGoals = async (): Promise<AxiosResponse<ResponseTarget[]>> => {
  const res = await api.get<(ResponseTarget & { accountId?: string })[]>('impacts/targets')
  if (res.data) {
    res.data = res.data.map((rt) => {
      rt.userId = rt.accountId ?? ''
      delete rt.accountId
      return rt
    })
  }
  return res
}

export const fetchDomains = async (): Promise<AxiosResponse<Domain[]>> => {
  return openApi.get('/v2/email/domains')
}

export interface FetchDefaultTemplatesResponse {
  templates: EmailTemplate[]
  variables: Record<string, string>
}

export const fetchDefaults = async (): Promise<AxiosResponse<FetchDefaultTemplatesResponse>> => {
  return openApi.get('/v2/email/templates-defaults')
}

export const addDomain = async (domain: string): Promise<AxiosResponse<Domain>> => {
  return openApi.post('/v2/email/domains', { domain })
}

export const verifyDomain = async (id: string): Promise<AxiosResponse<DomainVerification>> => {
  return openApi.post(`/v2/email/domains/${id}/verify`)
}

export const deleteDomain = async (id: string): Promise<AxiosResponse<Domain[]>> => {
  return openApi.delete(`/v2/email/domains/${id}`)
}

export const getEmailTemplates = async (): Promise<AxiosResponse<EmailTemplateWithStatus[]>> => {
  return openApi.get('/v2/email/templates')
}

export const addEmailTemplate = async (
  body: EmailTemplate,
): Promise<AxiosResponse<EmailTemplateWithStatus>> => {
  return openApi.post('/v2/email/templates', body)
}

export const updateEmailTemplate = async (
  id: string,
  body: EmailTemplate,
): Promise<AxiosResponse<EmailTemplateWithStatus>> => {
  return openApi.put(`/v2/email/templates/${id}`, body)
}

export const deleteEmailTemplate = async (
  id: string,
): Promise<AxiosResponse<EmailTemplateWithStatus>> => {
  return openApi.delete(`/v2/email/templates/${id}`)
}

export const disableEmailTemplate = async (
  id: string,
): Promise<AxiosResponse<EmailTemplateWithStatus>> => {
  return openApi.post(`/v1/email/templates/${id}/disable`)
}

export const testEmailTemplate = async (body: {
  customerEmail: string
  notificationTemplate: EmailTemplate
}) => {
  return openApi.post('/v1/email/templates/test', body)
}

export const saveSustainabilityGoal = async (
  body: SustainabilityGoal,
): Promise<AxiosResponse<ResponseTarget>> => {
  const res = await api.post<ResponseTarget & { accountId?: string }>('impacts/target', body)
  if (res.data) {
    res.data.userId = res.data.accountId ?? ''
    delete res.data.accountId
  }
  return res
}

export const updateSustainabilityGoal = async (
  body: SustainabilityGoal,
): Promise<AxiosResponse<ResponseTarget>> => {
  const res = await api.put<ResponseTarget & { accountId?: string }>(
    `impacts/targets/${body.targetId}`,
    body,
  )
  if (res.data) {
    res.data.userId = res.data.accountId ?? ''
    delete res.data.accountId
  }
  return res
}

export const deleteSustainabilityGoal = async (
  targetId: string,
): Promise<AxiosResponse<ResponseTarget>> => {
  const res = await api.delete<ResponseTarget & { accountId?: string }>(
    `impacts/targets/${targetId}`,
  )
  if (res.data) {
    res.data.userId = res.data.accountId ?? ''
    delete res.data.accountId
  }
  return res
}

export const getRelationsAndImpactActionsData = async () => {
  const { data } = await api.get<{
    relation: TotalImpact[]
    impactAction: TotalImpact[]
  }>('api/user/impacts')
  store.commit('setImpactActionImpact', data.impactAction)
  store.commit('setRelationImpact', data.relation)
}

export const CUSTOM_INTEGRATION_API_KEY_TYPES = [
  'ZAPIER_KEY',
  'INTEGRATELY_KEY',
  'TOKI_API_KEY',
] as const
export type CustomIntegrationApiKeyType = (typeof CUSTOM_INTEGRATION_API_KEY_TYPES)[number]
export const API_KEY_TYPES = [
  'WIDGET_API_KEY',
  'OPEN_API_KEY',
  ...CUSTOM_INTEGRATION_API_KEY_TYPES,
] as const
export type ApiKeyType = (typeof API_KEY_TYPES)[number]

export interface ApiKeyResponse {
  _id: string
  name: string
  subType: ApiKeyType
  visibleValue: string
  value?: string
  activationDate: string
}

export const generateApiKey = async (name: string, type: string) => {
  return api.post('/api/api-keys', {
    name: name,
    type: type,
  })
}

export const updateApiKey = async (name: string, id: string | undefined) => {
  const res = await api.put('/api/api-keys/' + id, { name: name })
  if (res.data) {
    res.data.userId = res.data.accountId
    delete res.data.accountId
  }
  return res
}

export const deleteApiKey = async (id: string | undefined) => {
  const res = await api.delete('/api/api-keys/' + id)
  if (res.data) {
    res.data.userId = res.data.accountId
    delete res.data.accountId
  }
  return res
}

export const getApiKeys = async (): Promise<AxiosResponse<ApiKeyResponse[]>> => {
  return api.get<ApiKeyResponse[]>('/api/user/api-keys')
}

export const setLastVisitedDate = () => api.post<void>('/api/user/last-visited-date')

export const getDashboardUrl = (type: DashboardType, startDate: string, endDate: string) => {
  return api.get<string>(
    `/analytics/dashboard-url/${type}?startDate=${startDate}&endDate=${endDate}`,
  )
}

export const CUSTOM_INTEGRATION_TRIGGER_TYPES = [
  'transaction',
  'subscription',
  'order',
  'subscriber',
  'review',
  'affiliate',
  'partner',
  'form completion',
  'other',
] as const
export type CustomIntegrationTriggerType = (typeof CUSTOM_INTEGRATION_TRIGGER_TYPES)[number]

export interface CustomIntegrationTrigger {
  _id: string
  name: string
  type: CustomIntegrationTriggerType
  createdAt: string
}

export interface CustomIntegrationResponse {
  _id: string
  userId: string
  source: string
  imageUrl: string
  createdAt: string
  triggers: CustomIntegrationTrigger[]
  type?: CustomIntegrationType
}

export const getCustomIntegrations = async (): Promise<
  AxiosResponse<CustomIntegrationResponse[]>
> => {
  const res = await api.get('api/user/custom-integrations')
  if (res.data) {
    res.data = res.data.map((ci) => {
      ci.userId = ci.accountId
      delete ci.accountId
      return ci
    })
  }
  return res
}

export const getCustomIntegration = async (
  customIntegrationId: string,
): Promise<AxiosResponse<CustomIntegrationResponse>> => {
  const res = await api.get('api/user/custom-integrations/' + customIntegrationId)
  if (res.data) {
    res.data.userId = res.data.accountId
    delete res.data.accountId
  }
  return res
}

export const createCustomIntegrationTrigger = async (
  integrationId: string,
  name: string,
  type: string,
): Promise<AxiosResponse<CustomIntegrationResponse>> => {
  const res = await api.post('api/custom-integrations/' + integrationId + '/triggers', {
    name: name,
    type: type,
  })
  if (res.data) {
    res.data.userId = res.data.accountId
    delete res.data.accountId
  }
  return res
}

export const updateCustomIntegrationTrigger = async (
  integrationId: string,
  triggerId: string,
  name: string,
  type: string,
): Promise<AxiosResponse<CustomIntegrationResponse>> => {
  const res = await api.put('api/custom-integrations/' + integrationId + '/triggers/' + triggerId, {
    name: name,
    type: type,
  })
  if (res.data) {
    res.data.userId = res.data.accountId
    delete res.data.accountId
  }
  return res
}

export const deleteCustomIntegrationTrigger = async (
  integrationId: string,
  triggerId: string,
): Promise<AxiosResponse<CustomIntegrationResponse>> => {
  const res = await api.delete(
    'api/custom-integrations/' + integrationId + '/triggers/' + triggerId,
  )
  if (res.data) {
    res.data.userId = res.data.accountId
    delete res.data.accountId
  }
  return res
}

export const createCustomIntegration = async (
  name: string,
  imageUrl: string,
  triggerName: string,
  triggerType: string,
  type: CustomIntegrationType,
): Promise<AxiosResponse<CustomIntegrationResponse[]>> => {
  const res = await api.post('api/custom-integrations/', {
    source: name,
    imageUrl: imageUrl,
    triggers: [
      {
        name: triggerName,
        type: triggerType,
      },
    ],
    type,
  })
  if (res.data) {
    res.data = res.data.map((ci) => {
      ci.userId = ci.accountId
      delete ci.accountId
      return ci
    })
  }
  return res
}

export const updateCustomIntegration = async (
  integrationId: string,
  name: string,
  imageUrl: string,
): Promise<AxiosResponse<CustomIntegrationResponse>> => {
  const res = await api.put('api/custom-integrations/' + integrationId, {
    source: name,
    imageUrl: imageUrl,
  })
  if (res.data) {
    res.data.userId = res.data.accountId
    delete res.data.accountId
  }
  return res
}

export const deleteCustomIntegration = async (
  integrationId: string,
): Promise<AxiosResponse<CustomIntegrationResponse>> => {
  const res = await api.delete('api/custom-integrations/' + integrationId)
  if (res.data) {
    res.data.userId = res.data.accountId
    delete res.data.accountId
  }
  return res
}

export const getCarouselItems = (): (CarouselProjectItem | CarouselQuoteItem)[] => {
  return [
    {
      quote:
        '“Environmental impact is something our customers and teams care deeply about. Greenspark helps us take measurable and meaningful action across our business.”',
      imageUrl: require('@/assets/images/signup/ElliotBlackler.png'),
      authorName: 'Elliot Blackler',
      authorTitle: 'Co-Founder, Evopure',
    },
    {
      quote:
        '“Easy to set up integrations, very helpful and friendly support, nicely designed choice of badges and a good range of options for impact to add. Useful ideas in newsletters and always seem to be striving to improve.”',
      imageUrl: require('@/assets/images/signup/SkyProctor.png'),
      authorName: 'Sky Proctor',
      authorTitle: 'Founder, Sky Siouki',
    },
    {
      quote:
        '“Greenspark provides an authentic solution which can easily be integrated into our existing systems, and quickly demonstrates to our customers that we are doing our bit for the environment.”',
      imageUrl: require('@/assets/images/signup/LaurenceConisbee.png'),
      authorName: 'Laurence Conisbee',
      authorTitle: 'Managing Director, Wharf Distillery',
    },
    {
      quote:
        '“We love Greenspark due to the easy to use dashboard which enables us to create a positive impact for every employee within seconds! We look forward to recommending Greenspark to every business."',
      imageUrl: require('@/assets/images/signup/JohannesBenz.png'),
      authorName: 'Johannes Benz',
      authorTitle: 'Managing Director, Raidboxes',
    },
  ]
}
export const getIntegrations = async (
  search?: string,
): Promise<AxiosResponse<IntegrationOptionResponse[]>> => {
  return search
    ? openApi.get(`v1/integration-options?search=${search}`)
    : openApi.get('v1/integration-options/')
}

export type Provider = {
  name: 'rutter' | 'hotglue' | CustomIntegrationType
  externalId: string
}

export interface Subscription {
  _id: string
  id: string
  userId: string
  externalId: string
  externalCustomerId: string
  items: SubscriptionItem[]
  startDate: string
  endDate: string
  createdAt: string
  updatedAt: string
  __v: number
}

export const API_USAGE_STATS_APIS = ['impacts', 'widgets'] as const
export type ApiUsageStatsApi = (typeof API_USAGE_STATS_APIS)[number]

export interface ApiUsageCountDTO {
  userId: string
  invoiced: boolean
  invoicedAt: string
  count: number
  maxCount: number
  startDate: Date
  endDate: Date
}

export const listApiUsageCounts = async (): Promise<AxiosResponse<ApiUsageItem[]>> => {
  return openApi.get('/v1/billing/api-usages')
}
export const ONBOARDING_STEP_UPDATERS = ['user', 'system'] as const
export type OnboardingStepUpdater = (typeof ONBOARDING_STEP_UPDATERS)[number]
export const ONBOARDING_STEPS = ['skipOnboarding'] as const
export type OnboardingStep = (typeof ONBOARDING_STEPS)[number]

export interface OnboardingStepDescriptor {
  value: boolean
  updatedAt?: string
  updatedBy?: OnboardingStepUpdater
  priority?: number
}

export interface OnboardingSteps {
  userId: string
  dismiss: OnboardingStepDescriptor
  skipOnboarding: OnboardingStepDescriptor
  plantTrees: OnboardingStepDescriptor
  addIntegration: OnboardingStepDescriptor
  addAutomation: OnboardingStepDescriptor
  addWidgets: OnboardingStepDescriptor
  customerEngagementPortal: OnboardingStepDescriptor
  offsetTeam: OnboardingStepDescriptor
  addReferral: OnboardingStepDescriptor
}

export const updateOnboardingSteps = async (
  step: OnboardingStep | ChecklistItemType,
): Promise<OnboardingSteps> => {
  const { data } = await api.put('/onboarding-steps', { step: step })
  if (data) {
    data.userId = data.accountId
    delete data.accountId
  }
  return data
}

export const dismissOnboardingSteps = async (): Promise<OnboardingSteps> => {
  const { data } = await api.delete('/onboarding-steps')
  if (data) {
    data.userId = data.accountId
    delete data.accountId
  }
  return data
}
export const PROMO_CODE_SLUGS = ['addTrees100', 'addTrees50', 'addPlastic200']
export type PromoCodeSlug = (typeof PROMO_CODE_SLUGS)[number]

export interface PromoCodeResponse {
  isValid: boolean
  slug?: PromoCodeSlug
}

export const validatePromoCode = async (promoCode: string): Promise<PromoCodeResponse> => {
  const { data } = await publicOpenApi.get(`/v1/promotions/validate/${promoCode}`)
  return data
}

export const usePromoCode = async (promoCode: string): Promise<AxiosResponse> => {
  return await openApi.post(`/v1/promotions/use/${promoCode}`)
}

export interface UserInviteDto {
  id: string
  email: string
  status: TeamMemberStatus
  createdAt: string
}

export interface UserDto {
  userId: string
  email: string
  firstName: string
  lastName: string
  createdAt: string
}

interface CreateUserInvite {
  email: string
}

interface CreateUserInvitesRequestBody {
  invites: CreateUserInvite[]
}

interface CreateUserRequestBody {
  firstName: string
  lastName: string
  userInviteId: string
}

interface UpdateUserRequestBody {
  firstName?: string
  lastName?: string
}

export const getUserInvites = async (): Promise<AxiosResponse<Array<UserInviteDto>>> => {
  return await openApi.get('/v1/user-invites')
}

export const getUsers = async (): Promise<AxiosResponse<Array<UserDto>>> => {
  return await openApi.get('/v1/users')
}

export const getUser = async (userId: string): Promise<AxiosResponse<UserDto>> => {
  return await openApi.get(`/v1/users/${userId}`)
}

export const updateUser = async (
  userId: string,
  firstName?: string,
  lastName?: string,
): Promise<AxiosResponse<UserDto>> => {
  const requestBody: UpdateUserRequestBody = {
    firstName,
    lastName,
  }
  return await openApi.put(`/v1/users/${userId}`, requestBody)
}

export const inviteUsers = async (
  emails: Array<string>,
): Promise<AxiosResponse<Array<UserInviteDto>>> => {
  const requestBody: CreateUserInvitesRequestBody = {
    invites: emails.map((email) => ({ email })),
  }
  return await openApi.post('/v1/user-invites', requestBody)
}

export const createUser = async (
  firstName: string,
  lastName: string,
  userInviteId: string,
): Promise<AxiosResponse<UserDto>> => {
  const requestBody: CreateUserRequestBody = {
    firstName,
    lastName,
    userInviteId,
  }
  return await openApi.post('/v1/user', requestBody)
}

export const deleteUserInvite = async (inviteId: string): Promise<AxiosResponse<void>> => {
  return await openApi.delete(`/v1/user-invites/${inviteId}`)
}

export interface InitiatePlaidTransactionConnectionResponse {
  linkToken: string
}

export const initiatePlaidTransactionConnection = async (): Promise<
  AxiosResponse<InitiatePlaidTransactionConnectionResponse>
> => {
  return await openApi.get('v1/transactions/initiate-connect')
}

export const connectTransactionAccount = async (body: {
  publicToken: string
}): Promise<AxiosResponse<GetTransactionBanksResponse>> => {
  return await openApi.post('/v1/transactions/connect', body)
}
export const getConnectEarthSessionId = async (): Promise<AxiosResponse<ConnectEarthSession>> => {
  return await openApi.get('/v1/transactions/session')
}

export interface BankAccount {
  id: string
  name: string
}

export interface GetTransactionBanksResponse {
  accounts: BankAccount[]
  name: string
  logo: string | null
  id: string
}

export const getBanks = async (): Promise<AxiosResponse<GetTransactionBanksResponse[]>> => {
  return await openApi.get('/v1/transactions/banks')
}

export interface GetProjectsResponse {
  projectId: string
  projectCategoryId: string
  name: string
  description: string
  type: OffsetType
  registryLink: string
  imageUrl: string
  countries: string[]
  vintage: string
  price: number
  availability: number
}

export const PROJECT_STATUSES = ['approved', 'unapproved', 'deprecated'] as const
export type ProjectStatus = (typeof PROJECT_STATUSES)[number]

export interface ProjectQueryParams {
  currency?: CurrencyCode
  plan?: PersonalSubscriptionType | TechnicalAndBusinessSubscriptionType
  statuses?: ProjectStatus
}

export const getAppProjects = async (
  query?: ProjectQueryParams,
): Promise<AxiosResponse<GetProjectsResponse[]>> => {
  const params = new URLSearchParams(query as { [key: string]: string })
  return openApi.get<GetProjectsResponse[]>(
    `/v1/projects${params.toString() ? `?${params.toString()}` : ''}`,
  )
}

export const getApprovedProjects = async (
  query?: ProjectQueryParams,
): Promise<AxiosResponse<GetProjectsResponse[]>> => {
  const params = new URLSearchParams(query as { [key: string]: string })
  return publicOpenApi.get<GetProjectsResponse[]>(
    `/v1/projects${params.toString() ? `?${params.toString()}` : ''}`,
  )
}

export interface CreateIntegrationRequestBody {
  platform: IntegrationPlatform
  externalId?: string
  publicToken?: string
}

export interface IntegrationResponseDto {
  id: string
  types: IntegrationType[]
  name: string
  platform: IntegrationPlatform
  status: IntegrationStatus
  automationIds: string[]
  createdAt: string
  writeDirection?: IIntegrationWriteDirection
}

export interface CreateAutomationRequestBody {
  name: string
  schedule: {
    startDate: string
    endDate: string
  }
  trigger: AutomationTrigger
  modifiers?: AutomationModifier[]
  offsets: AutomationOffset[]
  ids?: string[]
}

export interface UpdateAutomationRequestBody {
  name?: string
  schedule?: {
    startDate: string
    endDate: string | undefined
  }
  modifiers?: AutomationModifier[]
  offsets?: AutomationOffset[]
  ids?: string[]
  enabled?: boolean
}

export const fetchAllIntegrations = async (): Promise<AxiosResponse<IntegrationResponseDto[]>> => {
  return await openApi.get('/v1/integrations')
}

export const createIntegration = async (
  body: CreateIntegrationRequestBody,
): Promise<AxiosResponse<IntegrationResponseDto>> => {
  return await openApi.post('/v1/integrations', body)
}
export const deleteIntegration = async (integrationId: string): Promise<AxiosResponse<void>> => {
  return await api.delete(`/integrations/${integrationId}`)
}

export const enableIntegrationWriteDirection = async (
  integrationId: string,
): Promise<AxiosResponse<IntegrationResponseDto>> => {
  const response: AxiosResponse<IntegrationResponseDto> = await openApi.post(
    `/v1/integrations/${integrationId}/enable-write-direction`,
  )
  return response
}

export const disableIntegrationWriteDirection = async (
  integrationId: string,
): Promise<AxiosResponse<IntegrationResponseDto>> => {
  const response: AxiosResponse<IntegrationResponseDto> = await openApi.post(
    `/v1/integrations/${integrationId}/disable-write-direction`,
  )
  return response
}

export const testIntegrationWriteDirection = async (
  integrationId: string,
): Promise<AxiosResponse<IIntegrationWriteDirectionTestStatus>> => {
  const response: AxiosResponse<IIntegrationWriteDirectionTestStatus> = await openApi.post(
    `/v1/integrations/${integrationId}/test-write-direction`,
  )
  return response
}

export const syncIntegrationWriteDirection = async (
  integrationId: string,
): Promise<AxiosResponse<IntegrationResponseDto>> => {
  const response: AxiosResponse<IntegrationResponseDto> = await openApi.post(
    `/v1/integrations/${integrationId}/sync-write-direction`,
  )
  return response
}

export const createAutomation = async (
  integrationId: string,
  body: CreateAutomationRequestBody,
): Promise<AxiosResponse<Automation>> => {
  const response: AxiosResponse<Automation> = await openApi.post(
    `/v1/integrations/${integrationId}/automations`,
    body,
  )
  return response
}
export const updateAutomation = async (
  integrationId: string,
  automationId: string,
  body: UpdateAutomationRequestBody,
): Promise<AxiosResponse<Automation>> => {
  const response: AxiosResponse<Automation> = await openApi.put(
    `/v1/integrations/${integrationId}/automations/${automationId}`,
    body,
  )
  return response
}
export const deleteAutomation = async (
  integrationId: string,
  automationId: string,
): Promise<AxiosResponse<void>> => {
  return await openApi.delete(`/v1/integrations/${integrationId}/automations/${automationId}`)
}
export const fetchAutomationsByIntegrationId = async (
  integrationId: string,
): Promise<AxiosResponse<Automation[]>> => {
  const response: AxiosResponse<Automation[]> = await openApi.get(
    `/v1/integrations/${integrationId}/automations`,
  )
  return response
}

export interface FormAnswerDto {
  id: string
  answer: string
}

export interface UpdateFormAnswerDto extends Pick<FormAnswerDto, 'answer'> {
  id?: string
}

export interface FormQuestionDto {
  id: string
  question: string
  answers: FormAnswerDto[]
}

export interface CreateFormQuestionRequestBody {
  question: string
  answers: string[]
}

export interface UpdateFormQuestionRequestBody {
  question?: string
  answers?: UpdateFormAnswerDto[]
}

export const createFormQuestion = async (
  body: CreateFormQuestionRequestBody,
): Promise<AxiosResponse<FormQuestionDto>> => {
  return await openApi.post('/v1/form-questions', body)
}

export const fetchFormQuestion = async (
  formQuestionId: string,
): Promise<AxiosResponse<FormQuestionDto>> => {
  return await openApi.get(`/v1/form-questions/${formQuestionId}`)
}

export const fetchFormQuestionByAutomationId = async (
  automationId: string,
): Promise<AxiosResponse<FormQuestionDto>> => {
  return await openApi.get(`/v1/form-questions/automation/${automationId}`)
}

export const updateFormQuestion = async (
  formQuestionId: string,
  body: UpdateFormQuestionRequestBody,
): Promise<AxiosResponse<FormQuestionDto>> => {
  return await openApi.put(`/v1/form-questions/${formQuestionId}`, body)
}

export const TRANSACTION_PURCHASE_PROVIDERS = ['veritree', 'unfccc', 'plasticBank'] as const
export type TransactionPurchaseProviders = (typeof TRANSACTION_PURCHASE_PROVIDERS)[number]

export interface TransactionPurchase {
  id: string
  type: OffsetType
  amount: number
  projectId: string
  receiptUrl: string
  provider: TransactionPurchaseProviders
  periodStartDate: string
  periodEndDate: string
}

export const getPurchases = async (): Promise<AxiosResponse<TransactionPurchase[]>> => {
  return openApi.get<TransactionPurchase[]>('v1/impacts/purchases')
}
