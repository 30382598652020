<template>
  <div class="upgrade-wrapper">
    <CloseNavigation />
    <div class="upgrade-inner">
      <div class="upgrade-text-wrapper">
        <h2 class="upgrade-title">
          {{ getAccountTypeIsBusiness ? t('upgrade_title_business') : t('upgrade_title_personal') }}
        </h2>
        <p class="upgrade-text">
          {{ getAccountTypeIsBusiness ? t('upgrade_text_business') : t('upgrade_text_personal') }}
        </p>

        <div v-if="!hideYearlyToggle && getAccountTypeIsBusiness" class="input-wrapper">
          <label for="switch" class="label">{{ t('monthly') }}</label>
          <v-switch id="switch" v-model="isSubscriptionYearly" color="#3B755F" inset hide-details />
          <label class="label" for="switch"
            >{{ t('yearly') }} <span class="label-info">{{ t('save') }}</span></label
          >
        </div>
      </div>
      <upgrade-card-list
        :is-business="getAccountTypeIsBusiness"
        :is-subscription-yearly="isSubscriptionYearly"
        v-model:path-to-navigate="pathToNavigate"
        @turnOffYearlyToggle="turnOffYearlyToggle"
      />
      <template v-if="getAccountTypeIsBusiness">
        <div v-if="getAccountTypeIsBusiness" class="comparison-button-wrapper">
          <button class="animated-link" @click="scrollToTable">
            {{ t('compare') }}
            <v-icon class="arrow-icon"> mdi-arrow-down </v-icon>
          </button>
        </div>
        <free-plan-card v-if="showFreePlanCard" />

        <transition name="fade">
          <features-table id="table" class="features-table" />
        </transition>
      </template>
      <FrequentlyAskedQuestions :is-business="getAccountTypeIsBusiness" class="faq-accordion" />
    </div>
  </div>
</template>

<script lang="ts">
import CloseNavigation from '@/components/tools/CloseNavigation.vue'
import FrequentlyAskedQuestions from '@/components/checkout/FrequentlyAskedQuestions.vue'
import type { CurrencyCode } from '@/helpers/interfaces'
import UpgradeCardList from '@/components/checkout/UpgradeCardList.vue'
import FeaturesTable from '@/components/checkout/FeaturesTable.vue'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { SetUpSocialMediaTracking } from '@/helpers/mixins/setupSocialMediaTracking'
import FreePlanCard from '@/components/checkout/FreePlanCard.vue'
import type { SubscriptionItem } from '@/store/subscriptions'
import type { Account } from '@/store'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SelectPlanView',
  components: {
    FreePlanCard,
    FeaturesTable,
    UpgradeCardList,
    FrequentlyAskedQuestions,
    CloseNavigation,
  },
  mixins: [LoadCrispMixin, SetUpSocialMediaTracking, Utils],
  data() {
    return {
      dialog: true,
      isSubscriptionYearly: true,
      hideYearlyToggle: false,
    }
  },
  computed: {
    pathToNavigate(): string {
      if (this.$route.path === '/select-plan' && !this.authUserId) return '/e/signup'
      else return '/checkout'
    },
    showFreePlanCard(): boolean {
      return (
        this.$route.path === '/select-plan' ||
        this.$route.path === '/finish-subscription' ||
        this.$route.name === 'DirectMarketplaceSignup' ||
        this.getActiveSubscriptionItem?.product === 'freeBusiness'
      )
    },
    authUserId(): string {
      return this.$store.getters['getAuthUserId']
    },
    userCurrencyCode(): CurrencyCode {
      return this.$store.getters['getUserCurrencyCode']
    },
    getAccountTypeIsBusiness(): boolean {
      return this.$store.getters['getAccountTypeIsBusiness']
    },
    getActiveSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getActiveSubscriptionItem']
    },
  },
  async created() {
    await this.setProjectList()
    this.setUpFacebookTracking('ViewContent')
    try {
      const location = await this.getLocation()
      this.setAccount({
        currency: this.account?.userFromMarketplace
          ? 'dollar'
          : this.getCurrencyByLocation(location),
      })
    } catch (error) {
      this.setAccount({
        currency: this.account?.userFromMarketplace ? 'dollar' : this.getCurrencyByLocation(''),
      })
      console.error('geolocation', error)
    }
  },
  methods: {
    t(key: string) {
      return this.$t(`SelectPlanView.${key}`)
    },
    scrollToTable() {
      const table = document.getElementById('table')
      if (table) table.scrollIntoView({ behavior: 'smooth' })
    },
    turnOffYearlyToggle() {
      this.isSubscriptionYearly = true
      this.hideYearlyToggle = true
    },
    setProjectList(): Promise<void> {
      return this.$store.dispatch('setProjectList')
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

html {
  scroll-behavior: smooth;
}

.upgrade-inner {
  scroll-behavior: smooth;
}

.faq-accordion {
  margin: 0 auto;
}

.upgrade-text-wrapper {
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;

  .v-switch {
    min-width: 65px;
  }
}

.label {
  cursor: pointer;
  font-size: 18px;
}

.label:not(:last-of-type) {
  margin-right: 15px;
}

.label-info {
  color: var(--ui-green);
  font-weight: 700;
}

.upgrade-wrapper {
  width: 100%;
  height: 100%;
  z-index: 4;
  min-height: 100vh;
}

.upgrade-inner {
  width: 100%;
  background-image: url('../assets/backgrounds/signup-background.svg');
  background-size: cover;
  background-position: center;
  padding: 40px 10px;
  min-height: 100%;
}

.upgrade-title {
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 20px;
  max-width: 18ch;
}

.upgrade-text {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
  max-width: 45ch;
}

.comparison-button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.arrow-icon {
  color: inherit;
  font-size: 20px;
}

.animated-link {
  margin-bottom: 70px;
  font-weight: 600;
}

.demo-link-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-wide);
  margin: 0 auto 100px;
  padding: 40px;
  gap: 20px;
  width: fit-content;
}

.demo-link-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 10px;
  text-align: center;
}

.demo-link-description {
  font-size: 16px;
  margin-bottom: 0;
  max-width: 25ch;
  text-align: center;
}

.demo-link {
  background-color: var(--gray-light-20);
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  color: var(--font-color-primary);
  text-decoration: none;
  transition: all linear 0.2s;
}

.demo-link:hover,
.demo-link:focus {
  color: white;
  box-shadow: inset 200px 0 0 0 var(--ui-green);
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .upgrade-inner {
    padding: 40px 24px;
  }

  .demo-link-wrapper {
    flex-direction: row;
  }

  .demo-link-title {
    text-align: left;
  }

  .demo-link-description {
    text-align: left;
  }

  .label {
    font-size: 32px;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .upgrade-inner {
    padding: 40px;
  }

  .faq-accordion {
    max-width: 60%;
  }

  .upgrade-title {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 40px;
  }

  .features-table {
    margin: 0 auto 80px;
    max-width: 1220px;
  }

  .label {
    text-align: left;
    width: 100%;
  }

  .label:not(:last-of-type) {
    text-align: right;
  }
}
</style>
