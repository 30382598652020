<template>
  <div class="account-wrapper">
    <v-tabs v-model="tab" bg-color="transparent" color="green" center-active grow>
      <v-tab class="tabs" @click="() => selectTab('profile')">
        <div class="tab-header">
          {{ t('profile') }}
        </div>
      </v-tab>
      <v-tab v-if="isBusiness" class="tabs" @click="() => selectTab('users')">
        <div class="tab-header">
          {{ t('users') }}
        </div>
      </v-tab>
      <v-tab class="tabs" @click="() => selectTab('billing')">
        <div class="tab-header">
          {{ t('billing') }}
        </div>
      </v-tab>
      <v-tab v-if="isBusiness" class="tabs" @click="() => selectTab('apiKey')">
        <div class="tab-header">
          {{ t('api_key') }}
        </div>
      </v-tab>
    </v-tabs>

    <v-window :touch="false" v-model="tab" class="tabitem-wrapper">
      <v-window-item>
        <div class="tab-content">
          <UpdateProfile :png="png" :url="url" @uploadClick="openUploadModal()" />
        </div>
      </v-window-item>
      <v-window-item v-if="isBusiness">
        <div class="tab-content">
          <Users />
        </div>
      </v-window-item>
      <v-window-item>
        <div class="tab-content">
          <Billing :account="account" />
        </div>
      </v-window-item>
      <v-window-item v-if="isBusiness">
        <div class="tab-content">
          <ApiKey />
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import UpdateProfile from '@/components/account/UpdateProfile.vue'
import ApiKey from '@/components/account/ApiKey.vue'
import Billing from '@/components/account/Billing.vue'
import { updateAccount } from '@api/index'
import type { Account } from '@/store'
import Users from '@/components/account/Users.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AccountView',
  components: {
    ApiKey,
    UpdateProfile,
    Billing,
    Users,
  },
  data() {
    return {
      url: '',
      publicId: '',
      tab: 0,
      png: false,
    }
  },
  computed: {
    isBusiness(): boolean {
      return this.account.accountType === 'ecommerce' || this.account.accountType === 'business'
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
  async created() {
    this.setImage()
    this.setActiveTab()
    this.routeCheck()
  },
  methods: {
    t(key: string) {
      return this.$t(`AccountView.${key}`)
    },
    setImage() {
      if (this.account.imageURL) {
        this.url = this.account.imageURL
      } else {
        this.url = require('@/assets/live-page-logo.svg')
      }

      if (this.url.endsWith('.png')) {
        this.png = true
      } else {
        this.png = false
      }
    },
    openUploadModal() {
      // TODO: Add css & localisation, error handling
      // https://cloudinary.com/documentation/upload_widget#look_and_feel_customization
      // https://cloudinary.com/documentation/upload_widget#localization

      // All Parameters:
      // https://cloudinary.com/documentation/upload_widget_reference

      if (window.cloudinary) {
        window.cloudinary
          .createUploadWidget(
            {
              cloud_name: process.env.VUE_APP_CLOUDNAME,
              upload_preset: process.env.VUE_APP_UPLOAD_PRESET,
              folder: 'profilePics',
              sources: ['local', 'url', 'camera'],
              cropping: true,
              cropping_aspect_ratio: 1,
              croppingShowDimensions: true,
              showSkipCropButton: true,
              showPoweredBy: false,
              clientAllowedFormats: ['png', 'jpg', 'jpeg'],
            },
            (error, result) => {
              if (!error && result && result.event === 'success') {
                if (result.info.url.endsWith('.png')) {
                  this.png = true
                } else {
                  this.png = false
                }
                this.url = result.info.url
                this.publicId = result.info.public_id
                this.uploadImage()
                this.setAccount({ imageURL: this.url })
              }
            },
          )
          .open()
      }
    },
    async uploadImage() {
      await updateAccount({ userId: this.account.accountId, imageURL: this.url })
    },
    routeCheck() {
      if (window.location.search === '?billing') {
        this.tab = 1
      }
    },
    setActiveTab() {
      if (this.$route.query.tab) {
        if (this.$route.query.tab === 'profile') this.tab = 0
        if (this.$route.query.tab === 'users') this.tab = 1
        if (this.$route.query.tab === 'billing') this.tab = 2
        if (this.$route.query.tab === 'apiKey') this.tab = 3
      }
    },
    selectTab(tab: string) {
      // prevent navigation to the same tab
      if (this.$route.query.tab === tab) return
      this.$router.push({ path: '/account', query: { tab: tab } })
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.account-wrapper {
  overflow: hidden;
  background: var(--ui-white);
  border-radius: 5px;
  box-shadow: var(--box-shadow);
}

.tabs {
  background-color: var(--ui-white);
}

.tab-header {
  text-transform: capitalize;
}

.tab-content {
  padding-top: 35px;
  padding-bottom: 20px;
}

.tabitem-wrapper {
  padding: 15px;
  background-color: white;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .tabitem-wrapper {
    padding: 30px;
  }
}
</style>
