<template>
  <li class="impact-by-brands-list-item">
    <button class="impact-by-brands-button" @click="openPopup">
      <img
        height="80"
        width="80"
        :src="brand?.imageURL || require('@/assets/icons/greenspark-logo-vertical-green.svg')"
        :alt="brand?.companyName"
        class="company-logo"
        loading="lazy"
      />
      <p class="flavor-text">
        {{ t('flavor_text') }}
      </p>
      <p class="flavor-text brand-name">
        <b>{{ brand?.companyName }}</b>
      </p>

      <div class="impacts">
        <ImpactPill v-for="(impact, index) in brand?.impacts || []" :key="index" :impact="impact" />
      </div>
    </button>
  </li>
</template>

<script lang="ts">
import type { ImpactByBrand } from '@/helpers/interfaces'
import ImpactPill from '@/components/ImpactWallet/ImpactPill.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImpactsByBrandsItem',
  components: { ImpactPill },
  methods: {
    t(key: string, params?: { [key: string]: string }) {
      return this.$t(`ImpactsByBrandsItem.${key}`, params ?? {})
    },
    tc(key: string, amount: number) {
      return this.$t(`ImpactsByBrandsItem.${key}`, amount ?? 0)
    },
    openPopup() {
      this.$parent?.$parent?.$emit('open-popup', this.brand)
    },
  },
  props: {
    brand: { type: Object as () => ImpactByBrand },
  },
})
</script>

<style lang="scss" scoped>
.impact-by-brands-list-item {
  border-radius: var(--border-radius-big);
  width: 100%;
}

.impact-by-brands-button {
  align-items: center;
  background-color: var(--ui-beige);
  border-radius: var(--border-radius-big);
  color: var(--font-color-primary);
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  text-decoration: none;
  width: 100%;
  height: 100%;
}

.impact-by-brands-button:hover {
  box-shadow: var(--box-shadow-light);
}

.company-logo {
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: contain;
}

.flavor-text {
  font-size: 18px;
  line-height: 24px;
  padding: 0 15px;
  margin-bottom: 5px;
}

.flavor-text.brand-name {
  margin-bottom: 10px;
  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.impact-icon {
  color: inherit;
  flex-shrink: 0;
  height: 24px;
  margin-right: 5px;
  width: 24px;
}

.impacts {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 79px;
  flex-wrap: wrap;
  gap: 10px;
}

.impact-by-brands-button::after {
  border-right: 2px solid var(--ui-green);
  border-top: 2px solid var(--ui-green);
  bottom: 50%;
  content: '';
  display: block;
  flex-shrink: 0;
  height: 10px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%) rotate(40deg);
  width: 10px;
}

.impact-by-brands-button:hover::after {
  opacity: 0;
  transform: translate(calc(-50% + 10px), -50%) rotate(40deg);
  transition: all linear 0.2s;
}

.impact-by-brands-button::before {
  border-right: 2px solid var(--ui-green);
  border-top: 2px solid var(--ui-green);
  bottom: 50%;
  content: '';
  display: block;
  height: 10px;
  opacity: 0;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(-50%, -50%) rotate(40deg);
  width: 10px;
}

.impact-by-brands-button:hover::before {
  opacity: 1;
  transform: translate(calc(-50% + 10px), -50%) rotate(40deg);
  transition: all linear 0.2s;
}
</style>
