<template>
  <div class="outer-wrapper">
    <div class="transfer-header">
      {{ t('header') }}
    </div>
    <div class="description-text">
      {{ descriptionText }}
    </div>
    <div v-for="(receipt, a) in receipts" :key="a" class="impact-wrapper">
      <div class="type">
        {{ receipt.type }}
      </div>
      <div class="download" @click="openUrl(receipt.url)">
        Download
        <img class="download-icon" :src="require('@/assets/icons/download.svg')" alt="download" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Account } from '@/store'
import type { LivePageData, OffsetType } from '@/helpers/interfaces'
import type { TranslateResult } from 'vue-i18n'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TransferredImpact',
  components: {},
  data() {
    const receipts: Array<{ url: string; type: OffsetType }> = []

    return {
      receipts,
    }
  },
  computed: {
    transferImpactReceipts(): Array<{ url: string; type: OffsetType }> {
      const transferredImpact = this.live
        ? this.livePageData?.transferredImpact
        : this.account.transferredImpacts
      const initial: Array<{ url: string; type: OffsetType }> = []
      return (
        transferredImpact?.reduce((acc, { type, urls }) => {
          return urls.length ? [...acc, ...urls.map((url) => ({ url, type }))] : acc
        }, initial) || []
      )
    },
    descriptionText(): TranslateResult {
      if (this.live) {
        return this.livePageData?.name + ' ' + this.t('text_live')
      }

      return this.$t('text')
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
  created() {
    this.setReceipts()
  },
  methods: {
    t(key: string) {
      return this.$t(`TransferredImpact.${key}`)
    },
    setReceipts() {
      this.receipts = this.transferImpactReceipts
      for (let i = 0; i < this.receipts.length; i++) {
        this.receipts[i].type = (this.receipts[i].type.charAt(0).toUpperCase() +
          this.receipts[i].type.slice(1)) as OffsetType
      }
    },
    openUrl(url) {
      window.open(url, '_blank')
    },
  },
  props: {
    livePageData: {
      type: Object as PropType<LivePageData>,
    },
    live: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
.outer-wrapper {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 20px 80px;
  box-shadow: 1px -1px 10px -1px rgb(0 0 0 / 10%);
  -webkit-box-shadow: 1px -1px 10px -1px rgb(0 0 0 / 10%);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  margin-top: 45px;
  margin-bottom: 25px;
  background-image: url('../../assets/gifs/comparisons/background_transferred_impact.svg');
  background-size: cover;
}

.transfer-header {
  margin-bottom: 10px;
  color: #212121;
  font-size: 22px;
  font-weight: 600;
}

.description-text {
  color: #212121;
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 25px;
}

.impact-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 18px;
  padding-bottom: 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
}

.type {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
}

.download {
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}

.download-icon {
  filter: invert(7%) sepia(5%) saturate(6266%) hue-rotate(166deg) brightness(91%) contrast(83%);
  margin-left: 5px;
  max-width: 22px;
}
</style>
