<template>
  <div class="menu-bar">
    <div
      v-for="(item, index) in menuItems"
      :key="index"
      :class="['menu-item', { 'menu-item-active': item.active }]"
      @click="$emit('menuClick', item.value, index)"
    >
      <p class="menu-item-label">
        {{ item.label }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import type { MenuItem } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomMenu',
  emits: ['menuClick'],
  methods: {
    t(key: string) {
      return this.$t(`Menu.${key}`)
    },
  },
  props: {
    menuItems: {
      type: Array as PropType<MenuItem[]>,
    },
  },
})
</script>

<style lang="scss" scoped>
.menu-bar {
  display: flex;
}

.menu-item {
  align-items: center;
  display: flex;
  cursor: pointer;
  background-color: var(--ui-green-light);
  padding: 12px 24px;
  border-radius: 99px;
  transition: all linear 0.2s;
}

.menu-item:hover,
.menu-item:focus {
  box-shadow: inset 200px 0 0 0 var(--ui-green);
}

.menu-item:not(:first-child) {
  margin-left: 10px;
}

.menu-item.menu-item-active {
  background-color: #3b755f;
}

.menu-item-label {
  font-size: 18px;
  font-weight: 700;
  color: #f2ebdb;
  margin: 0;
  text-align: center;
  white-space: nowrap;
}
</style>
