export const de = {
  LivePageView: {
    impact: 'Impact',
    our_impact: 'Unser Impact',
    my_impact: 'Mein Impact',
    projects: 'Unterstützte Projekte',
    public_ledger: 'Zahlungsregister',
    back: 'Zurück zum Dashboard',
    logout: 'Abmelden',
    update: 'Seite aktualisieren',
    home: 'Home',
    about: 'Über uns',
    projects_home: 'Projekte',
  },

  TopStatsPublic: {
    earth_positive: 'Monate Klima-positiv',
    co2: 'Tonnen CO2',
    plastic_bottles: 'Plastikflaschen',
    trees: 'Bäume',
    header: 'Unser Impact',
    tooltipCarbonText: 'Tonnen CO2 stammen aus Partnerschaften.',
    tooltipPlasticText: 'Plastikflaschen sind aus Partnerschaften entstanden.',
    tooltipTreesText: 'Bäume stammen aus Partnerschaften.',
  },

  Banner: {
    description:
      'Wir sind bei Greenspark dabei, um sicher zu gehen, dass {accountName} eine positive Auswirkung auf unser Klima hat. Hier kannst du unseren Impact verfolgen!',
    description_personal:
      'Ich bin bei Greenspark dabei um eine positive auswirkung auf unser Klima zu haben. Hier kannst du meinen Impact verfolgen!',
    our_website: 'Unsere webseite',
  },

  ComparisonCardPublic: {
    header_co2: 'Unsere CO2-Kompensationen entsprechen:',
    private_header_co2: 'Deine CO2-Kompensationen entsprechen:',
    header_plastic: 'Unser eingesammeltes Plastik entspricht:',
    private_header_plastic: 'Dein eingesammeltes Plastik entspricht:',
    header_trees: 'Unsere gepflanzten Bäume ensprechen:',
    private_header_trees: 'Deine gepflanzten Bäume ensprechen:',
    flights: 'Hin- und Rückflüge von London nach Los Angeles',
    flights_tooltip:
      'Im Durchschnitt verursacht eine Hin- und Rückfahrt 932,7 kg CO2 Emissionen. Diese Berechnung variiert je nach Fluggesellschaft.',
    distance: 'Ausgeglichene km mit einem durchschnittlichen Auto.',
    distance_tooltip:
      'Die durchschnittliche Person fährt 21,687 km pro Jahr und stößt dabei 5.43 Tonnen CO2 aus.',
    plant_based: 'Jahre an CO2 emissionen eingespart durch ein pflanzenbasiertes Leben',
    plant_based_tooltip:
      'Pflanzenbasiert zu leben spart etwa 0,8 Tonnen CO2 pro Jahr, das ist viermal mehr als durch umfassendes Recycling eingespart wird.',
    straws: 'Plastikstrohhalme von der Mülldeponie entfernt',
    straws_tooltip: 'Ein durchschnittlicher Strohhalm wiegt 0,5 g.',
    coffee_cups: 'Einweg-Kaffeetassen vor der Mülldeponie gerettet',
    coffee_cups_tooltip: 'Der durchschnittliche Plastikanteil eines Kaffeebechers liegt bei 4g.',
    bathtubs: 'Badewannen gefüllt mit Plastikflaschen aus dem meer entfernt',
    bathtubs_tooltip:
      'Die größte Victoria Plumb-Badewanne hat ein Kapazität von 260l, daher würden ungefähr 520 x 500-ml-Flaschen benötigt, um eine zu füllen.',
    football_pitches: 'Fußballplatz mit Bäumen bepflanzt | Fußballplätze mit Bäumen bepflanzt',
    football_pitches_tooltip:
      'Der durchschnittliche Fußballplatz ist 0,71 Hektar groß und unsere Bäume werden mit einer Dichte von 2.500 Bäumen/Hektar gepflanzt.',
    area_planted: 'Bepflanzte Waldfläche',
    area_planted_tooltip: 'Unsere Bäume werden mit einer Dichte von 2.500 Bäumen/Hektar gepflanzt.',
    carbon_sequestered: 'Potentielle CO2-absetzung pro Jahr',
    carbon_sequestered_tooltip:
      'Im Durchschnitt bindet ein Hektar ausgewachsener Bäume 23 Tonnen CO2 im Jahr.',
    more_information: 'Mehr Informationen',
    hectar: 'Hektar',
    tonnes: 'Tonnen',
    thousand: 'tausend',
    million: 'Million',
    billion: 'Milliarde',
  },

  TopStatCard: {
    transferredText:
      ' wurden nicht mit Greenspark gepflanzt. Überprüfe den öffentlichen Zahlungsregister, um alle Rechnungen zu sehen.',
  },

  ProjectCard: {
    learn: 'Weiterlesen',
  },

  LivePublicLedger: {
    header: 'Zahlungsregister',
    text: 'Zertifizierungen und Transparenz sind wichtig. Deshalb, posten wir jedes Quartal alle Zahlungsnachweise, zu denen die Finanzierung von {name} beigetragen hat.',
    carbon: 'CO2',
    plastic: 'Plastik',
    trees: 'Bäume',
  },

  TransferredImpact: {
    header: 'Übertragener Impact',
    text: 'Hier kannst du alle Rechnungen unseres Impacts sehen den wir gemacht haben bevor wir bei Greenspark dabei waren. Die Summen wurden Ihrem Dashboard hinzugefügt und diese alle Rechnungen werden auf Ihrem öffentlichen Profil sichtbar sein. Somit ist alles transparent!',
    text_live:
      'hat Impact übertragen der gemacht wurde bevor sie bei Greenspark dabei waren. Siehe hier alle verifizierten Bestätigungsdokumente.',
  },

  ProjectReceipt: {
    download: 'Herunterladen',
  },

  Footer: {
    description: 'Begleite uns auf userer Reise!',
    join: 'Konto erstellen',
  },
}

export default de
