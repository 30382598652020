<template>
  <section>
    <div v-if="isDashboardAllowed" :class="['input-wrapper', { advanced: advancedAnalytics }]">
      <div v-if="!advancedAnalytics" class="call-to-upgrade">
        <p class="call-to-upgrade__text">
          {{ t('upgrade_text') }}
        </p>
        <router-link to="/upgrade-plan" class="call-to-upgrade__button">
          {{ t('upgrade') }}
        </router-link>
      </div>
      <v-date-input
        v-if="!!subscription"
        v-model="dateRange"
        :label="t('dates')"
        :placeholder="t('dates')"
        width="100%"
        max-width="320"
        multiple="range"
        :allowed-dates="allowedDates"
        clearable
        @click:clear="clearDateRange()"
        elevation="12"
        color="#3B755F"
        variant="outlined"
        @update:model-value="getUrl"
        prepend-inner-icon="mdi-calendar-range-outline"
      >
      </v-date-input>
    </div>
    <iframe
      v-if="iframeUrl"
      id="iframe"
      ref="iframe"
      :src="iframeUrl"
      :class="['iframe', $route.name, { advanced: advancedAnalytics }]"
      frameborder="0"
      allowtransparency
    />
    <div v-if="!isDashboardAllowed" class="call-to-upgrade-card">
      <img
        height="60"
        width="60"
        :src="require('@/assets/icons/leaf-icon.svg')"
        alt="leaf"
        class="call-to-upgrade-card__image"
      />
      <h3 class="call-to-upgrade-card__title">
        {{ t('call_to_upgrade_card_title') }}
      </h3>
      <p class="call-to-upgrade-card__text" v-html="t('call_to_upgrade_card_text')" />
      <router-link to="/upgrade-plan" class="call-to-upgrade-card__button animated-link">
        {{ t('upgrade') }}
      </router-link>
    </div>
  </section>
</template>

<script lang="ts">
import { getDashboardUrl } from '@api/index'
import type { DashboardType } from '@/helpers/interfaces'
import { formatDateToLocal, formatDateAsYYYYMMDD } from '@/helpers/parsers'
import type { SubscriptionItem } from '@/store/subscriptions'
import type { Account } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AnalyticsDashboard',
  data() {
    return {
      iframeUrl: '',
      menu: false,
    }
  },
  computed: {
    dateRange: {
      get(): Date[] {
        return this.analyticsDateRange
      },
      set(value: Date[]) {
        this.setAnalyticsDateRange(value || [])
      },
    },
    isDashboardAllowed(): boolean {
      return (this.$route.name as string) === 'livePage' ? this.advancedAnalytics : true
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    advancedAnalytics(): boolean {
      return this.$store.getters['getAdvancedAnalyticsFeatureSetting']
    },
    subscription(): SubscriptionItem {
      return this.$store.getters['getActivePaidSubscriptionItem']
    },
    analyticsDateRange(): Date[] {
      return this.$store.getters['getAnalyticsDateRange']
    },
  },
  async created() {
    if (this.subscription && this.isDashboardAllowed) {
      this.setInitialDateRange()

      this.iframeUrl = await this.getIframeUrl()
    }
  },
  methods: {
    setInitialDateRange() {
      const today = new Date()
      const endDate = formatDateToLocal(today)
      const signup = new Date(this.account.signupDate)

      const thirtyDaysAgo = new Date()
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
      const startDate = signup > thirtyDaysAgo ? signup : thirtyDaysAgo

      const formattedStartDate = new Date(formatDateToLocal(startDate))
      const formattedEndDate = new Date(endDate)

      const dateRange: Date[] = []
      const currentDate = formattedStartDate

      while (currentDate <= formattedEndDate) {
        dateRange.push(new Date(currentDate))
        currentDate.setDate(currentDate.getDate() + 1)
      }

      this.setAnalyticsDateRange(dateRange)
    },
    t(key: string) {
      return this.$t(`AnalyticsDashboard.${key}`)
    },
    clearDateRange() {
      this.dateRange = []
      this.$nextTick(() => {
        this.getUrl()
      })
    },
    allowedDates(val) {
      const today = formatDateAsYYYYMMDD(new Date())
      const signupDate = new Date(this.account.signupDate)
      const cutoffDate = new Date('2022-02-01')
      const currentDate = formatDateAsYYYYMMDD(new Date(val))

      const limit = formatDateAsYYYYMMDD(signupDate >= cutoffDate ? signupDate : cutoffDate)
      return currentDate >= limit && currentDate <= today
    },
    async getIframeUrl() {
      const today = new Date().toISOString()
      const cutoffDate = '2022-02-01'
      const startDate = this.dateRange[0] ? formatDateToLocal(this.dateRange[0]) : cutoffDate
      const endDate = this.dateRange[this.dateRange.length - 1]
        ? formatDateToLocal(this.dateRange[this.dateRange.length - 1])
        : today

      const { data: url } = await getDashboardUrl(
        this.$route.name as DashboardType,
        startDate,
        endDate,
      )

      return url
    },
    async getUrl() {
      this.iframeUrl = await this.getIframeUrl()
    },
    setAnalyticsDateRange(range) {
      return this.$store.commit('setAnalyticsDateRange', range)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.iframe-container {
  width: 100%;
}

.iframe {
  width: 100%;
}

.iframe.automations {
  height: 800px;
}

.iframe.automations.advanced {
  height: 3725px;
}

.iframe.general {
  height: 1985px;
}

.iframe.livePage {
  height: 2365px;
}

.iframe.subscriptionAndTailoredImpact {
  height: 2625px;
}

.iframe.customerEngagement {
  height: 3650px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0 8px;
  margin-bottom: 20px;
  flex-direction: column;
}

.input-wrapper.advanced {
  justify-content: flex-end;
}

.call-to-upgrade {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.call-to-upgrade__text {
  margin-bottom: 0;
  text-decoration: underline;
}

.call-to-upgrade__button {
  margin-left: 5px;
  padding: 2px 5px;
  border-radius: 8px;
  background-color: var(--ui-green);
  color: beige;
  font-weight: 600;
  text-decoration: none;
}

.call-to-upgrade__button:hover {
  opacity: 0.8;
}

.call-to-upgrade-card {
  align-items: center;
  background: url('../../assets/backgrounds/customer-engagement-alert.svg') var(--ui-beige)
    no-repeat center/cover;
  border-radius: var(--border-radius-small);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.call-to-upgrade-card__image {
  margin-bottom: 10px;
}

.call-to-upgrade-card__title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
}

.call-to-upgrade-card__text {
  font-size: 18px;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 40px;
  text-align: center;
  max-width: 415px;
}

.call-to-upgrade-card__text:deep(span) {
  padding: 0 6px;
  border-radius: 8px;
  background-color: var(--ui-green);
  color: var(--ui-beige);
}

.call-to-upgrade-card__button {
  text-transform: uppercase;
  font-weight: 600;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .call-to-upgrade-card {
    padding: 40px 40px 30px;
  }

  .input-wrapper {
    flex-direction: row;
  }
}

@media only screen and (min-width: 900px) and (max-width: 960px) {
  .iframe.livePage {
    height: 1600px;
  }
}

@media only screen and (min-width: 1165px) {
  .iframe.livePage {
    height: 1565px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .iframe.automations {
    height: 800px;
  }

  .iframe.automations.advanced {
    height: 3725px;
  }

  .iframe.subscriptionAndTailoredImpact {
    height: 1650px;
  }

  .iframe.customerEngagement {
    height: 3905px;
  }

  .iframe.general {
    height: 1565px;
  }

  .iframe.livePage {
    height: 1565px;
  }
}
</style>
