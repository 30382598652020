import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c68c1b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carbon-footprint-introduction" }
const _hoisted_2 = { class: "carbon-footprint-introduction__title" }
const _hoisted_3 = { class: "carbon-footprint-introduction__description" }
const _hoisted_4 = { class: "carbon-footprint-introduction__list" }
const _hoisted_5 = { class: "carbon-footprint-introduction__list-item" }
const _hoisted_6 = { class: "carbon-footprint-introduction__list-item-text" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "carbon-footprint-introduction__list-item" }
const _hoisted_9 = { class: "carbon-footprint-introduction__list-item-text" }
const _hoisted_10 = { class: "carbon-footprint-introduction__list-item" }
const _hoisted_11 = { class: "carbon-footprint-introduction__list-item-text" }
const _hoisted_12 = { class: "carbon-footprint-introduction__list-item" }
const _hoisted_13 = { class: "carbon-footprint-introduction__list-item-text" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "carbon-footprint-introduction__title" }
const _hoisted_16 = { class: "carbon-footprint-introduction__card-wrapper" }
const _hoisted_17 = { class: "carbon-footprint-introduction__card" }
const _hoisted_18 = { class: "carbon-footprint-introduction__card-title" }
const _hoisted_19 = { class: "carbon-footprint-introduction__card-desc" }
const _hoisted_20 = { class: "carbon-footprint-introduction__card" }
const _hoisted_21 = { class: "carbon-footprint-introduction__card-title" }
const _hoisted_22 = { class: "carbon-footprint-introduction__card-desc" }
const _hoisted_23 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MapPinRound = _resolveComponent("MapPinRound")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_LineChartDecline = _resolveComponent("LineChartDecline")!
  const _component_TargetRectangular = _resolveComponent("TargetRectangular")!
  const _component_BarChart = _resolveComponent("BarChart")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.t('title')), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.t('description')), 1),
    _createElementVNode("ul", _hoisted_4, [
      _createElementVNode("li", _hoisted_5, [
        _createVNode(_component_MapPinRound, { class: "carbon-footprint-introduction__list-item-icon" }),
        _createElementVNode("p", _hoisted_6, [
          _createTextVNode(_toDisplayString(_ctx.t('reason_1')) + " ", 1),
          _createVNode(_component_v_tooltip, {
            "open-on-click": _ctx.isTouch,
            "content-class": "carbon-footprint-introduction__tooltip-content",
            attach: "#carbon-footprint-tooltip-1",
            "max-width": "480px",
            absolute: "",
            "close-delay": "300"
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_icon, _mergeProps({
                id: "carbon-footprint-tooltip-1",
                class: "carbon-footprint-introduction__list-item-tooltip-icon"
              }, props), {
                default: _withCtx(() => [
                  _createTextVNode(" mdi-information-outline ")
                ]),
                _: 2
              }, 1040)
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "carbon-footprint-introduction__list-item-tooltip-content",
                innerHTML: _ctx.t('reason_1_tooltip')
              }, null, 8, _hoisted_7)
            ]),
            _: 1
          }, 8, ["open-on-click"])
        ])
      ]),
      _createElementVNode("li", _hoisted_8, [
        _createVNode(_component_LineChartDecline, { class: "carbon-footprint-introduction__list-item-icon" }),
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.t('reason_2')), 1)
      ]),
      _createElementVNode("li", _hoisted_10, [
        _createVNode(_component_TargetRectangular, { class: "carbon-footprint-introduction__list-item-icon" }),
        _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.t('reason_3')), 1)
      ]),
      _createElementVNode("li", _hoisted_12, [
        _createVNode(_component_BarChart, { class: "carbon-footprint-introduction__list-item-icon" }),
        _createElementVNode("p", _hoisted_13, [
          _createTextVNode(_toDisplayString(_ctx.t('reason_4')) + " ", 1),
          _createVNode(_component_v_tooltip, {
            "open-on-click": _ctx.isTouch,
            "content-class": "carbon-footprint-introduction__tooltip-content",
            attach: "#carbon-footprint-tooltip-2",
            "max-width": "480px",
            absolute: "",
            "close-delay": "300"
          }, {
            activator: _withCtx(({ props }) => [
              _createVNode(_component_v_icon, _mergeProps({
                id: "carbon-footprint-tooltip-2",
                class: "carbon-footprint-introduction__list-item-tooltip-icon"
              }, props), {
                default: _withCtx(() => [
                  _createTextVNode(" mdi-information-outline ")
                ]),
                _: 2
              }, 1040)
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.t('reason_4_tooltip')
              }, null, 8, _hoisted_14)
            ]),
            _: 1
          }, 8, ["open-on-click"])
        ])
      ])
    ]),
    _createElementVNode("h2", _hoisted_15, _toDisplayString(_ctx.t('how_it_works')), 1),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.t('bank_accounts_title')), 1),
        _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.t('bank_accounts_description')), 1)
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.t('fuel_heat_electricity_title')), 1),
        _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.t('fuel_heat_electricity_description')), 1)
      ])
    ]),
    _createElementVNode("div", {
      class: "carbon-footprint-introduction__security-text",
      innerHTML: _ctx.t('security_text')
    }, null, 8, _hoisted_23),
    _createVNode(_component_gs_button, {
      size: "large",
      "full-width": "",
      onClick: _ctx.connectBankAccount
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('get_started')), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}