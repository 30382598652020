<template>
  <div>
    <LoadingOpaque v-if="loading" />

    <div v-if="!loading" class="header-bar">
      <a href="https://www.getgreenspark.com/">
        <img class="logo" :src="require('@/assets/greenspark-logo.svg')" alt="greenspark-logo" />
      </a>

      <BurgerMenu :items="items" />
    </div>

    <div v-if="!loading" class="page-content-outer-wrapper">
      <div class="page-content-wrapper">
        <OverallBanner />
        <div v-if="!isMobile" class="menu-bar">
          <div :class="menuItem === 0 ? 'menu-item-active' : 'menu-item'" @click="menuItem = 0">
            <div class="menu-item-label">
              {{ t('our_impact') }}
            </div>
          </div>
          <div :class="menuItem === 1 ? 'menu-item-active' : 'menu-item'" @click="menuItem = 1">
            <div class="menu-item-label">
              {{ t('projects') }}
            </div>
          </div>
          <div :class="menuItem === 2 ? 'menu-item-active' : 'menu-item'" @click="menuItem = 2">
            <div class="menu-item-label">
              {{ t('public_ledger') }}
            </div>
          </div>
        </div>

        <div class="tabs-outer-wrapper">
          <div class="tabs-wrapper">
            <OurImpact v-if="menuItem === 0" :total-impacts-array="totalImpactsArray" />

            <SupportedProjectsAggregate v-if="menuItem === 1" />

            <PublicLedger v-if="menuItem === 2" />
          </div>
        </div>
        <PublicImpactMobileMenu
          v-if="isMobile"
          :menu-item="menuItem"
          @homeClick="menuItem = 0"
          @projectsClick="menuItem = 1"
          @ledgerClick="menuItem = 2"
        />
      </div>
    </div>

    <CustomFooterV2 v-if="!loading" :text="t('join')" />
  </div>
</template>

<script lang="ts">
import BurgerMenu from '@/components/navigation/BurgerMenu.vue'
import OurImpact from '@/components/PublicImpactDashboard/OurImpact.vue'
import SupportedProjectsAggregate from '@/components/PublicImpactDashboard/SupportedProjectsAggregate.vue'
import PublicImpactMobileMenu from '@/components/PublicImpactDashboard/PublicImpactMobileMenu.vue'
import CustomFooterV2 from '@/components/navigation/CustomFooterV2.vue'
import OverallBanner from '@/components/PublicImpactDashboard/OverallBanner.vue'
import { getAllImpactData } from '@api/index'
import LoadingOpaque from '@/components/tools/LoadingOpaque.vue'
import PublicLedger from '@/components/common/PublicLedger.vue'
import type { OffsetType, TotalImpact } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AllMemberImpactView',
  mixins: [Utils],
  components: {
    PublicLedger,
    BurgerMenu,
    OurImpact,
    SupportedProjectsAggregate,
    PublicImpactMobileMenu,
    CustomFooterV2,
    OverallBanner,
    LoadingOpaque,
  },
  data() {
    return {
      menuItem: 0,
      windowWidth: 0,
      loading: false,
      totalImpactsArray: [],
      items: [],
    } as {
      menuItem: number
      windowWidth: number
      loading: boolean
      totalImpactsArray: TotalImpact[]
      items: { text: string; url: string }[]
    }
  },
  async created() {
    window.addEventListener('resize', this.reportWindowSize)
    await this.setAllImpactData()
    this.loading = false
    this.reportWindowSize()
    this.setItems()
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize)
  },
  methods: {
    t(key: string) {
      return this.$t(`AllMemberImpactView.${key}`)
    },
    async setAllImpactData() {
      this.loading = true
      const allImpactData = await getAllImpactData()
      this.totalImpactsArray = Object.entries(allImpactData).map(([type, amount]) => ({
        type: type as OffsetType,
        amount,
      }))
    },
    reportWindowSize() {
      this.windowWidth = window.innerWidth
    },
    setItems() {
      this.items = [
        { text: `${this.t('home')}`, url: 'https://www.getgreenspark.com' },
        { text: `${this.t('about')}`, url: 'https://www.getgreenspark.com/about-us' },
        { text: `${this.t('pricing')}`, url: 'https://www.getgreenspark.com/business/pricing' },
        { text: `${this.t('login')}`, url: window.location.origin },
      ]
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.header-bar {
  height: 80px;
  background: #ffffff;
  padding: 8px 40px;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  max-width: 180px;
}

.menu-bar {
  margin-top: -32px;
  padding: 8px 30px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-item {
  display: flex;
  cursor: pointer;
  margin: 0 10px;
  background-color: #afc6bd;
  padding: 12px 24px;
  border-radius: 99px;
}

.menu-item:hover {
  background-color: #3b755f;
}

.menu-item-active {
  display: flex;
  cursor: pointer;
  margin: 0 10px;
  background-color: #3b755f;
  padding: 12px 24px;
  border-radius: 99px;
}

.menu-item-label {
  font-size: 18px;
  font-weight: 700;
  color: #f2ebdb;
  text-align: center;
}

.tabs-outer-wrapper {
  display: flex;
  justify-content: center;
}

.tabs-wrapper {
  margin-top: 40px;
  width: 100%;
  padding-bottom: 100px;
}

.page-content-outer-wrapper {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-content-wrapper {
  max-width: 900px;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .page-content-outer-wrapper {
    margin-top: 0;
  }

  .page-content-wrapper {
    width: 100%;
  }

  .tabs-wrapper {
    margin-top: 10px;
    padding-bottom: 0;
  }

  .header-bar {
    padding: 8px 20px;
  }
}
</style>
