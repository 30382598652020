<template>
  <svg width="66" height="88" viewBox="0 0 66 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9065_22004)" filter="url(#filter0_d_9065_22004)">
      <path
        d="M35.6055 79.9423C35.6055 79.9423 28.5693 76.0729 32.0009 72.7811C32.0009 72.7811 34.3944 70.5288 30.4725 68.2476C30.4725 68.2476 25.2386 65.721 27.9204 62.819C27.9204 62.819 31.7846 60.5234 29.4055 57.2316C29.4055 57.2316 24.5032 53.8675 27.4735 51.0955C27.4735 51.0955 30.4004 49.5795 25.5125 45.5225C21.4033 42.1151 24.5609 39.834 25.6711 39.2131C26.0749 38.9821 26.3777 38.6212 26.5074 38.1736C26.7525 37.3507 26.4065 36.1523 22.8884 35.6614C17.2651 34.8818 25.6856 30.3772 25.6856 30.3772C25.6856 30.3772 29.0451 28.8323 24.7484 25.9881C24.7484 25.9881 22.0809 23.6059 28.2521 21.7722C28.2521 21.7722 33.8753 20.1696 31.6548 15.564C31.2655 14.7555 30.7032 14.048 30.0688 13.4561C29.4776 12.9074 28.7711 11.8823 30.6023 11.1171C30.6023 11.1171 33.039 9.13915 29.3046 7.96968C29.3046 7.96968 27.1851 7.30554 28.3386 6.1072C29.0451 5.35643 29.319 4.28804 29.0018 3.30626C28.5116 1.81917 28.3962 0 30.6744 0C30.6744 0 36.2399 1.94911 34.6251 7.03122C34.6251 7.03122 33.7455 9.81772 36.9897 9.52897C40.2339 9.24021 37.3934 14.2501 37.3934 14.2501C37.3934 14.2501 36.2688 16.1271 38.9939 16.0837C41.7334 16.0404 37.4078 20.9782 37.4078 20.9782C37.4078 20.9782 33.7744 24.2844 38.345 26.3057C38.345 26.3057 42.1515 27.6918 36.1246 30.3194C35.7209 30.4927 35.346 30.7381 35.0576 31.0558C34.3078 31.8643 33.6446 33.438 36.7157 35.5171C41.2576 38.5779 35.793 41.1045 35.793 41.1045C35.793 41.1045 33.1255 42.5338 37.3502 45.2048C41.5892 47.8758 38.446 51.4131 38.446 51.4131C38.446 51.4131 36.0669 54.0552 38.4027 56.1776C40.7385 58.3 37.6962 62.0249 37.6962 62.0249C37.6962 62.0249 35.173 64.1473 38.446 66.3851C41.7046 68.623 38.8497 71.1207 38.8497 71.1207C38.8497 71.1207 36.6581 73.272 39.0371 74.889C41.4162 76.4916 35.6055 79.9423 35.6055 79.9423Z"
        fill="#126F42"
      />
      <path
        d="M35.4901 80C35.4901 80 39.8156 72.2902 37.0761 69.1717C31.7989 63.1222 38.3017 62.3281 38.3017 62.3281C38.3017 62.3281 44.1268 59.686 40.8682 57.3326C33.8464 52.2361 39.1524 50.5036 39.1524 50.5036C39.1524 50.5036 45.3956 49.2475 42.2812 45.0316C42.2812 45.0316 38.1142 41.8986 41.4449 40.7003C43.1031 40.1083 43.3626 38.6934 43.2472 37.4951C43.1607 36.5999 42.7282 35.7625 42.1082 35.0984C41.3296 34.2899 40.6663 32.8172 44.8477 32.0087C44.8477 32.0087 48.164 31.1569 45.7849 28.0961C43.4058 25.0352 47.7458 25.2085 47.7458 25.2085C47.7458 25.2085 49.577 24.27 48.4379 22.0322C47.2989 19.7943 52.6481 19.8665 52.6481 19.8665C52.6481 19.8665 54.3207 19.2023 53.023 16.6613C52.4319 15.5063 53.023 14.0769 54.2774 13.716C54.2918 13.716 54.3207 13.7015 54.3351 13.7015C55.1137 13.5138 55.6328 12.8208 55.6472 12.0267C55.6616 10.6118 55.9356 8.83599 57.2044 9.39906C57.2044 9.39906 61.6453 12.2578 61.2993 19.0868C61.2993 19.0868 61.1407 21.9455 58.7472 23.9813C58.7472 23.9813 56.6565 25.0064 57.8821 28.2693C57.8821 28.2693 59.0644 31.8643 56.6709 32.4996C54.2774 33.1637 52.1291 34.8818 53.2537 37.163C54.3784 39.4442 53.4988 40.2238 52.6049 40.8591C51.7109 41.5088 49.9951 43.6023 52.6914 45.0316C55.3732 46.461 51.1054 50.2004 51.1054 50.2004C51.1054 50.2004 48.8705 51.8751 51.2063 55.1237C52.5184 56.9573 52.1291 58.762 51.5235 59.9892C51.1486 60.74 50.5863 61.3897 49.8798 61.8517C49.0579 62.3859 47.7747 63.5698 48.1496 65.4611C48.4812 67.0637 49.303 67.7134 48.0198 69.215C47.3133 70.0379 46.2463 70.1534 45.3091 70.6732C44.3863 71.1785 43.031 72.348 41.3007 75.3511C38.8208 79.7979 35.4901 80 35.4901 80Z"
        fill="#1E6561"
      />
      <path
        d="M35.6054 79.9422C36.707 77.8025 36.9391 76.0208 36.981 74.9308C37.1511 70.5215 34.6192 66.6781 33.682 65.2546C32.4564 63.3935 31.7672 62.995 31.3375 61.3896C30.8242 59.4708 31.375 58.1338 30.3758 57.0062C29.2887 55.779 27.7473 55.6866 27.7473 55.6866C27.7473 55.6866 24.0994 54.7626 26.6515 49.8393C28.2087 46.8074 19.4999 48.6554 19.4999 48.6554C19.4999 48.6554 14.3381 49.0163 15.3041 43.1257C15.9818 38.9532 11.0651 39.5596 11.0651 39.5596C11.0651 39.5596 9.2916 42.2161 7.28742 43.1257C6.86929 43.3134 6.53766 43.6455 6.35022 44.0642C6.01859 44.8871 6.19162 46.1432 9.79625 46.7785C10.3874 46.894 10.9642 47.1105 11.4544 47.4715C12.406 48.1645 13.3576 49.5361 12.1032 51.9472C12.1032 51.9472 10.6469 55.4123 16.2846 54.7915C21.432 54.2428 20.1055 60.0902 19.8316 61.1297C19.8027 61.2741 19.7739 61.4329 19.745 61.5772C19.6297 62.8767 19.6153 68.1176 26.8678 68.7528C29.6232 68.994 30.7752 70.1504 31.0448 70.302C32.827 71.2982 34.8831 73.6733 35.6054 79.9422Z"
        fill="#1E6561"
      />
      <path
        d="M11.0651 39.5307C11.0651 39.5307 3.63958 37.3795 4.01446 34.3475C4.11539 33.5535 4.76423 32.9471 5.57167 32.846C6.63864 32.7305 8.28235 32.153 6.9991 29.5397C5.18237 25.887 4.38935 21.238 7.73445 21.8299C7.73445 21.8299 10.1423 22.5663 9.7242 19.6643C9.7242 19.6643 8.18142 14.8709 11.3968 15.5639C11.3968 15.5639 12.68 16.3003 12.6656 14.2934C12.6656 13.3261 12.9107 12.3732 13.4009 11.5358C14.0498 10.4529 14.7707 8.92253 14.6265 7.69531C14.6265 7.69531 19.5721 13.1528 16.8181 17.9462C16.8181 17.9462 15.4051 19.5055 16.7749 21.8588C17.7986 23.6202 16.0972 25.5982 15.1888 26.4789C14.6698 26.9843 14.3093 27.6195 14.1795 28.3125C14.0209 29.1644 14.093 30.2472 15.1744 30.9402C17.366 32.3696 16.5874 34.4486 16.1549 35.2571C15.9098 35.7047 15.7512 36.21 15.7079 36.7009C15.5781 37.8848 15.8665 39.704 18.4907 40.3681C21.2014 41.0611 19.7451 44.1075 19.7451 44.1075C19.7451 44.1075 17.5823 47.5293 24.8493 48.0779C24.8493 48.0779 15.2177 51.1965 15.1888 45.9122C15.0879 40.6424 15.6214 40.4836 11.0651 39.5307Z"
        fill="#1E6561"
      />
      <path
        d="M14.5977 7.70976C14.5977 7.70976 9.11866 2.67095 12.5935 2.71427C16.0684 2.75758 17.5103 13.5138 17.5103 13.5138C17.5103 13.5138 16.4721 9.6733 14.5977 7.70976Z"
        fill="#126F42"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_9065_22004"
        x="0"
        y="0"
        width="65.318"
        height="88"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9065_22004" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_9065_22004"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_9065_22004">
        <rect width="57.318" height="80" fill="white" transform="translate(4)" />
      </clipPath>
    </defs>
  </svg>
</template>
