<template>
  <div>
    <div
      v-if="account.accountType !== 'personal' && account.accountType !== 'family'"
      class="back"
      @click="$router.push('/add-impact')"
    >
      <img class="chevron" alt="chevron" :src="require('@/assets/icons/chevron_left.svg')" />
      <div>
        {{ t('back') }}
      </div>
    </div>
    <!-- How It Works -->
    <div class="how-it-works">
      <HowItWorks
        :account="account"
        @integrationClick="$router.push('/add-impact/manage-integrations-automations/integrations')"
      />
    </div>
    <section class="impact-settings-section">
      <ImpactSettings :model-value="localCart" @update:model-value="onImpactSettingChange" />
    </section>
    <div
      v-if="
        alertTypes.includes('noSubscription') &&
        account.accountType !== 'business' &&
        account.accountType !== 'ecommerce'
      "
      class="upgrade"
    >
      <h2 class="upgrade-title">
        {{ t('upgrade') }}
      </h2>
      <p class="upgrade-text">
        {{ t('upgrade_text') }}
      </p>
      <router-link to="/upgrade-plan" class="upgrade-button">
        {{ t('upgrade') }}
      </router-link>
    </div>

    <!-- Cart -->
    <transition v-if="getIsCartOpen" name="fade">
      <div class="cart-wrapper">
        <AddCart />
      </div>
    </transition>

    <!-- Payment Error Alert -->
    <Alert
      :alert="alert"
      :error="error"
      :show-buttons="error"
      :text="error ? t('error') : t('success')"
      @closeAlertClick=";(alert = false), (error = false)"
    />
  </div>
</template>

<script lang="ts">
import HowItWorks from '@/components/add-impact/HowItWorks.vue'
import AddCart from '@/components/add-impact/AddCart.vue'
import Alert from '@/components/tools/Alert.vue'
import type { AlertType, ProjectId } from '@/helpers/constants'
import type { Account } from '@/store'
import ImpactSettings from '@/components/onboarding/ImpactSettings.vue'
import type { AutomationOffset } from '@/store/integrations'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ManualOffsets',
  components: {
    HowItWorks,
    AddCart,
    Alert,
    ImpactSettings,
  },
  data() {
    return {
      alert: false,
      error: false,
      relationStopped: false,
    }
  },
  computed: {
    localCart(): Omit<Required<AutomationOffset>, 'source'>[] {
      return [...this.getCurrentCart]
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    alertTypes(): AlertType[] {
      return this.$store.getters['getAlertTypes']
    },
    getCurrentCart(): Omit<Required<AutomationOffset>, 'source'>[] {
      return this.$store.getters['getCurrentCart']
    },
    getIsCartOpen(): boolean {
      return this.$store.getters['getIsCartOpen']
    },
  },
  async created() {
    this.resetCart()
    this.relationStopped = this.alertTypes.some((alert) => alert === 'stoppedAt')
  },
  methods: {
    onImpactSettingChange(impactSetting: Omit<Required<AutomationOffset>, 'source'>[]) {
      impactSetting.forEach(({ projectId, amount }) => {
        this.setCartByProjectId({ projectId, amount: amount || 0 })
      })
    },
    t(key: string) {
      return this.$t(`ManualOffsets.${key}`)
    },
    setCartByProjectId(payload: { projectId: ProjectId; amount: number }): Promise<void> {
      return this.$store.dispatch('setCartByProjectId', payload)
    },
    resetCart(): Promise<void> {
      return this.$store.dispatch('resetCart')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.add-impact {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.impact-settings-section {
  margin: 20px 0;
}

.back {
  display: flex;
  font-weight: 500;
  color: #3b755f;
  cursor: pointer;
  margin-bottom: 7px;
  width: 70px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 3px;
}

.chevron {
  width: 23px;
  filter: invert(39%) sepia(15%) saturate(1175%) hue-rotate(106deg) brightness(96%) contrast(87%);
}

.cart-wrapper {
  margin-top: 20px;
  display: flex;
  background: #cfcccc;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  height: 100px;
  position: fixed;
  z-index: 1;
  width: 100%;
  left: 0;
  bottom: 0;
  padding-left: 256px;
}

.upgrade {
  background: var(--ui-white);
  border-radius: var(--border-radius-small);
  padding: 30px;
  box-shadow: var(--box-shadow-light);
}

.upgrade-title {
  font-size: 38px;
  line-height: 48px;
  font-weight: 600;
  margin-bottom: 4px;
}

.upgrade-text {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 40px;
}

.upgrade-button {
  align-items: center;
  background-color: var(--ui-green);
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
  color: var(--ui-white);
  display: flex;
  height: 54px;
  font-weight: 600;
  justify-content: center;
  padding: 0 16px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
}

@media screen and (max-width: 860px) {
  .cart-wrapper {
    padding-left: 0;
    z-index: 4;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .add-impact {
    flex-direction: column;
  }
}
</style>
