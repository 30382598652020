<template>
  <div class="price-comparison-dialog">
    <div class="price-comparison-dialog-header">
      <h1 class="dialog-title">
        {{ t('title') }}
      </h1>
      <v-btn class="price-comparison-dialog-close-button" icon @click="close" variant="plain">
        <v-icon color="black"> mdi-close </v-icon>
      </v-btn>
    </div>
    <p class="dialog-desc">
      {{ t('desc') }}
    </p>
    <table>
      <thead>
        <tr>
          <th />
          <th>{{ t('current_plan') }}</th>
          <th>{{ t('new_plan') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ t('trees_kenya') }}</td>
          <td>
            {{
              t('trees_price', {
                price: getFormattedProjectPriceById(VERITREE_EARTHLUNGS_PROJECT_ID()),
              })
            }}
          </td>
          <td>
            {{ t('trees_price', { price: paidPlanPrice(VERITREE_EARTHLUNGS_PROJECT_ID()) }) }}
          </td>
        </tr>
        <tr>
          <td>{{ t('trees_oregon') }}</td>
          <td>
            {{
              t('trees_price', { price: getFormattedProjectPriceById(OREGON_TREES_PROJECT_ID()) })
            }}
          </td>
          <td>{{ t('trees_price', { price: paidPlanPrice(OREGON_TREES_PROJECT_ID()) }) }}</td>
        </tr>
        <tr>
          <td>{{ t('kelp') }}</td>
          <td>{{ t('kelp_price', { price: getFormattedProjectPriceById(KELP_PROJECT_ID()) }) }}</td>
          <td>{{ t('kelp_price', { price: paidPlanPrice(KELP_PROJECT_ID()) }) }}</td>
        </tr>
        <tr>
          <td>{{ t('plastic') }}</td>
          <td>
            {{
              t('plastic_price', { price: getFormattedProjectPriceById(PLASTIC_BANK_PROJECT_ID()) })
            }}
          </td>
          <td>{{ t('plastic_price', { price: paidPlanPrice(PLASTIC_BANK_PROJECT_ID()) }) }}</td>
        </tr>
        <tr>
          <td>{{ t('carbon') }}</td>
          <td>
            {{
              t('carbon_price', { price: getFormattedProjectPriceById(CARBON_POOL_PROJECT_ID()) })
            }}
          </td>
          <td>{{ t('carbon_price', { price: paidPlanPrice(CARBON_POOL_PROJECT_ID()) }) }}</td>
        </tr>
        <tr>
          <td colspan="2">
            {{ t('sum_current') }}
          </td>
          <td>{{ currencyFormatter(currentSum) }}</td>
        </tr>
        <tr>
          <td colspan="2">
            {{ t('sum_new') }}
          </td>
          <td>{{ currencyFormatter(paidPlanSum) }}</td>
        </tr>
      </tbody>
    </table>
    <gs-button size="large" full-width @click.prevent="navigate">
      {{ t('change_plan') }}
    </gs-button>
  </div>
</template>

<script lang="ts">
import type { OffsetType } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { Decimal } from 'decimal.js'
import type { Account } from '@/store'
import type { ProjectId } from '@/helpers/constants'
import {
  CARBON_POOL_PROJECT_ID,
  KELP_PROJECT_ID,
  OREGON_TREES_PROJECT_ID,
  PLASTIC_BANK_PROJECT_ID,
  VERITREE_EARTHLUNGS_PROJECT_ID,
} from '@/helpers/constants'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PriceComparisonDialog',
  methods: {
    CARBON_POOL_PROJECT_ID() {
      return CARBON_POOL_PROJECT_ID
    },
    PLASTIC_BANK_PROJECT_ID() {
      return PLASTIC_BANK_PROJECT_ID
    },
    KELP_PROJECT_ID() {
      return KELP_PROJECT_ID
    },
    OREGON_TREES_PROJECT_ID() {
      return OREGON_TREES_PROJECT_ID
    },
    VERITREE_EARTHLUNGS_PROJECT_ID() {
      return VERITREE_EARTHLUNGS_PROJECT_ID
    },
    t(key: string, params?: { [k: string]: string | number }) {
      return this.$t(`PriceComparisonDialog.${key}`, params ?? {})
    },
    paidPlanPrice(projectId: string): string {
      return this.getUserCurrencySymbol + this.getPaidProjectPriceById(projectId)
    },
    navigate() {
      this.$router.push('/upgrade-plan')
    },
  },
  mixins: [Utils],
  computed: {
    currentSum(): number {
      return this.account.impacts.reduce((acc, { amount, type, projectId }) => {
        acc = new Decimal(acc)
          .plus(
            new Decimal(type === 'carbon' ? amount * 1000 : amount).times(
              this.getProjectPriceById(projectId as ProjectId),
            ),
          )
          .toDP(3)
          .toNumber()
        return acc
      }, 0)
    },
    paidPlanSum(): number {
      return this.account.impacts.reduce((acc, { amount, type, projectId }) => {
        acc = new Decimal(acc)
          .plus(
            new Decimal(type === 'carbon' ? amount * 1000 : amount).times(
              this.getPaidProjectPriceById(projectId),
            ),
          )
          .toDP(3)
          .toNumber()
        return acc
      }, 0)
    },
    getDefaultProjectPriceByType(): (type: OffsetType) => number {
      return this.$store.getters['getDefaultProjectPriceByType']
    },
    getDefaultPaidProjectPriceByType(): (type: OffsetType) => number {
      return this.$store.getters['getDefaultPaidProjectPriceByType']
    },
    getPaidProjectPriceById(): (projectId: string) => number {
      return this.$store.getters['getPaidProjectPriceById']
    },
    getUserCurrencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
    getFormattedProjectPriceById(): (projectId: string) => string {
      return this.$store.getters['getFormattedProjectPriceById']
    },
    getProjectPriceById(): (projectId: ProjectId, freeBusinessPrice?: boolean) => number {
      return this.$store.getters['getProjectPriceById']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
  props: {
    close: {
      type: Function as PropType<() => void>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.price-comparison-dialog {
  background-color: white;
  padding: 16px;
  overflow-y: scroll;
  width: 100%;
  position: relative;
  height: 100%;
}

.price-comparison-dialog-header {
  padding-right: 36px;
}

.dialog-title {
  font-size: 24px;
  color: var(--ui-green);
  margin-bottom: 32px;
  max-width: 30ch;
}

.price-comparison-dialog-close-button {
  position: absolute;
  top: 16px;
  right: 16px;
}

.dialog-desc {
  font-size: 16px;
  margin-bottom: 16px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 24px;
}

th,
td {
  font-size: 14px;
}

tr > td:not(:first-child),
tr > th:not(:first-child) {
  text-align: center;
}

tr:not(:has([colspan])) > td:last-child,
tr:not(:has([colspan])) > th:last-child {
  color: var(--ui-green);
}

tbody tr:has([colspan]) > td:last-child {
  text-align: right;
}

thead {
  background-color: var(--gray-light-F1);
}

tr {
  height: 40px;
}

tbody tr {
  height: 60px;
}

tbody tr:not(:has([colspan])) {
  border-bottom: 1px solid var(--gray-light-A9);
}

tbody tr:has([colspan]) {
  height: 40px;
}

tbody tr:last-child {
  color: var(--ui-green);
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  th,
  td {
    font-size: 20px;
  }

  .price-comparison-dialog {
    padding: 32px;
  }

  .price-comparison-dialog-close-button {
    position: absolute;
    top: 32px;
    right: 32px;
  }

  table {
    margin-bottom: 32px;
  }
}
</style>
