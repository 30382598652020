import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ff98286a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "dialog-wrapper"
}
const _hoisted_2 = { class: "dialog-wrapper" }
const _hoisted_3 = { class: "close-wrapper" }
const _hoisted_4 = { class: "dialog-title" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "dialog-text" }
const _hoisted_7 = { class: "impact-input" }
const _hoisted_8 = {
  class: "textarea",
  style: {"min-height":"150px","width":"100%"}
}
const _hoisted_9 = { style: {"width":"100%"} }
const _hoisted_10 = {
  key: 0,
  class: "total"
}
const _hoisted_11 = {
  key: 0,
  class: "discount",
  style: {"margin-bottom":"2px"}
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }
const _hoisted_14 = {
  key: 1,
  class: "total"
}
const _hoisted_15 = {
  key: 0,
  class: "discount",
  style: {"margin-bottom":"2px"}
}
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 2 }
const _hoisted_18 = {
  key: 2,
  class: "total"
}
const _hoisted_19 = {
  key: 0,
  class: "discount",
  style: {"margin-bottom":"2px"}
}
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { key: 2 }
const _hoisted_22 = { class: "button-wrapper" }
const _hoisted_23 = {
  key: 0,
  class: "button-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImpactInput = _resolveComponent("ImpactInput")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('reward')), 1),
            _createElementVNode("img", {
              style: {"width":"25px"},
              class: "close-icon",
              src: require('@/assets/icons/close_icon.svg'),
              alt: "close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dialogClick')))
            }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('text')), 1),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ImpactInput, { onSetImpactType: _ctx.setImpactType }, null, 8, ["onSetImpactType"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_v_textarea, {
              modelValue: _ctx.message,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.message) = $event)),
              width: "100%",
              variant: "outlined",
              color: "#3B755F",
              clearable: "",
              flat: "",
              "no-resize": "",
              name: "input-7-4",
              label: _ctx.t('description')
            }, null, 8, ["modelValue", "label"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_ctx.account.currency === 'euro')
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  (_ctx.discountTotal > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.t('total_impact_discount')) + " " + _toDisplayString(_ctx.currencyEuro(_ctx.discountTotal)), 1))
                    : _createCommentVNode("", true),
                  (_ctx.discountTotal > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.t('total')) + " " + _toDisplayString(_ctx.currencyEuro(_ctx.totalPriceWithDiscount)), 1))
                    : _createCommentVNode("", true),
                  (_ctx.discountTotal === 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.t('total')) + " " + _toDisplayString(_ctx.currencyEuro(_ctx.impactCost)), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.account.currency === 'pound')
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  (_ctx.discountTotal > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.t('total_impact_discount')) + " " + _toDisplayString(_ctx.currencyPound(_ctx.discountTotal)), 1))
                    : _createCommentVNode("", true),
                  (_ctx.discountTotal > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.t('total')) + " " + _toDisplayString(_ctx.currencyPound(_ctx.totalPriceWithDiscount)), 1))
                    : _createCommentVNode("", true),
                  (_ctx.discountTotal === 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.t('total')) + " " + _toDisplayString(_ctx.currencyPound(_ctx.impactCost)), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.account.currency === 'dollar')
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  (_ctx.discountTotal > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.t('total_impact_discount')) + " " + _toDisplayString(_ctx.currencyDollar(_ctx.discountTotal)), 1))
                    : _createCommentVNode("", true),
                  (_ctx.discountTotal > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.t('total')) + " " + _toDisplayString(_ctx.currencyDollar(_ctx.totalPriceWithDiscount)), 1))
                    : _createCommentVNode("", true),
                  (_ctx.discountTotal === 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.t('total')) + " " + _toDisplayString(_ctx.currencyDollar(_ctx.impactCost)), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_v_btn, {
              class: "button",
              color: "#3B755F",
              height: "54",
              variant: "flat",
              width: "100%",
              disabled: _ctx.lessThanMin || _ctx.loading,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sendReward()))
            }, {
              default: _withCtx(() => [
                (!_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(_ctx.lessThanMin
                ? _ctx.userCurrencySymbol + _ctx.IMPACT_LOWER_PRICE_LIMIT + ' ' + _ctx.t('minimum')
                : _ctx.t('reward')), 1))
                  : _createCommentVNode("", true),
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                      key: 1,
                      indeterminate: "",
                      color: "#F9F9F9"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["disabled"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}