<template>
  <button class="d-flex align-center" @click.prevent="openUploadModal">
    <p class="font-weight-bold text-body-1 ma-0">
      {{ buttonText }}
    </p>
    <div class="business-logo ml-3">
      <img v-if="imageUrl" class="uploaded-icon" :src="imageUrl" alt="" />
      <v-icon v-else class="image-placeholder"> mdi-cloud-upload </v-icon>
    </div>
  </button>
</template>

<script lang="ts">
import type { Notification } from '@/store/notification'
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'

export default defineComponent({
  name: 'UploadIcon',
  emits: ['update:model-value'],
  data() {
    return {
      imageUrl: '',
      widget: undefined,
    } as {
      imageUrl?: string
      widget?: { open: () => void }
    }
  },
  computed: {
    buttonText(): TranslateResult {
      return this.imageUrl ? this.t('edit_icon') : this.t('upload_icon')
    },
  },
  created() {
    this.imageUrl = this.modelValue
    this.createUploadWidget()
  },
  methods: {
    t(key: string) {
      return this.$t(`UploadIcon.${key}`)
    },
    openUploadModal() {
      if (this.widget) this.widget.open()
    },
    createUploadWidget() {
      // https://cloudinary.com/documentation/upload_widget#look_and_feel_customization
      // https://cloudinary.com/documentation/upload_widget#localization

      // All Parameters:
      // https://cloudinary.com/documentation/upload_widget_reference
      if (window.cloudinary) {
        this.widget = window.cloudinary.createUploadWidget(
          {
            cloud_name: process.env.VUE_APP_CLOUDNAME,
            upload_preset: process.env.VUE_APP_UPLOAD_PRESET,
            folder: 'integrationPics',
            sources: ['local', 'google_drive', 'url', 'camera'],
            cropping: true,
            maxFiles: 3,
            cropping_aspect_ratio: 1,
            croppingShowDimensions: true,
            showSkipCropButton: true,
            showPoweredBy: false,
            clientAllowedFormats: ['png', 'jpg', 'jpeg'],
            styles: {
              palette: {
                window: '#FFF',
                windowBorder: '#3B755F',
                tabIcon: '#3B755F',
                menuIcons: '#5A616A',
                textDark: '#212121',
                textLight: '#FFFFFF',
                link: '#3B755F',
                action: '#FF620C',
                inactiveTabIcon: '#BBB',
                error: '#F44235',
                inProgress: '#0078FF',
                complete: '#3B755F',
                sourceBg: '#E4EBF1',
              },
              frame: {
                background: '#0E2F5B99',
              },
              fonts: {
                "'Cabin', sans-serif":
                  'https://fonts.googleapis.com/css2?family=Cabin&display=swap',
              },
            },
          },
          (error, result) => {
            if (!error && result && result.event === 'success') {
              this.imageUrl = result.info.url
              this.$emit('update:model-value', this.imageUrl)
            }
            if (error) {
              this.$store.dispatch('notification/notify', {
                text: this.$t('CommonUi.error_generic'),
                isError: true,
                isClosable: true,
                buttonText: 'close',
              } as Notification)
            }
          },
        )
      }
    },
  },
  props: {
    modelValue: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.image-placeholder {
  background: url('../../assets/backgrounds/upload-icon-background.png') no-repeat center/contain;
  height: 50px;
  width: 50px;
  flex-shrink: 0;
  color: var(--ui-green);
  font-size: 24px;
}

.uploaded-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
</style>
