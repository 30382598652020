<template>
  <div class="promo-code-wrapper">
    <button
      class="add-promo-code"
      :disabled="hasValidPromoCode"
      @click.prevent="isDialogOpen = true"
    >
      <span>{{ t('add_promo_code') }}</span>
      <v-icon v-if="!hasValidPromoCode" style="color: inherit"> mdi-plus </v-icon>
      <span v-else class="promo-code-pill">
        <v-icon>mdi-ticket-percent-outline</v-icon>
        {{ typedInPromoCode }}
        <button @click.prevent.stop="clearPromoCode"><v-icon>mdi-close</v-icon></button>
      </span>
    </button>

    <p v-if="notification" :class="['notification-text', { invalid: !isValid }]">
      {{ t(snakeCase(notification)) }}
    </p>

    <v-dialog v-model="isDialogOpen" max-width="500" :fullscreen="isMobile">
      <div class="promo-code-dialog">
        <div class="dialog-header">
          <h2 class="dialog-title">
            {{ t('dialog_title') }}
          </h2>
          <button class="dialog-close-button" @click.prevent="isDialogOpen = false">
            <v-icon
              style="color: inherit; font-size: inherit; line-height: inherit"
              variant="plain"
            >
              mdi-close
            </v-icon>
          </button>
        </div>
        <v-form v-model="isFormValid">
          <gs-input
            v-model="typedInPromoCode"
            :label="t('input_label')"
            class="promo-input"
            :rules="[rules.maxlength(maxLength), rules.minlength(minLength), rules.required]"
            :hide-details="false"
          />
          <gs-button
            class="mt-2"
            full-width
            size="large"
            :disabled="!isFormValid || loading"
            :loading="loading"
            @click.prevent="validatePromoCode"
          >
            {{ t('button_text') }}
          </gs-button>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import type { PromoCodeSlug } from '@api/index'
import { validatePromoCode } from '@api/index'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { Notification } from '@/store/notification'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AddPromoCode',
  emits: ['setPromoCode'],
  mixins: [Utils, RulesMixin],
  data() {
    const notification: PromoCodeSlug | '' = ''

    return {
      typedInPromoCode: '',
      notification,
      isDialogOpen: false,
      loading: false,
      isValid: false,
      isFormValid: false,
      maxLength: 30,
      minLength: 3,
    }
  },
  computed: {
    hasValidPromoCode(): boolean {
      return this.isValid && !!this.typedInPromoCode
    },
  },
  methods: {
    async validatePromoCode(): Promise<void> {
      this.loading = true
      try {
        const response = await validatePromoCode(this.typedInPromoCode)
        this.isValid = response.isValid
        if (response.isValid && response.slug) {
          this.notification = response.slug
          this.$emit('setPromoCode', this.typedInPromoCode)
        } else if (!response.isValid || !response.slug) this.notification = 'invalid'
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: this.$t('CommonUi.error_generic'),
          isError: true,
          isClosable: true,
          buttonText: 'close',
        } as Notification)
        console.error(e)
      }
      this.isDialogOpen = false
      this.loading = false
    },
    t(key: string) {
      return this.$t(`AddPromoCode.${key}`)
    },
    clearPromoCode() {
      this.typedInPromoCode = ''
      this.notification = ''
      this.isValid = false
      this.$emit('setPromoCode', '')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.add-promo-code {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
  outline: none;
}

.promo-code-wrapper {
  margin-bottom: 20px;
}

.notification-text {
  color: var(--ui-green);
  font-size: 14px;
  line-height: 20px;
}

.notification-text.invalid {
  color: var(--ui-red);
}

.promo-code-pill {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  gap: 5px;
  padding: 2px 5px;
  border-radius: var(--border-radius-small);
  background-color: var(--ui-beige);
}

.promo-code-pill i {
  color: var(--ui-green);
  outline: none;
}

.promo-code-dialog {
  background-color: white;
  padding: 20px;
  height: 100%;
  min-height: 100vh;
}

.dialog-close-button {
  font-size: 30px;
  line-height: 30px;
  color: var(--ui-black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-header {
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
}

.dialog-title {
  font-size: 24px;
  line-height: 30px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .promo-code-dialog {
    height: auto;
    min-height: auto;
  }
}
</style>
