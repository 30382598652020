const countDecimals = function (value: number): number {
  if (Math.floor(value.valueOf()) === value.valueOf()) return 2

  const str = value.toString()
  if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
    return Number(str.split('.')[1].length) || 2
  } else if (str.indexOf('.') !== -1) {
    return str.split('.')[1].length || 2
  }
  return Number(str.split('-')[1]) || 2
}

export default {
  methods: {
    currencyEuro(val) {
      if (typeof val !== 'number') {
        return val
      }
      const format = new Intl.NumberFormat('de-AT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: countDecimals(val),
      })
      return format.format(val)
    },
    currencyPound(val) {
      if (typeof val !== 'number') {
        return val
      }
      const format = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: countDecimals(val),
      })
      return format.format(val)
    },
    currencyDollar(val) {
      if (typeof val !== 'number') {
        return val
      }
      const format = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: countDecimals(val),
      })
      return format.format(val)
    },
  },
}
