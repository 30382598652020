<template>
  <component
    :is="tag"
    class="onboarding-pill"
    :class="{ disabled }"
    @click.prevent="copyToClipboard"
  >
    <v-icon v-if="mode !== 'impact'" class="onboarding-pill__icon"> mdi-check </v-icon>
    <svg v-else class="onboarding-pill__impact-icon">
      <use :href="'#' + offsetType + '-icon'" />
    </svg>
    <span class="onboarding-pill__content" ref="slot">
      <slot />
    </span>
    <v-icon v-if="isCopyEnabled" class="onboarding-pill__icon copy">
      {{ isCopied ? 'mdi-check-circle' : 'mdi-content-copy' }}
    </v-icon>
  </component>
</template>

<script lang="ts">
import type { OffsetType } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OnboardingPill',
  data() {
    return {
      isCopied: false,
    }
  },
  computed: {
    tag(): string {
      return this.isCopyEnabled ? 'button' : 'div'
    },
  },
  methods: {
    copyToClipboard() {
      const slot = this.$refs.slot as HTMLElement
      navigator.clipboard.writeText(slot.innerHTML).then(() => {
        this.isCopied = true
        setTimeout(() => {
          this.isCopied = false
        }, 2000)
      })
    },
  },
  props: {
    isCopyEnabled: {
      default: false,
      type: Boolean,
    },
    mode: {
      default: 'default',
      type: String as PropType<'default' | 'impact'>,
    },
    offsetType: {
      type: String as PropType<OffsetType>,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.onboarding-pill {
  border-radius: var(--border-radius-big);
  background-color: var(--ui-beige);
  padding: 5px 9px 5px 5px;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  gap: 4px;

  &.disabled {
    background-color: var(--gray-light-DB);
    cursor: default;

    .onboarding-pill__icon {
      color: var(--ui-black);
    }
  }
}

.onboarding-pill__icon {
  color: var(--ui-green);
  font-size: 22px;
  line-height: 22px;
  margin-left: -2px;
  flex-shrink: 0;
}

.onboarding-pill__impact-icon {
  height: 22px;
  width: 22px;
  flex-shrink: 0;
}

.onboarding-pill__content {
  font-size: 18px;
  font-weight: 400;
}

.onboarding-pill__icon.copy {
  margin: 0 0 0 4px;
}
</style>
