<template>
  <div>
    <div class="back" @click="clickOnBack()">
      <v-icon class="chevron"> mdi-chevron-left </v-icon>
      <div>
        {{ $t(`CommonUi.back`) }}
      </div>
    </div>
    <div class="wrapper">
      <h2 class="header">
        {{ customIntegration.source }}
      </h2>
      <i18n-t
        :keypath="`ManageCustomIntegrationView.description_${customIntegrationType}`"
        tag="div"
        class="mt-1 mb-4"
      >
        {{ customIntegration.source }}
        <a
          :href="API_DOCUMENTATION_URL"
          target="_blank"
          style="text-decoration: none"
          class="font-weight-bold"
        >
          {{ t('link') }}
        </a>
      </i18n-t>
      <div class="table-wrapper">
        <div
          class="d-md-inline-flex flex-md-row justify-md-space-between align-md-start d-flex flex-column mb-2 table-text-wrapper"
        >
          <div class="mr-16">
            <p class="text-h5 font-weight-bold mb-2">
              {{ t('triggers') }}
            </p>
            <p class="text-body-1 mb-4">
              {{ t(`triggers_description_${customIntegrationType}`) }}
            </p>
          </div>
          <v-btn
            class="text-white"
            height="56"
            min-width="227"
            color="green"
            @click="isCreateTriggerDialogOpen = true"
          >
            {{ t('create_trigger') }}
          </v-btn>
        </div>
        <v-table class="table" fixed-header>
          <template v-slot:default>
            <thead>
              <th class="text-left">
                {{ t('name') }}
              </th>
              <th class="text-left">
                {{ t('type') }}
              </th>
              <th class="text-left">
                {{ t('trigger_id') }}
              </th>
              <th class="text-left">
                {{ t('created') }}
              </th>
              <th />
            </thead>
            <tbody>
              <tr v-for="(item, index) in customIntegration.triggers" :key="index">
                <td class="text-body-1 text-no-wrap">
                  {{ item.name }}
                </td>
                <td class="text-capitalize text-body-1 text-no-wrap">
                  {{ item.type }}
                </td>
                <td class="text-body-1 text-no-wrap">
                  {{ item._id }}
                  <v-btn icon @click="copyToClipboard(item)" variant="plain">
                    <img
                      v-if="!item.isCopied"
                      :src="require('@/assets/icons/file_copy.svg')"
                      alt="copy"
                    />
                    <img
                      v-if="item.isCopied"
                      :src="require('@/assets/icons/check_circle_2.svg')"
                      alt="copy"
                    />
                  </v-btn>
                </td>
                <td class="text-body-1 text-no-wrap">
                  {{ dateFormatFilter(item.createdAt, userLocale) }}
                </td>
                <td class="text-body-1 pr-0 text-no-wrap d-flex flex-row-reverse align-center">
                  <QuickMenu
                    class="pr-2"
                    style="height: 25px"
                    :quick-menu-items="menuItems"
                    @quickMenuClick="quickMenuClickTrigger($event, item)"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </div>
      <div class="table-wrapper">
        <div
          class="d-md-inline-flex flex-md-row justify-md-space-between align-md-start d-flex flex-column table-text-wrapper"
        >
          <div class="mr-16">
            <p class="text-h5 font-weight-bold mb-2">
              {{ t('source') }}
            </p>
            <p class="text-body-1 mb-0">
              \|||||||||||||||||||||||||||||\\
              {{ t(`source_description_${customIntegrationType}`) }}
            </p>
            <i18n-t
              v-if="customIntegrationType === 'custom'"
              keypath="ManageCustomIntegrationView.source_description_help"
              tag="div"
              class="text-body-1"
            >
              <a
                :href="API_DOCUMENTATION_URL"
                target="_blank"
                style="text-decoration: none"
                class="font-weight-bold"
              >
                {{ t('source_description_help_link') }}
              </a>
            </i18n-t>
          </div>
        </div>

        <v-table class="table" fixed-header>
          <thead>
            <th class="text-left">
              {{ t('name') }}
            </th>
            <th class="text-left">
              {{ t('source_id') }}
            </th>
            <th class="text-left">
              {{ t('created') }}
            </th>
            <th />
          </thead>
          <tbody>
            <tr>
              <td class="text-body-1 text-no-wrap">
                {{ customIntegration.source }}
              </td>
              <td class="text-body-1 text-no-wrap">
                {{ customIntegration._id }}
                <v-btn icon @click="copyToClipboard(customIntegration)" variant="plain">
                  <img
                    v-if="!customIntegration.isCopied"
                    :src="require('@/assets/icons/file_copy.svg')"
                    alt="copy"
                  />
                  <img
                    v-if="customIntegration.isCopied"
                    :src="require('@/assets/icons/check_circle_2.svg')"
                    alt="copy"
                  />
                </v-btn>
              </td>
              <td class="text-body-1 text-no-wrap">
                {{ dateFormatFilter(customIntegration.createdAt, userLocale) }}
              </td>
              <td class="text-body-1 pl-5 pr-0 text-no-wrap d-flex flex-row-reverse align-center">
                <QuickMenu
                  class="pr-2"
                  style="height: 25px"
                  :quick-menu-items="sourceMenuItems"
                  @quickMenuClick="quickMenuClickSource($event)"
                />
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </div>
    <transition name="fade">
      <v-dialog v-model="isEditTriggerDialogOpen" persistent width="572">
        <v-card class="d-flex flex-column" height="370">
          <v-card-title
            class="text-black font-weight-bold text-h5 pb-4 d-flex py-6 pt-4 align-center"
          >
            {{ t('edit_trigger_title') }}
            <v-spacer />
            <v-btn icon @click="resetState" variant="plain">
              <v-icon color="black"> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form v-model="isTriggerEditFormValid">
              <v-text-field
                v-model="editTriggerName"
                class="mb-10"
                variant="outlined"
                flat
                type="text"
                :label="t('trigger_name')"
                :rules="[localRules.maxLength, rules.required]"
                maxlength="40"
                counter
              />
              <v-select
                v-model="editTriggerType"
                :items="triggerTypes"
                :label="t('trigger_type')"
                variant="outlined"
                flat
                :rules="[rules.required]"
                hide-details
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex mt-auto mb-0">
            <v-btn
              class="create button text-white text-capitalize font-weight-bold text-h5"
              color="green"
              height="50px"
              :loading="isEditTriggerLoading"
              :disabled="
                isEditTriggerLoading ||
                !isTriggerEditFormValid ||
                (selectedTrigger.name === editTriggerName &&
                  selectedTrigger.type.charAt(0).toUpperCase() + selectedTrigger.type.slice(1) ===
                    editTriggerType)
              "
              @click="editTrigger"
            >
              {{ $t('CommonUi.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </transition>
    <transition name="fade">
      <v-dialog v-model="isCreateTriggerDialogOpen" persistent width="572">
        <v-card class="d-flex flex-column" height="370">
          <v-card-title
            class="text-black font-weight-bold text-h5 d-flex py-6 pt-4 pb-3 align-center"
          >
            {{ t('create_trigger_title') }}
            <v-spacer />
            <v-btn icon @click="resetState" variant="plain">
              <v-icon color="black"> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form ref="createTriggerForm" v-model="isCreateTriggerFormValid">
              <gs-input
                v-model="createTriggerName"
                class="mb-10"
                :label="t('trigger_name')"
                :rules="[localRules.maxLength, rules.required]"
                maxlength="40"
                counter
                :hide-details="false"
              />
              <v-select
                v-model="createTriggerType"
                :items="triggerTypes"
                :label="t('trigger_type')"
                variant="outlined"
                flat
                :rules="[rules.required]"
                hide-details
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex mt-auto mb-0">
            <v-btn
              class="create button text-white text-capitalize font-weight-bold text-h5"
              color="green"
              height="50px"
              :loading="isCreateTriggerLoading"
              :disabled="isCreateTriggerLoading || !isCreateTriggerFormValid"
              @click="createTrigger"
            >
              {{ t('create_trigger_title') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </transition>
    <transition name="fade">
      <v-dialog v-model="isDeleteTriggerDialogOpen" persistent width="572">
        <v-card class="d-flex flex-column" height="370">
          <v-card-title
            class="text-red font-weight-bold text-h5 pb-4 d-flex py-6 pt-4 align-center"
          >
            {{ t('delete_trigger_title') }}
            <v-spacer />
            <v-btn icon @click="resetState" variant="plain">
              <v-icon color="black"> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="text-black text-body-1 mb-16">
            <i18n-t keypath="ManageCustomIntegrationView.delete_trigger_text" tag="div">
              <span class="font-weight-bold"> {{ selectedTrigger.name }} </span>
            </i18n-t>
          </v-card-text>
          <v-card-text class="pb-0">
            <v-checkbox v-model="isDeleteTriggerDialogChecked">
              <template v-slot:label>
                <span id="checkboxLabel" class="text-black">{{
                  t('delete_trigger_checkbox')
                }}</span>
              </template>
            </v-checkbox>
          </v-card-text>
          <v-card-actions class="d-flex mt-auto mb-0 pt-0">
            <v-btn
              class="delete button text-white text-capitalize font-weight-bold text-h5"
              color="red"
              height="50px"
              :loading="isDeleteTriggerLoading"
              :disabled="isDeleteTriggerLoading || !isDeleteTriggerDialogChecked"
              @click="deleteTrigger"
            >
              {{ t('delete_trigger_title') }}
            </v-btn>
            <v-btn
              class="cancel button text-capitalize font-weight-bold text-h5"
              variant="outlined"
              color="black"
              height="50px"
              @click="resetState"
            >
              {{ $t('CommonUi.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </transition>
    <transition name="fade">
      <v-dialog v-model="isEditSourceDialogOpen" persistent width="572">
        <v-card class="d-flex flex-column" height="370">
          <v-card-title
            class="text-black font-weight-bold text-h5 pb-4 d-flex py-6 pt-4 align-center"
          >
            {{ t('edit_source_title') }}
            <v-spacer />
            <v-btn icon @click="resetState" variant="plain">
              <v-icon color="black"> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form ref="editSourceForm" v-model="isSourceEditFormValid">
              <v-text-field
                v-model="editSourceName"
                class="mb-5"
                variant="outlined"
                flat
                :label="t('source_name')"
                maxlength="40"
                counter
                :rules="[localRules.maxLength, rules.required]"
              />
            </v-form>
            <div class="d-flex align-center">
              <p v-if="!customIntegration.imageUrl" class="font-weight-bold text-body-1 ma-0">
                {{ t('upload_icon') }}
              </p>
              <p v-if="customIntegration.imageUrl" class="font-weight-bold text-body-1 ma-0">
                {{ t('edit_icon') }}
              </p>
              <div class="business-logo ml-3" @click="openUploadModal">
                <img
                  :style="'max-width: 100%; object-fit: contain;'"
                  :src="
                    editSourceUrl
                      ? editSourceUrl
                      : require('@/assets/icons/custom-integration-upload.svg')
                  "
                  alt="logo"
                />
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex mt-auto mb-0">
            <v-btn
              class="create button text-white text-capitalize font-weight-bold text-h5"
              color="green"
              height="50px"
              :loading="isEditSourceLoading"
              :disabled="
                isEditSourceLoading ||
                !isSourceEditFormValid ||
                (editSourceName === customIntegration.source &&
                  editSourceUrl === customIntegration.imageUrl)
              "
              @click="editSource"
            >
              {{ $t('CommonUi.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </transition>
    <transition name="fade">
      <v-dialog v-model="isDeleteSourceDialogOpen" persistent width="572">
        <v-card class="d-flex flex-column" height="370">
          <v-card-title
            class="text-red font-weight-bold text-h5 d-flex py-6 pt-4 pb-3 align-center"
          >
            {{ t('delete_source_title') }}
            <v-spacer />
            <v-btn icon @click="resetState" variant="plain">
              <v-icon color="black"> mdi-close </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="text-black text-body-1 mb-16">
            <i18n-t keypath="ManageCustomIntegrationView.delete_source_text" tag="div">
              <span class="font-weight-bold"> {{ customIntegration.source }} </span>
            </i18n-t>
          </v-card-text>
          <v-card-text class="pb-0">
            <v-checkbox v-model="isDeleteSourceDialogChecked" class="delete checkbox">
              <template v-slot:label>
                <span id="checkboxLabel" class="text-black">{{ t('delete_source_checkbox') }}</span>
              </template>
            </v-checkbox>
          </v-card-text>
          <v-card-actions class="d-flex mt-auto mb-0 pt-0">
            <v-btn
              class="delete button text-white text-capitalize font-weight-bold text-h5"
              color="red"
              height="50px"
              :loading="isDeleteSourceLoading"
              :disabled="isDeleteSourceLoading || !isDeleteSourceDialogChecked"
              @click="deleteSource"
            >
              {{ t('delete_source_title') }}
            </v-btn>
            <v-btn
              class="cancel button text-capitalize font-weight-bold text-h5"
              variant="outlined"
              color="black"
              height="50px"
              @click="resetState"
            >
              {{ $t('CommonUi.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </transition>
    <LoadingOpaque v-if="loading" />
  </div>
</template>

<script lang="ts">
import type { CustomIntegrationResponse, CustomIntegrationTrigger } from '@api/index'
import {
  createCustomIntegrationTrigger,
  deleteCustomIntegration,
  deleteCustomIntegrationTrigger,
  getCustomIntegration,
  updateCustomIntegration,
  updateCustomIntegrationTrigger,
} from '@api/index'
import type { Account } from '@/store'
import LoadingOpaque from '@/components/tools/LoadingOpaque.vue'
import type { Notification } from '@/store/notification'
import QuickMenu from '@/components/tools/Table/QuickMenu.vue'
import { API_DOCUMENTATION_URL } from '@/helpers/constants'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { CustomIntegrationType } from '@/helpers/interfaces'

export default defineComponent({
  name: 'ManageCustomIntegrationView',
  components: {
    LoadingOpaque,
    QuickMenu,
  },
  mixins: [RulesMixin],
  data() {
    return {
      customIntegration: {
        _id: '',
        userId: '',
        source: '',
        imageUrl: '',
        createdAt: '',
        triggers: [],
        isCopied: false,
      },
      loading: false,
      localRules: {
        maxLength: (value) => value.length > 2 && value.length < 41,
      },
      menuItems: [
        {
          value: 'edit',
          label: 'Edit',
          image: '',
        },
        {
          value: 'delete',
          label: 'Delete',
          image: '',
        },
      ],
      triggerTypes: [
        'Transaction',
        'Subscription',
        'Order',
        'Subscriber',
        'Review',
        'Affiliate',
        'Partner',
        'Form completion',
        'Other',
      ],
      selectedTrigger: {
        name: '',
        type: '',
        _id: '',
      },
      isCreateTriggerDialogOpen: false,
      isCreateTriggerLoading: false,
      createTriggerName: '',
      createTriggerType: '',
      editTriggerName: '',
      editTriggerType: '',
      isEditTriggerDialogOpen: false,
      isEditTriggerLoading: false,
      isDeleteTriggerDialogOpen: false,
      isDeleteTriggerDialogChecked: false,
      isDeleteTriggerLoading: false,
      isDeleteSourceDialogOpen: false,
      isDeleteSourceDialogChecked: false,
      isDeleteSourceLoading: false,
      isEditSourceDialogOpen: false,
      isEditSourceLoading: false,
      editSourceName: '',
      editSourceUrl: '',
      isSourceEditFormValid: false,
      isTriggerEditFormValid: false,
      isCreateTriggerFormValid: false,
      API_DOCUMENTATION_URL: '',
    } as {
      customIntegration: CustomIntegrationView
      loading: boolean
      localRules: {
        maxLength: (value: unknown[]) => boolean
      }
      menuItems: { value: string; label: string; image: string }[]
      triggerTypes: string[]
      selectedTrigger: { name: string; _id: string; type: string }
      isCreateTriggerDialogOpen: boolean
      isCreateTriggerLoading: boolean
      createTriggerName: string
      createTriggerType: string
      editTriggerName: string
      editTriggerType: string
      isEditTriggerDialogOpen: boolean
      isEditTriggerLoading: boolean
      isDeleteTriggerDialogOpen: boolean
      isDeleteTriggerDialogChecked: boolean
      isDeleteTriggerLoading: boolean
      isDeleteSourceDialogOpen: boolean
      isDeleteSourceDialogChecked: boolean
      isDeleteSourceLoading: boolean
      isEditSourceDialogOpen: boolean
      isEditSourceLoading: boolean
      editSourceName: string
      editSourceUrl: string
      isSourceEditFormValid: boolean
      isTriggerEditFormValid: boolean
      isCreateTriggerFormValid: boolean
      API_DOCUMENTATION_URL: string
    }
  },
  computed: {
    customIntegrationType(): CustomIntegrationType {
      const type = this.$route.query.type
      if (!type) {
        throw new Error('Custom integration type is not defined')
      }

      return type as CustomIntegrationType
    },
    sourceMenuItems(): {
      value: string
      label: string
      image: string
    }[] {
      if (this.customIntegrationType !== 'custom') {
        return this.menuItems.filter((item) => item.value !== 'edit')
      }

      return this.menuItems
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    userLocale(): string {
      return this.$store.getters['getUserLocale']
    },
  },
  async created() {
    await this.loadCustomIntegration()
    this.API_DOCUMENTATION_URL = API_DOCUMENTATION_URL
  },
  methods: {
    t(key: string, params?: { [k: string]: string }) {
      return this.$t(`ManageCustomIntegrationView.${key}`, params ?? {})
    },
    dateFormatFilter: function (value, userLocale) {
      if (!value) return ''
      value = value.toString()
      return new Date(value).toLocaleDateString(userLocale || 'en')
    },
    async loadCustomIntegration() {
      this.loading = true
      try {
        const { data } = await getCustomIntegration(this.customIntegrationId)
        this.setCustomIntegration(data)
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: e?.message,
          isError: true,
        } as Notification)
      } finally {
        this.loading = false
      }
    },
    clickOnBack() {
      this.$router.back()
    },
    resetState() {
      if (this.isCreateTriggerDialogOpen) {
        const createTriggerForm = this.$refs.createTriggerForm
        if (createTriggerForm) (createTriggerForm as HTMLFormElement).resetValidation()
      }
      this.isCreateTriggerDialogOpen = false
      this.isCreateTriggerLoading = false
      this.createTriggerName = ''
      this.createTriggerType = ''
      this.isEditTriggerDialogOpen = false
      this.isEditTriggerLoading = false
      this.editTriggerName = ''
      this.editTriggerType = ''
      this.isDeleteTriggerDialogOpen = false
      this.isDeleteTriggerDialogChecked = false
      this.isDeleteTriggerLoading = false
      this.isDeleteSourceDialogOpen = false
      this.isDeleteSourceDialogChecked = false
      this.isDeleteSourceLoading = false
      this.isEditSourceDialogOpen = false
      this.isEditSourceLoading = false
      this.editSourceName = ''
    },
    async quickMenuClickTrigger(value: string, item: CustomIntegrationTrigger) {
      switch (value) {
        case 'edit':
          this.selectedTrigger = item
          this.editTriggerName = item.name
          this.editTriggerType = item.type.charAt(0).toUpperCase() + item.type.slice(1)
          this.isEditTriggerDialogOpen = true
          break
        case 'delete':
          this.selectedTrigger = item
          this.isDeleteTriggerDialogOpen = true
          break
      }
    },
    async quickMenuClickSource(value: string) {
      switch (value) {
        case 'edit':
          this.editSourceName = this.customIntegration.source
          this.editSourceUrl = this.customIntegration.imageUrl
          this.isEditSourceDialogOpen = true
          break
        case 'delete':
          this.isDeleteSourceDialogOpen = true
          break
      }
    },
    async createTrigger() {
      this.isCreateTriggerLoading = true
      try {
        this.createTriggerType =
          this.createTriggerType.charAt(0).toLowerCase() + this.createTriggerType.slice(1)
        const { data } = await createCustomIntegrationTrigger(
          this.customIntegrationId,
          this.createTriggerName,
          this.createTriggerType,
        )
        this.setCustomIntegration(data)
        this.resetState()
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: e?.message,
          isError: true,
        } as Notification)
      } finally {
        this.isCreateTriggerLoading = false
      }
    },
    async editTrigger() {
      this.isEditTriggerLoading = true
      try {
        this.editTriggerType =
          this.editTriggerType.charAt(0).toLowerCase() + this.editTriggerType.slice(1)
        const { data } = await updateCustomIntegrationTrigger(
          this.customIntegrationId,
          this.selectedTrigger._id,
          this.editTriggerName,
          this.editTriggerType,
        )
        this.setCustomIntegration(data)
        this.resetState()
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: e?.message,
          isError: true,
        } as Notification)
      } finally {
        this.isEditTriggerLoading = false
      }
    },
    async deleteTrigger() {
      this.isDeleteTriggerLoading = true
      try {
        const { data } = await deleteCustomIntegrationTrigger(
          this.customIntegrationId,
          this.selectedTrigger._id,
        )
        this.setCustomIntegration(data)
        this.$store.dispatch('notification/notify', {
          text: this.t('notify_delete_trigger', { trigger: this.selectedTrigger.name }),
          error: false,
        } as Notification)
        this.resetState()
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: e?.message,
          isError: true,
        } as Notification)
      } finally {
        this.isDeleteTriggerLoading = false
      }
    },
    async deleteSource() {
      this.isDeleteSourceLoading = true
      try {
        await deleteCustomIntegration(this.customIntegrationId)
        this.resetState()
        this.clickOnBack()
        this.$store.dispatch('notification/notify', {
          text: this.t('notify_delete_source', { source: this.customIntegration.source }),
          isError: false,
        } as Notification)
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: e?.message,
          isError: true,
        } as Notification)
      } finally {
        this.isDeleteSourceLoading = false
      }
    },
    async editSource() {
      this.isEditSourceLoading = true
      try {
        const { data } = await updateCustomIntegration(
          this.customIntegrationId,
          this.editSourceName,
          this.editSourceUrl,
        )
        this.setCustomIntegration(data)
        this.isEditSourceDialogOpen = false
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: e?.message,
          isError: true,
        } as Notification)
      } finally {
        this.isEditSourceLoading = false
      }
    },
    openUploadModal() {
      // TODO: Add css & localisation, error handling
      // https://cloudinary.com/documentation/upload_widget#look_and_feel_customization
      // https://cloudinary.com/documentation/upload_widget#localization

      // All Parameters:
      // https://cloudinary.com/documentation/upload_widget_reference

      if (window.cloudinary) {
        window.cloudinary
          .createUploadWidget(
            {
              cloud_name: process.env.VUE_APP_CLOUDNAME,
              upload_preset: process.env.VUE_APP_UPLOAD_PRESET,
              folder: 'integrationPics',
              sources: ['local', 'url', 'camera'],
              cropping: true,
              cropping_aspect_ratio: 1,
              croppingShowDimensions: true,
              showSkipCropButton: true,
              showPoweredBy: false,
              clientAllowedFormats: ['png', 'jpg', 'jpeg'],
            },
            (error, result) => {
              if (!error && result && result.event === 'success') {
                this.editSourceUrl = result.info.url
              }
            },
          )
          .open()
      }
    },
    copyToClipboard(obj: CustomIntegrationTriggerView | CustomIntegrationView) {
      navigator.clipboard.writeText(obj._id).then(() => {
        obj.isCopied = true
        setTimeout(() => {
          obj.isCopied = false
        }, 2000)
      })
    },
    setCustomIntegration(data: CustomIntegrationResponse) {
      this.customIntegration = {
        ...data,
        isCopied: false,
        triggers: data.triggers.map((trigger) => {
          return {
            ...trigger,
            isCopied: false,
          }
        }),
      }
    },
  },
  props: {
    customIntegrationId: { type: String as PropType<string>, required: true },
  },
})

interface CustomIntegrationTriggerView extends CustomIntegrationTrigger {
  isCopied: boolean
}

interface CustomIntegrationView extends Omit<CustomIntegrationResponse, 'triggers'> {
  isCopied: boolean
  triggers: CustomIntegrationTriggerView[]
}
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 10px;
  color: var(--ui-black);
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.text {
  color: var(--ui-black);
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 20px;
}

.back {
  display: flex;
  font-weight: 500;
  color: var(--ui-green);
  cursor: pointer;
  margin-bottom: 7px;
  width: 70px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 3px;
  text-transform: capitalize;
}

.chevron {
  color: var(--ui-green);
}

.wrapper {
  background: var(--ui-white);
  border-radius: 5px;
  padding: 20px;
  box-shadow: var(--box-shadow);
}

.source-icon {
  height: 40px;
  margin-right: 10px;
}

.back {
  display: flex;
  font-weight: 500;
  color: var(--ui-green);
  cursor: pointer;
  margin-bottom: 7px;
  width: 70px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 3px;
}

.table-wrapper {
  border: 1px solid var(--gray-light);
  border-radius: 10px;
  background-color: white;
  margin-bottom: 40px;
  overflow: hidden;
}

.table-text-wrapper {
  padding: 16px;
}

.table {
  border-top: 1px solid var(--gray-light);
  border-radius: 0;
}

.table th {
  padding: 16px 16px;
  background: none !important;
}

.table tr:hover {
  background: none !important;
}

.create.button {
  position: relative;
  flex: 1 1 100%;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
}

.delete.button {
  position: relative;
  flex: 1 1 50%;
  margin-left: 8px;
  margin-right: 20px;
  margin-bottom: 16px;
}

.cancel.button {
  position: relative;
  flex: 1 1 50%;
  margin-right: 8px;
  margin-bottom: 16px;
}

.business-logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
</style>
