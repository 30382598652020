import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a1074138"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "dialog-wrapper"
}
const _hoisted_2 = { class: "close-wrapper" }
const _hoisted_3 = { class: "dialog-title" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "dialog-text" }
const _hoisted_6 = { class: "button-wrapper" }
const _hoisted_7 = {
  key: 0,
  class: "button-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('stop')), 1),
            _createElementVNode("img", {
              style: {"width":"25px"},
              class: "close-icon",
              src: require('@/assets/icons/close_icon.svg'),
              alt: "close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dialogClick')))
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('text')), 1),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_v_btn, {
              class: "button",
              color: "#E57066",
              height: "54",
              variant: "flat",
              width: "100%",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.stop()))
            }, {
              default: _withCtx(() => [
                (!_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.t('stop')), 1))
                  : _createCommentVNode("", true),
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                      key: 1,
                      indeterminate: "",
                      color: "#F9F9F9"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}