import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54792d82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "topstat-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "stat carbon-card",
  style: {"margin-left":"0px"}
}
const _hoisted_4 = { class: "text-number-wrapper" }
const _hoisted_5 = { class: "number" }
const _hoisted_6 = {
  key: 1,
  class: "stat plastic-card"
}
const _hoisted_7 = { class: "text-number-wrapper" }
const _hoisted_8 = { class: "number" }
const _hoisted_9 = {
  key: 2,
  class: "stat tree-card"
}
const _hoisted_10 = { class: "text-number-wrapper" }
const _hoisted_11 = { class: "number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.carbon && _ctx.carbon > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.formatTotalAmounts(_ctx.carbon)) + " " + _toDisplayString(_ctx.t('carbon')), 1),
                  _createElementVNode("div", null, _toDisplayString(_ctx.fundingType === 'oneTimeImpact' ? _ctx.t('co2_once') : _ctx.t('co2')), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.plastic && _ctx.plastic > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.formatTotalAmounts(_ctx.plastic)) + " " + _toDisplayString(_ctx.t('plastic')), 1),
                  _createElementVNode("div", null, _toDisplayString(_ctx.fundingType === 'oneTimeImpact' ? _ctx.t('plastic_bottles_once') : _ctx.t('plastic_bottles')), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.trees && _ctx.trees > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.formatTotalAmounts(_ctx.trees)) + " " + _toDisplayString(_ctx.t('tree')), 1),
                  _createElementVNode("div", null, _toDisplayString(_ctx.fundingType === 'oneTimeImpact' ? _ctx.t('trees_once') : _ctx.t('trees')), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}