import type { OffsetType, TotalImpact } from '@/helpers/interfaces'
import { OFFSET_TYPES } from '@/helpers/interfaces'
import { Decimal } from 'decimal.js'

export const parseImpactsArrayToObject = (impacts: TotalImpact[]): Record<OffsetType, number> => {
  const map = new Map<OffsetType, number>()
  for (const type of OFFSET_TYPES) {
    map.set(type, 0)
  }
  for (const impact of impacts) {
    map.set(impact.type, new Decimal(map.get(impact.type) || 0).add(impact.amount).toNumber())
  }

  return Object.fromEntries(map.entries()) as Record<OffsetType, number>
}
