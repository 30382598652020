import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23c6bdca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "path-wrapper" }
const _hoisted_2 = { class: "left-image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "content-header" }
const _hoisted_6 = { class: "content-text" }
const _hoisted_7 = { class: "button-text" }
const _hoisted_8 = { class: "left-image" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "content" }
const _hoisted_11 = { class: "content-header" }
const _hoisted_12 = { class: "content-text" }
const _hoisted_13 = { class: "button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "path",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('progressItemClick', 1)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          class: "icon",
          src: require('@/assets/Network/referral.svg'),
          alt: "referral"
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('referral')), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('referral_text')), 1)
        ]),
        _createVNode(_component_v_btn, {
          class: "button",
          color: "#3B755F",
          height: "34",
          width: "143"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('button_text')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", {
      class: "path",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('progressItemClick', 2)))
    }, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("img", {
          class: "icon",
          src: require('@/assets/Network/partnership.svg'),
          alt: "referral"
        }, null, 8, _hoisted_9)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.t('partnership')), 1),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.t('partnership_text')), 1)
        ]),
        _createVNode(_component_v_btn, {
          class: "button",
          color: "#3B755F",
          height: "34",
          width: "143"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.t('button_text')), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}