<template>
  <div :class="['reference-box', theme]">
    <h3 class="reference-box-title">
      {{ t('title') }}
    </h3>
    <ul class="reference-box-companies-list">
      <li
        v-for="(image, index) in clientLogos"
        :key="index"
        class="reference-box-company-list-item"
      >
        <img class="company-logo" :src="image" alt="" />
      </li>
    </ul>
    <ul class="reasons-to-join-list">
      <li class="reasons-to-join-list-item">
        <div class="reason-icon">
          <img :src="require('@/assets/icons/checkout/chartIncrease.svg')" alt="" />
        </div>
        <div class="text-wrapper">
          <p class="reason-title">
            {{ t('increase_title') }}
          </p>
          <p class="reason-text">
            {{ t('increase_text') }}
          </p>
        </div>
      </li>
      <li class="reasons-to-join-list-item">
        <div class="reason-icon">
          <img :src="require('@/assets/icons/checkout/chartRise.svg')" alt="" />
        </div>

        <div class="text-wrapper">
          <p class="reason-title">
            {{ t('rise_title') }}
          </p>
          <p class="reason-text">
            {{ t('rise_text') }}
          </p>
        </div>
      </li>
      <li class="reasons-to-join-list-item">
        <div class="reason-icon">
          <img :src="require('@/assets/icons/checkout/chartMore.svg')" alt="" />
        </div>

        <div class="text-wrapper">
          <p class="reason-title">
            {{ t('more_title') }}
          </p>
          <p class="reason-text">
            {{ t('more_text') }}
          </p>
        </div>
      </li>
      <li class="reasons-to-join-list-item">
        <div class="reason-icon">
          <img :src="require('@/assets/icons/checkout/chartUplift.svg')" alt="" />
        </div>

        <div class="text-wrapper">
          <p class="reason-title">
            {{ t('uplift_title') }}
          </p>
          <p class="reason-text">
            {{ t('uplift_text') }}
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export type ReferenceBoxTheme = 'lightgreen' | 'beige'
export default defineComponent({
  name: 'ReferenceBox',
  data() {
    const clientLogos: string[] = []

    return {
      clientLogos,
    }
  },
  mounted() {
    this.importAll()
  },
  methods: {
    t(key: string) {
      return this.$t(`ReferenceBox.${key}`)
    },
    importAll() {
      const requireContext = require.context('@/assets/images/clients/', true, /\.png$/)
      requireContext.keys().forEach((key) => this.clientLogos.push(requireContext(key)))
    },
  },
  props: {
    theme: {
      default: 'lightgreen',
      type: String as PropType<ReferenceBoxTheme>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.reference-box {
  width: 100%;
  padding: 36px;
  max-width: 590px;
  display: flex;
  flex-direction: column;

  &.lightgreen {
    border-radius: var(--border-radius-big);
    border: 2px solid var(--ui-green-light);
  }

  &.beige {
    border: none;
    border-radius: 0;
    background-color: var(--ui-green-light);

    .reference-box-title {
      font-size: 24px;
      line-height: 30px;
      max-width: 22ch;
    }

    .reference-box-companies-list {
      gap: 10px;
    }

    .reference-box-company-list-item {
      max-width: 110px;
    }

    .reason-icon {
      background: url('../../assets/backgrounds/icon-background-beige.png') center/contain no-repeat;
    }

    .reasons-to-join-list-item {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    .reasons-to-join-list {
      border: 2px solid var(--ui-beige);
      border-radius: var(--border-radius-big);
      padding: 20px;
    }
  }
}

.reference-box-title {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  max-width: 27ch;
  margin: 0 auto 14px;
  text-transform: uppercase;
  flex-grow: 0;
  flex-shrink: 0;
}

.reference-box-companies-list {
  list-style-type: none;
  display: flex;
  gap: 5px;
  padding: 0;
  margin-bottom: 24px;
  justify-content: center;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
}

.reference-box-company-list-item {
  max-width: 75px;
}

.company-logo {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.reasons-to-join-list {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  padding: 0;
  flex-grow: 1;
  flex-shrink: 0;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.reasons-to-join-list-item {
  display: flex;
  gap: 10px;
  align-items: center;
}

.reason-icon {
  background: url('../../assets/backgrounds/icon-background.png') center/contain no-repeat;
  height: 55px;
  width: 55px;
  font-size: 32px;
  color: var(--ui-green);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.reason-title {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}

.reason-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0;
}
</style>
