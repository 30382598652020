<template>
  <div>
    <v-menu
      :open-on-hover="!isTouch"
      location="top"
      offset="5 100"
      close-delay="250"
      content-class="elevation-3"
    >
      <template v-slot:activator="{ props }">
        <img
          class="icon"
          :src="require('@/assets/icons/info.svg')"
          :style="iconStyle ? iconStyle : ''"
          alt="tooltip icon"
          v-bind="props"
        />
      </template>
      <div class="tooltip-content">
        <div class="text">
          {{ body }}
        </div>
        <a v-if="link" class="link" @click="linkClick()">{{ linkText }}</a>
        <div v-if="warningText" class="warning-text">
          {{ warningText }}
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script lang="ts">
import type { TranslateResult } from 'vue-i18n'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Tooltip',
  mixins: [Utils],
  methods: {
    t(key: string) {
      return this.$t(`Tooltip.${key}`)
    },
    linkClick() {
      window.open(`${this.link}`, '_blank')
    },
  },
  props: {
    body: { required: true, type: String as () => TranslateResult },
    linkText: { required: false, type: String },
    link: { required: false, type: String },
    iconStyle: { required: false, type: String },
    warningText: { required: false, type: String as () => TranslateResult },
  },
})
</script>

<style lang="scss" scoped>
.tooltip-content {
  width: 240px;
  min-height: 100px;
  background: #f9f9f9;
  text-align: center;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
}

.link {
  color: #3b755f;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

.text {
  padding-bottom: 5px;
  color: #757d82;
  font-size: 14px;
}

.small-icon {
  font-weight: 600;
  font-size: 16px;
}

.warning-text {
  color: var(--ui-red);
  margin-top: 12px;
  font-size: 14px;
}
</style>
