<template>
  <div class="user-component">
    <div class="user-component-wrapper">
      <template v-if="$route.name === 'DirectMarketplaceSignup'">
        <ReferenceBox class="reference-box" theme="beige" />
      </template>
      <img
        v-else
        alt="signup"
        class="user-image"
        :src="require('@/assets/create-account-shape.jpg')"
      />
      <div class="user-wrapper">
        <div class="back" @click="$emit('backClick')">
          <img
            alt="chevron"
            class="chevron"
            :src="require('@/assets/icons/chevron_left.svg')"
            @click="$emit('backClick')"
          />
          <div>
            {{ t('back') }}
          </div>
        </div>
        <div class="form-wrapper">
          <div class="header">
            {{ t('create_account') }}
          </div>
          <v-form v-model="isFormValid">
            <div class="name-wrapper">
              <div class="text-field-wrapper">
                <v-text-field
                  id="Email"
                  v-model="userObject.email"
                  :label="t('email')"
                  autocomplete="email"
                  class="input-field"
                  :rules="[rules.email, rules.required]"
                  color="green"
                  flat
                  hide-details
                  name="Email"
                  variant="outlined"
                  type="email"
                  @keyup.enter="buttonActive && !loading ? signup : ''"
                />
              </div>
            </div>

            <div class="name-wrapper">
              <div class="text-field-wrapper">
                <v-text-field
                  id="Password"
                  v-model="userObject.password"
                  :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :label="t('password')"
                  :type="showPassword ? 'text' : 'password'"
                  autocomplete="password"
                  class="input-field"
                  color="green"
                  :rules="[rules.password, rules.required]"
                  flat
                  hide-details
                  name="password"
                  variant="outlined"
                  @click:append-inner="showPassword = !showPassword"
                  @keyup.enter="buttonActive && !loading ? signup : ''"
                />
              </div>
            </div>

            <div
              v-if="account.emailAlreadyUsed"
              :style="fromAppMarketplace ? 'background: #2E3A8C' : ''"
              class="error-message"
            >
              {{ !fromAppMarketplace ? t('already_used') : t('already_account') }}
              <span
                v-if="fromAppMarketplace"
                class="login-link"
                @click="$router.push('/login?app')"
                >{{ t('login') }}</span
              >
            </div>

            <div
              :style="hasPasswordError ? 'background: red; color: #F9F9F9' : ''"
              class="password-hint"
            >
              {{ t('password_hint') }}
            </div>

            <div class="checkbox-wrapper">
              <v-checkbox
                v-model="checkbox"
                color="#3B755F"
                hide-details
                :rules="[rules.required]"
                style="margin: 0"
              />
              <div class="terms">
                Accept our <span class="link" @click="openUrl('terms')">Terms & Conditions</span> &
                <span class="link" @click="openUrl('privacy')">Privacy Policy</span>
              </div>
            </div>
            <template v-if="$route.name === 'DirectMarketplaceSignup'">
              <AddPromoCode @setPromoCode="setPromoCode" />
            </template>
            <div class="button-wrapper">
              <gs-button
                :disabled="!isFormValid || loading"
                :loading="loading"
                :full-width="true"
                size="large"
                @click.prevent="signup"
              >
                {{ fromAppMarketplace ? t('continue') : t('continue_to_payment') }}
              </gs-button>
            </div>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { createAccount, usePromoCode, userRegistration } from '@api/index'
import { PRIVACY_POLICY_URL, TERMS_CONDITIONS_URL } from '@/helpers/constants'
import ReferenceBox from '@/components/register/ReferenceBox.vue'
import type { Account } from '@/store'
import AddPromoCode from '@/components/checkout/AddPromoCode.vue'
import type { User } from '@/store/users'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { Clearable } from '@/helpers/interfaces'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Signup1',
  emits: ['backClick', 'setLineItemsClick', 'checkoutClick'],
  components: {
    AddPromoCode,
    ReferenceBox,
  },
  mixins: [RulesMixin],
  data() {
    const isFormValid: Clearable<boolean> = null

    return {
      userObject: {
        name: '',
        email: '',
        password: '',
      },
      showPassword: false,
      loading: false,
      buttonActive: false,
      checkbox: false,
      accountType: '',
      isFormValid,
      fromAppMarketplace: false,
      userAlreadyHasAccount: false,
      validatedPromoCode: '',
    }
  },
  computed: {
    accountName(): string {
      return this.accountType === 'ecommerce'
        ? this.account.companyName
        : this.currentUser.firstName + ' ' + this.currentUser.lastName
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
    hasPasswordError(): boolean {
      return Boolean(this.userObject.password) && !this.rules.password(this.userObject.password)
    },
  },
  created() {
    this.setUserType()
    this.setEmailAlreadyUsed(false)
    this.setSignupComplete(false)
    if (!this.fromAppMarketplace) {
      this.$emit('setLineItemsClick')
    }
    this.checkIfEntered()
    if (this.currentUser.email !== '') {
      this.userObject.email = this.currentUser.email
    }
  },
  methods: {
    t(key: string) {
      return this.$t(`Signup.${key}`)
    },
    setUserType() {
      switch (this.$route.name) {
        case 'Signup':
          this.accountType = 'business'
          break
        case 'SignupEcommerce':
        case 'SignupInvite':
          this.accountType = 'ecommerce'
          break
        case 'SignupPersonal':
          this.accountType = 'personal'
          break
        case 'SignupFamily':
          this.accountType = 'family'
          break
        case 'DirectMarketplaceSignup':
          this.accountType = 'ecommerce'
          this.fromAppMarketplace = true
          break
      }
    },
    checkIfEntered() {
      if (this.currentUser.email !== '' && this.currentUser.email !== ' ') {
        this.userObject.email = this.currentUser.email
      }
    },
    async signup() {
      this.loading = true
      this.updateCurrentUser({ email: this.userObject.email })

      const payload = {
        email: this.userObject.email,
        password: this.userObject.password,
        name: this.accountName,
      }
      // register user with firebase, if user already exists, this will commit emailAlreadyUsed to true in the store
      await userRegistration(payload)

      if (!this.account.emailAlreadyUsed) {
        switch (this.accountType) {
          case 'ecommerce':
          case 'business':
            {
              this.userAlreadyHasAccount = false

              const planPayload = {
                standard: this.account.employeePlans.standard || 0,
                superior: this.account.employeePlans.superior || 0,
                premium: this.account.employeePlans.premium || 0,
              }

              const treePlanter = this.account.employeePlanAddons.treePlanter || 0

              const plasticHero = this.account.employeePlanAddons.plasticHero || 0

              const livePageId = Date.now() + Math.floor(Math.random() * 1000)
              let widgetFolderName =
                this.account.companyName + Math.floor(Math.random() * 100).toString()
              widgetFolderName = widgetFolderName.replace(/\s/g, '-').toLowerCase()

              const payload1 = {
                user: {
                  accountType: this.accountType,
                  email: this.userObject.email,
                  vatId: this.account.vatId,
                  companyName: this.account.companyName,
                  firstName: this.currentUser.firstName,
                  lastName: this.currentUser.lastName,
                  accountId: this.currentUser.id,
                  employeePlans: planPayload,
                  employeePlanAddons: {
                    treePlanter: treePlanter,
                    plasticHero: plasticHero,
                  },
                  signupDate: Date.now(),
                  termsAccept: this.checkbox,
                  currency: this.account.currency,
                  livePageId: livePageId,
                  widgetFolderName: widgetFolderName,
                  accountFromMarketplace: this.fromAppMarketplace,
                  address: {
                    street: this.account.address.street,
                    postCode: this.account.address.postCode,
                    city: this.account.address.city,
                    country:
                      this.location !== '' ? this.location ?? '' : this.account.address.country,
                  },
                },
              }

              await createAccount(payload1)
              if (this.validatedPromoCode) await usePromoCode(this.validatedPromoCode)
            }
            break
          case 'personal':
            {
              const planPayload = {
                personal: this.account.personalPlans.personal || 0,
                plasticHero: this.account.personalPlans.plasticHero || 0,
                earthHero: this.account.personalPlans.earthHero || 0,
              }

              const treeAddon = this.account.personalPlanAddons.treeAddon || 0

              const plasticAddon = this.account.personalPlanAddons.plasticAddon || 0

              const livePageId = Date.now() + Math.floor(Math.random() * 1000)

              const payload1 = {
                user: {
                  accountType: 'personal',
                  email: this.userObject.email,
                  companyName: this.account.companyName,
                  firstName: this.currentUser.firstName,
                  lastName: this.currentUser.lastName,
                  accountId: this.currentUser.id,
                  personalPlans: planPayload,
                  personalPlanAddons: {
                    treeAddon: treeAddon,
                    plasticAddon: plasticAddon,
                  },
                  signupDate: Date.now(),
                  termsAccept: this.checkbox,
                  currency: this.account.currency,
                  livePageId: livePageId,
                  address: {
                    street: this.account.address.street,
                    postCode: this.account.address.postCode,
                    city: this.account.address.city,
                    country: this.account.address.country,
                  },
                  widgets: [''],
                },
              }
              await createAccount(payload1)
              await this.setOnboardingSkipped()
            }
            break
          case 'family':
            {
              const planPayload = {
                positiveFamilyAdult: this.account.personalPlans.positiveFamilyAdult || 0,
                positiveFamilyChild: this.account.personalPlans.positiveFamilyChild || 0,
                positiveFamilyPet: this.account.personalPlans.positiveFamilyPet || 0,
                earthPositiveFamilyAdult: this.account.personalPlans.earthPositiveFamilyAdult || 0,
                earthPositiveFamilyChild: this.account.personalPlans.earthPositiveFamilyChild || 0,
                earthPositiveFamilyPet: this.account.personalPlans.earthPositiveFamilyPet || 0,
              }

              const treeAddon = this.account.personalPlanAddons.treeAddon || 0

              const livePageId = Date.now() + Math.floor(Math.random() * 1000)

              const payload1 = {
                user: {
                  accountType: 'family',
                  email: this.userObject.email,
                  companyName: this.account.companyName,
                  firstName: this.currentUser.firstName,
                  lastName: this.currentUser.lastName,
                  accountId: this.currentUser.id,
                  personalPlans: planPayload,
                  personalPlanAddons: {
                    treeAddon: treeAddon,
                  },
                  signupDate: Date.now(),
                  termsAccept: this.checkbox,
                  currency: this.account.currency,
                  livePageId: livePageId,
                  address: {
                    street: this.account.address.street,
                    postCode: this.account.address.postCode,
                    city: this.account.address.city,
                    country: this.account.address.country,
                  },
                  widgets: [''],
                },
              }
              await createAccount(payload1)
              await this.setOnboardingSkipped()
            }
            break
        }
        this.checkoutClick()
      }
      if (this.fromAppMarketplace && this.account.emailAlreadyUsed) {
        this.userAlreadyHasAccount = true
      }
      this.loading = false
    },
    openUrl(value) {
      switch (value) {
        case 'terms':
          window.open(TERMS_CONDITIONS_URL, '_blank')
          break
        case 'privacy':
          window.open(PRIVACY_POLICY_URL, '_blank')
          break
      }
    },
    checkoutClick() {
      this.$emit('checkoutClick')
    },
    setPromoCode(promoCode: string): void {
      this.validatedPromoCode = promoCode
    },
    onEmailAlreadyUsedChange() {
      this.loading = false
    },
    updateCurrentUser(user: Partial<User>): Promise<void> {
      return this.$store.dispatch('updateCurrentUser', user)
    },
    setOnboardingSkipped(): Promise<void> {
      return this.$store.dispatch('setOnboardingSkipped')
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
    setEmailAlreadyUsed(emailAlreadyUsed) {
      return this.$store.commit('setEmailAlreadyUsed', emailAlreadyUsed)
    },
    setSignupComplete(signupComplete) {
      return this.$store.commit('setSignupComplete', signupComplete)
    },
    clearEmailAlreadyUsedFeedback() {
      if (!this.account.emailAlreadyUsed || this.fromAppMarketplace) return

      this.setEmailAlreadyUsed(false)
    },
  },
  props: {
    location: {
      type: String,
    },
  },
  watch: {
    'account.emailAlreadyUsed': [
      {
        handler: 'onEmailAlreadyUsedChange',
      },
    ],
    'userObject.email': [
      {
        handler: 'clearEmailAlreadyUsedFeedback',
      },
    ],
    'userObject.password': [
      {
        handler: 'clearEmailAlreadyUsedFeedback',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.user-component {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.user-component-wrapper {
  width: 100%;
  border-radius: 8px;
  display: flex;
  box-shadow: 0 42px 76px rgba(0, 0, 0, 0.05), 0 27.2222px 44.5093px rgba(0, 0, 0, 0.037963),
    0 16.1778px 24.2074px rgba(0, 0, 0, 0.0303704), 0 8.4px 12.35px rgba(0, 0, 0, 0.025),
    0 3.42222px 6.19259px rgba(0, 0, 0, 0.0196296), 0 0.777778px 2.99074px rgba(0, 0, 0, 0.012037);
}

.user-image {
  width: 400px;
  max-width: 45%;
  max-height: 640px;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.user-wrapper {
  width: 100%;
}

.chevron {
  width: 23px;
  filter: invert(39%) sepia(15%) saturate(1175%) hue-rotate(106deg) brightness(96%) contrast(87%);
}

.back {
  padding-top: 20px;
  padding-left: 20px;
  display: flex;
  font-weight: 500;
  color: #3b755f;
  cursor: pointer;
}

.form-wrapper {
  padding: 36px;
}

.header {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: #212121;
  margin-bottom: 24px;
}

.left-input {
  margin-right: 4px;
}

.right-input {
  margin-left: 4px;
}

.name-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-label {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
  margin-bottom: 4px;
}

.input-description {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #b0b0b0;
  margin-bottom: 12px;
}

.text-field-wrapper {
  display: flex;
  width: 100%;
}

.employee-input {
  min-width: 90%;
  margin-bottom: 14px;
}

.error-message {
  font-size: 16px;
  color: #f9f9f9;
  font-weight: 500;
  text-align: left;
  width: 100%;
  padding: 8px 20px;
  background: red;
}

.password-hint {
  text-align: left;
  width: 100%;
  padding: 8px 20px;
  background: #afc6bd;
  border-radius: 4px;
  margin-bottom: 22px;
  margin-top: 5px;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
}

.checkbox-wrapper {
  display: flex;
  margin: 40px 0 25px 0;
  width: 100%;
}

.terms {
  display: flex;
  align-items: center;
  color: #212121;
  padding-top: 3px;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  flex-wrap: wrap;
}

.link {
  margin-left: 3px;
  margin-right: 3px;
  color: #3b755f;
  font-weight: bold;
}

.button-wrapper {
  margin-top: 20px;
}

.login-link {
  margin-left: 3px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
}

@media #{map-get($display-breakpoints, 'md')} {
  // .user-component-wrapper {
  //   margin-bottom: 20px;
  // }

  .user-image {
    display: none;
  }

  .reference-box {
    display: none;
  }

  .employee-input {
    min-width: 95%;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  // .user-component-wrapper {
  //   margin-bottom: 20px;
  // }

  .user-image {
    display: none;
  }

  .reference-box {
    display: none;
  }

  .text-field-wrapper {
    justify-content: space-between;
  }

  .employee-input {
    min-width: 80%;
    margin-right: 10px;
  }

  .form-wrapper {
    padding: 10px;
  }
}
</style>
