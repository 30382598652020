<template>
  <section class="edit-automation">
    <v-expansion-panels v-model="openedIndex" variant="accordion" flat>
      <!-- Integration -->
      <v-expansion-panel readonly>
        <v-expansion-panel-title hide-actions>
          <div class="panel-header">
            <p class="mb-0 panel-header-text">Integration:</p>
            <div class="pill-wrapper">
              <onboarding-pill>{{
                getIntegrationPlatformName(automation.platform)
              }}</onboarding-pill>
              <onboarding-pill v-if="!isCustomIntegration">
                {{ automation.integrationName }}
              </onboarding-pill>
            </div>
          </div>
        </v-expansion-panel-title>
      </v-expansion-panel>

      <template v-if="isCustomIntegration">
        <!-- Source details-->
        <v-expansion-panel readonly>
          <v-expansion-panel-title hide-actions>
            <template v-slot="{ expanded }">
              <div class="panel-header">
                <p class="mb-0 panel-header-text">Source details</p>
                <div class="pill-wrapper">
                  <onboarding-pill>{{ automation?.sourceDetails?.sourceName }}</onboarding-pill>
                  <onboarding-pill :is-copy-enabled="true">
                    {{ automation?.sourceDetails?.sourceId }}
                  </onboarding-pill>
                </div>
                <button v-if="!isIntegratorIntegration" class="toggle-edit-button">
                  <template v-if="!expanded">
                    Edit
                    <v-icon class="toggle-edit-button__icon"> mdi-pencil-outline </v-icon>
                  </template>
                  <template v-else>
                    Cancel
                    <v-icon class="toggle-edit-button__icon"> mdi-close-circle-outline </v-icon>
                  </template>
                </button>
              </div>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text v-if="!isIntegratorIntegration">
            <div class="automation-name-wrapper section">
              <div class="label-wrapper">
                <p class="label">
                  {{ t('source_label') }}
                </p>
                <p class="description">{{ t('source_tooltip') }}</p>
              </div>
              <v-form v-model="isAutomationNameValid">
                <gs-input
                  v-model="localSourceDetails.sourceName"
                  class="automation-name-input"
                  :label="t('source_name')"
                  :hide-details="false"
                  :rules="[rules.required]"
                />
                <gs-button
                  size="large"
                  full-width
                  :disabled="loading || !isAutomationNameValid"
                  @click.prevent="editSource"
                >
                  {{ t('save') }}
                </gs-button>
              </v-form>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <!-- Source triggers-->
        <v-expansion-panel readonly>
          <v-expansion-panel-title hide-actions>
            <template v-slot="{ expanded }">
              <div class="panel-header">
                <p class="mb-0 panel-header-text">Trigger details</p>
                <div class="pill-wrapper">
                  <onboarding-pill>{{ automation.name }}</onboarding-pill>
                  <onboarding-pill>{{ automation.trigger }}</onboarding-pill>
                  <onboarding-pill :is-copy-enabled="true">
                    {{ automation.id }}
                  </onboarding-pill>
                </div>
                <button class="toggle-edit-button">
                  <template v-if="!expanded">
                    Edit
                    <v-icon class="toggle-edit-button__icon"> mdi-pencil-outline </v-icon>
                  </template>
                  <template v-else>
                    Cancel
                    <v-icon class="toggle-edit-button__icon"> mdi-close-circle-outline </v-icon>
                  </template>
                </button>
              </div>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div class="automation-name-wrapper section">
              <div class="label-wrapper">
                <p class="label">
                  {{ t('trigger_label') }}
                </p>
                <p class="description">{{ t('custom_trigger_name_tooltip') }}</p>
              </div>
              <v-form v-model="isAutomationNameValid">
                <gs-input
                  v-model="localAutomation.name"
                  class="automation-name-input"
                  :label="t('trigger_name')"
                  :hide-details="false"
                  :rules="[rules.required]"
                />
                <div class="label-wrapper">
                  <p class="label">
                    {{ t('trigger_type_label') }}
                  </p>
                  <p class="description">{{ t('trigger_type_help') }}</p>
                </div>
                <v-select
                  v-model="localAutomation.trigger"
                  variant="outlined"
                  flat
                  color="green"
                  :items="triggerTypes"
                  class="automation-name-input"
                  :label="t('trigger_type')"
                  :rules="[rules.required]"
                />
                <gs-button
                  size="large"
                  full-width
                  :disabled="loading || !isAutomationNameValid"
                  @click.prevent="editTrigger"
                >
                  {{ t('save') }}
                </gs-button>
              </v-form>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </template>
      <template v-else>
        <!-- Automation name-->
        <v-expansion-panel readonly>
          <v-expansion-panel-title hide-actions>
            <template v-slot="{ expanded }">
              <div class="panel-header">
                <p class="mb-0 panel-header-text">Automation</p>
                <div class="pill-wrapper">
                  <onboarding-pill>{{ automation.name }}</onboarding-pill>
                  <onboarding-pill>
                    {{
                      $t(
                        `IntegrationMixin.${
                          hasAllProductsModifier
                            ? 'offset_all_products'
                            : snakeCase(automation.trigger)
                        }`,
                      )
                    }}</onboarding-pill
                  >
                  <onboarding-pill v-if="isFormIntegration">
                    {{ formSourceId }}
                  </onboarding-pill>
                </div>
                <button class="toggle-edit-button">
                  <template v-if="!expanded">
                    Edit
                    <v-icon class="toggle-edit-button__icon"> mdi-pencil-outline </v-icon>
                  </template>
                  <template v-else>
                    Cancel
                    <v-icon class="toggle-edit-button__icon"> mdi-close-circle-outline </v-icon>
                  </template>
                </button>
              </div>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div class="automation-name-wrapper section">
              <div class="label-wrapper">
                <p class="label">
                  {{ t('name_automation') }}
                </p>
                <p class="description">{{ t('automation_tooltip') }}</p>
              </div>
              <v-form v-model="isAutomationNameValid">
                <gs-input
                  v-model="localAutomation.name"
                  class="automation-name-input"
                  :label="t('automation_name')"
                  :hide-details="false"
                  :rules="[rules.required]"
                />
                <select-form-impact-source
                  v-if="isFormIntegration"
                  v-model="localFormImpactSource"
                  :platform="automation.platform"
                  :error="formImpactSourceError"
                  class="select-form-impact-source"
                />
                <gs-button
                  size="large"
                  full-width
                  :disabled="loading || !isAutomationNameValid || formImpactSourceError"
                  @click.prevent="saveChanges({ name: localAutomation.name })"
                >
                  {{ t('save') }}
                </gs-button>
              </v-form>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Offset settings -->
        <v-expansion-panel readonly>
          <v-expansion-panel-title hide-actions>
            <template v-slot="{ expanded }">
              <div class="panel-header">
                <p class="mb-0 panel-header-text">Impact</p>
                <div class="pill-wrapper" v-if="automation.offsets.length">
                  <template v-if="automation.trigger !== 'offsetPerProduct'">
                    <onboarding-pill
                      v-for="(offset, index) in accumulatedOffsetsByType"
                      :key="index"
                      mode="impact"
                      :offset-type="offset.type"
                    >
                      <span v-if="!includes(PERCENTAGE_TRIGGERS, automation.trigger)">{{
                        offset.amount
                      }}</span>
                    </onboarding-pill>
                  </template>
                  <onboarding-pill v-if="automation.trigger === 'offsetPerProduct'">
                    {{ t('offset_per_product.impact_per_product') }}
                  </onboarding-pill>
                  <onboarding-pill v-if="showModifierPill">
                    {{ labelForModifier }}
                  </onboarding-pill>
                </div>
                <button class="toggle-edit-button">
                  <template v-if="!expanded">
                    Edit
                    <v-icon class="toggle-edit-button__icon"> mdi-pencil-outline </v-icon>
                  </template>
                  <template v-else>
                    Cancel
                    <v-icon class="toggle-edit-button__icon"> mdi-close-circle-outline </v-icon>
                  </template>
                </button>
              </div>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div
              v-if="automation.trigger === 'perSelectedAnswer'"
              class="impact-settings-per-question-wrapper"
            >
              <ImpactSettingsPerQuestion
                :question-data="formQuestion"
                @save="onImpactSettingsPerQuestionSave"
                @editingChange="onImpactSettingsPerQuestionEditingChange"
              />
              <template v-if="!isImpactSettingPerQuestionEditing">
                <PerSelectedAnswerPostPurchaseEmailNotification />
                <gs-button
                  size="large"
                  full-width
                  :disabled="loading"
                  @click.prevent="saveChangesWithFormQuestions"
                >
                  {{ t('save') }}
                </gs-button>
              </template>
            </div>
            <v-form v-else v-model="isFormValid">
              <div class="select-project-wrapper section">
                <div class="label-wrapper">
                  <p class="label choose">
                    {{ t(`${snakeCase(automation.trigger)}.choose_impact`) }}
                  </p>
                  <p class="description">
                    {{ t(`${snakeCase(automation.trigger)}.choose_impact_tooltip`) }}
                  </p>
                </div>
                <impact-settings
                  v-if="automation.trigger !== 'offsetPerProduct' || hasAllProductsModifier"
                  v-model="localAutomation.offsets"
                  :single-selection="includes(PERCENTAGE_TRIGGERS, automation.trigger)"
                  class="mb-8"
                  @onValidity="setImpactSettingValidity"
                />
                <template
                  v-if="localAutomation.trigger === 'offsetPerProduct' && !hasAllProductsModifier"
                >
                  <ProductTable
                    v-show="showProductsTable"
                    ref="productTable"
                    :automation-id="automation.id"
                    :integration-id="integrationId"
                    :selected-products="selectedProducts"
                    @select-items="(items) => (selectedProducts = items)"
                    @isAllSelected="setIsAllSelected"
                    @save="onImpactSettingsSave"
                  />
                </template>
              </div>
              <div v-if="isAdditionalSettingNeeded" class="additional-impact-setting section">
                <div class="label-wrapper">
                  <p class="label choose">
                    {{ t(`${snakeCase(automation.trigger)}.label`) }}
                  </p>
                  <p class="description">{{ t(`${snakeCase(automation.trigger)}.tooltip`) }}</p>
                </div>
                <template v-if="includes(CURRENCY_MODIFIER_TRIGGERS, automation.trigger)">
                  <div class="input-wrapper">
                    <v-select
                      v-model="localModifier.currency"
                      :items="availableCurrencies"
                      variant="outlined"
                      color="green"
                      item-color="green"
                      class="dropdown-select"
                      hide-details
                    />

                    <gs-input
                      v-model.number="localModifier.amount"
                      label="Amount"
                      type="number"
                      min="0"
                      class="additional-impact-setting-input"
                      :rules="[rules.positiveInteger, rules.required]"
                    />
                  </div>
                </template>
                <template v-if="includes(PERCENTAGE_TRIGGERS, automation.trigger)">
                  <div class="input-wrapper">
                    <gs-input
                      v-model.number="localModifier.amount"
                      label="%"
                      type="number"
                      min="0"
                      class="additional-impact-setting-input"
                      :rules="[rules.positiveInteger, rules.required]"
                    />
                  </div>
                </template>
              </div>
              <div v-if="automation.trigger !== 'offsetPerProduct'" class="totals-wrapper">
                <p v-if="!!cartText" class="cart-items-text">
                  {{ cartText }}
                </p>
                <p v-if="isTotalTextNeeded" class="total-text">
                  {{ totalText }}
                </p>
              </div>
              <gs-button
                size="large"
                full-width
                :disabled="
                  !isFormValid || loading || !isImpactSettingValid || !isOffsetSettingTotalValid
                "
                @click.prevent="saveChanges({ offsets: localAutomation.offsets })"
              >
                {{ saveOffsetSettingButtonText }}
              </gs-button>
            </v-form>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <!-- Schedule settings -->
        <v-expansion-panel readonly>
          <v-expansion-panel-title hide-actions>
            <template v-slot="{ expanded }">
              <div class="panel-header">
                <p class="mb-0 panel-header-text">Schedule</p>
                <div class="pill-wrapper">
                  <onboarding-pill>
                    <span class="schedule-label-wrapper">
                      {{ automation.scheduleLabel }}
                      <v-icon
                        v-if="automation.status === 'active' && !automation.schedule.endDate"
                        class="ongoing-icon"
                      >
                        mdi-all-inclusive
                      </v-icon>
                    </span>
                  </onboarding-pill>
                </div>

                <button class="toggle-edit-button">
                  <template v-if="!expanded">
                    Edit
                    <v-icon class="toggle-edit-button__icon"> mdi-pencil-outline </v-icon>
                  </template>
                  <template v-else>
                    Cancel
                    <v-icon class="toggle-edit-button__icon"> mdi-close-circle-outline </v-icon>
                  </template>
                </button>
              </div>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div class="schedule-picker section">
              <div class="label-wrapper">
                <p class="label choose">
                  {{ t('schedule') }}
                </p>
                <p class="description">{{ t('schedule_tooltip') }}</p>
              </div>
              <DateTimePicker
                v-model="localAutomation.schedule.startDate"
                :min-value="initialDate"
                class="mb-5"
                :clearable="false"
                :max-width="450"
              />

              <p class="mb-1">End date</p>
              <v-checkbox
                id="checkbox-datetime"
                v-model="isEndDateVisible"
                name="checkbox-datetime"
                inline
                label="Set an end date"
                style="margin: 0 0 10px"
                hide-details
              />
              <DateTimePicker
                v-if="isEndDateVisible"
                v-model="localAutomation.schedule.endDate"
                :min-value="localAutomation.schedule.startDate"
                class="mb-5"
                :error="!isScheduleValid"
                :max-width="450"
                @focus="isScheduleValid = true"
                :clearable="false"
              />
              <gs-button
                size="large"
                full-width
                :disabled="!isScheduleValid || loading"
                @click.prevent="saveChanges({ schedule: localAutomation.schedule })"
              >
                {{ t('save') }}
              </gs-button>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
        <!-- Order origin settings -->
        <v-expansion-panel v-if="showOrderOriginModifier" readonly>
          <v-expansion-panel-title hide-actions>
            <template v-slot="{ expanded }">
              <div class="panel-header">
                <p class="mb-0 panel-header-text">Order Origin</p>
                <div class="pill-wrapper">
                  <onboarding-pill>
                    {{ getOrderOriginLabel(orderOriginModifier) }}
                  </onboarding-pill>
                </div>
                <button class="toggle-edit-button">
                  <template v-if="!expanded">
                    Edit
                    <v-icon class="toggle-edit-button__icon"> mdi-pencil-outline </v-icon>
                  </template>
                  <template v-else>
                    Cancel
                    <v-icon class="toggle-edit-button__icon"> mdi-close-circle-outline </v-icon>
                  </template>
                </button>
              </div>
            </template>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <div class="automation-name-wrapper section">
              <select-shopify-order-origin v-model="localOrderOriginModifier" />
              <gs-button
                size="large"
                full-width
                :disabled="loading"
                @click.prevent="
                  saveChanges({
                    modifiers: [{ type: 'orderOrigin', stringValue: localOrderOriginModifier }],
                  })
                "
              >
                {{ t('save') }}
              </gs-button>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>
  </section>
</template>

<script lang="ts">
import type {
  AutomationModifier,
  AutomationOffset,
  AutomationTableData,
} from '@/store/integrations'
import type { ProjectId } from '@/helpers/constants'
import { FORM_INTEGRATION_SOURCES } from '@/helpers/constants'
import OnboardingPill from '@/components/onboarding/OnboardingPill.vue'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type {
  AutomationTrigger,
  BillingTriggerType,
  Clearable,
  CurrencyCode,
  OffsetType,
  ShopifyOrderOrigin,
  StoreIntegrationTrigger,
} from '@/helpers/interfaces'
import { CURRENCY_MODIFIER_TRIGGERS, PERCENTAGE_TRIGGERS } from '@/helpers/interfaces'
import { CUSTOM_SOURCES, INTEGRATOR_SOURCES } from '@/helpers/interfaces'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import ImpactSettings from '@/components/onboarding/ImpactSettings.vue'
import ProductTable from '@/components/onboarding/ProductTable.vue'
import { Decimal } from 'decimal.js'
import { IMPACT_LOWER_PRICE_LIMIT } from '@/helpers/constants'
import { currencySymbols, getCurrencySymbol } from '@/helpers/pricing'
import { IMPACT_TYPE_DEFAULT_PROJECT_ID_MAP } from '@/helpers/projects'
import { includes } from '@/helpers/parsers'
import DateTimePicker from '@/components/common/DateTimePicker.vue'
import type {
  CustomIntegrationTrigger,
  CustomIntegrationTriggerType,
  UpdateAutomationRequestBody,
} from '@api/index'
import { CUSTOM_INTEGRATION_TRIGGER_TYPES } from '@api/index'
import type { TranslateResult } from 'vue-i18n'
import SelectShopifyOrderOrigin from '@/components/integration/SelectShopifyOrderOrigin.vue'
import SelectFormImpactSource from '@/components/integration/SelectFormImpactSource.vue'
import ImpactSettingsPerQuestion from '@/components/onboarding/ImpactSettingsPerQuestion.vue'
import type { FormQuestion, UpdateFormQuestionRequestPayload } from '@/store/form-question'
import PerSelectedAnswerPostPurchaseEmailNotification from '@/components/integration/PerSelectedAnswerPostPurchaseEmailNotification.vue'
import type { Account } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EditAutomation',
  components: {
    PerSelectedAnswerPostPurchaseEmailNotification,
    ImpactSettingsPerQuestion,
    SelectFormImpactSource,
    SelectShopifyOrderOrigin,
    DateTimePicker,
    ProductTable,
    ImpactSettings,
    OnboardingPill,
  },
  mixins: [IntegrationsMixin, Utils, RulesMixin],
  data() {
    return {
      tabIndex: 1,
      localAutomation: {} as AutomationTableData,
      localModifier: {
        amount: null,
        currency: 'USD',
      },
      localOrderOriginModifier: '' as ShopifyOrderOrigin,
      showProductsTable: false,
      selectedProducts: [],
      isAllProductSelected: true,
      loading: false,
      isFormValid: true,
      isAutomationNameValid: true,
      formImpactSourceError: false,
      isEndDateVisible: false,
      isScheduleValid: true,
      isAllProductSelectedTable: false,
      isImpactSettingValid: true,
      localSourceDetails: {
        sourceName: '',
        sourceId: '',
        imageUrl: '',
      },
      triggerTypes: [],
      localFormImpactSource: 'all',
      isImpactSettingPerQuestionEditing: false,
      originalFormQuestion: {} as FormQuestion,
      initialDate: new Date(Date.now()).toISOString(),
      PERCENTAGE_TRIGGERS,
      CURRENCY_MODIFIER_TRIGGERS,
    } as {
      originalFormQuestion: FormQuestion
      localFormImpactSource: string | 'all'
      triggerTypes: { title: TranslateResult; value: string }[]
      localSourceDetails: { sourceName: string; sourceId: string; imageUrl: string }
      selectedProducts: string[]
      localModifier: { amount: Clearable<number>; currency: CurrencyCode }
      localAutomation: AutomationTableData
      tabIndex: Clearable<number>
      localOrderOriginModifier: ShopifyOrderOrigin
      showProductsTable: boolean
      isAllProductSelected: boolean
      loading: boolean
      isFormValid: boolean
      isAutomationNameValid: boolean
      formImpactSourceError: boolean
      isEndDateVisible: boolean
      isScheduleValid: boolean
      isAllProductSelectedTable: boolean
      isImpactSettingValid: boolean
      isImpactSettingPerQuestionEditing: boolean
      initialDate: string
      PERCENTAGE_TRIGGERS
      CURRENCY_MODIFIER_TRIGGERS
    }
  },
  computed: {
    openedIndex: {
      get() {
        return this.tabIndex
      },
      set(index) {
        this.tabIndex = index
        this.updateDetails()
      },
    },
    accumulatedOffsetsByType(): AutomationOffset[] {
      return this.automation.offsets.reduce((acc, { type, amount }) => {
        const item = acc.find((item) => item.type === type)
        const amountToAdd = amount || 0

        if (!item) {
          acc.push({ type, amount: amountToAdd })
        } else {
          item.amount = item.amount ? item.amount + amountToAdd : amountToAdd
        }
        return acc
      }, [] as AutomationOffset[])
    },
    initialAllProductSelectedSetting(): boolean {
      return !!this.automation.modifiers?.some(
        ({ type, booleanValue }) => type === 'globalOffsetPerProduct' && booleanValue,
      )
    },
    isOffsetSettingTotalValid(): boolean {
      if (includes(PERCENTAGE_TRIGGERS, this.automation.trigger)) {
        return true
      } else {
        return this.total >= IMPACT_LOWER_PRICE_LIMIT
      }
    },
    saveOffsetSettingButtonText(): TranslateResult {
      if (!this.isAdditionalSettingNeeded && this.total < IMPACT_LOWER_PRICE_LIMIT) {
        return this.t('min_limit', { limit: this.userCurrencySymbol + IMPACT_LOWER_PRICE_LIMIT })
      } else {
        return this.t('save')
      }
    },
    total(): number {
      let total = 0
      this.localAutomation.offsets.forEach(({ amount, projectId }) => {
        if (!projectId) return
        total = new Decimal(this.getProjectPriceById(projectId as ProjectId))
          .times(amount || 0)
          .add(total)
          .toNumber()
      })

      return total
    },
    isAdditionalSettingNeeded(): boolean {
      return includes(
        [...PERCENTAGE_TRIGGERS, ...CURRENCY_MODIFIER_TRIGGERS],
        this.automation.trigger as AutomationTrigger,
      )
    },
    availableCurrencies(): {
      title: string
      value: CurrencyCode
    }[] {
      return Object.entries(currencySymbols).map(([key, value]) => {
        return {
          title: value,
          value: key as CurrencyCode,
        }
      })
    },
    integrationId(): string {
      return this.getIntegrationIdByAutomationId(this.selectedAutomationId ?? '')
    },
    isCustomIntegration(): boolean {
      return includes([...CUSTOM_SOURCES, ...INTEGRATOR_SOURCES], this.automation.platform)
    },
    isIntegratorIntegration(): boolean {
      return includes(INTEGRATOR_SOURCES, this.automation.platform)
    },
    automation(): AutomationTableData {
      return this.getAutomationById(this.selectedAutomationId ?? '')
    },
    showModifierPill(): boolean {
      return (
        this.automation.modifiers?.some(
          ({ type }) => type !== 'orderOrigin' && type !== 'globalOffsetPerProduct',
        ) || false
      )
    },
    showOrderOriginModifier(): boolean {
      return (
        this.automation.platform === 'shopify' &&
        !!this.automation.modifiers?.some(({ type }) => type === 'orderOrigin')
      )
    },
    labelForModifier(): string {
      if (this.automation.modifiers?.find((m) => m.type === 'currencyCode'))
        return `${getCurrencySymbol(this.localModifier.currency)} ${this.localModifier.amount} `
      if (this.automation.modifiers?.find((m) => m.type === 'percentage'))
        return `${this.localModifier.amount}%`
      return ''
    },
    orderOriginModifier(): ShopifyOrderOrigin {
      return (
        (this.automation.modifiers?.find(({ type }) => type === 'orderOrigin')
          ?.stringValue as ShopifyOrderOrigin) || 'all'
      )
    },
    offsetSettingsForCart(): { [key in OffsetType]: number } {
      return this.localAutomation.offsets.reduce((acc, { amount, type }) => {
        const amountToAdd = amount || 0
        return {
          ...acc,
          [type]: acc[type] ? acc[type] + amountToAdd : amountToAdd,
        }
      }, {} as { [key in OffsetType]: number })
    },
    cartText(): TranslateResult {
      return this.getCartText(this.offsetSettingsForCart, this.automation.trigger, {
        amount: this.localModifier.amount,
        currencyCode: this.localModifier.currency,
        percentage: this.localModifier.amount,
      })
    },
    totalText(): TranslateResult {
      const params: { [key: string]: string } = {
        price: `${this.userCurrencySymbol}${this.total} ${this.getUserCurrencyCode}`,
      }
      if (includes(CURRENCY_MODIFIER_TRIGGERS, this.automation.trigger)) {
        params.spend_level = `${getCurrencySymbol(this.localModifier.currency || 'USD')}${
          this.localModifier.amount || 0
        }`
      }
      return this.t(`${this.snakeCase(this.automation.trigger)}.total`, params)
    },
    isTotalTextNeeded(): boolean {
      return !includes(PERCENTAGE_TRIGGERS, this.automation.trigger)
    },
    isFormIntegration(): boolean {
      return includes(FORM_INTEGRATION_SOURCES, this.automation.platform)
    },
    formSourceId(): string | TranslateResult {
      if (!this.allFormsModifierEnabled && !this.automation.source?.ids?.length)
        throw new Error('Form source id is not defined')
      return this.allFormsModifierEnabled || !this.automation.source?.ids?.[0]
        ? this.t(`${this.snakeCase(this.automation.trigger)}.all`)
        : this.automation.source?.ids?.[0]
    },
    allFormsModifierEnabled(): boolean {
      return !!this.automation.modifiers?.some(
        ({ type, booleanValue }) => type === 'allForms' && booleanValue,
      )
    },
    formQuestion(): FormQuestion {
      return this.$store.getters['getFormQuestion']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    userCurrencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
    getStoreAccessTokenByStoreType(): (storeType: string) => string {
      return this.$store.getters['getStoreAccessTokenByStoreType']
    },
    getCustomIntegrationTriggerListById(): (
      customIntegrationId: string,
    ) => CustomIntegrationTrigger[] {
      return this.$store.getters['getCustomIntegrationTriggerListById']
    },
    getAutomationById(): (automationId: string) => AutomationTableData {
      return this.$store.getters['getAutomationTableDataById']
    },
    getIntegrationIdByAutomationId(): (automationId: string) => string {
      return this.$store.getters['getIntegrationIdByAutomationId']
    },
    getProjectPriceById(): (projectId: ProjectId) => number {
      return this.$store.getters['getProjectPriceById']
    },
    hasAllProductsModifier(): boolean | undefined {
      return this.automation.modifiers?.some(
        (modifier) => modifier.type === 'globalOffsetPerProduct' && modifier.booleanValue,
      )
    },
  },
  async created() {
    this.resetFormQuestion()
    if (this.isIntegratorIntegration) this.openedIndex = 2
    this.localAutomation = {
      ...this.automation,
      schedule: { ...this.automation.schedule },
      offsets: [
        ...this.automation.offsets.map((offset) => ({
          ...offset,
          projectId: offset.projectId || IMPACT_TYPE_DEFAULT_PROJECT_ID_MAP[offset.type],
        })),
      ],
    }
    this.setLocalModifier()
    this.isAllProductSelected = this.initialAllProductSelectedSetting
    this.showProductsTable = !this.initialAllProductSelectedSetting
    this.localOrderOriginModifier =
      (this.automation.modifiers?.find(({ type }) => type === 'orderOrigin')
        ?.stringValue as ShopifyOrderOrigin) || 'all'
    this.isEndDateVisible = !!this.automation.schedule.endDate
    this.localSourceDetails = this.automation.sourceDetails || {
      sourceName: '',
      sourceId: '',
      imageUrl: '',
    }
    this.triggerTypes = CUSTOM_INTEGRATION_TRIGGER_TYPES.map((triggerType) => {
      return {
        title: this.t(this.snakeCase(triggerType)),
        value: triggerType,
      }
    })

    this.localFormImpactSource =
      this.allFormsModifierEnabled || !this.automation.source?.ids?.[0]
        ? 'all'
        : this.automation.source?.ids?.[0]

    if (this.automation.trigger === 'perSelectedAnswer') {
      this.originalFormQuestion = await this.loadFormQuestion({
        automationTableData: { ...this.automation },
      })
    }
  },
  methods: {
    t(key: string, params?: Record<string, unknown>): TranslateResult {
      return this.$t(`EditAutomation.${key}`, params ?? {})
    },
    setLocalModifier(): void {
      this.localModifier = this.automation.modifiers?.reduce((acc, modifier) => {
        const returnValue: { amount: Clearable<number>; currency: CurrencyCode } = {} as {
          amount: Clearable<number>
          currency: CurrencyCode
        }
        if (modifier.numberValue) {
          returnValue.amount = modifier.numberValue
        }
        if (modifier.stringValue && modifier.type !== 'orderOrigin') {
          returnValue.currency = modifier.stringValue as CurrencyCode
        }
        return {
          ...acc,
          ...returnValue,
        }
      }, {} as { amount: Clearable<number>; currency: CurrencyCode }) || {
        amount: null,
        currency: 'USD',
      }
    },
    updateDetails() {
      this.localAutomation = {
        ...this.automation,
        schedule: { ...this.automation.schedule },
      }
      this.automation.offsets.forEach(({ type, amount, projectId, source }, index) => {
        this.localAutomation.offsets[index] = {
          type,
          amount,
          projectId: projectId || IMPACT_TYPE_DEFAULT_PROJECT_ID_MAP[type],
          source,
        }
      })
      this.setLocalModifier()
      this.localOrderOriginModifier =
        (this.automation.modifiers?.find(({ type }) => type === 'orderOrigin')
          ?.stringValue as ShopifyOrderOrigin) || 'all'
      this.isEndDateVisible = !!this.automation.schedule.endDate
      this.localSourceDetails = this.automation.sourceDetails || {
        sourceName: '',
        sourceId: '',
        imageUrl: '',
      }
    },
    setImpactSettingValidity(payload: boolean) {
      this.isImpactSettingValid = payload
    },
    setIsAllSelected(payload) {
      this.isAllProductSelectedTable = payload
    },
    toggleCheckbox(value) {
      this.isAllProductSelected = value
      this.showProductsTable = !value
    },
    onImpactSettingsPerQuestionSave(updatedData: FormQuestion) {
      this.localAutomation.offsets = updatedData.answers.flatMap(({ id, offsets }) =>
        offsets.map((offset) => ({
          ...offset,
          source: {
            type: 'answer',
            ids: [id],
          },
        })),
      )
      this.setFormQuestion({ formQuestion: updatedData })
    },
    onImpactSettingsPerQuestionEditingChange(isEditing: boolean) {
      this.isImpactSettingPerQuestionEditing = isEditing
    },
    onImpactSettingsSave(data) {
      this.localAutomation.offsets = data.flatMap(({ productId, offsets }) =>
        offsets.map((offset) => ({
          ...offset,
          source: {
            type: 'product',
            ids: [productId],
          },
        })),
      )
    },
    async saveChangesWithFormQuestions() {
      const { id, question, answers } = this.formQuestion
      const originalAnswers = [...answers]
      const updateFormQuestionRequestPayload: UpdateFormQuestionRequestPayload = {
        id,
        question,
        answers: answers.map(({ answer, id }) => {
          // new answers has temporary generated id to abel to iterate over them
          // on formQuestion update the backend will generate new id for them
          const answerId = this.originalFormQuestion.answers.map(({ id }) => id).includes(id)
            ? id
            : undefined
          const offsets = this.localAutomation.offsets.filter(({ source }) =>
            source?.ids?.includes(id),
          )
          return { answer, id: answerId, offsets }
        }),
      }

      const updatedFormQuestion = await this.updateFormQuestion({
        updateFormQuestionRequestPayload,
      })

      // update source (answer) ids on impactSetting as backend returns new answer ids
      // create an oldId -> newId map
      const sourceIdsMap = originalAnswers.reduce((acc, { answer, id: oldId }) => {
        const newId = updatedFormQuestion.answers.find(
          (answerData) => answerData.answer === answer,
        )?.id
        if (!newId) throw new Error(`Answer not found: ${answer}`)
        acc[oldId] = newId
        return acc
      }, {} as { [key: string]: string })
      // update source ids on localAutomation
      this.localAutomation.offsets.forEach(({ source }) => {
        if (source) source.ids = [sourceIdsMap[source.ids[0]]]
      })
      // update originalFormQuestion with updatedFormQuestion
      this.originalFormQuestion = updatedFormQuestion
      await this.saveChanges({ offsets: this.localAutomation.offsets })
    },
    async saveChanges(payload: UpdateAutomationRequestBody) {
      if (
        includes(
          [...PERCENTAGE_TRIGGERS, ...CURRENCY_MODIFIER_TRIGGERS, 'offsetPerProduct'],
          this.automation.trigger,
        )
      ) {
        if (!payload.modifiers) payload.modifiers = []
        payload.modifiers = [
          ...payload.modifiers,
          ...this.getModifierTypeByTrigger(
            this.automation.trigger as StoreIntegrationTrigger | BillingTriggerType,
            this.localModifier.amount ? this.localModifier.amount : undefined,
            this.localModifier.currency,
            this.isAllProductSelected,
          ),
        ]
      }

      if (this.selectedProducts.length) {
        payload.ids = []
      }

      if (payload.offsets) {
        payload.offsets = payload.offsets.filter(({ amount }) => !!amount)
      }

      if (this.isFormIntegration && this.localFormImpactSource) {
        if (!payload.modifiers) payload.modifiers = []
        const allFormsModifier: AutomationModifier = {
          type: 'allForms',
          booleanValue: this.localFormImpactSource === 'all',
        }
        payload.modifiers = [...payload.modifiers, allFormsModifier]
        payload.ids = this.localFormImpactSource === 'all' ? [] : [this.localFormImpactSource]
      }
      await this.updateAutomation({
        automationId: this.localAutomation.id,
        createAutomationRequestBody: payload,
      })
      this.localAutomation = { ...this.automation }
      if (this.automation.trigger === 'perSelectedAnswer')
        await this.loadFormQuestion({ automationTableData: { ...this.automation } })
      this.openedIndex = null
    },
    editSource() {
      this.editSourceAction({
        customIntegrationId: this.localSourceDetails.sourceId,
        sourceName: this.localSourceDetails.sourceName,
        sourceUrl: this.localSourceDetails.imageUrl,
      })
      this.openedIndex = null
    },
    editTrigger() {
      this.editTriggerAction({
        customIntegrationId: this.localSourceDetails.sourceId,
        triggerId: this.automation.id,
        triggerName: this.localAutomation.name,
        triggerType: this.localAutomation.trigger as CustomIntegrationTriggerType,
      })
      this.openedIndex = null
    },
    getOrderOriginLabel(orderOrigin: ShopifyOrderOrigin) {
      switch (orderOrigin) {
        case 'all':
          return 'All'
        case 'pos':
          return 'POS only'
        case 'web':
          return 'Online only'
      }
    },
    onIsEndDateVisibleChange(value: boolean) {
      if (!value) {
        this.localAutomation.schedule.endDate = undefined
      }
    },
    setOnboardingSkipped(): Promise<void> {
      return this.$store.dispatch('setOnboardingSkipped')
    },
    updateAutomation(payload: {
      automationId: string
      createAutomationRequestBody: UpdateAutomationRequestBody
    }): Promise<void> {
      return this.$store.dispatch('updateAutomation', payload)
    },
    editSourceAction(payload: {
      customIntegrationId: string
      sourceName: string
      sourceUrl: string
    }): Promise<void> {
      return this.$store.dispatch('editSource', payload)
    },
    editTriggerAction(payload: {
      customIntegrationId: string
      triggerId: string
      triggerName: string
      triggerType: CustomIntegrationTriggerType
    }): Promise<void> {
      return this.$store.dispatch('editTrigger', payload)
    },
    loadFormQuestion(payload: {
      automationTableData: AutomationTableData
    }): Promise<Promise<FormQuestion>> {
      return this.$store.dispatch('loadFormQuestion', payload)
    },
    resetFormQuestion(): Promise<void> {
      return this.$store.dispatch('resetFormQuestion')
    },
    setFormQuestion(payload: { formQuestion: FormQuestion }): Promise<void> {
      return this.$store.dispatch('setFormQuestion', payload)
    },
    updateFormQuestion(payload: {
      updateFormQuestionRequestPayload: UpdateFormQuestionRequestPayload
    }): Promise<Promise<FormQuestion>> {
      return this.$store.dispatch('updateFormQuestion', payload)
    },
  },
  props: {
    selectedAutomationId: {
      type: String,
    },
  },
  watch: {
    isEndDateVisible: [
      {
        handler: 'onIsEndDateVisibleChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.panel-header {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    align-items: center;
    justify-content: space-between;
    width: auto;
  }

  .pill-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    flex-basis: calc(50% - 5px);
    flex-grow: 1;
    flex-shrink: 0;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      flex-basis: auto;
      flex-grow: 0;
    }
  }

  .panel-header-text {
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
    padding: 10px 0;
    flex-basis: calc(50% - 5px);
    flex-grow: 1;
    flex-shrink: 0;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      flex-basis: auto;
      flex-grow: 0;
    }
  }
}

.impact-icon {
  height: 16px;
  width: 16px;
}

.toggle-edit-button {
  margin-left: auto;
  color: var(--font-color-primary);
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 5px;
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 0;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex-basis: auto;
    flex-grow: 0;
  }
}

.toggle-edit-button__icon {
  color: var(--font-color-primary);
  font-size: 22px;
  height: 22px;
  width: 22px;
}

:deep(.v-expansion-panel:not(:last-child)) {
  border-bottom: 2px solid var(--ui-green-light);
}

:deep(.v-expansion-panel-header:hover) {
  cursor: auto;
}

.label-wrapper {
  margin-bottom: 24px;

  .label {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 8px;
  }

  .description {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;
  }
}

.help-text {
  color: var(--gray);
  margin-bottom: 24px;
}

.button-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.dropdown-select {
  width: 100px;
  flex-grow: 0;
}

.additional-impact-setting-input {
  width: 200px;
  flex-grow: 0;
}

.automation-name-input {
  max-width: 340px;
}

.section:not(:last-child) {
  margin-bottom: 20px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.schedule-label-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.ongoing-icon {
  color: inherit;
}

.totals-wrapper {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 24px;
}

.cart-items-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: var(--gray);
  margin-bottom: 10px;
}

.total-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

.select-form-impact-source {
  margin-bottom: 40px;
}

.impact-settings-per-question-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .section:not(:last-child) {
    margin-bottom: 40px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
}
</style>
