<template>
  <section class="canceled-wrapper">
    <div class="canceled-inner">
      <h1 class="cancel-title">
        {{ t('canceled') }}
        <img :src="require('@/assets/icons/sad-emote.svg')" loading="lazy" height="40" width="50" />
      </h1>

      <div class="body-text" v-html="t('canceled_text')" />

      <gs-button
        v-if="isLoggedIn"
        size="large"
        class="restart-button"
        :loading="isLoading"
        @click="restartSubscription"
      >
        {{ t('restart') }}
      </gs-button>

      <gs-button :href="feedback" size="large" type="secondary">
        {{ t('feedback') }}
      </gs-button>
    </div>
  </section>
</template>

<script lang="ts">
import type { BusinessSubscriptionType } from '@/helpers/constants'
import {
  TYPEFORM_URL_NON_TRIAL,
  TYPEFORM_URL_TRIAL_GROWTH_PREMIUM,
  TYPEFORM_URL_TRIAL_STARTER,
} from '@/helpers/constants'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CanceledView',
  data() {
    return {
      typeFormUrlNonTrial: '',
      typeFormUrlTrialStarter: '',
      typeFormUrlTrialGrowthPremium: '',
      isLoading: false,
    }
  },
  computed: {
    feedback(): string {
      if (this.isTrial && this.isStarter) {
        return this.typeFormUrlTrialStarter + this.queryParams
      } else if (this.isTrial && this.isGrowthOrPremium) {
        return this.typeFormUrlTrialGrowthPremium + this.queryParams
      } else {
        return this.typeFormUrlNonTrial + this.queryParams
      }
    },
    isTrial(): boolean {
      return !!this.$route.query.isActiveSubscriptionOnTrial
    },
    isStarter(): boolean {
      return (
        this.$route.query.product === 'starterBusiness' ||
        this.$route.query.product === 'starterBusinessYearly'
      )
    },
    isGrowthOrPremium(): boolean {
      return (
        this.$route.query.product === 'growthBusiness' ||
        this.$route.query.product === 'growthBusinessYearly' ||
        this.$route.query.product === 'premiumBusiness' ||
        this.$route.query.product === 'premiumBusinessYearly'
      )
    },
    product(): BusinessSubscriptionType {
      return this.$route.query.product as BusinessSubscriptionType
    },
    queryParams(): string {
      if (Object.keys(this.$route.query).length) {
        return Object.entries(this.$route.query).reduce((acc, [key, value]) => {
          return `${acc}${key}=${value}&`
        }, '?')
      }
      return ''
    },
    isLoggedIn(): boolean {
      return !!this.authUserId
    },
    authUserId(): string {
      return this.$store.getters['getAuthUserId']
    },
  },
  created() {
    this.typeFormUrlNonTrial = TYPEFORM_URL_NON_TRIAL
    this.typeFormUrlTrialStarter = TYPEFORM_URL_TRIAL_STARTER
    this.typeFormUrlTrialGrowthPremium = TYPEFORM_URL_TRIAL_GROWTH_PREMIUM
  },
  methods: {
    t(key: string) {
      return this.$t(`CanceledView.${key}`)
    },
    async restartSubscription() {
      await Promise.all([this.setCurrentUser(), this.setOnboardingSkipped(false)])
      this.isLoading = true
      const path = this.$route.query.public_token ? 'direct-signup' : 'select-plan'
      await this.$router.push({
        path,
        query: { restartSubscription: 'true', ...this.$route.query },
      })
      this.isLoading = false
    },
    setCurrentUser(): Promise<Promise<void>> {
      return this.$store.dispatch('setCurrentUser')
    },
    setOnboardingSkipped(withNavigation: boolean): Promise<Promise<void>> {
      return this.$store.dispatch('setOnboardingSkipped', withNavigation)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.canceled-wrapper {
  background: url('../assets/backgrounds/cancel-view-bottom-left.png') bottom left no-repeat,
    url('../assets/backgrounds/cancel-view-top-right.png') top right no-repeat;
  background-size: 50%;
  min-height: 100vh;
  height: 100%;
  padding: 15px;
}

.canceled-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(6px);
  border-radius: 20px;
  border: 1px solid var(--gray-light-C0);
  padding: 32px 24px;
  text-align: center;
  max-width: 1200px;
  max-height: 760px;
}

.cancel-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 40px;
  line-height: normal;
  margin-bottom: 40px;
}

.body-text {
  font-size: 24px;
  text-align: center;
  line-height: normal;
  width: 100%;
  margin-bottom: 40px;
  max-width: 35ch;
}

.restart-button {
  margin-bottom: 16px;
}

//.logo-wrapper {
//  max-width: 220px;
//}
//
//.logo {
//  max-width: 100%;
//}
//
//.header {
//  font-weight: bold;
//  font-size: 35px;
//  margin-bottom: 28px;
//}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .canceled-wrapper {
    background-size: auto;
    padding: 62px 0;
    display: flex;
    justify-content: center;
  }

  .canceled-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
  }

  .cancel-title {
    flex-direction: row;
    gap: 10px;
    font-size: 50px;
  }
}
</style>
