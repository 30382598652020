import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d8a8d4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "frequently-asked-questions" }
const _hoisted_2 = { class: "faq-title" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "demo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.t('title')), 1),
    _createVNode(_component_v_expansion_panels, {
      multiple: "",
      flat: ""
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faqList, (item, key) => {
          return (_openBlock(), _createBlock(_component_v_expansion_panel, {
            key: key,
            class: "v-expansion-panel-headers"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_expansion_panel_title, { class: "widget-header" }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: require('@/assets/icons/main-shape.svg'),
                    alt: "shape",
                    height: "20",
                    width: "20",
                    class: "header-logo"
                  }, null, 8, _hoisted_3),
                  _createElementVNode("div", null, _toDisplayString(item.question), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_v_expansion_panel_text, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "accordion-content",
                    innerHTML: item.answer
                  }, null, 8, _hoisted_4)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    _createElementVNode("p", _hoisted_5, [
      _createTextVNode(_toDisplayString(_ctx.t('questions')) + " ", 1),
      _createElementVNode("button", {
        class: "link",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openChat()))
      }, _toDisplayString(_ctx.t('get_in_touch')), 1)
    ])
  ]))
}