<template>
  <div>
    <v-autocomplete
      v-model="selectedAccountItem"
      v-model:search-input="search"
      :loading="loading"
      :items="accounts.map((account) => `${account.accountId} - ${account.companyName}`)"
      :label="'Accounts'"
      variant="outlined"
      color="#3B755F"
      item-color="#3B755F"
      class="dropdown"
      :placeholder="'Min 3 characters to search'"
      :rules="[rules.required]"
    />

    <div v-for="offsetType in OFFSET_TYPES()" :key="offsetType">
      <div style="display: flex">
        <div style="width: 300px; margin-right: 40px">
          <v-text-field
            v-model="impacts[offsetType].amount"
            :label="'Amount'"
            variant="outlined"
            clearable
            type="number"
            flat
            min="0"
            color="#3B755F"
            class="employee-input"
          />
        </div>
        <gs-button
          :class="'impact-button'"
          size="large"
          width="300"
          :loading="loading"
          :disabled="!selectedAccountItem || !impacts[offsetType]"
          @click="() => openUploadModal(offsetType)"
        >
          Upload {{ offsetType }} receipts ({{ impacts[offsetType].urls.length }})
        </gs-button>
      </div>
    </div>

    <div v-if="error" style="background: red; color: white">
      An error occured: {{ errorMessage }}
    </div>

    <v-btn
      class="button"
      color="#3B755F"
      height="54"
      width="100%"
      style="margin-bottom: 0"
      @click="saveUpdatedUsers()"
    >
      <div v-if="!loading" class="button-text">Save</div>
      <v-progress-circular v-if="loading" indeterminate color="#F9F9F9" />
    </v-btn>
  </div>
</template>

<script lang="ts">
import type { AccountDto, UpdateTransferredImpactsRequestBody } from '@api/index'
import { fetchAllAccount, updateTransferredImpacts } from '@api/index'
import type { OffsetType } from '@/helpers/interfaces'
import { OFFSET_TYPES } from '@/helpers/interfaces'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { TransferredImpact } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AccountsView',
  methods: {
    OFFSET_TYPES() {
      return OFFSET_TYPES
    },
    openUploadModal(type: OffsetType) {
      const accountId = this.selectedAccountItem.split(' - ')[0]
      const account = this.accounts.find((a) => a.accountId === accountId)
      if (window.cloudinary && account) {
        window.cloudinary
          .createUploadWidget(
            {
              cloud_name: process.env.VUE_APP_CLOUDNAME,
              upload_preset: process.env.VUE_APP_UPLOAD_PRESET,
              folder: 'transferImpactReceipts',
              sources: ['local', 'url', 'camera'],
              showSkipCropButton: false,
              showPoweredBy: false,
              clientAllowedFormats: ['png', 'jpg', 'jpeg', 'pdf'],
            },
            (error: unknown, result: { event: string; info: { url: string } }) => {
              if (!error && result && result.event === 'success') {
                this.impacts[type].urls.push(result.info.url)
              }
            },
          )
          .open()
      }
    },
    async saveUpdatedUsers() {
      try {
        this.loading = true
        const accountId = this.selectedAccountItem.split(' - ')[0]
        const impacts: TransferredImpact[] = []
        for (const [type, { amount, urls }] of Object.entries(this.impacts)) {
          if (Number(amount))
            impacts.push({
              type: type as OffsetType,
              amount: Number(amount),
              urls,
            })
        }

        this.payload = {
          accountId,
          impacts,
        }
        if (this.payload) await updateTransferredImpacts(this.payload)
        this.payload = undefined
        this.selectedAccountItem = ''
        this.search = ''
        this.impacts = {
          carbon: { amount: 0, urls: [] },
          plastic: { amount: 0, urls: [] },
          kelp: { amount: 0, urls: [] },
          trees: { amount: 0, urls: [] },
        }
        this.errorMessage = ''
      } catch (error) {
        this.error = true
        this.errorMessage = error
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    onSelectedAccountItemChange() {
      this.payload = undefined
      this.impacts = {
        carbon: { amount: 0, urls: [] },
        plastic: { amount: 0, urls: [] },
        kelp: { amount: 0, urls: [] },
        trees: { amount: 0, urls: [] },
      }
    },
  },
  mixins: [RulesMixin],
  data() {
    return {
      errorMessage: '',
      selectedAccountItem: '',
      search: '',
      loading: false,
      error: false,
      impacts: {
        carbon: { amount: 0, urls: [] },
        plastic: { amount: 0, urls: [] },
        kelp: { amount: 0, urls: [] },
        trees: { amount: 0, urls: [] },
      },
      payload: undefined,
      accounts: [],
    } as {
      errorMessage: string
      selectedAccountItem: string
      search: string
      loading: boolean
      error: boolean
      impacts: Record<OffsetType, { amount: number; urls: string[] }>
      payload?: UpdateTransferredImpactsRequestBody
      accounts: AccountDto[]
    }
  },
  async created() {
    this.loading = true
    this.accounts = await fetchAllAccount()
    this.loading = false
  },
  watch: {
    selectedAccountItem: [
      {
        handler: 'onSelectedAccountItemChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.impact-edit {
  min-height: 100px;
  padding-top: 20px;
  width: 360px;
}

.text-field-wrapper {
  display: flex;
  width: 100%;
  min-width: 160px;
  margin-top: 20px;
}

.button {
  margin-top: 35px;
}

.button-text {
  color: #f9f9f9;
  font-weight: 600;
  font-size: 16px;
}
</style>
