import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8e025ade"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "signup" }
const _hoisted_2 = { class: "signup-wrapper" }
const _hoisted_3 = {
  key: 1,
  class: "select-plan-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ShopifySetupInstructions = _resolveComponent("ShopifySetupInstructions")!
  const _component_UserInfo1 = _resolveComponent("UserInfo1")!
  const _component_SelectPlanView = _resolveComponent("SelectPlanView")!
  const _component_Signup1 = _resolveComponent("Signup1")!
  const _component_CallToUpgradePopup = _resolveComponent("CallToUpgradePopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (!_ctx.loaded)
          ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isSubscriptionRestart)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.progressItem === 0)
              ? (_openBlock(), _createBlock(_component_ShopifySetupInstructions, {
                  key: 0,
                  onNextClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.progressItem++))
                }))
              : _createCommentVNode("", true),
            (_ctx.progressItem === 1)
              ? (_openBlock(), _createBlock(_component_UserInfo1, {
                  key: 1,
                  onNextClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.progressItem++)),
                  onBackClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.progressItem--)),
                  onEcommerceBackClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.progressItem = 0))
                }))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.progressItem === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_SelectPlanView, { onBackClick: _ctx.onSelectPlanViewBackClick }, null, 8, ["onBackClick"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.progressItem === 3 && !_ctx.isSubscriptionRestart)
        ? (_openBlock(), _createBlock(_component_Signup1, {
            key: 2,
            location: _ctx.location,
            onBackClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.progressItem--)),
            onCheckoutClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.checkout()))
          }, null, 8, ["location"]))
        : _createCommentVNode("", true),
      (_ctx.isCallToUpgradePopupOpen)
        ? (_openBlock(), _createBlock(_component_CallToUpgradePopup, {
            key: 3,
            "popup-type": 'integration',
            "is-premium-only": false,
            onClose: _ctx.onCallToUpgradePopupClose
          }, null, 8, ["onClose"]))
        : _createCommentVNode("", true)
    ])
  ]))
}