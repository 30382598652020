import type {
  AutomationModifierType,
  AutomationSourceType,
  AutomationStatus,
  AutomationTrigger,
  Clearable,
  CustomIntegrationType,
  Emptyable,
  Impact,
  IntegrationOptionSlug,
  IntegrationOptionWriteDirectionSyncedProperty,
  IntegrationPlatform,
  IntegrationStatusType,
  IntegrationType,
  OffsetType,
  ProductFetchingStatus,
  WriteDirectionTestStatus,
} from '@/helpers/interfaces'
import {
  BILLING_INTEGRATION_SOURCES,
  CUSTOM_INTEGRATION_TYPES,
  EMAIL_INTEGRATION_SOURCES,
  FEEDBACK_INTEGRATION_SOURCES,
  STORE_TYPES,
} from '@/helpers/interfaces'
import type { SetProductFetchStateByIntegrationIdParams, StateInterface } from '@/store/index'
import type {
  CreateAutomationRequestBody,
  CreateIntegrationRequestBody,
  CustomIntegrationResponse,
  CustomIntegrationTrigger,
  CustomIntegrationTriggerType,
  Provider,
  Subscription,
  UpdateAutomationRequestBody,
} from '@api/index'
import {
  createAutomation,
  createIntegration,
  deleteAutomation,
  deleteCustomIntegration,
  deleteCustomIntegrationTrigger,
  deleteIntegration,
  fetchAllIntegrations,
  fetchAutomationsByIntegrationId,
  getCustomIntegrations,
  getIntegrations,
  updateAutomation,
  updateCustomIntegration,
  updateCustomIntegrationTrigger,
} from '@api/index'
import type { SelectedIntegration } from '@/components/onboarding/SelectIntegration.vue'
import type { AxiosResponse } from 'axios'
import type { Notification } from '@/store/notification'
import VueI18n from '@/i18n'
import type { ActionContext } from 'vuex'
import type { ProjectId } from '@/helpers/constants'
import { API_ERRORS, FORM_INTEGRATION_SOURCES, isDemoEnvironment } from '@/helpers/constants'
import { includes } from '@/helpers/parsers'

export const demoStoreIntegrations: Integration[] = (STORE_TYPES || []).map((type) => ({
  id: `demo-${type}`,
  name: `${type} demo`,
  platform: type,
  automationList: [],
  createdAt: new Date().toLocaleDateString(),
  status: {
    status: 'active',
  },
  types: ['store'],
}))

export const demoEmailIntegrations: Integration[] = (EMAIL_INTEGRATION_SOURCES || []).map(
  (type) => {
    const integrationData: Integration = {
      id: `demo-${type}`,
      name: `${type} demo`,
      platform: type,
      automationList: [],
      createdAt: new Date().toLocaleDateString(),
      status: {
        status: 'active',
      },
      types: ['email'],
    }

    if (type === 'klaviyo') {
      return {
        ...integrationData,
        writeDirection: {
          status: {
            status: 'disabled',
          },
          testStatus: {
            status: 'success',
            initiatedAt: new Date(),
          },
          syncHistory: {
            initiatedAt: new Date(),
            finishedAt: null,
            numberOfSyncedImpactActions: null,
          },
        },
      }
    } else {
      return { ...integrationData }
    }
  },
)

export const demoFeedbackIntegrations: Integration[] = (FEEDBACK_INTEGRATION_SOURCES || []).map(
  (type) => ({
    id: `demo-${type}`,
    name: `${type} demo`,
    platform: type,
    automationList: [],
    createdAt: new Date().toLocaleDateString(),
    status: {
      status: 'active',
    },
    types: ['feedback'],
  }),
)

export const demoFormIntegrations: Integration[] = (FORM_INTEGRATION_SOURCES || []).map((type) => ({
  id: `demo-${type}`,
  name: `${type} demo`,
  platform: type,
  automationList: [],
  createdAt: new Date().toLocaleDateString(),
  status: {
    status: 'active',
  },
  types: ['form'],
}))

export const demoBillingIntegrations: Integration[] = (BILLING_INTEGRATION_SOURCES || []).map(
  (type) => ({
    id: `demo-${type}`,
    name: `${type} demo`,
    platform: type,
    automationList: [],
    createdAt: new Date().toLocaleDateString(),
    status: {
      status: 'active',
    },
    types: ['billing'],
  }),
)

export interface IntegrationStatus {
  status: IntegrationStatusType
  description?: string | ProductFetchingStatus
  url?: string
}

export interface AutomationOffset {
  type: OffsetType
  amount?: Clearable<number>
  projectId?: ProjectId
  source?: AutomationSource
}

export interface AutomationModifier {
  type: AutomationModifierType
  numberValue?: number
  stringValue?: string
  booleanValue?: boolean
}

export interface Schedule {
  startDate: string
  endDate: string | undefined
}

export interface IIntegrationStatus {
  status: IntegrationStatus
  initiatedAt: Date
  initiatedBy: string
  description?: string
  url?: string
}

export interface IIntegrationWriteDirectionTestStatus {
  status: WriteDirectionTestStatus
  initiatedAt: Date
  initiatedBy?: string
  description?: string
  url?: string
}

export interface IIntegrationWriteDirectionSyncHistory {
  initiatedAt: Date
  initiatedBy?: string
  jobId?: string
  finishedAt: Clearable<Date>
  numberOfSyncedImpactActions: Clearable<number>
}

export interface IIntegrationOptionWriteDirectionSyncedData {
  profile: IntegrationOptionWriteDirectionSyncedProperty[]
  event: IntegrationOptionWriteDirectionSyncedProperty[]
  metric: IntegrationOptionWriteDirectionSyncedProperty[]
}

export interface IIntegrationOptionWriteDirectionSyncedTestData {
  profileEmail: string
  metricName: string
}

export interface IIntegrationWriteDirection {
  status: IntegrationStatus
  testStatus: IIntegrationWriteDirectionTestStatus
  syncHistory?: IIntegrationWriteDirectionSyncHistory
}

export interface Integration {
  id: string
  types: IntegrationType[]
  name: string
  platform: IntegrationPlatform
  status: IntegrationStatus
  automationList: Automation[]
  createdAt: string
  writeDirection?: IIntegrationWriteDirection
}

export interface Automation {
  id: string
  name: string
  offsets: AutomationOffset[]
  status: AutomationStatus
  trigger: AutomationTrigger
  modifiers?: AutomationModifier[]
  schedule: Schedule
  source?: AutomationSource
}

export interface AutomationSource {
  type: AutomationSourceType
  ids: string[]
}

export interface IntegrationsState {
  integrationList: Integration[]
  integrationOptionList: IntegrationOption[]
  customIntegrationList: CustomIntegrationResponse[]
  selectedIntegrationForDataSync: SelectedIntegration | null
}

export interface ProductFetchingStatusRecord {
  id: string
  status: ProductFetchingStatus
  lastProductsFetchDate: string | null
}

export interface AutomationTableData {
  id: string
  sourceDetails?: { sourceName: string; sourceId: string; imageUrl: string }
  name: string
  integrationName: string
  trigger: AutomationTrigger | CustomIntegrationTriggerType
  platform: IntegrationPlatform
  schedule: Schedule
  scheduleLabel: string
  scheduleLabelUTC: string
  offsets: AutomationOffset[]
  status: AutomationStatus
  statusLabel: AutomationStatus
  modifiers?: AutomationModifier[]
  source?: AutomationSource
}

export interface IntegrationTableData {
  id: string
  icon: string
  platform: IntegrationPlatform
  createdAt: string
  name: string
  automationNumber: number
  status: IntegrationStatusType
  statusUrl: string
  writeDirection?: IIntegrationWriteDirection
}

export interface IIntegrationOptionWriteDirection {
  isAvailable: boolean
  syncedProperties: IIntegrationOptionWriteDirectionSyncedData
  syncedTestData: IIntegrationOptionWriteDirectionSyncedTestData
  provider: string
  documentationLink: string
}

export interface IntegrationOption {
  id: string
  name: string
  slug: IntegrationOptionSlug
  category: string
  tags: string[]
  icon: string
  provider: Provider
  triggers?: AutomationTrigger[]
  status: 'planned' | 'active'
  activeNumber: number
  url?: string
  writeDirection?: IIntegrationOptionWriteDirection
}

export interface IntegrationOptionResponse {
  _id: string
  name: string
  slug: IntegrationOptionSlug
  category: string
  tags: string[]
  icon: string
  provider: Provider
  triggers: AutomationTrigger[]
  status: 'planned' | 'active'
  activeNumber: number
  writeDirection?: IIntegrationOptionWriteDirection
}

export interface ActiveProductsFromAutomationsWithGlobalOffsets {
  automationName: string
  offsets: Impact[]
}

export interface ActiveProductsFromOtherAutomationsWithOffsets
  extends ActiveProductsFromAutomationsWithGlobalOffsets {
  productId: string
}

export const integrationsModule = {
  state: () =>
    ({
      integrationList: [],
      integrationOptionList: [],
      customIntegrationList: [],
      selectedIntegrationForDataSync: null,
    } as IntegrationsState),
  getters: {
    getIntegrationsByPlatform:
      (state: IntegrationsState) =>
      (platform: IntegrationPlatform): Integration[] => {
        return state.integrationList.filter((integration) => integration.platform === platform)
      },
    getIntegrationOptionByPlatform:
      (state: IntegrationsState) =>
      (platform: IntegrationPlatform): IntegrationOption | undefined => {
        return state.integrationOptionList.find(({ slug }) => slug === platform)
      },
    getCustomIntegrationsByPlatform:
      (state: IntegrationsState) =>
      (platform: CustomIntegrationType): CustomIntegrationResponse[] => {
        return state.customIntegrationList.filter(({ type }) => type === platform)
      },
    getCustomIntegrationBySourceId:
      (state: IntegrationsState) =>
      (sourceId: string): CustomIntegrationResponse | undefined => {
        return state.customIntegrationList.find(({ _id }) => _id === sourceId)
      },
    getIntegrationsAmountByPlatform:
      (state: IntegrationsState) =>
      (platform: IntegrationPlatform): number => {
        return (
          state.integrationList.filter((integration) => integration.platform === platform).length ||
          state.customIntegrationList.filter(({ type }) => type === platform && type !== 'custom')
            .length
        )
      },
    getIfAtLeastOneAutomationActive: (state: IntegrationsState): boolean => {
      return (
        state.integrationList.some(({ automationList }) => automationList.length) ||
        state.customIntegrationList.some(({ triggers }) => triggers.length)
      )
    },
    hasPaymentDetails:
      (state: IntegrationsState, getters) =>
      (subscriptions: Subscription[]): boolean => {
        if (getters.getIsShopifyActive) {
          const shopifyStores: Integration[] = getters.getIntegrationsByPlatform('shopify') || []
          return (
            getters.hasOwnPaidSubscriptionItem &&
            shopifyStores.some(({ status }) => status.status === 'active')
          )
        } else {
          return subscriptions.some(
            (subscription) => !!subscription.externalCustomerId && !!subscription.externalId,
          )
        }
      },
    getIsActiveIntegration: (state: IntegrationsState): boolean => {
      return !!state.integrationList.length || !!state.customIntegrationList.length
    },
    getIsActiveCustomIntegration: (state: IntegrationsState): boolean => {
      return state.customIntegrationList.some(({ type }) => type === 'custom')
    },
    getIntegrationsAmount: (state: IntegrationsState): number => {
      return state.integrationList.length + state.customIntegrationList.length
    },
    getUserStores: (state: IntegrationsState): Integration[] => {
      return state.integrationList.filter(({ types }) => types.some((type) => type === 'store'))
    },
    getShouldDisplayShopifyOverlay: (s: IntegrationsState, getters): boolean => {
      return getters.getUserStores.some(
        ({ status, platform }) =>
          status.status === 'pending' && platform === 'shopify' && !!status.url,
      )
    },
    getLastIntegrationId: (state: IntegrationsState): string => {
      return state.integrationList[state.integrationList.length - 1]?.id || ''
    },
    getLastShopifyIntegration: (state: IntegrationsState, getters): string => {
      return getters.getIntegrationsByPlatform('shopify')[
        getters.getIntegrationsByPlatform('shopify').length - 1
      ]
    },
    getIsShopifyActive: (state: IntegrationsState, getters): boolean => {
      return !!getters.getIntegrationsByPlatform('shopify').length
    },
    getProductFetchStatusByIntegrationId:
      (state: IntegrationsState) =>
      (integrationId: string): ProductFetchingStatus => {
        return state.integrationList.find(({ id }) => id === integrationId)?.status
          .description as ProductFetchingStatus
      },
    getAutomationsDataForTable: (state: IntegrationsState, getters): AutomationTableData[] => {
      let automationList: AutomationTableData[] = []
      automationList = state.integrationList.reduce((acc: AutomationTableData[], integration) => {
        integration.automationList.forEach(
          ({ name, trigger, schedule, offsets, status, modifiers, id, source }) => {
            acc.push({
              id,
              integrationName: integration.name,
              name,
              trigger,
              platform: integration.platform,
              schedule,
              scheduleLabel: getters.getFormattedSchedule(schedule, status),
              scheduleLabelUTC: getters.getFormattedScheduleUTC(schedule, status),
              offsets,
              status,
              statusLabel: getters.getFormattedStatus(schedule, status),
              modifiers,
              source,
            })
          },
        )
        return acc
      }, [])
      state.customIntegrationList.forEach(
        ({ source, triggers, createdAt, type, imageUrl, _id }) => {
          triggers.forEach((trigger) =>
            automationList.push({
              id: trigger._id,
              sourceDetails: {
                sourceName: source,
                sourceId: _id,
                imageUrl,
              },
              integrationName: source,
              name: trigger.name,
              trigger: trigger.type,
              platform: type || ('custom' as IntegrationPlatform),
              schedule: {
                startDate: createdAt,
                endDate: undefined,
              },
              scheduleLabel: getters.getFormattedSchedule(
                {
                  startDate: createdAt,
                  endDate: null,
                },
                'active',
              ),
              scheduleLabelUTC: getters.getFormattedScheduleUTC(
                {
                  startDate: createdAt,
                  endDate: null,
                },
                'active',
              ),
              offsets: [],
              status: 'active',
              statusLabel: 'active',
            }),
          )
        },
      )
      return automationList
    },
    getIntegrationPlatformImage:
      (state: IntegrationsState) =>
      (platform: IntegrationPlatform): string | '' => {
        if (platform === 'custom') return ''
        return state.integrationOptionList.find(({ slug }) => slug === platform)?.icon || ''
      },
    getIntegrationsForTable:
      (state: IntegrationsState, getters) =>
      (platformToFilter: Emptyable<IntegrationPlatform>): IntegrationTableData[] => {
        let integrations: IntegrationTableData[] = []
        let customIntegrations: IntegrationTableData[] = []
        if (includes(CUSTOM_INTEGRATION_TYPES, platformToFilter) || !platformToFilter) {
          customIntegrations = state.customIntegrationList
            .map(({ source, triggers, createdAt, type, imageUrl, _id }) => {
              return {
                id: _id,
                icon: imageUrl || getters.getIntegrationPlatformImage(type),
                platform: type as IntegrationPlatform,
                createdAt: new Date(createdAt).toLocaleDateString('en', {
                  month: 'long',
                  year: 'numeric',
                  day: 'numeric',
                }),
                name: source,
                status: 'active' as IntegrationStatusType,
                statusUrl: '',
                automationNumber: triggers.length,
              }
            })
            .filter(({ platform }) => {
              if (platformToFilter) {
                return platform === platformToFilter
              } else {
                return true
              }
            })
        }
        integrations = state.integrationList
          .filter((integration) => {
            if (platformToFilter) {
              return integration.platform === platformToFilter
            } else {
              return true
            }
          })
          .map(({ id, platform, name, createdAt, status, automationList, writeDirection }) => {
            return {
              id,
              icon: getters.getIntegrationPlatformImage(platform),
              platform,
              createdAt: new Date(createdAt).toLocaleDateString('en', {
                month: 'long',
                year: 'numeric',
                day: 'numeric',
              }),
              name,
              status: status.status,
              statusUrl: status.url || '',
              automationNumber: automationList.length,
              writeDirection,
            }
          })
        return [...integrations, ...customIntegrations]
      },
    getFormattedSchedule:
      () =>
      (schedule: Schedule, status: AutomationStatus): string => {
        if (status === 'active' && schedule.endDate) {
          return `${new Date(schedule.startDate).toLocaleDateString('en', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })} - ${new Date(schedule.endDate).toLocaleDateString('en', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}`
        } else if (status === 'active' && !schedule.endDate) {
          return `${new Date(schedule.startDate).toLocaleDateString('en', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })} -`
        } else {
          return '-'
        }
      },
    getFormattedScheduleUTC:
      () =>
      (schedule: Schedule, status: AutomationStatus): string => {
        if (status === 'active' && schedule.endDate) {
          return `${new Date(schedule.startDate).toLocaleDateString('en', {
            hour: 'numeric',
            minute: 'numeric',
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            timeZone: 'UTC',
          })} UTC - ${new Date(schedule.endDate).toLocaleDateString('en', {
            hour: 'numeric',
            minute: 'numeric',
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            timeZone: 'UTC',
          })} UTC`
        } else if (status === 'active' && !schedule.endDate) {
          return `${new Date(schedule.startDate).toLocaleDateString('en', {
            hour: 'numeric',
            minute: 'numeric',
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            timeZone: 'UTC',
          })} UTC -`
        } else {
          return '-'
        }
      },
    getFormattedStatus:
      () =>
      (schedule: Schedule, status: AutomationStatus): string => {
        const today = new Date().getTime()
        const endDate = schedule.endDate ? new Date(schedule.endDate).getTime() : null
        const startDate = new Date(schedule.startDate).getTime()

        if (status === 'active' && (!endDate || endDate > today) && startDate <= today) {
          return 'active'
        }
        if (status === 'active' && startDate > today) {
          return 'scheduled'
        }
        if (status === 'disabled' && endDate && endDate < today) {
          return 'ended'
        }
        if (status === 'disabled' && !endDate) {
          return 'disabled'
        } else {
          return 'disabled'
        }
      },
    getAvailableAutomationTriggersByPlatform:
      (state: IntegrationsState) =>
      (platform: IntegrationPlatform): AutomationTrigger[] => {
        const triggersByPlatform =
          state.integrationOptionList.find(({ slug }) => slug === platform)?.triggers || []

        if (
          triggersByPlatform.includes('offsetPerProduct') &&
          !triggersByPlatform.includes('offsetAllProducts')
        ) {
          const index = triggersByPlatform.indexOf('offsetPerProduct')
          triggersByPlatform.splice(index, 0, 'offsetAllProducts')
        }

        return triggersByPlatform
      },
    hasAnyAutomation: (state: IntegrationsState): boolean => {
      return (
        state.integrationList.some(({ automationList }) => automationList.length) ||
        state.customIntegrationList.some(({ triggers }) => triggers.length)
      )
    },
    getCustomIntegrationTriggerListById:
      (state: IntegrationsState) =>
      (id: string): CustomIntegrationTrigger[] => {
        return state.customIntegrationList.find(({ _id }) => _id === id)?.triggers || []
      },
    getIntegrationIdByAutomationId:
      (state: IntegrationsState) =>
      (automationId: string): string => {
        return (
          state.integrationList.find(({ automationList }) =>
            automationList.some(({ id }) => id === automationId),
          )?.id || ''
        )
      },
    getActiveProductsFromOtherAutomationsWithOffsets:
      (state: IntegrationsState) =>
      (
        integrationId: string,
        automationId?: string,
      ): ActiveProductsFromOtherAutomationsWithOffsets[] => {
        return (
          state.integrationList
            .find(({ id }) => id === integrationId)
            ?.automationList.reduce(
              (acc: ActiveProductsFromOtherAutomationsWithOffsets[], curr) => {
                if (
                  curr.id !== automationId &&
                  ['active', 'scheduled'].includes(curr.status) &&
                  !curr.schedule.endDate &&
                  curr.source?.type === 'product'
                ) {
                  curr.source.ids.forEach((productId) => {
                    acc.push({
                      productId: productId,
                      automationName: curr.name,
                      offsets: curr.offsets.map((offset) => ({
                        type: offset.type,
                        amount: offset.amount || 0,
                      })),
                    })
                  })
                }
                return acc
              },
              [],
            ) || []
        )
      },
    getActiveProductsFromAutomationsWithGlobalOffsets:
      (state: IntegrationsState) =>
      (integrationId: string): ActiveProductsFromAutomationsWithGlobalOffsets[] => {
        return (
          state.integrationList
            .find(({ id }) => id === integrationId)
            ?.automationList.reduce(
              (acc: ActiveProductsFromAutomationsWithGlobalOffsets[], curr) => {
                if (
                  curr.modifiers?.some(
                    ({ type, booleanValue }) => type === 'globalOffsetPerProduct' && booleanValue,
                  ) &&
                  ['active', 'scheduled'].includes(curr.status) &&
                  !curr.schedule.endDate
                ) {
                  acc.push({
                    automationName: curr.name,
                    offsets: curr.offsets.map((offset) => ({
                      type: offset.type,
                      amount: offset.amount || 0,
                    })),
                  })
                }
                return acc
              },
              [],
            ) || []
        )
      },
    getAutomationTableDataById:
      (state: IntegrationsState, getters) =>
      (automationId: string): AutomationTableData | undefined => {
        return getters.getAutomationsDataForTable.find(({ id }) => id === automationId)
      },
    getAutomationById:
      (state: IntegrationsState) =>
      (automationId: string): Automation | void => {
        for (const { automationList } of state.integrationList) {
          const automation: Automation | undefined = automationList.find(
            ({ id }) => id === automationId,
          )
          if (automation) return automation
        }
      },
    getOptionsForSelectionList: (state: IntegrationsState): IntegrationOption[] => {
      let returnValue: IntegrationOption[] = []
      returnValue = [...state.integrationOptionList]
      if (state.customIntegrationList.length) {
        state.customIntegrationList
          .filter(({ type }) => type === 'custom')
          .forEach(({ _id, triggers, imageUrl, source }) => {
            returnValue.push({
              id: _id,
              icon: imageUrl,
              name: source,
              status: 'active',
              activeNumber: triggers.length,
              slug: 'custom',
              category: 'custom',
              tags: ['custom'],
              provider: {
                name: 'custom',
                externalId: '',
              },
            })
          })
      }
      return returnValue.sort((a, b) => b.activeNumber - a.activeNumber)
    },
    getIntegrationById:
      (state: IntegrationsState) =>
      (integrationId: string): Integration => {
        return state.integrationList.find(({ id }) => id === integrationId) || ({} as Integration)
      },
    getIntegrationList: (state: IntegrationsState): Integration[] => {
      return state.integrationList
    },
    getIntegrationOptionList: (state: IntegrationsState): IntegrationOption[] => {
      return state.integrationOptionList
    },
    getSelectedIntegrationForDataSync: (state: IntegrationsState): SelectedIntegration | null => {
      return state.selectedIntegrationForDataSync
    },
  },
  mutations: {
    setIntegrationList(state: IntegrationsState, integrationList: Integration[]) {
      state.integrationList = integrationList
    },
    createIntegration(s: IntegrationsState, integration: Integration) {
      s.integrationList[s.integrationList.length] = integration
    },
    createAutomation(
      s: IntegrationsState,
      payload: { selectedIntegrationIndex: number; automation: Automation },
    ) {
      s.integrationList[payload.selectedIntegrationIndex].automationList.push(payload.automation)
    },
    updateAutomation(
      s: IntegrationsState,
      payload: {
        selectedIntegrationIndex: number
        selectedAutomationIndex: number
        automation: Automation
      },
    ) {
      s.integrationList[payload.selectedIntegrationIndex].automationList[
        payload.selectedAutomationIndex
      ] = payload.automation
      // s.integrationList[payload.selectedIntegrationIndex].automationList[payload.selectedAutomationIndex] = payload.automation
    },
    updateIntegration(
      s: IntegrationsState,
      payload: {
        updatedIntegrationIndex: number
        updatedIntegration: Integration
      },
    ) {
      s.integrationList[payload.updatedIntegrationIndex] = payload.updatedIntegration
    },
    deleteIntegration(s: IntegrationsState, integrationId: string) {
      s.integrationList = s.integrationList.filter(({ id }) => id !== integrationId)
    },
    setCustomIntegrationList(
      state: IntegrationsState,
      customIntegrationList: CustomIntegrationResponse[],
    ) {
      state.customIntegrationList = customIntegrationList
    },
    setProductFetchState(s: IntegrationsState, params: SetProductFetchStateByIntegrationIdParams) {
      const integrationIndex = s.integrationList.findIndex(({ id }) => id === params.integrationId)
      if (integrationIndex !== -1) {
        s.integrationList[integrationIndex].status.description = params.state
      } else {
        throw new Error('integration not found')
      }
    },
    setIntegrationOptions(s: IntegrationsState, integrationOptionList: IntegrationOption[]) {
      s.integrationOptionList = integrationOptionList
    },
    updateTriggerList(
      s: IntegrationsState,
      payload: { customIntegrationIndex: number; updatedTriggerList: CustomIntegrationTrigger[] },
    ) {
      s.customIntegrationList[payload.customIntegrationIndex].triggers = payload.updatedTriggerList
    },
    updateCustomIntegration(
      s: IntegrationsState,
      payload: {
        updatedCustomIntegration: CustomIntegrationResponse
        customIntegrationIndex: number
      },
    ) {
      s.customIntegrationList[payload.customIntegrationIndex] = payload.updatedCustomIntegration
    },
    setSelectedIntegrationForDataSync: (
      s: IntegrationsState,
      integration: SelectedIntegration | null,
    ) => {
      s.selectedIntegrationForDataSync = integration
    },
  },
  actions: {
    async setIntegrations(
      { commit, state, dispatch }: ActionContext<IntegrationsState, StateInterface>,
      withCustomIntegrations = true,
    ) {
      commit('setLoading', true)
      try {
        if (isDemoEnvironment && !state.integrationList.length) {
          commit('setIntegrationList', [
            ...demoStoreIntegrations,
            ...demoEmailIntegrations,
            ...demoFeedbackIntegrations,
            ...demoFormIntegrations,
            ...demoBillingIntegrations,
          ])
        } else if (isDemoEnvironment && !!state.integrationList.length) {
          return
        } else {
          const { data } = await fetchAllIntegrations()
          const integrationList: Integration[] = data.map((integration) => ({
            ...integration,
            automationList: [],
          }))
          const response: AxiosResponse<Automation[]>[] = await Promise.all(
            data.map(({ id }) => fetchAutomationsByIntegrationId(id)),
          )
          integrationList.forEach((integration, index) => {
            integration.automationList = response[index].data
          })
          commit('setIntegrationList', integrationList)
          if (withCustomIntegrations) await dispatch('setCustomIntegrationList')
        }
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
      commit('setLoading', false)
    },
    async createIntegration(
      { commit, dispatch }: ActionContext<IntegrationsState, StateInterface>,
      createIntegrationRequestBody: CreateIntegrationRequestBody,
    ): Promise<boolean> {
      commit('setLoading', true)
      let localStatus = 0
      try {
        const { data, status } = await createIntegration({
          ...createIntegrationRequestBody,
          // Hotglue adds '-v2' to the Stripe integration, making the platform 'stripe-v2',
          // but we only accept 'stripe'.
          platform:
            (createIntegrationRequestBody.platform as string) === 'stripe-v2'
              ? 'stripe'
              : createIntegrationRequestBody.platform,
        })
        const integration: Integration = {
          ...data,
          automationList: [],
        }
        commit('createIntegration', integration)
        localStatus = status
      } catch (e) {
        if (e.response?.data === API_ERRORS.STORE_ALREADY_CONNECTED) {
          throw API_ERRORS.STORE_ALREADY_CONNECTED
        }

        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
        throw e
      }
      commit('setLoading', false)
      return localStatus === 200
    },
    async updateIntegration(
      { commit, state, dispatch }: ActionContext<IntegrationsState, StateInterface>,
      updatedIntegration: Integration,
    ) {
      commit('setLoading', true)
      try {
        const updatedIntegrationIndex = state.integrationList.findIndex(
          ({ id }) => id === updatedIntegration.id,
        )
        if (updatedIntegrationIndex !== -1) {
          commit('updateIntegration', {
            updatedIntegrationIndex,
            updatedIntegration,
          })
        }
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
      commit('setLoading', false)
    },
    async createAutomation(
      { commit, state, dispatch }: ActionContext<IntegrationsState, StateInterface>,
      payload: {
        integrationId: string
        createAutomationRequestBody: CreateAutomationRequestBody
      },
    ) {
      commit('setLoading', true)
      try {
        const selectedIntegrationIndex = state.integrationList.findIndex(
          ({ id }) => id === payload.integrationId,
        )
        if (isDemoEnvironment) {
          const demoAutomation: Automation = {
            id: `demo-${new Date().getTime()}`,
            name: payload.createAutomationRequestBody.name,
            offsets: payload.createAutomationRequestBody.offsets,
            status: 'active',
            trigger: payload.createAutomationRequestBody.trigger,
            modifiers: payload.createAutomationRequestBody.modifiers || [],
            schedule: payload.createAutomationRequestBody.schedule,
            source: {
              type: 'product',
              ids: payload.createAutomationRequestBody.ids || [],
            },
          }
          if (selectedIntegrationIndex !== -1) {
            commit('createAutomation', {
              selectedIntegrationIndex,
              automation: demoAutomation,
            })
          }
        } else {
          const { data: automation } = await createAutomation(
            payload.integrationId,
            payload.createAutomationRequestBody,
          )
          if (selectedIntegrationIndex !== -1) {
            commit('createAutomation', {
              selectedIntegrationIndex,
              automation,
            })
          }
        }
        commit('setLoading', false)
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
    },
    async updateAutomation(
      { commit, state, getters, dispatch }: ActionContext<IntegrationsState, StateInterface>,
      payload: {
        automationId: string
        createAutomationRequestBody: UpdateAutomationRequestBody
      },
    ) {
      commit('setLoading', true)
      try {
        const integrationId = getters.getIntegrationIdByAutomationId(payload.automationId)
        if (!integrationId) {
          throw new Error('integrationId not found')
        }
        const selectedIntegrationIndex = state.integrationList.findIndex(
          ({ id }) => id === integrationId,
        )
        const selectedAutomationIndex = state.integrationList[
          selectedIntegrationIndex
        ].automationList.findIndex(({ id }) => id === payload.automationId)
        if (selectedAutomationIndex === -1) {
          throw new Error('automation not found')
        }
        if (isDemoEnvironment) {
          const currentAutomation: Automation = getters.getAutomationById(payload.automationId)
          Object.keys(currentAutomation).forEach((key) => {
            if (payload.createAutomationRequestBody[key]) {
              currentAutomation[key] = payload.createAutomationRequestBody[key]
            }
          })
          if (payload.createAutomationRequestBody.ids?.length) {
            currentAutomation.source = {
              type: 'product',
              ids: payload.createAutomationRequestBody.ids,
            }
          }
          commit('updateAutomation', {
            selectedIntegrationIndex,
            selectedAutomationIndex,
            automation: currentAutomation,
          })
        } else {
          const { data: automation } = await updateAutomation(
            integrationId,
            payload.automationId,
            payload.createAutomationRequestBody,
          )
          commit('updateAutomation', {
            selectedIntegrationIndex,
            selectedAutomationIndex,
            automation,
          })
        }
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.success_automation_updated'),
            isError: false,
          } as Notification,
          { root: true },
        )
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
      commit('setLoading', false)
    },
    async deleteAutomation(
      { commit, state, getters, dispatch }: ActionContext<IntegrationsState, StateInterface>,
      automationId: string,
    ) {
      commit('setLoading', true)
      const integrationId = getters.getIntegrationIdByAutomationId(automationId)
      try {
        if (!isDemoEnvironment) await deleteAutomation(integrationId, automationId)
        const updatedIntegrationIndex = state.integrationList.findIndex(
          ({ id }) => id === integrationId,
        )
        if (updatedIntegrationIndex !== -1) {
          const updatedIntegration = state.integrationList[updatedIntegrationIndex]
          updatedIntegration.automationList = updatedIntegration.automationList.filter(
            ({ id }) => id !== automationId,
          )
          commit('updateIntegration', {
            updatedIntegrationIndex,
            updatedIntegration,
          })
          dispatch(
            'notification/notify',
            {
              text: VueI18n.global.t('CommonUi.success_automation_deleted'),
              isError: false,
            } as Notification,
            { root: true },
          )
        }
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
      commit('setLoading', false)
    },
    async deleteIntegration(
      { commit, dispatch }: ActionContext<IntegrationsState, StateInterface>,
      integrationId: string,
    ) {
      commit('setLoading', true)
      try {
        await deleteIntegration(integrationId)
        commit('deleteIntegration', integrationId)
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
      commit('setLoading', false)
    },
    async fetchIntegrationOptions({ commit, getters }, searchTerm?: string) {
      const { data } = await getIntegrations(searchTerm === 'active' ? '' : searchTerm)
      let integrationOptionList: IntegrationOption[] = data.map((integrationOption) => {
        return {
          ...integrationOption,
          id: integrationOption._id,
          activeNumber: getters.getIntegrationsAmountByPlatform(integrationOption.slug),
        }
      })
      if (searchTerm === 'active') {
        integrationOptionList = integrationOptionList.filter(({ activeNumber }) => !!activeNumber)
      }
      const sortedIntegrationOptionList = integrationOptionList.sort((a, b) => {
        return b.activeNumber - a.activeNumber || a.name.localeCompare(b.name)
      })
      if (sortedIntegrationOptionList.some(({ slug }) => slug === 'custom')) {
        sortedIntegrationOptionList.push(
          integrationOptionList.splice(
            integrationOptionList.indexOf(
              integrationOptionList.find(({ slug }) => slug === 'custom') as IntegrationOption,
            ),
            1,
          )[0],
        )
      }
      commit('setIntegrationOptions', sortedIntegrationOptionList)
    },
    async setCustomIntegrationList({ commit, getters }) {
      if (getters.getAccountTypeIsBusiness) {
        const { data } = await getCustomIntegrations()
        commit('setCustomIntegrationList', data)
      }
    },
    async deleteTrigger(
      { commit, state, dispatch },
      payload: { customIntegrationId: string; triggerId: string },
    ) {
      commit('setLoading', true)
      try {
        await deleteCustomIntegrationTrigger(payload.customIntegrationId, payload.triggerId)
        const updatedTriggerList = state.customIntegrationList
          .find(({ _id }) => _id === payload.customIntegrationId)
          ?.triggers.filter(({ _id }) => _id !== payload.triggerId)
        const customIntegrationIndex = state.customIntegrationList.findIndex(
          ({ _id }) => _id === payload.customIntegrationId,
        )
        if (updatedTriggerList) {
          commit('updateTriggerList', {
            customIntegrationIndex,
            updatedTriggerList,
          })
          dispatch(
            'notification/notify',
            {
              text: VueI18n.global.t('CommonUi.success_trigger_updated'),
              isError: false,
            } as Notification,
            { root: true },
          )
        }
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
      commit('setLoading', false)
    },
    async editTrigger(
      { commit, state, dispatch },
      payload: {
        customIntegrationId: string
        triggerId: string
        triggerName: string
        triggerType: CustomIntegrationTriggerType
      },
    ) {
      commit('setLoading', true)
      try {
        const { data } = await updateCustomIntegrationTrigger(
          payload.customIntegrationId,
          payload.triggerId,
          payload.triggerName,
          payload.triggerType,
        )
        const updatedTriggerList = data.triggers
        const customIntegrationIndex = state.customIntegrationList.findIndex(
          ({ _id }) => _id === payload.customIntegrationId,
        )
        if (updatedTriggerList) {
          commit('updateTriggerList', {
            customIntegrationIndex,
            updatedTriggerList,
          })
          dispatch(
            'notification/notify',
            {
              text: VueI18n.global.t('CommonUi.success_trigger_updated'),
              isError: false,
            } as Notification,
            { root: true },
          )
        }
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
      commit('setLoading', false)
    },
    async deleteSource({ commit, state, dispatch }, payload: { customIntegrationId: string }) {
      commit('setLoading', true)
      try {
        await deleteCustomIntegration(payload.customIntegrationId)
        const updatedCustomIntegrationList = state.customIntegrationList.filter(
          ({ _id }) => _id !== payload.customIntegrationId,
        )
        commit('setCustomIntegrationList', updatedCustomIntegrationList)
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
      commit('setLoading', false)
    },
    async editSource(
      { commit, state, dispatch },
      payload: { customIntegrationId: string; sourceName: string; sourceUrl: string },
    ) {
      commit('setLoading', true)
      try {
        const { data } = await updateCustomIntegration(
          payload.customIntegrationId,
          payload.sourceName,
          payload.sourceUrl,
        )
        const updatedCustomIntegration = data
        const customIntegrationIndex = state.customIntegrationList.findIndex(
          ({ _id }) => _id === payload.customIntegrationId,
        )
        if (customIntegrationIndex !== -1) {
          commit('updateCustomIntegration', {
            updatedCustomIntegration,
            customIntegrationIndex,
          })
          dispatch(
            'notification/notify',
            {
              text: VueI18n.global.t('CommonUi.success_source_updated'),
              isError: false,
            } as Notification,
            { root: true },
          )
        }
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
      commit('setLoading', false)
    },
  },
}
