<template>
  <div>
    <div class="description">
      <div class="description-header">
        {{ t('header') }}
      </div>
      <div class="description-text">
        {{ t('text') }}
      </div>
    </div>
    <ProjectCardList>
      <ProjectCard
        v-for="(project, a) in projects"
        :key="a"
        :title="project.name"
        :image="project.imageUrl"
        :link="project.link"
      />
    </ProjectCardList>
  </div>
</template>

<script lang="ts">
import ProjectCard from '@/components/projects/ProjectCard.vue'
import ProjectCardList from '@/components/projects/ProjectCardList.vue'
import type { Project } from '@/store/projects'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SupportedProjectsAggregate',
  components: {
    ProjectCardList,
    ProjectCard,
  },
  created() {
    this.setProjectList()
  },
  methods: {
    t(key: string, params?: { [k: string]: string }) {
      return this.$t(`SupportedProjectsAggregate.${key}`, params ?? {})
    },
    setProjectList(): Promise<void> {
      return this.$store.dispatch('setProjectList')
    },
  },
  computed: {
    projects(): Project[] {
      return this.$store.getters['getProjectsWithLink']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.description {
  padding: 0 120px;
  margin-bottom: 48px;
}

.description-header {
  margin-bottom: 10px;
  color: #212121;
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
}

.description-text {
  color: #212121;
  font-size: 18px;
  margin-top: 16px;
  text-align: center;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .description {
    padding: 0 20px;
    margin: 20px 0;
  }

  .description-header {
    margin-bottom: 10px;
    font-size: 26px;
    line-height: 40px;
  }

  .description-text {
    font-size: 18px;
    margin-top: 16px;
  }
}
</style>
