<template>
  <section class="widget-row-section">
    <div v-if="loaded" class="widget-row">
      <WidgetPreview
        v-for="(color, index) in brandColors"
        :key="index"
        :color="color"
        :trigger="widgetType?.trigger"
        :api-key="widgetApiKey"
      >
        <template v-slot:widget>
          <WidgetRowItem
            :color="color"
            :flavor-text="widgetFlavorText"
            :widget-labels="widgetLabels"
            :is-used-in-dialog="true"
            :is-icon-visible="widgetType?.trigger === 'offsetPerCurrentCart'"
            :icon-types="currentCartOffsetTypes"
          />
        </template>
      </WidgetPreview>
    </div>
  </section>
</template>
<script lang="ts">
import { getCurrencySymbol } from '@/helpers/pricing'
import { BRAND_COLORS, PERCENTAGE_TRIGGERS } from '@/helpers/interfaces'
import WidgetPreview from '@/components/shareables/WidgetPreview.vue'
import WidgetRowItem from '@/components/shareables/WidgetRowItem.vue'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { defineComponent } from 'vue'

import type { BrandColor, CurrencyCode, OffsetType } from '@/helpers/interfaces'
import type { DialogMode } from '@/components/shareables/WidgetDialog.vue'
import type { TranslateResult } from 'vue-i18n'
import type { PropType } from 'vue'
import type { ApiKeyResponse } from '@api/index'

export default defineComponent({
  name: 'WidgetRow',
  components: {
    WidgetRowItem,
    WidgetPreview,
  },
  mixins: [LoadCrispMixin],
  data() {
    return {
      code: '',
      loaded: false,
      shopify: '',
      buttonActive: false,
      windowWidth: 0,
      currentCartOffsetTypes: [],
      widgetLabels: [],
      widgetFlavorText: '',
      brandColors: BRAND_COLORS,
    } as {
      code: string
      loaded: boolean
      shopify: string
      buttonActive: boolean
      windowWidth: number
      currentCartOffsetTypes: OffsetType[]
      widgetLabels: TranslateResult[]
      widgetFlavorText: TranslateResult
      brandColors: BrandColor[]
    }
  },
  computed: {
    dialogMode(): DialogMode {
      return this.widgetType?.trigger === 'offsetPerProduct' ? 'product' : 'widget'
    },
    currency(): CurrencyCode {
      return this.$store.getters['getUserCurrencyCode']
    },
  },
  async created() {
    window.addEventListener('resize', this.reportWindowSize)
    this.reportWindowSize()
    this.setWidgetFlavorText()
    this.setWidgetLabel()
    this.loaded = true
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize)
  },
  methods: {
    t(key: string, params?: { [k: string]: number | string | null }) {
      return this.$t(`WidgetRow.${key}`, params ?? {})
    },
    reportWindowSize() {
      this.windowWidth = window.innerWidth
    },
    setWidgetFlavorText() {
      switch (this.widgetType?.trigger) {
        case 'offsetByPercentageOrder':
          if (this.widgetType.status === 'active') {
            this.widgetFlavorText = this.t('offset_by_percentage_order_active_flavor_text', {
              percentage:
                this.widgetType.modifiers?.find(({ type }) => type === 'percentage')?.numberValue ||
                0,
            })
          } else {
            this.widgetFlavorText = this.t('offset_by_percentage_order_inactive_flavor_text')
          }
          break
        case 'byPercentageOfRevenue':
          if (this.widgetType.status === 'active') {
            this.widgetFlavorText = this.t('by_percentage_of_revenue_active_flavor_text', {
              percentage:
                this.widgetType.modifiers?.find(({ type }) => type === 'percentage')?.numberValue ||
                0,
            })
          } else {
            this.widgetFlavorText = this.t('by_percentage_of_revenue_inactive_flavor_text')
          }
          break
        case 'offsetBySpend':
          if (this.widgetType.status === 'active') {
            this.widgetFlavorText = this.t('offset_by_spend_active_flavor_text', {
              currency: getCurrencySymbol(this.currency),
              spendAmount:
                this.widgetType.modifiers?.find(({ type }) => type === 'spendAmount')
                  ?.numberValue || 0,
            })
          } else {
            this.widgetFlavorText = this.t('offset_by_spend_inactive_flavor_text')
          }
          break
        case 'offsetByStoreRevenue':
          if (this.widgetType.status === 'active') {
            this.widgetFlavorText = this.t('offset_by_store_revenue_active_flavor_text', {
              currency: getCurrencySymbol(this.currency),
              threshold:
                this.widgetType.modifiers?.find(({ type }) => type === 'threshold')?.numberValue ||
                0,
            })
          } else {
            this.widgetFlavorText = this.t('offset_by_store_revenue_inactive_flavor_text')
          }
          break
        case 'offsetPerOrder':
          if (this.widgetType.status === 'active') {
            this.widgetFlavorText = this.t('offset_by_per_order_active_flavor_text')
          } else {
            this.widgetFlavorText = this.t('offset_by_per_order_inactive_flavor_text')
          }
          break
        case 'offsetPerProduct':
          if (this.widgetType.status === 'active') {
            const textsPerOffsetTypes = {
              Trees: 'plants',
              carbon: 'offsets',
              plastic: 'rescues',
            }
            this.widgetFlavorText = this.t('offset_by_per_product_active_flavor_text', {
              offsetText: textsPerOffsetTypes.Trees,
            })
          } else {
            this.widgetFlavorText = this.t('offset_by_per_product_inactive_flavor_text')
          }
          break
        case 'offsetPerCurrentCart': {
          if (this.widgetType.status === 'active') {
            this.widgetFlavorText = this.t('offset_per_current_cart_flavor_text')
          } else {
            this.widgetFlavorText = this.t('offset_per_current_cart_flavor_text_inactive')
          }
          break
        }
      }
    },
    getWidgetLabel(type: OffsetType, amount): TranslateResult {
      switch (type) {
        case 'carbon':
          return amount > 1
            ? this.t('carbon_widget_label_plural', { amount: amount.toLocaleString() })
            : this.t('carbon_widget_label', { amount: amount })
        case 'plastic':
          return amount > 1
            ? this.t('plastic_widget_label_plural', { amount: amount.toLocaleString() })
            : this.t('plastic_widget_label', { amount: amount })
        case 'trees':
          return amount > 1
            ? this.t('trees_widget_label_plural', { amount: amount.toLocaleString() })
            : this.t('trees_widget_label', { amount: amount })
        case 'kelp':
          return amount > 1
            ? this.t('kelp_widget_label_plural', { amount: amount.toLocaleString() })
            : this.t('kelp_widget_label', { amount: amount })
        default: {
          const exhaustiveCheck: never = type
          throw new Error(exhaustiveCheck)
        }
      }
    },
    setWidgetLabel() {
      // if the widget status is active then we need to show the labels
      // otherwise we don't need to show the labels, there will be a flavor text displayed
      // which will tell the user to create an automation with the trigger type in order to activate the widget
      if (this.widgetType?.status === 'active') {
        this.widgetType.offsets.forEach(({ amount, type }) => {
          this.widgetLabels.push(this.getWidgetLabel(type, amount))
        })
        if (PERCENTAGE_TRIGGERS.includes(this.widgetType.trigger)) {
          const offsetByPercentageOrderTexts = {
            trees: this.t('offset_by_percentage_order_label_trees'),
            kelp: this.t('offset_by_percentage_order_label_kelp'),
            carbon: this.t('offset_by_percentage_order_label_carbon'),
            plastic: this.t('offset_by_percentage_order_label_plastic'),
          }
          this.widgetLabels.push(offsetByPercentageOrderTexts[this.widgetType.offsets[0].type])
        }
        if (this.widgetType.trigger === 'offsetPerProduct') {
          const offsets = {
            trees: this.t('offset_by_product_label_trees_plural', { amount: 2 }),
            carbon: this.t('offset_by_product_label_carbon_plural', { amount: 2 }),
            plastic: this.t('offset_by_product_label_plastic_plural', { amount: 2 }),
          }
          this.widgetLabels = [...Object.values(offsets)]
        }
        if (this.widgetType.trigger === 'offsetPerCurrentCart') {
          this.widgetType.offsets.forEach(({ type }) => {
            this.currentCartOffsetTypes.push(type)
          })
        }
      }
    },
    onShopifyChange() {
      this.buttonActive = this.shopify !== ''
    },
  },
  props: {
    widgetType: {
      type: Object,
      required: true,
    },
    widgetApiKey: {
      type: Object as PropType<ApiKeyResponse>,
      required: false,
    },
  },
  watch: {
    shopify: [
      {
        handler: 'onShopifyChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.widget-row-section {
  width: 100%;
}

.widget-header {
  color: #212121;
  font-size: 22px;
  font-weight: 600;
  margin-top: 18px;
}

.divider-line {
  height: 1px;
  width: 100%;
  background-color: black;
  opacity: 0.2;
  margin-top: 14px;
}

.widget-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.button {
  margin-top: 18px;
  border-radius: 0;
  border-bottom: 2px solid;
  padding: 0 !important;
}

.button-text {
  color: #f9f9f9;
  font-weight: 600;
  font-size: 12px;
}

.button-text-send {
  color: #3b755f;
  font-weight: 600;
  font-size: 12px;
}

.pop-up {
  background: #f9f9f9;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  height: 100%;
}

.close-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
  cursor: pointer;
}

.widget-overlay {
  width: 70%;
}

.pop-up-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.overlay-text {
  text-align: center;
  color: #212121;
  font-size: 15px;
  margin-top: 12px;
}

.code {
  resize: none;
  width: 95%;
  padding: 10px;
  background-color: rgb(235, 235, 235);
  border-color: rgb(140, 149, 158);
  color: #212121;
  font-size: 14px;
  margin-top: 15px;
  word-break: break-all;
}

.button-wrapper {
  display: flex;
  margin-top: 12px;
}

.how-it-works {
  margin-top: 14px;
  margin-bottom: 14px;
  width: 100%;
}

.hiw-title {
  font-weight: bold;
  margin-bottom: 14px;
}

.number-explanation-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.number {
  border: 2px solid #3b755f;
  border-radius: 50%;
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3b755f;
  font-weight: 600;
  margin-right: 8px;
}

.explanation {
  font-size: 14px;
}

.dropdown {
  width: 100%;
}

.copy-item-wrapper {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: bold;
  // margin-right: 10px;
  margin-bottom: -7px;
  // width: 100%;
}

.copy-inner {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.item-to-copy {
  padding: 5px 20px;
  background: #f2ebdb;
  height: 54px;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  max-width: 100%;
  white-space: nowrap;
  width: 355px;
}

.switch {
  cursor: pointer;
  color: #3b755f;
  font-size: 12px;
  margin-top: 12px;
}

.switch:hover {
  opacity: 80;
}

.support-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.support {
  font-size: 14px;
}

.green-text {
  padding-left: 2px;
  color: #3b755f;
  text-decoration: underline;
  cursor: pointer;
}

.green-text:hover {
  opacity: 80;
}
</style>
