<template>
  <div v-if="loaded">
    <div class="header-tooltip-wrapper">
      <div class="partner-impact-header">
        {{ t('header') }}
      </div>
    </div>

    <div class="partner-subheader">
      {{ t('funded') }}
    </div>
    <div class="partner-text">
      {{ t('funded_text') }}
    </div>

    <ReferralStats :referrals-count="partnersNumber" :impacts="relationImpact" type="partners" />

    <div class="partner-subheader" style="margin-top: 24px">
      {{ t('total') }}
    </div>
    <div class="partner-text">
      {{ t('total_text') }}
    </div>

    <ReferralStats :impacts="relationImpactTotal" type="partners" />
  </div>
</template>

<script lang="ts">
import ReferralStats from '@/components/referrals/ReferralStats.vue'
import { getOverallAccountImpactFromRelations } from '@api/index'
import type { OffsetType } from '@/helpers/interfaces'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PartnerImpact',
  emits: ['getFreshDataClick'],
  components: {
    ReferralStats,
  },
  data() {
    return {
      loaded: false,
      partnersNumber: 0,
      relationImpact: {} as { [key in OffsetType]: number },
      relationImpactTotal: {} as { [key in OffsetType]: number },
    }
  },
  async created() {
    await this.getOverallImpact()
    this.loaded = true
  },
  methods: {
    t(key: string) {
      return this.$t(`PartnerImpact.${key}`)
    },
    async getOverallImpact() {
      const { count, relationSumImpact, relatedUserSumImpact } =
        await getOverallAccountImpactFromRelations('partner')
      this.partnersNumber = count
      this.relationImpactTotal = relatedUserSumImpact
      this.relationImpact = relationSumImpact
    },
    async onGetFreshDataChange() {
      if (this.getFreshData) {
        this.loaded = false
        await this.getOverallImpact()
        this.loaded = true
        this.$emit('getFreshDataClick')
      }
    },
  },
  props: {
    getFreshData: {
      type: Boolean,
    },
  },
  watch: {
    getFreshData: [
      {
        handler: 'onGetFreshDataChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.partner-impact-header {
  color: #212121;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 16px;
  margin-right: 5px;
  line-height: 34px;
}

.partner-subheader {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #212121;
  margin-bottom: 6px;
}

.partner-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  color: #212121;
  margin-bottom: 18px;
  max-width: 574px;
}
</style>
