<template>
  <section class="top-stats-container section section--lg">
    <div class="section-header">
      <h2 class="section-title">
        {{ t(`title`) }}
      </h2>
      <p class="section-description">{{ t('description') }}</p>
    </div>
    <div class="top-stat-card-list">
      <TopStatsCard
        v-for="(value, key) in topStatDataToDisplay"
        :key="key"
        :type="key"
        :amount="value"
        :transferred-impact="getTransferredImpactByOffsetType(key)"
        :relation-impact="getRelationImpactByOffsetType(key)"
        :impact-action-impact="getImpactActionImpactByOffsetType(key)"
        :background-color="colorSettings[key] || '#A9A9A9'"
        :icon-style="'simple'"
      />
      <div class="add-more-impact-card" @click="onAddMoreImpactClick">
        <div class="add-more-impact-card-icon"></div>
        <div class="add-more-impact-card-content">
          <p class="add-more-impact-card-text">
            {{ t('add_more_impact') }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TopStatsCard from '@/components/your-impact/TopStatsCard.vue'
import type { OffsetType } from '@/helpers/interfaces'
import type { TopStatData } from '@/store'
import { IMPACT_TYPE_TOP_STAT_CARD_COLOR_MAP } from '@/helpers/projects'

export default defineComponent({
  name: 'TopStats',
  components: {
    TopStatsCard,
  },
  computed: {
    colorSettings(): Record<OffsetType, string> {
      return IMPACT_TYPE_TOP_STAT_CARD_COLOR_MAP
    },
    topStatDataToDisplay(): TopStatData {
      const data = { ...this.topStatData }

      Object.keys(data).forEach((key) => {
        if (key !== 'monthsEarthPositive' && !data[key]) {
          delete data[key]
        }
      })

      return data
    },
    topStatData(): TopStatData {
      return this.$store.getters['getTopStats']
    },
    getTransferredImpactByOffsetType(): (offsetType: keyof TopStatData) => number {
      return this.$store.getters['getTransferredImpactByOffsetType']
    },
    getRelationImpactByOffsetType(): (offsetType: keyof TopStatData) => number {
      return this.$store.getters['getRelationImpactByOffsetType']
    },
    getImpactActionImpactByOffsetType(): (offsetType: keyof TopStatData) => number {
      return this.$store.getters['getImpactActionImpactByOffsetType']
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`TopStats.${key}`)
    },
    onAddMoreImpactClick() {
      this.$router.push('add-impact')
    },
  },
})
</script>

<style scoped lang="scss">
@import '~vuetify/settings';

.section-title {
  margin-bottom: 8px;
}

.section-description {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
}

.top-stats-container {
  width: 100%;
  background: var(--ui-white);
  border-radius: 8px;
  padding: 16px 8px;
  box-shadow: 0px 4px 4px 0px #0000001a;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding: 24px;
  }
}

.section {
  position: relative;
}

.top-stat-card-list {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}

.add-more-impact-card {
  display: flex;
  width: var(--dashboard--add-more-impact-button-width);
  height: auto;
  padding: 24px 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: #dbdbdb;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }
}

.add-more-impact-card-icon {
  width: 32px;
  height: 32px;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 7px;
    top: 0;
    bottom: 0;
    left: 50%;
    background: var(--ui-black);
  }

  &::before {
    transform: translateX(-50%);
  }
  &::after {
    transform: translateX(-50%) rotate(90deg);
  }
}

.add-more-impact-card-text {
  color: var(--ui-black);
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  margin-bottom: 0;
}
</style>
