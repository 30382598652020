<template>
  <button class="icon-wrapper" :disabled="disabled" @click="shareClick">
    <v-icon class="share-icon">
      {{ 'mdi-' + platform }}
    </v-icon>
  </button>
</template>

<script lang="ts">
import type { ReferralSocialPlatform } from '@/helpers/constants'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ReferralShareIcon',
  methods: {
    t(key: string) {
      return this.$t(`ReferralShareIcon.${key}`)
    },
    shareClick() {
      switch (this.platform) {
        case 'facebook':
          window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.referralLink}`, '_blank')
          break
        case 'twitter':
          window.open(
            `https://twitter.com/intent/tweet?url=${this.referralLink}&text=Join%20our%20journey%20with%20Greenspark%20and%20maximise%20your%20positive%20impact!`,
            '_blank',
          )
          break
        case 'linkedin':
          window.open(
            `https://www.linkedin.com/shareArticle?url=https://getgreenspark.com?i%3D${this.referralLink}&title=Join%20our%20journey%20with%20Greenspark%20and%20maximise%20your%20positive%20impact!`,
            '_blank',
          )
          break
        case 'email':
          window.open(
            `mailto:?subject=Join us at Greenspark&body=Join%20our%20journey%20with%20Greenspark%20and%20maximise%20your%20positive%20impact!%20Use%20our%20referral%20link:%20${this.referralLink}`,
            '_blank',
          )
          break
      }
    },
  },
  props: {
    platform: {
      type: String as PropType<ReferralSocialPlatform>,
    },
    disabled: {
      type: Boolean,
    },
    referralLink: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.icon-wrapper {
  background: var(--ui-green);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  margin-right: 8px;
  cursor: pointer;
  flex-shrink: 0;
}

.icon-wrapper:disabled {
  background-color: var(--gray-light);
  cursor: not-allowed;
}

.icon-wrapper:hover {
  opacity: 0.8;
}

.share-icon {
  color: var(--ui-beige);
}
</style>
