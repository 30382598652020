<template>
  <v-card class="dialog-card">
    <div class="card-texts-wrapper">
      <v-card-title
        :class="[
          `${dialog?.options.titleColor}--text`,
          'font-weight-bold',
          'text-h5 d-flex py-6 pt-4 pb-3 align-center',
        ]"
      >
        {{ dialog?.texts.title }}
        <v-spacer />
        <v-btn icon @click="onClose" variant="plain">
          <v-icon color="black"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text :class="[`${dialog?.options.descriptionColor}--text`, 'text-body-1']">
        <p class="description" v-html="dialog?.texts.description" />
      </v-card-text>
    </div>
    <v-card-actions>
      <gs-button
        class="primary-button"
        type="primary"
        size="large"
        :loading="isLoading"
        @click="onApprove"
      >
        {{ dialog?.texts.primaryButtonText }}
      </gs-button>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import type { Dialog } from '@/store/dialog'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SimpleDialogTemplate',
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    onClose() {
      if (this.dialog?.actions?.onCloseButtonClick) {
        this.dialog.actions.onCloseButtonClick()
      }
      if (this.close) this.close()
    },
    async onApprove() {
      if (this.dialog?.actions?.onPrimaryButtonClick) {
        this.isLoading = true
        await this.dialog?.actions.onPrimaryButtonClick()
        this.isLoading = false
      }
      if (this.close) this.close()
    },
  },
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
    close: {
      type: Function as PropType<() => void>,
    },
  },
})
</script>

<style lang="scss" scoped>
.dialog-card {
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-texts-wrapper {
  padding: 0 8px;
}

.description:deep(span) {
  background: var(--ui-beige);
  padding: 0 5px 0;
  border-radius: 6px;
  font-weight: 600;
  white-space: nowrap;
}

.primary-button {
  position: relative;
  flex: 1 1 100%;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
}
</style>
