<template>
  <panel background="secondary" class="custom-domain-dialog">
    <p class="description">
      {{ t('custom_domain_description') }}
      <a
        href="https://help.getgreenspark.com/en/article/how-do-i-add-a-custom-domain-np3n2h/"
        target="_blank"
      >
        {{ t('custom_domain_docs') }}
      </a>
    </p>
    <div class="accordion">
      <div class="header">
        {{ t('email_domains') }}
        <button class="add-button" :disabled="isNewDomainOpen" @click.prevent="openAddNewPanel">
          New +
        </button>
      </div>
      <div v-if="localDomainList.length" class="domain-list">
        <div v-for="(item, index) in localDomainList" :key="index" class="domain-list-item">
          <div class="list-item-header" @click.prevent.stop="openEditPanel(index)">
            <div class="title-wrapper">
              <v-icon
                :class="[
                  'domain-status-icon',
                  { verified: item.validationStatus?.valid },
                  { new: !item.id },
                ]"
              >
                {{ item.validationStatus?.valid ? 'mdi-play-circle' : 'mdi-stop-circle' }}
              </v-icon>

              <p class="domain-name mb-0">
                {{ item.id ? item.domain : t('new_domain') }}
              </p>
            </div>

            <button
              v-if="item.id"
              :class="['delete-button', { 'is-active': openedDrawerIndex === index }]"
              @click.prevent.stop="openDeleteModal(item.id, item.domain)"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </button>
          </div>
          <div :class="['input-wrapper', { 'is-open': openedIndex === index }]">
            <v-form ref="domainForm" v-model="isFormValid">
              <div class="label-wrapper">
                <p class="label">
                  {{ t('from_domain') }}
                </p>
                <v-tooltip :open-on-click="isTouch" color="black" location="top" max-width="240px">
                  <template v-slot:activator="{ props }">
                    <v-icon class="tooltip-icon" v-bind="props"> mdi-information-outline </v-icon>
                  </template>
                  <span>{{ t('domain_tooltip') }}</span>
                </v-tooltip>
              </div>
              <p class="hint">
                {{ t('domain_hint') }}
              </p>
              <v-text-field
                :id="item.id"
                v-model="item.domain"
                :placeholder="t('new_domain_hint')"
                class="input-field"
                type="text"
                :rules="[rules.required, rules.domain, rules.maxlength(100)]"
                variant="outlined"
                flat
                color="green"
                hide-details
                :readonly="isReadOnly"
                :disabled="isReadOnly"
              />
              <div v-if="item.id" class="dns-setting-wrapper">
                <p class="label">
                  {{ t('dns_setting') }}
                </p>
                <p class="hint">
                  {{ t('dns_hint') }}
                </p>

                <v-table class="table mb-5" fixed-header>
                  <thead>
                    <th class="text-left">Status</th>
                    <th class="text-left">Type</th>
                    <th class="text-left">Host</th>
                    <th class="text-left">Value</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(key, index) in Object.keys(item.dns).filter(
                        (item) => item === 'dkim1' || item === 'dkim2' || item === 'mailCname',
                      )"
                      :key="index"
                    >
                      <td class="text-body-1 text-no-wrap">
                        <v-icon
                          :class="[
                            'status-icon',
                            { active: item.validationStatus?.dns[key].valid },
                          ]"
                        >
                          {{
                            item.validationStatus?.dns[key].valid
                              ? 'mdi-check-circle-outline'
                              : 'mdi-stop-circle-outline'
                          }}
                        </v-icon>
                      </td>
                      <td class="text-body-1 text-no-wrap text-uppercase">
                        {{ item.dns[key].type }}
                      </td>
                      <td class="text-body-1 text-no-wrap">
                        {{ item.dns[key].host }}
                        <copy-button :text="item.dns[key].host" />
                      </td>
                      <td class="text-body-1 text-no-wrap">
                        {{ item.dns[key].data }}
                        <copy-button :text="item.dns[key].data" />
                      </td>
                    </tr>
                  </tbody>
                </v-table>

                <p class="label">
                  {{ t('verify') }}
                </p>
                <p class="hint">
                  {{ t('verify_hint') }}
                </p>
                <v-checkbox
                  :rules="[rules.required]"
                  color="green"
                  class="checkbox"
                  hide-details
                  :label="
                    item.validationStatus?.valid ? t('verify_again_label') : t('verify_label')
                  "
                />
              </div>

              <div class="button-wrapper">
                <gs-button type="clear" size="large" @click.prevent="cancel(index)">
                  cancel
                </gs-button>
                <gs-button
                  size="large"
                  :disabled="!isFormValid"
                  :loading="loading"
                  @click.prevent="verifyDomain(index)"
                >
                  {{ buttonText(index) }}
                </gs-button>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>
    <DeleteDomainDialog
      v-if="isDeleteDialogOpen"
      ref="deleteDomainDialog"
      delete-type="domain"
      :selected-domain="selectedDomain"
      @close="isDeleteDialogOpen = false"
      @deleted="handleDelete()"
    />

    <CallToUpgradePopup
      v-if="isCallToUpgradePopupOpen"
      popup-type="domain"
      :is-premium-only="false"
      @close="isCallToUpgradePopupOpen = false"
    />
  </panel>
</template>

<script lang="ts">
import Panel from '@/components/common/Panel.vue'
import type { Notification } from '@/store/notification'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { Domain } from '@/store/customer-engagement'
import { addDomain, verifyDomain } from '@api/index'
import CopyButton from '@/components/common/copy-button.vue'
import DeleteDomainDialog from '@/components/CustomerEngagementPortal/EmailTemplates/DeleteDomainDialog.vue'
import CallToUpgradePopup from '@/components/common/CallToUpgradePopup.vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomDomain',
  components: {
    CallToUpgradePopup,
    DeleteDomainDialog,
    Panel,
    CopyButton,
  },
  mixins: [RulesMixin, Utils],
  data() {
    return {
      loading: false,
      openedIndex: null,
      openedDrawerIndex: null,
      localDomainList: [],
      isDeleteDialogOpen: false,
      selectedDomain: {},
      isFormValid: false,
      isReadOnly: false,
      isCallToUpgradePopupOpen: false,
    } as {
      loading: boolean
      isDeleteDialogOpen: boolean
      selectedDomain: Record<string, string>
      isFormValid: boolean
      isReadOnly: boolean
      isCallToUpgradePopupOpen: boolean
      localDomainList: Domain[]
      openedDrawerIndex: number | null
      openedIndex: number | null
    }
  },
  computed: {
    isNewDomainOpen(): boolean {
      return this.openedIndex === null ? false : !this.localDomainList[this.openedIndex].id
    },
    savedDomainList(): Domain[] {
      return this.$store.getters['getSavedDomainList']
    },
    maxCustomDomainAmount(): number {
      return this.$store.getters['getMaxCustomDomainAmount']
    },
  },
  async created() {
    this.localDomainList = [...this.savedDomainList]
    if (!this.localDomainList.length) {
      this.openAddNewPanel()
    }
  },
  methods: {
    t(key: string) {
      return this.$t(`CustomDomain.${key}`)
    },
    openAddNewPanel() {
      if (this.localDomainList.length >= this.maxCustomDomainAmount) {
        this.isCallToUpgradePopupOpen = true
      } else {
        this.isReadOnly = false
        this.localDomainList.push({
          id: '',
          domain: '',
          dns: {
            mailCname: { host: '', data: '', type: '', valid: false },
            dkim1: { host: '', data: '', type: '', valid: false },
            dkim2: { host: '', data: '', type: '', valid: false },
          },
        })
        this.openedIndex = this.localDomainList.length - 1
      }
    },
    cancel(index: number) {
      this.openedIndex = null
      if (!this.localDomainList[index].id) this.localDomainList.pop()
    },
    openEditPanel(index: number) {
      if (this.openedIndex === index) {
        this.openedIndex = null
      } else {
        this.isReadOnly = !!this.localDomainList[index].id
        this.openedIndex = index
      }
    },
    openDeleteModal(domainId, domainName) {
      this.selectedDomain = { domainId, domainName }
      this.isDeleteDialogOpen = true
    },
    handleDelete() {
      this.openedIndex = null
      this.localDomainList = [...this.savedDomainList]
    },
    async verifyDomain(index) {
      this.loading = true
      if (this.localDomainList[index].id) {
        try {
          const { data } = await verifyDomain(this.localDomainList[index].id)
          await this.setDomainList()
          this.localDomainList = [...this.savedDomainList]
          data.validationStatus?.valid
            ? this.$store.dispatch('notification/notify', {
                text: this.t('domain_verified'),
              } as Notification)
            : this.$store.dispatch('notification/notify', {
                text: 'Verification unsuccessful: Please make sure you have added the correct records to your DNS settings. It may also take up to 24 hours for your domain registrar to process the new settings so please check back later.',
                isError: true,
                isClosable: true,
                buttonText: 'close',
              } as Notification)
        } catch (e) {
          this.$store.dispatch('notification/notify', {
            text: e?.message,
            isError: true,
          } as Notification)
        }
        this.loading = false
      } else {
        try {
          await addDomain(this.localDomainList[index].domain)
          await this.setDomainList()
          this.localDomainList = [...this.savedDomainList]
          this.isReadOnly = true
        } catch (e) {
          this.$store.dispatch('notification/notify', {
            text: e?.message,
            isError: true,
          } as Notification)
        }
        this.loading = false
      }
    },
    buttonText(index) {
      if (this.localDomainList[index].id && this.localDomainList[index].validationStatus?.valid) {
        return this.t('verified')
      } else if (
        this.localDomainList[index].id &&
        !this.localDomainList[index].validationStatus?.valid
      ) {
        return this.t('verify')
      } else {
        return this.t('next')
      }
    },
    onLocalDomainListChange() {
      if (!this.localDomainList.length) {
        this.openAddNewPanel()
      }
    },
    setDomainList(): Promise<Promise<void>> {
      return this.$store.dispatch('setDomainList')
    },
  },
  watch: {
    localDomainList: [
      {
        handler: 'onLocalDomainListChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.description {
  font-size: 18px;
  line-height: 22px;
  max-width: 63ch;
  margin-bottom: 20px;
}

.accordion {
  background-color: white;
  border-radius: var(--border-radius-big);
  margin-bottom: 20px;
}

.button-wrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
  width: 100%;
}

.button-wrapper > button {
  width: 100%;
}

.save-button {
  color: var(--ui-white);
  margin-bottom: 20px;
  position: relative;
}

.textarea {
  max-height: none; // .v-input reset
}

.input-wrapper {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  padding: 0 20px;
  transition: all linear 0.2s;
}

.input-wrapper.is-open {
  max-height: 1000px;
  padding: 20px;
  transition: all linear 0.2s;
  visibility: visible;
}

.domain-list-item .input-wrapper.is-open {
  border-top: none;
}

.domain-list-item:not(:last-of-type) .input-wrapper.is-open {
  border-bottom: 1px solid var(--gray);
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 18px;
  padding: 15px 10px;
  border-bottom: 1px solid var(--gray);
}

.add-button {
  border: 1px solid var(--ui-green);
  border-radius: 10px;
  padding: 0 5px;
  color: var(--ui-green);
  font-size: 14px;
}

.list-item-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding: 15px 10px;
  position: relative;
  width: 100%;
}

.list-item-header:hover {
  background-color: var(--ui-green-light-20);
  cursor: pointer;
}

.domain-list-item:not(:last-of-type) .list-item-header {
  border-bottom: 1px solid var(--gray);
}

.domain-name {
  font-size: 18px;
  font-weight: 600;
}

.title-wrapper {
  align-items: center;
  display: flex;
}

.domain-status-icon,
.domain-status-icon.new {
  color: transparent;
  border: 1px solid var(--gray-light);
  border-radius: 50%;
  margin-right: 6px;
  height: 24px;
  width: 24px;
  font-size: 24px;
  line-height: 24px;
}

.domain-status-icon:not(.verified):not(.new) {
  border: none;
  color: var(--ui-red);
  border-radius: 50%;
  margin-right: 6px;
  height: 24px;
  width: 24px;
  font-size: 24px;
  line-height: 24px;
}

.domain-status-icon.verified {
  border: none;
  color: var(--ui-green);
}

.title-wrapper.add-new {
  margin-bottom: 15px;
}

.delete-button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-button.is-active,
.delete-button:hover {
  background-color: var(--gray-light);
}

.label-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tooltip-icon {
  color: var(--font-color-primary);
  cursor: pointer;
}

.label {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0;
}

.hint {
  font-size: 14px;
  line-height: 16px;
  color: var(--gray-light);
  margin-bottom: 10px;
  max-width: 45ch;
}

.checkbox {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 40px;
}

.input-field {
  margin-bottom: 40px;
}

.status-icon:not(.active) {
  color: var(--ui-red);
}

.status-icon.active {
  color: var(--ui-green);
}

.table {
  border: 1px solid var(--gray-light);
  border-radius: 10px;
}

.table th {
  padding: 16px 16px;
  background: none !important;
}

.table tr:hover {
  background: none !important;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .dialog {
    flex-direction: column;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .button-wrapper {
    justify-content: flex-end;
    flex-direction: row;
    gap: 10px;
  }

  .button-wrapper > button:first-child {
    width: auto;
  }

  .button-wrapper > button:last-child {
    width: 200px;
  }

  .save-button {
    order: 2;
    width: 150px;
    margin-bottom: 0;
  }

  .cancel-button {
    order: 1;
  }

  .drawer-button {
    text-transform: capitalize;
  }

  .input-wrapper {
    padding: 0 40px;
  }

  .input-wrapper.is-open {
    padding: 20px 40px;
  }

  .dialog {
    display: flex;
    gap: 40px;
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
  }

  .checkbox {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 20px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .custom-domain-dialog {
    margin: 0 auto;
    max-width: 1080px;
  }
}
</style>
