import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06f4e853"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project-card-wrapper" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "project-id" }
const _hoisted_5 = { class: "project-title" }
const _hoisted_6 = { class: "project-learn-more" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("a", {
      href: _ctx.link,
      target: "_blank",
      class: "project-card-link"
    }, [
      _createElementVNode("img", {
        class: "project-image",
        alt: "supported-project-image",
        src: _ctx.image
      }, null, 8, _hoisted_3),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.titleId), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.titleSub), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.t('learn')), 1)
    ], 8, _hoisted_2)
  ]))
}