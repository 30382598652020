<template>
  <div class="user-component">
    <div class="user-component-wrapper">
      <img class="user-image" :src="require('@/assets/create-account-shape.jpg')" alt="signup" />
      <div class="user-wrapper">
        <div class="form-wrapper">
          <img class="logo" :src="require('@/assets/greenspark-logo.svg')" alt="greenspark-app" />
          <div class="header">
            {{ t('welcome') }}
          </div>

          <div class="subtext">
            {{ t('subtext') }}
          </div>

          <div class="how-it-works-wrapper">
            <div class="how-it-works-header">
              {{ t('how_it_works') }}
            </div>

            <div class="number-text-wrapper">
              <div class="number">1</div>
              <div class="text">
                {{ t('one') }}
              </div>
            </div>

            <div class="number-text-wrapper">
              <div class="number">2</div>
              <div class="text">
                {{ t('two') }}
              </div>
            </div>

            <div class="number-text-wrapper">
              <div class="number">3</div>
              <div class="text">
                {{ t('three') }}
              </div>
            </div>
          </div>

          <div class="button-wrapper">
            <div v-if="error" class="error-message">
              {{ t('error') }} <span class="login" @click="$router.push('/login')">Login</span>
            </div>
            <gs-button :disabled="error" :full-width="true" size="large" @click.prevent="next">
              {{ t('continue') }}
            </gs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SignupPreInfo',
  emits: ['nextClick'],
  methods: {
    t(key: string) {
      return this.$t(`SignupPreInfo.${key}`)
    },
    next() {
      this.$emit('nextClick')
    },
  },
  props: {
    error: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.user-component {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.user-component-wrapper {
  width: 100%;
  border-radius: 8px;
  display: flex;
  box-shadow: 0 42px 76px rgba(0, 0, 0, 0.05), 0 27.2222px 44.5093px rgba(0, 0, 0, 0.037963),
    0 16.1778px 24.2074px rgba(0, 0, 0, 0.0303704), 0 8.4px 12.35px rgba(0, 0, 0, 0.025),
    0 3.42222px 6.19259px rgba(0, 0, 0, 0.0196296), 0 0.777778px 2.99074px rgba(0, 0, 0, 0.012037);
}

.user-image {
  width: 400px;
  max-width: 45%;
  max-height: 640px;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.user-wrapper {
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

.form-wrapper {
  padding: 60px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 214px;
}

.header {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: #212121;
  margin-bottom: 24px;
  margin-top: 16px;
}

.subtext {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  color: #212121;
}

.how-it-works-wrapper {
  width: 100%;
  margin-top: 32px;
}

.how-it-works-header {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 29px;
  text-align: left;
  color: #212121;
  margin-bottom: 16px;
}

.number-text-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.number {
  height: 27px;
  width: 27px;
  min-height: 27px;
  min-width: 27px;
  border-radius: 50%;
  background: #f2ebdb;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  margin-left: 8px;
}

.button-wrapper {
  width: 100%;
  margin-top: 45px;
}

.error-message {
  font-size: 16px;
  color: #f9f9f9;
  font-weight: 500;
  text-align: left;
  width: 100%;
  padding: 8px 20px;
  background: red;
  margin-bottom: 10px;
}

.login {
  text-decoration: underline;
  cursor: pointer;
}

@media #{map-get($display-breakpoints, 'md')} {
  .user-component-wrapper {
    margin-bottom: 20px;
  }

  .user-image {
    display: none;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .user-wrapper {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 34px;
  }

  .header {
    text-align: center;
  }

  .user-component-wrapper {
    margin-bottom: 20px;
  }

  .user-image {
    display: none;
  }

  .user-image {
    display: none;
  }

  .form-wrapper {
    padding: 10px;
  }
}
</style>
