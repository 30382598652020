<template>
  <div>
    <div class="login">
      <div class="user-component">
        <div class="user-component-wrapper">
          <img class="user-image" :src="require('@/assets/login-image.svg')" alt="login" />
          <div class="user-wrapper">
            <div class="form-wrapper">
              <div class="logo-wrapper">
                <img
                  class="logo"
                  :src="require('@/assets/greenspark-logo.svg')"
                  alt="greenspark-app"
                />
              </div>
              <div class="header">
                {{ t('login') }}
              </div>
              <v-form v-model="isFormValid">
                <div class="name-wrapper">
                  <div class="text-field-wrapper">
                    <v-text-field
                      id="Email"
                      v-model="loginDetails.email"
                      name="Email"
                      autocomplete="email"
                      type="email"
                      :rules="[rules.required, rules.email]"
                      :label="t('email')"
                      variant="outlined"
                      flat
                      color="green"
                      hide-details
                      @keyup.enter="login"
                    />
                  </div>
                </div>

                <div class="name-wrapper">
                  <div class="text-field-wrapper">
                    <v-text-field
                      id="Password"
                      v-model="loginDetails.password"
                      name="password"
                      autocomplete="password"
                      :type="showPassword ? 'text' : 'password'"
                      :label="t('password')"
                      :rules="[rules.required]"
                      :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      variant="outlined"
                      flat
                      color="green"
                      hide-details
                      @click:append-inner="showPassword = !showPassword"
                      @keyup.enter="login"
                    />
                  </div>
                </div>
              </v-form>
              <div class="forgot-password">
                <router-link
                  :to="fromAppMarketplace ? '/reset-password?app' : '/reset-password'"
                  class="forgot-password-text"
                >
                  {{ t('forgot_password') }}
                </router-link>
              </div>

              <div class="button-wrapper">
                <gs-button
                  :disabled="!loading && !isFormValid"
                  :full-width="true"
                  size="large"
                  @click.prevent="login"
                >
                  {{ t('login') }}
                </gs-button>
              </div>
              <div v-if="loginError" class="login-error">
                {{ t('login_error') }}
              </div>

              <div v-if="!fromAppMarketplace" class="not-member">
                {{ t('not_member') }}
                <span class="green-text" @click="$router.push('/choose-signup')">{{
                  t('signup')
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition v-if="loading" name="fade">
      <Loading />
    </transition>
  </div>
</template>

<script lang="ts">
import { getDataForShopifySignup, userLogin } from '@api/index'
import Loading from '@/components/tools/Loading.vue'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { User } from '@/store/users'
import { defineComponent } from 'vue'
import { getSubscriptions } from '@api/index'
import { setUpRecurringChargeShopify } from '@api/index'
import type { CreateIntegrationRequestBody } from '@api/index'

import type { Notification } from '@/store/notification'
import { API_ERRORS } from '@/helpers/constants'

export default defineComponent({
  name: 'LoginView',
  components: { Loading },
  mixins: [LoadCrispMixin, RulesMixin],
  data() {
    return {
      loginDetails: {
        email: '',
        password: '',
      },
      showPassword: false,
      loading: false,
      isFormValid: false,
      windowWidth: 0,
      fromAppMarketplace: false,
    }
  },
  computed: {
    loginWithPublicToken(): boolean {
      return this.$route.query.withPublicToken === 'true'
    },
    loginError(): boolean {
      return this.$store.getters['getLoginError']
    },
    publicToken(): string {
      return this.$store.getters['getPublicToken']
    },
  },
  async created() {
    await this.routeCheck()
    this.toggleChatBubbleVisibility(true)
    window.addEventListener('resize', this.reportWindowSize)
    this.reportWindowSize()
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize)
  },
  methods: {
    t(key: string) {
      return this.$t(`LoginView.${key}`)
    },
    reportWindowSize() {
      this.windowWidth = window.innerWidth
    },
    async login() {
      this.loading = true
      try {
        await userLogin(this.loginDetails)
      } catch (error) {
        this.$store.commit('setLoginError', true)
        setTimeout(() => {
          this.$store.commit('setLoginError', false)
        }, 5000)
        console.error(error.message)
        return (this.loading = false)
      }

      // user canceled subscription, does not have subscription object, redirect to /cancel page
      const subscriptions = await getSubscriptions()
      if (!subscriptions.length) {
        const query = this.$route.query
        await this.$router.push({ path: 'canceled', query })
        return
      }

      try {
        await this.$store.dispatch('setupAccount')
      } catch (error) {
        this.$store.commit('setLoginError', true)
        setTimeout(() => {
          this.$store.commit('setLoginError', false)
        }, 5000)
        console.error(error.message)
      }

      // if user is admin redirect to /admin/home
      if (this.$store.getters['getAccountIsAdmin']) {
        await this.$router.push('/admin/home')
        return
      }

      // if user is coming from the shopify app store, but tries to log in, since already has account
      if (this.$store.getters['getPublicToken']) {
        try {
          await this.handleShopifyLogin()
        } catch (error) {
          this.$store.commit('setLoginError', true)
          setTimeout(() => {
            this.$store.commit('setLoginError', false)
          }, 5000)
          console.error(error.message)
        }
      }

      this.$router.push('/')
      this.loading = false
    },
    async handleShopifyLogin() {
      const payload: CreateIntegrationRequestBody = {
        publicToken: this.$store.getters['getPublicToken'],
        platform: 'shopify',
      }

      const maxActiveSources = this.$store.getters['getMaxActiveIntegrationSources']
      const isAmountExceeded = this.$store.getters['getIntegrationsAmount'] >= maxActiveSources
      if (isAmountExceeded) {
        this.$store.dispatch('notification/notify', {
          text: this.$t('CallToUpgradePopup.integration_text_generic', {
            maxAmount: maxActiveSources,
          }),
          isError: true,
          isClosable: true,
          buttonText: 'close',
        } as Notification)
        return
      }

      try {
        const isOldStore = await this.$store.dispatch('createIntegration', payload)
        if (isOldStore) {
          this.$store.commit('setPublicToken', '')
          return
        }
      } catch (error) {
        if (error === API_ERRORS.STORE_ALREADY_CONNECTED) {
          this.$store.dispatch('notification/notify', {
            text: this.$t('CommonUi.store_already_connected'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification)
          this.$router.push('/')
          return
        }
      }

      const nextUrl = this.$store.getters['getOnboardingSkipStatus']
        ? '/add-integrations-automations?platform=shopify'
        : '/onboarding?directSignup=true&onboarding=true'
      const next = encodeURIComponent(nextUrl)
      const url = `${window.location.origin}/shopify/success?next=${next}`
      await setUpRecurringChargeShopify({
        integrationId: this.$store.getters['getLastIntegrationId'] || '',
        url: url,
      })
      this.$store.commit('setPublicToken', '')
      await this.$store.dispatch('setIntegrations')
      await this.$store.dispatch('setIntegrationOptions')
    },
    async routeCheck() {
      if (!window.location.search.includes('app')) return

      this.fromAppMarketplace = true
      if (this.publicToken !== '') {
        const {
          data: { email },
        } = await getDataForShopifySignup({ publicToken: this.publicToken })
        this.updateCurrentUser({ email })
      }
      if (this.currentUser.email !== '') {
        this.loginDetails.email = this.currentUser.email
      }
    },
    updateCurrentUser(userProps: Partial<User>): Promise<void> {
      return this.$store.dispatch('updateCurrentUser', userProps)
    },
    setPublicToken() {
      return this.$store.commit('setPublicToken')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.login {
  padding: 10vh 2%;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100vh;
  background-image: url('../../assets/backgrounds/login-background.svg');
  background-size: cover;
  align-items: center;
}

.user-component {
  max-height: 640px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  width: 1150px;
}

.user-component-wrapper {
  width: 100%;
  border-radius: 8px;
  display: flex;
  box-shadow: 0 42px 76px rgba(0, 0, 0, 0.05), 0 27.2222px 44.5093px rgba(0, 0, 0, 0.037963),
    0 16.1778px 24.2074px rgba(0, 0, 0, 0.0303704), 0 8.4px 12.35px rgba(0, 0, 0, 0.025),
    0 3.42222px 6.19259px rgba(0, 0, 0, 0.0196296), 0 0.777778px 2.99074px rgba(0, 0, 0, 0.012037);
}

.user-image {
  width: 400px;
  max-width: 45%;
  max-height: 640px;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.user-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chevron {
  width: 23px;
  filter: invert(39%) sepia(15%) saturate(1175%) hue-rotate(106deg) brightness(96%) contrast(87%);
}

.form-wrapper {
  padding: 36px;
  width: 70%;
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.header {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: #212121;
  margin-bottom: 24px;
  text-align: center;
}

.name-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-label {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
  margin-bottom: 4px;
}

.input-description {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #b0b0b0;
  margin-bottom: 12px;
}

.text-field-wrapper {
  display: flex;
  width: 100%;
}

.error-message {
  font-size: 16px;
  color: #f9f9f9;
  font-weight: 500;
  text-align: left;
  width: 100%;
  padding: 8px 20px;
  background: red;
}

.link {
  margin-left: 3px;
  margin-right: 3px;
  color: var(--ui-green);
  font-weight: bold;
}

.button-wrapper {
  margin-top: 20px;
}

.forgot-password {
  display: flex;
  justify-content: flex-end;
  color: var(--ui-green);
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}

.forgot-password-text {
  cursor: pointer;
  text-decoration: none;
}

.login-error {
  font-size: 16px;
  font-weight: bold;
  color: red;
  text-align: center;
  width: 100%;
  padding: 8px 20px;
}

.not-member {
  margin-top: 15px;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
}

.green-text {
  color: var(--ui-green);
  cursor: pointer;
  margin-left: 3px;
  font-weight: bold;
}

@media #{map-get($display-breakpoints, 'md')} {
  .login {
    padding: 10vh 3%;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .user-image {
    display: none;
  }

  .text-field-wrapper {
    justify-content: space-between;
  }

  .form-wrapper {
    padding: 10px;
    width: 90%;
  }
}

@media #{map-get($display-breakpoints, 'xs')} {
  .login {
    padding: 0;
    width: 100%;
    height: 100vh;
  }

  .login-form {
    width: 100%;
    padding: 10px;
    justify-content: center;
  }

  .user-component {
    min-height: 100%;
  }

  .input-field {
    width: 100%;
  }

  .forgot-password {
    width: 100%;
    margin-bottom: 20px;
  }

  .button-wrapper {
    width: 100%;
  }

  .login-image {
    display: none;
  }

  .logo-wrapper {
    width: 100%;
    max-width: unset;
    height: 60px;
    display: flex;
    justify-content: center;

    .logo {
      height: 100%;
      max-width: 160px;
    }
  }
}
</style>
