import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e27cd68"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "call-to-upgrade"
}
const _hoisted_2 = { class: "call-to-upgrade__text" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "call-to-upgrade-card"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "call-to-upgrade-card__title" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_date_input = _resolveComponent("v-date-input")!

  return (_openBlock(), _createElementBlock("section", null, [
    (_ctx.isDashboardAllowed)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['input-wrapper', { advanced: _ctx.advancedAnalytics }])
        }, [
          (!_ctx.advancedAnalytics)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.t('upgrade_text')), 1),
                _createVNode(_component_router_link, {
                  to: "/upgrade-plan",
                  class: "call-to-upgrade__button"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('upgrade')), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (!!_ctx.subscription)
            ? (_openBlock(), _createBlock(_component_v_date_input, {
                key: 1,
                modelValue: _ctx.dateRange,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateRange) = $event)),
                  _ctx.getUrl
                ],
                label: _ctx.t('dates'),
                placeholder: _ctx.t('dates'),
                width: "100%",
                "max-width": "320",
                multiple: "range",
                "allowed-dates": _ctx.allowedDates,
                clearable: "",
                "onClick:clear": _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearDateRange())),
                elevation: "12",
                color: "#3B755F",
                variant: "outlined",
                "prepend-inner-icon": "mdi-calendar-range-outline"
              }, null, 8, ["modelValue", "label", "placeholder", "allowed-dates", "onUpdate:modelValue"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.iframeUrl)
      ? (_openBlock(), _createElementBlock("iframe", {
          key: 1,
          id: "iframe",
          ref: "iframe",
          src: _ctx.iframeUrl,
          class: _normalizeClass(['iframe', _ctx.$route.name, { advanced: _ctx.advancedAnalytics }]),
          frameborder: "0",
          allowtransparency: ""
        }, null, 10, _hoisted_3))
      : _createCommentVNode("", true),
    (!_ctx.isDashboardAllowed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("img", {
            height: "60",
            width: "60",
            src: require('@/assets/icons/leaf-icon.svg'),
            alt: "leaf",
            class: "call-to-upgrade-card__image"
          }, null, 8, _hoisted_5),
          _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.t('call_to_upgrade_card_title')), 1),
          _createElementVNode("p", {
            class: "call-to-upgrade-card__text",
            innerHTML: _ctx.t('call_to_upgrade_card_text')
          }, null, 8, _hoisted_7),
          _createVNode(_component_router_link, {
            to: "/upgrade-plan",
            class: "call-to-upgrade-card__button animated-link"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('upgrade')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}