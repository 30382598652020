<template>
  <div class="checkout-view">
    <div class="checkout-wrapper">
      <div class="side-wrapper side-wrapper--left">
        <div class="subscription-items">
          <a class="navigate-back" @click="$router.back()">
            <v-icon class="chevron">mdi-chevron-left</v-icon>
            <img
              class="logo"
              :src="require('@/assets/greenspark-logo.svg')"
              alt="greenspark-logo"
            />
          </a>
          <CheckoutItem
            v-for="(item, a) in items"
            :key="a"
            :type="item.type"
            :amount="item.amount"
            :cost="item.cost"
            :currency="account.currency"
            :trial="trial"
            :country="item.country"
          />

          <div class="total">
            <div class="total-text">
              {{ !!planType ? t('total_today') : t('total') }}
            </div>
            <div v-if="account.currency === 'euro'" class="total-number">
              {{ currencyEuro(total) }} {{ getUserCurrencyCode }}
            </div>
            <div v-if="account.currency === 'pound'" class="total-number">
              {{ currencyPound(total) }} {{ getUserCurrencyCode }}
            </div>
            <div v-if="account.currency === 'dollar'" class="total-number">
              {{ currencyDollar(total) }} {{ getUserCurrencyCode }}
            </div>
          </div>
        </div>
        <ReferenceBox />
      </div>

      <div class="side-wrapper side-wrapper--right">
        <div class="checkout-header">
          <a class="navigate-back" @click="$router.back()">
            <v-icon class="chevron">mdi-chevron-left</v-icon>
            <img
              class="logo"
              :src="require('@/assets/greenspark-logo.svg')"
              alt="greenspark-logo"
            />
          </a>
          <h2 class="checkout-title">
            {{ t('header') }}
          </h2>
          <ul v-if="shouldShowTrial" class="checkout-checklist">
            <li class="checkout-checklist-item">
              <v-icon class="checkmark"> mdi-check </v-icon>
              {{ t('free_trial') }}
            </li>
            <li class="checkout-checklist-item">
              <v-icon class="checkmark"> mdi-check </v-icon>
              {{ t('easy_setup') }}
            </li>
            <li class="checkout-checklist-item">
              <v-icon class="checkmark"> mdi-check </v-icon>
              {{ t('customisable') }}
            </li>
          </ul>
        </div>

        <form id="payment-form">
          <div id="payment-element">
            <!--Stripe.js injects the Payment Element-->
          </div>
          <template v-if="!subscriptionItems.length && paymentType === 'subscription'">
            <AddPromoCode @setPromoCode="setPromoCode" />
          </template>
          <div class="button-wrapper">
            <v-btn
              id="submit"
              class="button"
              color="#3B755F"
              height="54"
              width="100%"
              :disabled="buttonLoading"
              @click="pay()"
            >
              <div v-if="!buttonLoading" class="button-text" style="color: #f9f9f9">
                {{ buttonText }}
              </div>
              <v-progress-circular v-if="buttonLoading" indeterminate color="#F9F9F9" />
            </v-btn>
          </div>
          <p v-if="showUpgradeText && shouldShowTrial" class="payment-on-file">
            {{ t('payment_on_file') }}
          </p>
          <div v-if="showError" class="error-message">
            {{ errorMessage }}
          </div>
        </form>
        <div class="subscription-items mobile" style="margin-top: 34px">
          <CheckoutItem
            v-for="(item, a) in items"
            :key="a"
            :type="item.type"
            :amount="item.amount"
            :cost="item.cost"
            :currency="account.currency"
            :country="item.country"
          />

          <div class="total">
            <div class="total-text">
              {{ !!planType ? t('total_today') : t('total') }}
            </div>
            <div v-if="account.currency === 'euro'" class="total-number">
              {{ currencyEuro(total) }} {{ getUserCurrencyCode }}
            </div>
            <div v-if="account.currency === 'pound'" class="total-number">
              {{ currencyPound(total) }} {{ getUserCurrencyCode }}
            </div>
            <div v-if="account.currency === 'dollar'" class="total-number">
              {{ currencyDollar(total) }} {{ getUserCurrencyCode }}
            </div>
          </div>
        </div>
      </div>
      <LoadingOpaque v-if="loading" />
    </div>
  </div>
</template>

<script lang="ts">
import type { Subscription } from '@api/index'
import {
  addEcommerceSubscription,
  createPaymentIntent,
  createSetupIntent,
  getSubscriptions,
  usePromoCode,
} from '@api/index'
import type { NewPaidBusinessSubscriptionType } from '@/helpers/pricing'
import { getPlanPrice } from '@/helpers/pricing'
import CheckoutItem from '@/components/checkout/CheckoutItem.vue'
import LoadingOpaque from '@/components/tools/LoadingOpaque.vue'
import ReferenceBox from '@/components/register/ReferenceBox.vue'
import { Decimal } from 'decimal.js'
import type { Account } from '@/store'
import AddPromoCode from '@/components/checkout/AddPromoCode.vue'
import type { SubscriptionItem } from '@/store/subscriptions'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { User } from '@/store/users'
import type { OffsetType } from '@/helpers/interfaces'
import type { Project } from '@/store/projects'
import type { Stripe, StripeElements, Appearance } from '@stripe/stripe-js/dist/stripe-js'
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'
import type { ProjectId } from '@/helpers/constants'

export default defineComponent({
  name: 'CheckoutView',
  components: {
    AddPromoCode,
    CheckoutItem,
    LoadingOpaque,
    ReferenceBox,
  },
  mixins: [Utils],
  data() {
    return {
      planType: '',
      paramsRaw: new URLSearchParams(),
      items: [],
      elements: null,
      stripe: null,
      confirmParams: { return_url: '' },
      elementsOptions: {
        appearance: {
          theme: 'flat',
          variables: {
            colorPrimary: '#3b755f',
            colorBackground: '#f2ebdb',
            colorText: '#212121',
          },
        },
        clientSecret: '',
      },
      errorMessage: '',
      showError: false,
      buttonLoading: false,
      loading: false,
      dialog: true,
      showUpgradeText: false,
      params: '',
      total: 0,
      currency: '',
      currencyTemp: '',
      paymentType: '',
      trial: false,
      validatedPromoCode: '',
      alreadyHasSubscriptionId: false,
      successfulBusinessSignupUrl: '',
    } as {
      planType: NewPaidBusinessSubscriptionType | ''
      paramsRaw: URLSearchParams
      items: {
        type: string
        amount: number
        cost: number
        projectId?: ProjectId
        country?: string
      }[]
      elements: StripeElements | null
      stripe: Stripe | null
      confirmParams: {
        return_url: string
        payment_method?: string
        receipt_email?: string
        expand?: Array<string>
      }
      elementsOptions: {
        appearance: Appearance
        clientSecret: string
      }
      errorMessage: string
      showError: boolean
      buttonLoading: boolean
      loading: boolean
      dialog: boolean
      showUpgradeText: boolean
      params: string
      total: number
      currency: string
      currencyTemp: string
      paymentType: string
      trial: boolean
      validatedPromoCode: string
      alreadyHasSubscriptionId: boolean
      successfulBusinessSignupUrl: string
    }
  },
  computed: {
    shouldShowTrial(): boolean {
      return this.paymentType === 'subscription'
    },
    buttonText(): TranslateResult {
      switch (this.paymentType) {
        case 'addcard':
          return this.t('add_card')
        case 'onetime':
          return this.t('pay')
        case 'subscription':
          return this.t('subscribe')
        default:
          return this.t('subscribe')
      }
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
    isOnboardingSkipped(): boolean {
      return this.$store.getters['getOnboardingSkipStatus']
    },
    hasOwnPaidSubscriptionItem(): boolean {
      return this.$store.getters['hasOwnPaidSubscriptionItem']
    },
    getCurrentUserFullName(): string {
      return this.$store.getters['getCurrentUserFullName']
    },
    subscriptionItems(): SubscriptionItem[] {
      return this.$store.getters['getSubscriptionItems']
    },
    getDefaultProjectPriceByType(): (type: OffsetType) => number {
      return this.$store.getters['getDefaultProjectPriceByType']
    },
    hasPaymentDetails(): (subscriptions: Subscription[]) => boolean {
      return this.$store.getters['hasPaymentDetails']
    },
    getProjectById(): (projectId: string) => Project | undefined {
      return this.$store.getters['getProjectById']
    },
  },
  async created() {
    this.loading = true
    this.getURLParams()
    this.successfulBusinessSignupUrl = this.isOnboardingSkipped
      ? `${window.location.origin}/payment-success?plan=${this.planType}&type=${this.paymentType}`
      : `${window.location.origin}/onboarding?product=${this.planType}`
  },
  async mounted() {
    const subscriptions = await getSubscriptions()
    this.showUpgradeText = this.hasPaymentDetails(subscriptions)
    await this.setUpStripe()
  },
  methods: {
    t(key: string) {
      return this.$t(`CheckoutView.${key}`)
    },
    getURLParams() {
      this.paramsRaw = new URLSearchParams(window.location.search)
      this.params = this.paramsRaw.toString()
      this.currency = this.paramsRaw.get('currency') ?? ''
      this.paymentType = this.paramsRaw.get('type') ?? ''
      this.trial = this.paramsRaw.get('trial') === 'true'

      if (this.paramsRaw.has('plan')) {
        this.planType = this.paramsRaw.get('plan') as NewPaidBusinessSubscriptionType
        if (this.planType) {
          this.items.push({
            type: this.planType,
            amount: 1,
            cost: getPlanPrice(this.planType, this.account.currency),
          })
        }
      }
      Object.entries(this.$route.query).forEach(([key, value]) => {
        const project = this.getProjectById(key)
        const country = project?.countries?.[0] || ''

        if (project) {
          this.items.push({
            type: project.type,
            amount: parseInt(value as string),
            cost: new Decimal(project.price).times(parseInt(value as string)).toNumber(),
            country,
            projectId: project.projectId,
          })
        }
      })

      this.total = Number(this.paramsRaw.get('total'))
      this.items = this.items.filter((item) => item.amount > 0)
    },
    async setUpStripe() {
      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY ?? '')
      if (this.paymentType === 'onetime') await this.paymentIntent()
      if (this.paymentType === 'addcard') await this.zeroPriceSubscription()
      if (this.paymentType === 'subscription') await this.subscribeToPlan()
      this.elements = this.stripe.elements({
        clientSecret: this.elementsOptions.clientSecret,
        appearance: this.elementsOptions.appearance,
      })
      const paymentElement = this.elements.create('payment')
      // const paymentElement = this.elements.create('payment', {
      //   supportedCountries: this.euroCountries,
      //   placeholderCountry: this.account.address.country
      // })
      paymentElement.mount('#payment-element')

      this.loading = false
    },
    async paymentIntent() {
      this.setCurrency()
      const metadata = this.items
        .filter(({ projectId }) => !!projectId)
        .reduce((acc, { projectId, amount }) => {
          acc[projectId as string] = amount
          return acc
        }, {})
      const payload = {
        amount: ((this.total + 1) * 100 - 100).toFixed(0),
        metadata,
      }
      const {
        data: { clientSecret },
      } = await createPaymentIntent(payload)
      this.elementsOptions.clientSecret = clientSecret
      this.confirmParams.return_url = `${window.location.origin}/payment-success?${this.params}`
      this.confirmParams.receipt_email = this.currentUser.email
    },
    async zeroPriceSubscription() {
      this.setCurrency()
      await this.setupIntent()
    },
    async subscribeToPlan() {
      this.setCurrency()
      const {
        data: { clientSecret },
      } = await createSetupIntent({ product: this.planType })
      this.elementsOptions.clientSecret = clientSecret
      if (this.account.accountType === 'personal' || this.account.accountType === 'family') {
        this.confirmParams.return_url = `${window.location.origin}/payment-success?${this.params}`
      } else {
        this.confirmParams.return_url = this.successfulBusinessSignupUrl
      }
    },
    async setupIntent() {
      const {
        data: { clientSecret },
      } = await createSetupIntent()
      this.elementsOptions.clientSecret = clientSecret
      this.confirmParams.return_url = `${window.location.origin}/payment-success?${this.params}`
    },
    setCurrency() {
      this.currencyTemp = ''
      switch (this.account.currency) {
        case 'euro':
          this.currencyTemp = 'eur'
          break
        case 'pound':
          this.currencyTemp = 'gbp'
          break
        case 'dollar':
          this.currencyTemp = 'usd'
          break
      }
    },
    async pay() {
      this.loading = true
      this.showError = false
      this.buttonLoading = true

      if (this.alreadyHasSubscriptionId) {
        const payload = {
          metadata: {},
        }

        if (['business', 'ecommerce'].includes(this.account.accountType)) {
          payload.metadata = {
            type: 'subscription',
            userId: this.account.accountId,
          }
        } else {
          payload.metadata = {
            type: 'customerUpgrade',
            userId: this.account.accountId,
          }
        }

        const { data } = await addEcommerceSubscription(payload)
        this.setAccount(data.user)
        await this.setRequiredActionAlert()
        await this.$router.push('/payment-success?type=subscription-added')
      } else {
        if (!this.stripe || !this.elements) return
        if (this.paymentType === 'subscription') {
          if (this.validatedPromoCode) await usePromoCode(this.validatedPromoCode)

          const { error } = await this.stripe.confirmSetup({
            elements: this.elements,
            confirmParams: this.confirmParams,
          })

          console.error('error', error)

          if (error) {
            if (error.type === 'card_error' || error.type === 'validation_error') {
              this.errorMessage = error.message ?? ''
            } else if (error.code === 'invalid_owner_name') {
              this.errorMessage = 'Invalid owner name.'
            } else {
              this.errorMessage = 'An unexpected error occurred. Please try again or get in touch.'
            }
            this.showError = true
          }
        }

        if (this.paymentType === 'onetime') {
          const { error } = await this.stripe.confirmPayment({
            elements: this.elements,
            confirmParams: this.confirmParams,
          })
          if (error) {
            if (error.type === 'card_error' || error.type === 'validation_error') {
              this.errorMessage = error.message ?? ''
            } else {
              this.errorMessage = 'An unexpected error occurred. Please try again or get in touch.'
            }
            this.showError = true
          }
        }

        if (this.paymentType === 'addcard') {
          const { error } = await this.stripe.confirmSetup({
            elements: this.elements,
            confirmParams: this.confirmParams,
          })

          console.error('error:', error)

          if (error.type === 'card_error' || error.type === 'validation_error') {
            this.errorMessage = error.message ?? ''
          } else {
            this.errorMessage = 'An unexpected error occured. Please try again or get in touch.'
          }
        }
      }

      this.buttonLoading = false
      this.loading = false
    },
    setPromoCode(promoCode: string) {
      this.validatedPromoCode = promoCode
    },
    setRequiredActionAlert(): Promise<void> {
      return this.$store.dispatch('setRequiredActionAlert')
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
    setTopAlertBar() {
      return this.$store.commit('setTopAlertBar')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

#payment-form {
  max-width: 590px;
  width: 100%;
}

#payment-element {
  margin-bottom: 62px;
}

.checkout-wrapper {
  background: var(--ui-beige);
  display: flex;
  min-height: 100vh;
  align-items: stretch;
}

.side-wrapper {
  width: 50%;
  padding: 80px 95px;
}

.subscription-items {
  margin-bottom: 70px;
  max-width: 590px;
  width: 100%;
}

.side-wrapper--left {
  padding: 88px 95px 80px;
  /* 8px more than the right panel to correct title and navigation button height difference visually*/
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.side-wrapper--right {
  background: white;
  box-shadow: var(--box-shadow-wide);

  .navigate-back {
    display: none;
  }
}

.navigate-back {
  display: flex;
  align-items: center;
  color: var(--ui-green);
  margin-left: -11px;
  /* visual correction to match chevron to the start of the content*/
  margin-bottom: 24px;
}

.total {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  font-size: 20px;
  font-weight: bold;
  line-height: 29px;
  color: var(--font-color-primary);
}

.chevron {
  font-size: 28px;
  height: 32px;
  width: 32px;
  color: inherit;
}

.checkout-header {
  margin-bottom: 36px;
}

.checkout-title {
  color: var(--font-color-primary);
  font-size: 38px;
  line-height: 48px;
  font-weight: 600;
  margin-bottom: 16px;
}

.button-wrapper {
  margin-bottom: 20px;
}

.error-message {
  font-size: 16px;
  color: var(--ui-white);
  font-weight: 500;
  text-align: left;
  width: 100%;
  padding: 8px 20px;
  background: var(--ui-red);
  margin-bottom: 10px;
  margin-top: 18px;
}

.mobile {
  display: none;
}

.checkout-checklist {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;
}

.checkout-checklist-item {
  position: relative;
  padding-left: 30px;
  flex-shrink: 0;
  font-size: 18px;
  line-height: 24px;
}

.checkmark {
  color: var(--ui-green);
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  height: 24px;
  width: 24px;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .navigate-back {
    left: 20px;
    top: 60px;
    position: absolute;
    margin: 0;
  }

  .side-wrapper--left {
    padding: 126px 30px;
  }

  .side-wrapper--right {
    padding: 50px 30px;

    .navigate-back {
      display: flex;
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  #payment-element {
    margin-bottom: 30px;
  }

  .checkout-header {
    margin-bottom: 10px;
  }

  .checkout-title {
    font-size: 34px;
    line-height: 40px;
  }

  .checkout-checklist {
    gap: 10px;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .checkout-checklist-item {
    font-size: 16px;
    line-height: 20px;
    padding-left: 22px;
  }

  .checkmark {
    height: 20px;
    width: 20px;
    font-size: 18px;
  }

  .navigate-back {
    top: 20px;
    left: 14px;
  }

  .side-wrapper--left {
    display: none;
  }

  .side-wrapper--right {
    padding: 65px 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .payment-on-file {
    font-size: 14px;
    margin-bottom: 0;
  }
}

@media #{map-get($display-breakpoints, 'xs')} {
  .checkout-checklist {
    gap: 5px;
  }

  .checkout-checklist-item {
    font-size: 14px;
    line-height: 18px;
    padding-left: 18px;
  }

  .checkmark {
    height: 16px;
    width: 16px;
    font-size: 16px;
  }
}
</style>
