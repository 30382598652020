<template>
  <div class="success-feedback">
    <h2>{{ t('header') }}</h2>
    <p>{{ t('description') }}</p>
    <div class="progress-bar-wrapper">
      <v-progress-linear color="#3B755F" indeterminate rounded height="6" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import { computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

const store = useStore()
const route = useRoute()
const router = useRouter()
const i18n = useI18n()

const setupIsFinished = computed(() => !store.getters['getShouldDisplayShopifyOverlay'])
const nextParam = route.query.next?.toString()
const nextRoute = computed(() => (nextParam ? decodeURIComponent(nextParam) : '/'))

const t = (key: string) => i18n.t(`ShopifySetupSuccessView.${key}`)
const validateCurrentRoute = () => setupIsFinished.value && router.push(nextRoute.value)

watch(setupIsFinished, validateCurrentRoute, { immediate: true })
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.success-feedback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 24px;
  }
}

.progress-bar-wrapper {
  width: 150px;

  @media #{map-get($display-breakpoints, 'xs')} {
    width: 100px;
  }
}
</style>
