import { en } from './en'
import { de } from './de'

export const messages = {
  en,
  de,
  // can add more languages, eg: de, fr, es by just adding them to this list and creating the file.
}

export default messages
