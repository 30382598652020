<template>
  <div class="custom-integration">
    <dl class="integration-name-wrapper section">
      <dd class="label">
        {{ t('integration') }}
      </dd>
      <OnboardingPill>
        {{ onboardingPillText }}
      </OnboardingPill>
      <OnboardingPill
        v-if="type === 'custom' && customIntegrationSettings?.sourceDetails.sourceName"
      >
        {{ customIntegrationSettings?.sourceDetails.sourceName }}
      </OnboardingPill>
    </dl>
    <v-form v-model="isFormValid" class="custom-integration-form">
      <div v-if="showSourceNameAndIconSections" class="source-wrapper section">
        <div class="label-wrapper">
          <p class="label">
            {{ t('source_label') }}
          </p>

          <v-tooltip
            :open-on-click="isTouch"
            v-if="type === 'custom'"
            color="black"
            location="top"
            max-width="240px"
          >
            <template v-slot:activator="{ props }">
              <v-icon class="tooltip-icon" v-bind="props"> mdi-information-outline</v-icon>
            </template>
            <span>{{ t('source_tooltip') }}</span>
          </v-tooltip>
        </div>
        <p class="help-text">
          {{ t('source_help') }}
        </p>
        <gs-input
          v-model="payload.sourceDetails.sourceName"
          :hide-details="false"
          class="custom-input"
          :label="t('source_name')"
          :rules="[rules.required, rules.maxlength(maxLength), rules.minlength(minLength)]"
          counter
          :maxlength="maxLength"
        />
      </div>
      <div v-if="showSourceNameAndIconSections" class="icon-wrapper section">
        <p class="label">
          {{ t('icon_label') }}
        </p>
        <p class="help-text">
          {{ t('icon_help') }}
        </p>
        <UploadIcon v-model="payload.imageUrl" />
      </div>
      <div class="trigger-wrapper section">
        <div class="label-wrapper">
          <p class="label">
            {{ t('trigger_label') }}
          </p>

          <v-tooltip :open-on-click="isTouch" color="black" location="top" max-width="240px">
            <template v-slot:activator="{ props }">
              <v-icon class="tooltip-icon" v-bind="props"> mdi-information-outline</v-icon>
            </template>
            <span>{{ t('trigger_tooltip') }}</span>
          </v-tooltip>
        </div>
        <p class="help-text">
          {{
            t(getTriggerHelpKey, {
              source: type ? getIntegrationPlatformName(type) || '' : '',
            })
          }}
        </p>
        <gs-input
          v-model="payload.triggerDetails.triggerName"
          :hide-details="false"
          class="custom-input"
          :label="t('trigger_name')"
          :rules="[rules.required, rules.maxlength(maxLength), rules.minlength(minLength)]"
          counter
          :maxlength="maxLength"
        />
      </div>
      <div class="trigger-type-wrapper section">
        <div class="label-wrapper">
          <p class="label">
            {{ t('trigger_type_label') }}
          </p>
        </div>
        <p class="help-text">
          {{ t('trigger_type_help') }}
        </p>
        <v-select
          v-model="payload.triggerDetails.triggerType"
          variant="outlined"
          flat
          color="green"
          :items="triggerTypes"
          class="custom-input"
          :label="t('trigger_type')"
          :rules="[rules.required]"
        />
      </div>
    </v-form>

    <div class="button-wrapper">
      <gs-button
        :uppercased="false"
        capitalized
        class="form-control"
        icon="mdi-chevron-left"
        size="large"
        muted
        outlined
        type="secondary-v2"
        @click="$emit('back')"
      >
        {{ $t('CommonUi.back') }}
      </gs-button>
      <gs-button
        type="primary"
        size="large"
        full-width
        :disabled="!isFormValid || loading"
        :loading="loading"
        @click.prevent="createCustomIntegration"
      >
        {{ t('next') }}
      </gs-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Utils } from '@/helpers/mixins/utilsMixin'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import UploadIcon from '@/components/common/UploadIcon.vue'
import type { TranslateResult } from 'vue-i18n'
import type { CustomIntegrationTriggerType } from '@api/index'
import {
  createCustomIntegration,
  createCustomIntegrationTrigger,
  CUSTOM_INTEGRATION_TRIGGER_TYPES,
  updateCustomIntegration,
  updateCustomIntegrationTrigger,
} from '@api/index'
import OnboardingPill from '@/components/onboarding/OnboardingPill.vue'
import type { Notification } from '@/store/notification'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { CustomIntegrationType } from '@/helpers/interfaces'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'

export interface CustomIntegrationSetting {
  triggerDetails: {
    triggerType: CustomIntegrationTriggerType | ''
    triggerName: string
    triggerId: string
  }
  sourceDetails: {
    sourceName: string
    sourceId: string
  }
  imageUrl: string
}

export default defineComponent({
  name: 'CustomIntegration',
  emits: ['next', 'back'],
  components: {
    OnboardingPill,
    UploadIcon,
  },
  mixins: [RulesMixin, Utils, IntegrationsMixin],
  data() {
    const payload: CustomIntegrationSetting = {
      triggerDetails: {
        triggerType: '',
        triggerName: '',
        triggerId: '',
      },
      sourceDetails: {
        sourceName: '',
        sourceId: '',
      },
      imageUrl: '',
    }

    return {
      isFormValid: true,
      loading: false,
      payload,
      maxLength: 40,
      minLength: 3,
    }
  },
  computed: {
    getTriggerHelpKey(): string {
      if (this.type === 'custom') return 'trigger_help_custom'
      return `trigger_help`
    },
    areTriggerDetailsTheSame(): boolean {
      return Object.entries(this.payload.triggerDetails).every(([key, value]) => {
        return this.customIntegrationSettings?.triggerDetails[key] === value
      })
    },
    imageFromIcon(): string {
      let returnValue = ''
      if (this.customIntegrationSettings?.sourceDetails.sourceId) {
        returnValue = this.getCustomIntegrationBySourceId(
          this.customIntegrationSettings?.sourceDetails.sourceId,
        )?.imageUrl
      }
      return returnValue
    },
    areSourceDetailsTheSame(): boolean {
      return (
        Object.entries(this.payload.sourceDetails).every(([key, value]) => {
          return this.customIntegrationSettings?.sourceDetails[key] === value
        }) && this.payload.imageUrl === this.customIntegrationSettings?.imageUrl
      )
    },
    triggerTypes(): { title: TranslateResult; value: CustomIntegrationTriggerType }[] {
      return CUSTOM_INTEGRATION_TRIGGER_TYPES.map((triggerType) => {
        return {
          title: this.t(this.snakeCase(triggerType)),
          value: triggerType,
        }
      })
    },
    onboardingPillText(): TranslateResult {
      switch (this.type) {
        case 'zapier':
          return this.t('zapier')
        case 'integrately':
          return this.t('integrately')
        case 'toki':
          return this.t('toki')
        default:
          return this.t('custom')
      }
    },
    initialSourceName(): string | undefined {
      return this.type === 'custom'
        ? this.customIntegrationSettings?.sourceDetails.sourceName
        : this.capitalizeFirstLetter(this.type ?? '')
    },
    showSourceNameAndIconSections(): boolean {
      return this.type === 'custom'
    },
    getCustomIntegrationBySourceId(): (sourceId: string) => CustomIntegrationSetting {
      return this.$store.getters['getCustomIntegrationBySourceId']
    },
  },
  created() {
    if (this.customIntegrationSettings) {
      this.payload = {
        ...this.customIntegrationSettings,
        triggerDetails: { ...this.customIntegrationSettings.triggerDetails },
        sourceDetails: {
          ...this.customIntegrationSettings.sourceDetails,
          sourceName: this.initialSourceName ?? '',
        },
        imageUrl: this.imageUrl || this.imageFromIcon || '',
      }
    }
  },
  methods: {
    async createCustomIntegration() {
      this.loading = true
      try {
        if (this.areTriggerDetailsTheSame && this.areSourceDetailsTheSame) {
          this.$emit('next')
        }
        if (
          !this.areTriggerDetailsTheSame &&
          this.payload.triggerDetails.triggerId &&
          this.payload.sourceDetails.sourceId
        ) {
          const { data } = await updateCustomIntegrationTrigger(
            this.payload.sourceDetails.sourceId,
            this.payload.triggerDetails.triggerId,
            this.payload.triggerDetails.triggerName,
            this.payload.triggerDetails.triggerType,
          )
          this.payload.triggerDetails.triggerName =
            data.triggers.find(({ _id }) => _id === this.payload.triggerDetails.triggerId)?.name ||
            ''
          this.payload.triggerDetails.triggerType =
            data.triggers.find(({ _id }) => _id === this.payload.triggerDetails.triggerId)?.type ||
            ''
          this.$emit('next', this.payload)
        }
        if (
          !this.areSourceDetailsTheSame &&
          this.payload.triggerDetails.triggerId &&
          this.payload.sourceDetails.sourceId
        ) {
          const { data } = await updateCustomIntegration(
            this.payload.sourceDetails.sourceId,
            this.payload.sourceDetails.sourceName,
            this.payload.imageUrl,
          )
          this.payload.sourceDetails.sourceName = data.source
          this.payload.imageUrl = data.imageUrl
          this.$emit('next', this.payload)
        }
        if (!this.payload.triggerDetails.triggerId && !this.payload.sourceDetails.sourceId) {
          const { data } = await createCustomIntegration(
            this.payload.sourceDetails.sourceName,
            this.payload.imageUrl,
            this.payload.triggerDetails.triggerName,
            this.payload.triggerDetails.triggerType,
            this.type ?? 'custom',
          )
          this.payload.triggerDetails.triggerId = data[data.length - 1].triggers[0]?._id
          this.payload.sourceDetails.sourceId = data[data.length - 1]._id
          this.$emit('next', this.payload)
        }
        if (this.payload.sourceDetails.sourceId && !this.payload.triggerDetails.triggerId) {
          await createCustomIntegrationTrigger(
            this.payload.sourceDetails.sourceId,
            this.payload.triggerDetails.triggerName,
            this.payload.triggerDetails.triggerType,
          )
          this.$store.dispatch('notification/notify', {
            text: this.$t('CustomIntegration.trigger_created'),
            isError: false,
            buttonText: 'close',
          } as Notification)
          this.$emit('next')
        }
        await this.setCustomIntegrationList()
      } catch (e) {
        console.error(e)
        this.$store.dispatch('notification/notify', {
          text: this.$t('CommonUi.error_generic'),
          isError: true,
          isClosable: true,
          buttonText: 'close',
        } as Notification)
      }
      this.loading = false
    },
    t(key: string, params: { [k: string]: string } = {}) {
      return this.$t(`CustomIntegration.${key}`, params)
    },
    setCustomIntegrationList(): Promise<void> {
      return this.$store.dispatch('setCustomIntegrationList')
    },
  },
  props: {
    customIntegrationSettings: {
      type: Object as PropType<CustomIntegrationSetting>,
    },
    type: {
      type: String as PropType<CustomIntegrationType>,
    },
    imageUrl: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.section {
  padding: 15px 15px 15px 0;
}

.section:not(:first-child),
.custom-integration-form {
  border-top: 1px solid var(--ui-green-light);
}

.integration-name-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.label {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
}

.label-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
}

.help-text {
  color: var(--gray-light);
  max-width: 50ch;
  font-weight: 400;
  margin-bottom: 24px;
}

.tooltip-icon {
  color: var(--font-color-primary);
  cursor: pointer;
}

.custom-input {
  max-width: 340px;
}

.button-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .section {
    padding: 24px 18px 24px 0;
  }

  .button-wrapper {
    padding: 0 18px 24px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
}
</style>
