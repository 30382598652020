<template>
  <div class="stepper">
    <ul class="step-indicator-list">
      <img
        v-if="!isMobile && showLogo"
        :src="require('@/assets/greenspark-logo.svg')"
        alt=""
        class="greenspark-logo"
      />
      <li
        v-for="(item, index) in maxValue"
        :key="index"
        :class="['step-indicator', { 'is-active': index <= modelValue - 1 }, 'animate', 'pop ']"
      >
        <span class="step-indicator-number">{{ index + 1 }}</span>
      </li>
    </ul>
    <div class="stepper-items" ref="stepper-items">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Stepper',
  mixins: [Utils],
  data() {
    return {
      steps: 1,
    }
  },
  props: {
    modelValue: {
      default: 1,
      type: Number,
    },
    maxValue: {
      default: 1,
      type: Number,
    },
    showLogo: {
      default: true,
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

$step-indicator-connector-width: 35px;

.stepper {
  width: 100%;
}

.step-indicator-list {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $step-indicator-connector-width;
  padding: 24px 0 28px;
  position: relative;
  max-width: 1150px;
  margin: 0 auto 0;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 40px 0 42px;
  }
}

.step-indicator {
  height: 32px;
  width: 32px;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--ui-green-light);
  color: var(--ui-green-light);
  font-weight: 400;
  position: relative;
  background-color: transparent;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    height: 42px;
    width: 42px;
  }
}

.step-indicator.is-active {
  color: var(--ui-white);
  border: none;
}

.step-indicator-number {
  z-index: 2;
}

.step-indicator.is-active::before {
  border: none;
  background-color: var(--ui-green);
  height: 100%;
  width: 100%;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  border-radius: 50%;
}

.step-indicator:not(:first-of-type)::after {
  height: 1px;
  width: $step-indicator-connector-width;
  position: absolute;
  content: '';
  right: 100%;
  border: 1px var(--ui-green-light) dashed;
}

.step-indicator.is-active:not(:first-of-type)::after {
  border: 1px var(--ui-green) dashed;
  animation-name: grow;
  animation-duration: 0.5s;
  animation-direction: normal;
  transform-origin: 0% 100%;
}

.stepper-items {
  position: relative;
  min-height: 400px;
  margin-bottom: 40px;
  width: 100%;
}

.is-active.animate.pop::before {
  animation-duration: 0.5s;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
}

.greenspark-logo {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  left: 0;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animate-pop {
  0% {
    transform: scale(0.2, 0.2);
  }

  100% {
    transform: scale(1, 1);
  }
}
</style>
