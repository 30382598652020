<template>
  <div v-if="loaded" class="badge-wrapper" :style="colour ? colour : ''">
    <div v-if="htmlContent" v-html="htmlContent" />
    <div
      v-else
      class="widget-image-wrapper"
      :style="`background-image: url(${encodeForCSS(image ?? '')});`"
    />

    <v-dialog v-model="dialog" width="480" :fullscreen="isMobile" class="badge-dialog" scrollable>
      <template v-slot:activator="{ props }">
        <v-btn class="button mt-2" color="#3B755F" height="auto" variant="text" v-bind="props">
          {{ useButtonText || t('use_badge') }}
        </v-btn>
      </template>

      <div class="pop-up" :style="colour ? colour : ''">
        <div class="close-wrapper" @click="dialog = false">
          <img
            style="width: 25px"
            class="close-icon"
            :src="require('@/assets/icons/close_icon.svg')"
            alt="close"
          />
        </div>

        <!-- If no shopify active -->

        <div v-if="codePage" class="pop-up-content-wrapper">
          <div v-if="htmlContent" v-html="htmlContent" />
          <img v-else :src="image" class="widget-overlay" alt="image" />

          <div class="overlay-text" :style="colour ? 'color: #F9F9F9' : ''">
            {{ overlayText }}
          </div>

          <div class="code">
            {{ code }}
          </div>

          <div class="button-wrapper">
            <v-btn
              @click="() => onCopy(code)"
              class="button"
              color="#3B755F"
              height="54"
              width="230"
              style="border-radius: 3px"
              variant="flat"
            >
              <div class="button-text">
                {{ !copied ? t('copy') : t('copied') }}
              </div>
            </v-btn>
          </div>
          <div v-if="isShopifyIntegrated" class="switch" @click="codePage = false">
            {{ t('go_shopify') }}
          </div>
        </div>

        <!-- If Shopify Active -->
        <div v-if="!codePage" class="menu-wrapper">
          <CustomMenu :menu-items="menuItems" @menuClick="menuClick" />
        </div>
        <div v-if="!codePage && activeMenuItem === 'app_embed'" class="pop-up-content-wrapper">
          <div v-if="htmlContent" v-html="htmlContent" />
          <img v-else :src="image" class="widget-overlay" alt="image" />

          <div class="overlay-text" :style="colour ? 'color: #F9F9F9' : ''">
            {{ t('overlay_shopify') }}
          </div>

          <div class="how-it-works">
            <div class="hiw-title">
              {{ t('how_it_works') }}
            </div>
            <div class="number-explanation-wrapper">
              <div class="number">1</div>
              <div class="explanation">
                {{ t('one') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">2</div>
              <div class="explanation">
                {{ t('two') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">3</div>
              <div class="explanation">
                {{ t('three') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">4</div>
              <div class="explanation">
                {{ t('four') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">5</div>
              <div class="explanation">
                {{ t('five') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">6</div>
              <div class="explanation">
                {{ t('six') }}
              </div>
            </div>
          </div>

          <v-select
            v-model="shopify"
            :items="shopifyUrls"
            label="Shop Name"
            variant="outlined"
            color="#3B755F"
            item-color="#3B755F"
            class="dropdown"
            hide-details
          />

          <div class="copy-item-wrapper">
            <div class="label">
              {{ t('id') }}
            </div>
            <div class="copy-inner">
              <div class="item-to-copy">
                {{ account.livePageId }}
              </div>
              <v-btn
                @click="() => onCopy(account.livePageId)"
                class="button"
                color="#3B755F"
                height="54"
                width="80"
                variant="flat"
              >
                <div class="button-text">
                  {{ !copied ? t('copy1') : t('copied') }}
                </div>
              </v-btn>
            </div>
          </div>

          <div class="copy-item-wrapper">
            <div class="label">
              {{ t('badge_url') }}
            </div>
            <div class="copy-inner">
              <div class="item-to-copy">
                {{ url }}
              </div>
              <v-btn
                @click="() => onCopy1(url ?? '')"
                class="button"
                color="#3B755F"
                height="54"
                width="80"
                variant="flat"
              >
                <div class="button-text">
                  {{ !copied1 ? t('copy1') : t('copied') }}
                </div>
              </v-btn>
            </div>
          </div>

          <div class="support">
            <span>{{ t('questions') }}</span>
            <button class="green-text" @click="openChat()">
              {{ t('chat_with_us') }}
            </button>
          </div>

          <div class="button-wrapper">
            <v-btn
              class="button"
              color="#3B755F"
              height="54"
              width="230"
              style="border-radius: 3px"
              variant="flat"
              :disabled="!buttonActive"
              @click="shopifyUrl()"
            >
              <div class="button-text">
                {{ t('proceed_to_shopify') }}
              </div>
            </v-btn>
          </div>

          <div class="switch" @click="codePage = true">
            {{ t('go_copy') }}
          </div>
        </div>

        <div v-if="!codePage && activeMenuItem === 'app_block'" class="pop-up-content-wrapper">
          <div v-if="htmlContent" v-html="htmlContent" />
          <img v-else :src="image" class="widget-overlay" alt="image" />

          <div class="overlay-text" :style="colour ? 'color: #F9F9F9' : ''">
            {{ t('overlay_shopify_block') }}
          </div>

          <div class="how-it-works">
            <div class="hiw-title">
              {{ t('how_it_works') }}
            </div>
            <div class="number-explanation-wrapper">
              <div class="number">1</div>
              <div class="explanation">
                {{ t('shopify_tutorial.step_1') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">2</div>
              <div class="explanation">
                {{ t('shopify_tutorial.step_2') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">3</div>
              <div class="explanation">
                {{ t('shopify_tutorial.step_3') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">4</div>
              <div class="explanation">
                {{ t('shopify_tutorial.step_4') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">5</div>
              <div class="explanation">
                {{ t('shopify_tutorial.step_5') }}
              </div>
            </div>

            <div class="number-explanation-wrapper">
              <div class="number">6</div>
              <div class="explanation">
                {{ t('shopify_tutorial.step_6') }}
              </div>
            </div>
          </div>

          <v-select
            v-model="shopify"
            :items="shopifyUrls"
            label="Shop Name"
            variant="outlined"
            color="#3B755F"
            item-color="#3B755F"
            class="dropdown dropdown-block"
            hide-details
          />

          <div class="support">
            <span>{{ t('questions') }}</span>
            <i18n-t tag="span" keypath="Badge.help">
              <a
                href="https://help.shopify.com/en/manual/online-store/themes/theme-structure/extend/apps#app-blocks"
                target="_blank"
                class="green-text"
                >{{ t('help_read') }}</a
              >
              <button class="green-text" @click="openChat()">
                {{ t('help_chat') }}
              </button>
            </i18n-t>
          </div>

          <div class="button-wrapper">
            <v-btn
              class="button"
              color="#3B755F"
              height="54"
              width="230"
              style="border-radius: 3px"
              variant="flat"
              :disabled="!buttonActive"
              @click="shopifyUrl()"
            >
              <div class="button-text">
                {{ t('proceed_to_shopify') }}
              </div>
            </v-btn>
          </div>

          <div class="switch" @click="codePage = true">
            {{ t('go_copy') }}
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import CustomMenu from '@/components/Network/CustomMenu.vue'
import type { TranslateResult } from 'vue-i18n'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Badge',
  components: {
    CustomMenu,
  },
  mixins: [LoadCrispMixin, Utils],
  data() {
    const displayUrls: { title: string; value: string }[] = []

    return {
      dialog: false,
      copied: false,
      code: '',
      loaded: false,
      shopify: '',
      activeMenuItem: 'app_block',
      displayUrls,
      buttonActive: false,
      copied1: false,
      codePage: true,
      windowWidth: 0,
      menuItems: [
        { active: true, value: 'app_block', label: this.$t(`Badge.app_block`) },
        { active: false, value: 'app_embed', label: this.$t(`Badge.app_embed`) },
      ],
    }
  },
  async created() {
    window.addEventListener('resize', this.reportWindowSize)
    this.reportWindowSize()
    await this.setCode()
    this.setUrls()
    this.codePage = !this.isShopifyIntegrated
    this.loaded = true
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize)
  },
  methods: {
    t(key: string) {
      return this.$t(`Badge.${key}`)
    },
    reportWindowSize() {
      this.windowWidth = window.innerWidth
    },
    onCopy(text: string) {
      try {
        navigator.clipboard.writeText(text)
        this.copied = true
        setTimeout(() => {
          this.copied = false
        }, 1500)
      } catch (error) {
        this.onError(error)
      }
    },
    onCopy1(text: string) {
      try {
        navigator.clipboard.writeText(text)
        this.copied1 = true
        setTimeout(() => {
          this.copied1 = false
        }, 1500)
      } catch (error) {
        this.onError(error)
      }
    },
    onError(error) {
      console.error(error)
    },
    setCode() {
      const name = this.account.companyName.replace(/\s+/g, '-').toLowerCase()
      this.code = `<a href="https://app.getgreenspark.com/dashboard/${this.account.livePageId}/${name}?utm_source=${name}" rel="nofollow" target="_blank" style="border: 0;"><img loading="lazy" style="max-width:100%; max-height:150px;" src="${this.url}" alt="Greenspark | Plastic & Carbon Offset" /></a>`
    },
    setUrls() {
      if (!this.shopifyUrls) return
      for (let i = 0; i < this.shopifyUrls.length; i++) {
        this.displayUrls.push({ title: this.shopifyUrls[i] as string, value: this.shopifyUrls[i] })
      }
    },
    shopifyUrl() {
      if (this.activeMenuItem === 'app_block') {
        window.open(`https://${this.shopify}/admin/themes/current/editor`, '_blank')
      } else if (this.activeMenuItem === 'app_embed') {
        window.open(`https://${this.shopify}/admin/themes/current/editor?context=apps`, '_blank')
      }
    },
    menuClick(value, index) {
      for (let i = 0; i < this.menuItems.length; i++) {
        if (this.menuItems[i].active) this.menuItems[i].active = false
      }

      this.menuItems[index].active = !this.menuItems[index].active
      this.activeMenuItem = value
    },
    onShopifyChange() {
      this.buttonActive = this.shopify !== ''
    },
  },
  props: {
    image: {
      type: String,
    },
    htmlContent: {
      type: String,
      required: false,
    },
    url: {
      type: String,
    },
    colour: {
      type: String,
    },
    overlayText: { type: String as () => TranslateResult },
    shopifyUrls: {
      type: Array as PropType<string[]>,
    },
    isShopifyIntegrated: {
      type: Boolean,
    },
    useButtonText: {
      type: String,
      required: false,
    },
  },
  watch: {
    shopify: [
      {
        handler: 'onShopifyChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.menu-wrapper {
  align-items: flex-start;
  display: flex;
  padding: 20px 0;
  width: 100%;
}

.badge-wrapper {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 260px;
  background-color: #f9f9f9 !important;
}

.widget-image-wrapper {
  width: 100%;
  height: 100px;
  background-size: contain;
  background-position: center;
}

.widget-small {
  width: 100%;
  height: auto;
}

.button {
  border-radius: 0;
  padding: 0 !important;
}

.button-text {
  color: #f9f9f9;
  font-weight: 600;
  font-size: 12px;
}

.button-text-send {
  color: #3b755f;
  font-weight: 600;
  font-size: 12px;
}

.pop-up {
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--ui-white);
  min-height: 100%;
  overflow-y: scroll;
}

.close-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
  cursor: pointer;
}

.widget-overlay {
  width: 70%;
  max-height: 160px;
}

.pop-up-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.overlay-text {
  text-align: center;
  color: #212121;
  font-size: 15px;
  margin-top: 12px;
}

.code {
  resize: none;
  width: 95%;
  padding: 10px;
  background-color: rgb(235, 235, 235);
  border-color: rgb(140, 149, 158);
  color: #212121;
  font-size: 14px;
  margin-top: 15px;
  word-break: break-all;
}

.button-wrapper {
  display: flex;
  margin-top: 12px;
}

.how-it-works {
  margin-top: 14px;
  margin-bottom: 14px;
  width: 100%;
}

.hiw-title {
  font-weight: bold;
  margin-bottom: 14px;
}

.number-explanation-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.number {
  border: 2px solid #3b755f;
  border-radius: 50%;
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3b755f;
  font-weight: 600;
  margin-right: 8px;
}

.explanation {
  font-size: 14px;
}

.dropdown {
  width: 100%;
}

.dropdown-block {
  margin-bottom: 16px;
}

.copy-item-wrapper {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.label {
  font-weight: bold;
  margin-bottom: 10px;
}

.copy-inner {
  display: flex;
  width: 100%;
}

.item-to-copy {
  align-items: center;
  padding: 5px 20px;
  background: var(--ui-beige);
  height: 54px;
  display: flex;
  width: calc(100% - 80px);
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 255px;
}

.switch {
  cursor: pointer;
  color: #3b755f;
  font-size: 12px;
  margin-top: 12px;
}

.switch:hover {
  opacity: 80;
}

.support {
  font-size: 14px;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.green-text {
  padding-left: 2px;
  color: #3b755f;
  text-decoration: underline;
  cursor: pointer;
}

.green-text:hover {
  opacity: 80;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .item-to-copy {
    max-width: 360px;
  }

  .widget-image-wrapper {
    width: 100%;
  }
}
</style>
