import type { StateInterface } from '@/store'
import type { ActionContext } from 'vuex'

export interface Notification {
  text: string
  details?: string
  isError?: boolean
  buttonText?: string
  isClosable?: boolean
}

interface NotificationState {
  notification?: Notification
  show: boolean
}

export const notificationModule = {
  namespaced: true,
  state: (): NotificationState => ({
    show: false,
    notification: undefined,
  }),
  getters: {
    getNotification: (state: NotificationState): Notification | undefined => state.notification,
    showNotification: (state: NotificationState): boolean => state.show,
  },
  mutations: {
    setShow: (state: NotificationState, show: boolean) => (state.show = show),
    setNotification: (state: NotificationState, notification: Notification) =>
      (state.notification = notification),
  },
  actions: {
    notify(
      { commit, dispatch }: ActionContext<NotificationState, StateInterface>,
      notification: Notification,
    ): void {
      commit('setNotification', notification)

      commit('setShow', true)
      if (!notification.isClosable) {
        setTimeout(() => {
          dispatch('closeNotification')
        }, 5000)
      }
    },
    closeNotification({ commit }: ActionContext<NotificationState, StateInterface>): void {
      commit('setShow', false)
      commit('setNotification', undefined)
    },
  },
}
