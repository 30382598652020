<template>
  <footer>
    <div
      style="
        min-width: 100%;
        min-height: 100px;
        background: none;
        margin-bottom: -10px;
        display: flex;
        align-items: flex-end;
      "
    >
      <!--      <Vue3Lottie-->
      <!--        :options="defaultOptions"-->
      <!--        @animCreated="handleAnimation"-->
      <!--      />-->
    </div>

    <div class="footer-bottom">
      <div class="footer-description">
        {{ text ? text : t('description') }}
      </div>
      <a rel="noopener noreferrer" role="button" @click="openUrl">{{ t('join') }}</a>
    </div>
  </footer>
</template>

<script lang="ts">
// import { Vue3Lottie } from 'vue3-lottie'
// import * as animationData from '../../assets/footer.json'
import type { TranslateResult } from 'vue-i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomFooterV2',
  // components: {
  //   Vue3Lottie
  // },
  methods: {
    t(key: string, params?: { [k: string]: string }) {
      return this.$t(`Footer.${key}`, params ?? {})
    },
    openUrl() {
      window.open('https://www.getgreenspark.com', '_self')
    },
  },
  props: {
    text: { required: false, type: String as () => TranslateResult },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.footer-top {
  background-image: url('../../assets/footer.svg');
  background-size: contain;
  background-position-y: bottom;
  height: 300px;
  margin-bottom: -1px;
}

.footer-bottom {
  background-color: #3b755f;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

[role='button'] {
  background-color: #f2ebdb;
  width: fit-content;
  padding: 8px 15px;
  border-radius: 99px;
  color: #3b755f;
  font-weight: 700;
  transition: all 0.3s ease;
  font-size: 18px;
}

[role='button']:hover {
  transform: translateY(-5px);
}

.footer-description {
  color: #f2ebdb;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  margin: 15px 0;
}

@media #{map-get($display-breakpoints, 'xl')} {
  .footer-top {
    background-size: cover;
    background-position-y: top;
    background-position-x: center;
  }
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  footer {
    background-size: contain;
  }

  .footer-top {
    height: 200px;
  }
}

@media #{map-get($display-breakpoints, 'xs')} {
  footer {
    margin-top: auto;
    margin-bottom: 60px;
  }

  .footer-top {
    height: 140px;
  }

  .footer-description {
    font-size: 14px;
    margin: 10px 0;
  }

  [role='button'] {
    font-size: 12px;
  }
}
</style>
