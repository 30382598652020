import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20892e94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "logo",
      src: require('@/assets/greenspark-logo.svg'),
      alt: "greenspark-logo"
    }, null, 8, _hoisted_2),
    (_ctx.$route.path !== '/finish-subscription')
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          style: {"width":"30px","cursor":"pointer"},
          class: "close-icon",
          src: require('@/assets/icons/close_icon.svg'),
          alt: "close",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backClick && _ctx.backClick(...args)))
        }, null, 8, _hoisted_3))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "logout",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.logOut()))
        }, _toDisplayString(_ctx.$t('CommonUi.logout')), 1))
  ]))
}