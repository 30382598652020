<template>
  <div class="tooltip">
    <v-menu
      :open-on-hover="!isTouch"
      :open-on-click="isTouch"
      location="top"
      offset="5 0"
      close-delay="250"
      content-class="elevation-1"
    >
      <template v-slot:activator="{ props }">
        <img
          class="tooltip-icon"
          :src="require('@/assets/icons/info.svg')"
          alt="tooltip icon"
          v-bind="props"
        />
      </template>
      <div class="tooltip-content">
        <div class="tooltip-text" v-html="content" />
      </div>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Utils } from '@/helpers/mixins/utilsMixin'

export default defineComponent({
  name: 'BaseTooltip',
  mixins: [Utils],
  props: {
    content: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.tooltip {
  line-height: 0;
  cursor: pointer;
}

.tooltip-icon {
  width: 100%;
  height: 100%;

  &:focus {
    outline: none;
  }
}

.tooltip-content {
  width: 200px;
  min-height: 100px;
  background: var(--ui-white);
  text-align: left;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.tooltip-text {
  padding-bottom: 5px;
  color: var(--ui-black);
  font-size: 14px;
  line-height: 20px;
}
</style>
