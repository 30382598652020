<template>
  <div class="topstat-wrapper">
    <div v-if="referralsCount !== undefined" class="stat referral-card">
      <div class="text-number-wrapper">
        <p class="number">
          {{ referralsCount }}
        </p>
        <p>
          {{ t(type) }}
        </p>
      </div>
    </div>

    <div v-for="(value, key) in impacts" :key="key" :class="['stat', key]">
      <div class="text-number-wrapper">
        <p class="number">
          {{ formatNumberByLocale(value) }}
        </p>
        <p>
          {{ t(key) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { OffsetType } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ReferralStats',
  mixins: [Utils],
  methods: {
    t(key: string) {
      return this.$t(`ReferralStats.${key}`)
    },
  },
  props: {
    referralsCount: {
      type: Number,
    },
    impacts: {
      type: Object as PropType<{ [key in OffsetType]: number }>,
    },
    type: {
      required: true,
      type: String as PropType<'referrals' | 'partners'>,
    },
  },
  computed: {
    getImpactBackgroundByType(): (type: OffsetType) => string {
      return this.$store.getters['getImpactBackgroundByType']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.topstat-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}

.stat {
  display: flex;
  align-items: center;
  width: 24%;
  padding: 16px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  min-height: 80px;
  justify-content: center;
  background-color: var(--ui-beige);
}

.text-number-wrapper {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: inherit;
  flex-direction: column;
}

.text-number-wrapper > p {
  margin: 0;
  color: inherit;
}

.number {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
}

.referral-card .number {
  color: var(--ui-beige);
}

.stat-icon {
  width: 65px;
}

.stat.carbon {
  background-image: url('../../assets/gifs/comparisons/stat_carbon_background.svg');
  background-color: var(--ui-beige);
}

.stat.plastic {
  background-image: url('../../assets/gifs/comparisons/stat_plastic_background.svg');
  background-color: var(--ui-beige);
}

.stat.trees {
  background-image: url('../../assets/gifs/comparisons/stat_tree_background.svg');
  background-color: var(--ui-beige);
}

.stat.kelp {
  background-image: url('../../assets/gifs/comparisons/stat_kelp_background.svg');
  background-color: var(--ui-beige);
}

.referral-card {
  background: var(--ui-green);
  color: var(--ui-beige);
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .text-number-wrapper {
    font-size: 16px;
    margin-left: -4px;
    text-align: center;
  }

  .number {
    font-size: 20px;
  }

  .stat {
    width: 49%;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .topstat-wrapper {
    flex-wrap: wrap;
  }
}

@media #{map-get($display-breakpoints, 'xs')} {
  .stat {
    width: 100%;
  }
}
</style>
