<template>
  <v-text-field
    v-bind="$attrs"
    variant="outlined"
    color="green"
    flat
    :hide-details="hideDetails"
    :model-value="modelValue"
    @update:model-value="input"
    @blur="blur"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GsInput',
  emits: ['update:modelValue', 'blur', 'update:error'],
  methods: {
    input(v: string): void {
      this.$emit('update:modelValue', v)
    },
    blur(e: MouseEvent): void {
      this.$emit('blur', e)
    },
    updateError(e: KeyboardEvent): void {
      this.$emit('update:error', e)
    },
  },
  props: {
    modelValue: {
      type: [String, Number],
    },
    hideDetails: {
      default: true,
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped></style>
