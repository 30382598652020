<template>
  <div class="user-component">
    <div class="user-component-wrapper">
      <img class="user-image" :src="require('@/assets/add-ons-shape.svg')" alt="signup" />
      <div class="user-wrapper">
        <div class="back">
          <img
            class="chevron"
            alt="chevron"
            :src="require('@/assets/icons/chevron_left.svg')"
            @click="$emit('backClick')"
          />
          <div @click="$emit('backClick')">
            {{ t('back') }}
          </div>
        </div>
        <div class="form-wrapper">
          <div class="header">
            {{ t('header') }}
          </div>

          <!-- Plastic Addon -->
          <div v-if="$route.name !== 'SignupFamily'" class="name-wrapper">
            <div class="tooltip-wrapper">
              <div class="input-label">
                {{ t('plastic_addon') }}
                <span v-if="account.currency === 'euro'" class="price">(€5,75)</span>
                <span v-if="account.currency === 'pound'" class="price">(£5.10)</span>
                <span v-if="account.currency === 'dollar'" class="price">($6.25)</span>
              </div>
              <Tooltip
                class="tooltip"
                :body="t('plastic_addon_tooltip')"
                :link-text="'Source'"
                :link="'https://ourworldindata.org/plastic-pollution'"
              />
            </div>
            <div class="input-description">
              {{ t('plastic_addon_description') }}
            </div>
            <div class="text-field-wrapper">
              <v-text-field
                v-model.number="plasticAddon"
                :label="t('add_plastic')"
                variant="outlined"
                type="number"
                flat
                min="0"
                color="#3B755F"
                class="employee-input"
              />
              <div class="plus-minus">
                <div
                  class="add"
                  style="margin-bottom: 1px"
                  @click="plasticAddon !== null ? plasticAddon++ : (plasticAddon = 1)"
                >
                  +
                </div>
                <div
                  class="add"
                  style="margin-top: 1px"
                  @click="plasticAddon !== null && plasticAddon !== 0 ? plasticAddon-- : ''"
                >
                  -
                </div>
              </div>
            </div>
          </div>

          <div v-if="error" class="error-message">
            {{ t('error') }}
          </div>

          <!-- Tree Addon -->
          <div class="name-wrapper" style="margin-bottom: 0">
            <div class="tooltip-wrapper">
              <div class="input-label">
                {{ t('tree_addon') }}
                <span v-if="account.currency === 'euro'" class="price">(€0,35 / Tree)</span>
                <span v-if="account.currency === 'pound'" class="price">(£0.32 / Tree)</span>
                <span v-if="account.currency === 'dollar'" class="price">($0,35 / Tree)</span>
              </div>
              <Tooltip
                class="tooltip"
                :body="t('tree_addon_tooltip')"
                :link-text="'Source'"
                :link="'https://science.sciencemag.org/content/365/6448/76'"
              />
            </div>
            <div class="input-description">
              {{ t('tree_addon_description') }}
            </div>
            <div class="text-field-wrapper">
              <v-text-field
                v-model.number="treeAddon"
                :label="t('add_trees')"
                variant="outlined"
                type="number"
                flat
                min="0"
                color="#3B755F"
                class="employee-input"
              />
              <div class="plus-minus">
                <div
                  class="add"
                  style="margin-bottom: 1px"
                  @click="treeAddon !== null ? treeAddon++ : (treeAddon = 1)"
                >
                  +
                </div>
                <div
                  class="add"
                  style="margin-top: 1px"
                  @click="treeAddon !== null && treeAddon !== 0 ? treeAddon-- : ''"
                >
                  -
                </div>
              </div>
            </div>
          </div>
          <div class="button-wrapper">
            <gs-button
              :disabled="!buttonActive"
              :full-width="true"
              size="large"
              @click.prevent="onNextClicked"
            >
              {{ t('continue') }}
            </gs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Tooltip from '@/components/tools/Tooltip.vue'
import type { Account } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AddOnsPersonal',
  emits: ['backClick', 'nextClick'],
  components: { Tooltip },
  data() {
    return {
      buttonActive: true,
      error: false,
      totalMembers: 0,
      earthHeroAmount: 0,
      plasticHeroAmount: 0,
      personalAmount: 0,
      treeAddon: null,
      plasticAddon: null,
    } as {
      buttonActive: boolean
      error: boolean
      totalMembers: number | string
      earthHeroAmount: number | null
      plasticHeroAmount: number | null
      personalAmount: number | null
      treeAddon: number | null
      plasticAddon: number | null
    }
  },
  created() {
    this.setNumbers()
    this.setMembersByPlan()
    this.setTotalMembers()
  },
  methods: {
    t(key: string) {
      return this.$t(`AddOnsPersonal.${key}`)
    },
    onNextClicked() {
      this.$emit('nextClick')
    },
    setNumbers() {
      this.treeAddon = this.account.personalPlanAddons.treeAddon
      this.plasticAddon = this.account.personalPlanAddons.plasticAddon
    },
    setMembersByPlan() {
      this.personalAmount = this.account.personalPlans.personal
      this.plasticHeroAmount = this.account.personalPlans.plasticHero
      this.earthHeroAmount = this.account.personalPlans.earthHero
    },
    setTotalMembers() {
      this.totalMembers = 0

      this.totalMembers =
        parseInt(`${this.personalAmount}`) +
        parseInt(`${this.plasticHeroAmount}`) +
        parseInt(`${this.earthHeroAmount}`)

      this.totalMembers = new Intl.NumberFormat('en-EN', {}).format(this.totalMembers)

      if (Number.isNaN(this.totalMembers)) {
        this.totalMembers = 0
      }
    },
    onPlasticAddonChange() {
      if (this.plasticAddon && this.plasticAddon < 0) {
        this.plasticAddon = 0
      } else if (this.plasticAddon === 0) {
        const personalPlanAddons = {
          plasticAddon: '0',
          treeAddon: this.treeAddon,
        }
        this.setUserPersonalPlanAddons(personalPlanAddons)
      } else if (!this.plasticAddon) {
        const personalPlanAddons = {
          plasticAddon: '0',
          treeAddon: this.treeAddon,
        }
        this.setUserPersonalPlanAddons(personalPlanAddons)
      } else {
        if (parseInt(`${this.plasticAddon}`) > parseInt(`${this.totalMembers}`)) {
          this.error = true
          setTimeout(() => {
            this.plasticAddon = parseInt(`${this.totalMembers}`)
          }, 2000)
        } else {
          this.error = false
          const personalPlanAddons = {
            plasticAddon: this.plasticAddon,
            treeAddon: this.treeAddon,
          }
          this.setUserPersonalPlanAddons(personalPlanAddons)
        }
      }
    },
    onTreeAddonChange() {
      if (this.treeAddon && this.treeAddon < 0) {
        this.treeAddon = 0
      } else if (this.treeAddon === 0) {
        const personalPlanAddons = {
          plasticAddon: this.plasticAddon,
          treeAddon: '0',
        }
        this.setUserPersonalPlanAddons(personalPlanAddons)
      } else if (!this.treeAddon) {
        const personalPlanAddons = {
          plasticAddon: this.plasticAddon,
          treeAddon: '0',
        }
        this.setUserPersonalPlanAddons(personalPlanAddons)
      } else {
        const personalPlanAddons = {
          plasticAddon: this.plasticAddon,
          treeAddon: this.treeAddon,
        }
        this.setUserPersonalPlanAddons(personalPlanAddons)
      }
    },
    setUserPersonalPlanAddons(addons) {
      return this.$store.commit('setUserPersonalPlanAddons', addons)
    },
  },
  watch: {
    plasticAddon: [
      {
        handler: 'onPlasticAddonChange',
      },
    ],
    treeAddon: [
      {
        handler: 'onTreeAddonChange',
      },
    ],
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.user-component {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.user-component-wrapper {
  width: 100%;
  border-radius: 8px;
  display: flex;
  box-shadow: 0 42px 76px rgba(0, 0, 0, 0.05), 0 27.2222px 44.5093px rgba(0, 0, 0, 0.037963),
    0 16.1778px 24.2074px rgba(0, 0, 0, 0.0303704), 0 8.4px 12.35px rgba(0, 0, 0, 0.025),
    0 3.42222px 6.19259px rgba(0, 0, 0, 0.0196296), 0 0.777778px 2.99074px rgba(0, 0, 0, 0.012037);
}

.user-image {
  width: 400px;
  max-width: 45%;
  max-height: 640px;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.user-wrapper {
  width: 100%;
}

.chevron {
  width: 23px;
  filter: invert(39%) sepia(15%) saturate(1175%) hue-rotate(106deg) brightness(96%) contrast(87%);
}

.back {
  padding-top: 20px;
  padding-left: 20px;
  display: flex;
  font-weight: 500;
  color: #3b755f;
  cursor: pointer;
}

.form-wrapper {
  padding: 36px;
}

.header {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: #212121;
  margin-bottom: 24px;
}

.name-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.tooltip-wrapper {
  display: flex;
}

.input-label {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
  margin-bottom: 4px;
}

.price {
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  color: #212121;
}

.tooltip {
  display: flex;
  align-items: center;
  margin-left: 6px;
  padding-bottom: 4px;
}

.input-description {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #b0b0b0;
  margin-bottom: 12px;
}

.text-field-wrapper {
  display: flex;
  width: 100%;
}

.employee-input {
  min-width: 90%;
  margin-bottom: 14px;
}

.plus-minus {
  min-width: 10%;
  cursor: pointer;
  height: 56px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}

.add {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f9f9f9;
  font-weight: 600;
  background: #3b755f;
  font-size: 18px;
  height: 28px;
  width: 28px;
  border-radius: 50%;
}

.error-message {
  font-size: 16px;
  color: #f9f9f9;
  font-weight: 500;
  text-align: left;
  width: 100%;
  padding: 8px 20px;
  background: red;
  margin-bottom: 10px;
  margin-top: -20px;
}

.button-wrapper {
  margin-top: 20px;
}

@media #{map-get($display-breakpoints, 'md')} {
  .user-component-wrapper {
    margin-bottom: 20px;
  }

  .user-image {
    display: none;
  }

  .employee-input {
    min-width: 95%;
  }

  .plus-minus {
    display: flex;
    align-items: baseline;
    margin-left: 10px;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .user-component-wrapper {
    margin-bottom: 20px;
  }

  .user-image {
    display: none;
  }

  .text-field-wrapper {
    justify-content: space-between;
  }

  .employee-input {
    min-width: 80%;
    margin-right: 10px;
  }

  .plus-minus {
    display: flex;
    align-items: baseline;
    margin-left: 0;
  }

  .form-wrapper {
    padding: 10px;
  }
}
</style>
