<template>
  <div></div>
</template>

<script>
import QrCreator from 'qr-creator'

export default {
  props: {
    text: { type: String, required: true },
    size: { type: Number, required: false, default: 256 },
    color: { type: String, required: false, default: '#000' },
    bgColor: { type: String, required: false, default: '#FFF' },
  },

  watch: {
    text: function () {
      this.renderQr()
    },
    size: function () {
      this.renderQr()
    },
    color: function () {
      this.renderQr()
    },
    bgColor: function () {
      this.renderQr()
    },
  },

  data() {
    return {
      qrCode: {},
    }
  },

  mounted() {
    this.renderQr()
  },

  methods: {
    renderQr: function () {
      this.$el.innerHTML = ''
      this.qrCode = QrCreator.render(
        {
          text: this.text,
          radius: this.size,
          ecLevel: 'H', // L, M, Q, H
          fill: this.color,
          background: this.bgColor,
          size: this.size,
        },
        this.$el,
      )
    },
  },
}
</script>
