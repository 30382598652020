<template>
  <div class="calendar">
    <h2 class="title">🎉 {{ t('title') }} {{ year }}</h2>
    <p class="subtitle">{{ t('subtitle') }}</p>
    <button v-if="isClosable" class="dialog-close-button" @click.prevent="$emit('close')">
      <v-icon variant="plain"> mdi-close </v-icon>
    </button>

    <div class="calendar-list-container">
      <div class="calendar-list">
        <div
          class="quarter"
          v-for="(eventsInQuarter, key, index) of calendar"
          :key="key"
          v-observe-intersection:[index]="(i) => (visibleIndex = i)"
        >
          <h4>{{ key }}</h4>
          <div
            :class="['event-item', { 'next-event': event.name === nextEvent.name }]"
            v-for="event of eventsInQuarter"
            :key="event.name"
          >
            <span class="event-date">{{ event.displayDates }}</span>
            <div class="right-side">
              <span v-if="event.name === nextEvent.name" class="next-event-marker">{{
                t('next_event')
              }}</span>
              <span class="event-name" v-html="event.stylizedName ?? event.name" />
            </div>
          </div>
        </div>
      </div>
      <div class="markers">
        <div
          v-for="(_, key, index) of calendar"
          :key="key"
          :class="['marker-dot', { visible: visibleIndex === index }]"
        />
      </div>
    </div>
    <hr />
    <h3 class="useful-resources">{{ t('useful_resources') }}</h3>
    <p class="resource-links">
      <a
        href="https://drive.google.com/drive/folders/1dG7XV1gr8DI198xzRyYDHW8c37C0F5NF"
        target="_blank"
        >{{ t('download_media') }}</a
      ><span class="separator"> | </span>
      <a href="https://www.getgreenspark.com/case-studies" target="_blank">{{
        t('boost_engagement')
      }}</a>
      <span class="separator"> | </span>
      <router-link :to="{ name: 'Automations' }">{{ t('active_automations') }}</router-link>
    </p>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import type { Calendar, CalendarEvent } from '@/helpers/event-calendar'

const i18n = useI18n()
const t = (key: string) => i18n.t(`YearlyCalendar.${key}`)

defineEmits(['close'])
const { calendar } = defineProps<{
  calendar: Calendar
  year: string
  isClosable?: boolean
}>()

const visibleIndex = ref<number>()

const nextEvent = computed<CalendarEvent>(() => {
  const now = new Date()
  const { Q1, Q2, Q3, Q4 } = calendar
  const events = [...Q1, ...Q2, ...Q3, ...Q4]
  const futureEvents = events.filter((e) => new Date(e.startDate).getTime() - now.getTime() > 0)
  const currentYear = now.getFullYear()

  return futureEvents.sort((a, b) => {
    const aDate = new Date(a.startDate)
    const bDate = new Date(b.startDate)
    // This makes the calendar repeat each year, which might not be correct,
    // because holidays might not have a fixed day + month.
    aDate.setFullYear(currentYear)
    bDate.setFullYear(currentYear)
    return aDate.getTime() - bDate.getTime()
  })[0]
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

$hide-markers-breakpoint: map-get($display-breakpoints, 'sm-and-up');

.calendar {
  width: 100%;
  height: 100%;
  background-color: var(--ui-white);
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 32px;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 8px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
  }
}

.subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 24px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  }
}

.dialog-close-button {
  position: absolute;
  top: 16px;
  right: 16px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    top: 36px;
    right: 32px;
  }

  i {
    font-size: 32px;
    width: 32px;
    height: 32px;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: 44px;
      width: 44px;
      height: 44px;
    }
  }
}

.calendar-list-container {
  width: 100%;
  margin-bottom: 8px;

  @media #{$hide-markers-breakpoint} {
    margin-bottom: 24px;
  }
}

.calendar-list {
  width: 100%;
  background-color: var(--gray-light-F1);
  border-radius: 10px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  gap: 24px;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-padding: 16px;

  @media #{$hide-markers-breakpoint} {
    margin-bottom: 24px;
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    overflow-x: scroll;
    scroll-snap-type: none;
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    overflow-x: visible;
    margin-bottom: 0;
  }
}

.quarter {
  $gap: 8px;

  display: flex;
  flex-direction: column;
  gap: $gap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
  overflow-x: scroll;
  scroll-snap-align: start;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    flex-basis: calc(50% - #{$gap});
    flex-shrink: 0;
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    flex-basis: calc(25% - #{$gap});
    flex-shrink: 1;
  }

  h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
  }

  .event-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: white;
    border-radius: 10px;
    padding: 10px 8px;
    gap: 16px;

    &.next-event {
      align-items: flex-start;

      .event-name {
        font-weight: 700;
      }
    }

    .event-date {
      flex-basis: 60px;
      flex-grow: 0;
      flex-shrink: 0;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
    }

    .event-name {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;

      :deep(p) {
        margin-bottom: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
      }
    }

    .right-side {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }

    .next-event-marker {
      flex-grow: 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      padding: 2px 8px;
      border-radius: 8px;
      background-color: var(--ui-emerald-green);
    }
  }
}

.markers {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 8px 0 8px;
  gap: 16px;

  @media #{$hide-markers-breakpoint} {
    display: none;
  }

  .marker-dot {
    height: 10px;
    width: 10px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid var(--gray-light-A9);
    transition: all ease 0.3s;

    &.visible {
      background-color: var(--ui-dark-gray);
      border: none;
    }
  }
}

hr {
  margin: 0 0 16px;
  background-color: #cccccc;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin: 0 8px 24px;
  }
}

.useful-resources {
  display: block;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 16px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: 32px;
    line-height: 39px;
  }
}

.resource-links {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    display: block;
  }

  a {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: var(--font-color-link);
    text-decoration: underline;
  }

  .separator {
    height: 0;
    border-width: 0px;
    border-bottom-width: 1px;
    border-color: #cccccc;
    border-style: solid;
    overflow: hidden;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      height: auto;
      border-bottom-width: 0;
    }
  }
}
</style>
