<template>
  <div class="path-wrapper">
    <!-- Referrals -->
    <div class="path" @click="$emit('progressItemClick', 1)">
      <div class="left-image">
        <img class="icon" :src="require('@/assets/Network/referral.svg')" alt="referral" />
      </div>
      <div class="content">
        <div>
          <div class="content-header">
            {{ t('referral') }}
          </div>
          <div class="content-text">
            {{ t('referral_text') }}
          </div>
        </div>
        <v-btn class="button" color="#3B755F" height="34" width="143">
          <div class="button-text">
            {{ t('button_text') }}
          </div>
        </v-btn>
      </div>
    </div>

    <!-- Partnerships -->
    <div class="path" @click="$emit('progressItemClick', 2)">
      <div class="left-image">
        <img class="icon" :src="require('@/assets/Network/partnership.svg')" alt="referral" />
      </div>
      <div class="content">
        <div>
          <div class="content-header">
            {{ t('partnership') }}
          </div>
          <div class="content-text">
            {{ t('partnership_text') }}
          </div>
        </div>
        <v-btn class="button" color="#3B755F" height="34" width="143">
          <div class="button-text">
            {{ t('button_text') }}
          </div>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ChoosePath',
  emits: ['progressItemClick'],
  methods: {
    t(key: string) {
      return this.$t(`ChoosePath.${key}`)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.path-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow-y: scroll;
}

.path {
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  padding: 18px 12px 25px 12px;
  width: 48.5%;
  // height: 253px;
  cursor: pointer;
  transform: translate(0px, 0px);
  transition: box-shadow 200ms ease, transform 200ms ease, background-color 200ms ease,
    -webkit-transform 200ms ease;
}

.path:hover {
  -webkit-box-shadow: 0 0 35px 7px rgb(0 0 0 / 6%);
  box-shadow: 0 0 35px 7px rgb(0 0 0 / 6%);
  -webkit-transform: translate(0px, -4px);
  -ms-transform: translate(0px, -4px);
  transform: translate(0px, -4px);
}

.icon {
  width: 60px;
}

.content {
  margin-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content-header {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  margin-bottom: 7px;
}

.content-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
  margin-bottom: 24px;
}

.button-text {
  color: #f9f9f9;
  font-weight: 600;
  font-size: 15px;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .path-wrapper {
    flex-direction: column;
  }

  .path {
    width: 100%;
    margin-top: 18px;
  }
}
</style>
