<template>
  <svg viewBox="0 0 1218 696" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.7688 100.237V92.6432L36.1925 88.832L29.6018 92.6432V100.237L36.1925 104.034L42.7688 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M57.568 100.237V92.6432L50.9916 88.832L44.4153 92.6432V100.237L50.9916 104.034L57.568 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M72.3692 100.237V92.6432L65.7929 88.832L59.2166 92.6432V100.237L65.7929 104.034L72.3692 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M87.1688 100.237V92.6432L80.5925 88.832L74.0161 92.6432V100.237L80.5925 104.034L87.1688 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M160.942 100.237V92.6432L154.366 88.832L147.775 92.6432V100.237L154.366 104.034L160.942 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M175.743 100.237V92.6432L169.167 88.832L162.59 92.6432V100.237L169.167 104.034L175.743 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M190.542 100.237V92.6432L183.965 88.832L177.389 92.6432V100.237L183.965 104.034L190.542 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M205.341 100.237V92.6432L198.765 88.832L192.188 92.6432V100.237L198.765 104.034L205.341 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M220.085 100.237V92.6432L213.509 88.832L206.933 92.6432V100.237L213.509 104.034L220.085 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M249.514 100.237V92.6432L242.923 88.832L236.347 92.6432V100.237L242.923 104.034L249.514 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.314 100.237V92.6432L257.738 88.832L251.147 92.6432V100.237L257.738 104.034L264.314 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.057 100.237V92.6432L272.467 88.832L265.89 92.6432V100.237L272.467 104.034L279.057 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M249.514 74.5779V66.9843L242.923 63.1875L236.347 66.9843V74.5779L242.923 78.3747L249.514 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.314 74.5779V66.9843L257.738 63.1875L251.147 66.9843V74.5779L257.738 78.3747L264.314 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.057 74.5779V66.9843L272.467 63.1875L265.89 66.9843V74.5779L272.467 78.3747L279.057 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.572 74.5779V66.9843L286.996 63.1875L280.419 66.9843V74.5779L286.996 78.3747L293.572 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M308.386 74.5779V66.9843L301.795 63.1875L295.219 66.9843V74.5779L301.795 78.3747L308.386 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M323.129 74.5779V66.9843L316.538 63.1875L309.962 66.9843V74.5779L316.538 78.3747L323.129 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M337.642 74.5779V66.9843L331.066 63.1875L324.49 66.9843V74.5779L331.066 78.3747L337.642 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M352.444 74.5779V66.9843L345.867 63.1875L339.291 66.9843V74.5779L345.867 78.3747L352.444 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M367.186 74.5779V66.9843L360.61 63.1875L354.033 66.9843V74.5779L360.61 78.3747L367.186 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M381.715 74.5779V66.9843L375.138 63.1875L368.562 66.9843V74.5779L375.138 78.3747L381.715 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M396.514 74.5779V66.9843L389.938 63.1875L383.362 66.9843V74.5779L389.938 78.3747L396.514 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M411.257 74.5779V66.9843L404.681 63.1875L398.105 66.9843V74.5779L404.681 78.3747L411.257 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M323.129 100.237V92.6432L316.538 88.832L309.962 92.6432V100.237L316.538 104.034L323.129 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M337.642 100.237V92.6432L331.066 88.832L324.49 92.6432V100.237L331.066 104.034L337.642 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M352.444 100.237V92.6432L345.867 88.832L339.291 92.6432V100.237L345.867 104.034L352.444 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M367.186 100.237V92.6432L360.61 88.832L354.033 92.6432V100.237L360.61 104.034L367.186 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M381.715 100.237V92.6432L375.138 88.832L368.562 92.6432V100.237L375.138 104.034L381.715 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M396.514 100.237V92.6432L389.938 88.832L383.362 92.6432V100.237L389.938 104.034L396.514 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M411.257 100.237V92.6432L404.681 88.832L398.105 92.6432V100.237L404.681 104.034L411.257 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M337.642 125.28V117.672L331.066 113.875L324.49 117.672V125.28L331.066 129.077L337.642 125.28Z"
      fill="#DBDBDB"
    />
    <path
      d="M352.444 125.28V117.672L345.867 113.875L339.291 117.672V125.28L345.867 129.077L352.444 125.28Z"
      fill="#DBDBDB"
    />
    <path
      d="M367.186 125.28V117.672L360.61 113.875L354.033 117.672V125.28L360.61 129.077L367.186 125.28Z"
      fill="#DBDBDB"
    />
    <path
      d="M381.715 125.28V117.672L375.138 113.875L368.562 117.672V125.28L375.138 129.077L381.715 125.28Z"
      fill="#DBDBDB"
    />
    <path
      d="M396.514 125.28V117.672L389.938 113.875L383.362 117.672V125.28L389.938 129.077L396.514 125.28Z"
      fill="#DBDBDB"
    />
    <path
      d="M411.257 125.28V117.672L404.681 113.875L398.105 117.672V125.28L404.681 129.077L411.257 125.28Z"
      fill="#DBDBDB"
    />
    <path
      d="M352.444 150.308V142.715L345.867 138.918L339.291 142.715V150.308L345.867 154.105L352.444 150.308Z"
      fill="#DBDBDB"
    />
    <path
      d="M367.186 150.308V142.715L360.61 138.918L354.033 142.715V150.308L360.61 154.105L367.186 150.308Z"
      fill="#DBDBDB"
    />
    <path
      d="M352.444 175.328V167.734L345.867 163.938L339.291 167.734V175.328L345.867 179.125L352.444 175.328Z"
      fill="#DBDBDB"
    />
    <path
      d="M367.186 175.328V167.734L360.61 163.938L354.033 167.734V175.328L360.61 179.125L367.186 175.328Z"
      fill="#DBDBDB"
    />
    <path
      d="M381.715 150.308V142.715L375.138 138.918L368.562 142.715V150.308L375.138 154.105L381.715 150.308Z"
      fill="#DBDBDB"
    />
    <path
      d="M396.514 150.308V142.715L389.938 138.918L383.362 142.715V150.308L389.938 154.105L396.514 150.308Z"
      fill="#DBDBDB"
    />
    <path
      d="M35.3597 112.901V105.307L28.7834 101.496L22.207 105.307V112.901L28.7834 116.698L35.3597 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M50.1622 112.901V105.307L43.5859 101.496L37.0095 105.307V112.901L43.5859 116.698L50.1622 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M64.9615 112.901V105.307L58.3852 101.496L51.8088 105.307V112.901L58.3852 116.698L64.9615 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M79.7754 112.901V105.307L73.1847 101.496L66.6084 105.307V112.901L73.1847 116.698L79.7754 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M94.4469 112.901V105.307L87.8705 101.496L81.2942 105.307V112.901L87.8705 116.698L94.4469 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M109.248 112.901V105.307L102.672 101.496L96.0957 105.307V112.901L102.672 116.698L109.248 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M124.061 112.901V105.307L117.471 101.496L110.894 105.307V112.901L117.471 116.698L124.061 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M138.863 112.901V105.307L132.286 101.496L125.696 105.307V112.901L132.286 116.698L138.863 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M153.535 112.901V105.307L146.959 101.496L140.382 105.307V112.901L146.959 116.698L153.535 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M168.334 112.901V105.307L161.757 101.496L155.181 105.307V112.901L161.757 116.698L168.334 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M183.149 112.901V105.307L176.559 101.496L169.982 105.307V112.901L176.559 116.698L183.149 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M183.149 87.4841V79.8905L176.559 76.0938L169.982 79.8905V87.4841L176.559 91.2809L183.149 87.4841Z"
      fill="#DBDBDB"
    />
    <path
      d="M197.95 112.901V105.307L191.373 101.496L184.783 105.307V112.901L191.373 116.698L197.95 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M212.62 112.901V105.307L206.044 101.496L199.468 105.307V112.901L206.044 116.698L212.62 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M227.434 112.901V105.307L220.844 101.496L214.267 105.307V112.901L220.844 116.698L227.434 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M242.236 112.901V105.307L235.645 101.496L229.069 105.307V112.901L235.645 116.698L242.236 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.036 112.901V105.307L250.46 101.496L243.869 105.307V112.901L250.46 116.698L257.036 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.708 112.901V105.307L265.131 101.496L258.555 105.307V112.901L265.131 116.698L271.708 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.522 112.901V105.307L279.932 101.496L273.355 105.307V112.901L279.932 116.698L286.522 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M35.3597 137.948V130.34L28.7834 126.543L22.207 130.34V137.948L28.7834 141.744L35.3597 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M20.5604 137.948V130.34L13.9841 126.543L7.40771 130.34V137.948L13.9841 141.744L20.5604 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M50.1622 137.948V130.34L43.5859 126.543L37.0095 130.34V137.948L43.5859 141.744L50.1622 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M64.9615 137.948V130.34L58.3852 126.543L51.8088 130.34V137.948L58.3852 141.744L64.9615 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M79.7754 137.948V130.34L73.1847 126.543L66.6084 130.34V137.948L73.1847 141.744L79.7754 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M94.4469 137.948V130.34L87.8705 126.543L81.2942 130.34V137.948L87.8705 141.744L94.4469 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M109.248 137.948V130.34L102.672 126.543L96.0957 130.34V137.948L102.672 141.744L109.248 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M124.061 137.948V130.34L117.471 126.543L110.894 130.34V137.948L117.471 141.744L124.061 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M138.863 137.948V130.34L132.286 126.543L125.696 130.34V137.948L132.286 141.744L138.863 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M153.535 137.948V130.34L146.959 126.543L140.382 130.34V137.948L146.959 141.744L153.535 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M168.334 137.948V130.34L161.757 126.543L155.181 130.34V137.948L161.757 141.744L168.334 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M183.149 137.948V130.34L176.559 126.543L169.982 130.34V137.948L176.559 141.744L183.149 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M197.95 137.948V130.34L191.373 126.543L184.783 130.34V137.948L191.373 141.744L197.95 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M212.62 137.948V130.34L206.044 126.543L199.468 130.34V137.948L206.044 141.744L212.62 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M227.434 137.948V130.34L220.844 126.543L214.267 130.34V137.948L220.844 141.744L227.434 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M242.236 137.948V130.34L235.645 126.543L229.069 130.34V137.948L235.645 141.744L242.236 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.036 137.948V130.34L250.46 126.543L243.869 130.34V137.948L250.46 141.744L257.036 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.708 137.948V130.34L265.131 126.543L258.555 130.34V137.948L265.131 141.744L271.708 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.522 137.948V130.34L279.932 126.543L273.355 130.34V137.948L279.932 141.744L286.522 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.237 137.948V130.34L294.661 126.543L288.085 130.34V137.948L294.661 141.744L301.237 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.237 162.972V155.379L294.661 151.582L288.085 155.379V162.972L294.661 166.769L301.237 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M35.3597 162.972V155.379L28.7834 151.582L22.207 155.379V162.972L28.7834 166.769L35.3597 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M20.5604 162.972V155.379L13.9841 151.582L7.40771 155.379V162.972L13.9841 166.769L20.5604 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M50.1622 162.972V155.379L43.5859 151.582L37.0095 155.379V162.972L43.5859 166.769L50.1622 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M64.9615 162.972V155.379L58.3852 151.582L51.8088 155.379V162.972L58.3852 166.769L64.9615 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M64.9615 238.112V230.504L58.3852 226.707L51.8088 230.504V238.112L58.3852 241.909L64.9615 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M79.7754 162.972V155.379L73.1847 151.582L66.6084 155.379V162.972L73.1847 166.769L79.7754 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M94.4469 162.972V155.379L87.8705 151.582L81.2942 155.379V162.972L87.8705 166.769L94.4469 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M109.248 162.972V155.379L102.672 151.582L96.0957 155.379V162.972L102.672 166.769L109.248 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M124.061 162.972V155.379L117.471 151.582L110.894 155.379V162.972L117.471 166.769L124.061 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M138.863 162.972V155.379L132.286 151.582L125.696 155.379V162.972L132.286 166.769L138.863 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M153.535 162.972V155.379L146.959 151.582L140.382 155.379V162.972L146.959 166.769L153.535 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M168.334 162.972V155.379L161.757 151.582L155.181 155.379V162.972L161.757 166.769L168.334 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M183.149 162.972V155.379L176.559 151.582L169.982 155.379V162.972L176.559 166.769L183.149 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M197.95 162.972V155.379L191.373 151.582L184.783 155.379V162.972L191.373 166.769L197.95 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M212.62 162.972V155.379L206.044 151.582L199.468 155.379V162.972L206.044 166.769L212.62 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M79.7754 188.019V180.426L73.1847 176.629L66.6084 180.426V188.019L73.1847 191.816L79.7754 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M94.4469 188.019V180.426L87.8705 176.629L81.2942 180.426V188.019L87.8705 191.816L94.4469 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M109.248 188.019V180.426L102.672 176.629L96.0957 180.426V188.019L102.672 191.816L109.248 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M124.061 188.019V180.426L117.471 176.629L110.894 180.426V188.019L117.471 191.816L124.061 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M138.863 188.019V180.426L132.286 176.629L125.696 180.426V188.019L132.286 191.816L138.863 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M153.535 188.019V180.426L146.959 176.629L140.382 180.426V188.019L146.959 191.816L153.535 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M168.334 188.019V180.426L161.757 176.629L155.181 180.426V188.019L161.757 191.816L168.334 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M183.149 188.019V180.426L176.559 176.629L169.982 180.426V188.019L176.559 191.816L183.149 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M197.95 188.019V180.426L191.373 176.629L184.783 180.426V188.019L191.373 191.816L197.95 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M212.62 188.019V180.426L206.044 176.629L199.468 180.426V188.019L206.044 191.816L212.62 188.019Z"
      fill="#DBDBDB"
    />
    <path
      id="public-map-canada-kelp"
      d="M79.7754 213.066V205.473L73.1847 201.676L66.6084 205.473V213.066L73.1847 216.863L79.7754 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M94.4469 213.066V205.473L87.8705 201.676L81.2942 205.473V213.066L87.8705 216.863L94.4469 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M109.248 213.066V205.473L102.672 201.676L96.0957 205.473V213.066L102.672 216.863L109.248 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M124.061 213.066V205.473L117.471 201.676L110.894 205.473V213.066L117.471 216.863L124.061 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M138.863 213.066V205.473L132.286 201.676L125.696 205.473V213.066L132.286 216.863L138.863 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M153.535 213.066V205.473L146.959 201.676L140.382 205.473V213.066L146.959 216.863L153.535 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M168.334 213.066V205.473L161.757 201.676L155.181 205.473V213.066L161.757 216.863L168.334 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M183.149 213.066V205.473L176.559 201.676L169.982 205.473V213.066L176.559 216.863L183.149 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M197.95 213.066V205.473L191.373 201.676L184.783 205.473V213.066L191.373 216.863L197.95 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M212.62 213.066V205.473L206.044 201.676L199.468 205.473V213.066L206.044 216.863L212.62 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M227.434 162.972V155.379L220.844 151.582L214.267 155.379V162.972L220.844 166.769L227.434 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M242.236 162.972V155.379L235.645 151.582L229.069 155.379V162.972L235.645 166.769L242.236 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.036 162.972V155.379L250.46 151.582L243.869 155.379V162.972L250.46 166.769L257.036 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.708 162.972V155.379L265.131 151.582L258.555 155.379V162.972L265.131 166.769L271.708 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.522 162.972V155.379L279.932 151.582L273.355 155.379V162.972L279.932 166.769L286.522 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.237 213.066V205.473L294.661 201.676L288.085 205.473V213.066L294.661 216.863L301.237 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M315.923 213.066V205.473L309.332 201.676L302.756 205.473V213.066L309.332 216.863L315.923 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M227.434 213.066V205.473L220.844 201.676L214.267 205.473V213.066L220.844 216.863L227.434 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M242.236 213.066V205.473L235.645 201.676L229.069 205.473V213.066L235.645 216.863L242.236 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.036 213.066V205.473L250.46 201.676L243.869 205.473V213.066L250.46 216.863L257.036 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.708 213.066V205.473L265.131 201.676L258.555 205.473V213.066L265.131 216.863L271.708 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.522 213.066V205.473L279.932 201.676L273.355 205.473V213.066L279.932 216.863L286.522 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M79.7754 238.112V230.504L73.1847 226.707L66.6084 230.504V238.112L73.1847 241.909L79.7754 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M94.4469 238.112V230.504L87.8705 226.707L81.2942 230.504V238.112L87.8705 241.909L94.4469 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M109.248 238.112V230.504L102.672 226.707L96.0957 230.504V238.112L102.672 241.909L109.248 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M124.061 238.112V230.504L117.471 226.707L110.894 230.504V238.112L117.471 241.909L124.061 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M138.863 238.112V230.504L132.286 226.707L125.696 230.504V238.112L132.286 241.909L138.863 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M153.535 238.112V230.504L146.959 226.707L140.382 230.504V238.112L146.959 241.909L153.535 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M168.334 238.112V230.504L161.757 226.707L155.181 230.504V238.112L161.757 241.909L168.334 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M183.149 238.112V230.504L176.559 226.707L169.982 230.504V238.112L176.559 241.909L183.149 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M197.95 238.112V230.504L191.373 226.707L184.783 230.504V238.112L191.373 241.909L197.95 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M212.62 238.112V230.504L206.044 226.707L199.468 230.504V238.112L206.044 241.909L212.62 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M227.434 238.112V230.504L220.844 226.707L214.267 230.504V238.112L220.844 241.909L227.434 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M242.236 238.112V230.504L235.645 226.707L229.069 230.504V238.112L235.645 241.909L242.236 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.036 238.112V230.504L250.46 226.707L243.869 230.504V238.112L250.46 241.909L257.036 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.708 238.112V230.504L265.131 226.707L258.555 230.504V238.112L265.131 241.909L271.708 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.522 238.112V230.504L279.932 226.707L273.355 230.504V238.112L279.932 241.909L286.522 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M64.9615 263.14V255.547L58.3852 251.75L51.8088 255.547V263.14L58.3852 266.952L64.9615 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M79.7754 263.14V255.547L73.1847 251.75L66.6084 255.547V263.14L73.1847 266.952L79.7754 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M94.4469 263.14V255.547L87.8705 251.75L81.2942 255.547V263.14L87.8705 266.952L94.4469 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M109.248 263.14V255.547L102.672 251.75L96.0957 255.547V263.14L102.672 266.952L109.248 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M124.061 263.14V255.547L117.471 251.75L110.894 255.547V263.14L117.471 266.952L124.061 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M138.863 263.14V255.547L132.286 251.75L125.696 255.547V263.14L132.286 266.952L138.863 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M153.535 263.14V255.547L146.959 251.75L140.382 255.547V263.14L146.959 266.952L153.535 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M168.334 263.14V255.547L161.757 251.75L155.181 255.547V263.14L161.757 266.952L168.334 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M183.149 263.14V255.547L176.559 251.75L169.982 255.547V263.14L176.559 266.952L183.149 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M197.95 263.14V255.547L191.373 251.75L184.783 255.547V263.14L191.373 266.952L197.95 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M212.62 263.14V255.547L206.044 251.75L199.468 255.547V263.14L206.044 266.952L212.62 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M227.434 263.14V255.547L220.844 251.75L214.267 255.547V263.14L220.844 266.952L227.434 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M64.9615 288.187V280.594L58.3852 276.797L51.8088 280.594V288.187L58.3852 291.984L64.9615 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M50.1622 288.187V280.594L43.5859 276.797L37.0095 280.594V288.187L43.5859 291.984L50.1622 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M79.7754 288.187V280.594L73.1847 276.797L66.6084 280.594V288.187L73.1847 291.984L79.7754 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M94.4469 288.187V280.594L87.8705 276.797L81.2942 280.594V288.187L87.8705 291.984L94.4469 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M109.248 288.187V280.594L102.672 276.797L96.0957 280.594V288.187L102.672 291.984L109.248 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M124.061 288.187V280.594L117.471 276.797L110.894 280.594V288.187L117.471 291.984L124.061 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M138.863 288.187V280.594L132.286 276.797L125.696 280.594V288.187L132.286 291.984L138.863 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M153.535 288.187V280.594L146.959 276.797L140.382 280.594V288.187L146.959 291.984L153.535 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M168.334 288.187V280.594L161.757 276.797L155.181 280.594V288.187L161.757 291.984L168.334 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M183.149 288.187V280.594L176.559 276.797L169.982 280.594V288.187L176.559 291.984L183.149 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M197.95 288.187V280.594L191.373 276.797L184.783 280.594V288.187L191.373 291.984L197.95 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M212.62 288.187V280.594L206.044 276.797L199.468 280.594V288.187L206.044 291.984L212.62 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M153.535 313.226V305.633L146.959 301.836L140.382 305.633V313.226L146.959 317.023L153.535 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M168.334 313.226V305.633L161.757 301.836L155.181 305.633V313.226L161.757 317.023L168.334 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M183.149 313.226V305.633L176.559 301.836L169.982 305.633V313.226L176.559 317.023L183.149 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M197.95 313.226V305.633L191.373 301.836L184.783 305.633V313.226L191.373 317.023L197.95 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M212.62 313.226V305.633L206.044 301.836L199.468 305.633V313.226L206.044 317.023L212.62 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M64.9615 313.226V305.633L58.3852 301.836L51.8088 305.633V313.226L58.3852 317.023L64.9615 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M79.7754 313.226V305.633L73.1847 301.836L66.6084 305.633V313.226L73.1847 317.023L79.7754 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M94.4469 313.226V305.633L87.8705 301.836L81.2942 305.633V313.226L87.8705 317.023L94.4469 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M109.248 313.226V305.633L102.672 301.836L96.0957 305.633V313.226L102.672 317.023L109.248 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M124.061 313.226V305.633L117.471 301.836L110.894 305.633V313.226L117.471 317.023L124.061 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M64.9615 338.273V330.68L58.3852 326.883L51.8088 330.68V338.273L58.3852 342.07L64.9615 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M79.7754 338.273V330.68L73.1847 326.883L66.6084 330.68V338.273L73.1847 342.07L79.7754 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M94.4469 338.273V330.68L87.8705 326.883L81.2942 330.68V338.273L87.8705 342.07L94.4469 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M109.248 338.273V330.68L102.672 326.883L96.0957 330.68V338.273L102.672 342.07L109.248 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M124.061 338.273V330.68L117.471 326.883L110.894 330.68V338.273L117.471 342.07L124.061 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M138.863 313.226V305.633L132.286 301.836L125.696 305.633V313.226L132.286 317.023L138.863 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M183.149 413.449V405.855L176.559 402.059L169.982 405.855V413.449L176.559 417.26L183.149 413.449Z"
      fill="#DBDBDB"
    />
    <path
      d="M212.749 413.449V405.855L206.173 402.059L199.596 405.855V413.449L206.173 417.26L212.749 413.449Z"
      fill="#DBDBDB"
    />
    <path
      d="M138.863 388.276V380.668L132.286 376.871L125.696 380.668V388.276L132.286 392.073L138.863 388.276Z"
      fill="#DBDBDB"
    />
    <path
      d="M153.535 388.276V380.668L146.959 376.871L140.382 380.668V388.276L146.959 392.073L153.535 388.276Z"
      fill="#DBDBDB"
    />
    <path
      d="M168.334 388.276V380.668L161.757 376.871L155.181 380.668V388.276L161.757 392.073L168.334 388.276Z"
      fill="#DBDBDB"
    />
    <path
      d="M183.149 388.276V380.668L176.559 376.871L169.982 380.668V388.276L176.559 392.073L183.149 388.276Z"
      fill="#DBDBDB"
    />
    <path
      d="M227.55 413.449V405.855L220.974 402.059L214.397 405.855V413.449L220.974 417.26L227.55 413.449Z"
      fill="#DBDBDB"
    />
    <path
      d="M242.236 413.449V405.855L235.645 402.059L229.069 405.855V413.449L235.645 417.26L242.236 413.449Z"
      fill="#DBDBDB"
    />
    <path
      d="M256.907 413.449V405.855L250.331 402.059L243.754 405.855V413.449L250.331 417.26L256.907 413.449Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.593 413.449V405.855L265.016 402.059L258.426 405.855V413.449L265.016 417.26L271.593 413.449Z"
      fill="#DBDBDB"
    />
    <path
      d="M94.4469 363.319V355.711L87.8705 351.914L81.2942 355.711V363.319L87.8705 367.116L94.4469 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M109.248 363.319V355.711L102.672 351.914L96.0957 355.711V363.319L102.672 367.116L109.248 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M124.061 363.319V355.711L117.471 351.914L110.894 355.711V363.319L117.471 367.116L124.061 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M153.535 363.319V355.711L146.959 351.914L140.382 355.711V363.319L146.959 367.116L153.535 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M168.334 363.319V355.711L161.757 351.914L155.181 355.711V363.319L161.757 367.116L168.334 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M197.95 363.319V355.711L191.373 351.914L184.783 355.711V363.319L191.373 367.116L197.95 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M212.62 363.319V355.711L206.044 351.914L199.468 355.711V363.319L206.044 367.116L212.62 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M227.434 288.187V280.594L220.844 276.797L214.267 280.594V288.187L220.844 291.984L227.434 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M242.236 263.14V255.547L235.645 251.75L229.069 255.547V263.14L235.645 266.952L242.236 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.036 263.14V255.547L250.46 251.75L243.869 255.547V263.14L250.46 266.952L257.036 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.708 263.14V255.547L265.131 251.75L258.555 255.547V263.14L265.131 266.952L271.708 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.237 263.14V255.547L294.661 251.75L288.085 255.547V263.14L294.661 266.952L301.237 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M330.594 238.112V230.504L324.018 226.707L317.442 230.504V238.112L324.018 241.909L330.594 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.237 188.019V180.426L294.661 176.629L288.085 180.426V188.019L294.661 191.816L301.237 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.708 188.019V180.426L265.131 176.629L258.555 180.426V188.019L265.131 191.816L271.708 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.522 188.019V180.426L279.932 176.629L273.355 180.426V188.019L279.932 191.816L286.522 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M27.9663 125.385V117.792L21.3757 113.98L14.7993 117.792V125.385L21.3757 129.182L27.9663 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M42.7688 125.385V117.792L36.1925 113.98L29.6018 117.792V125.385L36.1925 129.182L42.7688 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M57.568 125.385V117.792L50.9916 113.98L44.4153 117.792V125.385L50.9916 129.182L57.568 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M72.3692 125.385V117.792L65.7929 113.98L59.2166 117.792V125.385L65.7929 129.182L72.3692 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M87.0554 125.385V117.792L80.4648 113.98L73.8884 117.792V125.385L80.4648 129.182L87.0554 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M101.854 125.385V117.792L95.2777 113.98L88.687 117.792V125.385L95.2777 129.182L101.854 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M116.654 125.385V117.792L110.078 113.98L103.501 117.792V125.385L110.078 129.182L116.654 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M131.455 125.385V117.792L124.879 113.98L118.303 117.792V125.385L124.879 129.182L131.455 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M146.141 125.385V117.792L139.55 113.98L132.974 117.792V125.385L139.55 129.182L146.141 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M160.942 125.385V117.792L154.366 113.98L147.775 117.792V125.385L154.366 129.182L160.942 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M175.743 125.385V117.792L169.167 113.98L162.59 117.792V125.385L169.167 129.182L175.743 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M190.542 125.385V117.792L183.965 113.98L177.389 117.792V125.385L183.965 129.182L190.542 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M205.227 125.385V117.792L198.651 113.98L192.06 117.792V125.385L198.651 129.182L205.227 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M220.029 125.385V117.792L213.453 113.98L206.876 117.792V125.385L213.453 129.182L220.029 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M234.828 125.385V117.792L228.252 113.98L221.676 117.792V125.385L228.252 129.182L234.828 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M249.628 125.385V117.792L243.052 113.98L236.475 117.792V125.385L243.052 129.182L249.628 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.314 125.385V117.792L257.738 113.98L251.147 117.792V125.385L257.738 129.182L264.314 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.115 125.385V117.792L272.539 113.98L265.962 117.792V125.385L272.539 129.182L279.115 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.829 125.385V117.792L287.252 113.98L280.676 117.792V125.385L287.252 129.182L293.829 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M308.643 125.385V117.792L302.052 113.98L295.476 117.792V125.385L302.052 129.182L308.643 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.829 150.42V142.812L287.252 139.016L280.676 142.812V150.42L287.252 154.217L293.829 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M308.643 150.42V142.812L302.052 139.016L295.476 142.812V150.42L302.052 154.217L308.643 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M27.9663 150.42V142.812L21.3757 139.016L14.7993 142.812V150.42L21.3757 154.217L27.9663 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M13.1527 150.42V142.812L6.57634 139.016L0 142.812V150.42L6.57634 154.217L13.1527 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M42.7688 150.42V142.812L36.1925 139.016L29.6018 142.812V150.42L36.1925 154.217L42.7688 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M57.568 150.42V142.812L50.9916 139.016L44.4153 142.812V150.42L50.9916 154.217L57.568 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M72.3692 150.42V142.812L65.7929 139.016L59.2166 142.812V150.42L65.7929 154.217L72.3692 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M87.0554 150.42V142.812L80.4648 139.016L73.8884 142.812V150.42L80.4648 154.217L87.0554 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M101.854 150.42V142.812L95.2777 139.016L88.687 142.812V150.42L95.2777 154.217L101.854 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M116.654 150.42V142.812L110.078 139.016L103.501 142.812V150.42L110.078 154.217L116.654 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M131.455 150.42V142.812L124.879 139.016L118.303 142.812V150.42L124.879 154.217L131.455 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M146.141 150.42V142.812L139.55 139.016L132.974 142.812V150.42L139.55 154.217L146.141 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M160.942 150.42V142.812L154.366 139.016L147.775 142.812V150.42L154.366 154.217L160.942 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M175.743 150.42V142.812L169.167 139.016L162.59 142.812V150.42L169.167 154.217L175.743 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M190.542 150.42V142.812L183.965 139.016L177.389 142.812V150.42L183.965 154.217L190.542 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M205.227 150.42V142.812L198.651 139.016L192.06 142.812V150.42L198.651 154.217L205.227 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M72.3692 175.457V167.863L65.7929 164.066L59.2166 167.863V175.457L65.7929 179.254L72.3692 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M87.0554 175.457V167.863L80.4648 164.066L73.8884 167.863V175.457L80.4648 179.254L87.0554 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M101.854 175.457V167.863L95.2777 164.066L88.687 167.863V175.457L95.2777 179.254L101.854 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M116.654 175.457V167.863L110.078 164.066L103.501 167.863V175.457L110.078 179.254L116.654 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M131.455 175.457V167.863L124.879 164.066L118.303 167.863V175.457L124.879 179.254L131.455 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M146.141 175.457V167.863L139.55 164.066L132.974 167.863V175.457L139.55 179.254L146.141 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M160.942 175.457V167.863L154.366 164.066L147.775 167.863V175.457L154.366 179.254L160.942 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M175.743 175.457V167.863L169.167 164.066L162.59 167.863V175.457L169.167 179.254L175.743 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M190.542 175.457V167.863L183.965 164.066L177.389 167.863V175.457L183.965 179.254L190.542 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M205.227 175.457V167.863L198.651 164.066L192.06 167.863V175.457L198.651 179.254L205.227 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M220.029 150.42V142.812L213.453 139.016L206.876 142.812V150.42L213.453 154.217L220.029 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M72.3692 200.5V192.906L65.7929 189.109L59.2166 192.906V200.5L65.7929 204.297L72.3692 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M87.0554 200.5V192.906L80.4648 189.109L73.8884 192.906V200.5L80.4648 204.297L87.0554 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M101.854 200.5V192.906L95.2777 189.109L88.687 192.906V200.5L95.2777 204.297L101.854 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M116.654 200.5V192.906L110.078 189.109L103.501 192.906V200.5L110.078 204.297L116.654 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M131.455 200.5V192.906L124.879 189.109L118.303 192.906V200.5L124.879 204.297L131.455 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M146.141 200.5V192.906L139.55 189.109L132.974 192.906V200.5L139.55 204.297L146.141 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M160.942 200.5V192.906L154.366 189.109L147.775 192.906V200.5L154.366 204.297L160.942 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M175.743 200.5V192.906L169.167 189.109L162.59 192.906V200.5L169.167 204.297L175.743 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M190.542 200.5V192.906L183.965 189.109L177.389 192.906V200.5L183.965 204.297L190.542 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M205.227 200.5V192.906L198.651 189.109L192.06 192.906V200.5L198.651 204.297L205.227 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M220.029 200.5V192.906L213.453 189.109L206.876 192.906V200.5L213.453 204.297L220.029 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M234.828 150.42V142.812L228.252 139.016L221.676 142.812V150.42L228.252 154.217L234.828 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M249.628 150.42V142.812L243.052 139.016L236.475 142.812V150.42L243.052 154.217L249.628 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.314 150.42V142.812L257.738 139.016L251.147 142.812V150.42L257.738 154.217L264.314 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.115 150.42V142.812L272.539 139.016L265.962 142.812V150.42L272.539 154.217L279.115 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.829 225.547V217.953L287.252 214.156L280.676 217.953V225.547L287.252 229.343L293.829 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M308.643 225.547V217.953L302.052 214.156L295.476 217.953V225.547L302.052 229.343L308.643 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M323.317 225.547V217.953L316.741 214.156L310.164 217.953V225.547L316.741 229.343L323.317 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M72.3692 225.547V217.953L65.7929 214.156L59.2166 217.953V225.547L65.7929 229.343L72.3692 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M87.0554 225.547V217.953L80.4648 214.156L73.8884 217.953V225.547L80.4648 229.343L87.0554 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M101.854 225.547V217.953L95.2777 214.156L88.687 217.953V225.547L95.2777 229.343L101.854 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M116.654 225.547V217.953L110.078 214.156L103.501 217.953V225.547L110.078 229.343L116.654 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M131.455 225.547V217.953L124.879 214.156L118.303 217.953V225.547L124.879 229.343L131.455 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M146.141 225.547V217.953L139.55 214.156L132.974 217.953V225.547L139.55 229.343L146.141 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M160.942 225.547V217.953L154.366 214.156L147.775 217.953V225.547L154.366 229.343L160.942 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M175.743 225.547V217.953L169.167 214.156L162.59 217.953V225.547L169.167 229.343L175.743 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M190.542 225.547V217.953L183.965 214.156L177.389 217.953V225.547L183.965 229.343L190.542 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M205.227 225.547V217.953L198.651 214.156L192.06 217.953V225.547L198.651 229.343L205.227 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M220.029 225.547V217.953L213.453 214.156L206.876 217.953V225.547L213.453 229.343L220.029 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M234.828 225.547V217.953L228.252 214.156L221.676 217.953V225.547L228.252 229.343L234.828 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M249.628 225.547V217.953L243.052 214.156L236.475 217.953V225.547L243.052 229.343L249.628 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.314 225.547V217.953L257.738 214.156L251.147 217.953V225.547L257.738 229.343L264.314 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.115 225.547V217.953L272.539 214.156L265.962 217.953V225.547L272.539 229.343L279.115 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.829 250.592V242.984L287.252 239.188L280.676 242.984V250.592L287.252 254.389L293.829 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M337.872 250.592V242.984L331.296 239.188L324.72 242.984V250.592L331.296 254.389L337.872 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M72.3692 250.592V242.984L65.7929 239.188L59.2166 242.984V250.592L65.7929 254.389L72.3692 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M57.6099 250.592V242.984L51.0335 239.188L44.4429 242.984V250.592L51.0335 254.389L57.6099 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M87.0554 250.592V242.984L80.4648 239.188L73.8884 242.984V250.592L80.4648 254.389L87.0554 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M101.854 250.592V242.984L95.2777 239.188L88.687 242.984V250.592L95.2777 254.389L101.854 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M116.654 250.592V242.984L110.078 239.188L103.501 242.984V250.592L110.078 254.389L116.654 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M131.455 250.592V242.984L124.879 239.188L118.303 242.984V250.592L124.879 254.389L131.455 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M146.141 250.592V242.984L139.55 239.188L132.974 242.984V250.592L139.55 254.389L146.141 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M160.942 250.592V242.984L154.366 239.188L147.775 242.984V250.592L154.366 254.389L160.942 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M175.743 250.592V242.984L169.167 239.188L162.59 242.984V250.592L169.167 254.389L175.743 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M190.542 250.592V242.984L183.965 239.188L177.389 242.984V250.592L183.965 254.389L190.542 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M205.227 250.592V242.984L198.651 239.188L192.06 242.984V250.592L198.651 254.389L205.227 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M220.029 250.592V242.984L213.453 239.188L206.876 242.984V250.592L213.453 254.389L220.029 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M234.828 250.592V242.984L228.252 239.188L221.676 242.984V250.592L228.252 254.389L234.828 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M249.628 250.592V242.984L243.052 239.188L236.475 242.984V250.592L243.052 254.389L249.628 250.592Z"
      fill="#DBDBDB"
    />
    <path
      id="public-map-usa-trees"
      d="M72.3692 275.621V268.027L65.7929 264.23L59.2166 268.027V275.621L65.7929 279.432L72.3692 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M57.6099 275.621V268.027L51.0335 264.23L44.4429 268.027V275.621L51.0335 279.432L57.6099 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M87.0554 275.621V268.027L80.4648 264.23L73.8884 268.027V275.621L80.4648 279.432L87.0554 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M101.854 275.621V268.027L95.2777 264.23L88.687 268.027V275.621L95.2777 279.432L101.854 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M116.654 275.621V268.027L110.078 264.23L103.501 268.027V275.621L110.078 279.432L116.654 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M131.455 275.621V268.027L124.879 264.23L118.303 268.027V275.621L124.879 279.432L131.455 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M146.141 275.621V268.027L139.55 264.23L132.974 268.027V275.621L139.55 279.432L146.141 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M160.942 275.621V268.027L154.366 264.23L147.775 268.027V275.621L154.366 279.432L160.942 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M175.743 275.621V268.027L169.167 264.23L162.59 268.027V275.621L169.167 279.432L175.743 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M190.542 275.621V268.027L183.965 264.23L177.389 268.027V275.621L183.965 279.432L190.542 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M205.227 275.621V268.027L198.651 264.23L192.06 268.027V275.621L198.651 279.432L205.227 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M220.029 275.621V268.027L213.453 264.23L206.876 268.027V275.621L213.453 279.432L220.029 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M234.828 275.621V268.027L228.252 264.23L221.676 268.027V275.621L228.252 279.432L234.828 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M72.3692 300.66V293.066L65.7929 289.27L59.2166 293.066V300.66L65.7929 304.457L72.3692 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M57.6099 300.66V293.066L51.0335 289.27L44.4429 293.066V300.66L51.0335 304.457L57.6099 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M87.0554 300.66V293.066L80.4648 289.27L73.8884 293.066V300.66L80.4648 304.457L87.0554 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M101.854 300.66V293.066L95.2777 289.27L88.687 293.066V300.66L95.2777 304.457L101.854 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M116.654 300.66V293.066L110.078 289.27L103.501 293.066V300.66L110.078 304.457L116.654 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M131.455 300.66V293.066L124.879 289.27L118.303 293.066V300.66L124.879 304.457L131.455 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M72.3692 325.711V318.117L65.7929 314.32L59.2166 318.117V325.711L65.7929 329.507L72.3692 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M57.6099 325.711V318.117L51.0335 314.32L44.4429 318.117V325.711L51.0335 329.507L57.6099 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M87.0554 325.711V318.117L80.4648 314.32L73.8884 318.117V325.711L80.4648 329.507L87.0554 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M101.854 325.711V318.117L95.2777 314.32L88.687 318.117V325.711L95.2777 329.507L101.854 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M116.654 325.711V318.117L110.078 314.32L103.501 318.117V325.711L110.078 329.507L116.654 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M72.3692 350.748V343.155L65.7929 339.344L59.2166 343.155V350.748L65.7929 354.545L72.3692 350.748Z"
      fill="#DBDBDB"
    />
    <path
      d="M87.0554 350.748V343.155L80.4648 339.344L73.8884 343.155V350.748L80.4648 354.545L87.0554 350.748Z"
      fill="#DBDBDB"
    />
    <path
      d="M101.854 350.748V343.155L95.2777 339.344L88.687 343.155V350.748L95.2777 354.545L101.854 350.748Z"
      fill="#DBDBDB"
    />
    <path
      d="M116.654 350.748V343.155L110.078 339.344L103.501 343.155V350.748L110.078 354.545L116.654 350.748Z"
      fill="#DBDBDB"
    />
    <path
      d="M131.455 325.711V318.117L124.879 314.32L118.303 318.117V325.711L124.879 329.507L131.455 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M146.141 300.66V293.066L139.55 289.27L132.974 293.066V300.66L139.55 304.457L146.141 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M160.942 300.66V293.066L154.366 289.27L147.775 293.066V300.66L154.366 304.457L160.942 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M175.743 300.66V293.066L169.167 289.27L162.59 293.066V300.66L169.167 304.457L175.743 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M190.542 300.66V293.066L183.965 289.27L177.389 293.066V300.66L183.965 304.457L190.542 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M205.227 300.66V293.066L198.651 289.27L192.06 293.066V300.66L198.651 304.457L205.227 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M220.029 300.66V293.066L213.453 289.27L206.876 293.066V300.66L213.453 304.457L220.029 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M234.828 300.66V293.066L228.252 289.27L221.676 293.066V300.66L228.252 304.457L234.828 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M101.854 375.795V368.187L95.2777 364.391L88.687 368.187V375.795L95.2777 379.592L101.854 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M116.654 375.795V368.187L110.078 364.391L103.501 368.187V375.795L110.078 379.592L116.654 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M131.455 375.795V368.187L124.879 364.391L118.303 368.187V375.795L124.879 379.592L131.455 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M146.141 375.795V368.187L139.55 364.391L132.974 368.187V375.795L139.55 379.592L146.141 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M160.942 375.795V368.187L154.366 364.391L147.775 368.187V375.795L154.366 379.592L160.942 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M175.743 375.795V368.187L169.167 364.391L162.59 368.187V375.795L169.167 379.592L175.743 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M146.141 400.871V393.277L139.55 389.48L132.974 393.277V400.871L139.55 404.668L146.141 400.871Z"
      fill="#DBDBDB"
    />
    <path
      id="public-map-honduras-trees"
      d="M160.942 400.871V393.277L154.366 389.48L147.775 393.277V400.871L154.366 404.668L160.942 400.871Z"
      fill="#DBDBDB"
    />
    <path
      d="M175.743 400.871V393.277L169.167 389.48L162.59 393.277V400.871L169.167 404.668L175.743 400.871Z"
      fill="#DBDBDB"
    />
    <path
      d="M205.227 375.795V368.187L198.651 364.391L192.06 368.187V375.795L198.651 379.592L205.227 375.795Z"
      fill="#DBDBDB"
    />
    <path
      id="public-map-haiti-plastic"
      d="M220.029 375.795V368.187L213.453 364.391L206.876 368.187V375.795L213.453 379.592L220.029 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M249.628 275.621V268.027L243.052 264.23L236.475 268.027V275.621L243.052 279.432L249.628 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.314 250.592V242.984L257.738 239.188L251.147 242.984V250.592L257.738 254.389L264.314 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.115 250.592V242.984L272.539 239.188L265.962 242.984V250.592L272.539 254.389L279.115 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.829 175.457V167.863L287.252 164.066L280.676 167.863V175.457L287.252 179.254L293.829 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.314 175.457V167.863L257.738 164.066L251.147 167.863V175.457L257.738 179.254L264.314 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.115 175.457V167.863L272.539 164.066L265.962 167.863V175.457L272.539 179.254L279.115 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.829 200.5V192.906L287.252 189.109L280.676 192.906V200.5L287.252 204.297L293.829 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.314 200.5V192.906L257.738 189.109L251.147 192.906V200.5L257.738 204.297L264.314 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.115 200.5V192.906L272.539 189.109L265.962 192.906V200.5L272.539 204.297L279.115 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M242.236 87.3657V79.7577L235.645 75.9609L229.069 79.7577V87.3657L235.645 91.1625L242.236 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.036 87.3657V79.7577L250.46 75.9609L243.869 79.7577V87.3657L250.46 91.1625L257.036 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.708 87.3657V79.7577L265.131 75.9609L258.555 79.7577V87.3657L265.131 91.1625L271.708 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.522 87.3657V79.7577L279.932 75.9609L273.355 79.7577V87.3657L279.932 91.1625L286.522 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.036 61.8201V54.2265L250.46 50.4297L243.869 54.2265V61.8201L250.46 65.6169L257.036 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.708 61.8201V54.2265L265.131 50.4297L258.555 54.2265V61.8201L265.131 65.6169L271.708 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.522 61.8201V54.2265L279.932 50.4297L273.355 54.2265V61.8201L279.932 65.6169L286.522 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.672 49.0506V41.457L287.095 37.6602L280.519 41.457V49.0506L287.095 52.8473L293.672 49.0506Z"
      fill="#DBDBDB"
    />
    <path
      d="M308.356 49.0506V41.457L301.766 37.6602L295.189 41.457V49.0506L301.766 52.8473L308.356 49.0506Z"
      fill="#DBDBDB"
    />
    <path
      d="M323.157 49.0506V41.457L316.567 37.6602L309.99 41.457V49.0506L316.567 52.8473L323.157 49.0506Z"
      fill="#DBDBDB"
    />
    <path
      d="M381.829 49.0506V41.457L375.253 37.6602L368.677 41.457V49.0506L375.253 52.8473L381.829 49.0506Z"
      fill="#DBDBDB"
    />
    <path
      d="M396.516 49.0506V41.457L389.925 37.6602L383.349 41.457V49.0506L389.925 52.8473L396.516 49.0506Z"
      fill="#DBDBDB"
    />
    <path
      d="M411.314 49.0506V41.457L404.738 37.6602L398.147 41.457V49.0506L404.738 52.8473L411.314 49.0506Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.078 61.8201V54.2265L294.487 50.4297L287.911 54.2265V61.8201L294.487 65.6169L301.078 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M315.749 61.8201V54.2265L309.173 50.4297L302.597 54.2265V61.8201L309.173 65.6169L315.749 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M330.551 61.8201V54.2265L323.975 50.4297L317.398 54.2265V61.8201L323.975 65.6169L330.551 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M345.108 61.8201V54.2265L338.532 50.4297L331.956 54.2265V61.8201L338.532 65.6169L345.108 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M359.794 61.8201V54.2265L353.217 50.4297L346.627 54.2265V61.8201L353.217 65.6169L359.794 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M374.594 61.8201V54.2265L368.017 50.4297L361.441 54.2265V61.8201L368.017 65.6169L374.594 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M389.151 61.8201V54.2265L382.575 50.4297L375.998 54.2265V61.8201L382.575 65.6169L389.151 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M403.822 61.8201V54.2265L397.246 50.4297L390.669 54.2265V61.8201L397.246 65.6169L403.822 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M418.636 61.8201V54.2265L412.045 50.4297L405.469 54.2265V61.8201L412.045 65.6169L418.636 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.078 87.3657V79.7577L294.487 75.9609L287.911 79.7577V87.3657L294.487 91.1625L301.078 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M315.749 87.3657V79.7577L309.173 75.9609L302.597 79.7577V87.3657L309.173 91.1625L315.749 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M330.551 87.3657V79.7577L323.975 75.9609L317.398 79.7577V87.3657L323.975 91.1625L330.551 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M345.108 87.3657V79.7577L338.532 75.9609L331.956 79.7577V87.3657L338.532 91.1625L345.108 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M359.794 87.3657V79.7577L353.217 75.9609L346.627 79.7577V87.3657L353.217 91.1625L359.794 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M374.594 87.3657V79.7577L368.017 75.9609L361.441 79.7577V87.3657L368.017 91.1625L374.594 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M389.151 87.3657V79.7577L382.575 75.9609L375.998 79.7577V87.3657L382.575 91.1625L389.151 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M403.822 87.3657V79.7577L397.246 75.9609L390.669 79.7577V87.3657L397.246 91.1625L403.822 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M418.636 87.3657V79.7577L412.045 75.9609L405.469 79.7577V87.3657L412.045 91.1625L418.636 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M345.108 112.901V105.307L338.532 101.496L331.956 105.307V112.901L338.532 116.698L345.108 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M359.794 112.901V105.307L353.217 101.496L346.627 105.307V112.901L353.217 116.698L359.794 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M374.594 112.901V105.307L368.017 101.496L361.441 105.307V112.901L368.017 116.698L374.594 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M389.151 112.901V105.307L382.575 101.496L375.998 105.307V112.901L382.575 116.698L389.151 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M403.822 112.901V105.307L397.246 101.496L390.669 105.307V112.901L397.246 116.698L403.822 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M418.636 112.901V105.307L412.045 101.496L405.469 105.307V112.901L412.045 116.698L418.636 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M345.108 137.816V130.223L338.532 126.426L331.956 130.223V137.816L338.532 141.613L345.108 137.816Z"
      fill="#DBDBDB"
    />
    <path
      d="M359.794 137.816V130.223L353.217 126.426L346.627 130.223V137.816L353.217 141.613L359.794 137.816Z"
      fill="#DBDBDB"
    />
    <path
      d="M374.594 137.816V130.223L368.017 126.426L361.441 130.223V137.816L368.017 141.613L374.594 137.816Z"
      fill="#DBDBDB"
    />
    <path
      d="M389.151 137.816V130.223L382.575 126.426L375.998 130.223V137.816L382.575 141.613L389.151 137.816Z"
      fill="#DBDBDB"
    />
    <path
      d="M403.822 137.816V130.223L397.246 126.426L390.669 130.223V137.816L397.246 141.613L403.822 137.816Z"
      fill="#DBDBDB"
    />
    <path
      d="M345.108 162.734V155.141L338.532 151.344L331.956 155.141V162.734L338.532 166.531L345.108 162.734Z"
      fill="#DBDBDB"
    />
    <path
      d="M359.794 162.734V155.141L353.217 151.344L346.627 155.141V162.734L353.217 166.531L359.794 162.734Z"
      fill="#DBDBDB"
    />
    <path
      d="M359.794 187.894V180.301L353.217 176.504L346.627 180.301V187.894L353.217 191.705L359.794 187.894Z"
      fill="#DBDBDB"
    />
    <path
      d="M374.594 162.734V155.141L368.017 151.344L361.441 155.141V162.734L368.017 166.531L374.594 162.734Z"
      fill="#DBDBDB"
    />
    <path
      d="M440.529 150.308V142.715L433.952 138.918L427.362 142.715V150.308L433.952 154.105L440.529 150.308Z"
      fill="#DBDBDB"
    />
    <path
      d="M455.271 150.308V142.715L448.695 138.918L442.104 142.715V150.308L448.695 154.105L455.271 150.308Z"
      fill="#DBDBDB"
    />
    <path
      d="M455.271 175.328V167.734L448.695 163.938L442.104 167.734V175.328L448.695 179.125L455.271 175.328Z"
      fill="#DBDBDB"
    />
    <path
      d="M433.193 162.734V155.141L426.603 151.344L420.026 155.141V162.734L426.603 166.531L433.193 162.734Z"
      fill="#DBDBDB"
    />
    <path
      d="M447.864 162.734V155.141L441.288 151.344L434.711 155.141V162.734L441.288 166.531L447.864 162.734Z"
      fill="#DBDBDB"
    />
    <path
      d="M462.665 162.734V155.141L456.089 151.344L449.513 155.141V162.734L456.089 166.531L462.665 162.734Z"
      fill="#DBDBDB"
    />
    <path
      d="M791.182 100.237V92.6432L784.606 88.832L778.029 92.6432V100.237L784.606 104.034L791.182 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M805.982 100.237V92.6432L799.406 88.832L792.83 92.6432V100.237L799.406 104.034L805.982 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M820.782 100.237V92.6432L814.206 88.832L807.629 92.6432V100.237L814.206 104.034L820.782 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M835.581 100.237V92.6432L829.005 88.832L822.429 92.6432V100.237L829.005 104.034L835.581 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 100.237V92.6432L902.778 88.832L896.202 92.6432V100.237L902.778 104.034L909.354 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M924.155 100.237V92.6432L917.578 88.832L911.002 92.6432V100.237L917.578 104.034L924.155 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M938.956 100.237V92.6432L932.38 88.832L925.803 92.6432V100.237L932.38 104.034L938.956 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M953.77 100.237V92.6432L947.179 88.832L940.603 92.6432V100.237L947.179 104.034L953.77 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M968.5 100.237V92.6432L961.924 88.832L955.347 92.6432V100.237L961.924 104.034L968.5 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M997.928 100.237V92.6432L991.352 88.832L984.761 92.6432V100.237L991.352 104.034L997.928 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 100.237V92.6432L1006.15 88.832L999.575 92.6432V100.237L1006.15 104.034L1012.73 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.47 100.237V92.6432L1020.9 88.832L1014.3 92.6432V100.237L1020.9 104.034L1027.47 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M997.928 74.5779V66.9843L991.352 63.1875L984.761 66.9843V74.5779L991.352 78.3747L997.928 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 74.5779V66.9843L1006.15 63.1875L999.575 66.9843V74.5779L1006.15 78.3747L1012.73 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.47 74.5779V66.9843L1020.9 63.1875L1014.3 66.9843V74.5779L1020.9 78.3747L1027.47 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042 74.5779V66.9843L1035.41 63.1875L1028.83 66.9843V74.5779L1035.41 78.3747L1042 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M1071.54 100.237V92.6432L1064.97 88.832L1058.38 92.6432V100.237L1064.97 104.034L1071.54 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M1086.06 100.237V92.6432L1079.48 88.832L1072.9 92.6432V100.237L1079.48 104.034L1086.06 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M1100.87 100.237V92.6432L1094.28 88.832L1087.7 92.6432V100.237L1094.28 104.034L1100.87 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M1115.61 100.237V92.6432L1109.02 88.832L1102.45 92.6432V100.237L1109.02 104.034L1115.61 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M1130.13 100.237V92.6432L1123.55 88.832L1116.98 92.6432V100.237L1123.55 104.034L1130.13 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M1144.94 100.237V92.6432L1138.35 88.832L1131.78 92.6432V100.237L1138.35 104.034L1144.94 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M1159.67 100.237V92.6432L1153.09 88.832L1146.52 92.6432V100.237L1153.09 104.034L1159.67 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M1174.51 100.237V92.6432L1167.94 88.832L1161.36 92.6432V100.237L1167.94 104.034L1174.51 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M1174.51 125.28V117.672L1167.94 113.875L1161.36 117.672V125.28L1167.94 129.077L1174.51 125.28Z"
      fill="#DBDBDB"
    />
    <path
      d="M1189.36 125.28V117.672L1182.78 113.875L1176.21 117.672V125.28L1182.78 129.077L1189.36 125.28Z"
      fill="#DBDBDB"
    />
    <path
      d="M1189.36 150.308V142.715L1182.78 138.918L1176.21 142.715V150.308L1182.78 154.105L1189.36 150.308Z"
      fill="#DBDBDB"
    />
    <path
      d="M1174.51 150.308V142.715L1167.94 138.918L1161.36 142.715V150.308L1167.94 154.105L1174.51 150.308Z"
      fill="#DBDBDB"
    />
    <path
      d="M1174.51 175.355V167.762L1167.94 163.965L1161.36 167.762V175.355L1167.94 179.152L1174.51 175.355Z"
      fill="#DBDBDB"
    />
    <path
      d="M1086.06 125.28V117.672L1079.48 113.875L1072.9 117.672V125.28L1079.48 129.077L1086.06 125.28Z"
      fill="#DBDBDB"
    />
    <path
      d="M1100.87 125.28V117.672L1094.28 113.875L1087.7 117.672V125.28L1094.28 129.077L1100.87 125.28Z"
      fill="#DBDBDB"
    />
    <path
      d="M1115.61 125.28V117.672L1109.02 113.875L1102.45 117.672V125.28L1109.02 129.077L1115.61 125.28Z"
      fill="#DBDBDB"
    />
    <path
      d="M1130.13 125.28V117.672L1123.55 113.875L1116.98 117.672V125.28L1123.55 129.077L1130.13 125.28Z"
      fill="#DBDBDB"
    />
    <path
      d="M1144.94 125.28V117.672L1138.35 113.875L1131.78 117.672V125.28L1138.35 129.077L1144.94 125.28Z"
      fill="#DBDBDB"
    />
    <path
      d="M1159.67 125.28V117.672L1153.09 113.875L1146.52 117.672V125.28L1153.09 129.077L1159.67 125.28Z"
      fill="#DBDBDB"
    />
    <path
      d="M1159.67 150.308V142.715L1153.09 138.918L1146.52 142.715V150.308L1153.09 154.105L1159.67 150.308Z"
      fill="#DBDBDB"
    />
    <path
      d="M1159.67 175.355V167.762L1153.09 163.965L1146.52 167.762V175.355L1153.09 179.152L1159.67 175.355Z"
      fill="#DBDBDB"
    />
    <path
      d="M1159.67 200.5V192.906L1153.09 189.109L1146.52 192.906V200.5L1153.09 204.297L1159.67 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M1100.87 150.308V142.715L1094.28 138.918L1087.7 142.715V150.308L1094.28 154.105L1100.87 150.308Z"
      fill="#DBDBDB"
    />
    <path
      d="M1115.61 150.308V142.715L1109.02 138.918L1102.45 142.715V150.308L1109.02 154.105L1115.61 150.308Z"
      fill="#DBDBDB"
    />
    <path
      d="M1130.13 150.308V142.715L1123.55 138.918L1116.98 142.715V150.308L1123.55 154.105L1130.13 150.308Z"
      fill="#DBDBDB"
    />
    <path
      d="M1144.94 150.308V142.715L1138.35 138.918L1131.78 142.715V150.308L1138.35 154.105L1144.94 150.308Z"
      fill="#DBDBDB"
    />
    <path
      d="M783.774 112.901V105.307L777.198 101.496L770.622 105.307V112.901L777.198 116.698L783.774 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M798.573 112.901V105.307L791.997 101.496L785.42 105.307V112.901L791.997 116.698L798.573 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M813.39 112.901V105.307L806.8 101.496L800.223 105.307V112.901L806.8 116.698L813.39 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M828.189 112.901V105.307L821.613 101.496L815.022 105.307V112.901L821.613 116.698L828.189 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M842.861 112.901V105.307L836.284 101.496L829.708 105.307V112.901L836.284 116.698L842.861 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M857.675 112.901V105.307L851.085 101.496L844.508 105.307V112.901L851.085 116.698L857.675 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M872.476 112.901V105.307L865.899 101.496L859.309 105.307V112.901L865.899 116.698L872.476 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M887.276 112.901V105.307L880.699 101.496L874.123 105.307V112.901L880.699 116.698L887.276 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M901.947 112.901V105.307L895.37 101.496L888.794 105.307V112.901L895.37 116.698L901.947 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M783.774 87.4841V79.8905L777.198 76.0938L770.622 79.8905V87.4841L777.198 91.2809L783.774 87.4841Z"
      fill="#DBDBDB"
    />
    <path
      d="M798.573 87.4841V79.8905L791.997 76.0938L785.42 79.8905V87.4841L791.997 91.2809L798.573 87.4841Z"
      fill="#DBDBDB"
    />
    <path
      d="M813.39 87.4841V79.8905L806.8 76.0938L800.223 79.8905V87.4841L806.8 91.2809L813.39 87.4841Z"
      fill="#DBDBDB"
    />
    <path
      d="M828.189 87.4841V79.8905L821.613 76.0938L815.022 79.8905V87.4841L821.613 91.2809L828.189 87.4841Z"
      fill="#DBDBDB"
    />
    <path
      d="M842.861 87.4841V79.8905L836.284 76.0938L829.708 79.8905V87.4841L836.284 91.2809L842.861 87.4841Z"
      fill="#DBDBDB"
    />
    <path
      d="M857.675 87.4841V79.8905L851.085 76.0938L844.508 79.8905V87.4841L851.085 91.2809L857.675 87.4841Z"
      fill="#DBDBDB"
    />
    <path
      d="M872.476 87.4841V79.8905L865.899 76.0938L859.309 79.8905V87.4841L865.899 91.2809L872.476 87.4841Z"
      fill="#DBDBDB"
    />
    <path
      d="M887.276 87.4841V79.8905L880.699 76.0938L874.123 79.8905V87.4841L880.699 91.2809L887.276 87.4841Z"
      fill="#DBDBDB"
    />
    <path
      d="M901.947 87.4841V79.8905L895.37 76.0938L888.794 79.8905V87.4841L895.37 91.2809L901.947 87.4841Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 112.901V105.307L910.172 101.496L903.595 105.307V112.901L910.172 116.698L916.762 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 87.3657V79.7577L910.172 75.9609L903.595 79.7577V87.3657L910.172 91.1625L916.762 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M842.861 61.8201V54.2265L836.284 50.4297L829.708 54.2265V61.8201L836.284 65.6169L842.861 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M857.675 61.8201V54.2265L851.085 50.4297L844.508 54.2265V61.8201L851.085 65.6169L857.675 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M872.476 61.8201V54.2265L865.899 50.4297L859.309 54.2265V61.8201L865.899 65.6169L872.476 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M872.476 36.156V28.5624L865.899 24.7656L859.309 28.5624V36.156L865.899 39.9671L872.476 36.156Z"
      fill="#DBDBDB"
    />
    <path
      d="M887.276 61.8201V54.2265L880.699 50.4297L874.123 54.2265V61.8201L880.699 65.6169L887.276 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M901.947 61.8201V54.2265L895.37 50.4297L888.794 54.2265V61.8201L895.37 65.6169L901.947 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 61.7055V54.0976L910.172 50.3008L903.595 54.0976V61.7055L910.172 65.5023L916.762 61.7055Z"
      fill="#DBDBDB"
    />
    <path
      d="M931.563 112.901V105.307L924.987 101.496L918.396 105.307V112.901L924.987 116.698L931.563 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M931.563 87.4841V79.8905L924.987 76.0938L918.396 79.8905V87.4841L924.987 91.2809L931.563 87.4841Z"
      fill="#DBDBDB"
    />
    <path
      d="M946.363 112.901V105.307L939.786 101.496L933.21 105.307V112.901L939.786 116.698L946.363 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M961.049 112.901V105.307L954.459 101.496L947.882 105.307V112.901L954.459 116.698L961.049 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M975.849 112.901V105.307L969.258 101.496L962.682 105.307V112.901L969.258 116.698L975.849 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M946.363 87.3657V79.7577L939.786 75.9609L933.21 79.7577V87.3657L939.786 91.1625L946.363 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M961.049 87.3657V79.7577L954.459 75.9609L947.882 79.7577V87.3657L954.459 91.1625L961.049 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M975.849 87.3657V79.7577L969.258 75.9609L962.682 79.7577V87.3657L969.258 91.1625L975.849 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M990.649 112.901V105.307L984.073 101.496L977.482 105.307V112.901L984.073 116.698L990.649 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 112.901V105.307L998.873 101.496L992.296 105.307V112.901L998.873 116.698L1005.45 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 112.901V105.307L1013.54 101.496L1006.97 105.307V112.901L1013.54 116.698L1020.14 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 112.901V105.307L1028.36 101.496L1021.77 105.307V112.901L1028.36 116.698L1034.94 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M783.774 137.948V130.34L777.198 126.543L770.622 130.34V137.948L777.198 141.744L783.774 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M768.974 137.948V130.34L762.398 126.543L755.821 130.34V137.948L762.398 141.744L768.974 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M798.573 137.948V130.34L791.997 126.543L785.42 130.34V137.948L791.997 141.744L798.573 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M813.39 137.948V130.34L806.8 126.543L800.223 130.34V137.948L806.8 141.744L813.39 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M828.189 137.948V130.34L821.613 126.543L815.022 130.34V137.948L821.613 141.744L828.189 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M842.861 137.948V130.34L836.284 126.543L829.708 130.34V137.948L836.284 141.744L842.861 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M857.675 137.948V130.34L851.085 126.543L844.508 130.34V137.948L851.085 141.744L857.675 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M872.476 137.948V130.34L865.899 126.543L859.309 130.34V137.948L865.899 141.744L872.476 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M887.276 137.948V130.34L880.699 126.543L874.123 130.34V137.948L880.699 141.744L887.276 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M901.947 137.948V130.34L895.37 126.543L888.794 130.34V137.948L895.37 141.744L901.947 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 137.948V130.34L910.172 126.543L903.595 130.34V137.948L910.172 141.744L916.762 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M931.563 137.948V130.34L924.987 126.543L918.396 130.34V137.948L924.987 141.744L931.563 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M621.2 137.948V130.34L614.609 126.543L608.033 130.34V137.948L614.609 141.744L621.2 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M606.383 137.948V130.34L599.807 126.543L593.231 130.34V137.948L599.807 141.744L606.383 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M621.2 61.8201V54.2265L614.609 50.4297L608.033 54.2265V61.8201L614.609 65.6169L621.2 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M606.383 61.8201V54.2265L599.807 50.4297L593.231 54.2265V61.8201L599.807 65.6169L606.383 61.8201Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 137.948V130.34L629.423 126.543L622.833 130.34V137.948L629.423 141.744L636 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 137.948V130.34L644.225 126.543L637.649 130.34V137.948L644.225 141.744L650.801 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 137.948V130.34L659.025 126.543L652.448 130.34V137.948L659.025 141.744L665.601 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 112.901V105.307L644.225 101.496L637.649 105.307V112.901L644.225 116.698L650.801 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 112.901V105.307L659.025 101.496L652.448 105.307V112.901L659.025 116.698L665.601 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 137.948V130.34L673.709 126.543L667.118 130.34V137.948L673.709 141.744L680.285 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 137.948V130.34L688.51 126.543L681.934 130.34V137.948L688.51 141.744L695.086 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M694.873 162.959V155.609L688.511 151.941L682.15 155.609V162.959L688.511 166.627L694.873 162.959Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.887 137.948V130.34L703.31 126.543L696.734 130.34V137.948L703.31 141.744L709.887 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.687 137.948V130.34L718.111 126.543L711.534 130.34V137.948L718.111 141.744L724.687 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M739.374 137.948V130.34L732.797 126.543L726.207 130.34V137.948L732.797 141.744L739.374 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M754.173 137.948V130.34L747.597 126.543L741.021 130.34V137.948L747.597 141.744L754.173 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 162.972V155.379L629.423 151.582L622.833 155.379V162.972L629.423 166.769L636 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 162.972V155.379L644.225 151.582L637.649 155.379V162.972L644.225 166.769L650.801 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M561.591 162.972V155.379L555.015 151.582L548.438 155.379V162.972L555.015 166.769L561.591 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M547.619 162.972V155.379L541.042 151.582L534.466 155.379V162.972L541.042 166.769L547.619 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M540.561 175.691V168.098L533.985 164.301L527.409 168.098V175.691L533.985 179.488L540.561 175.691Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 162.972V155.379L659.025 151.582L652.448 155.379V162.972L659.025 166.769L665.601 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 162.972V155.379L673.709 151.582L667.118 155.379V162.972L673.709 166.769L680.285 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.887 162.972V155.379L703.31 151.582L696.734 155.379V162.972L703.31 166.769L709.887 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.687 162.972V155.379L718.111 151.582L711.534 155.379V162.972L718.111 166.769L724.687 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M739.374 162.972V155.379L732.797 151.582L726.207 155.379V162.972L732.797 166.769L739.374 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M754.173 162.972V155.379L747.597 151.582L741.021 155.379V162.972L747.597 166.769L754.173 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M946.363 137.948V130.34L939.786 126.543L933.21 130.34V137.948L939.786 141.744L946.363 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M961.049 137.948V130.34L954.459 126.543L947.882 130.34V137.948L954.459 141.744L961.049 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M975.849 137.948V130.34L969.258 126.543L962.682 130.34V137.948L969.258 141.744L975.849 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M990.649 137.948V130.34L984.073 126.543L977.482 130.34V137.948L984.073 141.744L990.649 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 137.948V130.34L998.873 126.543L992.296 130.34V137.948L998.873 141.744L1005.45 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 137.948V130.34L1013.54 126.543L1006.97 130.34V137.948L1013.54 141.744L1020.14 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 137.948V130.34L1028.36 126.543L1021.77 130.34V137.948L1028.36 141.744L1034.94 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M1049.65 137.948V130.34L1043.07 126.543L1036.5 130.34V137.948L1043.07 141.744L1049.65 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M1064.18 137.948V130.34L1057.6 126.543L1051.03 130.34V137.948L1057.6 141.744L1064.18 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M1078.89 137.948V130.34L1072.32 126.543L1065.74 130.34V137.948L1072.32 141.744L1078.89 137.948Z"
      fill="#DBDBDB"
    />
    <path
      d="M1064.18 162.972V155.379L1057.6 151.582L1051.03 155.379V162.972L1057.6 166.769L1064.18 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M1078.89 162.972V155.379L1072.32 151.582L1065.74 155.379V162.972L1072.32 166.769L1078.89 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M1064.18 188.019V180.426L1057.6 176.629L1051.03 180.426V188.019L1057.6 191.816L1064.18 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M1078.89 188.019V180.426L1072.32 176.629L1065.74 180.426V188.019L1072.32 191.816L1078.89 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M1049.65 162.972V155.379L1043.07 151.582L1036.5 155.379V162.972L1043.07 166.769L1049.65 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M783.774 162.972V155.379L777.198 151.582L770.622 155.379V162.972L777.198 166.769L783.774 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M768.974 162.972V155.379L762.398 151.582L755.821 155.379V162.972L762.398 166.769L768.974 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M798.573 162.972V155.379L791.997 151.582L785.42 155.379V162.972L791.997 166.769L798.573 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M813.39 162.972V155.379L806.8 151.582L800.223 155.379V162.972L806.8 166.769L813.39 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M621.2 187.976V180.383L614.609 176.586L608.033 180.383V187.976L614.609 191.773L621.2 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 187.976V180.383L629.423 176.586L622.833 180.383V187.976L629.423 191.773L636 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 187.976V180.383L644.225 176.586L637.649 180.383V187.976L644.225 191.773L650.801 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 187.976V180.383L659.025 176.586L652.448 180.383V187.976L659.025 191.773L665.601 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 187.976V180.383L673.709 176.586L667.118 180.383V187.976L673.709 191.773L680.285 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M547.298 187.976V180.383L540.722 176.586L534.146 180.383V187.976L540.722 191.773L547.298 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M525.659 200.394V192.801L519.083 189.004L512.507 192.801V200.394L519.083 204.191L525.659 200.394Z"
      fill="#DBDBDB"
    />
    <path
      d="M562.113 187.976V180.383L555.522 176.586L548.946 180.383V187.976L555.522 191.773L562.113 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M547.298 263.14V255.547L540.722 251.75L534.146 255.547V263.14L540.722 266.952L547.298 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M532.498 263.14V255.547L525.921 251.75L519.345 255.547V263.14L525.921 266.952L532.498 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M532.498 313.226V305.633L525.921 301.836L519.345 305.633V313.226L525.921 317.023L532.498 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M562.113 263.14V255.547L555.522 251.75L548.946 255.547V263.14L555.522 266.952L562.113 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M547.298 288.187V280.594L540.722 276.797L534.146 280.594V288.187L540.722 291.984L547.298 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M547.298 313.226V305.633L540.722 301.836L534.146 305.633V313.226L540.722 317.023L547.298 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M562.113 288.187V280.594L555.522 276.797L548.946 280.594V288.187L555.522 291.984L562.113 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M576.913 288.187V280.594L570.337 276.797L563.746 280.594V288.187L570.337 291.984L576.913 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M591.712 288.187V280.594L585.136 276.797L578.56 280.594V288.187L585.136 291.984L591.712 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 187.976V180.383L688.51 176.586L681.934 180.383V187.976L688.51 191.773L695.086 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.887 187.976V180.383L703.31 176.586L696.734 180.383V187.976L703.31 191.773L709.887 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.687 187.976V180.383L718.111 176.586L711.534 180.383V187.976L718.111 191.773L724.687 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M739.374 187.976V180.383L732.797 176.586L726.207 180.383V187.976L732.797 191.773L739.374 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M754.173 187.976V180.383L747.597 176.586L741.021 180.383V187.976L747.597 191.773L754.173 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M783.774 187.976V180.383L777.198 176.586L770.622 180.383V187.976L777.198 191.773L783.774 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M768.974 187.976V180.383L762.398 176.586L755.821 180.383V187.976L762.398 191.773L768.974 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M798.573 187.976V180.383L791.997 176.586L785.42 180.383V187.976L791.997 191.773L798.573 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M813.39 187.976V180.383L806.8 176.586L800.223 180.383V187.976L806.8 191.773L813.39 187.976Z"
      fill="#DBDBDB"
    />
    <path
      d="M621.2 213.066V205.473L614.609 201.676L608.033 205.473V213.066L614.609 216.863L621.2 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M606.383 213.066V205.473L599.807 201.676L593.231 205.473V213.066L599.807 216.863L606.383 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 213.066V205.473L629.423 201.676L622.833 205.473V213.066L629.423 216.863L636 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 213.066V205.473L644.225 201.676L637.649 205.473V213.066L644.225 216.863L650.801 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 213.066V205.473L659.025 201.676L652.448 205.473V213.066L659.025 216.863L665.601 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 213.066V205.473L673.709 201.676L667.118 205.473V213.066L673.709 216.863L680.285 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M576.913 213.066V205.473L570.337 201.676L563.746 205.473V213.066L570.337 216.863L576.913 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M591.712 213.066V205.473L585.136 201.676L578.56 205.473V213.066L585.136 216.863L591.712 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 213.066V205.473L688.51 201.676L681.934 205.473V213.066L688.51 216.863L695.086 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M621.2 313.226V305.633L614.609 301.836L608.033 305.633V313.226L614.609 317.023L621.2 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M606.383 313.226V305.633L599.807 301.836L593.231 305.633V313.226L599.807 317.023L606.383 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 313.226V305.633L629.423 301.836L622.833 305.633V313.226L629.423 317.023L636 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 313.226V305.633L644.225 301.836L637.649 305.633V313.226L644.225 317.023L650.801 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 313.226V305.633L659.025 301.836L652.448 305.633V313.226L659.025 317.023L665.601 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 313.226V305.633L673.709 301.836L667.118 305.633V313.226L673.709 317.023L680.285 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M562.113 313.226V305.633L555.522 301.836L548.946 305.633V313.226L555.522 317.023L562.113 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M576.913 313.226V305.633L570.337 301.836L563.746 305.633V313.226L570.337 317.023L576.913 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M591.712 313.226V305.633L585.136 301.836L578.56 305.633V313.226L585.136 317.023L591.712 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 313.226V305.633L688.51 301.836L681.934 305.633V313.226L688.51 317.023L695.086 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.887 213.066V205.473L703.31 201.676L696.734 205.473V213.066L703.31 216.863L709.887 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.687 213.066V205.473L718.111 201.676L711.534 205.473V213.066L718.111 216.863L724.687 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M739.374 213.066V205.473L732.797 201.676L726.207 205.473V213.066L732.797 216.863L739.374 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M754.173 213.066V205.473L747.597 201.676L741.021 205.473V213.066L747.597 216.863L754.173 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 263.14V255.547L673.709 251.75L667.118 255.547V263.14L673.709 266.952L680.285 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 263.14V255.547L688.51 251.75L681.934 255.547V263.14L688.51 266.952L695.086 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.887 263.14V255.547L703.31 251.75L696.734 255.547V263.14L703.31 266.952L709.887 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.687 263.14V255.547L718.111 251.75L711.534 255.547V263.14L718.111 266.952L724.687 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M739.374 263.14V255.547L732.797 251.75L726.207 255.547V263.14L732.797 266.952L739.374 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M754.173 263.14V255.547L747.597 251.75L741.021 255.547V263.14L747.597 266.952L754.173 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M783.774 213.066V205.473L777.198 201.676L770.622 205.473V213.066L777.198 216.863L783.774 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M768.974 213.066V205.473L762.398 201.676L755.821 205.473V213.066L762.398 216.863L768.974 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M798.573 213.066V205.473L791.997 201.676L785.42 205.473V213.066L791.997 216.863L798.573 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M621.2 238.112V230.504L614.609 226.707L608.033 230.504V238.112L614.609 241.909L621.2 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M606.383 238.112V230.504L599.807 226.707L593.231 230.504V238.112L599.807 241.909L606.383 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 238.112V230.504L629.423 226.707L622.833 230.504V238.112L629.423 241.909L636 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 238.112V230.504L644.225 226.707L637.649 230.504V238.112L644.225 241.909L650.801 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 263.14V255.547L644.225 251.75L637.649 255.547V263.14L644.225 266.952L650.801 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 238.112V230.504L659.025 226.707L652.448 230.504V238.112L659.025 241.909L665.601 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M562.113 238.112V230.504L555.522 226.707L548.946 230.504V238.112L555.522 241.909L562.113 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M576.913 238.112V230.504L570.337 226.707L563.746 230.504V238.112L570.337 241.909L576.913 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M591.712 238.112V230.504L585.136 226.707L578.56 230.504V238.112L585.136 241.909L591.712 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.687 238.112V230.504L718.111 226.707L711.534 230.504V238.112L718.111 241.909L724.687 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M739.374 238.112V230.504L732.797 226.707L726.207 230.504V238.112L732.797 241.909L739.374 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M783.774 238.112V230.504L777.198 226.707L770.622 230.504V238.112L777.198 241.909L783.774 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M798.573 238.112V230.504L791.997 226.707L785.42 230.504V238.112L791.997 241.909L798.573 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M783.774 263.14V255.547L777.198 251.75L770.622 255.547V263.14L777.198 266.952L783.774 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M783.774 288.187V280.594L777.198 276.797L770.622 280.594V288.187L777.198 291.984L783.774 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M798.573 263.14V255.547L791.997 251.75L785.42 255.547V263.14L791.997 266.952L798.573 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M813.39 213.066V205.473L806.8 201.676L800.223 205.473V213.066L806.8 216.863L813.39 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M813.39 238.112V230.504L806.8 226.707L800.223 230.504V238.112L806.8 241.909L813.39 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M828.189 162.972V155.379L821.613 151.582L815.022 155.379V162.972L821.613 166.769L828.189 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M842.861 162.972V155.379L836.284 151.582L829.708 155.379V162.972L836.284 166.769L842.861 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M857.675 162.972V155.379L851.085 151.582L844.508 155.379V162.972L851.085 166.769L857.675 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M872.476 162.972V155.379L865.899 151.582L859.309 155.379V162.972L865.899 166.769L872.476 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M887.276 162.972V155.379L880.699 151.582L874.123 155.379V162.972L880.699 166.769L887.276 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M901.947 162.972V155.379L895.37 151.582L888.794 155.379V162.972L895.37 166.769L901.947 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 162.972V155.379L910.172 151.582L903.595 155.379V162.972L910.172 166.769L916.762 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M931.563 162.972V155.379L924.987 151.582L918.396 155.379V162.972L924.987 166.769L931.563 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M946.363 162.972V155.379L939.786 151.582L933.21 155.379V162.972L939.786 166.769L946.363 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M961.049 162.972V155.379L954.459 151.582L947.882 155.379V162.972L954.459 166.769L961.049 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M828.189 188.019V180.426L821.613 176.629L815.022 180.426V188.019L821.613 191.816L828.189 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M842.861 188.019V180.426L836.284 176.629L829.708 180.426V188.019L836.284 191.816L842.861 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M857.675 188.019V180.426L851.085 176.629L844.508 180.426V188.019L851.085 191.816L857.675 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M872.476 188.019V180.426L865.899 176.629L859.309 180.426V188.019L865.899 191.816L872.476 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M887.276 188.019V180.426L880.699 176.629L874.123 180.426V188.019L880.699 191.816L887.276 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M901.947 188.019V180.426L895.37 176.629L888.794 180.426V188.019L895.37 191.816L901.947 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 188.019V180.426L910.172 176.629L903.595 180.426V188.019L910.172 191.816L916.762 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M931.563 188.019V180.426L924.987 176.629L918.396 180.426V188.019L924.987 191.816L931.563 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M946.363 188.019V180.426L939.786 176.629L933.21 180.426V188.019L939.786 191.816L946.363 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M961.049 188.019V180.426L954.459 176.629L947.882 180.426V188.019L954.459 191.816L961.049 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M828.189 213.066V205.473L821.613 201.676L815.022 205.473V213.066L821.613 216.863L828.189 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M842.861 213.066V205.473L836.284 201.676L829.708 205.473V213.066L836.284 216.863L842.861 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M857.675 213.066V205.473L851.085 201.676L844.508 205.473V213.066L851.085 216.863L857.675 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M872.476 213.066V205.473L865.899 201.676L859.309 205.473V213.066L865.899 216.863L872.476 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M887.276 213.066V205.473L880.699 201.676L874.123 205.473V213.066L880.699 216.863L887.276 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M901.947 213.066V205.473L895.37 201.676L888.794 205.473V213.066L895.37 216.863L901.947 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 213.066V205.473L910.172 201.676L903.595 205.473V213.066L910.172 216.863L916.762 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M931.563 213.066V205.473L924.987 201.676L918.396 205.473V213.066L924.987 216.863L931.563 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M946.363 213.066V205.473L939.786 201.676L933.21 205.473V213.066L939.786 216.863L946.363 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M961.049 213.066V205.473L954.459 201.676L947.882 205.473V213.066L954.459 216.863L961.049 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M975.849 162.972V155.379L969.258 151.582L962.682 155.379V162.972L969.258 166.769L975.849 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M990.649 162.972V155.379L984.073 151.582L977.482 155.379V162.972L984.073 166.769L990.649 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 162.972V155.379L998.873 151.582L992.296 155.379V162.972L998.873 166.769L1005.45 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M975.849 188.019V180.426L969.258 176.629L962.682 180.426V188.019L969.258 191.816L975.849 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M990.649 188.019V180.426L984.073 176.629L977.482 180.426V188.019L984.073 191.816L990.649 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 188.019V180.426L998.873 176.629L992.296 180.426V188.019L998.873 191.816L1005.45 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 162.972V155.379L1013.54 151.582L1006.97 155.379V162.972L1013.54 166.769L1020.14 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 162.972V155.379L1028.36 151.582L1021.77 155.379V162.972L1028.36 166.769L1034.94 162.972Z"
      fill="#DBDBDB"
    />
    <path
      d="M1049.65 213.066V205.473L1043.07 201.676L1036.5 205.473V213.066L1043.07 216.863L1049.65 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M1064.34 213.066V205.473L1057.76 201.676L1051.17 205.473V213.066L1057.76 216.863L1064.34 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M1049.65 238.112V230.504L1043.07 226.707L1036.5 230.504V238.112L1043.07 241.909L1049.65 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M1064.34 238.112V230.504L1057.76 226.707L1051.17 230.504V238.112L1057.76 241.909L1064.34 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M975.849 213.066V205.473L969.258 201.676L962.682 205.473V213.066L969.258 216.863L975.849 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M990.649 213.066V205.473L984.073 201.676L977.482 205.473V213.066L984.073 216.863L990.649 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 213.066V205.473L998.873 201.676L992.296 205.473V213.066L998.873 216.863L1005.45 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 213.066V205.473L1013.54 201.676L1006.97 205.473V213.066L1013.54 216.863L1020.14 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 213.066V205.473L1028.36 201.676L1021.77 205.473V213.066L1028.36 216.863L1034.94 213.066Z"
      fill="#DBDBDB"
    />
    <path
      d="M828.189 238.112V230.504L821.613 226.707L815.022 230.504V238.112L821.613 241.909L828.189 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M842.861 238.112V230.504L836.284 226.707L829.708 230.504V238.112L836.284 241.909L842.861 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M857.675 238.112V230.504L851.085 226.707L844.508 230.504V238.112L851.085 241.909L857.675 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M872.476 238.112V230.504L865.899 226.707L859.309 230.504V238.112L865.899 241.909L872.476 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M887.276 238.112V230.504L880.699 226.707L874.123 230.504V238.112L880.699 241.909L887.276 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M901.947 238.112V230.504L895.37 226.707L888.794 230.504V238.112L895.37 241.909L901.947 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 238.112V230.504L910.172 226.707L903.595 230.504V238.112L910.172 241.909L916.762 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M931.563 238.112V230.504L924.987 226.707L918.396 230.504V238.112L924.987 241.909L931.563 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M946.363 238.112V230.504L939.786 226.707L933.21 230.504V238.112L939.786 241.909L946.363 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M961.049 238.112V230.504L954.459 226.707L947.882 230.504V238.112L954.459 241.909L961.049 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M975.849 238.112V230.504L969.258 226.707L962.682 230.504V238.112L969.258 241.909L975.849 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M990.649 238.112V230.504L984.073 226.707L977.482 230.504V238.112L984.073 241.909L990.649 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 238.112V230.504L998.873 226.707L992.296 230.504V238.112L998.873 241.909L1005.45 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 238.112V230.504L1013.54 226.707L1006.97 230.504V238.112L1013.54 241.909L1020.14 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 238.112V230.504L1028.36 226.707L1021.77 230.504V238.112L1028.36 241.909L1034.94 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 263.14V255.547L1028.36 251.75L1021.77 255.547V263.14L1028.36 266.952L1034.94 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M813.39 263.14V255.547L806.8 251.75L800.223 255.547V263.14L806.8 266.952L813.39 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M828.189 263.14V255.547L821.613 251.75L815.022 255.547V263.14L821.613 266.952L828.189 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M842.861 263.14V255.547L836.284 251.75L829.708 255.547V263.14L836.284 266.952L842.861 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M857.675 263.14V255.547L851.085 251.75L844.508 255.547V263.14L851.085 266.952L857.675 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M872.476 263.14V255.547L865.899 251.75L859.309 255.547V263.14L865.899 266.952L872.476 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M887.276 263.14V255.547L880.699 251.75L874.123 255.547V263.14L880.699 266.952L887.276 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M901.947 263.14V255.547L895.37 251.75L888.794 255.547V263.14L895.37 266.952L901.947 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 263.14V255.547L910.172 251.75L903.595 255.547V263.14L910.172 266.952L916.762 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M931.563 263.14V255.547L924.987 251.75L918.396 255.547V263.14L924.987 266.952L931.563 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M946.363 263.14V255.547L939.786 251.75L933.21 255.547V263.14L939.786 266.952L946.363 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M961.049 263.14V255.547L954.459 251.75L947.882 255.547V263.14L954.459 266.952L961.049 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M975.849 263.14V255.547L969.258 251.75L962.682 255.547V263.14L969.258 266.952L975.849 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M813.39 288.187V280.594L806.8 276.797L800.223 280.594V288.187L806.8 291.984L813.39 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M798.573 288.187V280.594L791.997 276.797L785.42 280.594V288.187L791.997 291.984L798.573 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M783.774 313.226V305.633L777.198 301.836L770.622 305.633V313.226L777.198 317.023L783.774 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M783.774 363.319V355.711L777.198 351.914L770.622 355.711V363.319L777.198 367.116L783.774 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M798.573 313.226V305.633L791.997 301.836L785.42 305.633V313.226L791.997 317.023L798.573 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M828.189 288.187V280.594L821.613 276.797L815.022 280.594V288.187L821.613 291.984L828.189 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M842.861 288.187V280.594L836.284 276.797L829.708 280.594V288.187L836.284 291.984L842.861 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M857.675 288.187V280.594L851.085 276.797L844.508 280.594V288.187L851.085 291.984L857.675 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.829 288.187V280.594L703.253 276.797L696.662 280.594V288.187L703.253 291.984L709.829 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M739.43 288.187V280.594L732.854 276.797L726.278 280.594V288.187L732.854 291.984L739.43 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.629 288.187V280.594L718.052 276.797L711.476 280.594V288.187L718.052 291.984L724.629 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.829 313.226V305.633L703.253 301.836L696.662 305.633V313.226L703.253 317.023L709.829 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M739.43 313.226V305.633L732.854 301.836L726.278 305.633V313.226L732.854 317.023L739.43 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.629 313.226V305.633L718.052 301.836L711.476 305.633V313.226L718.052 317.023L724.629 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M754.23 288.187V280.594L747.654 276.797L741.077 280.594V288.187L747.654 291.984L754.23 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M754.23 313.226V305.633L747.654 301.836L741.077 305.633V313.226L747.654 317.023L754.23 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M532.498 338.273V330.68L525.921 326.883L519.345 330.68V338.273L525.921 342.07L532.498 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M547.298 338.273V330.68L540.722 326.883L534.146 330.68V338.273L540.722 342.07L547.298 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M621.2 338.273V330.68L614.609 326.883L608.033 330.68V338.273L614.609 342.07L621.2 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M606.383 338.273V330.68L599.807 326.883L593.231 330.68V338.273L599.807 342.07L606.383 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 338.273V330.68L629.423 326.883L622.833 330.68V338.273L629.423 342.07L636 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 338.273V330.68L644.225 326.883L637.649 330.68V338.273L644.225 342.07L650.801 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 338.273V330.68L659.025 326.883L652.448 330.68V338.273L659.025 342.07L665.601 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 338.273V330.68L673.709 326.883L667.118 330.68V338.273L673.709 342.07L680.285 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M562.113 338.273V330.68L555.522 326.883L548.946 330.68V338.273L555.522 342.07L562.113 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M576.913 338.273V330.68L570.337 326.883L563.746 330.68V338.273L570.337 342.07L576.913 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M502.768 338.273V330.68L496.192 326.883L489.616 330.68V338.273L496.192 342.07L502.768 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M517.569 338.273V330.68L510.992 326.883L504.416 330.68V338.273L510.992 342.07L517.569 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M517.569 313.226V305.633L510.992 301.836L504.416 305.633V313.226L510.992 317.023L517.569 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M591.712 338.273V330.68L585.136 326.883L578.56 330.68V338.273L585.136 342.07L591.712 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 338.273V330.68L688.51 326.883L681.934 330.68V338.273L688.51 342.07L695.086 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.829 338.273V330.68L703.253 326.883L696.662 330.68V338.273L703.253 342.07L709.829 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M739.43 338.273V330.68L732.854 326.883L726.278 330.68V338.273L732.854 342.07L739.43 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.629 338.273V330.68L718.052 326.883L711.476 330.68V338.273L718.052 342.07L724.629 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M754.23 338.273V330.68L747.654 326.883L741.077 330.68V338.273L747.654 342.07L754.23 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M768.916 338.273V330.68L762.34 326.883L755.749 330.68V338.273L762.34 342.07L768.916 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M532.498 363.319V355.711L525.921 351.914L519.345 355.711V363.319L525.921 367.116L532.498 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M547.298 363.319V355.711L540.722 351.914L534.146 355.711V363.319L540.722 367.116L547.298 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M621.2 363.319V355.711L614.609 351.914L608.033 355.711V363.319L614.609 367.116L621.2 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M606.383 363.319V355.711L599.807 351.914L593.231 355.711V363.319L599.807 367.116L606.383 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 363.319V355.711L629.423 351.914L622.833 355.711V363.319L629.423 367.116L636 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 363.319V355.711L644.225 351.914L637.649 355.711V363.319L644.225 367.116L650.801 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 363.319V355.711L659.025 351.914L652.448 355.711V363.319L659.025 367.116L665.601 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 363.319V355.711L673.709 351.914L667.118 355.711V363.319L673.709 367.116L680.285 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M562.113 363.319V355.711L555.522 351.914L548.946 355.711V363.319L555.522 367.116L562.113 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M576.913 363.319V355.711L570.337 351.914L563.746 355.711V363.319L570.337 367.116L576.913 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M502.768 363.319V355.711L496.192 351.914L489.616 355.711V363.319L496.192 367.116L502.768 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M517.569 363.319V355.711L510.992 351.914L504.416 355.711V363.319L510.992 367.116L517.569 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M591.712 363.319V355.711L585.136 351.914L578.56 355.711V363.319L585.136 367.116L591.712 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 363.319V355.711L688.51 351.914L681.934 355.711V363.319L688.51 367.116L695.086 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.829 363.319V355.711L703.253 351.914L696.662 355.711V363.319L703.253 367.116L709.829 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M739.43 363.319V355.711L732.854 351.914L726.278 355.711V363.319L732.854 367.116L739.43 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.629 363.319V355.711L718.052 351.914L711.476 355.711V363.319L718.052 367.116L724.629 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M754.23 363.319V355.711L747.654 351.914L741.077 355.711V363.319L747.654 367.116L754.23 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M768.916 363.319V355.711L762.34 351.914L755.749 355.711V363.319L762.34 367.116L768.916 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M783.774 388.347V380.754L777.198 376.957L770.622 380.754V388.347L777.198 392.159L783.774 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M532.498 388.347V380.754L525.921 376.957L519.345 380.754V388.347L525.921 392.159L532.498 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M547.298 388.347V380.754L540.722 376.957L534.146 380.754V388.347L540.722 392.159L547.298 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M621.2 388.347V380.754L614.609 376.957L608.033 380.754V388.347L614.609 392.159L621.2 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M606.383 388.347V380.754L599.807 376.957L593.231 380.754V388.347L599.807 392.159L606.383 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 388.347V380.754L629.423 376.957L622.833 380.754V388.347L629.423 392.159L636 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 388.347V380.754L644.225 376.957L637.649 380.754V388.347L644.225 392.159L650.801 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 388.347V380.754L659.025 376.957L652.448 380.754V388.347L659.025 392.159L665.601 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 388.347V380.754L673.709 376.957L667.118 380.754V388.347L673.709 392.159L680.285 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M562.113 388.347V380.754L555.522 376.957L548.946 380.754V388.347L555.522 392.159L562.113 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M576.913 388.347V380.754L570.337 376.957L563.746 380.754V388.347L570.337 392.159L576.913 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M502.768 388.347V380.754L496.192 376.957L489.616 380.754V388.347L496.192 392.159L502.768 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M517.569 388.347V380.754L510.992 376.957L504.416 380.754V388.347L510.992 392.159L517.569 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M591.712 388.347V380.754L585.136 376.957L578.56 380.754V388.347L585.136 392.159L591.712 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 388.347V380.754L688.51 376.957L681.934 380.754V388.347L688.51 392.159L695.086 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.829 388.347V380.754L703.253 376.957L696.662 380.754V388.347L703.253 392.159L709.829 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M739.43 388.347V380.754L732.854 376.957L726.278 380.754V388.347L732.854 392.159L739.43 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.629 388.347V380.754L718.052 376.957L711.476 380.754V388.347L718.052 392.159L724.629 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M754.23 388.347V380.754L747.654 376.957L741.077 380.754V388.347L747.654 392.159L754.23 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M768.916 388.347V380.754L762.34 376.957L755.749 380.754V388.347L762.34 392.159L768.916 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M783.774 413.893V406.285L777.198 402.488L770.622 406.285V413.893L777.198 417.69L783.774 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M532.498 413.893V406.285L525.921 402.488L519.345 406.285V413.893L525.921 417.69L532.498 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M547.298 413.893V406.285L540.722 402.488L534.146 406.285V413.893L540.722 417.69L547.298 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M621.2 413.893V406.285L614.609 402.488L608.033 406.285V413.893L614.609 417.69L621.2 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M606.383 413.893V406.285L599.807 402.488L593.231 406.285V413.893L599.807 417.69L606.383 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 413.893V406.285L629.423 402.488L622.833 406.285V413.893L629.423 417.69L636 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 413.893V406.285L644.225 402.488L637.649 406.285V413.893L644.225 417.69L650.801 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 413.893V406.285L659.025 402.488L652.448 406.285V413.893L659.025 417.69L665.601 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 413.893V406.285L673.709 402.488L667.118 406.285V413.893L673.709 417.69L680.285 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M562.113 413.893V406.285L555.522 402.488L548.946 406.285V413.893L555.522 417.69L562.113 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M576.913 413.893V406.285L570.337 402.488L563.746 406.285V413.893L570.337 417.69L576.913 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M502.768 413.893V406.285L496.192 402.488L489.616 406.285V413.893L496.192 417.69L502.768 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M517.569 413.893V406.285L510.992 402.488L504.416 406.285V413.893L510.992 417.69L517.569 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M591.712 413.893V406.285L585.136 402.488L578.56 406.285V413.893L585.136 417.69L591.712 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 413.893V406.285L688.51 402.488L681.934 406.285V413.893L688.51 417.69L695.086 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.829 413.893V406.285L703.253 402.488L696.662 406.285V413.893L703.253 417.69L709.829 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M739.43 413.893V406.285L732.854 402.488L726.278 406.285V413.893L732.854 417.69L739.43 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.629 413.893V406.285L718.052 402.488L711.476 406.285V413.893L718.052 417.69L724.629 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M754.23 413.893V406.285L747.654 402.488L741.077 406.285V413.893L747.654 417.69L754.23 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M768.916 413.893V406.285L762.34 402.488L755.749 406.285V413.893L762.34 417.69L768.916 413.893Z"
      fill="#DBDBDB"
    />
    <path
      d="M532.498 439.914V432.32L525.921 428.523L519.345 432.32V439.914L525.921 443.725L532.498 439.914Z"
      fill="#DBDBDB"
    />
    <path
      d="M547.298 439.914V432.32L540.722 428.523L534.146 432.32V439.914L540.722 443.725L547.298 439.914Z"
      fill="#DBDBDB"
    />
    <path
      d="M621.2 439.914V432.32L614.609 428.523L608.033 432.32V439.914L614.609 443.725L621.2 439.914Z"
      fill="#DBDBDB"
    />
    <path
      d="M606.383 439.914V432.32L599.807 428.523L593.231 432.32V439.914L599.807 443.725L606.383 439.914Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 439.914V432.32L629.423 428.523L622.833 432.32V439.914L629.423 443.725L636 439.914Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 439.914V432.32L644.225 428.523L637.649 432.32V439.914L644.225 443.725L650.801 439.914Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 439.914V432.32L659.025 428.523L652.448 432.32V439.914L659.025 443.725L665.601 439.914Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 439.914V432.32L673.709 428.523L667.118 432.32V439.914L673.709 443.725L680.285 439.914Z"
      fill="#DBDBDB"
    />
    <path
      d="M517.569 439.914V432.32L510.992 428.523L504.416 432.32V439.914L510.992 443.725L517.569 439.914Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 439.914V432.32L688.51 428.523L681.934 432.32V439.914L688.51 443.725L695.086 439.914Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.829 439.914V432.32L703.253 428.523L696.662 432.32V439.914L703.253 443.725L709.829 439.914Z"
      fill="#DBDBDB"
    />
    <path
      d="M739.43 439.914V432.32L732.854 428.523L726.278 432.32V439.914L732.854 443.725L739.43 439.914Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.629 439.914V432.32L718.052 428.523L711.476 432.32V439.914L718.052 443.725L724.629 439.914Z"
      fill="#DBDBDB"
    />
    <path
      d="M754.23 439.914V432.32L747.654 428.523L741.077 432.32V439.914L747.654 443.725L754.23 439.914Z"
      fill="#DBDBDB"
    />
    <path
      d="M621.2 465.945V458.351L614.609 454.555L608.033 458.351V465.945L614.609 469.742L621.2 465.945Z"
      fill="#DBDBDB"
    />
    <path
      d="M606.383 465.945V458.351L599.807 454.555L593.231 458.351V465.945L599.807 469.742L606.383 465.945Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 465.945V458.351L629.423 454.555L622.833 458.351V465.945L629.423 469.742L636 465.945Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 465.945V458.351L644.225 454.555L637.649 458.351V465.945L644.225 469.742L650.801 465.945Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 465.945V458.351L659.025 454.555L652.448 458.351V465.945L659.025 469.742L665.601 465.945Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 465.945V458.351L673.709 454.555L667.118 458.351V465.945L673.709 469.742L680.285 465.945Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 465.945V458.351L688.51 454.555L681.934 458.351V465.945L688.51 469.742L695.086 465.945Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.829 465.945V458.351L703.253 454.555L696.662 458.351V465.945L703.253 469.742L709.829 465.945Z"
      fill="#DBDBDB"
    />
    <path
      id="public-map-kenya-trees"
      d="M739.43 465.945V458.351L732.854 454.555L726.278 458.351V465.945L732.854 469.742L739.43 465.945Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.629 465.945V458.351L718.052 454.555L711.476 458.351V465.945L718.052 469.742L724.629 465.945Z"
      fill="#DBDBDB"
    />
    <path
      d="M621.2 491.979V484.385L614.609 480.574L608.033 484.385V491.979L614.609 495.776L621.2 491.979Z"
      fill="#DBDBDB"
    />
    <path
      d="M606.383 491.979V484.385L599.807 480.574L593.231 484.385V491.979L599.807 495.776L606.383 491.979Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 491.979V484.385L629.423 480.574L622.833 484.385V491.979L629.423 495.776L636 491.979Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 491.979V484.385L644.225 480.574L637.649 484.385V491.979L644.225 495.776L650.801 491.979Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 491.979V484.385L659.025 480.574L652.448 484.385V491.979L659.025 495.776L665.601 491.979Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 491.979V484.385L673.709 480.574L667.118 484.385V491.979L673.709 495.776L680.285 491.979Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 491.979V484.385L688.51 480.574L681.934 484.385V491.979L688.51 495.776L695.086 491.979Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.829 491.979V484.385L703.253 480.574L696.662 484.385V491.979L703.253 495.776L709.829 491.979Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.629 491.979V484.385L718.052 480.574L711.476 484.385V491.979L718.052 495.776L724.629 491.979Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 517.512V509.918L629.423 506.121L622.833 509.918V517.512L629.423 521.308L636 517.512Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 517.512V509.918L644.225 506.121L637.649 509.918V517.512L644.225 521.308L650.801 517.512Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 517.512V509.918L659.025 506.121L652.448 509.918V517.512L659.025 521.308L665.601 517.512Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 517.512V509.918L673.709 506.121L667.118 509.918V517.512L673.709 521.308L680.285 517.512Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 517.512V509.918L688.51 506.121L681.934 509.918V517.512L688.51 521.308L695.086 517.512Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.829 517.512V509.918L703.253 506.121L696.662 509.918V517.512L703.253 521.308L709.829 517.512Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.629 517.512V509.918L718.052 506.121L711.476 509.918V517.512L718.052 521.308L724.629 517.512Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 543.043V535.449L629.423 531.652L622.833 535.449V543.043L629.423 546.84L636 543.043Z"
      fill="#DBDBDB"
    />
    <path
      d="M621.2 543.043V535.449L614.609 531.652L608.033 535.449V543.043L614.609 546.84L621.2 543.043Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 543.043V535.449L644.225 531.652L637.649 535.449V543.043L644.225 546.84L650.801 543.043Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 543.043V535.449L659.025 531.652L652.448 535.449V543.043L659.025 546.84L665.601 543.043Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 543.043V535.449L673.709 531.652L667.118 535.449V543.043L673.709 546.84L680.285 543.043Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 543.043V535.449L688.51 531.652L681.934 535.449V543.043L688.51 546.84L695.086 543.043Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.829 543.043V535.449L703.253 531.652L696.662 535.449V543.043L703.253 546.84L709.829 543.043Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 568.584V560.976L629.423 557.18L622.833 560.976V568.584L629.423 572.381L636 568.584Z"
      fill="#DBDBDB"
    />
    <path
      d="M621.2 568.584V560.976L614.609 557.18L608.033 560.976V568.584L614.609 572.381L621.2 568.584Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 568.584V560.976L644.225 557.18L637.649 560.976V568.584L644.225 572.381L650.801 568.584Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 568.584V560.976L659.025 557.18L652.448 560.976V568.584L659.025 572.381L665.601 568.584Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 568.584V560.976L673.709 557.18L667.118 560.976V568.584L673.709 572.381L680.285 568.584Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 568.584V560.976L688.51 557.18L681.934 560.976V568.584L688.51 572.381L695.086 568.584Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.829 568.584V560.976L703.253 557.18L696.662 560.976V568.584L703.253 572.381L709.829 568.584Z"
      fill="#DBDBDB"
    />
    <path
      d="M754.145 568.584V560.976L747.569 557.18L740.978 560.976V568.584L747.569 572.381L754.145 568.584Z"
      fill="#DBDBDB"
    />
    <path
      d="M754.145 594.121V586.527L747.569 582.73L740.978 586.527V594.121L747.569 597.918L754.145 594.121Z"
      fill="#DBDBDB"
    />
    <path
      d="M768.945 568.584V560.976L762.369 557.18L755.792 560.976V568.584L762.369 572.381L768.945 568.584Z"
      fill="#DBDBDB"
    />
    <path
      d="M636 594.121V586.527L629.423 582.73L622.833 586.527V594.121L629.423 597.918L636 594.121Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 594.121V586.527L644.225 582.73L637.649 586.527V594.121L644.225 597.918L650.801 594.121Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 594.121V586.527L659.025 582.73L652.448 586.527V594.121L659.025 597.918L665.601 594.121Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 594.121V586.527L673.709 582.73L667.118 586.527V594.121L673.709 597.918L680.285 594.121Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 619.648V612.055L644.225 608.258L637.649 612.055V619.648L644.225 623.445L650.801 619.648Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 619.648V612.055L659.025 608.258L652.448 612.055V619.648L659.025 623.445L665.601 619.648Z"
      fill="#DBDBDB"
    />
    <path
      d="M650.801 645.683V638.09L644.225 634.293L637.649 638.09V645.683L644.225 649.48L650.801 645.683Z"
      fill="#DBDBDB"
    />
    <path
      d="M665.601 645.683V638.09L659.025 634.293L652.448 638.09V645.683L659.025 649.48L665.601 645.683Z"
      fill="#DBDBDB"
    />
    <path
      d="M680.285 619.648V612.055L673.709 608.258L667.118 612.055V619.648L673.709 623.445L680.285 619.648Z"
      fill="#DBDBDB"
    />
    <path
      d="M695.086 594.121V586.527L688.51 582.73L681.934 586.527V594.121L688.51 597.918L695.086 594.121Z"
      fill="#DBDBDB"
    />
    <path
      d="M709.829 594.121V586.527L703.253 582.73L696.662 586.527V594.121L703.253 597.918L709.829 594.121Z"
      fill="#DBDBDB"
    />
    <path
      d="M724.629 543.043V535.449L718.052 531.652L711.476 535.449V543.043L718.052 546.84L724.629 543.043Z"
      fill="#DBDBDB"
    />
    <path
      d="M768.945 543.043V535.449L762.369 531.652L755.792 535.449V543.043L762.369 546.84L768.945 543.043Z"
      fill="#DBDBDB"
    />
    <path
      d="M783.745 543.043V535.449L777.168 531.652L770.592 535.449V543.043L777.168 546.84L783.745 543.043Z"
      fill="#DBDBDB"
    />
    <path
      d="M768.916 439.914V432.32L762.34 428.523L755.749 432.32V439.914L762.34 443.725L768.916 439.914Z"
      fill="#DBDBDB"
    />
    <path
      d="M768.916 288.187V280.594L762.34 276.797L755.749 280.594V288.187L762.34 291.984L768.916 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M872.476 288.187V280.594L865.899 276.797L859.309 280.594V288.187L865.899 291.984L872.476 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M887.276 288.187V280.594L880.699 276.797L874.123 280.594V288.187L880.699 291.984L887.276 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M901.947 288.187V280.594L895.37 276.797L888.794 280.594V288.187L895.37 291.984L901.947 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 288.187V280.594L910.172 276.797L903.595 280.594V288.187L910.172 291.984L916.762 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M931.563 288.187V280.594L924.987 276.797L918.396 280.594V288.187L924.987 291.984L931.563 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M946.363 288.187V280.594L939.786 276.797L933.21 280.594V288.187L939.786 291.984L946.363 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M961.049 288.187V280.594L954.459 276.797L947.882 280.594V288.187L954.459 291.984L961.049 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M901.947 313.226V305.633L895.37 301.836L888.794 305.633V313.226L895.37 317.023L901.947 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 313.226V305.633L910.172 301.836L903.595 305.633V313.226L910.172 317.023L916.762 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M931.563 313.226V305.633L924.987 301.836L918.396 305.633V313.226L924.987 317.023L931.563 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M946.363 313.226V305.633L939.786 301.836L933.21 305.633V313.226L939.786 317.023L946.363 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M961.049 313.226V305.633L954.459 301.836L947.882 305.633V313.226L954.459 317.023L961.049 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M813.39 313.226V305.633L806.8 301.836L800.223 305.633V313.226L806.8 317.023L813.39 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M828.189 313.226V305.633L821.613 301.836L815.022 305.633V313.226L821.613 317.023L828.189 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M842.861 313.226V305.633L836.284 301.836L829.708 305.633V313.226L836.284 317.023L842.861 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M857.675 313.226V305.633L851.085 301.836L844.508 305.633V313.226L851.085 317.023L857.675 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M872.476 313.226V305.633L865.899 301.836L859.309 305.633V313.226L865.899 317.023L872.476 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M887.276 313.226V305.633L880.699 301.836L874.123 305.633V313.226L880.699 317.023L887.276 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M975.849 288.187V280.594L969.258 276.797L962.682 280.594V288.187L969.258 291.984L975.849 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M990.649 263.14V255.547L984.073 251.75L977.482 255.547V263.14L984.073 266.952L990.649 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 263.14V255.547L998.873 251.75L992.296 255.547V263.14L998.873 266.952L1005.45 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 263.14V255.547L1013.54 251.75L1006.97 255.547V263.14L1013.54 266.952L1020.14 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M1049.65 263.14V255.547L1043.07 251.75L1036.5 255.547V263.14L1043.07 266.952L1049.65 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M1210.59 260.797V253.203L1204.02 249.406L1197.44 253.203V260.797L1204.02 264.608L1210.59 260.797Z"
      fill="#DBDBDB"
    />
    <path
      d="M1203.25 248.234V240.641L1196.68 236.844L1190.1 240.641V248.234L1196.68 252.045L1203.25 248.234Z"
      fill="#DBDBDB"
    />
    <path
      d="M1079.01 238.112V230.504L1072.43 226.707L1065.85 230.504V238.112L1072.43 241.909L1079.01 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M1049.65 188.019V180.426L1043.07 176.629L1036.5 180.426V188.019L1043.07 191.816L1049.65 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M1152.25 188.019V180.426L1145.66 176.629L1139.08 180.426V188.019L1145.66 191.816L1152.25 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M1167.05 188.019V180.426L1160.47 176.629L1153.88 180.426V188.019L1160.47 191.816L1167.05 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 188.019V180.426L1013.54 176.629L1006.97 180.426V188.019L1013.54 191.816L1020.14 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 188.019V180.426L1028.36 176.629L1021.77 180.426V188.019L1028.36 191.816L1034.94 188.019Z"
      fill="#DBDBDB"
    />
    <path
      d="M776.381 125.385V117.792L769.805 113.98L763.214 117.792V125.385L769.805 129.182L776.381 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M791.182 125.385V117.792L784.606 113.98L778.029 117.792V125.385L784.606 129.182L791.182 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M805.982 125.385V117.792L799.406 113.98L792.83 117.792V125.385L799.406 129.182L805.982 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M820.782 125.385V117.792L814.206 113.98L807.629 117.792V125.385L814.206 129.182L820.782 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M835.466 125.385V117.792L828.89 113.98L822.299 117.792V125.385L828.89 129.182L835.466 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M850.267 125.385V117.792L843.691 113.98L837.115 117.792V125.385L843.691 129.182L850.267 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M865.068 125.385V117.792L858.491 113.98L851.915 117.792V125.385L858.491 129.182L865.068 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M879.868 125.385V117.792L873.292 113.98L866.715 117.792V125.385L873.292 129.182L879.868 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M894.555 125.385V117.792L887.979 113.98L881.388 117.792V125.385L887.979 129.182L894.555 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M850.267 100.237V92.6432L843.691 88.832L837.115 92.6432V100.237L843.691 104.034L850.267 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M865.068 100.237V92.6432L858.491 88.832L851.915 92.6432V100.237L858.491 104.034L865.068 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M879.868 100.237V92.6432L873.292 88.832L866.715 92.6432V100.237L873.292 104.034L879.868 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M894.555 100.237V92.6432L887.979 88.832L881.388 92.6432V100.237L887.979 104.034L894.555 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M820.782 74.5779V66.9843L814.206 63.1875L807.629 66.9843V74.5779L814.206 78.3747L820.782 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M835.581 74.5779V66.9843L829.005 63.1875L822.429 66.9843V74.5779L829.005 78.3747L835.581 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M850.267 74.5779V66.9843L843.691 63.1875L837.115 66.9843V74.5779L843.691 78.3747L850.267 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M865.068 74.5779V66.9843L858.491 63.1875L851.915 66.9843V74.5779L858.491 78.3747L865.068 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M879.868 74.5779V66.9843L873.292 63.1875L866.715 66.9843V74.5779L873.292 78.3747L879.868 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M894.555 74.5779V66.9843L887.979 63.1875L881.388 66.9843V74.5779L887.979 78.3747L894.555 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 125.385V117.792L902.778 113.98L896.202 117.792V125.385L902.778 129.182L909.354 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M924.155 125.385V117.792L917.578 113.98L911.002 117.792V125.385L917.578 129.182L924.155 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M938.956 125.385V117.792L932.38 113.98L925.803 117.792V125.385L932.38 129.182L938.956 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M613.792 125.385V117.792L607.215 113.98L600.639 117.792V125.385L607.215 129.182L613.792 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 125.385V117.792L622.015 113.98L615.439 117.792V125.385L622.015 129.182L628.591 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 125.385V117.792L636.816 113.98L630.239 117.792V125.385L636.816 129.182L643.392 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 125.385V117.792L651.616 113.98L645.04 117.792V125.385L651.616 129.182L658.207 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 125.385V117.792L666.303 113.98L659.727 117.792V125.385L666.303 129.182L672.879 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 125.385V117.792L681.103 113.98L674.526 117.792V125.385L681.103 129.182L687.679 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M694.153 85.4047V77.8111L687.576 74L681 77.8111V85.4047L687.576 89.2015L694.153 85.4047Z"
      fill="#DBDBDB"
    />
    <path
      d="M735.153 99.4047V91.8111L728.576 88L722 91.8111V99.4047L728.576 103.202L735.153 99.4047Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 125.385V117.792L695.903 113.98L689.326 117.792V125.385L695.903 129.182L702.479 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M708.952 85.4047V77.8111L702.376 74L695.8 77.8111V85.4047L702.376 89.2015L708.952 85.4047Z"
      fill="#DBDBDB"
    />
    <path
      d="M749.952 99.4047V91.8111L743.376 88L736.8 91.8111V99.4047L743.376 103.202L749.952 99.4047Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 125.385V117.792L710.703 113.98L704.127 117.792V125.385L710.703 129.182L717.294 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 125.385V117.792L725.39 113.98L718.813 117.792V125.385L725.39 129.182L731.966 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M746.766 125.385V117.792L740.189 113.98L733.613 117.792V125.385L740.189 129.182L746.766 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M613.792 150.42V142.812L607.215 139.016L600.639 142.812V150.42L607.215 154.217L613.792 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 150.42V142.812L622.015 139.016L615.439 142.812V150.42L622.015 154.217L628.591 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M598.992 150.42V142.812L592.415 139.016L585.839 142.812V150.42L592.415 154.217L598.992 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M606.153 163.405V155.797L599.576 152L593 155.797V163.405L599.576 167.202L606.153 163.405Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 150.42V142.812L636.816 139.016L630.239 142.812V150.42L636.816 154.217L643.392 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 150.42V142.812L651.616 139.016L645.04 142.812V150.42L651.616 154.217L658.207 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 150.42V142.812L666.303 139.016L659.727 142.812V150.42L666.303 154.217L672.879 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 150.42V142.812L681.102 139.016L674.526 142.812V150.42L681.102 154.217L687.679 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 150.42V142.812L695.903 139.016L689.326 142.812V150.42L695.903 154.217L702.479 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 150.42V142.812L710.703 139.016L704.127 142.812V150.42L710.703 154.217L717.294 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 150.42V142.812L725.39 139.016L718.813 142.812V150.42L725.39 154.217L731.966 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M746.766 150.42V142.812L740.189 139.016L733.613 142.812V150.42L740.189 154.217L746.766 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M761.581 125.385V117.792L754.99 113.98L748.414 117.792V125.385L754.99 129.182L761.581 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M953.641 125.385V117.792L947.065 113.98L940.489 117.792V125.385L947.065 129.182L953.641 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M968.441 125.385V117.792L961.864 113.98L955.288 117.792V125.385L961.864 129.182L968.441 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M983.243 125.385V117.792L976.667 113.98L970.09 117.792V125.385L976.667 129.182L983.243 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M983.243 100.237V92.6432L976.667 88.832L970.09 92.6432V100.237L976.667 104.034L983.243 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 74.5779V66.9843L902.778 63.1875L896.202 66.9843V74.5779L902.778 78.3747L909.354 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M865.068 48.9099V41.3163L858.491 37.5195L851.915 41.3163V48.9099L858.491 52.7067L865.068 48.9099Z"
      fill="#DBDBDB"
    />
    <path
      d="M879.868 48.9099V41.3163L873.292 37.5195L866.715 41.3163V48.9099L873.292 52.7067L879.868 48.9099Z"
      fill="#DBDBDB"
    />
    <path
      d="M894.555 48.9099V41.3163L887.979 37.5195L881.388 41.3163V48.9099L887.979 52.7067L894.555 48.9099Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 48.9099V41.3163L902.778 37.5195L896.202 41.3163V48.9099L902.778 52.7067L909.354 48.9099Z"
      fill="#DBDBDB"
    />
    <path
      d="M924.155 74.5779V66.9843L917.578 63.1875L911.002 66.9843V74.5779L917.578 78.3747L924.155 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M938.956 74.5779V66.9843L932.38 63.1875L925.803 66.9843V74.5779L932.38 78.3747L938.956 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M953.77 74.5779V66.9843L947.179 63.1875L940.603 66.9843V74.5779L947.179 78.3747L953.77 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M968.5 74.5779V66.9843L961.924 63.1875L955.347 66.9843V74.5779L961.924 78.3747L968.5 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M983.243 74.5779V66.9843L976.667 63.1875L970.09 66.9843V74.5779L976.667 78.3747L983.243 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M599.278 74.5779V66.9843L592.702 63.1875L586.125 66.9843V74.5779L592.702 78.3747L599.278 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M614.093 74.5779V66.9843L607.502 63.1875L600.926 66.9843V74.5779L607.502 78.3747L614.093 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.763 74.5779V66.9843L622.187 63.1875L615.611 66.9843V74.5779L622.187 78.3747L628.763 74.5779Z"
      fill="#DBDBDB"
    />
    <path
      d="M614.093 48.9099V41.3163L607.502 37.5195L600.926 41.3163V48.9099L607.502 52.7067L614.093 48.9099Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.763 48.9099V41.3163L622.187 37.5195L615.611 41.3163V48.9099L622.187 52.7067L628.763 48.9099Z"
      fill="#DBDBDB"
    />
    <path
      d="M998.042 125.385V117.792L991.466 113.98L984.89 117.792V125.385L991.466 129.182L998.042 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 125.385V117.792L1006.15 113.98L999.575 117.792V125.385L1006.15 129.182L1012.73 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.53 125.385V117.792L1020.95 113.98L1014.38 117.792V125.385L1020.95 129.182L1027.53 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042.26 125.385V117.792L1035.67 113.98L1029.09 117.792V125.385L1035.67 129.182L1042.26 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M1057.06 125.385V117.792L1050.48 113.98L1043.89 117.792V125.385L1050.48 129.182L1057.06 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042.26 100.237V92.6432L1035.67 88.832L1029.09 92.6432V100.237L1035.67 104.034L1042.26 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M1057.06 100.237V92.6432L1050.48 88.832L1043.89 92.6432V100.237L1050.48 104.034L1057.06 100.237Z"
      fill="#DBDBDB"
    />
    <path
      d="M1071.54 125.385V117.792L1064.97 113.98L1058.38 117.792V125.385L1064.97 129.182L1071.54 125.385Z"
      fill="#DBDBDB"
    />
    <path
      d="M1086.06 150.268V142.674L1079.48 138.863L1072.9 142.674V150.268L1079.48 154.065L1086.06 150.268Z"
      fill="#DBDBDB"
    />
    <path
      d="M1071.54 150.371V142.777L1064.97 138.98L1058.38 142.777V150.371L1064.97 154.168L1071.54 150.371Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042.26 150.42V142.812L1035.67 139.016L1029.09 142.812V150.42L1035.67 154.217L1042.26 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M1057.06 150.42V142.812L1050.48 139.016L1043.89 142.812V150.42L1050.48 154.217L1057.06 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M1100.87 200.472V192.879L1094.28 189.082L1087.7 192.879V200.472L1094.28 204.269L1100.87 200.472Z"
      fill="#DBDBDB"
    />
    <path
      d="M1086.06 200.428V192.82L1079.48 189.023L1072.9 192.82V200.428L1079.48 204.225L1086.06 200.428Z"
      fill="#DBDBDB"
    />
    <path
      d="M1071.54 200.527V192.934L1064.97 189.137L1058.38 192.934V200.527L1064.97 204.324L1071.54 200.527Z"
      fill="#DBDBDB"
    />
    <path
      d="M1057.06 200.584V192.976L1050.48 189.18L1043.89 192.976V200.584L1050.48 204.381L1057.06 200.584Z"
      fill="#DBDBDB"
    />
    <path
      d="M1071.54 175.436V167.828L1064.97 164.031L1058.38 167.828V175.436L1064.97 179.233L1071.54 175.436Z"
      fill="#DBDBDB"
    />
    <path
      d="M1057.06 175.484V167.891L1050.48 164.094L1043.89 167.891V175.484L1050.48 179.281L1057.06 175.484Z"
      fill="#DBDBDB"
    />
    <path
      d="M776.381 150.42V142.812L769.805 139.016L763.214 142.812V150.42L769.805 154.217L776.381 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M761.581 150.42V142.812L754.99 139.016L748.414 142.812V150.42L754.99 154.217L761.581 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M791.182 150.42V142.812L784.606 139.016L778.029 142.812V150.42L784.606 154.217L791.182 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M805.982 150.42V142.812L799.406 139.016L792.83 142.812V150.42L799.406 154.217L805.982 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 175.457V167.863L622.015 164.066L615.439 167.863V175.457L622.015 179.254L628.591 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 175.457V167.863L636.816 164.066L630.239 167.863V175.457L636.816 179.254L643.392 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 175.457V167.863L651.616 164.066L645.04 167.863V175.457L651.616 179.254L658.207 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 175.457V167.863L666.303 164.066L659.727 167.863V175.457L666.303 179.254L672.879 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 175.457V167.863L681.102 164.066L674.526 167.863V175.457L681.102 179.254L687.679 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 175.457V167.863L695.903 164.066L689.326 167.863V175.457L695.903 179.254L702.479 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 175.457V167.863L710.703 164.066L704.127 167.863V175.457L710.703 179.254L717.294 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 175.457V167.863L725.39 164.066L718.813 167.863V175.457L725.39 179.254L731.966 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M746.766 175.457V167.863L740.189 164.066L733.613 167.863V175.457L740.189 179.254L746.766 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M776.381 175.457V167.863L769.805 164.066L763.214 167.863V175.457L769.805 179.254L776.381 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M761.581 175.457V167.863L754.99 164.066L748.414 167.863V175.457L754.99 179.254L761.581 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M791.182 175.457V167.863L784.606 164.066L778.029 167.863V175.457L784.606 179.254L791.182 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M805.982 175.457V167.863L799.406 164.066L792.83 167.863V175.457L799.406 179.254L805.982 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M613.792 200.543V192.949L607.215 189.152L600.639 192.949V200.543L607.215 204.34L613.792 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M598.992 200.543V192.949L592.415 189.152L585.839 192.949V200.543L592.415 204.34L598.992 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 200.543V192.949L622.015 189.152L615.439 192.949V200.543L622.015 204.34L628.591 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 200.543V192.949L636.816 189.152L630.239 192.949V200.543L636.816 204.34L643.392 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 200.543V192.949L651.616 189.152L645.04 192.949V200.543L651.616 204.34L658.207 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M554.577 200.543V192.949L548.001 189.152L541.425 192.949V200.543L548.001 204.34L554.577 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M554.577 175.457V167.863L548.001 164.066L541.425 167.863V175.457L548.001 179.254L554.577 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M540.168 200.543V192.949L533.591 189.152L527.015 192.949V200.543L533.591 204.34L540.168 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M554.577 250.592V242.984L548.001 239.188L541.425 242.984V250.592L548.001 254.389L554.577 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M539.776 250.592V242.984L533.199 239.188L526.623 242.984V250.592L533.199 254.389L539.776 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M554.577 275.664V268.07L548.001 264.273L541.425 268.07V275.664L548.001 279.461L554.577 275.664Z"
      fill="#DBDBDB"
    />
    <path
      d="M539.776 275.664V268.07L533.199 264.273L526.623 268.07V275.664L533.199 279.461L539.776 275.664Z"
      fill="#DBDBDB"
    />
    <path
      d="M554.577 300.75V293.156L548.001 289.359L541.425 293.156V300.75L548.001 304.547L554.577 300.75Z"
      fill="#DBDBDB"
    />
    <path
      d="M539.776 300.75V293.156L533.199 289.359L526.623 293.156V300.75L533.199 304.547L539.776 300.75Z"
      fill="#DBDBDB"
    />
    <path
      d="M525.104 250.592V242.984L518.513 239.188L511.937 242.984V250.592L518.513 254.389L525.104 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M569.391 250.592V242.984L562.8 239.188L556.224 242.984V250.592L562.8 254.389L569.391 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M569.391 300.75V293.156L562.8 289.359L556.224 293.156V300.75L562.8 304.547L569.391 300.75Z"
      fill="#DBDBDB"
    />
    <path
      d="M584.192 200.543V192.949L577.616 189.152L571.025 192.949V200.543L577.616 204.34L584.192 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 200.543V192.949L666.303 189.152L659.727 192.949V200.543L666.303 204.34L672.879 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 200.543V192.949L681.102 189.152L674.526 192.949V200.543L681.102 204.34L687.679 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 200.543V192.949L695.903 189.152L689.326 192.949V200.543L695.903 204.34L702.479 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 200.543V192.949L710.703 189.152L704.127 192.949V200.543L710.703 204.34L717.294 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 200.543V192.949L725.39 189.152L718.813 192.949V200.543L725.39 204.34L731.966 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M746.766 200.543V192.949L740.189 189.152L733.613 192.949V200.543L740.189 204.34L746.766 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M776.381 200.543V192.949L769.805 189.152L763.214 192.949V200.543L769.805 204.34L776.381 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M761.581 200.543V192.949L754.99 189.152L748.414 192.949V200.543L754.99 204.34L761.581 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M791.182 200.543V192.949L784.606 189.152L778.029 192.949V200.543L784.606 204.34L791.182 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M805.982 200.543V192.949L799.406 189.152L792.83 192.949V200.543L799.406 204.34L805.982 200.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M613.792 225.547V217.953L607.215 214.156L600.639 217.953V225.547L607.215 229.343L613.792 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M598.992 225.547V217.953L592.415 214.156L585.839 217.953V225.547L592.415 229.343L598.992 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 225.547V217.953L622.015 214.156L615.439 217.953V225.547L622.015 229.343L628.591 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 225.547V217.953L636.816 214.156L630.239 217.953V225.547L636.816 229.343L643.392 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 225.547V217.953L651.616 214.156L645.04 217.953V225.547L651.616 229.343L658.207 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M613.792 250.592V242.984L607.215 239.188L600.639 242.984V250.592L607.215 254.389L613.792 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 250.592V242.984L622.015 239.188L615.439 242.984V250.592L622.015 254.389L628.591 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 250.592V242.984L636.816 239.188L630.239 242.984V250.592L636.816 254.389L643.392 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 250.592V242.984L651.616 239.188L645.04 242.984V250.592L651.616 254.389L658.207 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M569.391 225.543V217.949L562.8 214.152L556.224 217.949V225.543L562.8 229.34L569.391 225.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M555.476 225.488V217.894L548.885 214.098L542.309 217.894V225.488L548.885 229.285L555.476 225.488Z"
      fill="#DBDBDB"
    />
    <path
      d="M584.192 225.547V217.953L577.616 214.156L571.025 217.953V225.547L577.616 229.343L584.192 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M598.992 300.75V293.156L592.415 289.359L585.839 293.156V300.75L592.415 304.547L598.992 300.75Z"
      fill="#DBDBDB"
    />
    <path
      d="M584.192 300.75V293.156L577.616 289.359L571.025 293.156V300.75L577.616 304.547L584.192 300.75Z"
      fill="#DBDBDB"
    />
    <path
      d="M554.577 325.838V318.23L548.001 314.434L541.425 318.23V325.838L548.001 329.635L554.577 325.838Z"
      fill="#DBDBDB"
    />
    <path
      d="M539.776 325.838V318.23L533.199 314.434L526.623 318.23V325.838L533.199 329.635L539.776 325.838Z"
      fill="#DBDBDB"
    />
    <path
      d="M569.391 325.838V318.23L562.8 314.434L556.224 318.23V325.838L562.8 329.635L569.391 325.838Z"
      fill="#DBDBDB"
    />
    <path
      d="M598.992 325.838V318.23L592.415 314.434L585.839 318.23V325.838L592.415 329.635L598.992 325.838Z"
      fill="#DBDBDB"
    />
    <path
      d="M584.192 325.838V318.23L577.616 314.434L571.025 318.23V325.838L577.616 329.635L584.192 325.838Z"
      fill="#DBDBDB"
    />
    <path
      d="M510.174 325.838V318.23L503.598 314.434L497.021 318.23V325.838L503.598 329.635L510.174 325.838Z"
      fill="#DBDBDB"
    />
    <path
      d="M524.976 325.838V318.23L518.4 314.434L511.824 318.23V325.838L518.4 329.635L524.976 325.838Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 225.547V217.953L666.303 214.156L659.727 217.953V225.547L666.303 229.343L672.879 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 225.547V217.953L681.102 214.156L674.526 217.953V225.547L681.102 229.343L687.679 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 225.547V217.953L695.903 214.156L689.326 217.953V225.547L695.903 229.343L702.479 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M613.792 325.711V318.117L607.215 314.32L600.639 318.117V325.711L607.215 329.507L613.792 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 325.711V318.117L622.015 314.32L615.439 318.117V325.711L622.015 329.507L628.591 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 325.711V318.117L636.816 314.32L630.239 318.117V325.711L636.816 329.507L643.392 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 325.711V318.117L651.616 314.32L645.04 318.117V325.711L651.616 329.507L658.207 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 325.711V318.117L666.303 314.32L659.727 318.117V325.711L666.303 329.507L672.879 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 325.711V318.117L681.102 314.32L674.526 318.117V325.711L681.102 329.507L687.679 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 325.711V318.117L695.903 314.32L689.326 318.117V325.711L695.903 329.507L702.479 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 225.547V217.953L710.703 214.156L704.127 217.953V225.547L710.703 229.343L717.294 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 225.547V217.953L725.39 214.156L718.813 217.953V225.547L725.39 229.343L731.966 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M746.766 225.547V217.953L740.189 214.156L733.613 217.953V225.547L740.189 229.343L746.766 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 250.592V242.984L725.39 239.188L718.813 242.984V250.592L725.39 254.389L731.966 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M746.766 250.592V242.984L740.189 239.188L733.613 242.984V250.592L740.189 254.389L746.766 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M776.381 225.547V217.953L769.805 214.156L763.214 217.953V225.547L769.805 229.343L776.381 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M761.581 225.547V217.953L754.99 214.156L748.414 217.953V225.547L754.99 229.343L761.581 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 275.621V268.027L666.303 264.23L659.727 268.027V275.621L666.303 279.432L672.879 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 275.621V268.027L681.102 264.23L674.526 268.027V275.621L681.102 279.432L687.679 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 275.621V268.027L695.903 264.23L689.326 268.027V275.621L695.903 279.432L702.479 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 275.621V268.027L710.703 264.23L704.127 268.027V275.621L710.703 279.432L717.294 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 275.621V268.027L725.39 264.23L718.813 268.027V275.621L725.39 279.432L731.966 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M746.766 275.621V268.027L740.189 264.23L733.613 268.027V275.621L740.189 279.432L746.766 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M761.581 275.621V268.027L754.99 264.23L748.414 268.027V275.621L754.99 279.432L761.581 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 300.66V293.066L710.703 289.27L704.127 293.066V300.66L710.703 304.457L717.294 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 300.66V293.066L725.39 289.27L718.813 293.066V300.66L725.39 304.457L731.966 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M746.766 300.66V293.066L740.189 289.27L733.613 293.066V300.66L740.189 304.457L746.766 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M761.581 300.66V293.066L754.99 289.27L748.414 293.066V300.66L754.99 304.457L761.581 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M791.182 225.547V217.953L784.606 214.156L778.029 217.953V225.547L784.606 229.343L791.182 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M776.381 300.66V293.066L769.805 289.27L763.214 293.066V300.66L769.805 304.457L776.381 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M776.381 350.694V343.086L769.805 339.289L763.214 343.086V350.694L769.805 354.491L776.381 350.694Z"
      fill="#DBDBDB"
    />
    <path
      d="M791.182 300.66V293.066L784.606 289.27L778.029 293.066V300.66L784.606 304.457L791.182 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M791.182 250.592V242.984L784.606 239.188L778.029 242.984V250.592L784.606 254.389L791.182 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M791.182 275.621V268.027L784.606 264.23L778.029 268.027V275.621L784.606 279.432L791.182 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M805.982 225.547V217.953L799.406 214.156L792.83 217.953V225.547L799.406 229.343L805.982 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M820.782 150.42V142.812L814.206 139.016L807.629 142.812V150.42L814.206 154.217L820.782 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M835.466 150.42V142.812L828.89 139.016L822.299 142.812V150.42L828.89 154.217L835.466 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M850.267 150.42V142.812L843.691 139.016L837.115 142.812V150.42L843.691 154.217L850.267 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M865.068 150.42V142.812L858.491 139.016L851.915 142.812V150.42L858.491 154.217L865.068 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M879.868 150.42V142.812L873.292 139.016L866.715 142.812V150.42L873.292 154.217L879.868 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M894.555 150.42V142.812L887.979 139.016L881.388 142.812V150.42L887.979 154.217L894.555 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 150.42V142.812L902.778 139.016L896.202 142.812V150.42L902.778 154.217L909.354 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M924.155 150.42V142.812L917.578 139.016L911.002 142.812V150.42L917.578 154.217L924.155 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M938.956 150.42V142.812L932.38 139.016L925.803 142.812V150.42L932.38 154.217L938.956 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M953.641 150.42V142.812L947.065 139.016L940.489 142.812V150.42L947.065 154.217L953.641 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M820.782 175.457V167.863L814.206 164.066L807.629 167.863V175.457L814.206 179.254L820.782 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M835.466 175.457V167.863L828.89 164.066L822.299 167.863V175.457L828.89 179.254L835.466 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M850.267 175.457V167.863L843.691 164.066L837.115 167.863V175.457L843.691 179.254L850.267 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M865.068 175.457V167.863L858.491 164.066L851.915 167.863V175.457L858.491 179.254L865.068 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M879.868 175.457V167.863L873.292 164.066L866.715 167.863V175.457L873.292 179.254L879.868 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M894.555 175.457V167.863L887.979 164.066L881.388 167.863V175.457L887.979 179.254L894.555 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 175.457V167.863L902.778 164.066L896.202 167.863V175.457L902.778 179.254L909.354 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M924.155 175.457V167.863L917.578 164.066L911.002 167.863V175.457L917.578 179.254L924.155 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M938.956 175.457V167.863L932.38 164.066L925.803 167.863V175.457L932.38 179.254L938.956 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M953.641 175.457V167.863L947.065 164.066L940.489 167.863V175.457L947.065 179.254L953.641 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M820.782 200.5V192.906L814.206 189.109L807.629 192.906V200.5L814.206 204.297L820.782 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M835.466 200.5V192.906L828.89 189.109L822.299 192.906V200.5L828.89 204.297L835.466 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M850.267 200.5V192.906L843.691 189.109L837.115 192.906V200.5L843.691 204.297L850.267 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M865.068 200.5V192.906L858.491 189.109L851.915 192.906V200.5L858.491 204.297L865.068 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M879.868 200.5V192.906L873.292 189.109L866.715 192.906V200.5L873.292 204.297L879.868 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M894.555 200.5V192.906L887.979 189.109L881.388 192.906V200.5L887.979 204.297L894.555 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 200.5V192.906L902.778 189.109L896.202 192.906V200.5L902.778 204.297L909.354 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M924.155 200.5V192.906L917.578 189.109L911.002 192.906V200.5L917.578 204.297L924.155 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M938.956 200.5V192.906L932.38 189.109L925.803 192.906V200.5L932.38 204.297L938.956 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M953.641 200.5V192.906L947.065 189.109L940.489 192.906V200.5L947.065 204.297L953.641 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M968.441 200.5V192.906L961.864 189.109L955.288 192.906V200.5L961.864 204.297L968.441 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M983.243 150.42V142.812L976.667 139.016L970.09 142.812V150.42L976.667 154.217L983.243 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M968.312 150.42V142.812L961.721 139.016L955.145 142.812V150.42L961.721 154.217L968.312 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M998.042 150.42V142.812L991.466 139.016L984.89 142.812V150.42L991.466 154.217L998.042 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M968.441 175.484V167.891L961.864 164.094L955.288 167.891V175.484L961.864 179.281L968.441 175.484Z"
      fill="#DBDBDB"
    />
    <path
      d="M983.243 175.484V167.891L976.667 164.094L970.09 167.891V175.484L976.667 179.281L983.243 175.484Z"
      fill="#DBDBDB"
    />
    <path
      d="M998.042 175.484V167.891L991.466 164.094L984.89 167.891V175.484L991.466 179.281L998.042 175.484Z"
      fill="#DBDBDB"
    />
    <path
      d="M983.243 200.584V192.976L976.667 189.18L970.09 192.976V200.584L976.667 204.381L983.243 200.584Z"
      fill="#DBDBDB"
    />
    <path
      d="M998.042 200.584V192.976L991.466 189.18L984.89 192.976V200.584L991.466 204.381L998.042 200.584Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 150.42V142.812L1006.15 139.016L999.575 142.812V150.42L1006.15 154.217L1012.73 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.53 150.42V142.812L1020.95 139.016L1014.38 142.812V150.42L1020.95 154.217L1027.53 150.42Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042.26 225.547V217.953L1035.67 214.156L1029.09 217.953V225.547L1035.67 229.343L1042.26 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M1057.06 225.547V217.953L1050.48 214.156L1043.89 217.953V225.547L1050.48 229.343L1057.06 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M1071.73 225.547V217.953L1065.15 214.156L1058.58 217.953V225.547L1065.15 229.343L1071.73 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M1057.06 250.592V242.984L1050.48 239.188L1043.89 242.984V250.592L1050.48 254.389L1057.06 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M1218 248.248V240.641L1211.42 236.844L1204.83 240.641V248.248L1211.42 252.045L1218 248.248Z"
      fill="#DBDBDB"
    />
    <path
      d="M1071.73 250.592V242.984L1065.15 239.188L1058.58 242.984V250.592L1065.15 254.389L1071.73 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M1086.13 225.547V217.953L1079.55 214.156L1072.96 217.953V225.547L1079.55 229.343L1086.13 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M1100.93 225.547V217.953L1094.35 214.156L1087.78 217.953V225.547L1094.35 229.343L1100.93 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M1100.93 250.592V242.984L1094.35 239.188L1087.78 242.984V250.592L1094.35 254.389L1100.93 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M1115.61 225.547V217.953L1109.02 214.156L1102.45 217.953V225.547L1109.02 229.343L1115.61 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M1130.29 225.547V217.953L1123.71 214.156L1117.13 217.953V225.547L1123.71 229.343L1130.29 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M1115.61 300.66V293.066L1109.02 289.27L1102.45 293.066V300.66L1109.02 304.457L1115.61 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M1130.29 300.66V293.066L1123.71 289.27L1117.13 293.066V300.66L1123.71 304.457L1130.29 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M820.782 225.547V217.953L814.206 214.156L807.629 217.953V225.547L814.206 229.343L820.782 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M835.466 225.547V217.953L828.89 214.156L822.299 217.953V225.547L828.89 229.343L835.466 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M850.267 225.547V217.953L843.691 214.156L837.115 217.953V225.547L843.691 229.343L850.267 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M865.068 225.547V217.953L858.491 214.156L851.915 217.953V225.547L858.491 229.343L865.068 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M879.868 225.547V217.953L873.292 214.156L866.715 217.953V225.547L873.292 229.343L879.868 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M894.555 225.547V217.953L887.979 214.156L881.388 217.953V225.547L887.979 229.343L894.555 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 225.547V217.953L902.778 214.156L896.202 217.953V225.547L902.778 229.343L909.354 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M924.155 225.547V217.953L917.578 214.156L911.002 217.953V225.547L917.578 229.343L924.155 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M938.956 225.547V217.953L932.38 214.156L925.803 217.953V225.547L932.38 229.343L938.956 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M953.641 225.547V217.953L947.065 214.156L940.489 217.953V225.547L947.065 229.343L953.641 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M968.441 225.547V217.953L961.864 214.156L955.288 217.953V225.547L961.864 229.343L968.441 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M983.243 225.547V217.953L976.667 214.156L970.09 217.953V225.547L976.667 229.343L983.243 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M998.042 225.547V217.953L991.466 214.156L984.89 217.953V225.547L991.466 229.343L998.042 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 225.547V217.953L1006.15 214.156L999.575 217.953V225.547L1006.15 229.343L1012.73 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.53 225.547V217.953L1020.95 214.156L1014.38 217.953V225.547L1020.95 229.343L1027.53 225.547Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042.26 250.592V242.984L1035.67 239.188L1029.09 242.984V250.592L1035.67 254.389L1042.26 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M1086.29 250.592V242.984L1079.71 239.188L1073.13 242.984V250.592L1079.71 254.389L1086.29 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M820.782 250.592V242.984L814.206 239.188L807.629 242.984V250.592L814.206 254.389L820.782 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M806.025 250.592V242.984L799.448 239.188L792.872 242.984V250.592L799.448 254.389L806.025 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M835.466 250.592V242.984L828.89 239.188L822.299 242.984V250.592L828.89 254.389L835.466 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M850.267 250.592V242.984L843.691 239.188L837.115 242.984V250.592L843.691 254.389L850.267 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M865.068 250.592V242.984L858.491 239.188L851.915 242.984V250.592L858.491 254.389L865.068 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M879.868 250.592V242.984L873.292 239.188L866.715 242.984V250.592L873.292 254.389L879.868 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M894.555 250.592V242.984L887.979 239.188L881.388 242.984V250.592L887.979 254.389L894.555 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 250.592V242.984L902.778 239.188L896.202 242.984V250.592L902.778 254.389L909.354 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M924.155 250.592V242.984L917.578 239.188L911.002 242.984V250.592L917.578 254.389L924.155 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M938.956 250.592V242.984L932.38 239.188L925.803 242.984V250.592L932.38 254.389L938.956 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M953.641 250.592V242.984L947.065 239.188L940.489 242.984V250.592L947.065 254.389L953.641 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M968.441 250.592V242.984L961.864 239.188L955.288 242.984V250.592L961.864 254.389L968.441 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M983.243 250.592V242.984L976.667 239.188L970.09 242.984V250.592L976.667 254.389L983.243 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M998.042 250.592V242.984L991.466 239.188L984.89 242.984V250.592L991.466 254.389L998.042 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M820.782 275.621V268.027L814.206 264.23L807.629 268.027V275.621L814.206 279.432L820.782 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M806.025 275.621V268.027L799.448 264.23L792.872 268.027V275.621L799.448 279.432L806.025 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M835.466 275.621V268.027L828.89 264.23L822.299 268.027V275.621L828.89 279.432L835.466 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M850.267 275.621V268.027L843.691 264.23L837.115 268.027V275.621L843.691 279.432L850.267 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M865.068 275.621V268.027L858.491 264.23L851.915 268.027V275.621L858.491 279.432L865.068 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M879.868 275.621V268.027L873.292 264.23L866.715 268.027V275.621L873.292 279.432L879.868 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M894.555 275.621V268.027L887.979 264.23L881.388 268.027V275.621L887.979 279.432L894.555 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 275.621V268.027L902.778 264.23L896.202 268.027V275.621L902.778 279.432L909.354 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M924.155 275.621V268.027L917.578 264.23L911.002 268.027V275.621L917.578 279.432L924.155 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M938.956 275.621V268.027L932.38 264.23L925.803 268.027V275.621L932.38 279.432L938.956 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M953.641 275.621V268.027L947.065 264.23L940.489 268.027V275.621L947.065 279.432L953.641 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M968.441 275.621V268.027L961.864 264.23L955.288 268.027V275.621L961.864 279.432L968.441 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M983.243 275.621V268.027L976.667 264.23L970.09 268.027V275.621L976.667 279.432L983.243 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M820.782 300.66V293.066L814.206 289.27L807.629 293.066V300.66L814.206 304.457L820.782 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M806.025 300.66V293.066L799.448 289.27L792.872 293.066V300.66L799.448 304.457L806.025 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M835.466 300.66V293.066L828.89 289.27L822.299 293.066V300.66L828.89 304.457L835.466 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M850.267 300.66V293.066L843.691 289.27L837.115 293.066V300.66L843.691 304.457L850.267 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M865.068 300.66V293.066L858.491 289.27L851.915 293.066V300.66L858.491 304.457L865.068 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M879.868 300.66V293.066L873.292 289.27L866.715 293.066V300.66L873.292 304.457L879.868 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M894.555 300.66V293.066L887.979 289.27L881.388 293.066V300.66L887.979 304.457L894.555 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 300.66V293.066L902.778 289.27L896.202 293.066V300.66L902.778 304.457L909.354 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M924.155 300.66V293.066L917.578 289.27L911.002 293.066V300.66L917.578 304.457L924.155 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M938.956 300.66V293.066L932.38 289.27L925.803 293.066V300.66L932.38 304.457L938.956 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M953.641 300.66V293.066L947.065 289.27L940.489 293.066V300.66L947.065 304.457L953.641 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M968.441 300.66V293.066L961.864 289.27L955.288 293.066V300.66L961.864 304.457L968.441 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M983.243 300.66V293.066L976.667 289.27L970.09 293.066V300.66L976.667 304.457L983.243 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 325.711V318.117L710.703 314.32L704.127 318.117V325.711L710.703 329.507L717.294 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 325.711V318.117L725.39 314.32L718.813 318.117V325.711L725.39 329.507L731.966 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M746.766 325.711V318.117L740.189 314.32L733.613 318.117V325.711L740.189 329.507L746.766 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M761.581 325.711V318.117L754.99 314.32L748.414 318.117V325.711L754.99 329.507L761.581 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M791.182 325.711V318.117L784.606 314.32L778.029 318.117V325.711L784.606 329.507L791.182 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M820.782 325.711V318.117L814.206 314.32L807.629 318.117V325.711L814.206 329.507L820.782 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M806.025 325.711V318.117L799.448 314.32L792.872 318.117V325.711L799.448 329.507L806.025 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M554.577 350.808V343.215L548.001 339.418L541.425 343.215V350.808L548.001 354.605L554.577 350.808Z"
      fill="#DBDBDB"
    />
    <path
      d="M539.776 350.808V343.215L533.199 339.418L526.623 343.215V350.808L533.199 354.605L539.776 350.808Z"
      fill="#DBDBDB"
    />
    <path
      d="M569.391 350.808V343.215L562.8 339.418L556.224 343.215V350.808L562.8 354.605L569.391 350.808Z"
      fill="#DBDBDB"
    />
    <path
      d="M598.992 350.808V343.215L592.415 339.418L585.839 343.215V350.808L592.415 354.605L598.992 350.808Z"
      fill="#DBDBDB"
    />
    <path
      d="M584.192 350.808V343.215L577.616 339.418L571.025 343.215V350.808L577.616 354.605L584.192 350.808Z"
      fill="#DBDBDB"
    />
    <path
      d="M510.174 350.808V343.215L503.598 339.418L497.021 343.215V350.808L503.598 354.605L510.174 350.808Z"
      fill="#DBDBDB"
    />
    <path
      d="M524.976 350.808V343.215L518.4 339.418L511.824 343.215V350.808L518.4 354.605L524.976 350.808Z"
      fill="#DBDBDB"
    />
    <path
      d="M495.374 350.808V343.215L488.798 339.418L482.207 343.215V350.808L488.798 354.605L495.374 350.808Z"
      fill="#DBDBDB"
    />
    <path
      d="M613.792 350.694V343.086L607.215 339.289L600.639 343.086V350.694L607.215 354.491L613.792 350.694Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 350.694V343.086L622.015 339.289L615.439 343.086V350.694L622.015 354.491L628.591 350.694Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 350.694V343.086L636.816 339.289L630.239 343.086V350.694L636.816 354.491L643.392 350.694Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 350.694V343.086L651.616 339.289L645.04 343.086V350.694L651.616 354.491L658.207 350.694Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 350.694V343.086L666.303 339.289L659.727 343.086V350.694L666.303 354.491L672.879 350.694Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 350.694V343.086L681.102 339.289L674.526 343.086V350.694L681.102 354.491L687.679 350.694Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 350.694V343.086L695.903 339.289L689.326 343.086V350.694L695.903 354.491L702.479 350.694Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 350.694V343.086L710.703 339.289L704.127 343.086V350.694L710.703 354.491L717.294 350.694Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 350.694V343.086L725.39 339.289L718.813 343.086V350.694L725.39 354.491L731.966 350.694Z"
      fill="#DBDBDB"
    />
    <path
      d="M746.766 350.694V343.086L740.189 339.289L733.613 343.086V350.694L740.189 354.491L746.766 350.694Z"
      fill="#DBDBDB"
    />
    <path
      d="M761.581 350.694V343.086L754.99 339.289L748.414 343.086V350.694L754.99 354.491L761.581 350.694Z"
      fill="#DBDBDB"
    />
    <path
      d="M791.182 350.694V343.086L784.606 339.289L778.029 343.086V350.694L784.606 354.491L791.182 350.694Z"
      fill="#DBDBDB"
    />
    <path
      d="M776.381 375.795V368.187L769.805 364.391L763.214 368.187V375.795L769.805 379.592L776.381 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M554.577 375.91V368.316L548.001 364.52L541.425 368.316V375.91L548.001 379.707L554.577 375.91Z"
      fill="#DBDBDB"
    />
    <path
      d="M539.776 375.91V368.316L533.199 364.52L526.623 368.316V375.91L533.199 379.707L539.776 375.91Z"
      fill="#DBDBDB"
    />
    <path
      d="M569.391 375.91V368.316L562.8 364.52L556.224 368.316V375.91L562.8 379.707L569.391 375.91Z"
      fill="#DBDBDB"
    />
    <path
      d="M598.992 375.91V368.316L592.415 364.52L585.839 368.316V375.91L592.415 379.707L598.992 375.91Z"
      fill="#DBDBDB"
    />
    <path
      d="M584.192 375.91V368.316L577.616 364.52L571.025 368.316V375.91L577.616 379.707L584.192 375.91Z"
      fill="#DBDBDB"
    />
    <path
      d="M510.174 375.91V368.316L503.598 364.52L497.021 368.316V375.91L503.598 379.707L510.174 375.91Z"
      fill="#DBDBDB"
    />
    <path
      d="M524.976 375.91V368.316L518.4 364.52L511.824 368.316V375.91L518.4 379.707L524.976 375.91Z"
      fill="#DBDBDB"
    />
    <path
      d="M495.374 375.91V368.316L488.798 364.52L482.207 368.316V375.91L488.798 379.707L495.374 375.91Z"
      fill="#DBDBDB"
    />
    <path
      d="M613.792 375.795V368.187L607.215 364.391L600.639 368.187V375.795L607.215 379.592L613.792 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 375.795V368.187L622.015 364.391L615.439 368.187V375.795L622.015 379.592L628.591 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 375.795V368.187L636.816 364.391L630.239 368.187V375.795L636.816 379.592L643.392 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 375.795V368.187L651.616 364.391L645.04 368.187V375.795L651.616 379.592L658.207 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 375.795V368.187L666.303 364.391L659.727 368.187V375.795L666.303 379.592L672.879 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 375.795V368.187L681.102 364.391L674.526 368.187V375.795L681.102 379.592L687.679 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 375.795V368.187L695.903 364.391L689.326 368.187V375.795L695.903 379.592L702.479 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 375.795V368.187L710.703 364.391L704.127 368.187V375.795L710.703 379.592L717.294 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 375.795V368.187L725.39 364.391L718.813 368.187V375.795L725.39 379.592L731.966 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M746.766 375.795V368.187L740.189 364.391L733.613 368.187V375.795L740.189 379.592L746.766 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M761.581 375.795V368.187L754.99 364.391L748.414 368.187V375.795L754.99 379.592L761.581 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M776.381 401.015V393.422L769.805 389.625L763.214 393.422V401.015L769.805 404.812L776.381 401.015Z"
      fill="#DBDBDB"
    />
    <path
      d="M554.577 401.14V393.547L548.001 389.75L541.425 393.547V401.14L548.001 404.937L554.577 401.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M539.776 401.14V393.547L533.199 389.75L526.623 393.547V401.14L533.199 404.937L539.776 401.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M569.391 401.14V393.547L562.8 389.75L556.224 393.547V401.14L562.8 404.937L569.391 401.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M598.992 401.14V393.547L592.415 389.75L585.839 393.547V401.14L592.415 404.937L598.992 401.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M584.192 401.14V393.547L577.616 389.75L571.025 393.547V401.14L577.616 404.937L584.192 401.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M510.174 401.14V393.547L503.598 389.75L497.021 393.547V401.14L503.598 404.937L510.174 401.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M524.976 401.14V393.547L518.4 389.75L511.824 393.547V401.14L518.4 404.937L524.976 401.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M495.374 401.14V393.547L488.798 389.75L482.207 393.547V401.14L488.798 404.937L495.374 401.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M613.792 401.015V393.422L607.215 389.625L600.639 393.422V401.015L607.215 404.812L613.792 401.015Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 401.015V393.422L622.015 389.625L615.439 393.422V401.015L622.015 404.812L628.591 401.015Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 401.015V393.422L636.816 389.625L630.239 393.422V401.015L636.816 404.812L643.392 401.015Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 401.015V393.422L651.616 389.625L645.04 393.422V401.015L651.616 404.812L658.207 401.015Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 401.015V393.422L666.303 389.625L659.727 393.422V401.015L666.303 404.812L672.879 401.015Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 401.015V393.422L681.102 389.625L674.526 393.422V401.015L681.102 404.812L687.679 401.015Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 401.015V393.422L695.903 389.625L689.326 393.422V401.015L695.903 404.812L702.479 401.015Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 401.015V393.422L710.703 389.625L704.127 393.422V401.015L710.703 404.812L717.294 401.015Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 401.015V393.422L725.39 389.625L718.813 393.422V401.015L725.39 404.812L731.966 401.015Z"
      fill="#DBDBDB"
    />
    <path
      d="M746.766 401.015V393.422L740.189 389.625L733.613 393.422V401.015L740.189 404.812L746.766 401.015Z"
      fill="#DBDBDB"
    />
    <path
      d="M761.581 401.015V393.422L754.99 389.625L748.414 393.422V401.015L754.99 404.812L761.581 401.015Z"
      fill="#DBDBDB"
    />
    <path
      d="M776.381 426.859V419.266L769.805 415.469L763.214 419.266V426.859L769.805 430.656L776.381 426.859Z"
      fill="#DBDBDB"
    />
    <path
      d="M554.577 426.991V419.383L548.001 415.586L541.425 419.383V426.991L548.001 430.787L554.577 426.991Z"
      fill="#DBDBDB"
    />
    <path
      d="M539.776 426.991V419.383L533.199 415.586L526.623 419.383V426.991L533.199 430.787L539.776 426.991Z"
      fill="#DBDBDB"
    />
    <path
      d="M569.391 426.991V419.383L562.8 415.586L556.224 419.383V426.991L562.8 430.787L569.391 426.991Z"
      fill="#DBDBDB"
    />
    <path
      d="M598.992 426.991V419.383L592.415 415.586L585.839 419.383V426.991L592.415 430.787L598.992 426.991Z"
      fill="#DBDBDB"
    />
    <path
      d="M584.192 426.991V419.383L577.616 415.586L571.025 419.383V426.991L577.616 430.787L584.192 426.991Z"
      fill="#DBDBDB"
    />
    <path
      d="M510.174 426.991V419.383L503.598 415.586L497.021 419.383V426.991L503.598 430.787L510.174 426.991Z"
      fill="#DBDBDB"
    />
    <path
      d="M524.976 426.991V419.383L518.4 415.586L511.824 419.383V426.991L518.4 430.787L524.976 426.991Z"
      fill="#DBDBDB"
    />
    <path
      d="M613.792 426.859V419.266L607.215 415.469L600.639 419.266V426.859L607.215 430.656L613.792 426.859Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 426.859V419.266L622.015 415.469L615.439 419.266V426.859L622.015 430.656L628.591 426.859Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 426.859V419.266L636.816 415.469L630.239 419.266V426.859L636.816 430.656L643.392 426.859Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 426.859V419.266L651.616 415.469L645.04 419.266V426.859L651.616 430.656L658.207 426.859Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 426.859V419.266L666.303 415.469L659.727 419.266V426.859L666.303 430.656L672.879 426.859Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 426.859V419.266L681.102 415.469L674.526 419.266V426.859L681.102 430.656L687.679 426.859Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 426.859V419.266L695.903 415.469L689.326 419.266V426.859L695.903 430.656L702.479 426.859Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 426.859V419.266L710.703 415.469L704.127 419.266V426.859L710.703 430.656L717.294 426.859Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 426.859V419.266L725.39 415.469L718.813 419.266V426.859L725.39 430.656L731.966 426.859Z"
      fill="#DBDBDB"
    />
    <path
      d="M746.766 426.859V419.266L740.189 415.469L733.613 419.266V426.859L740.189 430.656L746.766 426.859Z"
      fill="#DBDBDB"
    />
    <path
      d="M761.581 426.859V419.266L754.99 415.469L748.414 419.266V426.859L754.99 430.656L761.581 426.859Z"
      fill="#DBDBDB"
    />
    <path
      d="M598.992 452.949V445.355L592.415 441.559L585.839 445.355V452.949L592.415 456.746L598.992 452.949Z"
      fill="#DBDBDB"
    />
    <path
      d="M613.792 452.82V445.226L607.215 441.43L600.639 445.226V452.82L607.215 456.631L613.792 452.82Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 452.82V445.226L622.015 441.43L615.439 445.226V452.82L622.015 456.631L628.591 452.82Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 452.82V445.226L636.816 441.43L630.239 445.226V452.82L636.816 456.631L643.392 452.82Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 452.82V445.226L651.616 441.43L645.04 445.226V452.82L651.616 456.631L658.207 452.82Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 452.82V445.226L666.303 441.43L659.727 445.226V452.82L666.303 456.631L672.879 452.82Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 452.82V445.226L681.102 441.43L674.526 445.226V452.82L681.102 456.631L687.679 452.82Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 452.82V445.226L695.903 441.43L689.326 445.226V452.82L695.903 456.631L702.479 452.82Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 452.82V445.226L710.703 441.43L704.127 445.226V452.82L710.703 456.631L717.294 452.82Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 452.82V445.226L725.39 441.43L718.813 445.226V452.82L725.39 456.631L731.966 452.82Z"
      fill="#DBDBDB"
    />
    <path
      d="M613.792 478.801V471.207L607.215 467.41L600.639 471.207V478.801L607.215 482.597L613.792 478.801Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 478.801V471.207L622.015 467.41L615.439 471.207V478.801L622.015 482.597L628.591 478.801Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 478.801V471.207L636.816 467.41L630.239 471.207V478.801L636.816 482.597L643.392 478.801Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 478.801V471.207L651.616 467.41L645.04 471.207V478.801L651.616 482.597L658.207 478.801Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 478.801V471.207L666.303 467.41L659.727 471.207V478.801L666.303 482.597L672.879 478.801Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 478.801V471.207L681.102 467.41L674.526 471.207V478.801L681.102 482.597L687.679 478.801Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 478.801V471.207L695.903 467.41L689.326 471.207V478.801L695.903 482.597L702.479 478.801Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 478.801V471.207L710.703 467.41L704.127 471.207V478.801L710.703 482.597L717.294 478.801Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 478.801V471.207L725.39 467.41L718.813 471.207V478.801L725.39 482.597L731.966 478.801Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 504.758V497.164L622.015 493.367L615.439 497.164V504.758L622.015 508.554L628.591 504.758Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 504.758V497.164L636.816 493.367L630.239 497.164V504.758L636.816 508.554L643.392 504.758Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 504.758V497.164L651.616 493.367L645.04 497.164V504.758L651.616 508.554L658.207 504.758Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 504.758V497.164L666.303 493.367L659.727 497.164V504.758L666.303 508.554L672.879 504.758Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 504.758V497.164L681.102 493.367L674.526 497.164V504.758L681.102 508.554L687.679 504.758Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 504.758V497.164L695.903 493.367L689.326 497.164V504.758L695.903 508.554L702.479 504.758Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 504.758V497.164L710.703 493.367L704.127 497.164V504.758L710.703 508.554L717.294 504.758Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 530.234V522.641L622.015 518.844L615.439 522.641V530.234L622.015 534.031L628.591 530.234Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 530.234V522.641L636.816 518.844L630.239 522.641V530.234L636.816 534.031L643.392 530.234Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 530.234V522.641L651.616 518.844L645.04 522.641V530.234L651.616 534.031L658.207 530.234Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 530.234V522.641L666.303 518.844L659.727 522.641V530.234L666.303 534.031L672.879 530.234Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 530.234V522.641L681.102 518.844L674.526 522.641V530.234L681.102 534.031L687.679 530.234Z"
      fill="#DBDBDB"
    />
    <path
      id="public-map-malawi-carbon"
      d="M702.479 530.234V522.641L695.903 518.844L689.326 522.641V530.234L695.903 534.031L702.479 530.234Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 530.234V522.641L710.703 518.844L704.127 522.641V530.234L710.703 534.031L717.294 530.234Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 555.707V548.113L622.015 544.316L615.439 548.113V555.707L622.015 559.504L628.591 555.707Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 555.707V548.113L636.816 544.316L630.239 548.113V555.707L636.816 559.504L643.392 555.707Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 555.707V548.113L651.616 544.316L645.04 548.113V555.707L651.616 559.504L658.207 555.707Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 555.707V548.113L666.303 544.316L659.727 548.113V555.707L666.303 559.504L672.879 555.707Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 555.707V548.113L681.102 544.316L674.526 548.113V555.707L681.102 559.504L687.679 555.707Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 555.707V548.113L695.903 544.316L689.326 548.113V555.707L695.903 559.504L702.479 555.707Z"
      fill="#DBDBDB"
    />
    <path
      d="M628.591 581.183V573.59L622.015 569.793L615.439 573.59V581.183L622.015 584.98L628.591 581.183Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 581.183V573.59L636.816 569.793L630.239 573.59V581.183L636.816 584.98L643.392 581.183Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 581.183V573.59L651.616 569.793L645.04 573.59V581.183L651.616 584.98L658.207 581.183Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 581.183V573.59L666.303 569.793L659.727 573.59V581.183L666.303 584.98L672.879 581.183Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 581.183V573.59L681.102 569.793L674.526 573.59V581.183L681.102 584.98L687.679 581.183Z"
      fill="#DBDBDB"
    />
    <path
      d="M702.479 581.183V573.59L695.903 569.793L689.326 573.59V581.183L695.903 584.98L702.479 581.183Z"
      fill="#DBDBDB"
    />
    <path
      d="M643.392 606.66V599.066L636.816 595.27L630.239 599.066V606.66L636.816 610.457L643.392 606.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 606.66V599.066L651.616 595.27L645.04 599.066V606.66L651.616 610.457L658.207 606.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 606.66V599.066L666.303 595.27L659.727 599.066V606.66L666.303 610.457L672.879 606.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M658.207 632.617V625.023L651.616 621.227L645.04 625.023V632.617L651.616 636.414L658.207 632.617Z"
      fill="#DBDBDB"
    />
    <path
      d="M672.879 632.617V625.023L666.303 621.227L659.727 625.023V632.617L666.303 636.414L672.879 632.617Z"
      fill="#DBDBDB"
    />
    <path
      d="M687.679 606.66V599.066L681.102 595.27L674.526 599.066V606.66L681.102 610.457L687.679 606.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M717.294 555.707V548.113L710.703 544.316L704.127 548.113V555.707L710.703 559.504L717.294 555.707Z"
      fill="#DBDBDB"
    />
    <path
      d="M761.509 555.707V548.113L754.932 544.316L748.356 548.113V555.707L754.932 559.504L761.509 555.707Z"
      fill="#DBDBDB"
    />
    <path
      d="M761.509 581.183V573.59L754.932 569.793L748.356 573.59V581.183L754.932 584.98L761.509 581.183Z"
      fill="#DBDBDB"
    />
    <path
      d="M776.308 555.707V548.113L769.732 544.316L763.156 548.113V555.707L769.732 559.504L776.308 555.707Z"
      fill="#DBDBDB"
    />
    <path
      d="M731.966 530.234V522.641L725.39 518.844L718.813 522.641V530.234L725.39 534.031L731.966 530.234Z"
      fill="#DBDBDB"
    />
    <path
      d="M746.766 452.82V445.226L740.189 441.43L733.613 445.226V452.82L740.189 456.631L746.766 452.82Z"
      fill="#DBDBDB"
    />
    <path
      d="M761.581 452.82V445.226L754.99 441.43L748.414 445.226V452.82L754.99 456.631L761.581 452.82Z"
      fill="#DBDBDB"
    />
    <path
      d="M791.182 375.795V368.187L784.606 364.391L778.029 368.187V375.795L784.606 379.592L791.182 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M820.782 350.694V343.086L814.206 339.289L807.629 343.086V350.694L814.206 354.491L820.782 350.694Z"
      fill="#DBDBDB"
    />
    <path
      d="M806.025 350.694V343.086L799.448 339.289L792.872 343.086V350.694L799.448 354.491L806.025 350.694Z"
      fill="#DBDBDB"
    />
    <path
      d="M835.466 325.711V318.117L828.89 314.32L822.299 318.117V325.711L828.89 329.507L835.466 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M850.267 325.711V318.117L843.691 314.32L837.115 318.117V325.711L843.691 329.507L850.267 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M865.068 325.711V318.117L858.491 314.32L851.915 318.117V325.711L858.491 329.507L865.068 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M865.068 350.754V343.16L858.491 339.363L851.915 343.16V350.754L858.491 354.55L865.068 350.754Z"
      fill="#DBDBDB"
    />
    <path
      d="M879.868 325.711V318.117L873.292 314.32L866.715 318.117V325.711L873.292 329.507L879.868 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M894.555 325.711V318.117L887.979 314.32L881.388 318.117V325.711L887.979 329.507L894.555 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 325.711V318.117L902.778 314.32L896.202 318.117V325.711L902.778 329.507L909.354 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M924.155 325.711V318.117L917.578 314.32L911.002 318.117V325.711L917.578 329.507L924.155 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M938.956 325.711V318.117L932.38 314.32L925.803 318.117V325.711L932.38 329.507L938.956 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M953.641 325.711V318.117L947.065 314.32L940.489 318.117V325.711L947.065 329.507L953.641 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M968.441 325.711V318.117L961.864 314.32L955.288 318.117V325.711L961.864 329.507L968.441 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M983.243 325.711V318.117L976.667 314.32L970.09 318.117V325.711L976.667 329.507L983.243 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M998.042 275.621V268.027L991.466 264.23L984.89 268.027V275.621L991.466 279.432L998.042 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 250.592V242.984L1006.15 239.188L999.575 242.984V250.592L1006.15 254.389L1012.73 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.53 250.592V242.984L1020.95 239.188L1014.38 242.984V250.592L1020.95 254.389L1027.53 250.592Z"
      fill="#DBDBDB"
    />
    <path
      d="M1057.06 275.621V268.027L1050.48 264.23L1043.89 268.027V275.621L1050.48 279.432L1057.06 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M1071.73 275.621V268.027L1065.15 264.23L1058.58 268.027V275.621L1065.15 279.432L1071.73 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M1100.93 275.621V268.027L1094.35 264.23L1087.78 268.027V275.621L1094.35 279.432L1100.93 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042.26 275.621V268.027L1035.67 264.23L1029.09 268.027V275.621L1035.67 279.432L1042.26 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M1203.2 273.273V265.68L1196.61 261.883L1190.03 265.68V273.273L1196.61 277.084L1203.2 273.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M1086.29 275.621V268.027L1079.71 264.23L1073.13 268.027V275.621L1079.71 279.432L1086.29 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M1159.77 275.621V268.027L1153.2 264.23L1146.61 268.027V275.621L1153.2 279.432L1159.77 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M1145.13 275.621V268.027L1138.55 264.23L1131.98 268.027V275.621L1138.55 279.432L1145.13 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 275.621V268.027L1006.15 264.23L999.575 268.027V275.621L1006.15 279.432L1012.73 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.53 275.621V268.027L1020.95 264.23L1014.38 268.027V275.621L1020.95 279.432L1027.53 275.621Z"
      fill="#DBDBDB"
    />
    <path
      d="M998.042 300.66V293.066L991.466 289.27L984.89 293.066V300.66L991.466 304.457L998.042 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M1057.06 300.66V293.066L1050.48 289.27L1043.89 293.066V300.66L1050.48 304.457L1057.06 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M1071.73 300.66V293.066L1065.15 289.27L1058.58 293.066V300.66L1065.15 304.457L1071.73 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042.26 300.66V293.066L1035.67 289.27L1029.09 293.066V300.66L1035.67 304.457L1042.26 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M1203.2 298.32V290.726L1196.61 286.93L1190.03 290.726V298.32L1196.61 302.117L1203.2 298.32Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 300.66V293.066L1006.15 289.27L999.575 293.066V300.66L1006.15 304.457L1012.73 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M1173.67 298.32V290.726L1167.09 286.93L1160.52 290.726V298.32L1167.09 302.117L1173.67 298.32Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.53 300.66V293.066L1020.95 289.27L1014.38 293.066V300.66L1020.95 304.457L1027.53 300.66Z"
      fill="#DBDBDB"
    />
    <path
      d="M1188.47 298.32V290.726L1181.89 286.93L1175.32 290.726V298.32L1181.89 302.117L1188.47 298.32Z"
      fill="#DBDBDB"
    />
    <path
      d="M998.042 325.711V318.117L991.466 314.32L984.89 318.117V325.711L991.466 329.507L998.042 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M1057.06 325.711V318.117L1050.48 314.32L1043.89 318.117V325.711L1050.48 329.507L1057.06 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M1071.73 325.711V318.117L1065.15 314.32L1058.58 318.117V325.711L1065.15 329.507L1071.73 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M1086.29 325.711V318.117L1079.71 314.32L1073.13 318.117V325.711L1079.71 329.507L1086.29 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042.26 325.711V318.117L1035.67 314.32L1029.09 318.117V325.711L1035.67 329.507L1042.26 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 325.711V318.117L1006.15 314.32L999.575 318.117V325.711L1006.15 329.507L1012.73 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M1173.67 323.367V315.773L1167.09 311.977L1160.52 315.773V323.367L1167.09 327.164L1173.67 323.367Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.53 325.711V318.117L1020.95 314.32L1014.38 318.117V325.711L1020.95 329.507L1027.53 325.711Z"
      fill="#DBDBDB"
    />
    <path
      d="M879.868 350.754V343.16L873.292 339.363L866.715 343.16V350.754L873.292 354.55L879.868 350.754Z"
      fill="#DBDBDB"
    />
    <path
      d="M894.555 350.754V343.16L887.979 339.363L881.388 343.16V350.754L887.979 354.55L894.555 350.754Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 350.754V343.16L902.778 339.363L896.202 343.16V350.754L902.778 354.55L909.354 350.754Z"
      fill="#DBDBDB"
    />
    <path
      d="M924.155 350.754V343.16L917.578 339.363L911.002 343.16V350.754L917.578 354.55L924.155 350.754Z"
      fill="#DBDBDB"
    />
    <path
      d="M894.555 375.795V368.187L887.979 364.391L881.388 368.187V375.795L887.979 379.592L894.555 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 375.795V368.187L902.778 364.391L896.202 368.187V375.795L902.778 379.592L909.354 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M924.155 375.795V368.187L917.578 364.391L911.002 368.187V375.795L917.578 379.592L924.155 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 400.824V393.23L902.778 389.434L896.202 393.23V400.824L902.778 404.635L909.354 400.824Z"
      fill="#DBDBDB"
    />
    <path
      d="M924.155 400.824V393.23L917.578 389.434L911.002 393.23V400.824L917.578 404.635L924.155 400.824Z"
      fill="#DBDBDB"
    />
    <path
      d="M909.354 425.875V418.281L902.778 414.484L896.202 418.281V425.875L902.778 429.672L909.354 425.875Z"
      fill="#DBDBDB"
    />
    <path
      d="M924.155 425.875V418.281L917.578 414.484L911.002 418.281V425.875L917.578 429.672L924.155 425.875Z"
      fill="#DBDBDB"
    />
    <path
      d="M938.956 350.754V343.16L932.38 339.363L925.803 343.16V350.754L932.38 354.55L938.956 350.754Z"
      fill="#DBDBDB"
    />
    <path
      d="M953.641 350.754V343.16L947.065 339.363L940.489 343.16V350.754L947.065 354.55L953.641 350.754Z"
      fill="#DBDBDB"
    />
    <path
      d="M968.441 350.754V343.16L961.864 339.363L955.288 343.16V350.754L961.864 354.55L968.441 350.754Z"
      fill="#DBDBDB"
    />
    <path
      d="M983.243 350.754V343.16L976.667 339.363L970.09 343.16V350.754L976.667 354.55L983.243 350.754Z"
      fill="#DBDBDB"
    />
    <path
      d="M998.042 350.748V343.155L991.466 339.344L984.89 343.155V350.748L991.466 354.545L998.042 350.748Z"
      fill="#DBDBDB"
    />
    <path
      d="M1057.06 350.754V343.16L1050.48 339.363L1043.89 343.16V350.754L1050.48 354.55L1057.06 350.754Z"
      fill="#DBDBDB"
    />
    <path
      d="M1071.73 350.754V343.16L1065.15 339.363L1058.58 343.16V350.754L1065.15 354.55L1071.73 350.754Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042.26 350.748V343.155L1035.67 339.344L1029.09 343.155V350.748L1035.67 354.545L1042.26 350.748Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 350.754V343.16L1006.15 339.363L999.575 343.16V350.754L1006.15 354.55L1012.73 350.754Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.53 350.754V343.16L1020.95 339.363L1014.38 343.16V350.754L1020.95 354.55L1027.53 350.754Z"
      fill="#DBDBDB"
    />
    <path
      d="M983.243 375.795V368.187L976.667 364.391L970.09 368.187V375.795L976.667 379.592L983.243 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M998.042 375.795V368.187L991.466 364.391L984.89 368.187V375.795L991.466 379.592L998.042 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042.26 375.795V368.187L1035.67 364.391L1029.09 368.187V375.795L1035.67 379.592L1042.26 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 375.795V368.187L1006.15 364.391L999.575 368.187V375.795L1006.15 379.592L1012.73 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.53 375.795V368.187L1020.95 364.391L1014.38 368.187V375.795L1020.95 379.592L1027.53 375.795Z"
      fill="#DBDBDB"
    />
    <path
      d="M998.042 450.918V443.324L991.466 439.527L984.89 443.324V450.918L991.466 454.715L998.042 450.918Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 450.918V443.324L1006.15 439.527L999.575 443.324V450.918L1006.15 454.715L1012.73 450.918Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.53 450.918V443.324L1020.95 439.527L1014.38 443.324V450.918L1020.95 454.715L1027.53 450.918Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042.18 450.918V443.324L1035.61 439.527L1029.03 443.324V450.918L1035.61 454.715L1042.18 450.918Z"
      fill="#DBDBDB"
    />
    <path
      id="public-map-philippines-carbon"
      d="M1056.99 450.918V443.324L1050.41 439.527L1043.83 443.324V450.918L1050.41 454.715L1056.99 450.918Z"
      fill="#DBDBDB"
    />
    <path
      d="M1071.8 450.918V443.324L1065.21 439.527L1058.63 443.324V450.918L1065.21 454.715L1071.8 450.918Z"
      fill="#DBDBDB"
    />
    <path
      d="M1086.46 450.918V443.324L1079.88 439.527L1073.29 443.324V450.918L1079.88 454.715L1086.46 450.918Z"
      fill="#DBDBDB"
    />
    <path
      d="M1101.26 450.918V443.324L1094.68 439.527L1088.1 443.324V450.918L1094.68 454.715L1101.26 450.918Z"
      fill="#DBDBDB"
    />
    <path
      d="M1175.17 498.39V490.797L1168.59 487L1162 490.797V498.39L1168.59 502.187L1175.17 498.39Z"
      fill="#DBDBDB"
    />
    <path
      d="M1189.97 498.39V490.797L1183.39 487L1176.82 490.797V498.39L1183.39 502.187L1189.97 498.39Z"
      fill="#DBDBDB"
    />
    <path
      d="M1071.8 476.045V468.437L1065.21 464.641L1058.63 468.437V476.045L1065.21 479.842L1071.8 476.045Z"
      fill="#DBDBDB"
    />
    <path
      d="M1086.46 476.045V468.437L1079.88 464.641L1073.29 468.437V476.045L1079.88 479.842L1086.46 476.045Z"
      fill="#DBDBDB"
    />
    <path
      d="M1101.26 476.045V468.437L1094.68 464.641L1088.1 468.437V476.045L1094.68 479.842L1101.26 476.045Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.53 476.045V468.437L1020.95 464.641L1014.38 468.437V476.045L1020.95 479.842L1027.53 476.045Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.53 501.164V493.57L1020.95 489.773L1014.38 493.57V501.164L1020.95 504.975L1027.53 501.164Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042.26 501.164V493.57L1035.67 489.773L1029.09 493.57V501.164L1035.67 504.975L1042.26 501.164Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042.26 400.824V393.23L1035.67 389.434L1029.09 393.23V400.824L1035.67 404.635L1042.26 400.824Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 400.824V393.23L1006.15 389.434L999.575 393.23V400.824L1006.15 404.635L1012.73 400.824Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 425.875V418.281L1006.15 414.484L999.575 418.281V425.875L1006.15 429.672L1012.73 425.875Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.53 400.824V393.23L1020.95 389.434L1014.38 393.23V400.824L1020.95 404.635L1027.53 400.824Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042.26 175.457V167.863L1035.67 164.066L1029.09 167.863V175.457L1035.67 179.254L1042.26 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 175.457V167.863L1006.15 164.066L999.575 167.863V175.457L1006.15 179.254L1012.73 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.53 175.457V167.863L1020.95 164.066L1014.38 167.863V175.457L1020.95 179.254L1027.53 175.457Z"
      fill="#DBDBDB"
    />
    <path
      d="M1042.26 200.5V192.906L1035.67 189.109L1029.09 192.906V200.5L1035.67 204.297L1042.26 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M1012.73 200.5V192.906L1006.15 189.109L999.575 192.906V200.5L1006.15 204.297L1012.73 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M1027.53 200.5V192.906L1020.95 189.109L1014.38 192.906V200.5L1020.95 204.297L1027.53 200.5Z"
      fill="#DBDBDB"
    />
    <path
      d="M990.649 87.3657V79.7577L984.073 75.9609L977.482 79.7577V87.3657L984.073 91.1625L990.649 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 87.3657V79.7577L998.873 75.9609L992.296 79.7577V87.3657L998.873 91.1625L1005.45 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 87.3657V79.7577L1013.54 75.9609L1006.97 79.7577V87.3657L1013.54 91.1625L1020.14 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 87.3657V79.7577L1028.36 75.9609L1021.77 79.7577V87.3657L1028.36 91.1625L1034.94 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M1049.49 87.3657V79.7577L1042.92 75.9609L1036.33 79.7577V87.3657L1042.92 91.1625L1049.49 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M1064.16 87.3657V79.7577L1057.59 75.9609L1051.01 79.7577V87.3657L1057.59 91.1625L1064.16 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M1078.96 87.3657V79.7577L1072.39 75.9609L1065.81 79.7577V87.3657L1072.39 91.1625L1078.96 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M1049.49 112.901V105.307L1042.92 101.496L1036.33 105.307V112.901L1042.92 116.698L1049.49 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M1064.16 112.901V105.307L1057.59 101.496L1051.01 105.307V112.901L1057.59 116.698L1064.16 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M783.774 11.3904V3.7968L777.198 0L770.622 3.7968V11.3904L777.198 15.1872L783.774 11.3904Z"
      fill="#DBDBDB"
    />
    <path
      d="M798.573 11.3904V3.7968L791.997 0L785.42 3.7968V11.3904L791.997 15.1872L798.573 11.3904Z"
      fill="#DBDBDB"
    />
    <path
      d="M754.173 11.3904V3.7968L747.597 0L741.021 3.7968V11.3904L747.597 15.1872L754.173 11.3904Z"
      fill="#DBDBDB"
    />
    <path
      d="M768.974 11.3904V3.7968L762.398 0L755.821 3.7968V11.3904L762.398 15.1872L768.974 11.3904Z"
      fill="#DBDBDB"
    />
    <path
      d="M946.363 11.3904V3.7968L939.786 0L933.21 3.7968V11.3904L939.786 15.1872L946.363 11.3904Z"
      fill="#DBDBDB"
    />
    <path
      d="M961.049 11.3904V3.7968L954.459 0L947.882 3.7968V11.3904L954.459 15.1872L961.049 11.3904Z"
      fill="#DBDBDB"
    />
    <path
      d="M975.849 11.3904V3.7968L969.258 0L962.682 3.7968V11.3904L969.258 15.1872L975.849 11.3904Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 36.4373V28.8437L998.873 25.0469L992.296 28.8437V36.4373L998.873 40.2341L1005.45 36.4373Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 36.4373V28.8437L1013.54 25.0469L1006.97 28.8437V36.4373L1013.54 40.2341L1020.14 36.4373Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 36.4373V28.8437L1028.36 25.0469L1021.77 28.8437V36.4373L1028.36 40.2341L1034.94 36.4373Z"
      fill="#DBDBDB"
    />
    <path
      d="M1049.65 36.4373V28.8437L1043.07 25.0469L1036.5 28.8437V36.4373L1043.07 40.2341L1049.65 36.4373Z"
      fill="#DBDBDB"
    />
    <path
      d="M1078.96 112.901V105.307L1072.39 101.496L1065.81 105.307V112.901L1072.39 116.698L1078.96 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M1093.52 87.3657V79.7577L1086.94 75.9609L1080.37 79.7577V87.3657L1086.94 91.1625L1093.52 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M1108.21 87.3657V79.7577L1101.63 75.9609L1095.05 79.7577V87.3657L1101.63 91.1625L1108.21 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M1123.01 87.3657V79.7577L1116.43 75.9609L1109.85 79.7577V87.3657L1116.43 91.1625L1123.01 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M1137.56 87.3657V79.7577L1130.99 75.9609L1124.41 79.7577V87.3657L1130.99 91.1625L1137.56 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M1152.25 87.3657V79.7577L1145.66 75.9609L1139.08 79.7577V87.3657L1145.66 91.1625L1152.25 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M1167.05 87.3657V79.7577L1160.47 75.9609L1153.88 79.7577V87.3657L1160.47 91.1625L1167.05 87.3657Z"
      fill="#DBDBDB"
    />
    <path
      d="M1093.52 112.901V105.307L1086.94 101.496L1080.37 105.307V112.901L1086.94 116.698L1093.52 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M1108.21 112.901V105.307L1101.63 101.496L1095.05 105.307V112.901L1101.63 116.698L1108.21 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M1123.01 112.901V105.307L1116.43 101.496L1109.85 105.307V112.901L1116.43 116.698L1123.01 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M1137.56 112.901V105.307L1130.99 101.496L1124.41 105.307V112.901L1130.99 116.698L1137.56 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M1152.25 112.901V105.307L1145.66 101.496L1139.08 105.307V112.901L1145.66 116.698L1152.25 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M1167.05 112.901V105.307L1160.47 101.496L1153.88 105.307V112.901L1160.47 116.698L1167.05 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M1181.85 112.901V105.307L1175.28 101.496L1168.7 105.307V112.901L1175.28 116.698L1181.85 112.901Z"
      fill="#DBDBDB"
    />
    <path
      d="M1181.85 137.816V130.223L1175.28 126.426L1168.7 130.223V137.816L1175.28 141.613L1181.85 137.816Z"
      fill="#DBDBDB"
    />
    <path
      d="M1196.58 137.816V130.223L1190 126.426L1183.43 130.223V137.816L1190 141.613L1196.58 137.816Z"
      fill="#DBDBDB"
    />
    <path
      d="M1181.85 162.734V155.141L1175.28 151.344L1168.7 155.141V162.734L1175.28 166.531L1181.85 162.734Z"
      fill="#DBDBDB"
    />
    <path
      d="M1167.05 137.816V130.223L1160.47 126.426L1153.88 130.223V137.816L1160.47 141.613L1167.05 137.816Z"
      fill="#DBDBDB"
    />
    <path
      d="M1093.52 137.816V130.223L1086.94 126.426L1080.37 130.223V137.816L1086.94 141.613L1093.52 137.816Z"
      fill="#DBDBDB"
    />
    <path
      d="M1108.21 137.816V130.223L1101.63 126.426L1095.05 130.223V137.816L1101.63 141.613L1108.21 137.816Z"
      fill="#DBDBDB"
    />
    <path
      d="M1123.01 137.816V130.223L1116.43 126.426L1109.85 130.223V137.816L1116.43 141.613L1123.01 137.816Z"
      fill="#DBDBDB"
    />
    <path
      d="M1137.56 137.816V130.223L1130.99 126.426L1124.41 130.223V137.816L1130.99 141.613L1137.56 137.816Z"
      fill="#DBDBDB"
    />
    <path
      d="M1152.25 137.816V130.223L1145.66 126.426L1139.08 130.223V137.816L1145.66 141.613L1152.25 137.816Z"
      fill="#DBDBDB"
    />
    <path
      d="M1137.56 162.734V155.141L1130.99 151.344L1124.41 155.141V162.734L1130.99 166.531L1137.56 162.734Z"
      fill="#DBDBDB"
    />
    <path
      d="M1152.25 162.734V155.141L1145.66 151.344L1139.08 155.141V162.734L1145.66 166.531L1152.25 162.734Z"
      fill="#DBDBDB"
    />
    <path
      d="M1167.05 162.734V155.141L1160.47 151.344L1153.88 155.141V162.734L1160.47 166.531L1167.05 162.734Z"
      fill="#DBDBDB"
    />
    <path
      d="M1093.52 162.734V155.141L1086.94 151.344L1080.37 155.141V162.734L1086.94 166.531L1093.52 162.734Z"
      fill="#DBDBDB"
    />
    <path
      d="M1108.21 162.734V155.141L1101.63 151.344L1095.05 155.141V162.734L1101.63 166.531L1108.21 162.734Z"
      fill="#DBDBDB"
    />
    <path
      d="M1123.01 162.734V155.141L1116.43 151.344L1109.85 155.141V162.734L1116.43 166.531L1123.01 162.734Z"
      fill="#DBDBDB"
    />
    <path
      d="M1078.89 213.179V205.586L1072.32 201.789L1065.74 205.586V213.179L1072.32 216.991L1078.89 213.179Z"
      fill="#DBDBDB"
    />
    <path
      d="M1093.52 212.937V205.344L1086.94 201.547L1080.37 205.344V212.937L1086.94 216.734L1093.52 212.937Z"
      fill="#DBDBDB"
    />
    <path
      d="M1108.21 212.937V205.344L1101.63 201.547L1095.05 205.344V212.937L1101.63 216.734L1108.21 212.937Z"
      fill="#DBDBDB"
    />
    <path
      d="M1123.01 212.937V205.344L1116.43 201.547L1109.85 205.344V212.937L1116.43 216.734L1123.01 212.937Z"
      fill="#DBDBDB"
    />
    <path
      d="M1093.52 238.112V230.504L1086.94 226.707L1080.37 230.504V238.112L1086.94 241.909L1093.52 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M1064.34 263.14V255.547L1057.76 251.75L1051.17 255.547V263.14L1057.76 266.952L1064.34 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 288.187V280.594L1028.36 276.797L1021.77 280.594V288.187L1028.36 291.984L1034.94 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M1195.88 285.84V278.246L1189.3 274.449L1182.71 278.246V285.84L1189.3 289.636L1195.88 285.84Z"
      fill="#DBDBDB"
    />
    <path
      d="M990.649 288.187V280.594L984.073 276.797L977.482 280.594V288.187L984.073 291.984L990.649 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 288.187V280.594L998.873 276.797L992.296 280.594V288.187L998.873 291.984L1005.45 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 288.187V280.594L1013.54 276.797L1006.97 280.594V288.187L1013.54 291.984L1020.14 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M1181.08 285.84V278.246L1174.49 274.449L1167.91 278.246V285.84L1174.49 289.636L1181.08 285.84Z"
      fill="#DBDBDB"
    />
    <path
      d="M1049.65 288.187V280.594L1043.07 276.797L1036.5 280.594V288.187L1043.07 291.984L1049.65 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M1064.34 288.187V280.594L1057.76 276.797L1051.17 280.594V288.187L1057.76 291.984L1064.34 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M1108.36 288.187V280.594L1101.79 276.797L1095.21 280.594V288.187L1101.79 291.984L1108.36 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M1078.89 288.187V280.594L1072.32 276.797L1065.72 280.594V288.187L1072.32 291.984L1078.89 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M1093.56 288.187V280.594L1086.99 276.797L1080.41 280.594V288.187L1086.99 291.984L1093.56 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M1123.09 288.187V280.594L1116.52 276.797L1109.93 280.594V288.187L1116.52 291.984L1123.09 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M1137.76 288.187V280.594L1131.19 276.797L1124.61 280.594V288.187L1131.19 291.984L1137.76 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M1152.45 288.187V280.594L1145.87 276.797L1139.3 280.594V288.187L1145.87 291.984L1152.45 288.187Z"
      fill="#DBDBDB"
    />
    <path
      d="M975.849 313.226V305.633L969.258 301.836L962.682 305.633V313.226L969.258 317.023L975.849 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 313.226V305.633L1028.36 301.836L1021.77 305.633V313.226L1028.36 317.023L1034.94 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M990.649 313.226V305.633L984.073 301.836L977.482 305.633V313.226L984.073 317.023L990.649 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 313.226V305.633L998.873 301.836L992.296 305.633V313.226L998.873 317.023L1005.45 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M1166.39 310.883V303.289L1159.82 299.492L1153.24 303.289V310.883L1159.82 314.679L1166.39 310.883Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 313.226V305.633L1013.54 301.836L1006.97 305.633V313.226L1013.54 317.023L1020.14 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M1181.08 310.883V303.289L1174.49 299.492L1167.91 303.289V310.883L1174.49 314.679L1181.08 310.883Z"
      fill="#DBDBDB"
    />
    <path
      d="M1049.65 313.226V305.633L1043.07 301.836L1036.5 305.633V313.226L1043.07 317.023L1049.65 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M1064.34 313.226V305.633L1057.76 301.836L1051.17 305.633V313.226L1057.76 317.023L1064.34 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M1049.65 338.273V330.68L1043.07 326.883L1036.5 330.68V338.273L1043.07 342.07L1049.65 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M1064.34 338.273V330.68L1057.76 326.883L1051.17 330.68V338.273L1057.76 342.07L1064.34 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M1079.01 313.226V305.633L1072.43 301.836L1065.85 305.633V313.226L1072.43 317.023L1079.01 313.226Z"
      fill="#DBDBDB"
    />
    <path
      d="M798.573 338.273V330.68L791.997 326.883L785.42 330.68V338.273L791.997 342.07L798.573 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M901.947 338.273V330.68L895.37 326.883L888.794 330.68V338.273L895.37 342.07L901.947 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 338.273V330.68L910.172 326.883L903.595 330.68V338.273L910.172 342.07L916.762 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M931.563 338.273V330.68L924.987 326.883L918.396 330.68V338.273L924.987 342.07L931.563 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M946.363 338.273V330.68L939.786 326.883L933.21 330.68V338.273L939.786 342.07L946.363 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M961.049 338.273V330.68L954.459 326.883L947.882 330.68V338.273L954.459 342.07L961.049 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M813.39 338.273V330.68L806.8 326.883L800.223 330.68V338.273L806.8 342.07L813.39 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M798.573 363.319V355.711L791.997 351.914L785.42 355.711V363.319L791.997 367.116L798.573 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M813.39 363.319V355.711L806.8 351.914L800.223 355.711V363.319L806.8 367.116L813.39 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M828.189 338.273V330.68L821.613 326.883L815.022 330.68V338.273L821.613 342.07L828.189 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M842.861 338.273V330.68L836.284 326.883L829.708 330.68V338.273L836.284 342.07L842.861 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M857.675 338.273V330.68L851.085 326.883L844.508 330.68V338.273L851.085 342.07L857.675 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M872.476 338.273V330.68L865.899 326.883L859.309 330.68V338.273L865.899 342.07L872.476 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M887.276 338.273V330.68L880.699 326.883L874.123 330.68V338.273L880.699 342.07L887.276 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M975.849 338.273V330.68L969.258 326.883L962.682 330.68V338.273L969.258 342.07L975.849 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 338.273V330.68L1028.36 326.883L1021.77 330.68V338.273L1028.36 342.07L1034.94 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M990.649 338.273V330.68L984.073 326.883L977.482 330.68V338.273L984.073 342.07L990.649 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 338.273V330.68L998.873 326.883L992.296 330.68V338.273L998.873 342.07L1005.45 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 338.273V330.68L1013.54 326.883L1006.97 330.68V338.273L1013.54 342.07L1020.14 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M901.947 363.319V355.711L895.37 351.914L888.794 355.711V363.319L895.37 367.116L901.947 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 363.319V355.711L910.172 351.914L903.595 355.711V363.319L910.172 367.116L916.762 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M901.947 388.347V380.754L895.37 376.957L888.794 380.754V388.347L895.37 392.159L901.947 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 388.347V380.754L910.172 376.957L903.595 380.754V388.347L910.172 392.159L916.762 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M901.947 413.394V405.801L895.37 402.004L888.794 405.801V413.394L895.37 417.191L901.947 413.394Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 413.394V405.801L910.172 402.004L903.595 405.801V413.394L910.172 417.191L916.762 413.394Z"
      fill="#DBDBDB"
    />
    <path
      d="M916.762 438.437V430.844L910.172 427.047L903.595 430.844V438.437L910.172 442.234L916.762 438.437Z"
      fill="#DBDBDB"
    />
    <path
      d="M931.563 363.319V355.711L924.987 351.914L918.396 355.711V363.319L924.987 367.116L931.563 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M946.363 363.319V355.711L939.786 351.914L933.21 355.711V363.319L939.786 367.116L946.363 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M961.049 363.319V355.711L954.459 351.914L947.882 355.711V363.319L954.459 367.116L961.049 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M887.276 363.319V355.711L880.699 351.914L874.123 355.711V363.319L880.699 367.116L887.276 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M975.849 363.319V355.711L969.258 351.914L962.682 355.711V363.319L969.258 367.116L975.849 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 363.319V355.711L1028.36 351.914L1021.77 355.711V363.319L1028.36 367.116L1034.94 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M990.649 363.319V355.711L984.073 351.914L977.482 355.711V363.319L984.073 367.116L990.649 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 363.319V355.711L998.873 351.914L992.296 355.711V363.319L998.873 367.116L1005.45 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 363.319V355.711L1013.54 351.914L1006.97 355.711V363.319L1013.54 367.116L1020.14 363.319Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 388.347V380.754L1028.36 376.957L1021.77 380.754V388.347L1028.36 392.159L1034.94 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 388.347V380.754L998.873 376.957L992.296 380.754V388.347L998.873 392.159L1005.45 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 388.347V380.754L1013.54 376.957L1006.97 380.754V388.347L1013.54 392.159L1020.14 388.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 463.394V455.801L1028.36 452.004L1021.77 455.801V463.394L1028.36 467.191L1034.94 463.394Z"
      fill="#DBDBDB"
    />
    <path
      id="public-map-indonesia-plastic"
      d="M1020.14 463.394V455.801L1013.54 452.004L1006.97 455.801V463.394L1013.54 467.191L1020.14 463.394Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 488.355V480.762L1028.36 476.965L1021.77 480.762V488.355L1028.36 492.152L1034.94 488.355Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 488.355V480.762L1013.54 476.965L1006.97 480.762V488.355L1013.54 492.152L1020.14 488.355Z"
      fill="#DBDBDB"
    />
    <path
      d="M1034.94 413.394V405.801L1028.36 402.004L1021.77 405.801V413.394L1028.36 417.191L1034.94 413.394Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 413.394V405.801L998.873 402.004L992.296 405.801V413.394L998.873 417.191L1005.45 413.394Z"
      fill="#DBDBDB"
    />
    <path
      d="M1049.65 513.543V505.949L1043.07 502.152L1036.5 505.949V513.543L1043.07 517.354L1049.65 513.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M1049.65 413.394V405.801L1043.07 402.004L1036.5 405.801V413.394L1043.07 417.191L1049.65 413.394Z"
      fill="#DBDBDB"
    />
    <path
      d="M1005.45 438.437V430.844L998.873 427.047L992.296 430.844V438.437L998.873 442.234L1005.45 438.437Z"
      fill="#DBDBDB"
    />
    <path
      d="M1020.14 438.437V430.844L1013.54 427.047L1006.97 430.844V438.437L1013.54 442.234L1020.14 438.437Z"
      fill="#DBDBDB"
    />
    <path
      d="M1079.11 438.437V430.844L1072.53 427.047L1065.96 430.844V438.437L1072.53 442.234L1079.11 438.437Z"
      fill="#DBDBDB"
    />
    <path
      id="public-map-philippines-plastic"
      d="M1093.79 438.437V430.844L1087.22 427.047L1080.64 430.844V438.437L1087.22 442.234L1093.79 438.437Z"
      fill="#DBDBDB"
    />
    <path
      d="M1079.11 463.394V455.801L1072.53 452.004L1065.96 455.801V463.394L1072.53 467.191L1079.11 463.394Z"
      fill="#DBDBDB"
    />
    <path
      d="M1064.32 463.394V455.801L1057.75 452.004L1051.17 455.801V463.394L1057.75 467.191L1064.32 463.394Z"
      fill="#DBDBDB"
    />
    <path
      d="M1093.79 463.394V455.801L1087.22 452.004L1080.64 455.801V463.394L1087.22 467.191L1093.79 463.394Z"
      fill="#DBDBDB"
    />
    <path
      d="M1064.34 513.543V505.949L1057.76 502.152L1051.17 505.949V513.543L1057.76 517.354L1064.34 513.543Z"
      fill="#DBDBDB"
    />
    <path
      d="M1079.01 338.273V330.68L1072.43 326.883L1065.85 330.68V338.273L1072.43 342.07L1079.01 338.273Z"
      fill="#DBDBDB"
    />
    <path
      d="M1079.01 263.14V255.547L1072.43 251.75L1065.85 255.547V263.14L1072.43 266.952L1079.01 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M1093.52 263.14V255.547L1086.94 251.75L1080.37 255.547V263.14L1086.94 266.952L1093.52 263.14Z"
      fill="#DBDBDB"
    />
    <path
      d="M1108.21 238.112V230.504L1101.63 226.707L1095.05 230.504V238.112L1101.63 241.909L1108.21 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M1123.01 238.112V230.504L1116.43 226.707L1109.85 230.504V238.112L1116.43 241.909L1123.01 238.112Z"
      fill="#DBDBDB"
    />
    <path
      d="M190.384 426.045V418.437L183.808 414.641L177.217 418.437V426.045L183.808 429.842L190.384 426.045Z"
      fill="#DBDBDB"
    />
    <path
      d="M205.184 426.045V418.437L198.608 414.641L192.031 418.437V426.045L198.608 429.842L205.184 426.045Z"
      fill="#DBDBDB"
    />
    <path
      d="M219.985 426.045V418.437L213.408 414.641L206.832 418.437V426.045L213.408 429.842L219.985 426.045Z"
      fill="#DBDBDB"
    />
    <path
      d="M234.785 426.045V418.437L228.209 414.641L221.632 418.437V426.045L228.209 429.842L234.785 426.045Z"
      fill="#DBDBDB"
    />
    <path
      d="M249.47 426.045V418.437L242.894 414.641L236.303 418.437V426.045L242.894 429.842L249.47 426.045Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.271 426.045V418.437L257.695 414.641L251.119 418.437V426.045L257.695 429.842L264.271 426.045Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.072 426.045V418.437L272.496 414.641L265.919 418.437V426.045L272.496 429.842L279.072 426.045Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.872 426.045V418.437L287.296 414.641L280.719 418.437V426.045L287.296 429.842L293.872 426.045Z"
      fill="#DBDBDB"
    />
    <path
      d="M205.184 451.347V443.754L198.608 439.957L192.031 443.754V451.347L198.608 455.144L205.184 451.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M219.985 451.347V443.754L213.408 439.957L206.832 443.754V451.347L213.408 455.144L219.985 451.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M234.785 451.347V443.754L228.209 439.957L221.632 443.754V451.347L228.209 455.144L234.785 451.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M249.47 451.347V443.754L242.894 439.957L236.303 443.754V451.347L242.894 455.144L249.47 451.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.271 451.347V443.754L257.695 439.957L251.119 443.754V451.347L257.695 455.144L264.271 451.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.072 451.347V443.754L272.496 439.957L265.919 443.754V451.347L272.496 455.144L279.072 451.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M308.802 451.347V443.754L302.226 439.957L295.649 443.754V451.347L302.226 455.144L308.802 451.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M323.601 451.347V443.754L317.025 439.957L310.449 443.754V451.347L317.025 455.144L323.601 451.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.872 451.347V443.754L287.296 439.957L280.719 443.754V451.347L287.296 455.144L293.872 451.347Z"
      fill="#DBDBDB"
    />
    <path
      d="M205.184 476.663V469.055L198.608 465.258L192.031 469.055V476.663L198.608 480.459L205.184 476.663Z"
      fill="#DBDBDB"
    />
    <path
      d="M190.384 476.663V469.055L183.808 465.258L177.217 469.055V476.663L183.808 480.459L190.384 476.663Z"
      fill="#DBDBDB"
    />
    <path
      d="M219.985 476.663V469.055L213.408 465.258L206.832 469.055V476.663L213.408 480.459L219.985 476.663Z"
      fill="#DBDBDB"
    />
    <path
      d="M234.785 476.663V469.055L228.209 465.258L221.632 469.055V476.663L228.209 480.459L234.785 476.663Z"
      fill="#DBDBDB"
    />
    <path
      d="M249.47 476.663V469.055L242.894 465.258L236.303 469.055V476.663L242.894 480.459L249.47 476.663Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.271 476.663V469.055L257.695 465.258L251.119 469.055V476.663L257.695 480.459L264.271 476.663Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.072 476.663V469.055L272.496 465.258L265.919 469.055V476.663L272.496 480.459L279.072 476.663Z"
      fill="#DBDBDB"
    />
    <path
      d="M308.802 476.663V469.055L302.226 465.258L295.649 469.055V476.663L302.226 480.459L308.802 476.663Z"
      fill="#DBDBDB"
    />
    <path
      d="M323.601 476.663V469.055L317.025 465.258L310.449 469.055V476.663L317.025 480.459L323.601 476.663Z"
      fill="#DBDBDB"
    />
    <path
      d="M338.331 476.663V469.055L331.741 465.258L325.164 469.055V476.663L331.741 480.459L338.331 476.663Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.872 476.663V469.055L287.296 465.258L280.719 469.055V476.663L287.296 480.459L293.872 476.663Z"
      fill="#DBDBDB"
    />
    <path
      d="M205.184 501.965V494.371L198.608 490.574L192.031 494.371V501.965L198.608 505.761L205.184 501.965Z"
      fill="#DBDBDB"
    />
    <path
      d="M219.985 501.965V494.371L213.408 490.574L206.832 494.371V501.965L213.408 505.761L219.985 501.965Z"
      fill="#DBDBDB"
    />
    <path
      d="M234.785 501.965V494.371L228.209 490.574L221.632 494.371V501.965L228.209 505.761L234.785 501.965Z"
      fill="#DBDBDB"
    />
    <path
      d="M249.47 501.965V494.371L242.894 490.574L236.303 494.371V501.965L242.894 505.761L249.47 501.965Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.271 501.965V494.371L257.695 490.574L251.119 494.371V501.965L257.695 505.761L264.271 501.965Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.072 501.965V494.371L272.496 490.574L265.919 494.371V501.965L272.496 505.761L279.072 501.965Z"
      fill="#DBDBDB"
    />
    <path
      d="M308.802 501.965V494.371L302.226 490.574L295.649 494.371V501.965L302.226 505.761L308.802 501.965Z"
      fill="#DBDBDB"
    />
    <path
      d="M323.601 501.965V494.371L317.025 490.574L310.449 494.371V501.965L317.025 505.761L323.601 501.965Z"
      fill="#DBDBDB"
    />
    <path
      d="M338.331 501.965V494.371L331.741 490.574L325.164 494.371V501.965L331.741 505.761L338.331 501.965Z"
      fill="#DBDBDB"
    />
    <path
      d="M353.045 501.965V494.371L346.469 490.574L339.893 494.371V501.965L346.469 505.761L353.045 501.965Z"
      fill="#DBDBDB"
    />
    <path
      d="M367.845 501.965V494.371L361.268 490.574L354.692 494.371V501.965L361.268 505.761L367.845 501.965Z"
      fill="#DBDBDB"
    />
    <path
      d="M382.574 501.965V494.371L375.997 490.574L369.407 494.371V501.965L375.997 505.761L382.574 501.965Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.872 501.965V494.371L287.296 490.574L280.719 494.371V501.965L287.296 505.761L293.872 501.965Z"
      fill="#DBDBDB"
    />
    <path
      d="M219.985 527.284V519.676L213.408 515.879L206.832 519.676V527.284L213.408 531.08L219.985 527.284Z"
      fill="#DBDBDB"
    />
    <path
      d="M234.785 527.284V519.676L228.209 515.879L221.632 519.676V527.284L228.209 531.08L234.785 527.284Z"
      fill="#DBDBDB"
    />
    <path
      d="M249.47 527.284V519.676L242.894 515.879L236.303 519.676V527.284L242.894 531.08L249.47 527.284Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.271 527.284V519.676L257.695 515.879L251.119 519.676V527.284L257.695 531.08L264.271 527.284Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.072 527.284V519.676L272.496 515.879L265.919 519.676V527.284L272.496 531.08L279.072 527.284Z"
      fill="#DBDBDB"
    />
    <path
      d="M308.802 527.284V519.676L302.226 515.879L295.649 519.676V527.284L302.226 531.08L308.802 527.284Z"
      fill="#DBDBDB"
    />
    <path
      d="M323.601 527.284V519.676L317.025 515.879L310.449 519.676V527.284L317.025 531.08L323.601 527.284Z"
      fill="#DBDBDB"
    />
    <path
      d="M338.331 527.284V519.676L331.741 515.879L325.164 519.676V527.284L331.741 531.08L338.331 527.284Z"
      fill="#DBDBDB"
    />
    <path
      d="M353.045 527.284V519.676L346.469 515.879L339.893 519.676V527.284L346.469 531.08L353.045 527.284Z"
      fill="#DBDBDB"
    />
    <path
      d="M367.845 527.284V519.676L361.268 515.879L354.692 519.676V527.284L361.268 531.08L367.845 527.284Z"
      fill="#DBDBDB"
    />
    <path
      d="M382.574 527.284V519.676L375.997 515.879L369.407 519.676V527.284L375.997 531.08L382.574 527.284Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.872 527.284V519.676L287.296 515.879L280.719 519.676V527.284L287.296 531.08L293.872 527.284Z"
      fill="#DBDBDB"
    />
    <path
      d="M234.785 552.586V544.992L228.209 541.195L221.632 544.992V552.586L228.209 556.382L234.785 552.586Z"
      fill="#DBDBDB"
    />
    <path
      d="M249.47 552.586V544.992L242.894 541.195L236.303 544.992V552.586L242.894 556.382L249.47 552.586Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.271 552.586V544.992L257.695 541.195L251.119 544.992V552.586L257.695 556.382L264.271 552.586Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.072 552.586V544.992L272.496 541.195L265.919 544.992V552.586L272.496 556.382L279.072 552.586Z"
      fill="#DBDBDB"
    />
    <path
      d="M308.802 552.586V544.992L302.226 541.195L295.649 544.992V552.586L302.226 556.382L308.802 552.586Z"
      fill="#DBDBDB"
    />
    <path
      d="M323.601 552.586V544.992L317.025 541.195L310.449 544.992V552.586L317.025 556.382L323.601 552.586Z"
      fill="#DBDBDB"
    />
    <path
      d="M338.331 552.586V544.992L331.741 541.195L325.164 544.992V552.586L331.741 556.382L338.331 552.586Z"
      fill="#DBDBDB"
    />
    <path
      d="M353.045 552.586V544.992L346.469 541.195L339.893 544.992V552.586L346.469 556.382L353.045 552.586Z"
      fill="#DBDBDB"
    />
    <path
      d="M367.845 552.586V544.992L361.268 541.195L354.692 544.992V552.586L361.268 556.382L367.845 552.586Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.872 552.586V544.992L287.296 541.195L280.719 544.992V552.586L287.296 556.382L293.872 552.586Z"
      fill="#DBDBDB"
    />
    <path
      d="M249.47 577.886V570.293L242.894 566.496L236.303 570.293V577.886L242.894 581.698L249.47 577.886Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.271 577.886V570.293L257.695 566.496L251.119 570.293V577.886L257.695 581.698L264.271 577.886Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.072 577.886V570.293L272.496 566.496L265.919 570.293V577.886L272.496 581.698L279.072 577.886Z"
      fill="#DBDBDB"
    />
    <path
      d="M308.802 577.886V570.293L302.226 566.496L295.649 570.293V577.886L302.226 581.698L308.802 577.886Z"
      fill="#DBDBDB"
    />
    <path
      d="M323.601 577.886V570.293L317.025 566.496L310.449 570.293V577.886L317.025 581.698L323.601 577.886Z"
      fill="#DBDBDB"
    />
    <path
      d="M338.331 577.886V570.293L331.741 566.496L325.164 570.293V577.886L331.741 581.698L338.331 577.886Z"
      fill="#DBDBDB"
    />
    <path
      d="M353.045 577.886V570.293L346.469 566.496L339.893 570.293V577.886L346.469 581.698L353.045 577.886Z"
      fill="#DBDBDB"
    />
    <path
      d="M367.845 577.886V570.293L361.268 566.496L354.692 570.293V577.886L361.268 581.698L367.845 577.886Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.872 577.886V570.293L287.296 566.496L280.719 570.293V577.886L287.296 581.698L293.872 577.886Z"
      fill="#DBDBDB"
    />
    <path
      d="M249.47 603.203V595.609L242.894 591.812L236.303 595.609V603.203L242.894 607L249.47 603.203Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.271 603.203V595.609L257.695 591.812L251.119 595.609V603.203L257.695 607L264.271 603.203Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.072 603.203V595.609L272.496 591.812L265.919 595.609V603.203L272.496 607L279.072 603.203Z"
      fill="#DBDBDB"
    />
    <path
      d="M308.802 603.203V595.609L302.226 591.812L295.649 595.609V603.203L302.226 607L308.802 603.203Z"
      fill="#DBDBDB"
    />
    <path
      d="M323.601 603.203V595.609L317.025 591.812L310.449 595.609V603.203L317.025 607L323.601 603.203Z"
      fill="#DBDBDB"
    />
    <path
      d="M338.331 603.203V595.609L331.741 591.812L325.164 595.609V603.203L331.741 607L338.331 603.203Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.872 603.203V595.609L287.296 591.812L280.719 595.609V603.203L287.296 607L293.872 603.203Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.271 628.508V620.914L257.695 617.117L251.119 620.914V628.508L257.695 632.319L264.271 628.508Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.072 628.508V620.914L272.496 617.117L265.919 620.914V628.508L272.496 632.319L279.072 628.508Z"
      fill="#DBDBDB"
    />
    <path
      d="M308.802 628.508V620.914L302.226 617.117L295.649 620.914V628.508L302.226 632.319L308.802 628.508Z"
      fill="#DBDBDB"
    />
    <path
      d="M323.601 628.508V620.914L317.025 617.117L310.449 620.914V628.508L317.025 632.319L323.601 628.508Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.872 628.508V620.914L287.296 617.117L280.719 620.914V628.508L287.296 632.319L293.872 628.508Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.271 653.828V646.234L257.695 642.438L251.119 646.234V653.828L257.695 657.625L264.271 653.828Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.072 653.828V646.234L272.496 642.438L265.919 646.234V653.828L272.496 657.625L279.072 653.828Z"
      fill="#DBDBDB"
    />
    <path
      d="M308.802 653.828V646.234L302.226 642.438L295.649 646.234V653.828L302.226 657.625L308.802 653.828Z"
      fill="#DBDBDB"
    />
    <path
      d="M293.872 653.828V646.234L287.296 642.438L280.719 646.234V653.828L287.296 657.625L293.872 653.828Z"
      fill="#DBDBDB"
    />
    <path
      d="M264.271 679.129V671.535L257.695 667.738L251.119 671.535V679.129L257.695 682.925L264.271 679.129Z"
      fill="#DBDBDB"
    />
    <path
      d="M279.072 679.129V671.535L272.496 667.738L265.919 671.535V679.129L272.496 682.925L279.072 679.129Z"
      fill="#DBDBDB"
    />
    <path
      d="M212.749 438.738V431.144L206.173 427.348L199.596 431.144V438.738L206.173 442.549L212.749 438.738Z"
      fill="#DBDBDB"
    />
    <path
      d="M227.55 438.738V431.144L220.974 427.348L214.397 431.144V438.738L220.974 442.549L227.55 438.738Z"
      fill="#DBDBDB"
    />
    <path
      d="M242.351 438.738V431.144L235.774 427.348L229.198 431.144V438.738L235.774 442.549L242.351 438.738Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.15 438.738V431.144L250.574 427.348L243.998 431.144V438.738L250.574 442.549L257.15 438.738Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.838 438.738V431.144L265.261 427.348L258.685 431.144V438.738L265.261 442.549L271.838 438.738Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.637 438.738V431.144L280.061 427.348L273.485 431.144V438.738L280.061 442.549L286.637 438.738Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.437 438.738V431.144L294.861 427.348L288.284 431.144V438.738L294.861 442.549L301.437 438.738Z"
      fill="#DBDBDB"
    />
    <path
      d="M316.251 438.738V431.144L309.66 427.348L303.084 431.144V438.738L309.66 442.549L316.251 438.738Z"
      fill="#DBDBDB"
    />
    <path
      d="M212.749 464.027V456.434L206.173 452.637L199.596 456.434V464.027L206.173 467.838L212.749 464.027Z"
      fill="#DBDBDB"
    />
    <path
      d="M197.776 464.027V456.434L191.2 452.637L184.624 456.434V464.027L191.2 467.838L197.776 464.027Z"
      fill="#DBDBDB"
    />
    <path
      d="M227.55 464.027V456.434L220.974 452.637L214.397 456.434V464.027L220.974 467.838L227.55 464.027Z"
      fill="#DBDBDB"
    />
    <path
      d="M242.351 464.027V456.434L235.774 452.637L229.198 456.434V464.027L235.774 467.838L242.351 464.027Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.15 464.027V456.434L250.574 452.637L243.998 456.434V464.027L250.574 467.838L257.15 464.027Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.838 464.027V456.434L265.261 452.637L258.685 456.434V464.027L265.261 467.838L271.838 464.027Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.637 464.027V456.434L280.061 452.637L273.485 456.434V464.027L280.061 467.838L286.637 464.027Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.437 464.027V456.434L294.861 452.637L288.284 456.434V464.027L294.861 467.838L301.437 464.027Z"
      fill="#DBDBDB"
    />
    <path
      d="M316.251 464.027V456.434L309.66 452.637L303.084 456.434V464.027L309.66 467.838L316.251 464.027Z"
      fill="#DBDBDB"
    />
    <path
      d="M330.98 464.027V456.434L324.403 452.637L317.827 456.434V464.027L324.403 467.838L330.98 464.027Z"
      fill="#DBDBDB"
    />
    <path
      d="M212.749 489.33V481.723L206.173 477.926L199.596 481.723V489.33L206.173 493.127L212.749 489.33Z"
      fill="#DBDBDB"
    />
    <path
      d="M197.776 489.33V481.723L191.2 477.926L184.624 481.723V489.33L191.2 493.127L197.776 489.33Z"
      fill="#DBDBDB"
    />
    <path
      d="M227.55 489.33V481.723L220.974 477.926L214.397 481.723V489.33L220.974 493.127L227.55 489.33Z"
      fill="#DBDBDB"
    />
    <path
      d="M242.351 489.33V481.723L235.774 477.926L229.198 481.723V489.33L235.774 493.127L242.351 489.33Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.15 489.33V481.723L250.574 477.926L243.998 481.723V489.33L250.574 493.127L257.15 489.33Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.838 489.33V481.723L265.261 477.926L258.685 481.723V489.33L265.261 493.127L271.838 489.33Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.637 489.33V481.723L280.061 477.926L273.485 481.723V489.33L280.061 493.127L286.637 489.33Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.437 489.33V481.723L294.861 477.926L288.284 481.723V489.33L294.861 493.127L301.437 489.33Z"
      fill="#DBDBDB"
    />
    <path
      d="M316.251 489.33V481.723L309.66 477.926L303.084 481.723V489.33L309.66 493.127L316.251 489.33Z"
      fill="#DBDBDB"
    />
    <path
      d="M330.98 489.33V481.723L324.403 477.926L317.827 481.723V489.33L324.403 493.127L330.98 489.33Z"
      fill="#DBDBDB"
    />
    <path
      d="M345.709 489.33V481.723L339.133 477.926L332.542 481.723V489.33L339.133 493.127L345.709 489.33Z"
      fill="#DBDBDB"
    />
    <path
      d="M360.51 489.33V481.723L353.934 477.926L347.357 481.723V489.33L353.934 493.127L360.51 489.33Z"
      fill="#DBDBDB"
    />
    <path
      d="M375.309 489.33V481.723L368.733 477.926L362.156 481.723V489.33L368.733 493.127L375.309 489.33Z"
      fill="#DBDBDB"
    />
    <path
      d="M212.749 514.62V507.012L206.173 503.215L199.596 507.012V514.62L206.173 518.416L212.749 514.62Z"
      fill="#DBDBDB"
    />
    <path
      d="M227.55 514.62V507.012L220.974 503.215L214.397 507.012V514.62L220.974 518.416L227.55 514.62Z"
      fill="#DBDBDB"
    />
    <path
      d="M242.351 514.62V507.012L235.774 503.215L229.198 507.012V514.62L235.774 518.416L242.351 514.62Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.15 514.62V507.012L250.574 503.215L243.998 507.012V514.62L250.574 518.416L257.15 514.62Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.838 514.62V507.012L265.261 503.215L258.685 507.012V514.62L265.261 518.416L271.838 514.62Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.637 514.62V507.012L280.061 503.215L273.485 507.012V514.62L280.061 518.416L286.637 514.62Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.437 514.62V507.012L294.861 503.215L288.284 507.012V514.62L294.861 518.416L301.437 514.62Z"
      fill="#DBDBDB"
    />
    <path
      d="M316.251 514.62V507.012L309.66 503.215L303.084 507.012V514.62L309.66 518.416L316.251 514.62Z"
      fill="#DBDBDB"
    />
    <path
      d="M330.98 514.62V507.012L324.403 503.215L317.827 507.012V514.62L324.403 518.416L330.98 514.62Z"
      fill="#DBDBDB"
    />
    <path
      d="M345.709 514.62V507.012L339.133 503.215L332.542 507.012V514.62L339.133 518.416L345.709 514.62Z"
      fill="#DBDBDB"
    />
    <path
      d="M360.51 514.62V507.012L353.934 503.215L347.357 507.012V514.62L353.934 518.416L360.51 514.62Z"
      fill="#DBDBDB"
    />
    <path
      d="M375.309 514.62V507.012L368.733 503.215L362.156 507.012V514.62L368.733 518.416L375.309 514.62Z"
      fill="#DBDBDB"
    />
    <path
      id="public-map-brazil-plastic"
      d="M389.996 514.62V507.012L383.42 503.215L376.829 507.012V514.62L383.42 518.416L389.996 514.62Z"
      fill="#DBDBDB"
    />
    <path
      d="M227.55 539.905V532.297L220.974 528.5L214.397 532.297V539.905L220.974 543.702L227.55 539.905Z"
      fill="#DBDBDB"
    />
    <path
      d="M242.351 539.905V532.297L235.774 528.5L229.198 532.297V539.905L235.774 543.702L242.351 539.905Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.15 539.905V532.297L250.574 528.5L243.998 532.297V539.905L250.574 543.702L257.15 539.905Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.838 539.905V532.297L265.261 528.5L258.685 532.297V539.905L265.261 543.702L271.838 539.905Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.637 539.905V532.297L280.061 528.5L273.485 532.297V539.905L280.061 543.702L286.637 539.905Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.437 539.905V532.297L294.861 528.5L288.284 532.297V539.905L294.861 543.702L301.437 539.905Z"
      fill="#DBDBDB"
    />
    <path
      d="M316.251 539.905V532.297L309.66 528.5L303.084 532.297V539.905L309.66 543.702L316.251 539.905Z"
      fill="#DBDBDB"
    />
    <path
      d="M330.98 539.905V532.297L324.403 528.5L317.827 532.297V539.905L324.403 543.702L330.98 539.905Z"
      fill="#DBDBDB"
    />
    <path
      d="M345.709 539.905V532.297L339.133 528.5L332.542 532.297V539.905L339.133 543.702L345.709 539.905Z"
      fill="#DBDBDB"
    />
    <path
      d="M360.51 539.905V532.297L353.934 528.5L347.357 532.297V539.905L353.934 543.702L360.51 539.905Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.15 565.194V557.586L250.574 553.789L243.998 557.586V565.194L250.574 568.991L257.15 565.194Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.838 565.194V557.586L265.261 553.789L258.685 557.586V565.194L265.261 568.991L271.838 565.194Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.637 565.194V557.586L280.061 553.789L273.485 557.586V565.194L280.061 568.991L286.637 565.194Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.437 565.194V557.586L294.861 553.789L288.284 557.586V565.194L294.861 568.991L301.437 565.194Z"
      fill="#DBDBDB"
    />
    <path
      d="M316.251 565.194V557.586L309.66 553.789L303.084 557.586V565.194L309.66 568.991L316.251 565.194Z"
      fill="#DBDBDB"
    />
    <path
      d="M330.98 565.194V557.586L324.403 553.789L317.827 557.586V565.194L324.403 568.991L330.98 565.194Z"
      fill="#DBDBDB"
    />
    <path
      d="M345.709 565.194V557.586L339.133 553.789L332.542 557.586V565.194L339.133 568.991L345.709 565.194Z"
      fill="#DBDBDB"
    />
    <path
      d="M360.51 565.194V557.586L353.934 553.789L347.357 557.586V565.194L353.934 568.991L360.51 565.194Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.15 590.483V582.875L250.574 579.078L243.998 582.875V590.483L250.574 594.28L257.15 590.483Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.838 590.483V582.875L265.261 579.078L258.685 582.875V590.483L265.261 594.28L271.838 590.483Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.637 590.483V582.875L280.061 579.078L273.485 582.875V590.483L280.061 594.28L286.637 590.483Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.437 590.483V582.875L294.861 579.078L288.284 582.875V590.483L294.861 594.28L301.437 590.483Z"
      fill="#DBDBDB"
    />
    <path
      d="M316.251 590.483V582.875L309.66 579.078L303.084 582.875V590.483L309.66 594.28L316.251 590.483Z"
      fill="#DBDBDB"
    />
    <path
      d="M330.98 590.483V582.875L324.403 579.078L317.827 582.875V590.483L324.403 594.28L330.98 590.483Z"
      fill="#DBDBDB"
    />
    <path
      id="public-map-chile-carbon"
      d="M257.15 615.768V608.16L250.574 604.363L243.998 608.16V615.768L250.574 619.565L257.15 615.768Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.838 615.768V608.16L265.261 604.363L258.685 608.16V615.768L265.261 619.565L271.838 615.768Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.637 615.768V608.16L280.061 604.363L273.485 608.16V615.768L280.061 619.565L286.637 615.768Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.437 615.768V608.16L294.861 604.363L288.284 608.16V615.768L294.861 619.565L301.437 615.768Z"
      fill="#DBDBDB"
    />
    <path
      d="M316.251 615.768V608.16L309.66 604.363L303.084 608.16V615.768L309.66 619.565L316.251 615.768Z"
      fill="#DBDBDB"
    />
    <path
      d="M330.98 615.768V608.16L324.403 604.363L317.827 608.16V615.768L324.403 619.565L330.98 615.768Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.15 641.057V633.449L250.574 629.652L243.998 633.449V641.057L250.574 644.854L257.15 641.057Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.838 641.057V633.449L265.261 629.652L258.685 633.449V641.057L265.261 644.854L271.838 641.057Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.637 641.057V633.449L280.061 629.652L273.485 633.449V641.057L280.061 644.854L286.637 641.057Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.437 641.057V633.449L294.861 629.652L288.284 633.449V641.057L294.861 644.854L301.437 641.057Z"
      fill="#DBDBDB"
    />
    <path
      d="M316.251 641.057V633.449L309.66 629.652L303.084 633.449V641.057L309.66 644.854L316.251 641.057Z"
      fill="#DBDBDB"
    />
    <path
      d="M257.15 666.35V658.742L250.574 654.945L243.998 658.742V666.35L250.574 670.147L257.15 666.35Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.838 666.35V658.742L265.261 654.945L258.685 658.742V666.35L265.261 670.147L271.838 666.35Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.637 666.35V658.742L280.061 654.945L273.485 658.742V666.35L280.061 670.147L286.637 666.35Z"
      fill="#DBDBDB"
    />
    <path
      d="M301.437 666.35V658.742L294.861 654.945L288.284 658.742V666.35L294.861 670.147L301.437 666.35Z"
      fill="#DBDBDB"
    />
    <path
      d="M271.838 691.635V684.027L265.261 680.23L258.685 684.027V691.635L265.261 695.432L271.838 691.635Z"
      fill="#DBDBDB"
    />
    <path
      d="M286.637 691.635V684.027L280.061 680.23L273.485 684.027V691.635L280.061 695.432L286.637 691.635Z"
      fill="#DBDBDB"
    />
    <path
      d="M1095.56 528.953V521.359L1088.97 517.562L1082.39 521.359V528.953L1088.97 532.764L1095.56 528.953Z"
      fill="#DBDBDB"
    />
    <path
      d="M1095.56 554V546.406L1088.97 542.609L1082.39 546.406V554L1088.97 557.797L1095.56 554Z"
      fill="#DBDBDB"
    />
    <path
      d="M1110.36 528.953V521.359L1103.79 517.562L1097.19 521.359V528.953L1103.79 532.764L1110.36 528.953Z"
      fill="#DBDBDB"
    />
    <path
      d="M1125.16 528.953V521.359L1118.59 517.562L1112.01 521.359V528.953L1118.59 532.764L1125.16 528.953Z"
      fill="#DBDBDB"
    />
    <path
      d="M1139.85 528.953V521.359L1133.26 517.562L1126.68 521.359V528.953L1133.26 532.764L1139.85 528.953Z"
      fill="#DBDBDB"
    />
    <path
      d="M1088.15 541.433V533.84L1081.58 530.043L1075 533.84V541.433L1081.58 545.244L1088.15 541.433Z"
      fill="#DBDBDB"
    />
    <path
      d="M1102.95 541.433V533.84L1096.38 530.043L1089.8 533.84V541.433L1096.38 545.244L1102.95 541.433Z"
      fill="#DBDBDB"
    />
    <path
      d="M1117.75 541.433V533.84L1111.18 530.043L1104.6 533.84V541.433L1111.18 545.244L1117.75 541.433Z"
      fill="#DBDBDB"
    />
    <path
      d="M1132.44 516.405V508.797L1125.86 505L1119.29 508.797V516.405L1125.86 520.202L1132.44 516.405Z"
      fill="#DBDBDB"
    />
    <path
      d="M1132.44 541.433V533.84L1125.86 530.043L1119.29 533.84V541.433L1125.86 545.244L1132.44 541.433Z"
      fill="#DBDBDB"
    />
    <path
      d="M1147.24 541.433V533.84L1140.66 530.043L1134.09 533.84V541.433L1140.66 545.244L1147.24 541.433Z"
      fill="#DBDBDB"
    />
    <path
      d="M1117.75 566.472V558.879L1111.18 555.082L1104.6 558.879V566.472L1111.18 570.269L1117.75 566.472Z"
      fill="#DBDBDB"
    />
    <path
      d="M1176.77 566.472V558.879L1170.19 555.082L1163.6 558.879V566.472L1170.19 570.269L1176.77 566.472Z"
      fill="#DBDBDB"
    />
    <path
      d="M1132.44 566.472V558.879L1125.86 555.082L1119.29 558.879V566.472L1125.86 570.269L1132.44 566.472Z"
      fill="#DBDBDB"
    />
    <path
      d="M1176.77 591.523V583.93L1170.19 580.133L1163.6 583.93V591.523L1170.19 595.32L1176.77 591.523Z"
      fill="#DBDBDB"
    />
    <path
      d="M1161.97 591.523V583.93L1155.38 580.133L1148.8 583.93V591.523L1155.38 595.32L1161.97 591.523Z"
      fill="#DBDBDB"
    />
    <path
      d="M1110.36 554V546.406L1103.79 542.609L1097.19 546.406V554L1103.79 557.797L1110.36 554Z"
      fill="#DBDBDB"
    />
    <path
      d="M1125.16 554V546.406L1118.59 542.609L1112.01 546.406V554L1118.59 557.797L1125.16 554Z"
      fill="#DBDBDB"
    />
    <path
      d="M1139.85 554V546.406L1133.26 542.609L1126.68 546.406V554L1133.26 557.797L1139.85 554Z"
      fill="#DBDBDB"
    />
    <path
      d="M1184.05 554V546.406L1177.47 542.609L1170.88 546.406V554L1177.47 557.797L1184.05 554Z"
      fill="#DBDBDB"
    />
    <path
      d="M1125.16 579.039V571.445L1118.59 567.648L1112.01 571.445V579.039L1118.59 582.836L1125.16 579.039Z"
      fill="#DBDBDB"
    />
    <path
      d="M1184.05 579.039V571.445L1177.47 567.648L1170.88 571.445V579.039L1177.47 582.836L1184.05 579.039Z"
      fill="#DBDBDB"
    />
    <path
      d="M1154.65 604.086V596.492L1148.07 592.695L1141.48 596.492V604.086L1148.07 607.883L1154.65 604.086Z"
      fill="#DBDBDB"
    />
  </svg>
</template>
