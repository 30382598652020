import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1042e676"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "tooltip-content" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_menu, {
      "open-on-hover": !_ctx.isTouch,
      "open-on-click": _ctx.isTouch,
      location: "top",
      offset: "5 0",
      "close-delay": "250",
      "content-class": "elevation-1"
    }, {
      activator: _withCtx(({ props }) => [
        _createElementVNode("img", _mergeProps({
          class: "tooltip-icon",
          src: require('@/assets/icons/info.svg'),
          alt: "tooltip icon"
        }, props), null, 16, _hoisted_2)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "tooltip-text",
            innerHTML: _ctx.content
          }, null, 8, _hoisted_4)
        ])
      ]),
      _: 1
    }, 8, ["open-on-hover", "open-on-click"])
  ]))
}