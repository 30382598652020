import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d535388"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "reset" }
const _hoisted_2 = { class: "reset-wrapper" }
const _hoisted_3 = { class: "logo-wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "password-reset-input-wrapper" }
const _hoisted_7 = { class: "button-wrapper" }
const _hoisted_8 = {
  key: 1,
  class: "email-sent"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_form, {
        class: "reset-form",
        autocomplete: "on"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "logo",
              src: require('@/assets/greenspark-logo.svg'),
              alt: "greenspark-app"
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('forgot_password')), 1),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_v_text_field, {
              id: "Password",
              modelValue: _ctx.password,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
              class: "input-field",
              name: "password",
              autocomplete: "password",
              type: _ctx.showPassword ? 'text' : 'password',
              label: _ctx.t('password'),
              rules: [_ctx.rules.password, _ctx.rules.required],
              "append-inner-icon": _ctx.showPassword ? 'mdi-eye' : 'mdi-eye-off',
              variant: "outlined",
              flat: "",
              "hide-details": "",
              color: "#3B755F",
              "onClick:appendInner": _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword))
            }, null, 8, ["modelValue", "type", "label", "rules", "append-inner-icon"])
          ]),
          (_ctx.hasPasswordError)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                style: _normalizeStyle(_ctx.hasPasswordError ? 'background: red; color: #F9F9F9' : ''),
                class: "password-hint"
              }, _toDisplayString(_ctx.t('password_hint')), 5))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_gs_button, {
              "full-width": true,
              size: "large",
              onClick: _withModifiers(_ctx.resetPassword, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('forgot_password')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          (_ctx.passwordReset)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createTextVNode(_toDisplayString(_ctx.t('error')) + " ", 1),
                _createElementVNode("span", {
                  class: "email-sent-link",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/reset-password')))
                }, "Request New Link")
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "login",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/login')))
          }, _toDisplayString(_ctx.t('login')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}