<template>
  <v-card class="request-integration">
    <h3 class="request-integration__title">Integration Request</h3>
    <p class="request-integration__desc">
      We are constantly adding new integrations. Please give us more details on what you what you
      want to achieve below.
    </p>
    <v-form v-model="isFormValid">
      <div class="form-wrapper">
        <gs-input v-model="payload.name" label="Integration name" />
        <gs-input
          v-model="payload.description"
          label="What do you want to achieve with this integration?"
        />
        <gs-input
          v-model="payload.impact"
          label="Estimated monthly impact generated using this integration"
        />
        <gs-button full-width size="large" @click.prevent="sendRequestedIntegrationForm">
          Invite
        </gs-button>
      </div>
    </v-form>
    <button class="close-request-integration-dialog" @click.prevent="close">
      <v-icon class="close-request-integration-dialog__icon"> mdi-close </v-icon>
    </button>
  </v-card>
</template>

<script lang="ts">
import type { Dialog } from '@/store/dialog'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RequestIntegrationDialog',
  data() {
    return {
      payload: {
        name: '',
        description: '',
        impact: '',
      },
      isFormValid: true,
    }
  },
  methods: {
    t(key: string) {
      return this.$t(`RequestIntegrationDialog.${key}`)
    },
    sendRequestedIntegrationForm() {},
  },
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
    close: {
      type: Function as PropType<() => void>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.request-integration__title,
.request-integration__desc {
  padding-right: 40px;
}

.request-integration {
  padding: 20px;
  position: relative;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
}

.close-request-integration-dialog {
  position: absolute;
  top: 20px;
  right: 20px;
}

.close-request-integration-dialog__icon {
  color: var(--ui-black);
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
}
</style>
