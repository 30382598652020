<template>
  <div class="update-wrapper">
    <!-- Upgrades -->
    <div class="subheader">
      {{ t('your_upgrades') }}
    </div>

    <!-- Plastic Heroes -->
    <div class="plan-name-total">
      <div class="plan">
        <span class="number">{{ plasticHero }}</span>
        <span class="x">x</span>
        <div>{{ t('plastic_hero') }}</div>

        <Tooltip
          class="tooltip"
          :body="t('plastic_hero_tooltip')"
          :link-text="'Source'"
          :link="'https://ourworldindata.org/plastic-pollution'"
        />

        <div class="pending-number">
          {{ t('pending', { count: pendingPlasticHero }) }}
        </div>
      </div>

      <div class="text-field-wrapper">
        <v-text-field
          v-model.number="addPlasticHero"
          :label="t('adjust_plastic_hero')"
          variant="outlined"
          clearable
          type="number"
          flat
          color="#3B755F"
          class="employee-input"
        />
        <div class="plus-minus">
          <div
            :class="'add'"
            style="margin-bottom: 1px"
            @click="addPlasticHero ? addPlasticHero++ : (addPlasticHero = 1)"
          >
            +
          </div>
          <div
            :class="['add', { disabled: isPlasticHeroMinusDisabled }]"
            style="margin-top: 1px"
            @click="!isPlasticHeroMinusDisabled ? addPlasticHero && addPlasticHero-- : null"
          >
            -
          </div>
        </div>
      </div>
    </div>

    <!-- Tree Planters -->
    <div class="plan-name-total">
      <div class="plan">
        <span class="number">{{ treePlanter }}</span>
        <span class="x">x</span>
        <div class="tree-text">
          {{ t('tree_planter') }}
        </div>

        <Tooltip class="tooltip" :body="t('trees_tooltip_new')" />

        <div class="pending-number">
          {{ t('pending', { count: pendingTreePlanter }) }}
        </div>
      </div>

      <div class="text-field-wrapper">
        <v-text-field
          v-model.number="addTreePlanter"
          :label="t('adjust_tree_planter')"
          variant="outlined"
          clearable
          type="number"
          flat
          color="#3B755F"
          class="employee-input"
        />
        <div class="plus-minus">
          <div
            :class="'add'"
            style="margin-bottom: 1px"
            @click="addTreePlanter ? addTreePlanter++ : (addTreePlanter = 1)"
          >
            +
          </div>
          <div
            :class="['add', { disabled: isTreePlanterMinusDisabled }]"
            style="margin-top: 1px"
            @click="!isTreePlanterMinusDisabled ? addTreePlanter && addTreePlanter-- : null"
          >
            -
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Tooltip from '@/components/tools/Tooltip.vue'
import type { Account } from '@/store'
import type { Currency, CurrencyCode } from '@/helpers/interfaces'
import type { SubscriptionItem } from '@/store/subscriptions'
import type { ProjectId } from '@/helpers/constants'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Upgrades',
  emits: ['getUpdatedValues'],
  components: {
    Tooltip,
  },
  data() {
    return {
      addTreePlanter: null,
      addPlasticHero: null,
    } as {
      addPlasticHero: number | null
      addTreePlanter: number | null
    }
  },
  computed: {
    isPlasticHeroMinusDisabled(): boolean {
      return (
        !(this.plasticHero + this.pendingPlasticHero) ||
        this.getIsTreePlanterNotDecreasable(Number(this.addPlasticHero) - 1)
      )
    },
    isTreePlanterMinusDisabled(): boolean {
      return (
        !(this.treePlanter + this.pendingTreePlanter) ||
        this.getIsTreePlanterNotDecreasable(Number(this.addTreePlanter) - 1)
      )
    },
    getUserCurrency(): Currency {
      return this.$store.getters['getUserCurrency']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    getUserCurrencyCode(): CurrencyCode {
      return this.$store.getters['getUserCurrencyCode']
    },
    getActiveSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getActiveSubscriptionItem']
    },
    getProjectPriceById(): (projectId: ProjectId, freeBusinessPrice?: boolean) => number {
      return this.$store.getters['getProjectPriceById']
    },
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`Upgrades.${key}`, params ?? {})
    },
    getIsPlasticHeroNotDecreasable(plasticHero?: number | null) {
      return (
        -Math.abs(this.plasticHero + Number(this.account.temporaryUpgrades.plasticHero)) >
        Number(plasticHero ?? this.addPlasticHero)
      )
    },
    getIsTreePlanterNotDecreasable(treePlanter?: number | null) {
      return (
        -Math.abs(this.treePlanter + Number(this.account.temporaryUpgrades.treePlanter)) >
        Number(treePlanter ?? this.addTreePlanter)
      )
    },
    onResetInputsChange() {
      if (this.resetInputs) {
        this.addTreePlanter = null
        this.addPlasticHero = null
      }
    },
    onAddTreesChange() {
      const amount = this.getIsTreePlanterNotDecreasable() ? null : this.addTreePlanter || 0
      this.$emit('getUpdatedValues', amount, 'treePlanter')
      this.addTreePlanter = amount
    },
    onAddPlasticChange() {
      const amount = this.getIsPlasticHeroNotDecreasable() ? null : this.addPlasticHero || 0
      this.$emit('getUpdatedValues', amount, 'plasticHero')
      this.addPlasticHero = amount
    },
  },
  props: {
    treePlanter: { required: true, type: Number, default: 0 },
    plasticHero: { required: true, type: Number, default: 0 },
    pendingTreePlanter: { required: true, type: Number, default: 0 },
    pendingPlasticHero: { required: true, type: Number, default: 0 },
    resetInputs: { required: true, type: Boolean, default: false },
  },
  watch: {
    resetInputs: [
      {
        handler: 'onResetInputsChange',
      },
    ],
    addTreePlanter: [
      {
        handler: 'onAddTreesChange',
      },
    ],
    addPlasticHero: [
      {
        handler: 'onAddPlasticChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.update-wrapper {
  padding: 25px 30px 30px;
  width: 100%;
  position: relative;
}

.subheader {
  color: var(--ui-black);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 18px;
}

.plan-name-total {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 22px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan {
  color: var(--ui-black);
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.x {
  margin-left: 10px;
  margin-right: 10px;
}

.number {
  color: var(--ui-black);
  font-weight: 600;
  font-size: 22px;
  margin-bottom: -3px;
}

.tooltip {
  margin-left: 5px;
}

.pending-number {
  font-size: 15px;
  color: var(--gray-light-40);
  margin-left: 8px;
  font-weight: normal;
  min-width: 85px;
}

.text-field-wrapper {
  display: flex;
  width: 250px;
  min-width: 250px;
}

.plus-minus {
  min-width: 10%;
  cursor: pointer;
  height: 56px;
  margin-left: 6px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}

.add {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ui-white);
  font-weight: 600;
  background: var(--ui-green);
  font-size: 18px;
  height: 28px;
  width: 28px;
  border-radius: 50%;
}

.add.disabled {
  background: var(--gray);
  cursor: not-allowed;
}

@media screen and (max-width: 930px) {
  .tree-text {
    max-width: 150px;
  }
}

@media screen and (max-width: 530px) {
  .plan-name-total {
    flex-direction: column;
  }

  .plan {
    margin-bottom: 18px;
  }

  .tree-text {
    max-width: 150px;
  }
}

@media screen and (max-width: 400px) {
  .tree-text {
    max-width: 150px;
  }
}
</style>
