<template>
  <div>
    <div class="top-stat-wrapper">
      <div class="stat-wrapper">
        <StatCard
          v-for="({ type, amount }, index) in totalImpactsArray"
          :key="index"
          :class="['stat-card', type]"
          :label="t(type)"
          :model-value="formatTotalAmounts(amount)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import StatCard from '@/components/PublicImpactDashboard/StatCard.vue'
import type { TotalImpact } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Stats',
  components: {
    StatCard,
  },
  mixins: [Utils],
  methods: {
    t(key: string) {
      return this.$t(`Stats.${key}`)
    },
  },
  props: {
    totalImpactsArray: {
      type: Array as PropType<TotalImpact[]>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.top-stat-wrapper {
  margin-top: 35px;

  padding: 20px;
  background: var(--ui-white);
  box-shadow: var(--box-shadow-wide);
  border-radius: 6.19184px;
}

.header {
  color: #212121;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.stat-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}

.stat-card.carbon {
  background-image: url('../../assets/gifs/comparisons/stat_carbon_background.svg');
  background-color: var(--ui-beige);
}

.stat-card.plastic {
  background-image: url('../../assets/gifs/comparisons/stat_plastic_background.svg');
  background-color: var(--ui-beige);
}

.stat-card.kelp {
  background-image: url('../../assets/gifs/comparisons/stat_kelp_background.svg');
  background-color: var(--ui-beige);
}

.stat-card.trees {
  background-image: url('../../assets/gifs/comparisons/stat_tree_background.svg');
  background-color: var(--ui-beige);
}

.stat.kelp {
  background-image: url('../../assets/gifs/comparisons/stat_kelp_background.svg');
  background-color: var(--ui-beige);
  margin-right: 0;
}

.earth-positive-card {
  background: var(--ui-beige);
}

@media #{map-get($display-breakpoints, 'xs')} {
  .stat-wrapper {
    flex-direction: column;
  }
}
</style>
