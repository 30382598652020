<script lang="ts">
import type { OffsetType, PlasticComparison } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PlasticComparison',
  mixins: [Utils],
  methods: {
    t(key: string) {
      return this.$t(`PlasticComparison.${key}`)
    },
  },
  props: {
    comparisons: {
      required: true,
      type: Object as PropType<PlasticComparison>,
    },
    colorSettings: {
      required: true,
      type: Object as PropType<Record<OffsetType, string>>,
    },
  },
})
</script>

<template>
  <div class="plastic-comparison-container">
    <div class="straws comparison-card" :style="{ 'background-color': colorSettings.plastic }">
      <div class="comparison-data">
        <div class="comparison-amount">
          {{ formatTotalAmounts(comparisons.straws.value) }}
        </div>
        <div class="info-text">
          {{ t('text.straws') }}
        </div>
        <a :href="comparisons.straws.link" target="_blank" class="info-sub-text">
          {{ t('sub_text.straws') }}
        </a>
      </div>
      <div class="comparison-icon-wrapper">
        <img
          id="strawsComparisonIcon"
          class="comparison-icon"
          alt="straws"
          :src="require('@/assets/icons/comparisons/straws_v2.svg')"
        />
      </div>
    </div>
    <div class="coffee-cups comparison-card" :style="{ 'background-color': colorSettings.plastic }">
      <div class="comparison-icon-wrapper">
        <img
          id="coffeeCupsComparisonIcon"
          class="comparison-icon"
          alt="coffee-cups"
          :src="require('@/assets/icons/comparisons/coffee_cups_v2.svg')"
        />
      </div>
      <div class="comparison-data">
        <div class="comparison-amount">
          {{ formatTotalAmounts(comparisons.coffeeCups.value) }}
        </div>
        <div class="info-text">
          {{ t('text.coffee_cups') }}
        </div>
        <a :href="comparisons.coffeeCups.link" target="_blank" class="info-sub-text">
          {{ t('sub_text.coffee_cups') }}
        </a>
      </div>
    </div>
    <div class="bathtubs comparison-card" :style="{ 'background-color': colorSettings.plastic }">
      <div class="comparison-data">
        <div class="comparison-amount">
          {{ formatTotalAmounts(comparisons.bathtubs.value) }}
        </div>
        <div class="info-text">
          {{ t('text.bathtubs') }}
        </div>
        <a :href="comparisons.bathtubs.link" target="_blank" class="info-sub-text">
          {{ t('sub_text.bathtubs') }}
        </a>
      </div>
      <div class="comparison-icon-wrapper">
        <img
          id="bathtubsComparisonIcon"
          class="comparison-icon"
          alt="area planted"
          :src="require('@/assets/icons/comparisons/bathtubs_v2.svg')"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '~vuetify/settings';

.plastic-comparison-container {
  display: grid;
  gap: 16px;
}

.comparison-card {
  display: grid;
  height: 170px;
  border-radius: 10px;
}

.comparison-card.straws {
  grid-template-columns: auto 100px;
  padding: 6px 11px 6px 22px;
}

.comparison-card.bathtubs {
  grid-template-columns: auto 100px;
  padding: 6px 11px 6px 22px;
}

.comparison-card.coffee-cups {
  grid-template-columns: 100px auto;
  padding: 6px 22px 6px 11px;
}

.comparison-data {
  font-family: Cabin;
  display: grid;
  align-content: center;
}

.comparison-card:nth-child(even) .comparison-data {
  text-align: right;
}

.comparison-amount {
  color: var(--font-color-primary);
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
}

.info-text {
  line-height: normal;
  font-size: 16px;
  margin-bottom: 6px;
}

.info-sub-text {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  color: #212121;
}

.comparison-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.comparison-icon {
  position: relative;
}

.straws .comparison-icon {
  height: 110px;
  left: 4px;
}

.coffee-cups .comparison-icon {
  height: 90px;
  right: 6px;
}

.bathtubs .comparison-icon {
  height: 88px;
  left: 6px;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .straws.comparison-card {
    grid-template-columns: auto 140px;
  }

  .bathtubs.comparison-card {
    grid-template-columns: auto 140px;
  }

  .coffee-cups.comparison-card {
    grid-template-columns: 120px auto;
  }

  .straws .comparison-icon {
    height: 118px;
    left: unset;
  }

  .coffee-cups .comparison-icon {
    height: 86px;
    right: unset;
  }

  .bathtubs .comparison-icon {
    height: 120px;
    left: unset;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .plastic-comparison-container {
    gap: 26px;
  }

  .straws.comparison-card {
    grid-template-columns: auto 150px;
  }

  .bathtubs.comparison-card {
    grid-template-columns: auto 200px;
  }

  .coffee-cups.comparison-card {
    grid-template-columns: 200px auto;
  }

  .straws .comparison-icon {
    height: 130px;
  }

  .coffee-cups .comparison-icon {
    height: 126px;
  }

  .bathtubs .comparison-icon {
    height: 154px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .straws.comparison-card,
  .coffee-cups.comparison-card {
    height: 180px;
  }

  .bathtubs.comparison-card {
    height: 360px;
    margin-bottom: auto;
  }

  .plastic-comparison-container {
    gap: 32px;
    padding-bottom: 40px;
  }

  .straws.comparison-card,
  .coffee-cups.comparison-card {
    grid-column: 2 / span 2;
    height: 168px;
  }

  .straws.comparison-card {
    grid-row: 2;
  }

  .bathtubs.comparison-card {
    grid-column: 1;
    grid-row: 1 / span 2;
    height: 320px;
    grid-template-columns: auto;
    grid-template-rows: auto 100px;
    gap: 16px;
    padding: 22px 40px 0 40px;
    width: 314px;
  }

  .coffee-cups.comparison-card {
    grid-row: 1;
  }

  .straws.comparison-card .comparison-data {
    grid-template-columns: 1fr auto;
    column-gap: 16px;
  }

  .coffee-cups.comparison-card .comparison-data {
    grid-template-columns: auto 1fr;
    column-gap: 16px;
  }

  .straws.comparison-card .comparison-data .info-sub-text {
    grid-column: 2;
  }

  .coffee-cups.comparison-card .comparison-data .info-sub-text {
    grid-column: 1 / span 2;
  }

  .straws.comparison-card .comparison-amount,
  .coffee-cups.comparison-card .comparison-amount {
    margin: auto;
  }

  .coffee-cups.comparison-card .comparison-amount {
    min-width: 80px;
  }

  .straws.comparison-card {
    grid-template-columns: auto 165px;
    gap: 16px;
  }

  .coffee-cups.comparison-card {
    grid-template-columns: 200px auto;
  }

  .comparison-data .info-text {
    font-size: 24px;
  }

  .bathtubs.comparison-card .comparison-data {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: start;
  }

  .bathtubs.comparison-card .comparison-data .info-text {
  }

  .bathtubs .comparison-data {
    grid-row: 1;
    display: grid;
    justify-content: center;
    align-content: space-around;
    text-align: center;
    gap: 16px;
  }

  .comparison-icon {
    position: absolute;
  }

  .bathtubs .comparison-icon {
    top: -16px;
    height: 200px;
    grid-row: 2;
  }

  .coffee-cups .comparison-icon {
    height: 200px;
    right: unset;
  }

  .straws .comparison-icon {
    height: 212px;
  }
}
</style>
