<template>
  <div class="dialog-wrapper" v-if="!loading">
    <div class="close-wrapper">
      <div class="back">
        <img
          v-if="progressItem !== 0 && !hideBack"
          class="chevron"
          style="width: 30px"
          alt="back"
          :src="require('@/assets/icons/chevron_left.svg')"
          @click="progressItemClick(0)"
        />
        <div class="dialog-title">
          {{ dialogTitle }}
        </div>
      </div>

      <img
        style="width: 25px"
        class="close-icon"
        :src="require('@/assets/icons/close_icon.svg')"
        alt="close"
        @click="$emit('dialogClick')"
      />
    </div>

    <!-- Choose path -->
    <ChoosePath v-if="progressItem === 0" @progressItemClick="progressItemClick" />

    <Referrals v-if="progressItem === 1" />

    <Partners
      v-if="progressItem === 2"
      :is-edit="false"
      :is-contact-edit="false"
      @getFreshDataClick="$emit('getFreshDataClick')"
      @dialogClick="$emit('dialogClick')"
      @showErrorAlert="$emit('showErrorAlert')"
      @showSuccessAlert="$emit('showSuccessAlert')"
    />
  </div>
</template>

<script lang="ts">
import ChoosePath from '@/components/Network/ChoosePath.vue'
import Referrals from '@/components/referrals/Referrals.vue'
import Partners from '@/components/Network/Partners.vue'
import type { Account } from '@/store'
import type { TranslateResult } from 'vue-i18n'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InvitePopUp',
  emits: [
    'dialogClick',
    'getFreshDataClick',
    'showErrorAlert',
    'showSuccessAlert',
    'dialogProgressItemClick',
  ],
  components: {
    ChoosePath,
    Referrals,
    Partners,
  },
  data() {
    return {
      loading: false,
      dialogTitle: '',
      hasSubscription: false,
    } as {
      loading: boolean
      dialogTitle: TranslateResult
      hasSubscription: boolean
    }
  },
  async created() {
    this.loading = true
    this.setTitle()
    this.loading = false
  },
  methods: {
    t(key: string) {
      return this.$t(`InvitePopUp.${key}`)
    },
    setTitle() {
      switch (this.progressItem) {
        case 0:
          this.dialogTitle = this.t('grow_network')
          break
        case 1:
          if (this.account.accountType === 'personal' || this.account.accountType === 'family') {
            this.dialogTitle = this.t('refer_personal')
          } else {
            this.dialogTitle = this.t('refer')
          }
          break
        case 2:
          this.dialogTitle = this.t('partner')
          break
      }
    },
    progressItemClick(number) {
      this.$emit('dialogProgressItemClick', number)
    },
    onProgressItemChange() {
      this.setTitle()
    },
  },
  props: {
    progressItem: {
      type: Number,
    },
    hideBack: {
      type: Boolean,
    },
  },
  watch: {
    progressItem: [
      {
        handler: 'onProgressItemChange',
      },
    ],
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.dialog-wrapper {
  background: #f9f9f9;
  overflow-y: hidden;
  padding: 25px;
  padding-bottom: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
}

.close-wrapper {
  margin-top: -10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.back {
  display: flex;
}

.chevron {
  cursor: pointer;
  margin-left: -8px;
}

.close-icon {
  cursor: pointer;
}

.dialog-title {
  color: #212121;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  width: 100%;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .dialog-wrapper {
    height: 100vh;
    justify-content: flex-start;
  }
}
</style>
