<template>
  <section>
    <automations-table v-if="hasAnyAutomation" @automationSelected="openEditPopup" />
    <div v-else class="no-automations">
      <div class="text-wrapper">
        <p class="no-automations-text">
          {{ t('no_automations_text') }}
        </p>
      </div>
      <gs-button
        size="large"
        width="200"
        @click="$router.push({ name: 'AddIntegrationsAndAutomations' })"
      >
        {{ t('add_automation') }}
      </gs-button>
    </div>
    <full-width-popup ref="popup" :popup-title="getPopupTitle">
      <div v-if="!isSimplifiedCustomIntegration" class="card">
        <p class="edit-automation-description">
          {{ getPopupDescription }}
        </p>
        <edit-automation :selected-automation-id="selectedAutomationId" />
      </div>
      <div v-else class="full-width-panel-wrapper">
        <full-width-panel
          :description="getSimplifiedCustomerIntegrationDescription"
          :title="getSimplifiedCustomerIntegrationTitle"
        >
          <edit-simplified-custom-integration
            v-if="platform"
            :custom-integration-type="platform"
            @back="back"
          />
        </full-width-panel>
      </div>
    </full-width-popup>
  </section>
</template>

<script lang="ts">
import AutomationsTable from '@/components/integration/AutomationsTable.vue'
import FullWidthPopup from '@/components/common/FullWidthPopup.vue'
import EditAutomation from '@/components/integration/EditAutomation.vue'
import { defineComponent } from 'vue'
import type { IntegrationPlatform } from '@/helpers/interfaces'
import { SIMPLIFIED_CUSTOM_INTEGRATION_SOURCES } from '@/helpers/interfaces'
import { includes } from '@/helpers/parsers'
import type { TranslateResult } from 'vue-i18n'
import EditSimplifiedCustomIntegration from '@/components/integration/EditSimplifiedCustomIntegration.vue'
import FullWidthPanel from '@/layouts/FullWidthPanel.vue'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'

export default defineComponent({
  name: 'AutomationsView',
  components: {
    EditAutomation,
    FullWidthPopup,
    FullWidthPanel,
    AutomationsTable,
    EditSimplifiedCustomIntegration,
  },
  mixins: [IntegrationsMixin],
  data() {
    return {
      selectedAutomationId: '',
      platform: undefined as IntegrationPlatform | undefined,
    }
  },
  async created() {
    await this.setCustomIntegrationList()
  },
  methods: {
    t(key: string, params?: { [key: string]: string }): TranslateResult {
      return this.$t(`AutomationsView.${key}`, params || {})
    },
    openEditPopup(automationId: string, platform: IntegrationPlatform): void {
      const popup = this.$refs.popup as HTMLElement & { isOpen: boolean }
      this.selectedAutomationId = automationId
      this.platform = platform
      this.$nextTick(() => {
        popup.isOpen = true
      })
    },
    setCustomIntegrationList(): Promise<void> {
      return this.$store.dispatch('setCustomIntegrationList')
    },
    back(): void {
      const popup = this.$refs.popup as HTMLElement & { isOpen: boolean }
      this.$nextTick(() => {
        popup.isOpen = false
        this.selectedAutomationId = ''
        this.platform = undefined
      })
    },
  },
  computed: {
    hasAnyAutomation(): boolean {
      return this.$store.getters['hasAnyAutomation']
    },
    getPopupTitle(): string {
      return includes(SIMPLIFIED_CUSTOM_INTEGRATION_SOURCES, this.platform)
        ? ''
        : this.t('edit_popup_title')
    },
    getPopupDescription(): string {
      return includes(SIMPLIFIED_CUSTOM_INTEGRATION_SOURCES, this.platform)
        ? ''
        : this.t('edit_automation_description')
    },
    isSimplifiedCustomIntegration(): boolean {
      return includes(SIMPLIFIED_CUSTOM_INTEGRATION_SOURCES, this.platform)
    },
    getSimplifiedCustomerIntegrationTitle(): string {
      return this.t('edit_integration_title', {
        source: this.getIntegrationPlatformName(this.platform as IntegrationPlatform) || '',
      })
    },
    getSimplifiedCustomerIntegrationDescription(): string {
      return this.t('edit_integration_description', {
        source: this.getIntegrationPlatformName(this.platform as IntegrationPlatform) || '',
      })
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.full-width-panel-wrapper {
  display: flex;
  justify-content: center;
}

.card {
  padding: 15px 5px;
  background: var(--ui-white);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  max-width: 1200px;
  margin: 0 auto 20px;
}

.no-automations {
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.text-wrapper {
  align-items: center;
  display: flex;
  max-width: 36ch;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.text-wrapper::before,
.text-wrapper::after {
  content: '';
  display: block;
  flex-shrink: 0;
  background: url('../../assets/reath-left.svg') no-repeat center/cover;
  height: 75px;
  width: 75px;
}

.text-wrapper::after {
  background-image: url('../../assets/reath-right.svg');
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .card {
    padding: 30px;
    margin-bottom: 30px;
  }

  .card.card--with-button {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .edit-automation-description {
    font-size: 18px;
    line-height: 24px;
    max-width: 40ch;
    margin-bottom: 20px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
}
</style>
