<template>
  <div class="panel">
    <p class="description">
      {{ t('description') }}
    </p>
    <div id="cert" ref="cert" class="certificate">
      <div class="logo-wrapper">
        <div class="side float-left">
          <svg class="greenspark-logo">
            <use href="#greenspark-logo" />
          </svg>
        </div>

        <p v-if="userLogoUrl" class="x-mark">X</p>

        <div v-if="userLogoUrl" class="side float-right">
          <img class="user-logo" :src="userLogoUrl" height="80" />
        </div>
      </div>
      <h2 class="text-wrapper">
        <span class="impact-text">
          {{ t('impact_text') }}
        </span>
      </h2>

      <p class="impact-date">
        {{ date }}
      </p>

      <p class="certificate-description">
        {{ t('cert_description') }}
      </p>

      <img :src="require('@/assets/signiture.svg')" class="signature" height="67" width="176" />
      <img
        class="footer-logo"
        :src="require('@/assets/greenspark-logo-beige.svg')"
        height="30"
        width="120"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { Account } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Certificate',
  data() {
    return {
      date: new Date().toLocaleDateString('en'),
    }
  },
  computed: {
    userLogoUrl(): string | undefined {
      return this.account.imageURL
    },
    userLocale(): string {
      return this.$store.getters['getUserLocale']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
  mounted() {
    this.scaler()
    window.addEventListener('resize', this.scaler)
  },
  methods: {
    t(key: string) {
      return this.$t(`Certificate.${key}`)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.scaler)
    },
    scaler() {
      const el = document.getElementById('cert') as HTMLElement // certificate
      const parent = el.parentElement as HTMLElement // wrapper element with box-shadow

      const scale = (parent?.offsetWidth - 60) / el?.offsetWidth || 1040 // 60px sum padding, 1040px fix width of element

      if (parent.offsetWidth - 60 < 1040) el.style.transform = `scale(${scale})`
    },
  },
})
</script>

<style lang="scss" scoped>
.panel {
  background-color: #ffffff;
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-wide);
  margin: 0 auto;
  padding: 30px;
  max-width: 1100px;
}

.certificate {
  align-items: center;
  background: url('../../assets/gifs/comparisons/banner-backgroup.svg') no-repeat center/cover;
  border-radius: var(--border-radius-big);
  display: flex;
  flex-direction: column;
  padding: 40px 60px;
  position: relative;
  width: 1040px;
  height: 560px;
  transform-origin: top left;
}

.logo-wrapper {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  width: 100%;
}

.side {
  display: flex;
}

.side.left {
  justify-content: flex-end;
}

.side.right {
  justify-content: flex-start;
}

.user-logo {
  object-fit: contain;
}

.x-mark {
  font-size: 28px;
  font-weight: 600;
  color: var(--gray-light);
  margin: 0 20px 0 5px;
}

.greenspark-logo {
  color: var(--ui-green);
  height: 80px;
  width: 80px;
}

.text-wrapper {
  align-items: baseline;
  display: flex;
}

.impact-text {
  font-size: 40px;
  line-height: 46px;
  text-align: center;
  width: 17ch;
  margin-bottom: 20px;
}

.text-wrapper::before,
.text-wrapper::after {
  content: '';
  display: block;
  flex-shrink: 0;
  background: url('../../assets/reath-left.svg') no-repeat center/cover;
  height: 85px;
  width: 85px;
}

.text-wrapper::after {
  background-image: url('../../assets/reath-right.svg');
}

.impact-date {
  color: var(--gray-light);
  margin-bottom: 40px;
}

.certificate-description {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
  width: 35ch;
}

.signature {
  margin-left: auto;
}

.footer-logo {
  bottom: 10px;
  position: absolute;
  left: 20px;
}
</style>
