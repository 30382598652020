<template>
  <div class="signup">
    <transition name="fade">
      <Loading v-if="!loaded" />
    </transition>

    <div class="signup-wrapper">
      <template v-if="!isSubscriptionRestart">
        <ShopifySetupInstructions v-if="progressItem === 0" @nextClick="progressItem++" />

        <UserInfo1
          v-if="progressItem === 1"
          @nextClick="progressItem++"
          @backClick="progressItem--"
          @ecommerceBackClick="progressItem = 0"
        />
      </template>
      <div v-if="progressItem === 2" class="select-plan-wrapper">
        <SelectPlanView @backClick="onSelectPlanViewBackClick" />
      </div>

      <Signup1
        v-if="progressItem === 3 && !isSubscriptionRestart"
        :location="location"
        @backClick="progressItem--"
        @checkoutClick="checkout()"
      />
      <CallToUpgradePopup
        v-if="isCallToUpgradePopupOpen"
        :popup-type="'integration'"
        :is-premium-only="false"
        @close="onCallToUpgradePopupClose"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Loading from '@/components/tools/Loading.vue'
import ShopifySetupInstructions from '@/components/register/ShopifySetupInstructions.vue'
import UserInfo1 from '@/components/register/UserInfo1.vue'
import Signup1 from '@/components/register/Signup1.vue'
import type { CreateIntegrationRequestBody, SetupRecurringChargeRequestBody } from '@api/index'
import {
  createAccountWidgets,
  getDataForShopifySignup,
  setUpRecurringChargeShopify,
} from '@api/index'
import { getAuthToken } from '@/auth'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import SelectPlanView from '@/views/SelectPlanView.vue'
import { eventBus } from '@/main'
import type { NewPaidBusinessSubscriptionType } from '@/helpers/pricing'
import type { Account } from '@/store'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { User } from '@/store/users'
import type { Integration } from '@/store/integrations'
import { defineComponent } from 'vue'
import CallToUpgradePopup from '@/components/common/CallToUpgradePopup.vue'
import { API_ERRORS } from '@/helpers/constants'
import type { Notification } from '@/store/notification'

export default defineComponent({
  name: 'DirectMarketplaceSignup',
  components: {
    CallToUpgradePopup,
    SelectPlanView,
    ShopifySetupInstructions,
    UserInfo1,
    Signup1,
    Loading,
  },
  mixins: [LoadCrispMixin, Utils],
  data() {
    return {
      loaded: false,
      progressItem: 0,
      location: '',
      successfulBusinessSignupUrl: '',
      planType: undefined,
      isCallToUpgradePopupOpen: false,
    } as {
      loaded: boolean
      progressItem: number
      location: string
      successfulBusinessSignupUrl: string
      planType?: NewPaidBusinessSubscriptionType
      isCallToUpgradePopupOpen: boolean
    }
  },
  computed: {
    isSubscriptionRestart(): boolean {
      return this.$route.query.restartSubscription === 'true'
    },
    authUserId(): string {
      return this.$store.getters['getAuthUserId']
    },
    publicToken(): string {
      return this.$store.getters['getPublicToken']
    },
    userStores(): Integration[] {
      return this.$store.getters['getUserStores']
    },
    getLastIntegrationId(): string {
      return this.$store.getters['getLastIntegrationId']
    },
    isOnboardingSkipped(): string {
      return this.$store.getters['getOnboardingSkipStatus']
    },
    integrationsAmount(): number {
      return this.$store.getters['getIntegrationsAmount']
    },
    maxActiveIntegrationSources(): number {
      return this.$store.getters['getMaxActiveIntegrationSources']
    },
    user(): User {
      return this.$store.getters['getCurrentUser']
    },
  },
  async created() {
    this.progressItem = this.isSubscriptionRestart ? 2 : 0
    eventBus.on('planSelected', this.setPlan)
    this.setPublicToken(this.$route.query.public_token as string)
    try {
      this.location = await this.getLocation()
    } catch (error) {
      this.location = ''
    }
    this.setAccount({ accountType: 'ecommerce' })
    const next = encodeURIComponent('/onboarding?directSignup=true')
    this.successfulBusinessSignupUrl = `${window.location.origin}/shopify/success?next=${next}`

    if (!this.publicToken) return this.$router.push('/login')

    if (this.isSubscriptionRestart) {
      this.toggleChatBubbleVisibility(true)
      this.loaded = true
      return
    }

    const {
      data: { publicTokens, email, foundAccount },
    } = await getDataForShopifySignup({ publicToken: this.publicToken })
    const authToken = await getAuthToken()

    this.setAccount({ accountType: 'ecommerce' })
    this.updateCurrentUser({ email })

    if (this.authUserId && authToken) {
      // if the user is logged in, we check based on the public token if it's a new store or an old store
      await this.setupShopify(email, foundAccount)
    } else {
      const publicTokenMatch = publicTokens.some((publicToken) => publicToken === this.publicToken)
      // if the user is not logged in, we check based on the public token if it's an existing account or a new account and redirect accordingly
      if (publicTokenMatch || foundAccount) {
        await this.$router.push('/login?app')
      }
    }

    this.toggleChatBubbleVisibility(true)
    this.loaded = true
  },
  methods: {
    t(key: string) {
      return this.$t(`DirectMarketplaceSignup.${key}`)
    },
    beforeDestroy() {
      eventBus.off('planSelected', this.setPlan)
    },
    onCallToUpgradePopupClose() {
      this.isCallToUpgradePopupOpen = false
      this.$router.push('/')
    },
    onSelectPlanViewBackClick() {
      if (this.isSubscriptionRestart) {
        this.$router.push({ path: 'canceled', query: { ...this.$route.query } })
      } else {
        this.progressItem--
      }
    },
    async setupShopify(email: string, foundAccount: boolean) {
      await this.setAccountAction(true, true)
      const isAmountExceeded = this.integrationsAmount >= this.maxActiveIntegrationSources
      if (isAmountExceeded && this.user?.email !== email && !foundAccount) {
        await this.$router.replace({ query: {} })
        this.isCallToUpgradePopupOpen = true
        return
      }

      const isOldStore = await this.createIntegration({
        publicToken: this.publicToken,
        platform: 'shopify',
      })
      const next = encodeURIComponent(
        this.isOnboardingSkipped
          ? '/add-integrations-automations?platform=shopify'
          : '/onboarding?directSignup=true&onboarding=true',
      )
      const url = `${window.location.origin}/shopify/success?next=${next}`
      if (isOldStore === null) return
      if (isOldStore) {
        return this.$router.push('/')
      }

      await setUpRecurringChargeShopify({
        integrationId: this.getLastIntegrationId || '',
        url,
      })
      await this.setIntegrations()
      this.setPublicToken('')
      if (await getAuthToken()) {
        return await this.$router.push({ name: 'ShopifySetup' })
      }
    },
    async setupShopifyForSubscriptionRestart(selectedPlan: NewPaidBusinessSubscriptionType) {
      this.loaded = false
      const isOldStore = await this.createIntegration({
        publicToken: this.publicToken,
        platform: 'shopify',
      })
      if (isOldStore === null) return

      const url = `${window.location.origin}/add-integrations-automations?platform=shopify&subscriptionRestart=true`
      const body: SetupRecurringChargeRequestBody = {
        integrationId: this.getLastIntegrationId || '',
        product: selectedPlan,
        url,
      }
      await setUpRecurringChargeShopify(body)
      this.setPublicToken('')
      await this.setIntegrations()
      await this.fetchIntegrationOptions()
    },
    async checkout() {
      this.loaded = false
      if (this.account.signupComplete) {
        const payload = {
          id: this.account.accountId,
        }
        try {
          await createAccountWidgets(payload)
          await this.setUpShopifyUser()
        } catch (error) {
          console.error(error)
        }
      }
      this.loaded = true
    },
    async setPlan(params) {
      this.planType = params.planType
      if (this.isSubscriptionRestart) {
        await this.setupShopifyForSubscriptionRestart(params.planType)
      } else {
        this.progressItem++
      }
    },
    async setUpShopifyUser() {
      const payload: CreateIntegrationRequestBody = {
        publicToken: this.publicToken,
        platform: 'shopify',
      }
      this.setPublicToken('')
      const isStoreExists = await this.createIntegration(payload)
      if (!isStoreExists && isStoreExists !== null) {
        await setUpRecurringChargeShopify({
          integrationId: this.getLastIntegrationId,
          product: this.planType,
          url: this.successfulBusinessSignupUrl,
        })
        await this.setAccountAction(true)
        await this.setIntegrations()
        await this.$router.push('/')
      }
    },
    updateCurrentUser(userProps: Partial<User>): Promise<void> {
      return this.$store.dispatch('updateCurrentUser', userProps)
    },
    updateIntegration(integration: Integration): Promise<void> {
      return this.$store.dispatch('updateIntegration', integration)
    },
    async createIntegration(
      createIntegrationRequestBody: CreateIntegrationRequestBody,
    ): Promise<boolean | null> {
      try {
        const isOld = await this.$store.dispatch('createIntegration', createIntegrationRequestBody)
        return isOld
      } catch (error) {
        if (error === API_ERRORS.STORE_ALREADY_CONNECTED) {
          this.$store.dispatch('notification/notify', {
            text: this.$t('CommonUi.store_already_connected'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification)
          this.$router.push('/')
        }

        return null
      }
    },
    setAccountAction(setSubscriptions?: boolean, setIntegrations?: boolean): Promise<void> {
      return this.$store.dispatch('setAccount', [setSubscriptions, setIntegrations])
    },
    setIntegrations(): Promise<Promise<void>> {
      return this.$store.dispatch('setIntegrations')
    },
    fetchIntegrationOptions(): Promise<Promise<void>> {
      return this.$store.dispatch('fetchIntegrationOptions')
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
    setPublicToken(publicToken: string): void {
      return this.$store.commit('setPublicToken', publicToken)
    },
  },
  watch: {
    // TODO: Changing steps should be controlled by the router,
    // which would make this unnecessary
    progressItem(updated, previous) {
      if (updated !== previous) window.scrollTo(0, 0)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.signup {
  padding: 40px 60px;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url('../../assets/backgrounds/signup-background.svg');
  background-size: cover;
  background-position: center;
}

.signup-wrapper {
  max-width: 1100px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.select-plan-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

@media #{map-get($display-breakpoints, 'md')} {
  .totals-wrapper {
    width: 100%;
    margin: 0;
    z-index: 2;
  }

  .signup {
    padding: 40px;
    flex-direction: column;
    // min-height: unset;
    max-height: unset;
    min-height: 100vh;
  }

  .signup-wrapper {
    flex-direction: column;
    height: 100%;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .signup {
    padding: 0;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    min-height: 100vh;
  }

  .signup-wrapper {
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
    min-height: 100vh;
  }
}
</style>
