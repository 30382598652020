<template>
  <section :class="['v-panel', padding, background]">
    <h3 v-if="$slots.title" class="v-panel-title">
      <slot name="title" />
    </h3>
    <slot />
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

type Padding = 'normal' | 'small'
type Background = 'primary' | 'secondary'

export default defineComponent({
  name: 'Panel',
  props: {
    padding: { type: String as () => Padding, default: 'normal' },
    background: { type: String as () => Background, default: 'primary' },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.v-panel {
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-wide);
  padding: 32px;
}

.v-panel.primary {
  background-color: white !important;
  /* important is set to overwrite vuetify styling*/
}

.v-panel.secondary {
  background-color: var(--ui-white) !important;
  /* important is set to overwrite vuetify styling*/
}

.v-panel.normal,
.v-panel.small {
  padding: 15px 10px;
}

.v-panel-title {
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 24px;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .v-panel.small {
    padding: 32px 20px;
  }

  .v-panel.normal {
    padding: 32px;
  }
}
</style>
