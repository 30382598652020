import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3020ed85"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "live-page-main" }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = { class: "logo-wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "live-page-header" }
const _hoisted_6 = { class: "live-page-wrapper" }
const _hoisted_7 = { class: "text-wrapper" }
const _hoisted_8 = { class: "info-text" }
const _hoisted_9 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "logo",
          src: require('@/assets/icons/main-shape.svg'),
          alt: "logo"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('header')), 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t('info_text')), 1),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_9, [
              (_ctx.account?.accountType === 'business' || _ctx.account?.accountType === 'ecommerce')
                ? (_openBlock(), _createBlock(_component_gs_button, {
                    key: 0,
                    size: "large",
                    type: "secondary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToEditorPage()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('edit')), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_gs_button, {
                size: "large",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToLivePage()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('view')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCopy && _ctx.onCopy(...args))),
              class: "copy"
            }, _toDisplayString(_ctx.copied ? _ctx.t('copied_link') : _ctx.t('copy_link')), 1)
          ])
        ])
      ])
    ])
  ]))
}