import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b783494"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "mb-0 alert-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_ctx.showAlert)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([
      'top-alert-wrapper',
      { trial: _ctx.isActiveSubscriptionOnTrial },
      { 'free-business': _ctx.isFreeBusinessSubscription },
    ])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alertTypes, (alert, index) => {
          return (_openBlock(), _createElementBlock("p", {
            key: index,
            class: "mb-0 alert-text",
            onClick: ($event: any) => (_ctx.redirectClick(alert))
          }, [
            (!_ctx.isFreeBusinessSubscription)
              ? (_openBlock(), _createBlock(_component_v_icon, {
                  key: 0,
                  class: "alert-icon"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-alert ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("span", {
              innerHTML: 
          _ctx.t(_ctx.snakeCase(alert), {
            expirationDate: new Date(_ctx.fundingEndsDate).toLocaleDateString('en', { month: 'long' }),
          })
        
            }, null, 8, _hoisted_2)
          ], 8, _hoisted_1))
        }), 128)),
        (_ctx.isActiveSubscriptionOnTrial && !_ctx.isFreeBusinessSubscription)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.t('trial_period')), 1))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}