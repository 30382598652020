import { listApiUsageCounts } from '@api/index'
import type { ActionContext } from 'vuex'
import type { StateInterface } from '@/store/index'
import VueI18n from '@/i18n'
import type { Notification } from '@/store/notification'
import type { Clearable } from '@/helpers/interfaces'

export const API_MODULES = [
  'impacts',
  'projects',
  'estimations',
  'reports',
  'email',
  'widgets',
  'overall',
] as const
export type ApiModule = (typeof API_MODULES)[number]

export interface OwnApiUsage {
  widgets: ApiUsageItem
  modules: ApiUsageItem[]
}

export interface ApiUsageLimit {
  amount: Clearable<number>
  hardLimit: boolean
}
export interface ApiBilling {
  chargeByCall: string
}
export interface ApiUsageItem {
  account: {
    accountId: string
    name: string
  }
  billing: ApiBilling
  apiModule: ApiModule
  count: number
  endDate: string
  startDate: string
  limit: ApiUsageLimit
}
export interface ApiUsageModuleState {
  apiUsage: ApiUsageItem[]
}
export const apiUsageModule = {
  state: () =>
    ({
      apiUsage: [],
    } as ApiUsageModuleState),
  mutations: {
    setApiUsage(state: ApiUsageModuleState, apiUsage: ApiUsageItem[]) {
      state.apiUsage = apiUsage
    },
  },
  actions: {
    async setApiUsage({ commit, dispatch }: ActionContext<ApiUsageModuleState, StateInterface>) {
      commit('setLoading', true)
      try {
        const { data } = await listApiUsageCounts()
        commit('setApiUsage', data)
      } catch (e) {
        console.error(e)
        dispatch(
          'notification/notify',
          {
            text: VueI18n.global.t('CommonUi.error_generic'),
            isError: true,
            isClosable: true,
            buttonText: 'close',
          } as Notification,
          { root: true },
        )
      }
      commit('setLoading', false)
    },
  },
  getters: {
    getOwnApiUsage: (
      state: ApiUsageModuleState,
      getters,
      rootState: StateInterface,
    ): OwnApiUsage => {
      // group api usage by apiModule, if apiModule is "widgets" then group it to "widgets", otherwise group it to "modules"
      return state.apiUsage
        .filter(({ account }) => account.accountId === rootState.account.accountId)
        .reduce((acc, apiUsage) => {
          if (!acc[apiUsage.apiModule] && apiUsage.apiModule === 'widgets') {
            acc[apiUsage.apiModule] = apiUsage
          } else if (!acc.modules) {
            acc.modules = []
            acc.modules.push(apiUsage)
          } else {
            acc.modules.push(apiUsage)
          }
          return acc
        }, {} as OwnApiUsage)
    },
    getVendorApiUsageGroupedByAccountId: (
      state: ApiUsageModuleState,
      getters,
      rootState: StateInterface,
    ) => {
      // filter out own account, group api usage by account name
      return state.apiUsage
        .filter(({ account }) => account.accountId !== rootState.account.accountId)
        .reduce((acc, apiUsage) => {
          if (!acc[apiUsage.account.name]) {
            acc[apiUsage.account.name] = []
          }
          acc[apiUsage.account.name].push(apiUsage)
          return acc
        }, {} as Record<string, ApiUsageItem[]>)
    },
    getUsagePeriod: (state: ApiUsageModuleState, getters, rootState: StateInterface) => {
      const nthNumber = (number: number) => {
        if (number > 3 && number < 21) return 'th'
        switch (number % 10) {
          case 1:
            return 'st'
          case 2:
            return 'nd'
          case 3:
            return 'rd'
          default:
            return 'th'
        }
      }
      const formatDate = (date: string) => {
        const formatter = new Intl.DateTimeFormat(rootState.languageSettings.locale || 'en-US', {
          month: 'long',
        })
        return `${formatter.format(new Date(date))} ${new Date(date).getDate()}${nthNumber(
          new Date(date).getDate(),
        )}`
      }
      if (!state.apiUsage.length || !state.apiUsage[0]) return 'N/A'
      else
        return `${formatDate(state.apiUsage[0].startDate)} -> ${formatDate(
          state.apiUsage[0].endDate,
        )}`
    },
  },
}
