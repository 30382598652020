import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_time_picker = _resolveComponent("v-time-picker")!

  return (_openBlock(), _createElementBlock("div", {
    class: "date-time-wrapper",
    style: _normalizeStyle({ maxWidth: _ctx.maxWidth + 'px' })
  }, [
    _createVNode(_component_v_menu, {
      ref: "dateMenu",
      modelValue: _ctx.dateMenu,
      "onUpdate:modelValue": [
        _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dateMenu) = $event)),
        _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('focus')))
      ],
      "close-on-content-click": false,
      transition: "scale-transition",
      "min-width": "auto"
    }, {
      activator: _withCtx(({ props }) => [
        _createVNode(_component_v_text_field, _mergeProps({
          "model-value": _ctx.displayDate,
          "prepend-inner-icon": "mdi-calendar-outline",
          color: "green",
          variant: "outlined",
          clearable: _ctx.clearable,
          "hide-details": "",
          error: _ctx.error,
          rules: _ctx.datePickerRules,
          readonly: ""
        }, props, {
          "onClick:clear": _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearDate()))
        }), null, 16, ["model-value", "clearable", "error", "rules"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_date_picker, {
          modelValue: _ctx.date,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.date) = $event)),
          "no-title": "",
          scrollable: "",
          color: "green",
          elevation: "12",
          min: _ctx.minValueExcluded
        }, null, 8, ["modelValue", "min"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_menu, {
      ref: "timeMenu",
      modelValue: _ctx.timeMenu,
      "onUpdate:modelValue": [
        _cache[7] || (_cache[7] = ($event: any) => ((_ctx.timeMenu) = $event)),
        _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('focus')))
      ],
      "close-on-content-click": false,
      transition: "scale-transition",
      "min-width": "auto"
    }, {
      activator: _withCtx(({ props }) => [
        _createVNode(_component_v_text_field, _mergeProps({
          modelValue: _ctx.displayTime,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.displayTime) = $event)),
          "prepend-inner-icon": "mdi-clock-time-four-outline",
          color: "green",
          variant: "outlined",
          readonly: "",
          "hide-details": "",
          rules: _ctx.timePickerRules,
          clearable: "",
          class: "date-picker"
        }, props, {
          "onClick:clear": _cache[5] || (_cache[5] = ($event: any) => (_ctx.clearTime()))
        }), null, 16, ["modelValue", "rules"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_time_picker, {
          modelValue: _ctx.time,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.time) = $event)),
          format: "24hr",
          scrollable: "",
          color: "green",
          elevation: "12"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 4))
}