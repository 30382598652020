<template>
  <div class="menu">
    <v-menu
      v-model="open"
      close-delay="250"
      content-class="elevation-3"
      transition="slide-y"
      location="left"
    >
      <template v-slot:activator="{ props }">
        <button :class="{ active: open }" v-bind="props">
          <i />
        </button>
      </template>
      <ul class="drawer-list">
        <li
          v-for="(item, a) in items"
          :key="a"
          class="menu-item"
          @click="() => onMenuItemClick(item)"
        >
          <span class="menu-item__text">{{ item.text }}</span>
        </li>
      </ul>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { logOut } from '@api/index'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import type { AuthUser } from '@/store'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

interface Item {
  text: string
  url: string
}

export default defineComponent({
  name: 'BurgerMenu',
  mixins: [LoadCrispMixin],
  data() {
    return {
      open: false,
    }
  },
  methods: {
    t(key: string) {
      return this.$t(`BurgerMenu.${key}`)
    },
    onMenuItemClick(item: Item) {
      switch (item.url) {
        case 'logout':
          this.logout()
          break
        case 'public':
          this.goToLivePage()
          break
        case 'support':
          this.openChatAndToggleNav()
          break
        case '/account?tab=billing':
          this.goToBillingPage()
          break
        default:
          this.url(item.url)
      }
    },
    openChatAndToggleNav() {
      this.openChat()
    },
    logout() {
      logOut()
    },
    url(location: string, query?: Record<string, string>) {
      if (this.authUser.uid) {
        this.setLanguageSettings('en-EN')
        this.$router.push({ path: location, query })
      } else {
        window.open(location, '_self')
      }
    },
    goToLivePage() {
      const companyNameUrl = this.account?.companyName
        ? encodeURIComponent(this.account?.companyName.replace(/\s/g, '-').toLowerCase())
        : ''

      // for personal
      const nameUrl =
        this.currentUser.firstName.replace(/\s/g, '-').toLowerCase() +
        '-' +
        this.currentUser.lastName.replace(/\s/g, '-').toLowerCase()

      if (this.account.accountType === 'business' || this.account.accountType === 'ecommerce') {
        window.open(
          `${process.env.VUE_APP_PUBLIC_PAGE_URL}/${this.account.livePageId}/${companyNameUrl}`,
          '_blank',
        )
      } else {
        window.open(
          `${process.env.VUE_APP_PUBLIC_PAGE_URL}/${this.account.livePageId}/${nameUrl}`,
          '_blank',
        )
      }
    },
    goToBillingPage() {
      // prevent navigation to billing page if already on that route
      if (this.$route.path === '/account' && this.$route.query.tab === 'billing') return
      this.url('/account', { tab: 'billing' })
    },
    setLanguageSettings(language: string): Promise<void> {
      return this.$store.dispatch('setLanguageSettings', language)
    },
  },
  props: {
    items: {
      type: Array as PropType<Item[]>,
    },
  },
  computed: {
    authUser(): AuthUser {
      return this.$store.getters['getAuthUser']
    },
  },
})
</script>

<style lang="scss" scoped>
hr {
  opacity: 0.2;
  margin-bottom: 0;
  margin-top: 5px;
  width: 100%;
  background: black;
}

button {
  display: flex;
  height: 60px;
  width: 30px;
  cursor: pointer;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}

button i {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 2.5px;
  color: var(--font-color-primary);
  font: bold 14px/0.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #252525;
  transition: all 0.2s ease-out;
}

button i::before,
button i::after {
  content: '';
  width: 30px;
  height: 2.5px;
  background: var(--ui-black);
  position: absolute;
  left: 0;
  transition: all 0.2s ease-out;
}

button i::before {
  top: -7px;
}

button i::after {
  bottom: -7px;
}

.active i {
  background: none;
}

.active i::before {
  top: 0;
  transform: rotateZ(45deg);
}

.active i::after {
  bottom: 0;
  transform: rotateZ(-45deg);
}

.drawer-list {
  list-style-type: none;
  background: var(--ui-white);
  min-height: 100px;
  min-width: 250px;
  padding: 0 0 25px;
}

.menu-item {
  color: var(--font-color-primary);
  padding-top: 25px;
  cursor: pointer;
}

.menu-item:hover {
  background: var(--ui-green-light-20);
}

.menu-item__text {
  display: block;
  border-bottom: 1px solid var(--gray-light);
  margin: 0 15px;
}
</style>
