<template>
  <div>
    <!-- Submenu -->
    <transition name="fade">
      <!-- Automations & Integrations -->
      <div class="submenu">
        <SubMenuTile
          :header="t('automatic')"
          :text="t('automatic_text')"
          :button-text="t('manage')"
          @actionClick="navigateToIntegrationList()"
        />

        <!-- Manual -->
        <SubMenuTile
          :header="t('manual')"
          :text="t('manual_text')"
          :button-text="t('manage')"
          @actionClick="$router.push('/add-impact/tailored-impact')"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import SubMenuTile from '@/components/tools/SubMenuTile.vue'
import type { Account } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AddImpactView',
  components: {
    SubMenuTile,
  },
  computed: {
    params(): string {
      return this.getIfAtLeastOneAutomationActive ? 'automations' : 'integrations'
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    getIsActiveIntegration(): boolean {
      return this.$store.getters['getIsActiveIntegration']
    },
    getIfAtLeastOneAutomationActive(): boolean {
      return this.$store.getters['getIfAtLeastOneAutomationActive']
    },
  },
  async created() {
    if (this.account.accountType === 'personal' || this.account.accountType === 'family') {
      await this.$router.push('/add-impact/tailored-impact')
    }
    await this.setAccount()
    await this.setIntegrations()
  },
  methods: {
    t(key: string) {
      return this.$t(`AddImpactView.${key}`)
    },
    navigateToIntegrationList() {
      this.$router.push('/add-impact/manage-integrations-automations/automations')
    },
    setAccount(): Promise<void> {
      return this.$store.dispatch('setAccount')
    },
    setIntegrations(): Promise<void> {
      return this.$store.dispatch('setIntegrations')
    },
  },
})
</script>

<style lang="scss" scoped>
.submenu {
  padding-top: 1px;
}
</style>
