<template>
  <div
    v-if="showAlert"
    :class="[
      'top-alert-wrapper',
      { trial: isActiveSubscriptionOnTrial },
      { 'free-business': isFreeBusinessSubscription },
    ]"
  >
    <p
      v-for="(alert, index) in alertTypes"
      :key="index"
      class="mb-0 alert-text"
      @click="redirectClick(alert)"
    >
      <v-icon v-if="!isFreeBusinessSubscription" class="alert-icon"> mdi-alert </v-icon>
      <span
        v-html="
          t(snakeCase(alert), {
            expirationDate: new Date(fundingEndsDate).toLocaleDateString('en', { month: 'long' }),
          })
        "
      />
    </p>
    <p v-if="isActiveSubscriptionOnTrial && !isFreeBusinessSubscription" class="mb-0 alert-text">
      {{ t('trial_period') }}
    </p>
  </div>
</template>

<script lang="ts">
import type { AlertType } from '@/helpers/constants'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { SubscriptionItem } from '@/store/subscriptions'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TopAlertBar',
  mixins: [Utils],
  computed: {
    showAlert(): boolean {
      return (
        this.showTopAlert || this.isActiveSubscriptionOnTrial || this.isFreeBusinessSubscription
      )
    },
    isFreeBusinessSubscription(): boolean {
      return this.getActiveSubscriptionItem?.product === 'freeBusiness'
    },
    alertTypes(): AlertType[] {
      return this.$store.getters['getAlertTypes']
    },
    fundingEndsDate(): string {
      return this.$store.getters['getFundingEndsDate']
    },
    relationId(): string {
      return this.$store.getters['getRelationId']
    },
    showTopAlert(): boolean {
      return this.$store.getters['getShowTopAlert']
    },
    isActiveSubscriptionOnTrial(): boolean {
      return this.$store.getters['isActiveSubscriptionOnTrial']
    },
    getActiveSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getActiveSubscriptionItem']
    },
  },
  methods: {
    t(key: string, params?: { [k: string]: string }) {
      return this.$t(`TopAlertBar.${key}`, params ?? {})
    },
    redirectClick(alertType: AlertType) {
      switch (alertType) {
        case 'trialPeriod':
          break
        case 'fundingEnding':
        case 'fundingEnded':
        case 'stoppedAt':
        case 'noSubscription':
          this.$router.push('/upgrade-plan')
          break
        case 'unclaimedAllowance':
          this.$router.push(`/partner/success/${this.relationId}?update=true`)
          break
        case 'freeBusinessSubscription':
          this.openPriceComparisonPopup()
      }
    },
    openPriceComparisonPopup(): Promise<void> {
      return this.$store.dispatch('openPriceComparisonPopup')
    },
  },
})
</script>
<style lang="scss" scoped>
@import '~vuetify/settings';

.top-alert-wrapper {
  background: var(--ui-red);
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  font-style: normal;
  min-height: 50px;
  line-height: 30px;
  text-align: center;
  width: 100%;
}

.top-alert-wrapper.trial {
  background: var(--ui-green);
  cursor: default;
}

.top-alert-wrapper.trial.free-business {
  background: var(--ui-green);
  cursor: pointer;
}

.top-alert-wrapper.free-business {
  background: var(--ui-green);
  cursor: pointer;
}

.alert-text {
  padding: 10px 10px 10px 0;
}

.alert-text:not(:first-child) {
  border-top: 1px solid var(--gray-light);
}

.alert-icon {
  color: inherit;
  font-size: 24px;
  flex-shrink: 0;
  margin: 0 5px 0 0;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .top-alert-wrapper {
    padding-left: 226px;
  }
}
</style>
