<template>
  <p class="per-selected-answer-notification">
    {{ t('post_purchase_email_notification_text') }}
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'

export default defineComponent({
  methods: {
    t(key: string): TranslateResult {
      return this.$t(`PerSelectedAnswerPostPurchaseEmailNotification.${key}`)
    },
  },
})
</script>

<style scoped lang="scss">
.per-selected-answer-notification {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 4px 8px;
  border: 1px solid var(--ui-red);
  background: rgba(229, 112, 102, 0.2);
}
</style>
