<template>
  <section>
    <div v-if="!loading" class="onboarding-view">
      <Stepper v-model="activeStep" :max-value="getActiveStepCount">
        <StepperContent :is-visible="activeStep === 1" transition="fade">
          <OnboardingPanel
            :title="t('select_integration_title')"
            :description="t('select_integration_description')"
            :is-skippable="true"
            :on-skip="skipToUserInviteFunction"
            :skip-button-text="getSkipButtonText(1)"
          >
            <SelectIntegration @integration-selected="setSelectedIntegration" />
          </OnboardingPanel>
        </StepperContent>
        <StepperContent
          :is-visible="activeStep === 2 && !isSimplifiedCustomIntegration"
          transition="fade"
        >
          <OnboardingPanel
            :title="stepTwoTitle"
            :description="stepTwoDescription"
            :is-skippable="!isCustomIntegrationSelected"
            :on-skip="skipToUserInviteFunction"
            :skip-button-text="getSkipButtonText(2)"
          >
            <CustomIntegration
              v-if="isCustomIntegrationSelected"
              :custom-integration-settings="selectedCustomIntegrationSettings"
              :type="getCustomIntegrationType(selectedIntegration.provider?.name as IntegrationPlatform)"
              :image-url="selectedCustomIntegrationSettings.imageUrl || selectedIntegration.icon"
              @back="stepBack"
              @next="setCustomIntegrationSettings"
            />
            <SelectTrigger
              v-else
              :selected-integration="selectedIntegration"
              v-model:name="automationName"
              v-model:active-trigger="selectedTrigger"
              v-model:shopify-order-origin="shopifyOrderOrigin"
              v-model:form-impact-source="formImpactSource"
              @submit="submitTriggerForm"
              @back="stepBack"
            />
          </OnboardingPanel>
        </StepperContent>
        <StepperContent :is-visible="showNextStep" transition="fade">
          <OnboardingPanel
            :title="
              apiKey
                ? t(`copy_api_key_title`, {source: getIntegrationPlatformName(selectedIntegration.provider?.name as IntegrationPlatform) || ''})
                : stepThreeTitle
            "
            :description="apiKey ? '' : stepThreeDescription"
          >
            <CreateApiKey
              v-if="isCustomIntegrationSelected && !apiKey"
              :custom-integration-setting="selectedCustomIntegrationSettings"
              :custom-integration-type="
                getCustomIntegrationType(selectedIntegration.provider?.name as IntegrationPlatform)
              "
              @back="stepBack"
              @keyGenerated="handleKeyGeneration"
              v-model:api-key-name="apiKeyName"
            />
            <CopyApiKey
              v-else-if="isCustomIntegrationSelected && apiKey"
              :custom-integration-type="
                getCustomIntegrationType(selectedIntegration.provider?.name as IntegrationPlatform
                )
              "
              :api-key="apiKey"
              :api-key-name="apiKeyName"
              @skip-to-user-invite-function="skipToUserInviteFunction"
            />
            <SelectProjects
              v-else
              :selected-integration="selectedIntegration"
              :automation-name="automationName"
              :selected-trigger="selectedTrigger"
              :shopify-order-origin="shopifyOrderOrigin"
              :form-impact-source="formImpactSource"
              :is-new-automation-enabled="isNewAutomationEnabled"
              :on-save-navigation="skipToUserInviteFunction"
              @back="stepBack"
              @revert-trigger-selection="revertTriggerSelection"
            />
          </OnboardingPanel>
        </StepperContent>
        <StepperContent :is-visible="showUserInviteStep" transition="fade">
          <OnboardingPanel
            :title="t('invite_users_title')"
            :description="inviteUsersDescriptionText"
            :is-skippable="true"
            :on-skip="onSkipUserInvites"
            :skip-button-text="getSkipButtonText(4)"
          >
            <InviteUserOnboard />
          </OnboardingPanel>
        </StepperContent>
      </Stepper>
    </div>
    <LoadingOpaque v-else />
  </section>
</template>

<script lang="ts">
import Stepper from '@/components/onboarding/Stepper.vue'
import StepperContent from '@/components/onboarding/StepperContent.vue'
import type { SelectedIntegration } from '@/components/onboarding/SelectIntegration.vue'
import SelectIntegration from '@/components/onboarding/SelectIntegration.vue'
import OnboardingPanel from '@/components/onboarding/OnboardingPanel.vue'
import { createSubscription, getCustomIntegrations, logOut } from '@api/index'
import SelectTrigger from '@/components/onboarding/SelectTrigger.vue'
import type {
  Clearable,
  CustomIntegrationType,
  IntegrationPlatform,
  IntegrationTriggerType,
  ShopifyOrderOrigin,
  StoreIntegrationTrigger,
} from '@/helpers/interfaces'
import {
  CUSTOM_INTEGRATION_TYPES,
  SIMPLIFIED_CUSTOM_INTEGRATION_SOURCES,
} from '@/helpers/interfaces'
import SelectProjects from '@/components/onboarding/SelectProjects.vue'
import type {
  NewPaidBusinessSubscriptionType,
  TechnicalAndBusinessSubscriptionType,
} from '@/helpers/pricing'
import type { CustomIntegrationSetting } from '@/components/onboarding/CustomIntegration.vue'
import CustomIntegration from '@/components/onboarding/CustomIntegration.vue'
import CreateApiKey from '@/components/onboarding/CreateApiKey.vue'
import CopyApiKey from '@/components/onboarding/CopyApiKey.vue'
import { SetUpSocialMediaTracking } from '@/helpers/mixins/setupSocialMediaTracking'
import type { Notification } from '@/store/notification'
import InviteUserOnboard from '@/components/onboarding/InviteUserOnboard.vue'
import type { SubscriptionItem } from '@/store/subscriptions'
import type { TranslateResult } from 'vue-i18n'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { Account } from '@/store'
import { defineComponent } from 'vue'
import type { NavigationFailure } from 'vue-router'
import LoadingOpaque from '@/components/tools/LoadingOpaque.vue'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'
import { includes } from '@/helpers/parsers'

export default defineComponent({
  name: 'OnboardingView',
  components: {
    InviteUserOnboard,
    CopyApiKey,
    CreateApiKey,
    CustomIntegration,
    SelectProjects,
    SelectTrigger,
    SelectIntegration,
    StepperContent,
    Stepper,
    OnboardingPanel,
    LoadingOpaque,
  },
  mixins: [SetUpSocialMediaTracking, Utils, IntegrationsMixin],
  data() {
    const initialCustomIntegrationSettings = {
      triggerDetails: {
        triggerName: '',
        triggerType: '',
        triggerId: '',
      },
      sourceDetails: {
        sourceName: '',
        sourceId: '',
      },
      imageUrl: '',
    }

    return {
      activeStep: 1,
      apiKey: '',
      apiKeyName: '',
      automationName: '',
      count: 0,
      formImpactSource: 'all',
      shopifyOrderOrigin: 'all',
      customIntegrationSettings: CUSTOM_INTEGRATION_TYPES.reduce(
        (acc, type) => ({
          ...acc,
          [type]: { ...initialCustomIntegrationSettings },
        }),
        {} as Record<CustomIntegrationType, CustomIntegrationSetting>,
      ),
      initialCustomIntegrationSettings,
      selectedTrigger: undefined,
      selectedIntegration: {},
      loading: false,
    } as {
      activeStep: number
      apiKey: string
      apiKeyName: string
      automationName: string
      count: number
      formImpactSource: string | 'all'
      shopifyOrderOrigin: ShopifyOrderOrigin
      customIntegrationSettings: Record<CustomIntegrationType, CustomIntegrationSetting>
      initialCustomIntegrationSettings: CustomIntegrationSetting
      selectedTrigger?: IntegrationTriggerType | StoreIntegrationTrigger
      selectedIntegration: SelectedIntegration
      loading: boolean
    }
  },
  computed: {
    isSimplifiedCustomIntegration(): boolean {
      return SIMPLIFIED_CUSTOM_INTEGRATION_SOURCES.some(
        (customIntegrationType) => customIntegrationType === this.selectedIntegration.platform,
      )
    },
    getActiveStepCount(): number {
      if (this.isSimplifiedCustomIntegration) {
        return this.renderUserInviteStep ? 3 : 2
      }
      return this.renderUserInviteStep ? 4 : 3
    },
    showNextStep(): boolean {
      return this.isSimplifiedCustomIntegration ? this.activeStep === 2 : this.activeStep === 3
    },
    showUserInviteStep(): boolean {
      if (this.isSimplifiedCustomIntegration) {
        return this.renderUserInviteStep && this.activeStep === 3
      }
      return this.renderUserInviteStep && this.activeStep === 4
    },
    stepTwoTitle(): TranslateResult {
      if (includes(CUSTOM_INTEGRATION_TYPES, this.selectedIntegration.platform))
        return this.t(`${this.selectedIntegration.platform}_integration_title`)
      return this.t('select_trigger_title')
    },
    stepTwoDescription(): TranslateResult {
      if (includes(CUSTOM_INTEGRATION_TYPES, this.selectedIntegration.platform))
        return this.t(`${this.selectedIntegration.platform}_integration_description`)
      return this.t(`select_trigger_description.${this.selectedIntegration.category}`)
    },
    stepThreeTitle(): TranslateResult {
      if (this.selectedIntegration.provider?.name === 'custom')
        return this.t('create_api_key_title')
      if (includes(CUSTOM_INTEGRATION_TYPES, this.selectedIntegration.provider?.name)) {
        return this.t(`create_custom_api_key_title`, {
          source: this.getIntegrationPlatformName(this.selectedIntegration.provider?.name) || '',
        })
      }
      return this.t('select_project_title')
    },
    stepThreeDescription(): TranslateResult {
      if (this.selectedIntegration.provider?.name === 'custom')
        return this.t('create_api_key_description')
      if (includes(CUSTOM_INTEGRATION_TYPES, this.selectedIntegration.provider?.name)) {
        return this.t(`create_custom_integration_key_description`, {
          source: this.getIntegrationPlatformName(this.selectedIntegration.provider?.name) || '',
          documentationUrl: this.getDocumentationUrl(
            this.selectedIntegration.platform as CustomIntegrationType,
          ),
        })
      }
      return this.t('select_project_description')
    },
    isCustomIntegrationSelected(): boolean {
      return CUSTOM_INTEGRATION_TYPES.some(
        (customIntegrationType) => customIntegrationType === this.selectedIntegration.platform,
      )
    },
    renderUserInviteStep(): boolean {
      return this.maxTeamMemberCount > 1
    },
    selectedCustomIntegrationSettings(): CustomIntegrationSetting {
      return this.customIntegrationSettings[
        this.getCustomIntegrationType(
          this.selectedIntegration.provider?.name as IntegrationPlatform,
        )
      ]
    },
    isUserFinishedDirectSignup(): boolean {
      return this.getIfUserHasShopifyStore && !this.getShouldDisplayShopifyOverlay
    },
    inviteUsersDescriptionText(): TranslateResult {
      const currentPlanType = this.highestActiveSubscriptionItem
        .product as TechnicalAndBusinessSubscriptionType
      const formattedPlanName = this.formatPlanName(currentPlanType)
      return this.t('invite_users_description', {
        plan: formattedPlanName,
        maxTeamMemberCount: (this.maxTeamMemberCount - 1).toString(),
      })
    },
    getIfUserHasShopifyStore(): boolean {
      return this.$store.getters['getIfUserHasShopifyStore']
    },
    getShouldDisplayShopifyOverlay(): boolean {
      return this.$store.getters['getShouldDisplayShopifyOverlay']
    },
    highestActiveSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getHighestActiveSubscriptionItem']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    isNewAutomationEnabled(): boolean {
      return this.$store.getters['getAutomationFeatureFlag']
    },
    maxTeamMemberCount(): number {
      return this.$store.getters['getMaxTeamMemberCount']
    },
    getEmailSyncAlertDismissed(): boolean {
      return this.$store.getters['getEmailSyncAlertDismissed']
    },
  },
  async created() {
    this.loading = true
    // looping the getUser until we have widgets, if the widget generation fails, or it is too slow, we log out the user (pepeHands)
    if (!this.$route.query.directSignup) {
      while (!this.account?.widgets?.length && this.count <= 10) {
        this.count++
        await this.delay()
        await this.setAccount()
      }
      if (!this.account?.widgets?.length) {
        logOut()
      } else {
        await this.createSubscription()
        await this.addReferral()
        await this.setRequiredActionAlert()
        await this.setIntegrations(false)
      }
    } else if (this.isUserFinishedDirectSignup) {
      this.setupThirdPartyTracking()
    }

    const redirectionRoute = await this.setSubscriptionItems()
    if (!redirectionRoute) await this.loadCustomIntegrations()
    this.loading = false
  },
  methods: {
    delay(): Promise<void> {
      return new Promise<void>((resolve) => setTimeout(resolve, 1000))
    },
    t(key: string, params?: { [key: string]: string }): TranslateResult {
      return this.$t(`OnboardingView.${key}`, params ?? {})
    },
    async createSubscription(): Promise<void> {
      const params = new URLSearchParams(window.location.search)
      const clientSecret = params.get('setup_intent_client_secret')
      if (clientSecret) {
        const product = params.get('product') as NewPaidBusinessSubscriptionType

        const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY ?? '')
        const { setupIntent } = await stripe.retrieveSetupIntent(clientSecret)

        switch (setupIntent?.status) {
          case 'succeeded':
            try {
              await createSubscription({
                product,
                metadata: { type: 'subscription', accountId: this.account?.accountId },
              })
              await this.setAccount()
              this.setupThirdPartyTracking(setupIntent.id)
            } catch (e) {
              // if the user already has a subscription, the backend will return a 409 status "conflict", which should not break the onboarding flow
              if (e?.response?.status !== 409) {
                console.error(e)
                this.$store.dispatch('notification/notify', {
                  text: this.$t('CommonUi.error_generic'),
                  isError: true,
                  isClosable: true,
                  buttonText: 'close',
                } as Notification)
              }
            }
            await this.$router.replace('/onboarding')
            break
          case 'processing':
          case 'requires_payment_method':
        }
      }
    },
    setSelectedIntegration(integration: SelectedIntegration): void {
      this.selectedIntegration = integration
      if (
        this.isCustomIntegrationSelected &&
        this.getCustomIntegrationType(
          this.selectedIntegration.provider?.name as IntegrationPlatform,
        ) !== 'custom'
      ) {
        this.customIntegrationSettings[
          this.getCustomIntegrationType(
            this.selectedIntegration.provider?.name as IntegrationPlatform,
          )
        ].imageUrl = this.selectedIntegration.icon
      }
      this.activeStep = 2
    },
    submitTriggerForm(): void {
      this.activeStep = 3
    },
    setCustomIntegrationSettings(payload: CustomIntegrationSetting): void {
      if (payload) {
        this.customIntegrationSettings[
          this.getCustomIntegrationType(
            this.selectedIntegration.provider?.name as IntegrationPlatform,
          )
        ] = payload
      }

      this.activeStep = 3
    },
    stepBack(): void {
      if (this.isSimplifiedCustomIntegration) {
        this.activeStep = 1
      } else {
        this.activeStep = this.activeStep - 1
      }
    },
    handleKeyGeneration(apiKey: string): void {
      this.apiKey = apiKey
    },
    setupThirdPartyTracking(transactionId: Clearable<string> = null): void {
      this.setUpFacebookTracking('Subscribe')
      this.setupLinkedInTracking('subscribe')
      this.setupGoogleTagManagerTracking('conversion', transactionId)
      this.setupActiveCampaignTracking('subscribe')
    },
    async loadCustomIntegrations(): Promise<void> {
      const { data: customIntegrations } = await getCustomIntegrations()
      customIntegrations
        .map((customIntegration) => ({
          ...customIntegration,
          type: customIntegration.type || 'custom',
        }))
        .forEach((customIntegration) => {
          this.customIntegrationSettings[customIntegration.type] = {
            triggerDetails: {
              triggerName: customIntegration.triggers[0].name,
              triggerType: customIntegration.triggers[0].type,
              triggerId: customIntegration.triggers[0]._id,
            },
            sourceDetails: {
              sourceName: customIntegration.source,
              sourceId: customIntegration._id,
            },
            imageUrl: customIntegration.imageUrl,
          }
        })
    },
    getSkipButtonText(stepNumber: number): TranslateResult {
      switch (stepNumber) {
        case 1:
          return this.t(this.renderUserInviteStep ? 'skip_integration' : 'skip')
        case 2:
          return this.t(this.renderUserInviteStep ? 'skip_automation' : 'skip')
        case 4:
          return this.t(this.renderUserInviteStep ? 'skip_invites' : 'skip')
        case 3:
        default:
          throw new Error('Not implemented')
      }
    },
    skipToUserInviteFunction(): Promise<void> {
      if (this.renderUserInviteStep) {
        this.activeStep = this.getActiveStepCount
        return Promise.resolve()
      }

      return this.onSkipUserInvites()
    },
    async onSkipUserInvites(): Promise<void> {
      await this.updateOnboardingSkipped()

      if (this.getEmailSyncAlertDismissed) {
        await this.$router.push({
          path: '/',
          query: { from: 'onboarding' },
        })
      } else {
        await this.$router.push({
          name: 'EmailIntegrationSyncAlert',
          query: { from: 'onboarding' },
        })
      }
    },
    revertTriggerSelection(
      preselectedTrigger: IntegrationTriggerType | StoreIntegrationTrigger,
    ): void {
      // TODO: form pre-filled values should be handled with a router
      // but this form does not have specific route for each step.
      // Remove this when we move these form steps to be controled by the router
      if (!preselectedTrigger) return
      this.selectedTrigger = preselectedTrigger
      this.activeStep = 2
    },
    setSubscriptionItems(): Promise<void | NavigationFailure> {
      return this.$store.dispatch('setSubscriptionItems')
    },
    setRequiredActionAlert(): Promise<void> {
      return this.$store.dispatch('setRequiredActionAlert')
    },
    setAccount(): Promise<void> {
      return this.$store.dispatch('setAccount')
    },
    addReferral(): Promise<void> {
      return this.$store.dispatch('addReferral')
    },
    updateOnboardingSkipped(): Promise<void> {
      return this.$store.dispatch('updateOnboardingSkipped')
    },
    setIntegrations(withCustomIntegrations: boolean): Promise<Promise<void>> {
      return this.$store.dispatch('setIntegrations', withCustomIntegrations)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.onboarding-view {
  min-height: 100vh;
  background: url('../assets/backgrounds/onboarding-background.png') no-repeat center/cover
    var(--ui-beige);
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .onboarding-view {
    padding: 0 15px 0;
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .onboarding-view {
    padding: 30px 20px 60px;
  }
}
</style>
