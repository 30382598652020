<template>
  <v-btn icon :size="35" class="ml-1" @click="copyToClipboard()">
    <v-icon class="icon" :size="14">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CopyButton',
  data() {
    return {
      icon: 'mdi-content-copy',
    }
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.text).then(() => {
        this.icon = 'mdi-check-circle'
        setTimeout(() => {
          this.icon = 'mdi-content-copy'
        }, 2000)
      })
    },
  },
  props: {
    text: { required: true, type: String },
  },
})
</script>

<style lang="scss" scoped>
.icon.icon {
  color: var(--ui-green);
}
</style>
