<template>
  <panel class="analytics">
    <h1 class="analytics__title">
      {{ t('title') }}
    </h1>
    <p class="analytics__description">
      {{ t('description') }}
    </p>
    <TopStatsPublic theme="guarded" class="analytics__top-stats" />
    <h2 :class="['analytics__title', { 'no-subscription': !subscription }]">
      {{ t('overview_title') }}
    </h2>
    <div v-if="!!subscription" class="analytics__menu-wrapper">
      <router-link
        v-for="(route, index) in nestedRoutes"
        :key="index"
        class="analytics__menu-item"
        :to="{ name: route.name }"
      >
        {{ t(snakeCase(route.name as string)) }}
      </router-link>
    </div>
    <router-view :key="$route.fullPath" />
  </panel>
</template>

<script lang="ts">
import Panel from '@/components/common/Panel.vue'
import TopStatsPublic from '@/components/livePage/TopStatsPublic.vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { SubscriptionItem } from '@/store/subscriptions'
import { defineComponent } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

export default defineComponent({
  name: 'AnalyticsView',
  components: { TopStatsPublic, Panel },
  mixins: [Utils],
  computed: {
    nestedRoutes(): RouteRecordRaw[] | undefined {
      const children = this.$router.options.routes?.find(
        ({ name }) => name === 'Analytics',
      )?.children
      return this.customerEngagementAnalytics
        ? children
        : children?.filter(({ name }) => name !== 'customerEngagement')
    },
    customerEngagementAnalytics(): boolean {
      return this.$store.getters['getCustomerEngagementAnalyticsFeatureFlag']
    },
    subscription(): SubscriptionItem {
      return this.$store.getters['getActivePaidSubscriptionItem']
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`AnalyticsView.${key}`)
    },
  },
})
</script>

<style lang="scss" scoped>
.analytics__title {
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 20px;
}

h2.analytics__title {
  margin-bottom: 0;
}

h2.analytics__title.no-subscription {
  margin-bottom: 20px;
}

.analytics__menu-wrapper {
  overflow-y: auto;
  width: 100%;
  padding: 20px 0;
  scrollbar-width: none;
  display: flex;
  scroll-behavior: smooth;
}

.analytics__menu-wrapper::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}

.analytics__menu-item {
  align-items: center;
  display: flex;
  cursor: pointer;
  background-color: var(--ui-green-light);
  padding: 12px 24px;
  border-radius: 99px;
  transition: all linear 0.2s;
  font-size: 18px;
  font-weight: 700;
  color: var(--ui-beige);
  margin: 0;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
}

.analytics__menu-item:hover,
.analytics__menu-item:focus {
  box-shadow: inset 290px 0 0 0 var(--ui-green);
}

.analytics__menu-item:not(:first-child) {
  margin-left: 10px;
}

.analytics__menu-item.router-link-exact-active {
  background-color: var(--ui-green);
  scroll-snap-align: end;
}
</style>
