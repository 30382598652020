import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_input = _resolveComponent("gs-input")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    class: "input-editor",
    disabled: _ctx.disabled,
    "onUpdate:modelValue": _ctx.onFormInput
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localValues, (_, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "edit-input"
        }, [
          _createVNode(_component_gs_input, {
            "model-value": _ctx.localValues[index].value,
            height: 56,
            rules: _ctx.getInputRules(index),
            label: _ctx.inputLabel,
            "onUpdate:modelValue": (inputValue) => _ctx.onInput(index, inputValue)
          }, null, 8, ["model-value", "rules", "label", "onUpdate:modelValue"]),
          _createVNode(_component_v_btn, {
            elevation: 0,
            height: 56,
            width: 40,
            "min-width": 0,
            variant: "outlined",
            disabled: _ctx.isActionButtonDisabled(index),
            class: "input-action-btn",
            onClick: () => _ctx.onActionButtonClick(index)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, {
                class: "material-icons-outlined input-action-icon",
                size: "large"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.isAddAction(index) ? 'mdi-plus-thick' : 'mdi-minus-thick'), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["disabled", "onUpdate:modelValue"]))
}