import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-99e3f1fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "referral-grid-outer" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "pop-up" }
const _hoisted_4 = {
  key: 2,
  class: "empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainTable = _resolveComponent("MainTable")!
  const _component_Reward = _resolveComponent("Reward")!
  const _component_History = _resolveComponent("History")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_LoadingOpaque = _resolveComponent("LoadingOpaque")!
  const _component_Empty = _resolveComponent("Empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.empty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_MainTable, {
            "menu-items": _ctx.menuItems,
            rows: _ctx.rowItems,
            "column-width": _ctx.columnWidth,
            "footer-items": _ctx.footerItems,
            "hide-partner-image": false,
            "hide-search": false,
            "no-results": _ctx.noSearchResults,
            "table-loading": _ctx.tableLoading,
            "active-page": _ctx.activePage,
            onQuickMenuClick: _ctx.quickMenuClick,
            onEmitSearch: _ctx.search,
            onEmitSort: _ctx.sort,
            onFooterClick: _ctx.footerClick,
            onArrowClick: _ctx.arrowClick
          }, null, 8, ["menu-items", "rows", "column-width", "footer-items", "no-results", "table-loading", "active-page", "onQuickMenuClick", "onEmitSearch", "onEmitSort", "onFooterClick", "onArrowClick"]),
          _createVNode(_component_v_dialog, {
            modelValue: _ctx.quickMenuDialog,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.quickMenuDialog) = $event)),
            width: "600",
            persistent: "",
            fullscreen: _ctx.isMobile ? true : false
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                (_ctx.activeQuickMenuItem === 'reward')
                  ? (_openBlock(), _createBlock(_component_Reward, {
                      key: 0,
                      "relations-id": _ctx.relationsIdQuickMenuItem,
                      "connectee-id-quick-menu-item": _ctx.connecteeIdQuickMenuItem,
                      onDialogClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogClick())),
                      onShowErrorAlert: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('showErrorAlert'))),
                      onShowSuccessAlert: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('showSuccessAlert'))),
                      onGetFreshDataClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('getFreshDataClick')))
                    }, null, 8, ["relations-id", "connectee-id-quick-menu-item"]))
                  : _createCommentVNode("", true),
                (_ctx.activeQuickMenuItem === 'history')
                  ? (_openBlock(), _createBlock(_component_History, {
                      key: 1,
                      "row-items": _ctx.historyRowItems,
                      "relations-id": _ctx.relationsIdQuickMenuItem,
                      "partner-name": _ctx.partnerName,
                      "window-width": _ctx.windowWidth,
                      onDialogClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.dialogClick()))
                    }, null, 8, ["row-items", "relations-id", "partner-name", "window-width"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "fullscreen"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingOpaque, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.empty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_Empty, {
            text: _ctx.t('empty'),
            text1: _ctx.t('empty1'),
            "button-text": _ctx.t('invite'),
            onButtonClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('emptyButtonClick')))
          }, null, 8, ["text", "text1", "button-text"])
        ]))
      : _createCommentVNode("", true)
  ]))
}