import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2dfd499b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "outer-wrapper" }
const _hoisted_2 = { class: "transfer-header" }
const _hoisted_3 = { class: "description-text" }
const _hoisted_4 = { class: "type" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('header')), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.descriptionText), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.receipts, (receipt, a) => {
      return (_openBlock(), _createElementBlock("div", {
        key: a,
        class: "impact-wrapper"
      }, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(receipt.type), 1),
        _createElementVNode("div", {
          class: "download",
          onClick: ($event: any) => (_ctx.openUrl(receipt.url))
        }, [
          _createTextVNode(" Download "),
          _createElementVNode("img", {
            class: "download-icon",
            src: require('@/assets/icons/download.svg'),
            alt: "download"
          }, null, 8, _hoisted_6)
        ], 8, _hoisted_5)
      ]))
    }), 128))
  ]))
}