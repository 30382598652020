<template>
  <div
    :class="['loading-wrapper', { 'no-shadow': backgroundColor }]"
    :style="{ backgroundColor: backgroundColor }"
  >
    <img class="loading-logo" alt="loading" :src="require('@/assets/icons/main-shape.svg')" />
    <div class="progress-bar-wrapper">
      <v-progress-linear color="#3B755F" indeterminate rounded height="6" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoaderBlock',
  methods: {
    t(key: string) {
      return this.$t(`Loading.${key}`)
    },
  },
  props: {
    backgroundColor: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.loading-wrapper.loading-wrapper {
  background: var(--ui-white);
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-wide);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 40px;
  height: 100%;
  width: 100%;
}

.loading-wrapper.no-shadow {
  box-shadow: none;
}

.loading-logo {
  width: 60px;
  margin-bottom: 30px;
}

.progress-bar-wrapper {
  width: 200px;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .loading-wrapper {
    margin-left: 0;
    padding: 10px;
    width: 100%;
  }

  .loading-logo {
    width: 60px;
    margin-bottom: 30px;
  }

  .progress-bar-wrapper {
    width: 150px;
  }
}
</style>
