import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fbb3c91c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-existing-integration" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card card--with-button" }
const _hoisted_4 = { class: "text-wrapper" }
const _hoisted_5 = { class: "add-integration-title" }
const _hoisted_6 = { class: "add-integraion-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_integrations_table = _resolveComponent("integrations-table")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_integrations_table, {
        mode: "select",
        onSelect: _ctx.onSelect
      }, null, 8, ["onSelect"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.t('add_integration_title')), 1),
        _createElementVNode("p", _hoisted_6, [
          _createTextVNode(_toDisplayString(_ctx.t('add_integration_description')) + " ", 1),
          _createElementVNode("button", {
            class: "chat-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openChat()))
          }, _toDisplayString(_ctx.t('get_in_touch')), 1)
        ])
      ]),
      _createVNode(_component_gs_button, {
        size: "large",
        href: "/add-integrations-automations?addNew=true"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('add_integration')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}