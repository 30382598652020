import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-333de0fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "edit-custom-integration" }
const _hoisted_2 = { class: "integration-name-wrapper section border-bottom border-top" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "integration-name-wrapper section" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "text-left" }
const _hoisted_8 = { class: "text-left" }
const _hoisted_9 = { class: "text-left" }
const _hoisted_10 = { class: "text-left" }
const _hoisted_11 = { class: "text-right" }
const _hoisted_12 = { class: "text-right" }
const _hoisted_13 = { class: "button-wrapper" }
const _hoisted_14 = { class: "popup-header" }
const _hoisted_15 = { class: "rename-description" }
const _hoisted_16 = { class: "popup-header-regenerate" }
const _hoisted_17 = { class: "regenerate-wrapper" }
const _hoisted_18 = { class: "regenerate-description" }
const _hoisted_19 = { class: "input-wrapper" }
const _hoisted_20 = { class: "alert-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OnboardingPill = _resolveComponent("OnboardingPill")!
  const _component_LoadingTable = _resolveComponent("LoadingTable")!
  const _component_QuickMenu = _resolveComponent("QuickMenu")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_gs_input = _resolveComponent("gs-input")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("dl", _hoisted_2, [
        _createElementVNode("dd", _hoisted_3, _toDisplayString(_ctx.t('integration')), 1),
        _createVNode(_component_OnboardingPill, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getIntegrationPlatformName(_ctx.customIntegrationType as IntegrationPlatform) ?? ''), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("dl", _hoisted_4, [
        _createElementVNode("dd", _hoisted_5, _toDisplayString(_ctx.t('table_title', {
            source: _ctx.getIntegrationPlatformName(_ctx.customIntegrationType as IntegrationPlatform) ?? '',
          })), 1),
        _createElementVNode("dd", {
          class: "description",
          innerHTML: _ctx.getDescription
        }, null, 8, _hoisted_6)
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_LoadingTable, { key: 0 }))
        : _createCommentVNode("", true),
      (!_ctx.loading && _ctx.apiKeys.length > 0)
        ? (_openBlock(), _createBlock(_component_v_table, {
            key: 1,
            class: "keys table",
            "fixed-header": ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("thead", null, [
                _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.t('table_name')), 1),
                _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.t('table_type')), 1),
                _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.t('placeholder')), 1),
                _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.t('table_created')), 1),
                _createElementVNode("th", _hoisted_11, _toDisplayString(_ctx.t('manage')), 1)
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.apiKeys, (item, index) => {
                  return (_openBlock(), _createElementBlock("tr", { key: index }, [
                    _createElementVNode("td", null, _toDisplayString(item.name), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.apiKeyTypeFilter(item.subType)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.visibleKeyFilter(item.visibleValue)), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.dateFormatFilter(item.activationDate, _ctx.userLocale)), 1),
                    _createElementVNode("td", _hoisted_12, [
                      _createVNode(_component_QuickMenu, {
                        "quick-menu-items": _ctx.menuItems,
                        onQuickMenuClick: ($event: any) => (_ctx.quickMenuClick($event, item))
                      }, null, 8, ["quick-menu-items", "onQuickMenuClick"])
                    ])
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_gs_button, {
          uppercased: false,
          capitalized: "",
          class: "form-control",
          icon: "mdi-chevron-left",
          muted: "",
          outlined: "",
          size: "large",
          type: "secondary-v2",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.back')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_gs_button, {
          loading: _ctx.loading,
          uppercased: false,
          capitalized: "",
          "full-width": "",
          size: "large",
          type: "secondary-v2",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('back')), ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('next')), 1)
          ]),
          _: 1
        }, 8, ["loading"])
      ])
    ]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.isEditApiKeyDialogOpen,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isEditApiKeyDialogOpen) = $event)),
      persistent: "",
      transition: "fade",
      width: "700"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { class: "card d-flex flex-column" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.t('edit_key_title', {
              source:
                _ctx.getIntegrationPlatformName(_ctx.customIntegrationType as IntegrationPlatform) ?? '',
            })), 1),
              _createElementVNode("button", {
                class: "close-button",
                variant: "plain",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isEditApiKeyDialogOpen = false))
              }, [
                _createVNode(_component_v_icon, { class: "close-icon" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" mdi-close")
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.t('edit_key_description')), 1),
            _createElementVNode("div", null, [
              _createVNode(_component_v_form, {
                modelValue: _ctx.isFormValid,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isFormValid) = $event)),
                class: "mt-5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_gs_input, {
                    modelValue: _ctx.editName,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editName) = $event)),
                    "hide-details": false,
                    maxlength: _ctx.maxLength,
                    label: _ctx.t('create_key_name'),
                    rules: [_ctx.rules.required, _ctx.rules.maxlength(_ctx.maxLength), _ctx.rules.minlength(_ctx.minLength)],
                    class: "apy-key-input",
                    counter: ""
                  }, null, 8, ["modelValue", "maxlength", "label", "rules"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createVNode(_component_gs_button, {
              disabled: _ctx.isEditApiKeyLoading || !_ctx.isFormValid || _ctx.editName === _ctx.currentItem?.name,
              loading: _ctx.isEditApiKeyLoading,
              "full-width": "",
              height: "50px",
              size: "large",
              onClick: _ctx.editApiKey
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('rename')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "loading", "onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.isRegenerateApiKeyDialogOpen,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.isRegenerateApiKeyDialogOpen) = $event)),
      persistent: "",
      transition: "fade",
      width: "700"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_card, { class: "card d-flex flex-column" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.t('regenerate_key_title', {
              source:
                _ctx.getIntegrationPlatformName(_ctx.customIntegrationType as IntegrationPlatform) ?? '',
            })), 1)
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.getRegenerateKeyDescription), 1),
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_v_text_field, {
                  "model-value": _ctx.apiKey,
                  class: "apy-key-input",
                  color: "green",
                  disabled: "",
                  flat: "",
                  "hide-details": "",
                  readonly: "",
                  variant: "outlined"
                }, null, 8, ["model-value"]),
                _createElementVNode("button", {
                  class: "apy-key-button",
                  onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.copy && _ctx.copy(...args)), ["prevent"]))
                }, [
                  _createVNode(_component_v_icon, { class: "copy-icon" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.isCopied ? 'mdi-check' : 'mdi-content-copy'), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.getAlertText), 1)
            ]),
            _createVNode(_component_gs_button, {
              disabled: !_ctx.wasCopiedAtLeastOnce,
              loading: _ctx.isRegenerateApiKeyLoading,
              uppercased: false,
              "full-width": "",
              height: "50px",
              size: "large",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isRegenerateApiKeyDialogOpen = false))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('done')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}