<template>
  <v-tooltip :open-on-click="isTouch" location="top" color="black">
    <template v-slot:activator="{ props }">
      <v-icon v-bind="props" class="tooltip" color="green">
        {{ infoIcon }}
      </v-icon>
    </template>
    <div class="tooltip-content">
      <slot />
    </div>
  </v-tooltip>
</template>
<script lang="ts">
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoTooltip',
  mixins: [Utils],
  computed: {
    infoIcon(): string {
      return this.outlined ? 'mdi-information-outline' : 'mdi-information'
    },
  },
  props: {
    outlined: {
      default: false,
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
.tooltip {
  margin-left: 5px;
  cursor: pointer;
}

.tooltip-content {
  width: 240px;
  text-align: center;
}
</style>
