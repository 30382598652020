<template>
  <div class="loading-wrapper">
    <img
      v-if="!hideWindmill"
      class="loading-logo"
      alt="Loading ..."
      :src="require('@/assets/icons/main-shape.svg')"
    />
    <div class="progress-bar-wrapper">
      <v-progress-linear color="#3B755F" indeterminate rounded height="6" />
    </div>
    <div class="text">
      {{ text }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoadingOpaque',
  components: {},
  methods: {
    t(key: string) {
      return this.$t(`LoadingOpaque.${key}`)
    },
  },
  props: {
    text: {
      type: String,
    },
    hideWindmill: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.loading-wrapper {
  width: 110%;
  height: 100%;
  background: #f9f9f9;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-left: -40px;
}

.loading-logo {
  width: 150px;
  margin-bottom: 30px;
}

.progress-bar-wrapper {
  width: 200px;
}

.text {
  color: #212121;
  font-weight: 600;
  font-size: 20px;
  margin-top: 15px;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .loading-wrapper {
    margin-left: 0;
    width: 100%;
    padding: 10px 10px 150px;
  }

  .text {
    font-size: 18px;
  }

  .progress-bar-wrapper {
    width: 150px;
  }

  .loading-logo {
    width: 100px;
    margin-bottom: 30px;
  }
}
</style>
