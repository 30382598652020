<template>
  <v-dialog
    :model-value="!!dialog.name"
    class="dialog"
    :persistent="dialog.options.persistent"
    :fullscreen="dialog.options.fullscreen || $vuetify.display.xs"
    :retain-focus="dialog.options.retainFocus"
    :width="dialog.options.width"
    @click:outside="onOutsideClick"
  >
    <component v-if="dialog.name" :is="dialog.name" :dialog="{ ...dialog }" :close="closeDialog" />
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import SimpleDialogTemplate from '@/components/dialog/template/SimpleDialogTemplate.vue'
import DeleteConfirmationDialogTemplate from '@/components/dialog/template/DeleteConfirmationDialogTemplate.vue'
import InviteUserDialog from '@/components/dialog/custom/InviteUserDialog.vue'
import RequestIntegrationDialog from '@/components/dialog/custom/RequestIntegrationDialog.vue'
import PriceComparisonDialog from '@/components/dialog/custom/PriceComparisonDialog.vue'
import CancelSubscriptionDialog from '@/components/dialog/custom/CancelSubscriptionDialog.vue'
import ProjectRefactorLogoutDialog from '@/components/dialog/custom/ProjectRefactorLogoutDialog.vue'
import type { Dialog } from '@/store/dialog'

export default defineComponent({
  name: 'DialogContainer',
  computed: {
    dialog(): Dialog {
      return this.$store.getters['getCurrentDialog']
    },
  },
  components: {
    SimpleDialogTemplate,
    DeleteConfirmationDialogTemplate,
    InviteUserDialog,
    RequestIntegrationDialog,
    PriceComparisonDialog,
    CancelSubscriptionDialog,
    ProjectRefactorLogoutDialog,
  },
  created() {
    this.closeDialog()
  },
  methods: {
    onOutsideClick() {
      if (this.dialog.options?.persistent) {
        return
      }

      this.closeDialog()
    },
    closeDialog(): Promise<void> {
      return this.$store.dispatch('closeDialog')
    },
  },
})
</script>
