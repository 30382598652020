<template>
  <div class="copy-apy-key">
    <dl class="integration-name-wrapper section bottom-border">
      <dd class="label integration">
        {{ t('integration') }}
      </dd>
      <OnboardingPill>
        {{ customIntegrationType ? getIntegrationPlatformName(customIntegrationType) : '' }}
      </OnboardingPill>
    </dl>
    <dl class="integration-name-wrapper section bottom-border">
      <dd class="label integration">
        {{
          t('api_key_name', {
            source: customIntegrationType
              ? getIntegrationPlatformName(customIntegrationType) || ''
              : '',
          })
        }}
      </dd>
      <OnboardingPill>
        {{ apiKeyName }}
      </OnboardingPill>
    </dl>
    <div class="section">
      <p class="label">
        {{
          t(getLabelKey, {
            source: customIntegrationType
              ? getIntegrationPlatformName(customIntegrationType) || ''
              : '',
          })
        }}
      </p>
      <div class="input-wrapper">
        <v-text-field
          class="apy-key-input"
          readonly
          disabled
          hide-details
          flat
          color="#323232"
          :model-value="apiKey"
          variant="outlined"
        />
        <button class="apy-key-button" @click.prevent="copy">
          <v-icon class="copy-icon">
            {{ isCopied ? 'mdi-check' : 'mdi-content-copy' }}
          </v-icon>
        </button>
      </div>
      <p class="alert-text">
        {{
          t(getAlertKey, {
            source: customIntegrationType
              ? getIntegrationPlatformName(customIntegrationType) || ''
              : '',
          })
        }}
      </p>
    </div>
    <div class="section">
      <gs-button
        :uppercased="false"
        full-width
        size="large"
        :loading="loading"
        :disabled="loading || !wasCopiedAtLeastOnce"
        @click="done"
      >
        {{ t('done') }}
      </gs-button>
    </div>
  </div>
</template>

<script lang="ts">
import type { Notification } from '@/store/notification'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { CustomIntegrationType } from '@/helpers/interfaces'
import OnboardingPill from '@/components/onboarding/OnboardingPill.vue'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'

export default defineComponent({
  name: 'CopyApiKey',
  components: { OnboardingPill },
  mixins: [IntegrationsMixin],
  emits: ['skip-to-user-invite-function'],
  data() {
    return {
      isCopied: false,
      wasCopiedAtLeastOnce: false,
      loading: false,
    }
  },
  computed: {
    getLabelKey(): string {
      if (this.customIntegrationType === 'custom') return 'copy_api_key_label_custom'
      return `copy_api_key_label`
    },
    getAlertKey(): string {
      if (this.customIntegrationType === 'custom') return 'alert_custom'
      return `alert`
    },
  },
  methods: {
    t(key: string, params: { [k: string]: string } = {}) {
      return this.$t(`CopyApiKey.${key}`, params)
    },
    async done() {
      this.loading = true
      try {
        await this.setOnboardingSkipped()
        await this.setCustomIntegrationList()
        if (this.$route.name === 'Onboarding') this.$emit('skip-to-user-invite-function')
        if (this.$route.name === 'AddIntegrationsAndAutomations')
          await this.$router.push({
            path: '/add-impact/manage-integrations-automations/integrations',
          })
      } catch (e) {
        console.error(e)
        this.$store.dispatch('notification/notify', {
          text: this.$t('CommonUi.error_generic'),
          isError: true,
          isClosable: true,
          buttonText: 'close',
        } as Notification)
      }
      this.loading = false
    },
    copy() {
      navigator.clipboard.writeText(this.apiKey ?? '').then(() => {
        this.isCopied = true
        setTimeout(() => {
          this.isCopied = false
        }, 2000)
      })
      if (!this.wasCopiedAtLeastOnce) this.wasCopiedAtLeastOnce = true
    },
    setAccount(): Promise<void> {
      return this.$store.dispatch('setAccount')
    },
    setOnboardingSkipped(): Promise<void> {
      return this.$store.dispatch('setOnboardingSkipped')
    },
    setCustomIntegrationList(): Promise<void> {
      return this.$store.dispatch('setCustomIntegrationList')
    },
  },
  props: {
    apiKey: {
      type: String,
    },
    apiKeyName: {
      type: String,
      required: true,
    },
    customIntegrationType: {
      type: String as PropType<CustomIntegrationType>,
    },
  },
})
</script>

<style lang="scss" scoped>
.section {
  padding: 15px 0;
}

.bottom-border {
  border-bottom: 2px solid #cccccc;
}

.integration-name-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.label {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin: 8px 0;
}

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  width: 100%;
}

.alert-text {
  font-weight: 600;
  color: #ff2424;
  margin-bottom: 0;
}

.help-text {
  color: var(--gray-light);
  max-width: 50ch;
  font-weight: 400;
  margin-bottom: 24px;
}

.apy-key-button {
  padding: 5px;
  border-radius: 5px;
  border: 2px solid var(--ui-dark-gray);
  color: var(--ui-dark-gray);
  height: 56px;
  width: 56px;
  flex-shrink: 0;
}

.copy-icon {
  color: var(--ui-dark-gray);
}
</style>
