import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-416ce126"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "email-integration-sync-alert" }
const _hoisted_2 = { class: "image-wrapper" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "select-integration-title" }
const _hoisted_5 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_integration = _resolveComponent("select-integration")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_onboarding_panel = _resolveComponent("onboarding-panel")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigateBack && _ctx.navigateBack(...args))),
      class: "close-button"
    }),
    _createVNode(_component_onboarding_panel, {
      title: _ctx.t('title'),
      description: _ctx.t('description'),
      "background-color": "transparent",
      "is-skippable": _ctx.isOnboarding,
      "on-skip": _ctx.skipDataSync,
      "skip-button-text": _ctx.t('skip')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: require('@/assets/images/email-data-sync-flow.svg'),
            alt: _ctx.t('title')
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t('select_integration')), 1),
        _createVNode(_component_select_integration, {
          onIntegrationSelected: _ctx.selectIntegration,
          "hide-search": "",
          "active-category": "email",
          "integration-option-selection": _ctx.getOptionsForSelectionList
        }, null, 8, ["onIntegrationSelected", "integration-option-selection"]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_gs_button, {
            type: "primary",
            size: "large",
            "full-width": "",
            disabled: !_ctx.getSelectedIntegrationForDataSync,
            loading: _ctx.loading,
            onClick: _withModifiers(_ctx.next, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.next')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "loading", "onClick"]),
          _createVNode(_component_v_checkbox, {
            modelValue: _ctx.isDismissed,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isDismissed) = $event)),
            color: "green",
            label: _ctx.t('dismiss')
          }, null, 8, ["modelValue", "label"])
        ])
      ]),
      _: 1
    }, 8, ["title", "description", "is-skippable", "on-skip", "skip-button-text"])
  ]))
}