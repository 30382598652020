<template>
  <div>
    <div v-if="!loading">
      <h1>Give Reward</h1>
      <span>
        This functionality gives a reward to the selected account, which will be marked as paid in
        our db with current date and with payer as Greenspark.
      </span>
      <v-autocomplete
        v-model="selectedAccountId"
        :items="accounts.map((account) => account.accountId)"
        :item-title="getAccountId"
        :item-value="getAccountId"
        :label="'Accounts'"
        variant="outlined"
        color="#3B755F"
        item-color="#3B755F"
        class="dropdown"
        :rules="[rules.required]"
      />
      <v-text-field
        id="Reason"
        v-model="reason"
        class="input-field"
        name="Reason"
        :rules="[rules.required]"
        :label="'Reason'"
        variant="outlined"
        flat
        color="green"
        hide-details
      />
      <section class="impact-settings-section">
        <ImpactSettings :model-value="impacts" @update:model-value="onImpactSettingChange" />
      </section>
      <gs-button
        :disabled="
          loading || !selectedAccountId || !reason || !impacts.some(({ amount }) => amount)
        "
        :loading="loading"
        :full-width="true"
        size="large"
        @click.prevent="giveRewardForAccount"
      >
        Save
      </gs-button>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script lang="ts">
import type { AccountDto, PaymentPayloadImpact } from '@api/index'
import { createReward, fetchAllAccount } from '@api/index'
import Loading from '@/components/tools/Loading.vue'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { Notification } from '@/store/notification'
import ImpactSettings from '@/components/onboarding/ImpactSettings.vue'
import type { Project } from '@/store/projects'
import { defineComponent } from 'vue'
import type { Account } from '@/store'

export default defineComponent({
  name: 'GiveRewardView',
  components: { ImpactSettings, Loading },
  mixins: [RulesMixin],
  data() {
    return {
      loading: false,
      selectedAccountId: '',
      reason: '',
      impacts: [],
      accounts: [],
    } as {
      loading: boolean
      selectedAccountId: string
      reason: string
      impacts: PaymentPayloadImpact[]
      accounts: AccountDto[]
    }
  },
  async created() {
    this.impacts = this.projects.map((project) => ({
      type: project.type,
      projectId: project.projectId,
      amount: 0,
    }))
    this.loading = true
    this.accounts = await fetchAllAccount()
    this.loading = false
  },
  methods: {
    onImpactSettingChange(value: Required<PaymentPayloadImpact>[]) {
      this.impacts = value
    },
    async giveRewardForAccount() {
      this.loading = true
      try {
        const account = this.accounts.find(
          (account) => account.accountId === this.selectedAccountId,
        )
        if (account) {
          const filteredImpactSetting = this.impacts.filter(({ amount }) => amount)
          await createReward(account.accountId, filteredImpactSetting, this.reason)
          this.selectedAccountId = ''
          this.reason = ''
          this.impacts = this.impacts.map((impact) => ({ ...impact, amount: 0 }))
          this.$store.dispatch('notification/notify', { text: 'Success' } as Notification)
        }
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: 'Error',
          isError: true,
        } as Notification)
      } finally {
        this.loading = false
      }
    },
    getAccountId(account: Account): string {
      return account.accountId
    },
  },
  computed: {
    projects(): Project[] {
      return this.$store.getters['getPartnerProjects']
    },
  },
})
</script>

<style lang="scss" scoped>
.dropdown {
  margin: 10px 0;
}

.input-field {
  margin: 10px 0;
}
</style>
