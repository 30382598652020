<template>
  <v-dialog
    :model-value="isOpen"
    @update:model-value="(v) => emit('update:is-open', v)"
    width="700"
    transition="fade"
    :fullscreen="isMobile || isMobileLarge"
  >
    <template v-slot:default>
      <v-card class="rebuy-setup-container">
        <v-card-title class="popup-header">
          <h5 class="popup-title">{{ isShopifyActive ? t('setup_title') : t('default_title') }}</h5>
          <div class="close-button-wrapper">
            <v-icon size="26px" color="black" @click="close"> mdi-close </v-icon>
          </div>
        </v-card-title>

        <v-card-text class="popup-content">
          <div class="setup-instruction" v-if="isShopifyActive">
            <div class="rebuy-disclaimer">
              <v-icon>mdi-information-outline</v-icon>
              <p class="mb-0">{{ t('rebuy_disclaimer') }}</p>
            </div>

            <p class="tutorial-intro">{{ t('in_order_to_use') }}</p>

            <ol class="instruction-list">
              <p class="tutorial-title">{{ t('setting_up') }}</p>
              <li v-if="!hasWidgetApiKey">
                {{ t('setup_tutorial.step_1') }}
                <router-link :to="{ name: 'Account', query: { tab: 'apiKey' } }">
                  {{ t('setup_tutorial.access_dashboard') }}
                  <v-icon color="#0017B4" size="20px">mdi-open-in-new</v-icon>
                </router-link>
              </li>
              <li>
                {{ t(hasWidgetApiKey ? 'setup_tutorial.step_2_1' : 'setup_tutorial.step_2_1_alt') }}
                <a :href="REBUY_URL" target="_blank">
                  {{ t('setup_tutorial.login_to_rebuy') }}
                  <v-icon color="#0017B4" size="20px">mdi-open-in-new</v-icon></a
                >
                {{ t('setup_tutorial.step_2_3') }}
              </li>
              <li>
                {{ t('setup_tutorial.step_3') }}
                <a :href="REBUY_INSTALLATION_GUIDE_URL" target="_blank">
                  {{ t('setup_tutorial.installation_guide') }}
                  <v-icon color="#0017B4" size="20px">mdi-open-in-new</v-icon>
                </a>
              </li>
              <li>
                <strong>{{ t('setup_tutorial.all_set') }}</strong> {{ t('setup_tutorial.step_4') }}
              </li>
            </ol>
          </div>

          <div class="shopify-warning" v-else>
            <v-icon>mdi-information-outline</v-icon>
            <p>
              <strong>{{ t('shopify_required_warning') }}</strong>
              {{ t('shopify_required_feedback') }}
            </p>
          </div>
        </v-card-text>

        <v-card-actions class="popup-actions">
          <gs-button
            v-if="isShopifyActive"
            full-width
            capitalized
            :uppercased="false"
            size="large"
            type="primary-v2"
            href="/shareables/badges?compatibility=rebuy"
          >
            {{ t('setup_complete') }}
          </gs-button>
          <gs-button
            v-else
            full-width
            capitalized
            :uppercased="false"
            size="large"
            type="primary-v2"
            href="/add-impact/manage-integrations-automations/integrations"
          >
            {{ t('setup_shopify_cta') }}
          </gs-button>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import { REBUY_URL, REBUY_INSTALLATION_GUIDE_URL } from '@/helpers/constants'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { getApiKeys } from '@api/index'

import type { ApiKeyResponse } from '@api/index'
import type { Notification } from '@/store/notification'

const store = useStore()
const i18n = useI18n()

const isMobile = ref<boolean>(Utils.isMobile)
const isMobileLarge = ref<boolean>(Utils.isMobileLarge)
const apiKeys = ref<ApiKeyResponse[]>([])

const emit = defineEmits(['update:is-open'])
const { isOpen } = defineProps<{
  isOpen: boolean
}>()

const isShopifyActive = computed(() => store.getters['getIsShopifyActive'])
const hasWidgetApiKey = computed<boolean>(() => {
  return !!apiKeys.value.find((k) => k.subType === 'WIDGET_API_KEY')
})

const fetchApiKeys = async () => {
  try {
    const { data } = await getApiKeys()
    apiKeys.value = data
  } catch (error) {
    store.dispatch('notification/notify', {
      text: 'An unexpected error occurred while trying to retrieve your API keys. Please try again or get in touch.',
      isError: true,
    } as Notification)
    console.error(error)
  }
}
const t = (key: string) => i18n.t(`RebuyIntegrationPopup.${key}`)
const close = () => emit('update:is-open', false)

await fetchApiKeys()
</script>

<style lang="scss" scoped>
.v-dialog .v-card.rebuy-setup-container {
  border-radius: 8px;
  padding: 24px;

  > .v-card-text.popup-content,
  > .v-card-actions.popup-actions,
  > .v-card-title.popup-header {
    padding: 0;
  }
}

.popup-header {
  position: relative;
  margin-bottom: 16px;

  .popup-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  .close-button-wrapper {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.popup-content {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;

  .shopify-warning {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .rebuy-disclaimer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
    margin-bottom: 16px;
    background-color: var(--ui-opaque-green);
  }

  .tutorial-intro {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 24px;
  }

  .instruction-list {
    background-color: var(--ui-white);
    border-radius: 8px;
    padding: 8px;

    .tutorial-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      margin-bottom: 16px;
    }

    li {
      width: auto;
      position: relative;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
      padding-inline-start: 16px;
      margin-inline-start: 19px;
      z-index: 1;

      &:not(:last-of-type) {
        margin-bottom: 16px;
      }

      &::before {
        content: url("data:image/svg+xml,%3Csvg width='27' height='26' viewBox='0 0 27 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27 13.0555C27 20.2658 22.051 26 15.7261 26C4.41401 26 0 19.0469 0 13.0555C0 5.21479 4.64331 0 10.7771 0C19.3949 0 27 5.84514 27 13.0555Z' fill='%2347D18F' /%3E%3C/svg%3E");
        width: 27px;
        height: 26px;
        position: absolute;
        top: -2px;
        left: -19px;
        z-index: -1;
      }

      &::marker {
        content: counter(list-item);
        position: relative;
        font-size: 18px;
        font-weight: 700;
      }

      a {
        font-weight: 700;
        color: var(--ui-dark-blue);
      }
    }
  }
}
</style>
