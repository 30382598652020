import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f4845e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-menu-wrapper" }
const _hoisted_2 = { class: "menu-item-text" }
const _hoisted_3 = { class: "menu-item-text" }
const _hoisted_4 = { class: "menu-item-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.menuItem === 0 ? 'menu-item-active' : 'menu-item'),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('homeClick')))
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('impact')), 1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.menuItem === 1 ? 'menu-item-active' : 'menu-item'),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('projectsClick')))
    }, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('projects')), 1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.menuItem === 2 ? 'menu-item-active' : 'menu-item'),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('ledgerClick')))
    }, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('public_ledger')), 1)
    ], 2)
  ]))
}