<template>
  <div class="how-it-works-wrapper">
    <div style="margin-bottom: 30px">
      <div v-if="header !== '' && header !== undefined" class="how-it-works-header">
        {{ header }}
      </div>
      <div v-if="subheader !== '' && subheader !== undefined" class="how-it-works-text">
        {{ subheader }}
      </div>
    </div>

    <!-- <div class="divider-line" /> -->

    <div class="how-it-works-subheader">
      {{ t('how_it_works') }}
    </div>

    <div v-if="text !== '' && text !== undefined" class="number-explanation-wrapper">
      <div class="number">1</div>
      <div class="explanation">
        {{ text }}
      </div>
    </div>

    <div v-if="text1 !== '' && text1 !== undefined" class="number-explanation-wrapper">
      <div class="number">2</div>
      <div class="explanation">
        {{ text1 }}
      </div>
    </div>

    <div v-if="text2 !== '' && text2 !== undefined" class="number-explanation-wrapper">
      <div class="number">3</div>
      <div class="explanation">
        {{ text2 }}
      </div>
    </div>

    <div
      v-if="text3 !== '' && text3 !== undefined"
      class="number-explanation-wrapper"
      style="margin-bottom: 0"
    >
      <div class="number">4</div>
      <div class="explanation">
        {{ text3 }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HowItWorksUtility',
  components: {},
  methods: {
    t(key: string) {
      return this.$t(`HowItWorksUtility.${key}`)
    },
  },
  props: {
    header: {
      type: String as PropType<string>,
    },
    subheader: {
      type: String as PropType<string>,
    },
    text: {
      type: String as PropType<string>,
    },
    text1: {
      type: String as PropType<string>,
    },
    text2: {
      type: String as PropType<string>,
    },
    text3: {
      type: String as PropType<string>,
    },
  },
})
</script>

<style lang="scss" scoped>
hr {
  opacity: 0.2;
  margin-top: 14px;
}

.how-it-works-header {
  color: #212121;
  font-size: 38px;
  line-height: 48px;
  font-weight: 600;
}

.how-it-works-text {
  color: #212121;
  font-family: Cabin;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin-top: 4px;
}

.how-it-works-subheader {
  color: #212121;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 16px;
}

.number-explanation-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.number {
  background: var(--ui-beige);
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212121;
  margin-right: 8px;
  font-family: Cabin;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.explanation {
  font-family: Cabin;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
</style>
