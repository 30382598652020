import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-481e9fe8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "v-panel-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(['v-panel', _ctx.padding, _ctx.background])
  }, [
    (_ctx.$slots.title)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, [
          _renderSlot(_ctx.$slots, "title", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}