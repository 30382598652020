import firebase from '@/auth/firebaseConfig.js'
import store, { getLocalStorageKey } from '@/store'
import { AUTH_TOKEN_COOKIE, clearCookie } from '@/helpers/cookie'
import i18n from '@/i18n'
import { Dialogs } from '@/store/dialog'
import router from '@/router'
import * as Sentry from '@sentry/vue'

import type { AxiosRequestConfig } from 'axios'
import type { Notification } from '@/store/notification'

// If there is currently a authenticated user, then return their JSON Web token
export const getAuthToken = async (): Promise<string | undefined> => {
  const user = await firebase.getCurrentUser()
  if (user) {
    return user.getIdToken()
  }
}

// Request interceptor that injects Firebase's JSON Web token into the request's headers
export async function tokenInterceptor(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  const token = await getAuthToken()
  if (!token) return config

  return {
    ...config,
    headers: {
      AuthToken: token,
    },
  }
}

export const redirectToLogin = () => {
  const account = store.state.account
  store.commit('setPublicToken', '')
  clearCookie(AUTH_TOKEN_COOKIE)
  window.localStorage.removeItem(getLocalStorageKey())
  indexedDB.deleteDatabase('firebase-installations-database')
  indexedDB.deleteDatabase('firebaseLocalStorageDb')

  if (router.currentRoute.value.name === 'Login') return
  if (account.userFromMarketplace) {
    return router.push('/login?app')
  } else {
    return router.push('/login')
  }
}

export const logOutAndRedirect = async () => {
  try {
    await firebase.auth().signOut()
    await redirectToLogin()
    store.dispatch('notification/notify', {
      text: i18n.global.t('CommonUi.error_generic_auth'),
      isError: true,
      buttonText: 'close',
    } as Notification)
  } catch (error) {
    await redirectToLogin()
    throw error
  }
}

export const authInterceptor = async function (error) {
  const isTokenRevoked = error?.response?.data?.message === 'TOKEN_REVOKED'
  if (isTokenRevoked) {
    store.dispatch('openDialog', {
      name: Dialogs.CUSTOM.PROJECT_REFACTOR_LOGOUT,
      options: {
        persistent: true,
      },
    })
    return Promise.reject(error)
  }

  const isAuthInvalid = error?.response?.status === 401

  if (isAuthInvalid) {
    try {
      logOutAndRedirect()
      return Promise.reject(error)
    } catch (logOutError) {
      console.error('Auth interceptor -', logOutError)
      return Promise.reject(logOutError)
    }
  }

  const firebaseUser = await firebase.getCurrentUser()
  Sentry.captureException(error, {
    user: { id: firebaseUser?.uid },
    extra: { ...JSON.parse(JSON.stringify(error)) },
  })
  return Promise.reject(error)
}
