<template>
  <div :class="['setting-card', { 'is-active': isActive, invalid: hasError }]">
    <div class="setting-card-alert">
      <slot name="alert" />
    </div>
    <div class="setting-card-wrapper">
      <div class="setting-card-text-wrapper">
        <div class="setting-card-title-wrapper">
          <div class="checkmark">
            <v-icon class="checkmark-icon" :icon="hasError ? 'mdi-exclamation' : 'mdi-check'" />
          </div>
          <p class="card-title">
            <slot name="title" />
          </p>
          <span v-if="activeAmount" class="setting-card-pill setting-card-pill--green">
            {{ tc('active', activeAmount) }}
          </span>
          <span v-if="hasError" class="setting-card-pill setting-card-pill--red">
            {{ tc('not_valid') }}
          </span>
          <span v-if="isRequired" class="setting-card-pill">
            {{ tc('required') }}
          </span>
        </div>
        <p class="card-description">
          <slot name="desc" />
        </p>
      </div>
      <slot name="button">
        <gs-button :type="isActive ? 'secondary' : 'primary'" @click="$emit('button-click')">
          {{ buttonText }}
        </gs-button>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SettingCard',
  emits: ['button-click'],
  components: {},
  methods: {
    tc(key: string, amount?: number) {
      return this.$t(`SettingCard.${key}`, amount ?? 0)
    },
  },
  props: {
    isActive: {
      type: Boolean,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    activeAmount: {
      type: Number,
    },
    buttonText: {
      type: String as PropType<string>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.setting-card {
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 15px;
  padding: 16px 24px;

  &.invalid {
    border-color: var(--red);
  }

  &.is-active {
    border-color: var(--ui-green);
  }

  :deep(.gs-alert) {
    margin-bottom: 10px;
  }
}

.setting-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.setting-card-text-wrapper {
  max-width: 50ch;
}

.setting-card-title-wrapper {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
}

.checkmark {
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid var(--ui-black);
  height: 20px;
  margin-right: 6px;
  position: relative;
  width: 20px;
}

.setting-card.is-active .checkmark {
  background-color: var(--ui-green);
  border: none;
}

.setting-card.invalid .checkmark {
  background-color: var(--red);
  border: none;
}

.checkmark-icon {
  color: white;
  font-size: 16px;
  line-height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 50%;
  right: 50%;
  transform: translate(-50%, calc(-50% + 1px));
}

.card-title {
  color: var(--ui-black);
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}

.setting-card-pill {
  background-color: var(--ui-black);
  color: white;
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin: 0 0 0 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;

  &--red {
    background-color: var(--red);
    color: white;
  }

  &--green {
    background-color: var(--ui-green);
    color: var(--ui-beige);
  }
}

.card-description {
  color: var(--ui-dark-gray);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .setting-card-wrapper {
    flex-direction: row;
  }
}
</style>
