import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-578f0480"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "description" }
const _hoisted_3 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.t('header')), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('description')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_gs_button, {
        class: "mb-4",
        type: 'primary',
        size: 'large',
        disabled: _ctx.loading,
        loading: _ctx.loading,
        onClick: _ctx.goToShopifyUrl
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('continue')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "loading", "onClick"]),
      (!_ctx.account.userFromMarketplace)
        ? (_openBlock(), _createBlock(_component_gs_button, {
            key: 0,
            class: "cancel-button",
            type: 'link',
            loading: _ctx.loading,
            disabled: _ctx.loading,
            onClick: _ctx.onClickCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('cancel')), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled", "onClick"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}