<template>
  <div v-if="loaded" class="signup">
    <div class="signup-wrapper">
      <ChooseFamilyPlan
        v-if="progressItem === 0"
        :account="account"
        :earth-positive-family-adult-price="earthPositiveFamilyAdultPrice"
        :earth-positive-family-child-price="earthPositiveFamilyChildPrice"
        :earth-positive-family-pet-price="earthPositiveFamilyPetPrice"
        :positive-family-adult-price="positiveFamilyAdultPrice"
        :positive-family-child-price="positiveFamilyChildPrice"
        :positive-family-pet-price="positiveFamilyPetPrice"
        @nextClick="progressItem++"
      />

      <AddOnsPersonal
        v-if="progressItem === 1"
        key="1"
        @backClick="progressItem--"
        @nextClick="progressItem++"
      />

      <UserInfo1
        v-if="progressItem === 2"
        key="2"
        @backClick="progressItem--"
        @nextClick="progressItem++"
      />

      <Signup1
        v-if="progressItem === 3"
        key="3"
        @backClick="progressItem--"
        @checkoutClick="checkout()"
        @setLineItemsClick="setLineItems()"
      />

      <div class="totals-wrapper">
        <TotalsFamily
          :earth-positive-family-adult-price="earthPositiveFamilyAdultPrice"
          :earth-positive-family-child-price="earthPositiveFamilyChildPrice"
          :earth-positive-family-pet-price="earthPositiveFamilyPetPrice"
          :positive-family-adult-price="positiveFamilyAdultPrice"
          :positive-family-child-price="positiveFamilyChildPrice"
          :positive-family-pet-price="positiveFamilyPetPrice"
          :progress-item="progressItem"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { startCheckout } from '@api/index'
import ChooseFamilyPlan from '@/components/register/ChooseFamilyPlan.vue'
import AddOnsPersonal from '@/components/register/AddOnsPersonal.vue'
import UserInfo1 from '@/components/register/UserInfo1.vue'
import Signup1 from '@/components/register/Signup1.vue'
import TotalsFamily from '@/components/register/TotalsFamily.vue'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import type { Account, StripeURLs } from '@/store'
import type { Currency, Emptyable } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { Stripe } from '@stripe/stripe-js/dist/stripe-js'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SignupFamilyView',
  components: {
    ChooseFamilyPlan,
    AddOnsPersonal,
    UserInfo1,
    Signup1,
    TotalsFamily,
  },
  mixins: [LoadCrispMixin, Utils],
  data() {
    return {
      progressItem: 0,
      lineItems: [],
      stripe: null,
      currency: '',
      location: null,
      positiveFamilyAdultPrice: 0,
      positiveFamilyChildPrice: 0,
      positiveFamilyPetPrice: 0,
      earthPositiveFamilyAdultPrice: 0,
      earthPositiveFamilyChildPrice: 0,
      earthPositiveFamilyPetPrice: 0,
      loaded: false,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      sessionId: '',
    } as {
      progressItem: number
      lineItems: {
        price: string
        quantity: number
        tax_rates?: string[]
      }[]
      stripe: Stripe | null
      currency: Emptyable<Currency>
      location: string | null
      positiveFamilyAdultPrice: number
      positiveFamilyChildPrice: number
      positiveFamilyPetPrice: number
      earthPositiveFamilyAdultPrice: number
      earthPositiveFamilyChildPrice: number
      earthPositiveFamilyPetPrice: number
      loaded: boolean
      publishableKey: string
      sessionId: string
    }
  },
  async created() {
    try {
      this.location = await this.getLocation()
    } catch (error) {
      this.location = ''
    }
    this.currency = await this.getCurrencyByLocation(this.location)
    this.setPrices()
    this.toggleChatBubbleVisibility(true)
    this.loaded = true
  },
  methods: {
    t(key: string) {
      return this.$t(`SignupFamilyView.${key}`)
    },
    setPrices() {
      switch (this.currency) {
        case 'euro':
          this.positiveFamilyAdultPrice = 6.0
          this.positiveFamilyChildPrice = 3.0
          this.positiveFamilyPetPrice = 0.7
          this.earthPositiveFamilyAdultPrice = 11.5
          this.earthPositiveFamilyChildPrice = 5.75
          this.earthPositiveFamilyPetPrice = 1.2
          break
        case 'pound':
          this.positiveFamilyAdultPrice = 4.8
          this.positiveFamilyChildPrice = 2.4
          this.positiveFamilyPetPrice = 0.8
          this.earthPositiveFamilyAdultPrice = 9.2
          this.earthPositiveFamilyChildPrice = 4.6
          this.earthPositiveFamilyPetPrice = 1.0
          break
        case 'dollar':
          this.positiveFamilyAdultPrice = 6.5
          this.positiveFamilyChildPrice = 3.25
          this.positiveFamilyPetPrice = 0.95
          this.earthPositiveFamilyAdultPrice = 12.5
          this.earthPositiveFamilyChildPrice = 6.25
          this.earthPositiveFamilyPetPrice = 1.8
          break
      }

      this.setAccount({ currency: this.getCurrencyByLocation(this.location ?? '') })
    },
    setLineItems() {
      if (this.progressItem !== 3) {
        this.lineItems = []
      } else {
        const positiveFamilyAdult = this.account.personalPlans.positiveFamilyAdult || 0

        const positiveFamilyChild = this.account.personalPlans.positiveFamilyChild || 0

        const positiveFamilyPet = this.account.personalPlans.positiveFamilyPet || 0

        const earthPositiveFamilyAdult = this.account.personalPlans.earthPositiveFamilyAdult || 0

        const earthPositiveFamilyChild = this.account.personalPlans.earthPositiveFamilyChild || 0

        const earthPositiveFamilyPet = this.account.personalPlans.earthPositiveFamilyPet || 0

        let treeAddon = this.account.personalPlanAddons.treeAddon
        treeAddon = treeAddon === null ? 0 : treeAddon

        this.lineItems = []

        if (
          process.env.VUE_APP_ENVIRONMENT === 'development' ||
          process.env.VUE_APP_ENVIRONMENT === 'local'
        ) {
          switch (this.account.currency) {
            case 'euro':
              //   // Positive Family Adult quantity
              if (this.account.personalPlans.positiveFamilyAdult) {
                const payload = {
                  price: 'price_1IxCWQHinkcuNuApVIqW4vr9', // price id from dashboard
                  quantity: positiveFamilyAdult,
                }
                this.lineItems.push(payload)
              }

              // Positive Family Child quantity
              if (this.account.personalPlans.positiveFamilyChild) {
                const payload = {
                  price: 'price_1IxDE2HinkcuNuApnu5tqtVS', // price id from dashboard
                  quantity: positiveFamilyChild,
                }
                this.lineItems.push(payload)
              }

              // Positive Family Pet quantity
              if (this.account.personalPlans.positiveFamilyPet) {
                const payload = {
                  price: 'price_1IxDEoHinkcuNuApSeBNAReq', // price id from dashboard
                  quantity: positiveFamilyPet,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Adult quantity
              if (this.account.personalPlans.earthPositiveFamilyAdult) {
                const payload = {
                  price: 'price_1IxF9VHinkcuNuApXy3tnePg', // price id from dashboard
                  quantity: earthPositiveFamilyAdult,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Child quantity
              if (this.account.personalPlans.earthPositiveFamilyChild) {
                const payload = {
                  price: 'price_1IxFABHinkcuNuApjeshOWgl', // price id from dashboard
                  quantity: earthPositiveFamilyChild,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Pet quantity
              if (this.account.personalPlans.earthPositiveFamilyPet) {
                const payload = {
                  price: 'price_1IxFAiHinkcuNuApdLNqGLAc', // price id from dashboard
                  quantity: earthPositiveFamilyPet,
                }
                this.lineItems.push(payload)
              }

              // treeAddon quantity
              if (this.account.personalPlanAddons.treeAddon) {
                const payload = {
                  price: 'price_1IwNrkHinkcuNuAp3Ac7Oub1', // price id from dashboard
                  quantity: treeAddon,
                }
                this.lineItems.push(payload)
              }
              break
            case 'pound':
              //   // Positive Family Adult quantity
              if (this.account.personalPlans.positiveFamilyAdult) {
                const payload = {
                  price: 'price_1IxCWQHinkcuNuAp72xqQ9qG', // price id from dashboard
                  tax_rates: ['txr_1IkFArHinkcuNuApWZpkvxIF'],
                  quantity: positiveFamilyAdult,
                }
                this.lineItems.push(payload)
              }

              // Positive Family Child quantity
              if (this.account.personalPlans.positiveFamilyChild) {
                const payload = {
                  price: 'price_1IxDE2HinkcuNuApWFuIIVBE', // price id from dashboard
                  tax_rates: ['txr_1IkFArHinkcuNuApWZpkvxIF'],
                  quantity: positiveFamilyChild,
                }
                this.lineItems.push(payload)
              }

              // Positive Family Pet quantity
              if (this.account.personalPlans.positiveFamilyPet) {
                const payload = {
                  price: 'price_1IxDEoHinkcuNuApQHwzE6sR', // price id from dashboard
                  tax_rates: ['txr_1IkFArHinkcuNuApWZpkvxIF'],
                  quantity: positiveFamilyPet,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Adult quantity
              if (this.account.personalPlans.earthPositiveFamilyAdult) {
                const payload = {
                  price: 'price_1IxF9VHinkcuNuApZ19bYMbz', // price id from dashboard
                  tax_rates: ['txr_1IkFArHinkcuNuApWZpkvxIF'],
                  quantity: earthPositiveFamilyAdult,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Child quantity
              if (this.account.personalPlans.earthPositiveFamilyChild) {
                const payload = {
                  price: 'price_1IxFABHinkcuNuApjtZc0ssK', // price id from dashboard
                  tax_rates: ['txr_1IkFArHinkcuNuApWZpkvxIF'],
                  quantity: earthPositiveFamilyChild,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Pet quantity
              if (this.account.personalPlans.earthPositiveFamilyPet) {
                const payload = {
                  price: 'price_1IxFAiHinkcuNuAp2wGAOmKP', // price id from dashboard
                  tax_rates: ['txr_1IkFArHinkcuNuApWZpkvxIF'],
                  quantity: earthPositiveFamilyPet,
                }
                this.lineItems.push(payload)
              }

              // treeAddon quantity
              if (this.account.personalPlanAddons.treeAddon) {
                const payload = {
                  price: 'price_1IwNrkHinkcuNuApEEwGUwpG', // price id from dashboard
                  tax_rates: ['txr_1IkFArHinkcuNuApWZpkvxIF'],
                  quantity: treeAddon,
                }
                this.lineItems.push(payload)
              }
              break
            case 'dollar':
              //   // Positive Family Adult quantity
              if (this.account.personalPlans.positiveFamilyAdult) {
                const payload = {
                  price: 'price_1IxCWQHinkcuNuApz6u0J3U3', // price id from dashboard
                  quantity: positiveFamilyAdult,
                }
                this.lineItems.push(payload)
              }

              // Positive Family Child quantity
              if (this.account.personalPlans.positiveFamilyChild) {
                const payload = {
                  price: 'price_1IxDE2HinkcuNuApLRCyEfQd', // price id from dashboard
                  quantity: positiveFamilyChild,
                }
                this.lineItems.push(payload)
              }

              // Positive Family Pet quantity
              if (this.account.personalPlans.positiveFamilyPet) {
                const payload = {
                  price: 'price_1IxDEoHinkcuNuApoEdr8c6S', // price id from dashboard
                  quantity: positiveFamilyPet,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Adult quantity
              if (this.account.personalPlans.earthPositiveFamilyAdult) {
                const payload = {
                  price: 'price_1IxF9VHinkcuNuAp6IdKm9YO', // price id from dashboard
                  quantity: earthPositiveFamilyAdult,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Child quantity
              if (this.account.personalPlans.earthPositiveFamilyChild) {
                const payload = {
                  price: 'price_1IxFABHinkcuNuApmwsa0mOv', // price id from dashboard
                  quantity: earthPositiveFamilyChild,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Pet quantity
              if (this.account.personalPlans.earthPositiveFamilyPet) {
                const payload = {
                  price: 'price_1IxFAiHinkcuNuApb6ET7rg7', // price id from dashboard
                  quantity: earthPositiveFamilyPet,
                }
                this.lineItems.push(payload)
              }

              // treeAddon quantity
              if (this.account.personalPlanAddons.treeAddon) {
                const payload = {
                  price: 'price_1IwNrkHinkcuNuApodHvgr9M', // price id from dashboard
                  quantity: treeAddon,
                }
                this.lineItems.push(payload)
              }
              break
          }
        } else if (process.env.VUE_APP_ENVIRONMENT === 'production') {
          switch (this.account.currency) {
            case 'euro':
              // Positive Family Adult quantity
              if (this.account.personalPlans.positiveFamilyAdult) {
                const payload = {
                  price: 'price_1J2Dn9HinkcuNuApu1umoOEH', // price id from dashboard
                  quantity: positiveFamilyAdult,
                }
                this.lineItems.push(payload)
              }

              // Positive Family Child quantity
              if (this.account.personalPlans.positiveFamilyChild) {
                const payload = {
                  price: 'price_1J2DkqHinkcuNuApAaQD07IY', // price id from dashboard
                  quantity: positiveFamilyChild,
                }
                this.lineItems.push(payload)
              }

              // Positive Family Pet quantity
              if (this.account.personalPlans.positiveFamilyPet) {
                const payload = {
                  price: 'price_1J2Dj1HinkcuNuApOeoBUJmj', // price id from dashboard
                  quantity: positiveFamilyPet,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Adult quantity
              if (this.account.personalPlans.earthPositiveFamilyAdult) {
                const payload = {
                  price: 'price_1J2DhVHinkcuNuApeoxq3KhC', // price id from dashboard
                  quantity: earthPositiveFamilyAdult,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Child quantity
              if (this.account.personalPlans.earthPositiveFamilyChild) {
                const payload = {
                  price: 'price_1J2Dd9HinkcuNuApIIpY0IE3', // price id from dashboard
                  quantity: earthPositiveFamilyChild,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Pet quantity
              if (this.account.personalPlans.earthPositiveFamilyPet) {
                const payload = {
                  price: 'price_1J2D52HinkcuNuApK8hOcFVP', // price id from dashboard
                  quantity: earthPositiveFamilyPet,
                }
                this.lineItems.push(payload)
              }

              // treeAddon quantity
              if (this.account.personalPlanAddons.treeAddon) {
                const payload = {
                  price: 'price_1J2Dp8HinkcuNuApntTnFoq3', // price id from dashboard
                  quantity: treeAddon,
                }
                this.lineItems.push(payload)
              }
              break
            case 'pound':
              //   // Positive Family Adult quantity
              if (this.account.personalPlans.positiveFamilyAdult) {
                const payload = {
                  price: 'price_1J2Dn9HinkcuNuApKkfeE1V4', // price id from dashboard
                  tax_rates: ['txr_1J2CwcHinkcuNuApZx7IKjFV'],
                  quantity: positiveFamilyAdult,
                }
                this.lineItems.push(payload)
              }

              // Positive Family Child quantity
              if (this.account.personalPlans.positiveFamilyChild) {
                const payload = {
                  price: 'price_1J2DkrHinkcuNuApxQdoe8jt', // price id from dashboard
                  tax_rates: ['txr_1J2CwcHinkcuNuApZx7IKjFV'],
                  quantity: positiveFamilyChild,
                }
                this.lineItems.push(payload)
              }

              // Positive Family Pet quantity
              if (this.account.personalPlans.positiveFamilyPet) {
                const payload = {
                  price: 'price_1J2Dj1HinkcuNuApKNAPhAwO', // price id from dashboard
                  tax_rates: ['txr_1J2CwcHinkcuNuApZx7IKjFV'],
                  quantity: positiveFamilyPet,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Adult quantity
              if (this.account.personalPlans.earthPositiveFamilyAdult) {
                const payload = {
                  price: 'price_1J2DhVHinkcuNuApYRy3qykM', // price id from dashboard
                  tax_rates: ['txr_1J2CwcHinkcuNuApZx7IKjFV'],
                  quantity: earthPositiveFamilyAdult,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Child quantity
              if (this.account.personalPlans.earthPositiveFamilyChild) {
                const payload = {
                  price: 'price_1J2Dd9HinkcuNuApmIZOh5yc', // price id from dashboard
                  tax_rates: ['txr_1J2CwcHinkcuNuApZx7IKjFV'],
                  quantity: earthPositiveFamilyChild,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Pet quantity
              if (this.account.personalPlans.earthPositiveFamilyPet) {
                const payload = {
                  price: 'price_1J2D52HinkcuNuApC2kZgqou', // price id from dashboard
                  tax_rates: ['txr_1J2CwcHinkcuNuApZx7IKjFV'],
                  quantity: earthPositiveFamilyPet,
                }
                this.lineItems.push(payload)
              }

              // treeAddon quantity
              if (this.account.personalPlanAddons.treeAddon) {
                const payload = {
                  price: 'price_1J2Dp8HinkcuNuApLQR2nBC4', // price id from dashboard
                  tax_rates: ['txr_1J2CwcHinkcuNuApZx7IKjFV'],
                  quantity: treeAddon,
                }
                this.lineItems.push(payload)
              }
              break
            case 'dollar':
              //   // Positive Family Adult quantity
              if (this.account.personalPlans.positiveFamilyAdult) {
                const payload = {
                  price: 'price_1J2Dn9HinkcuNuApyOThJDRb', // price id from dashboard
                  quantity: positiveFamilyAdult,
                }
                this.lineItems.push(payload)
              }

              // Positive Family Child quantity
              if (this.account.personalPlans.positiveFamilyChild) {
                const payload = {
                  price: 'price_1J2DkqHinkcuNuApepL9CmKT', // price id from dashboard
                  quantity: positiveFamilyChild,
                }
                this.lineItems.push(payload)
              }

              // Positive Family Pet quantity
              if (this.account.personalPlans.positiveFamilyPet) {
                const payload = {
                  price: 'price_1J2Dj1HinkcuNuApN6N9ocVQ', // price id from dashboard
                  quantity: positiveFamilyPet,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Adult quantity
              if (this.account.personalPlans.earthPositiveFamilyAdult) {
                const payload = {
                  price: 'price_1J2DhVHinkcuNuAp4BDwwxEO', // price id from dashboard
                  quantity: earthPositiveFamilyAdult,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Child quantity
              if (this.account.personalPlans.earthPositiveFamilyChild) {
                const payload = {
                  price: 'price_1J2Dd9HinkcuNuApCQPXc6w2', // price id from dashboard
                  quantity: earthPositiveFamilyChild,
                }
                this.lineItems.push(payload)
              }

              // Earth Positive Family Pet quantity
              if (this.account.personalPlans.earthPositiveFamilyPet) {
                const payload = {
                  price: 'price_1J2D52HinkcuNuAptCfeVna8', // price id from dashboard
                  quantity: earthPositiveFamilyPet,
                }
                this.lineItems.push(payload)
              }

              // treeAddon quantity
              if (this.account.personalPlanAddons.treeAddon) {
                const payload = {
                  price: 'price_1J2Dp8HinkcuNuApp93vTG6e', // price id from dashboard
                  quantity: treeAddon,
                }
                this.lineItems.push(payload)
              }
              break
          }
        }

        for (let i = 0; i < this.lineItems.length; i++) {
          if (this.lineItems[i].quantity === 0) {
            this.lineItems.splice(i, 1)
            i--
          }
        }
      }
    },
    async checkout() {
      this.setLineItems()

      if (!this.account.emailAlreadyUsed && this.account.signupComplete) {
        const payload = {
          success_url: this.stripeURLs.successURLFamily,
          cancel_url: this.stripeURLs.cancelURLFamily,
          payment_method_types: ['card'],
          line_items: this.lineItems.filter((v) => v.quantity > 0),
          mode: 'subscription',
          client_reference_id:
            this.currentUser.firstName +
            '-' +
            this.currentUser.lastName +
            `${Math.floor(Math.random() * 11)}`,
          customer_email: this.currentUser.email,
        }

        const { data } = await startCheckout(payload)
        const stripeSession = data
        this.sessionId = stripeSession.id

        this.setSignupId(this.sessionId)

        // this.$refs.checkoutRef.redirectToCheckout()

        this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY ?? '')
        this.stripe.redirectToCheckout({ sessionId: this.sessionId })
      }
    },
    onPersonalPlanChange() {
      this.setLineItems()
    },
    onPlasticHeroPlanChange() {
      this.setLineItems()
    },
    onEarthHeroPlanChange() {
      this.setLineItems()
    },
    setSignupId(id) {
      return this.$store.commit('setSignupId', id)
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
  },
  watch: {
    'account.personalPlans.personal': [
      {
        handler: 'onPersonalPlanChange',
      },
    ],
    'account.personalPlans.plasticHero': [
      {
        handler: 'onPlasticHeroPlanChange',
      },
    ],
    'account.personalPlans.earthHero': [
      {
        handler: 'onEarthHeroPlanChange',
      },
    ],
  },
  computed: {
    stripeURLs(): StripeURLs {
      return this.$store.getters['getStripeURLs']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.signup {
  padding: 10vh 2%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url('../../assets/backgrounds/signup-background.svg');
  background-size: cover;
  background-position: center;
}

.signup-wrapper {
  max-width: 1300px;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 640px;
}

.totals-wrapper {
  margin-left: 18px;
}

.signup-form {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 20px 60px;
  background: #f9f9f9;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
}

.logo-wrapper {
  max-width: 220px;
}

.logo {
  max-width: 100%;
}

.header {
  color: #212121;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 22px;
}

.input-field {
  margin-bottom: 12px;
  width: 70%;
}

.button {
  margin-top: 15px;
}

.already-member {
  margin-top: 15px;
  font-size: 14px;
  color: #212121;
}

@media #{map-get($display-breakpoints, 'md')} {
  .totals-wrapper {
    width: 100%;
    margin: 0;
    z-index: 2;
  }

  .signup {
    padding: 40px;
    flex-direction: column;
    // min-height: unset;
    max-height: unset;
    min-height: 100vh;
  }

  .signup-wrapper {
    flex-direction: column;
    height: 100%;
  }

  .totals-wrapper {
    display: flex;
    justify-content: center;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .signup {
    padding: 0;
    flex-direction: column;
    // min-height: unset;
    max-height: unset;
    min-height: 100vh;
  }

  .signup-wrapper {
    flex-direction: column;
    height: 100%;
  }

  .totals-wrapper {
    margin: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
