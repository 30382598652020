import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Transition as _Transition, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-011dd0a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "how-it-works" }
const _hoisted_3 = { class: "impact-settings-section" }
const _hoisted_4 = {
  key: 1,
  class: "upgrade"
}
const _hoisted_5 = { class: "upgrade-title" }
const _hoisted_6 = { class: "upgrade-text" }
const _hoisted_7 = { class: "cart-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HowItWorks = _resolveComponent("HowItWorks")!
  const _component_ImpactSettings = _resolveComponent("ImpactSettings")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AddCart = _resolveComponent("AddCart")!
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.account.accountType !== 'personal' && _ctx.account.accountType !== 'family')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "back",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/add-impact')))
        }, [
          _createElementVNode("img", {
            class: "chevron",
            alt: "chevron",
            src: require('@/assets/icons/chevron_left.svg')
          }, null, 8, _hoisted_1),
          _createElementVNode("div", null, _toDisplayString(_ctx.t('back')), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_HowItWorks, {
        account: _ctx.account,
        onIntegrationClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/add-impact/manage-integrations-automations/integrations')))
      }, null, 8, ["account"])
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(_component_ImpactSettings, {
        "model-value": _ctx.localCart,
        "onUpdate:modelValue": _ctx.onImpactSettingChange
      }, null, 8, ["model-value", "onUpdate:modelValue"])
    ]),
    (
        _ctx.alertTypes.includes('noSubscription') &&
        _ctx.account.accountType !== 'business' &&
        _ctx.account.accountType !== 'ecommerce'
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.t('upgrade')), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.t('upgrade_text')), 1),
          _createVNode(_component_router_link, {
            to: "/upgrade-plan",
            class: "upgrade-button"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('upgrade')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.getIsCartOpen)
      ? (_openBlock(), _createBlock(_Transition, {
          key: 2,
          name: "fade"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_AddCart)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_Alert, {
      alert: _ctx.alert,
      error: _ctx.error,
      "show-buttons": _ctx.error,
      text: _ctx.error ? _ctx.t('error') : _ctx.t('success'),
      onCloseAlertClick: _cache[2] || (_cache[2] = ($event: any) => {;(_ctx.alert = false), (_ctx.error = false)})
    }, null, 8, ["alert", "error", "show-buttons", "text"])
  ]))
}