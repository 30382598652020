import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7094f613"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "details"
}
const _hoisted_3 = { class: "button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.alert)
      ? (_openBlock(), _createBlock(_component_v_alert, {
          key: 0,
          prominent: "",
          type: !_ctx.error ? 'success' : 'error'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { align: "center" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "flex-grow-1" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
                    (!!_ctx.details)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.details), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                (_ctx.showButtons)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 0,
                      class: "flex-shrink-1 flex-grow-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          class: "button",
                          width: "100%",
                          style: {"margin-bottom":"8px"},
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeAlertClick')))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.buttonText || _ctx.t('again')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_btn, {
                          class: "button",
                          width: "100%",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openChatAndTurnOffAlert()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('support')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["type"]))
      : _createCommentVNode("", true)
  ]))
}