<template>
  <svg
    :id="`public-map-pin-${pin.country}-${pin.type}${isStatic ? 'static' : ''}`"
    :class="[
      'public-map-pin',
      { 'is-active': isActive },
      { 'is-animated': isAnimated },
      { 'is-static': isStatic },
    ]"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 53 55"
    fill="none"
  >
    <g clip-path="url(#clip0_4558_28673)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.5 3C35.6093 3 46 9.94588 46 23.1882C46 31.9976 39.5093 41.2871 26.5 51C13.4907 41.2871 7 31.9976 7 23.1882C7 9.94588 17.3907 3 26.5 3Z"
        :fill="colorSettings[pin.type]"
      />
      <path
        v-if="pin.type === 'plastic'"
        d="M31 22.247H22V17.9838C22 16.6384 23.1815 15.4413 24.7745 15.4413H28.2255C29.8185 15.4413 31 16.6384 31 17.9838V22.247ZM27.8725 12.6316H25.1275V12.5182C25.1275 12.2908 25.3433 12 25.7451 12H27.2549C27.6567 12 27.8725 12.2908 27.8725 12.5182V12.6316ZM22 31.0283H31V33.4696C31 34.256 30.3013 35 29.3039 35H23.6961C22.6987 35 22 34.256 22 33.4696V31.0283ZM22 25.0567H31V28.2186H22V25.0567Z"
        fill="#FCFCFC"
        stroke="#FCFCFC"
        stroke-width="2"
      />
      <template v-if="pin.type === 'trees'">
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M32.9658 24.5266C32.9658 23.4226 32.3971 22.4466 31.5318 21.8706C31.9218 21.3906 32.1533 20.7866 32.1533 20.1266C32.1533 18.5786 30.8818 17.3266 29.3096 17.3266C29.2689 17.3266 29.2283 17.3306 29.1877 17.3346C29.2649 17.0786 29.3096 16.8106 29.3096 16.5266C29.3096 14.9786 28.038 13.7266 26.4658 13.7266C24.8936 13.7266 23.6221 14.9786 23.6221 16.5266C23.6221 16.8066 23.6668 17.0786 23.7439 17.3346C23.7033 17.3346 23.6627 17.3266 23.6221 17.3266C22.0499 17.3266 20.7783 18.5786 20.7783 20.1266C20.7783 20.7866 21.0139 21.3946 21.3999 21.8706C20.5346 22.4466 19.9658 23.4186 19.9658 24.5266C19.9658 25.6346 20.5346 26.6066 21.3999 27.1826C21.0099 27.6626 20.7783 28.2666 20.7783 28.9266C20.7783 30.4746 22.0499 31.7266 23.6221 31.7266H29.3096C30.8818 31.7266 32.1533 30.4746 32.1533 28.9266C32.1533 28.2666 31.9177 27.6586 31.5318 27.1826C32.3971 26.6066 32.9658 25.6346 32.9658 24.5266Z"
          fill="white"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M29.8391 25.6704C29.6858 25.5211 29.4381 25.5211 29.2848 25.6704L27.2056 27.6959V22.1095C27.2056 21.8989 27.0287 21.7266 26.8125 21.7266C26.5963 21.7266 26.4195 21.8989 26.4195 22.1095V24.8242L24.635 23.0858C24.4817 22.9365 24.2341 22.9365 24.0808 23.0858C23.9275 23.2352 23.9275 23.4764 24.0808 23.6257L26.4234 25.9078V31.142L24.7372 29.4994C24.5839 29.35 24.3363 29.35 24.183 29.4994C24.0297 29.6487 24.0297 29.8899 24.183 30.0393L26.4273 32.2256V39.3437C26.4273 39.5543 26.6042 39.7266 26.8204 39.7266C27.0366 39.7266 27.2134 39.5543 27.2134 39.3437V28.7527C27.241 28.7374 27.2724 28.7221 27.296 28.6991L29.8509 26.2103C30.0041 26.061 30.0041 25.8197 29.8509 25.6704H29.8391Z"
          fill="white"
        />
      </template>
      <template v-if="pin.type === 'kelp'">
        <path
          d="M28.1941 36.6864C28.1941 36.6864 25.9132 35.4288 27.0256 34.359C27.0256 34.359 27.8015 33.627 26.5302 32.8856C26.5302 32.8856 24.8335 32.0645 25.7029 31.1213C25.7029 31.1213 26.9555 30.3752 26.1843 29.3054C26.1843 29.3054 24.5952 28.2121 25.558 27.3112C25.558 27.3112 26.5068 26.8185 24.9223 25.4999C23.5903 24.3926 24.6139 23.6512 24.9738 23.4494C25.1046 23.3743 25.2028 23.257 25.2449 23.1116C25.3243 22.8441 25.2121 22.4546 24.0717 22.2951C22.2488 22.0417 24.9784 20.5777 24.9784 20.5777C24.9784 20.5777 26.0675 20.0757 24.6746 19.1513C24.6746 19.1513 23.8099 18.377 25.8104 17.7811C25.8104 17.7811 27.6333 17.2603 26.9135 15.7634C26.7873 15.5007 26.605 15.2707 26.3993 15.0784C26.2077 14.9001 25.9787 14.5669 26.5723 14.3182C26.5723 14.3182 27.3622 13.6754 26.1516 13.2953C26.1516 13.2953 25.4645 13.0794 25.8384 12.69C26.0675 12.446 26.1563 12.0988 26.0535 11.7797C25.8945 11.2964 25.8571 10.7051 26.5956 10.7051C26.5956 10.7051 28.3998 11.3386 27.8763 12.9903C27.8763 12.9903 27.5912 13.8959 28.6429 13.8021C29.6945 13.7082 28.7737 15.3364 28.7737 15.3364C28.7737 15.3364 28.4092 15.9464 29.2925 15.9324C30.1806 15.9183 28.7784 17.523 28.7784 17.523C28.7784 17.523 27.6005 18.5976 29.0822 19.2545C29.0822 19.2545 30.3161 19.705 28.3624 20.559C28.2315 20.6153 28.11 20.695 28.0165 20.7983C27.7735 21.061 27.5585 21.5725 28.554 22.2482C30.0264 23.243 28.2549 24.0641 28.2549 24.0641C28.2549 24.0641 27.3902 24.5286 28.7597 25.3967C30.1339 26.2648 29.1149 27.4144 29.1149 27.4144C29.1149 27.4144 28.3437 28.2731 29.1009 28.9629C29.8581 29.6526 28.8719 30.8632 28.8719 30.8632C28.8719 30.8632 28.0539 31.553 29.1149 32.2803C30.1712 33.0076 29.2458 33.8194 29.2458 33.8194C29.2458 33.8194 28.5353 34.5185 29.3066 35.0441C30.0778 35.5649 28.1941 36.6864 28.1941 36.6864Z"
          fill="white"
        />
        <path
          d="M28.1567 36.7051C28.1567 36.7051 29.5589 34.1994 28.6709 33.1859C26.9602 31.2198 29.0682 30.9617 29.0682 30.9617C29.0682 30.9617 30.9565 30.1031 29.9001 29.3382C27.6239 27.6818 29.3439 27.1188 29.3439 27.1188C29.3439 27.1188 31.3678 26.7105 30.3582 25.3404C30.3582 25.3404 29.0074 24.3221 30.0871 23.9327C30.6246 23.7403 30.7087 23.2805 30.6713 22.891C30.6433 22.6001 30.5031 22.3279 30.3021 22.1121C30.0497 21.8493 29.8347 21.3707 31.1902 21.1079C31.1902 21.1079 32.2652 20.8311 31.494 19.8363C30.7228 18.8415 32.1296 18.8979 32.1296 18.8979C32.1296 18.8979 32.7232 18.5929 32.354 17.8656C31.9847 17.1382 33.7188 17.1617 33.7188 17.1617C33.7188 17.1617 34.261 16.9459 33.8403 16.12C33.6487 15.7446 33.8403 15.2801 34.247 15.1628C34.2516 15.1628 34.261 15.1581 34.2657 15.1581C34.518 15.0971 34.6863 14.8719 34.691 14.6138C34.6957 14.1539 34.7845 13.5768 35.1958 13.7598C35.1958 13.7598 36.6354 14.6889 36.5232 16.9083C36.5232 16.9083 36.4718 17.8374 35.6959 18.499C35.6959 18.499 35.0182 18.8322 35.4155 19.8926C35.4155 19.8926 35.7987 21.061 35.0228 21.2675C34.247 21.4833 33.5505 22.0417 33.9151 22.7831C34.2797 23.5245 33.9946 23.7778 33.7048 23.9843C33.415 24.1955 32.8588 24.8758 33.7328 25.3404C34.6022 25.8049 33.2187 27.0202 33.2187 27.0202C33.2187 27.0202 32.4942 27.5645 33.2514 28.6203C33.6767 29.2162 33.5505 29.8027 33.3542 30.2016C33.2327 30.4456 33.0504 30.6567 32.8214 30.8069C32.555 30.9805 32.139 31.3653 32.2605 31.98C32.368 32.5008 32.6344 32.712 32.2184 33.2C31.9894 33.4674 31.6435 33.505 31.3397 33.6739C31.0406 33.8381 30.6012 34.2182 30.0404 35.1942C29.2364 36.6394 28.1567 36.7051 28.1567 36.7051Z"
          fill="white"
        />
        <path
          d="M28.1942 36.6864C28.5513 35.991 28.6266 35.412 28.6401 35.0577C28.6953 33.6247 27.8745 32.3756 27.5707 31.9129C27.1734 31.3081 26.95 31.1786 26.8107 30.6568C26.6443 30.0332 26.8229 29.5987 26.4989 29.2322C26.1465 28.8334 25.6469 28.8034 25.6469 28.8034C25.6469 28.8034 24.4644 28.503 25.2917 26.903C25.7964 25.9176 22.9733 26.5182 22.9733 26.5182C22.9733 26.5182 21.3001 26.6355 21.6132 24.7211C21.8329 23.365 20.2391 23.5621 20.2391 23.5621C20.2391 23.5621 19.6642 24.4254 19.0145 24.7211C18.8789 24.7821 18.7714 24.89 18.7107 25.0261C18.6032 25.2935 18.6592 25.7017 19.8277 25.9082C20.0194 25.9457 20.2063 26.0161 20.3653 26.1334C20.6737 26.3587 20.9822 26.8044 20.5756 27.588C20.5756 27.588 20.1035 28.7142 21.931 28.5124C23.5997 28.3341 23.1697 30.2345 23.0808 30.5723C23.0715 30.6193 23.0622 30.6709 23.0528 30.7178C23.0154 31.1401 23.0107 32.8434 25.3618 33.0499C26.255 33.1282 26.6284 33.5041 26.7158 33.5534C27.2935 33.8771 27.96 34.649 28.1942 36.6864Z"
          fill="white"
        />
        <path
          d="M20.239 23.5527C20.239 23.5527 17.8319 22.8535 17.9534 21.8682C17.9861 21.6101 18.1965 21.413 18.4582 21.3802C18.8041 21.3426 19.3369 21.1549 18.9209 20.3056C18.332 19.1185 18.0749 17.6075 19.1593 17.7999C19.1593 17.7999 19.9399 18.0392 19.8043 17.0961C19.8043 17.0961 19.3042 15.5382 20.3465 15.7635C20.3465 15.7635 20.7625 16.0028 20.7578 15.3506C20.7578 15.0362 20.8373 14.7265 20.9962 14.4543C21.2065 14.1024 21.4402 13.605 21.3935 13.2062C21.3935 13.2062 22.9967 14.9799 22.1039 16.5377C22.1039 16.5377 21.6459 17.0445 22.0899 17.8093C22.4218 18.3818 21.8702 19.0246 21.5758 19.3109C21.4075 19.4751 21.2907 19.6815 21.2486 19.9068C21.1972 20.1836 21.2206 20.5355 21.5711 20.7608C22.2816 21.2253 22.0292 21.901 21.8889 22.1638C21.8095 22.3092 21.7581 22.4735 21.744 22.633C21.702 23.0178 21.7955 23.609 22.6461 23.8248C23.5248 24.0501 23.0528 25.0401 23.0528 25.0401C23.0528 25.0401 22.3517 26.1522 24.7074 26.3305C24.7074 26.3305 21.5851 27.3441 21.5758 25.6267C21.5431 23.914 21.716 23.8624 20.239 23.5527Z"
          fill="white"
        />
        <path
          d="M21.3842 13.2108C21.3842 13.2108 19.6081 11.5732 20.7345 11.5873C21.8609 11.6014 22.3283 15.0971 22.3283 15.0971C22.3283 15.0971 21.9918 13.849 21.3842 13.2108Z"
          fill="white"
        />
      </template>
      <path
        v-if="pin.type === 'carbon'"
        xmlns="http://www.w3.org/2000/svg"
        d="M33.3258 18.7666C32.6358 15.3166 29.6058 12.7266 25.9658 12.7266C23.0758 12.7266 20.5658 14.3666 19.3158 16.7666C16.3058 17.0866 13.9658 19.6366 13.9658 22.7266C13.9658 26.0366 16.6558 28.7266 19.9658 28.7266H32.9658C35.7258 28.7266 37.9658 26.4866 37.9658 23.7266C37.9658 21.0866 35.9158 18.9466 33.3258 18.7666Z"
        fill="white"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import {
  IMPACT_TYPE_TOP_STAT_CARD_COLOR_MAP,
  IMPACT_TYPE_INACTIVE_TOP_STAT_CARD_COLOR_MAP,
} from '@/helpers/projects'

import type { OffsetType } from '@/helpers/interfaces'

export interface MapPin {
  index: number
  country: string
  type: OffsetType
  projectId: string
  availability: number | null
  link?: string
}

export default defineComponent({
  name: 'PublicMapPin',
  props: {
    pin: { type: Object as PropType<MapPin>, required: true },
    isActive: { type: Boolean, default: false },
    isAnimated: { type: Boolean, default: true },
    isStatic: { type: Boolean, default: false },
  },
  computed: {
    colorSettings(): Record<OffsetType, string> {
      return this.isActive
        ? IMPACT_TYPE_TOP_STAT_CARD_COLOR_MAP
        : IMPACT_TYPE_INACTIVE_TOP_STAT_CARD_COLOR_MAP
    },
  },
})
</script>

<style scoped lang="scss">
@import '~vuetify/settings';

.public-map-pin {
  position: absolute;
  opacity: 0;
  height: 20px;
  width: 20px;
  animation-fill-mode: forwards;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &.is-static {
    cursor: auto;
    position: relative;
    opacity: 1;
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    height: 45px;
    width: 45px;
  }

  &.is-active {
    width: 25px;
    height: 25px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      height: 50px;
      width: 50px;
    }
  }

  &.is-animated {
    animation-name: drop-in;
    animation-duration: 1s;
  }

  &:nth-child(1) {
    animation-delay: 0.1s;
  }

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.3s;
  }
}

@keyframes drop-in {
  0% {
    transform: translateY(-150px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
