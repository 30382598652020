import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34f2cdca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "copy-apy-key" }
const _hoisted_2 = { class: "integration-name-wrapper section bottom-border" }
const _hoisted_3 = { class: "label integration" }
const _hoisted_4 = { class: "integration-name-wrapper section bottom-border" }
const _hoisted_5 = { class: "label integration" }
const _hoisted_6 = { class: "section" }
const _hoisted_7 = { class: "label" }
const _hoisted_8 = { class: "input-wrapper" }
const _hoisted_9 = { class: "alert-text" }
const _hoisted_10 = { class: "section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OnboardingPill = _resolveComponent("OnboardingPill")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("dl", _hoisted_2, [
      _createElementVNode("dd", _hoisted_3, _toDisplayString(_ctx.t('integration')), 1),
      _createVNode(_component_OnboardingPill, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.customIntegrationType ? _ctx.getIntegrationPlatformName(_ctx.customIntegrationType) : ''), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("dl", _hoisted_4, [
      _createElementVNode("dd", _hoisted_5, _toDisplayString(_ctx.t('api_key_name', {
            source: _ctx.customIntegrationType
              ? _ctx.getIntegrationPlatformName(_ctx.customIntegrationType) || ''
              : '',
          })), 1),
      _createVNode(_component_OnboardingPill, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.apiKeyName), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t(_ctx.getLabelKey, {
            source: _ctx.customIntegrationType
              ? _ctx.getIntegrationPlatformName(_ctx.customIntegrationType) || ''
              : '',
          })), 1),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_v_text_field, {
          class: "apy-key-input",
          readonly: "",
          disabled: "",
          "hide-details": "",
          flat: "",
          color: "#323232",
          "model-value": _ctx.apiKey,
          variant: "outlined"
        }, null, 8, ["model-value"]),
        _createElementVNode("button", {
          class: "apy-key-button",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.copy && _ctx.copy(...args)), ["prevent"]))
        }, [
          _createVNode(_component_v_icon, { class: "copy-icon" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isCopied ? 'mdi-check' : 'mdi-content-copy'), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.t(_ctx.getAlertKey, {
            source: _ctx.customIntegrationType
              ? _ctx.getIntegrationPlatformName(_ctx.customIntegrationType) || ''
              : '',
          })), 1)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_gs_button, {
        uppercased: false,
        "full-width": "",
        size: "large",
        loading: _ctx.loading,
        disabled: _ctx.loading || !_ctx.wasCopiedAtLeastOnce,
        onClick: _ctx.done
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('done')), 1)
        ]),
        _: 1
      }, 8, ["loading", "disabled", "onClick"])
    ])
  ]))
}