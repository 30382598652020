import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66d1be91"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_DynamicInputGroup = _resolveComponent("DynamicInputGroup")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "dialog-card" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, { class: "text-black font-weight-bold text-h5 d-flex py-6 pt-4 pb-3 align-center" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('invite_user')) + " ", 1),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, {
            icon: "",
            onClick: _ctx.close,
            variant: "plain"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { color: "black" }, {
                default: _withCtx(() => [
                  _createTextVNode(" mdi-close ")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, { class: "text-black text-body-1" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('invite_user_description')) + " ", 1),
          _createElementVNode("p", {
            class: "plan-limit-info",
            innerHTML: _ctx.planLimitInfoTextFirstLine
          }, null, 8, _hoisted_1),
          _createVNode(_component_i18n_t, {
            keypath: "InviteUserDialog.plan_limit_info_text_2",
            tag: "div",
            class: "plan-limit-info"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.tc('users', _ctx.remainingSeatCount)), 1),
              _createVNode(_component_router_link, { to: "/upgrade-plan" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('upgrade')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_DynamicInputGroup, {
            modelValue: _ctx.inviteEmails,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inviteEmails) = $event)),
            disabled: _ctx.isLoading,
            "input-rules": [_ctx.rules.email],
            "input-number-limit": _ctx.inputNumberLimit,
            "input-label": _ctx.inputLabel,
            onValidate: _ctx.onFormValidate
          }, null, 8, ["modelValue", "disabled", "input-rules", "input-number-limit", "input-label", "onValidate"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_gs_button, {
            class: "primary-button",
            type: "primary",
            size: "large",
            loading: _ctx.isLoading,
            disabled: !_ctx.isFormValid || _ctx.isLoading,
            onClick: _ctx.onInvite
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('invite')), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}