<template>
  <div class="live-page">LivePageView</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LivePageView',
  components: {},
  methods: {
    t(key: string) {
      return this.$t(`LivePageView.${key}`)
    },
  },
})
</script>

<style lang="scss" scoped></style>
