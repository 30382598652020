<template>
  <div class="nav-wrapper">
    <div class="nav-row-primary">
      <button v-if="isButtonVisible" class="refer-a-friend-button" @click="dialog = true">
        {{
          account.accountType === 'business' || account.accountType === 'ecommerce'
            ? t('refer_business')
            : t('refer_friend')
        }}
      </button>
      <gs-button
        v-if="isBusiness"
        size="small"
        type="icon"
        class="notification-bell-button beamer-trigger-button"
        icon="mdi-bell"
      />
      <div class="button-burger-wrapper">
        <BurgerMenu :items="items" />

        <v-dialog v-model="dialog" width="600" persistent :fullscreen="isMobile" scrollable>
          <InvitePopUp :progress-item="1" :hide-back="true" @dialogClick="dialog = false" />
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import InvitePopUp from '@/components/Network/InvitePopUp.vue'
import BurgerMenu from '@/components/navigation/BurgerMenu.vue'
import type { Account } from '@/store'
import { eventBus } from '@/main'
import type { DialogUpdatePayload } from '@/store/dialog'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NavBar',
  components: {
    InvitePopUp,
    BurgerMenu,
  },
  mixins: [Utils],
  data() {
    return {
      items: [],
      dialog: false,
    } as {
      items: Array<{
        text: string
        url: string
      }>
      dialog: boolean
    }
  },
  computed: {
    isButtonVisible(): boolean {
      return this.$route.path !== '/network'
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    loading(): boolean {
      return this.$store.getters['getLoading']
    },
    showTopAlert(): boolean {
      return this.$store.getters['getShowTopAlert']
    },
    isBusiness(): boolean {
      return this.$store.getters['getAccountTypeIsBusiness']
    },
  },
  created() {
    this.setItems()
    eventBus.on('reference', () => (this.dialog = true))
  },
  beforeUnmount() {
    eventBus.off('reference')
  },
  methods: {
    t(key: string) {
      return this.$t(`NavBar.${key}`)
    },
    setItems() {
      if (this.account.accountType === 'admin') {
        this.items = [{ url: 'logout', text: 'Logout' }]
      } else {
        this.items = [
          { url: 'public', text: 'Public Page' },
          { url: '/account?tab=billing', text: 'Billing' },
          { url: 'logout', text: 'Logout' },
        ]
      }
    },
    onWindowWidthChange() {
      this.setItems()
    },
    openDialog(dialog: DialogUpdatePayload): Promise<void> {
      return this.$store.dispatch('openDialog', dialog)
    },
  },
  watch: {
    windowWidth: [
      {
        handler: 'onWindowWidthChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.nav-wrapper {
  margin-bottom: 32px;
  box-shadow: var(--box-shadow);
}

.nav-row-primary {
  background: var(--ui-beige);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
  gap: 10px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    gap: 12px;
    padding: 0 24px;
  }
}

.nav-row-secondary {
  background: var(--ui-black);
  color: var(--ui-white);
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.nav-row-info-text {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.nav-row-info-text.info-text-action {
  margin-left: 5px;
  text-decoration: underline;
}

.nav-row-info-text.info-text-action:hover {
  cursor: pointer;
}

.button-burger-wrapper {
  display: none;
}

.refer-a-friend-button {
  padding: 7px 17px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--ui-green);
  border: 2px solid var(--ui-green);
  box-sizing: border-box;
  border-radius: 8px;
}

.refer-a-friend-button:hover {
  opacity: 0.8;
}

.beamer-trigger-button {
  overflow: visible !important;

  :deep(.beamer_icon.active) {
    background-color: var(--ui-red);
    top: -10px;
    right: -10px;
  }
}

.upgrade-button {
  align-items: center;
  background-color: var(--ui-green);
  border-radius: 8px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
  color: var(--ui-white);
  display: flex;
  margin-right: 24px;
  padding: 7px 17px;
  text-align: center;
  text-decoration: none;
}

.icon-wrapper {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  margin-right: 5px;
}

.button-icon {
  color: var(--ui-white);
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
}

.button-icon.second {
  top: 100%;
}

.upgrade-button:hover .button-icon {
  transform: translateY(-20px);
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .button-burger-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .nav-row-info-text:first-child {
    margin-left: 256px;
  }
}
</style>
