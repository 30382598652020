<template>
  <div class="carbon-footprint-introduction">
    <h2 class="carbon-footprint-introduction__title">
      {{ t('title') }}
    </h2>
    <p class="carbon-footprint-introduction__description">
      {{ t('description') }}
    </p>

    <ul class="carbon-footprint-introduction__list">
      <li class="carbon-footprint-introduction__list-item">
        <MapPinRound class="carbon-footprint-introduction__list-item-icon" />
        <p class="carbon-footprint-introduction__list-item-text">
          {{ t('reason_1') }}
          <v-tooltip
            :open-on-click="isTouch"
            content-class="carbon-footprint-introduction__tooltip-content"
            attach="#carbon-footprint-tooltip-1"
            max-width="480px"
            absolute
            close-delay="300"
          >
            <template v-slot:activator="{ props }">
              <v-icon
                id="carbon-footprint-tooltip-1"
                class="carbon-footprint-introduction__list-item-tooltip-icon"
                v-bind="props"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <div
              class="carbon-footprint-introduction__list-item-tooltip-content"
              v-html="t('reason_1_tooltip')"
            />
          </v-tooltip>
        </p>
      </li>

      <li class="carbon-footprint-introduction__list-item">
        <LineChartDecline class="carbon-footprint-introduction__list-item-icon" />
        <p class="carbon-footprint-introduction__list-item-text">
          {{ t('reason_2') }}
        </p>
      </li>

      <li class="carbon-footprint-introduction__list-item">
        <TargetRectangular class="carbon-footprint-introduction__list-item-icon" />
        <p class="carbon-footprint-introduction__list-item-text">
          {{ t('reason_3') }}
        </p>
      </li>

      <li class="carbon-footprint-introduction__list-item">
        <BarChart class="carbon-footprint-introduction__list-item-icon" />
        <p class="carbon-footprint-introduction__list-item-text">
          {{ t('reason_4') }}
          <v-tooltip
            :open-on-click="isTouch"
            content-class="carbon-footprint-introduction__tooltip-content"
            attach="#carbon-footprint-tooltip-2"
            max-width="480px"
            absolute
            close-delay="300"
          >
            <template v-slot:activator="{ props }">
              <v-icon
                id="carbon-footprint-tooltip-2"
                class="carbon-footprint-introduction__list-item-tooltip-icon"
                v-bind="props"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <div v-html="t('reason_4_tooltip')" />
          </v-tooltip>
        </p>
      </li>
    </ul>

    <h2 class="carbon-footprint-introduction__title">
      {{ t('how_it_works') }}
    </h2>

    <div class="carbon-footprint-introduction__card-wrapper">
      <div class="carbon-footprint-introduction__card">
        <p class="carbon-footprint-introduction__card-title">
          {{ t('bank_accounts_title') }}
        </p>
        <p class="carbon-footprint-introduction__card-desc">
          {{ t('bank_accounts_description') }}
        </p>
      </div>
      <div class="carbon-footprint-introduction__card">
        <p class="carbon-footprint-introduction__card-title">
          {{ t('fuel_heat_electricity_title') }}
        </p>
        <p class="carbon-footprint-introduction__card-desc">
          {{ t('fuel_heat_electricity_description') }}
        </p>
      </div>
    </div>
    <div class="carbon-footprint-introduction__security-text" v-html="t('security_text')" />
    <gs-button size="large" full-width @click="connectBankAccount">
      {{ t('get_started') }}
    </gs-button>
  </div>
</template>

<script lang="ts">
import MapPinRound from '@/components/icons/MapPinRound.vue'
import BarChart from '@/components/icons/BarChart.vue'
import TargetRectangular from '@/components/icons/TargetRectangular.vue'
import LineChartDecline from '@/components/icons/LineChartDecline.vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { MapPinRound, BarChart, TargetRectangular, LineChartDecline },
  name: 'CarbonFootprintIntroduction',
  mixins: [Utils],
  methods: {
    t(key: string) {
      return this.$t(`CarbonFootprintIntroduction.${key}`)
    },
    connectBankAccount(): Promise<void> {
      return this.$store.dispatch('connectBankAccount')
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.carbon-footprint-introduction__title {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
}

.carbon-footprint-introduction__description {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 32px;
}

.carbon-footprint-introduction__list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 16px;
}

.carbon-footprint-introduction__list-item {
  display: flex;
  align-items: flex-start;
  padding-bottom: 16px;
  gap: 16px;
}

.carbon-footprint-introduction__list-item-icon {
  font-size: 20px;
  color: var(--ui-emerald-green);
  margin-top: 4px;
}

.carbon-footprint-introduction__list-item-text {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 20px;
}

.carbon-footprint-introduction__list-item-tooltip-icon {
  font-size: 20px;
  color: var(--ui-sky-blue);
  margin-left: 4px;
  cursor: pointer;
}

.carbon-footprint-introduction__tooltip-content {
  background-color: white;
  color: var(--font-color-primary);
  opacity: 1 !important;
  box-shadow: var(--box-shadow);
  pointer-events: auto !important;
  left: 50% !important;
  bottom: 20px !important;
  top: auto !important;
  margin-bottom: 15px;
  min-width: 320px;
  transform: translateX(-50%);
}

.carbon-footprint-introduction__card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.carbon-footprint-introduction__card {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--gray-light-CC);
}

.carbon-footprint-introduction__card-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 16px;
}

.carbon-footprint-introduction__card-desc {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.carbon-footprint-introduction__security-text {
  padding: 16px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 8px;
  border: 1px solid var(--ui-red);
  background-color: var(--ui-red-20);
  margin-bottom: 16px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .carbon-footprint-introduction__card-title {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .carbon-footprint-introduction__card-desc {
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }

  .carbon-footprint-introduction {
    background-color: var(--ui-white);
    padding: 36px;
    border-radius: 8px;
    margin: 0;
    box-shadow: var(--box-shadow);
  }

  .carbon-footprint-introduction__title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 24px;
    font-weight: 600;
  }

  .carbon-footprint-introduction__description {
    font-size: 24px;
    line-height: 29px;
  }

  .carbon-footprint-introduction__list-item-text {
    margin-bottom: 0;
    font-size: 22px;
    line-height: 30px;
  }

  .carbon-footprint-introduction__list {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 0 40px;
  }

  .carbon-footprint-introduction__card-wrapper {
    flex-direction: row;
    margin-bottom: 24px;
  }

  .carbon-footprint-introduction__card-desc {
    font-size: 24px;
    line-height: 30px;
    margin: 0;
  }

  .carbon-footprint-introduction__security-text {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 32px;
  }

  .carbon-footprint-introduction__tooltip-content {
    min-width: 480px;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
}
</style>
