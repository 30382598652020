import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01450330"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-forms-impact-source" }
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_gs_input = _resolveComponent("gs-input")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("p", {
      class: _normalizeClass(['select-forms-impact-source__title', { onboarding: _ctx.isOnboarding }])
    }, _toDisplayString(_ctx.t('title')), 3),
    _createVNode(_component_v_radio_group, {
      modelValue: _ctx.radioGroupValue,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.radioGroupValue) = $event)),
        _ctx.onRadioGroupChange
      ]
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_component_v_radio, {
          key: 'all',
          color: "green",
          value: 'all'
        }, {
          label: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.t('all')), 1)
          ]),
          _: 1
        })),
        (_openBlock(), _createBlock(_component_v_radio, {
          key: 'chooseOne',
          color: "green",
          value: 'chooseOne'
        }, {
          label: _withCtx(() => [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t('choose_one')), 1)
          ]),
          _: 1
        }))
      ]),
      _: 1
    }, 8, ["modelValue", "onUpdate:modelValue"]),
    _createVNode(_component_gs_input, {
      modelValue: _ctx.formId,
      "onUpdate:modelValue": [
        _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formId) = $event)),
        _ctx.input
      ],
      class: "form-id-input",
      label: _ctx.t('form_id'),
      "hide-details": false,
      rules: _ctx.inputRules,
      disabled: !_ctx.inputEnabled,
      error: _ctx.error
    }, null, 8, ["modelValue", "label", "rules", "disabled", "error", "onUpdate:modelValue"]),
    _createElementVNode("a", {
      href: _ctx.documentationUrl,
      target: "_blank",
      class: "documentation-url"
    }, _toDisplayString(_ctx.t('where_to_find_form_id')), 9, _hoisted_4)
  ]))
}