<template>
  <svg width="80" height="84" viewBox="0 0 80 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M51.1648 80.9961H28.4719C26.5558 80.9961 25 79.591 25 77.8606V55.8725C25 54.8273 25.9389 53.9744 27.1017 53.9744H52.535C53.6923 53.9744 54.6367 54.8224 54.6367 55.8725V77.8606C54.6367 79.591 53.0809 80.9961 51.1648 80.9961Z"
      fill="#EDF5FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43.7133 10.2549L52.0327 17.719C53.6923 19.2053 54.6367 21.324 54.6367 23.54V26.9072C54.6367 28.0531 53.6977 28.9881 52.535 28.9881H27.1017C25.9444 28.9881 25 28.0585 25 26.9072V23.54C25 21.324 25.9444 19.2107 27.6039 17.719L35.9234 10.2549H43.7133Z"
      fill="#EDF5FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.1615 10.9733H34.7659C34.5059 10.9733 34.2979 10.7471 34.2979 10.4643V5.67405C34.2979 4.75219 34.9843 4 35.8372 4H44.0902C44.9379 4 45.6295 4.74654 45.6295 5.67405V10.4643C45.6295 10.7471 45.4215 10.9733 45.1615 10.9733Z"
      fill="#5DB1FF"
    />
    <path
      d="M52.535 37.3174H27.1017C25.941 37.3174 25 38.249 25 39.3983V43.6789C25 44.8282 25.941 45.7598 27.1017 45.7598H52.535C53.6957 45.7598 54.6367 44.8282 54.6367 43.6789V39.3983C54.6367 38.249 53.6957 37.3174 52.535 37.3174Z"
      fill="#EDF5FF"
    />
    <path
      d="M52.535 28.8766H27.1017C25.941 28.8766 25 29.8082 25 30.9574V35.2381C25 36.3873 25.941 37.3189 27.1017 37.3189H52.535C53.6957 37.3189 54.6367 36.3873 54.6367 35.2381V30.9574C54.6367 29.8082 53.6957 28.8766 52.535 28.8766Z"
      fill="#7FC1FF"
    />
    <path
      d="M52.535 45.7617H27.1017C25.941 45.7617 25 46.6933 25 47.8426V52.1232C25 53.2724 25.941 54.2041 27.1017 54.2041H52.535C53.6957 54.2041 54.6367 53.2724 54.6367 52.1232V47.8426C54.6367 46.6933 53.6957 45.7617 52.535 45.7617Z"
      fill="#7FC1FF"
    />
  </svg>
</template>
