import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7cdbcfe8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "header-tooltip-wrapper" }
const _hoisted_3 = { class: "referral-impact-header" }
const _hoisted_4 = { class: "referral-subheader" }
const _hoisted_5 = { class: "referral-text" }
const _hoisted_6 = {
  class: "referral-subheader",
  style: {"margin-top":"24px"}
}
const _hoisted_7 = { class: "referral-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReferralStats = _resolveComponent("ReferralStats")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('header')), 1)
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('funded')), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('funded_text')), 1),
        (_ctx.referralsActive)
          ? (_openBlock(), _createBlock(_component_ReferralStats, {
              key: 0,
              "referrals-count": _ctx.referralsNumber,
              impacts: _ctx.relationImpact,
              type: "referrals"
            }, null, 8, ["referrals-count", "impacts"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('total')), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.t('total_text')), 1),
        (_ctx.referralsActive)
          ? (_openBlock(), _createBlock(_component_ReferralStats, {
              key: 1,
              impacts: _ctx.relationImpactTotal,
              type: "referrals"
            }, null, 8, ["impacts"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}