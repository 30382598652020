<template>
  <div v-if="loaded" class="dialog-wrapper">
    <div class="dialog-wrapper">
      <div class="close-wrapper">
        <div class="dialog-title">
          {{ t('reward') }}
        </div>

        <img
          style="width: 25px"
          class="close-icon"
          :src="require('@/assets/icons/close_icon.svg')"
          alt="close"
          @click="$emit('dialogClick')"
        />
      </div>

      <div class="dialog-text">
        {{ t('text') }}
      </div>

      <div class="impact-input">
        <ImpactInput @setImpactType="setImpactType" />
      </div>
      <div class="textarea" style="min-height: 150px; width: 100%">
        <v-textarea
          v-model="message"
          width="100%"
          variant="outlined"
          color="#3B755F"
          clearable
          flat
          no-resize
          name="input-7-4"
          :label="t('description')"
        />
      </div>

      <!-- total -->
      <div style="width: 100%">
        <div v-if="account.currency === 'euro'" class="total">
          <span v-if="discountTotal > 0" class="discount" style="margin-bottom: 2px"
            >{{ t('total_impact_discount') }} {{ currencyEuro(discountTotal) }}</span
          >
          <span v-if="discountTotal > 0"
            >{{ t('total') }} {{ currencyEuro(totalPriceWithDiscount) }}</span
          >
          <span v-if="discountTotal === 0">{{ t('total') }} {{ currencyEuro(impactCost) }}</span>
        </div>
        <div v-if="account.currency === 'pound'" class="total">
          <span v-if="discountTotal > 0" class="discount" style="margin-bottom: 2px"
            >{{ t('total_impact_discount') }} {{ currencyPound(discountTotal) }}</span
          >
          <span v-if="discountTotal > 0"
            >{{ t('total') }} {{ currencyPound(totalPriceWithDiscount) }}</span
          >
          <span v-if="discountTotal === 0">{{ t('total') }} {{ currencyPound(impactCost) }}</span>
        </div>
        <div v-if="account.currency === 'dollar'" class="total">
          <span v-if="discountTotal > 0" class="discount" style="margin-bottom: 2px"
            >{{ t('total_impact_discount') }} {{ currencyDollar(discountTotal) }}</span
          >
          <span v-if="discountTotal > 0"
            >{{ t('total') }} {{ currencyDollar(totalPriceWithDiscount) }}</span
          >
          <span v-if="discountTotal === 0">{{ t('total') }} {{ currencyDollar(impactCost) }}</span>
        </div>
      </div>

      <div class="button-wrapper">
        <v-btn
          class="button"
          color="#3B755F"
          height="54"
          variant="flat"
          width="100%"
          :disabled="lessThanMin || loading"
          @click="sendReward()"
        >
          <div v-if="!loading" class="button-text">
            {{
              lessThanMin
                ? userCurrencySymbol + IMPACT_LOWER_PRICE_LIMIT + ' ' + t('minimum')
                : t('reward')
            }}
          </div>
          <v-progress-circular v-if="loading" indeterminate color="#F9F9F9" />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { ProjectId } from '@/helpers/constants'
import { IMPACT_LOWER_PRICE_LIMIT } from '@/helpers/constants'
import { giveReward } from '@api/index'
import ImpactInput from '@/components/Network/ImpactInput.vue'
import type { Account } from '@/store'
import type { Emptyable, OffsetType } from '@/helpers/interfaces'
import { Decimal } from 'decimal.js'
import type { Project } from '@/store/projects'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Reward',
  emits: ['getFreshDataClick', 'dialogClick', 'showSuccessAlert', 'showErrorAlert'],
  components: {
    ImpactInput,
  },
  data() {
    const impactArray: { type: Emptyable<OffsetType>; amount: number; projectId?: ProjectId }[] = []

    return {
      loaded: false,
      loading: false,
      impactArray,
      impactCost: 0,
      message: '',
      lessThanMin: false,
      discountTotal: 0,
      totalPriceWithDiscount: 0,
    }
  },
  computed: {
    IMPACT_LOWER_PRICE_LIMIT() {
      return IMPACT_LOWER_PRICE_LIMIT
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    userCurrencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
    getDefaultProjectPriceByType(): (type: OffsetType) => number {
      return this.$store.getters['getDefaultProjectPriceByType']
    },
    getProjectPriceById(): (projectId: ProjectId) => number {
      return this.$store.getters['getProjectPriceById']
    },
    projects(): Project[] {
      return this.$store.getters['getDefaultProjects']
    },
  },
  created() {
    this.lessThanMin = this.impactCost < IMPACT_LOWER_PRICE_LIMIT
    this.loaded = true
  },
  methods: {
    t(key: string) {
      return this.$t(`Reward.${key}`)
    },
    setImpactType(value) {
      this.impactArray = value
      this.calculateImpactCost()
    },
    calculateImpactCost() {
      const discountAmount =
        (this.account.discounts.find((discount) => discount.type === 'partnerImpactDiscount')
          ?.amount || 0) / 100
      this.impactCost = 0
      this.discountTotal = 0

      this.impactArray.forEach(({ type, amount }) => {
        if (type) {
          const currentImpactCost = new Decimal(this.getDefaultProjectPriceByType(type)).times(
            amount,
          )
          this.impactCost = new Decimal(this.impactCost).plus(currentImpactCost).toNumber()
          if (discountAmount > 0) {
            const currentDiscount = new Decimal(this.getDefaultProjectPriceByType(type))
              .times(discountAmount)
              .times(amount)
            this.discountTotal = new Decimal(this.discountTotal).plus(currentDiscount).toNumber()
          }
        }
      })

      this.discountTotal = Math.trunc(this.discountTotal * 100) / 100
      if (this.discountTotal > 0) {
        this.totalPriceWithDiscount = new Decimal(this.impactCost)
          .minus(this.discountTotal)
          .toDP(2)
          .toNumber()
      }
    },
    async sendReward() {
      try {
        this.loading = true
        const payload = {
          connecteeId: this.connecteeIdQuickMenuItem,
          impacts: this.impactArray
            .filter((impact) => impact.type !== '')
            .map((impact) => {
              const project = this.projects.find((project) => project.type === impact.type)
              impact.projectId = (project?.projectId || '') as ProjectId
              return impact
            }),
          message: this.message,
        }

        await giveReward(this.relationsId ?? '', payload)
        this.loading = false
        this.$emit('getFreshDataClick')
        this.$emit('dialogClick')
        this.$emit('showSuccessAlert')
      } catch (error) {
        console.error('error:', error)
        this.loading = false
        this.$emit('dialogClick')
        this.$emit('showErrorAlert')
      }
    },
    onImpactCostChange() {
      this.lessThanMin = this.impactCost < IMPACT_LOWER_PRICE_LIMIT
    },
  },
  props: {
    relationsId: {
      type: String,
    },
    connecteeIdQuickMenuItem: {
      type: String,
    },
  },
  watch: {
    impactCost: [
      {
        handler: 'onImpactCostChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.dialog-wrapper {
  background: #f9f9f9;
  padding: 12px 12px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.close-wrapper {
  margin-top: -10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.close-icon {
  cursor: pointer;
}

.dialog-title {
  color: #212121;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  width: 100%;
}

.dialog-text {
  color: #212121;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  width: 100%;
}

.impact-input {
  margin-bottom: 24px;
  width: 100%;
}

.discount {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #afc6bd;
  margin-top: 5px;
}

.total {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  margin-top: 24px;
  flex-direction: column;

  & > span {
    text-align: right;
  }
}

.button-wrapper {
  margin-top: 18px;
  width: 100%;
}

.button-text {
  color: #f9f9f9;
  font-weight: 600;
  font-size: 15px;
}
</style>
