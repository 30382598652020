<template>
  <div class="loading-wrapper">
    <GreenSparkLogo class="loading-logo" />
    <div class="progress-bar-wrapper">
      <v-progress-linear color="#3B755F" indeterminate rounded height="6" />
    </div>
  </div>
</template>

<script lang="ts">
import GreenSparkLogo from '@/components/icons/GreenSparkLogo.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Loading',
  components: { GreenSparkLogo },
  methods: {
    t(key: string) {
      return this.$t(`Loading.${key}`)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  background: var(--ui-white);
  z-index: 99999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  pointer-events: none;
}

.loading-logo {
  margin-bottom: 30px;
  color: var(--ui-green);
  fill: var(--ui-green);
  width: 150px;
  height: 150px;
}

.progress-bar-wrapper {
  width: 200px;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .loading-logo {
    margin-bottom: 30px;
    color: var(--ui-green);
    fill: var(--ui-green);
    width: 100px;
    height: 100px;
  }

  .progress-bar-wrapper {
    width: 150px;
  }
}
</style>
