<template>
  <div :class="['top-stat-wrapper', theme]">
    <div class="stat-wrapper">
      <!-- Months Earth Positive  -->
      <TopStatCard
        class="earth-positive-card"
        :label="t('earth_positive')"
        :model-value="displayedMonthsEarthPositive"
      />

      <TopStatCard
        v-for="({ type, amount }, index) in totalImpacts"
        :key="index"
        :offset-type="type"
        :model-value="amount"
        :transferred-impact="getTransferredImpact(type)"
        :relation-impact="getRelationImpact(type)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import TopStatCard from '@/components/livePage/TopStatCard.vue'
import type { LivePageData, OffsetType } from '@/helpers/interfaces'
import type { Account } from '@/store'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

type Theme = 'public' | 'guarded'

export default defineComponent({
  name: 'TopStatsPublic',
  components: {
    TopStatCard,
  },
  computed: {
    displayedMonthsEarthPositive(): number {
      return this.monthsEarthPositiveProp !== undefined
        ? this.monthsEarthPositiveProp
        : this.monthsEarthPositive
    },
    totalImpacts():
      | {
          type: 'trees' | 'carbon' | 'plastic' | 'kelp'
          amount: number
        }[]
      | undefined {
      return this.live ? this.propTotalImpacts : this.getTotalImpactsByType
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    getTotalImpactsByType(): { type: OffsetType; amount: number }[] {
      return this.$store.getters['getTotalImpactsByType']
    },
    monthsEarthPositive(): number {
      return this.$store.getters['getMonthsEarthPositive']
    },
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`TopStatsPublic.${key}`, params ?? {})
    },
    getRelationImpact(offsetType: OffsetType): number {
      const relationImpact = this.live
        ? this.livePageData?.relationImpact
        : this.account.relationImpact
      return (
        relationImpact?.reduce((acc, { type, amount }) => {
          return type === offsetType ? acc + amount : acc
        }, 0) || 0
      )
    },
    getTransferredImpact(offsetType: OffsetType): number {
      const transferredImpacts = this.live
        ? this.livePageData?.transferredImpact
        : this.account.transferredImpacts
      return (
        transferredImpacts?.reduce((acc, { type, amount }) => {
          return type === offsetType ? acc + amount : acc
        }, 0) || 0
      )
    },
  },
  props: {
    livePageData: {
      type: Object as PropType<LivePageData>,
    },
    monthsEarthPositiveProp: {
      type: Number,
    },
    theme: {
      type: String as PropType<Theme>,
    },
    live: {
      type: Boolean,
    },
    propTotalImpacts: {
      type: Array as PropType<{ type: OffsetType; amount: number }[]>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.top-stat-wrapper {
  margin-top: 35px;
  padding: 20px;
  background: var(--ui-white);
  box-shadow: var(--box-shadow-wide);
  border-radius: 6.19184px;
}

.top-stat-wrapper.guarded {
  box-shadow: none;
  background: none;
  margin: 0 0 40px;
  border-radius: 0;
  padding: 0;
  width: 100%;
}

.top-stat-wrapper.guarded:deep(.earth-positive-card) {
  color: var(--ui-beige);
  background-color: var(--ui-green);
}

.header {
  color: var(--font-color-primary);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.stat-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.earth-positive-card {
  background: var(--ui-beige);
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .stat-wrapper {
    flex-direction: column;
  }
}
</style>
