import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, Transition as _Transition, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1976b99a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "partners-wrapper"
}
const _hoisted_2 = {
  key: 0,
  class: "error-message"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "total"
}
const _hoisted_5 = {
  key: 0,
  class: "subtotal",
  style: {"margin-bottom":"2px"}
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = {
  key: 1,
  class: "total"
}
const _hoisted_9 = {
  key: 0,
  class: "subtotal",
  style: {"margin-bottom":"2px"}
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = {
  key: 2,
  class: "total"
}
const _hoisted_13 = {
  key: 0,
  class: "subtotal",
  style: {"margin-bottom":"2px"}
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { key: 0 }
const _hoisted_17 = {
  key: 0,
  class: "subtotal"
}
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = {
  key: 1,
  class: "subtotal"
}
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = {
  key: 2,
  class: "subtotal"
}
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { class: "button-wrapper" }
const _hoisted_27 = {
  key: 0,
  class: "button-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnerInformation = _resolveComponent("PartnerInformation")!
  const _component_FundedImpact = _resolveComponent("FundedImpact")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_call_to_upgrade_popup = _resolveComponent("call-to-upgrade-popup")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_form, {
          modelValue: _ctx.isFormValid,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isFormValid) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PartnerInformation, {
              "partner-credential": _ctx.partnerCredential,
              "onUpdate:partnerCredential": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.partnerCredential) = $event)),
              "is-edit": _ctx.isEdit
            }, null, 8, ["partner-credential", "is-edit"]),
            (_ctx.errorMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.t('error')), 1))
              : _createCommentVNode("", true),
            (!_ctx.isContactEdit)
              ? (_openBlock(), _createBlock(_component_FundedImpact, {
                  key: 1,
                  account: _ctx.account,
                  "funding-setting": _ctx.fundingSetting,
                  "is-edit": _ctx.isEdit,
                  onResetTotals: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resetTotals()))
                }, null, 8, ["account", "funding-setting", "is-edit"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.total > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_ctx.account.currency === 'euro')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (_ctx.discountTotal > 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.t('total_impact_discount')) + " " + _toDisplayString(_ctx.currencyEuro(_ctx.discountTotal)), 1))
                          : _createCommentVNode("", true),
                        (
              _ctx.discountTotal > 0 &&
              _ctx.fundingSetting.fundingType !== 'oneTimeImpact' &&
              _ctx.fundingSetting.fundingType !== 'oneTimeAllowance'
            )
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.totalText) + " " + _toDisplayString(_ctx.currencyEuro(_ctx.totalPriceWithDiscount)), 1))
                          : _createCommentVNode("", true),
                        (
              _ctx.discountTotal === 0 ||
              _ctx.fundingSetting.fundingType === 'oneTimeImpact' ||
              _ctx.fundingSetting.fundingType === 'oneTimeAllowance'
            )
                          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.totalText) + " " + _toDisplayString(_ctx.currencyEuro(_ctx.total)), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.account.currency === 'pound')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        (_ctx.discountTotal > 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.t('total_impact_discount')) + " " + _toDisplayString(_ctx.currencyPound(_ctx.discountTotal)), 1))
                          : _createCommentVNode("", true),
                        (
              _ctx.discountTotal > 0 &&
              _ctx.fundingSetting.fundingType !== 'oneTimeImpact' &&
              _ctx.fundingSetting.fundingType !== 'oneTimeAllowance'
            )
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.totalText) + " " + _toDisplayString(_ctx.currencyPound(_ctx.totalPriceWithDiscount)), 1))
                          : _createCommentVNode("", true),
                        (
              _ctx.discountTotal === 0 ||
              _ctx.fundingSetting.fundingType === 'oneTimeImpact' ||
              _ctx.fundingSetting.fundingType === 'oneTimeAllowance'
            )
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.totalText) + " " + _toDisplayString(_ctx.currencyPound(_ctx.total)), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.account.currency === 'dollar')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        (_ctx.discountTotal > 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.t('total_impact_discount')) + " " + _toDisplayString(_ctx.currencyDollar(_ctx.discountTotal)), 1))
                          : _createCommentVNode("", true),
                        (
              _ctx.discountTotal > 0 &&
              _ctx.fundingSetting.fundingType !== 'oneTimeImpact' &&
              _ctx.fundingSetting.fundingType !== 'oneTimeAllowance'
            )
                          ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.totalText) + " " + _toDisplayString(_ctx.currencyDollar(_ctx.totalPriceWithDiscount)), 1))
                          : _createCommentVNode("", true),
                        (
              _ctx.discountTotal === 0 ||
              _ctx.fundingSetting.fundingType === 'oneTimeImpact' ||
              _ctx.fundingSetting.fundingType === 'oneTimeAllowance'
            )
                          ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.totalText) + " " + _toDisplayString(_ctx.currencyDollar(_ctx.total)), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.totalSubNumber > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  (_ctx.account.currency === 'euro')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        (_ctx.discountTotal > 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.totalSubText) + " " + _toDisplayString(_ctx.currencyEuro(_ctx.totalPriceWithDiscount)), 1))
                          : _createCommentVNode("", true),
                        (_ctx.discountTotal === 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.totalSubText) + " " + _toDisplayString(_ctx.currencyEuro(_ctx.totalSubNumber)), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.account.currency === 'pound')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                        (_ctx.discountTotal > 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.totalSubText) + " " + _toDisplayString(_ctx.currencyPound(_ctx.totalPriceWithDiscount)), 1))
                          : _createCommentVNode("", true),
                        (_ctx.discountTotal === 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.totalSubText) + " " + _toDisplayString(_ctx.currencyPound(_ctx.totalSubNumber)), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.account.currency === 'dollar')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        (_ctx.discountTotal > 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_ctx.totalSubText) + " " + _toDisplayString(_ctx.currencyDollar(_ctx.totalPriceWithDiscount)), 1))
                          : _createCommentVNode("", true),
                        (_ctx.discountTotal === 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_ctx.totalSubText) + " " + _toDisplayString(_ctx.currencyDollar(_ctx.totalSubNumber)), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_26, [
          _createVNode(_component_v_btn, {
            class: "button",
            color: "#3B755F",
            height: "54",
            variant: "flat",
            width: "100%",
            disabled: _ctx.isLessThanMin || _ctx.loading || !_ctx.isFormValid,
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleButtonClick()))
          }, {
            default: _withCtx(() => [
              (!_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(_ctx.buttonLabel), 1))
                : _createCommentVNode("", true),
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                    key: 1,
                    indeterminate: "",
                    color: "#F9F9F9"
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        (_ctx.isCallToUpgradePopupOpen)
          ? (_openBlock(), _createBlock(_component_call_to_upgrade_popup, {
              key: 0,
              onClose: _ctx.closeUpgradePopup,
              "is-premium-only": false
            }, null, 8, ["onClose"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}