import type { TranslateResult } from 'vue-i18n'
import type { AccountImpact, TransferredImpact } from '@/store'
import type { ProjectId } from '@/helpers/constants'
import { FORM_INTEGRATION_SOURCES } from '@/helpers/constants'

export const EMAIL_INTEGRATION_SOURCES = [
  'mailchimp',
  'activecampaign',
  'klaviyo',
  'mailshake',
  'sendinblue',
  'mailgun',
  'campaign-monitor',
  'omnisend',
] as const
export type EmailIntegrationSource = (typeof EMAIL_INTEGRATION_SOURCES)[number]
export const FEEDBACK_INTEGRATION_SOURCES = ['reviewsio', 'judgeme', 'yotpo', 'stamped'] as const
export type FeedbackIntegrationSource = (typeof FEEDBACK_INTEGRATION_SOURCES)[number]
export const BILLING_INTEGRATION_SOURCES = ['stripe'] as const
export type BillingIntegrationSource = (typeof BILLING_INTEGRATION_SOURCES)[number]
export type FormIntegrationSource = (typeof FORM_INTEGRATION_SOURCES)[number]
export const INTEGRATION_SOURCES = [
  ...EMAIL_INTEGRATION_SOURCES,
  ...FEEDBACK_INTEGRATION_SOURCES,
  ...FORM_INTEGRATION_SOURCES,
  ...BILLING_INTEGRATION_SOURCES,
]
export type IntegrationSource =
  | EmailIntegrationSource
  | FeedbackIntegrationSource
  | FormIntegrationSource
export const INTEGRATION_TYPES = [
  'store',
  'email',
  'feedback',
  'custom',
  'integrators',
  'form',
  'billing',
] as const
export type IntegrationType = (typeof INTEGRATION_TYPES)[number]
export const AUTOMATION_SOURCE_TYPES = [
  'product',
  'order',
  'review',
  'subscription',
  'form',
  'answer',
] as const
export type AutomationSourceType = (typeof AUTOMATION_SOURCE_TYPES)[number]
export const INTEGRATION_STATUS_TYPES = [
  'active',
  'disabled',
  'deleted',
  'error',
  'pending',
] as const
export type IntegrationStatusType = (typeof INTEGRATION_STATUS_TYPES)[number]
export const WRITE_DIRECTION_STATUSES = ['active', 'disabled'] as const
export type WriteDirectionStatus = (typeof INTEGRATION_STATUS_TYPES)[number]
export const WRITE_DIRECTION_TEST_STATUSES = ['success', 'error'] as const
export type WriteDirectionTestStatus = (typeof WRITE_DIRECTION_TEST_STATUSES)[number]
export const INTEGRATION_PROVIDERS = ['rutter', 'hotglue'] as const
export type IntegrationProvider = (typeof INTEGRATION_PROVIDERS)[number]
export const INTEGRATION_OPTION_WRITE_DIRECTION_SYNCED_PROPERTIES = [
  'customerImpact',
  'impactCertificate',
  'totalImpact',
  'automationId',
  'loginLink',
  'signupLink',
  'type',
  'impactsTextPresent',
  'impactsTextPast',
  'email',
  'first_name',
  'last_name',
  'integrationId',
] as const
export type IntegrationOptionWriteDirectionSyncedProperty =
  (typeof INTEGRATION_OPTION_WRITE_DIRECTION_SYNCED_PROPERTIES)[number]

export const AUTOMATION_MODIFIER_TYPES_FOR_NUMBER_VALUE = [
  'percentage',
  'threshold',
  'spendAmount',
] as const
export const AUTOMATION_MODIFIER_TYPES_FOR_STRING_VALUE = ['currencyCode', 'orderOrigin'] as const
export const AUTOMATION_MODIFIER_TYPES_FOR_BOOLEAN_VALUE = [
  'globalOffsetPerProduct',
  'allForms',
] as const
export const AUTOMATION_MODIFIER_TYPES = [
  ...AUTOMATION_MODIFIER_TYPES_FOR_NUMBER_VALUE,
  ...AUTOMATION_MODIFIER_TYPES_FOR_STRING_VALUE,
  ...AUTOMATION_MODIFIER_TYPES_FOR_BOOLEAN_VALUE,
] as const
export type AutomationModifierType = (typeof AUTOMATION_MODIFIER_TYPES)[number]
export const EMAIL_AUTOMATION_TRIGGERS = ['perSubscriber', 'perSmsSubscriber'] as const
export type EmailAutomationTrigger = (typeof EMAIL_AUTOMATION_TRIGGERS)[number]
export const FEEDBACK_AUTOMATION_TRIGGERS = ['perProductReview', 'perCompanyReview'] as const
export type FeedbackAutomationTrigger = (typeof FEEDBACK_AUTOMATION_TRIGGERS)[number]
export const FORM_AUTOMATION_TRIGGERS = ['perCompletedForm', 'perSelectedAnswer'] as const
export type FormAutomationTrigger = (typeof FORM_AUTOMATION_TRIGGERS)[number]
export const BASIC_STORE_AUTOMATION_TRIGGERS = ['offsetPerOrder'] as const
export const BILLING_AUTOMATION_TRIGGERS = [
  'perPaidInvoice',
  'byPercentageOfPaidInvoice',
  'bySpendOfPaidInvoice',
  'byTieredSpendOfPaidInvoice',
  'byPercentageOfRevenue',
] as const
export const STORE_AUTOMATION_TRIGGERS = [
  'offsetPerOrder',
  'offsetByPercentageOrder',
  'offsetBySpend',
  'offsetByStoreRevenue',
  'offsetPerProduct',
  'offsetAllProducts',
  'byPercentageOfRevenue',
] as const
export const BASIC_AUTOMATION_TRIGGERS = [
  ...BASIC_STORE_AUTOMATION_TRIGGERS,
  ...EMAIL_AUTOMATION_TRIGGERS,
  ...FEEDBACK_AUTOMATION_TRIGGERS,
] as const
export const AUTOMATION_TRIGGERS = [
  ...STORE_AUTOMATION_TRIGGERS,
  ...EMAIL_AUTOMATION_TRIGGERS,
  ...FEEDBACK_AUTOMATION_TRIGGERS,
  ...FORM_AUTOMATION_TRIGGERS,
  ...BILLING_AUTOMATION_TRIGGERS,
] as const
export type AutomationTrigger = (typeof AUTOMATION_TRIGGERS)[number]
export const PERCENTAGE_TRIGGERS = [
  'byPercentageOfRevenue',
  'byPercentageOfPaidInvoice',
  'offsetByPercentageOrder',
] as const
export const CURRENCY_MODIFIER_TRIGGERS = [
  'bySpendOfPaidInvoice',
  'byTieredSpendOfPaidInvoice',
  'offsetBySpend',
  'offsetByStoreRevenue',
] as const
export const AUTOMATION_STATUSES = ['active', 'disabled', 'deleted', 'scheduled', 'ended'] as const
export type AutomationStatus = (typeof AUTOMATION_STATUSES)[number]
export const PRODUCT_FETCHING_STATUSES = ['ready', 'fetching', 'notReady', 'reFetching']
export type ProductFetchingStatus = (typeof PRODUCT_FETCHING_STATUSES)[number]

export const STORE_TYPES = [
  'shopify',
  'woocommerce',
  'magento',
  'squarespace',
  'prestashop',
  'amazon',
  'square',
  'ebay',
  'etsy',
  'rebuy',
] as const
export type StoreType = (typeof STORE_TYPES)[number]
export const STORE_INTEGRATION_TRIGGERS = [
  'offsetPerOrder',
  'offsetPerProduct',
  'offsetAllProducts',
  'offsetByPercentageOrder',
  'offsetBySpend',
  'offsetByStoreRevenue',
] as const
export const BASIC_STORE_INTEGRATION_TRIGGERS = ['offsetPerOrder'] as const
export type StoreIntegrationTrigger = (typeof STORE_AUTOMATION_TRIGGERS)[number]
export const EMAIL_TRIGGER_TYPES = ['perSubscriber', 'perSmsSubscriber'] as const
export type EmailTriggerType = (typeof EMAIL_TRIGGER_TYPES)[number]
export const FEEDBACK_TRIGGER_TYPES = ['perProductReview', 'perCompanyReview'] as const
export type FeedbackTriggerType = (typeof FEEDBACK_TRIGGER_TYPES)[number]
export const FORM_TRIGGER_TYPES = ['perCompletedForm', 'perSelectedAnswer'] as const
export type FormTriggerType = (typeof FORM_TRIGGER_TYPES)[number]
export type BillingTriggerType = (typeof BILLING_AUTOMATION_TRIGGERS)[number]
export const INTEGRATION_TRIGGER_TYPES = [
  ...EMAIL_TRIGGER_TYPES,
  ...FEEDBACK_TRIGGER_TYPES,
  ...FORM_TRIGGER_TYPES,
  ...BILLING_AUTOMATION_TRIGGERS,
] as const
export type IntegrationTriggerType = (typeof INTEGRATION_TRIGGER_TYPES)[number]
export const BASIC_TRIGGER_TYPES = [
  ...BASIC_STORE_INTEGRATION_TRIGGERS,
  ...INTEGRATION_TRIGGER_TYPES,
] as const
export type BasicTriggerType = (typeof BASIC_TRIGGER_TYPES)[number]
export const CUSTOM_SOURCES = ['custom'] as const
export type CustomSource = (typeof CUSTOM_SOURCES)[number]
export const INTEGRATOR_SOURCES = ['zapier', 'integrately'] as const
export const CUSTOM_LOYALTY_SOURCES = ['toki'] as const
export const SIMPLIFIED_CUSTOM_INTEGRATION_SOURCES = [...CUSTOM_LOYALTY_SOURCES]
export const CUSTOM_INTEGRATION_TYPES = [
  'custom',
  ...INTEGRATOR_SOURCES,
  ...CUSTOM_LOYALTY_SOURCES,
] as const
export type CustomIntegrationType = (typeof CUSTOM_INTEGRATION_TYPES)[number]
export type IntegratorSource = (typeof INTEGRATOR_SOURCES)[number]
export const SHOPIFY_ORDER_ORIGINS = ['all', 'web', 'pos'] as const
export type ShopifyOrderOrigin = (typeof SHOPIFY_ORDER_ORIGINS)[number]

export const INTEGRATION_PLATFORMS = [
  ...INTEGRATION_SOURCES,
  ...STORE_TYPES,
  ...CUSTOM_INTEGRATION_TYPES,
  ...BILLING_INTEGRATION_SOURCES,
] as const
export type IntegrationPlatform = (typeof INTEGRATION_PLATFORMS)[number]

export const INTEGRATION_OPTION_SLUGS = [...INTEGRATION_PLATFORMS] as const
export type IntegrationOptionSlug = (typeof INTEGRATION_PLATFORMS)[number]

export type BrandColor = 'beige' | 'green' | 'blue' | 'black' | 'white'
export type Currency = 'dollar' | 'euro' | 'pound'
export type CurrencyCode = 'USD' | 'EUR' | 'GBP' | 'HKD' | 'CAD' | 'AUD'
export const BRAND_COLORS: BrandColor[] = ['beige', 'green', 'blue', 'black', 'white']
export const ICON_STYLES = ['simple', 'flat', 'isometric'] as const
export type IconStyleType = (typeof ICON_STYLES)[number]
export const OFFSET_TYPES = ['trees', 'carbon', 'plastic', 'kelp'] as const
export const SUBSCRIPTION_OFFSET_TYPES = ['carbon', 'plastic', 'trees'] as const
export type OffsetType = (typeof OFFSET_TYPES)[number]

export type ComparisonValue = { value: number; suffix?: string; link: string }
const CARBON_COMPARISON_UNITS = ['flights', 'distance', 'plantBased'] as const
export type CarbonComparisonUnit = (typeof CARBON_COMPARISON_UNITS)[number]
const PLASTIC_COMPARISON_UNITS = ['straws', 'coffeeCups', 'bathtubs'] as const
export type PlasticComparisonUnit = (typeof PLASTIC_COMPARISON_UNITS)[number]
const TREE_COMPARISON_UNITS = ['areaPlanted', 'carbonSequestered', 'footballPitches'] as const
export type TreeComparisonUnit = (typeof TREE_COMPARISON_UNITS)[number]
const KELP_COMPARISON_UNITS = ['iceHockeyRinks', 'carbonSequestered', 'babyWhales'] as const
export type KelpComparisonUnit = (typeof KELP_COMPARISON_UNITS)[number]
export type ComparisonType =
  | CarbonComparisonUnit
  | PlasticComparisonUnit
  | TreeComparisonUnit
  | KelpComparisonUnit

export type CarbonComparison = Record<CarbonComparisonUnit, ComparisonValue>
export type PlasticComparison = Record<PlasticComparisonUnit, ComparisonValue>
export type KelpComparison = Record<KelpComparisonUnit, ComparisonValue>
export type TreeComparison = Record<TreeComparisonUnit, ComparisonValue>
export type Comparison = TreeComparison | CarbonComparison | PlasticComparison | KelpComparison

export const TopStatDataKeys = [...OFFSET_TYPES, 'monthsEarthPositive'] as const
export type TopStatDataKey = (typeof TopStatDataKeys)[number]
export const GREENSPARK_EMAIL_DOMAIN = 'get-greenspark.com'
export const GREENSPARK_EMAIL_ADDRESS = `hello@${GREENSPARK_EMAIL_DOMAIN}`

export interface Impact {
  type: Emptyable<OffsetType>
  amount: number
  projectId?: ProjectId
}

export type Emptyable<Type> = Type | ''
export type Clearable<Type> = Type | null
export type ImpactActionType =
  | 'ORDER_CREATED'
  | 'PER_COMPANY_REVIEW'
  | 'PER_PRODUCT_REVIEW'
  | 'PER_SUBSCRIBER'
  | 'ONE_OFF'
  | 'SUBSCRIPTION'
  | 'REFERRAL'
  | 'INVOICE'
  | 'GIFT'
  | 'REDEEM_LOYALTY_POINTS'
export type LineItem = { impacts: Impact[]; image: string; title: string }
export type Action = { impacts: Impact[]; createdAt: string; lineItems?: LineItem[] }
export type ImpactAction = {
  _id: string
  type: ImpactActionType
  action: Action
  imageURL: string
  sellerUserId: string
  sellerUsername: string
}
export type ImpactByBrand = {
  companyName: string
  imageURL: string
  impacts: Impact[]
  userId: string
  profileDescription: string
  url: string
}
export type PlanName = 'E-Commerce' | 'Plastic Hero' | 'Earth Hero' | 'Personal'

export interface Customer {
  orderId: string
  email: string
  firstName: string
  lastName: string
  impacts: Impact[]
  sellerCompanyName: string
  impactActionType: ImpactActionType | ''
}

export type TotalImpact = { type: OffsetType; amount: number }
export type AccountType = 'ecommerce' | 'business' | 'personal' | 'family' | 'admin'
export type SustainabilityGoal = { targetId?: string; title: string; description: string }

export interface ResponseTarget extends SustainabilityGoal {
  _id: string
  _v: number
  createdAt: Date
  updatedAt: Date
  userId: string
}

export type FeedItemType =
  | 'CAMPAIGN'
  | 'COMPANY_UPDATE'
  | 'IMPACT_FACT'
  | 'IMPACT_TARGET'
  | 'ORDER_CREATED'
  | 'PROJECT_DESCRIPTION'
  | 'PER_COMPANY_REVIEW'
  | 'PER_PRODUCT_REVIEW'
  | 'PER_SUBSCRIBER'
  | 'WHY_WE_JOINED'
  | 'ONE_OFF'
  | 'SUBSCRIPTION'
  | 'REFERRAL'
  | 'INVOICE'
  | 'GOAL'
  | 'INTRODUCTION'

export interface FeedItemsResponse {
  data: FeedItemResponse[]
  hasMore: boolean
}

export interface FeedItemResponse {
  _id: string
  createdAt: string
  description: string
  image: string
  videoURL?: string
  link: { label: string; url: string }
  title: string
  type: FeedItemType
  byImpactType?: OffsetType | 'all'
}

export interface FeedItem {
  type: FeedItemType
  title: string
  companyName?: string
  url?: string
  description?: string
  targetId?: string
  byImpactType?: OffsetType | 'all'
  videoURL?: string
  image?: string
  link?: { label: string; url: string }
}

export interface MenuItem {
  active?: boolean
  value: string | number
  label: string | TranslateResult
  image?: string
}

export interface SelectOption {
  value: string | number
  text: string | TranslateResult
}

export type DashboardType = 'general' | 'automations' | 'subscriptionAndTailoredImpact' | 'livePage'

export interface Heap {
  track: (event: string, properties?: Record<string, string>) => void
  identify: (identity: string) => void
  resetIdentity: () => void
  addUserProperties: (
    properties: Record<string, number | null | string | AccountImpact[] | TransferredImpact[]>,
  ) => void
  addEventProperties: (properties: Record<string, string>) => void
  removeEventProperty: (property: string) => void
  clearEventProperties: () => void
  appid: string
  userId: string
  identity: string | null
}

export interface LivePageData {
  companyName: string
  website: string
  name: string
  signupDate: Date
  impacts: AccountImpact[]
  temporaryUpgrades: TotalImpact[]
  transferredImpact: TransferredImpact[]
  relationImpact: TotalImpact[]
  impactActionImpacts: TotalImpact[]
  profileDescription: string
  imageURL: string
  accountType: AccountType
}

export interface CookieConfig {
  name: string
  value: string | undefined
  days: number
  domain?: string
  httpOnly?: boolean
  secure?: boolean
}
