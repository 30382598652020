<template>
  <div class="outer-wrapper">
    <Stats :total-impacts-array="totalImpactsArray" />
    <div class="panel">
      <ComparisonCardPublic
        v-for="(totalImpact, index) in totalImpactsArray"
        :key="index"
        :impact="totalImpact.amount"
        :impact-type="totalImpact.type"
        :live-page="true"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Stats from '@/components/PublicImpactDashboard/Stats.vue'
import ComparisonCardPublic from '@/components/livePage/ComparisonCardPublic.vue'
import type { TotalImpact } from '@/helpers/interfaces'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OurImpact',
  components: {
    Stats,
    ComparisonCardPublic,
  },
  mixins: [Utils],
  methods: {
    t(key: string) {
      return this.$t(`OurImpact.${key}`)
    },
  },
  props: {
    totalImpactsArray: {
      type: Array as PropType<TotalImpact[]>,
    },
  },
})
</script>

<style lang="scss" scoped>
.panel {
  background-color: white;
  box-shadow: var(--box-shadow-wide);
  border-radius: 10px;
  margin-top: 35px;
  padding: 20px;
}
</style>
