<template>
  <v-btn
    class="small-button"
    :color="`${type === 'green' ? '#3B755F' : '#E57066'}`"
    :height="28"
    width="auto"
    :disabled="disabled || false"
    @click="click"
  >
    <div class="button-text">
      {{ label }}
    </div>
  </v-btn>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

type SmallButtonType = 'green' | 'red'

export default defineComponent({
  name: 'SmallButton',
  components: {},
  methods: {
    click() {
      this.onClick()
    },
  },
  props: {
    label: { type: String, required: true },
    type: { type: String as PropType<SmallButtonType>, required: true },
    onClick: { type: Function as PropType<() => void>, required: true },
    disabled: { type: Boolean, default: false },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.small-button {
  font-size: 14px;
  font-weight: 600;
  color: #f9f9f9;
  margin-bottom: 4px;
  cursor: pointer;
  padding: 5px 10px !important;
  border-radius: 20px;
  margin-right: 8px;
  text-transform: none;
  letter-spacing: unset !important;
}

.small-button:hover {
  opacity: 0.8;
}
</style>
