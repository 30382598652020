import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a378c5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "tooltip-content" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = {
  key: 1,
  class: "warning-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_menu, {
      "open-on-hover": !_ctx.isTouch,
      location: "top",
      offset: "5 100",
      "close-delay": "250",
      "content-class": "elevation-3"
    }, {
      activator: _withCtx(({ props }) => [
        _createElementVNode("img", _mergeProps({
          class: "icon",
          src: require('@/assets/icons/info.svg'),
          style: _ctx.iconStyle ? _ctx.iconStyle : '',
          alt: "tooltip icon"
        }, props), null, 16, _hoisted_1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.body), 1),
          (_ctx.link)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: "link",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.linkClick()))
              }, _toDisplayString(_ctx.linkText), 1))
            : _createCommentVNode("", true),
          (_ctx.warningText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.warningText), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["open-on-hover"])
  ]))
}