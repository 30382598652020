import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1724fd4f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carts-wrapper" }
const _hoisted_2 = { class: "totals-cart-wrapper" }
const _hoisted_3 = { class: "number" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "button-wrapper" }
const _hoisted_6 = {
  key: 0,
  class: "button-text"
}
const _hoisted_7 = { class: "confirm" }
const _hoisted_8 = {
  key: 1,
  class: "button-text"
}
const _hoisted_9 = { class: "confirm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accumulatedCartByType, (cartItem) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (!!cartItem.amount)
            ? (_openBlock(), _createElementBlock("div", {
                key: cartItem.type,
                class: "text-number"
              }, [
                _createElementVNode("div", _hoisted_3, "+ " + _toDisplayString(cartItem.amount), 1),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t(cartItem.type)), 1)
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 256))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_v_btn, {
        class: "button",
        color: "#3B755F",
        height: "54",
        variant: "flat",
        disabled: _ctx.isDisabled,
        style: {"width":"100%","height":"100%","border-radius":"0"},
        onClick: _ctx.saveCart
      }, {
        default: _withCtx(() => [
          (!_ctx.loading && !_ctx.getMinimumCheckoutAmount)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createTextVNode(_toDisplayString(_ctx.currencyFormatter(_ctx.total)) + " ", 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('confirm')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.getMinimumCheckoutAmount)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("span", null, _toDisplayString(_ctx.currencyFormatter(_ctx.getMinimumCheckoutAmount)), 1),
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t('minimum')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                key: 2,
                indeterminate: "",
                color: "#F9F9F9"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ]))
}