import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9bd00f5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-header" }
const _hoisted_2 = { class: "upgrade-title" }
const _hoisted_3 = { class: "upgrade-text" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.isOpen,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isOpen) = $event)),
    persistent: "",
    "max-width": "540"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "card d-flex flex-column" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.popupTitle), 1),
            _createElementVNode("button", {
              class: "upgrade-close-button",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
            () => {
              _ctx.isOpen = !_ctx.isOpen
              _ctx.$emit('close')
            }
          , ["prevent"])),
              variant: "plain"
            }, [
              _createVNode(_component_v_icon, { class: "close-icon" }, {
                default: _withCtx(() => [
                  _createTextVNode(" mdi-close ")
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.popupType === 'generic')
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  innerHTML: _ctx.planNames
                }, null, 8, _hoisted_4))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "mb-0",
              innerHTML: _ctx.popupText
            }, null, 8, _hoisted_5)
          ]),
          _createVNode(_component_v_btn, {
            color: "green",
            class: "upgrade-button",
            height: "56px",
            width: "100%",
            onClick: _ctx.clickOnUpgrade
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.buttonLabel), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}