<template>
  <div :class="['public-ledger', { live: livePageData }]">
    <div class="header">
      <h2 :class="['header-title', { centered: isHeaderCentered }]">
        {{ t('header') }}
      </h2>
      <div :class="['header-description-wrapper', { centered: isHeaderCentered }]">
        <p :class="['header-description', { centered: isHeaderCentered }]">
          {{
            livePageData ? t('text', { name: livePageData.companyName }) : t('public_ledger_text')
          }}
        </p>
      </div>
    </div>

    <div v-if="allReceipts.length > 0" class="menu-bar">
      <button
        v-for="type in tabs"
        :key="type"
        :class="['menu-item', { 'is-active': activeTab === type }]"
        @click="setActiveTab(type)"
      >
        <div class="menu-item-label">
          {{ t(type) }}
        </div>
      </button>
    </div>

    <div class="accordion-wrapper">
      <v-expansion-panels
        v-if="allReceipts.length > 0"
        :model-value="accordion"
        variant="accordion"
        flat
      >
        <v-expansion-panel v-for="(value, key) in receiptsByType(activeTab)" :key="key">
          <v-expansion-panel-title>{{ value.date }}</v-expansion-panel-title>
          <v-expansion-panel-text>
            <div class="accordion-content">
              <ProjectReceipt
                v-for="(receipt, b) in value.receipts"
                :key="b"
                :name="receipt.receiptName"
                :image-url="receipt.imageUrl"
              />
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <a @click="navigateToAllLedgers" class="see-more">
        {{ t('all') }}
      </a>
    </div>
    <TransferredImpact v-if="isLifePageDataShown" :live-page-data="livePageData" :live="true" />
  </div>
</template>

<script lang="ts">
import ProjectReceipt from '@/components/ledger/ProjectReceipt.vue'
import TransferredImpact from '@/components/ledger/TransferredImpact.vue'
import type { Receipt, ReceiptItem } from '@api/index'
import { getPurchases } from '@api/index'
import type { LivePageData, OffsetType } from '@/helpers/interfaces'
import { OFFSET_TYPES } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { Account } from '@/store'
import type { Project } from '@/store/projects'

export default defineComponent({
  name: 'PublicLedger',
  mixins: [Utils],
  components: {
    ProjectReceipt,
    TransferredImpact,
  },
  data() {
    return {
      activeTab: OFFSET_TYPES[0],
      allReceipts: [],
      accordion: undefined,
    } as {
      activeTab: OffsetType
      allReceipts: Receipt[]
      accordion: string[] | undefined
    }
  },
  computed: {
    tabs(): OffsetType[] {
      return OFFSET_TYPES.filter((type) => this.receiptsByType(type).length > 0)
    },
    isLifePageDataShown(): boolean {
      return this.livePageData
        ? this.livePageData?.transferredImpact?.some((i) => i.urls.length)
        : false
    },
    isHeaderCentered(): boolean {
      return !this?.$route?.meta?.requiresAuth
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    projects(): Project[] {
      return this.$store.getters['getAllProjects']
    },
  },
  async created() {
    await this.getPurchases()
  },
  methods: {
    t(key: string, params?: { [k: string]: string }) {
      return this.$t(`LivePublicLedger.${key}`, params ?? {})
    },
    getProjectNameById(projectId: string): string {
      const projectName = this.projects.find((project) => project.projectId === projectId)?.name
      return projectName?.split(':')[0].trim() || ''
    },
    async getPurchases() {
      const { data: purchases } = await getPurchases()
      const receiptsMap: { [date: string]: { [type: string]: ReceiptItem[] } } = {}

      purchases.forEach((purchase) => {
        if (!receiptsMap[purchase.periodEndDate]) {
          receiptsMap[purchase.periodEndDate] = {}
        }

        if (!receiptsMap[purchase.periodEndDate][purchase.type]) {
          receiptsMap[purchase.periodEndDate][purchase.type] = []
        }

        receiptsMap[purchase.periodEndDate][purchase.type].push({
          id: purchase.id,
          receiptName: this.getProjectNameById(purchase.projectId),
          imageUrl: purchase.receiptUrl,
        })
      })

      const receipts: Receipt[] = []

      for (const date in receiptsMap) {
        for (const type in receiptsMap[date]) {
          receipts.push({
            receiptId: '',
            date: this.formatDateToMonthYear(date),
            type: type as OffsetType,
            time: new Date(date).getTime(),
            receipts: receiptsMap[date][type],
          })
        }
      }

      const impactTypes = this.account.impacts.map((impact) => impact.type)
      const accountSignupDate = new Date(this.account.signupDate).getTime()
      const sortedReceipts = receipts.sort((a, b) => b.time - a.time)
      const filteredReceipts = sortedReceipts.filter(
        (receipt) => impactTypes?.includes(receipt.type) && receipt.time > accountSignupDate,
      )

      this.allReceipts = filteredReceipts
    },
    receiptsByType(type: OffsetType): Receipt[] {
      return this.allReceipts.filter((receipt) => receipt?.type?.toLowerCase() === type)
    },
    setActiveTab(type: OffsetType) {
      this.activeTab = type
    },
    navigateToAllLedgers() {
      window.open(`${process.env.VUE_APP_PUBLIC_PAGE_URL}/accounts/overview#ledger`, '_blank')
    },
  },
  props: {
    livePageData: {
      type: Object as PropType<LivePageData>,
    },
  },
  watch: {
    activeTab() {
      this.accordion = []
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.public-ledger.live .header {
  text-align: center;
  align-items: center;
}

.public-ledger .header {
  margin: 35px 0;
  display: flex;
  flex-direction: column;
}

.public-ledger .header-title {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
}

.public-ledger .header-title.centered {
  text-align: center;
}

.public-ledger .header-description-wrapper {
  display: flex;
}

.public-ledger .header-description-wrapper.centered {
  justify-content: center;
}

.public-ledger .header-description {
  margin-top: 16px;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  max-width: 45ch;
}

.public-ledger .header-description.centered {
  text-align: center;
}

.accordion-content {
  padding-bottom: 10px;
}

.accordion-wrapper {
  padding: 70px 80px;
  background-image: url('../../assets/gifs/comparisons/public_ledger_background.svg');
  border-radius: 8px;
  margin-top: -30px;
  background-size: cover;
}

.menu-bar {
  padding: 8px 30px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-item {
  display: flex;
  cursor: pointer;
  margin: 0 10px;
  background-color: var(--ui-green-light);
  padding: 12px 24px;
  border-radius: 99px;
}

.menu-item.is-active {
  background-color: var(--ui-green);
}

.menu-item:hover {
  background-color: var(--ui-green);
}

.menu-item-label {
  font-size: 18px;
  font-weight: 700;
  color: var(--ui-beige);
}

.v-expansion-panel {
  margin: 4px 0;
  background: var(--ui-white);
  border-radius: 4px;
}

.see-more {
  display: block;
  color: var(--ui-black);
  margin-top: 16px;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .menu-item,
  .menu-item-active {
    padding: 10px 14px;
  }

  .menu-item-label {
    font-size: 14px;
  }

  .accordion-wrapper {
    padding: 40px 30px 20px 30px;
  }

  .description-header {
    font-size: 20px;
  }

  .description-text {
    margin-top: 8px;
    font-size: 14px;
  }
}
</style>
