<template>
  <svg width="95" height="91" viewBox="0 0 95 91" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3794_18160)">
      <path
        d="M50.7072 90.9991H48.3056V63.289C48.3056 58.1577 47.0513 53.1454 44.5843 48.3951C42.0876 43.5912 38.4555 39.2695 33.7949 35.549L35.293 33.668C40.221 37.5968 44.0671 42.1804 46.7184 47.2879C49.3697 52.3835 50.7131 57.7707 50.7131 63.289V90.9991H50.7072Z"
        fill="#47D18F"
      />
      <path
        d="M55.6352 91.0019H53.2336V62.244C53.2336 58.6426 53.4358 54.8626 53.8459 50.6837C54.054 48.5705 54.4998 46.7252 55.9979 44.5881C57.4305 42.5344 59.8678 40.189 63.8922 36.9805L65.3903 38.8615C57.5672 45.1 56.5983 47.3264 56.2416 50.9159C55.8374 55.0114 55.6412 58.714 55.6412 62.2381V90.9959L55.6352 91.0019Z"
        fill="#3B755F"
      />
      <path d="M0 0C0 0 9.15462 54.069 36.9454 36.9965L0 0Z" fill="#47D18F" />
      <path d="M0 0C0 0 53.9944 9.16727 36.9454 36.9965L0 0Z" fill="#3B755F" />
      <path
        d="M95.0001 6.77344C95.0001 6.77344 47.4555 14.8454 62.4714 39.347L95.0001 6.77344Z"
        fill="#47D18F"
      />
      <path
        d="M95.0006 6.77344C95.0006 6.77344 86.9397 54.3837 62.4719 39.347L95.0006 6.77344Z"
        fill="#3B755F"
      />
    </g>
    <defs>
      <clipPath id="clip0_3794_18160">
        <rect width="95" height="91" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
