import * as LDClientSDK from 'launchdarkly-js-client-sdk'
import type { Account, StateInterface } from '@/store/index'
import type { Clearable } from '@/helpers/interfaces'
import router from '@/router'
import type { ActionContext } from 'vuex'

const ANONYMOUS_ID = 'anonymous'

function createContext(account: Account): LDClientSDK.LDContext {
  return {
    kind: 'account',
    key: account.accountId,
  }
}

function createAnonymousContext(): LDClientSDK.LDContext {
  return {
    kind: 'account',
    anonymous: true,
    key: ANONYMOUS_ID,
  }
}

async function maintenanceRedirect(maintenanceUntil: string) {
  if (maintenanceUntil) {
    await router.replace({
      path: '/maintenance',
      query: { maintenanceUntil },
    })
  } else {
    if (router.currentRoute.value.path === '/maintenance') {
      await router.replace('/')
    }
  }
}

interface FeatureFlags {
  isIdentified: boolean
  ldClient: Clearable<LDClientSDK.LDClient>
  customerEngagementAnalytics: boolean
  carbonFootprint: boolean
  automation: boolean
  rebuy: boolean
  maintenanceUntil: string
}

export const featureFlagsModule = {
  state: () =>
    ({
      isIdentified: false,
      ldClient: null,
      customerEngagementAnalytics: false,
      carbonFootprint: false,
      automation: false,
      rebuy: false,
      maintenanceUntil: '',
    } as FeatureFlags),
  mutations: {
    setFeatureFlags(
      s,
      {
        maintenanceUntil,
        customerEngagementAnalytics,
        automation,
        carbonFootprint,
        rebuy,
      }: FeatureFlags,
    ) {
      s.maintenanceUntil = maintenanceUntil
      s.customerEngagementAnalytics = customerEngagementAnalytics
      s.carbonFootprint = carbonFootprint
      s.automation = automation
      s.rebuy = rebuy
    },
    setLdClient(s, ldClient: LDClientSDK.LDClient) {
      s.ldClient = ldClient
    },
    setIdentified(s, isIdentified: boolean) {
      s.isIdentified = isIdentified
    },
  },
  actions: {
    async loadFeatureFlags({ state, dispatch }: ActionContext<FeatureFlags, StateInterface>) {
      await dispatch('initializeLdClient')
      await dispatch('setFeatureFlags')
      if (state.ldClient) {
        state.ldClient.on('change', async () => {
          await dispatch('setFeatureFlags')
        })
      }
    },
    async initializeLdClient({ commit, rootState }: ActionContext<FeatureFlags, StateInterface>) {
      const ldClientId = process.env.VUE_APP_LAUNCHDARKLY_ID as string
      const context = rootState.account?.accountId
        ? createContext(rootState.account)
        : createAnonymousContext()
      const ldClient = LDClientSDK.initialize(ldClientId, context)
      await ldClient.waitForInitialization()
      commit('setLdClient', ldClient)
    },
    async setFeatureFlags({ commit, state }: ActionContext<FeatureFlags, StateInterface>) {
      if (state.ldClient) {
        const maintenanceUntil = state.ldClient.variation('maintenance-until', '') as string
        const customerEngagementAnalytics = state.ldClient.variation(
          'customer-engagement-analytics',
          false,
        ) as boolean
        const carbonFootprint = state.ldClient.variation('carbon-footprint', false) as boolean
        const automation = state.ldClient.variation('automation', false) as boolean
        const rebuy = state.ldClient.variation('rebuy', false) as boolean

        if (!carbonFootprint && router.currentRoute.value.name === 'CarbonReport') {
          await router.replace('/')
        }

        commit('setFeatureFlags', {
          maintenanceUntil,
          customerEngagementAnalytics,
          automation,
          carbonFootprint,
          rebuy,
        })

        await maintenanceRedirect(maintenanceUntil)
      }
    },
    async identifyLdcContext({
      commit,
      state,
      rootState,
    }: ActionContext<FeatureFlags, StateInterface>) {
      if (!state.isIdentified && state.ldClient) {
        const context = createContext(rootState.account)
        await state.ldClient.identify(context)
        commit('setIdentified', true)
      }
    },
  },
  getters: {
    getCustomerEngagementAnalyticsFeatureFlag: (state: FeatureFlags): boolean =>
      state.customerEngagementAnalytics,
    getAutomationFeatureFlag: (state: FeatureFlags): boolean => state.automation,
    getCarbonFootprintFeatureFlag: (state: FeatureFlags): boolean => state.carbonFootprint,
    getRebuyFeatureFlag: (state: FeatureFlags): boolean => state.rebuy,
  },
}
