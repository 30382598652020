import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d967274"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pop-up" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    width: "600",
    persistent: "",
    fullscreen: _ctx.isMobile,
    "model-value": _ctx.modelValue,
    "onUpdate:modelValue": _ctx.onUpdate
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('title')), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('description')), 1),
        _createVNode(_component_gs_button, {
          "full-width": "",
          size: "large",
          onClick: _ctx.handleClickContinue
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('continue')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_gs_button, {
          "full-width": "",
          type: "clear",
          size: "large",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model-value', false)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('cancel')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["fullscreen", "model-value", "onUpdate:modelValue"]))
}