<template>
  <v-dialog v-model="dialog" :fullscreen="true">
    <div class="outer-wrapper">
      <div class="success-t">
        <div class="success-wrapper">
          <div>
            <div class="header">
              {{ text }}
            </div>
            <div class="text">
              {{ subtext }}
            </div>

            <div class="button-wrapper">
              <gs-button
                :disabled="buttonLoading"
                :full-width="true"
                size="large"
                @click.prevent="goHome"
              >
                {{ label }}
              </gs-button>
            </div>
          </div>
        </div>
      </div>
      <!-- Loading -->
      <transition name="fade">
        <Loading v-if="loading" />
      </transition>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import Loading from '@/components/tools/Loading.vue'
import { createSubscription, getSubscriptionItems } from '@api/index'
import type { User } from '@/store/users'
import type { TranslateResult } from 'vue-i18n'
import type { BusinessSubscriptionType } from '@/helpers/constants'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaymentSuccessView',
  components: { Loading },
  data() {
    return {
      loading: false,
      buttonLoading: false,
      params: new URLSearchParams(),
      label: '',
      text: '',
      subtext: '',
      paramsAsString: '',
      paymentFailed: false,
      paymentType: '',
      returnUrl: '',
      dialog: true,
    } as {
      loading: boolean
      buttonLoading: boolean
      params: URLSearchParams
      label: TranslateResult
      text: string
      subtext: string
      paramsAsString: string
      paymentFailed: boolean
      paymentType: string
      returnUrl: string
      dialog: boolean
    }
  },
  async created() {
    this.loading = true
    await this.checkParams()
    await this.setAccount()
  },
  methods: {
    t(key: string) {
      return this.$t(`PaymentSuccessView.${key}`)
    },
    async checkParams() {
      this.params = new URLSearchParams(window.location.search)
      this.paymentType = this.params.get('type') ?? ''
      this.setReturnUrl()
      const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY ?? '')

      if (this.paymentType === 'onetime') {
        const clientSecret = this.params.get('payment_intent_client_secret') ?? ''
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)

        switch (paymentIntent?.status) {
          case 'succeeded':
            this.text = 'Payment succeeded!'
            this.subtext = 'Your payment was successful, continue to your dashboard.'
            this.label = this.t('continue')
            this.buttonLoading = false
            this.paymentFailed = false
            this.loading = false
            break
          case 'processing':
            this.text = 'Your payment is processing.'
            this.subtext = 'Your payment is still processing, please wait ...'
            this.label = this.t('continue')
            await this.checkParams()
            this.paymentFailed = false
            this.buttonLoading = true
            break
          case 'requires_payment_method':
            this.text = 'Your payment failed!'
            this.subtext =
              'There was an error with processing your payment, please try again or get in touch.'
            this.label = this.t('payment')
            this.paymentFailed = true
            this.buttonLoading = false
            this.loading = false
            break
          default:
            this.text = 'Something went wrong.'
            this.subtext =
              'There was an error with processing your payment, please try again or get in touch'
            this.label = this.t('payment')
            this.paymentFailed = true
            this.buttonLoading = false
            this.loading = false
            break
        }
        await this.setAccount()
      }

      if (this.paymentType === 'subscription-added') {
        this.text = 'Upgrade succeeded!'
        this.subtext = 'Your upgrade was successful, continue to your dashboard.'
        this.label = this.t('continue')
        this.buttonLoading = false
        this.paymentFailed = false
        this.loading = false
      }

      if (this.paymentType === 'addcard' || this.paymentType === 'subscription') {
        const clientSecret = this.params.get('setup_intent_client_secret') ?? ''

        const { setupIntent } = await stripe.retrieveSetupIntent(clientSecret)
        const product = this.params.get('plan') as BusinessSubscriptionType

        switch (setupIntent?.status) {
          case 'succeeded':
            await createSubscription({
              product: product || 'free',
              metadata: { type: 'subscription', accountId: this.accountId },
            })
            this.setSubscriptionItems(await getSubscriptionItems())
            this.text = 'Success!'
            this.subtext = 'Your subscription has been successfully saved.'
            this.label = this.t('continue')
            this.buttonLoading = false
            this.paymentFailed = false
            this.loading = false
            break
          case 'processing':
            this.text = 'Processing payment details.'
            this.subtext = 'Your payment is still processing, please wait ...'
            this.label = this.t('continue')
            await this.checkParams()
            this.paymentFailed = false
            this.buttonLoading = true
            break
          case 'requires_payment_method':
            this.text = 'Saving failed!'
            this.subtext =
              'There was an error saving your payment details, please try again or get in touch.'
            this.label = this.t('payment')
            this.paymentFailed = true
            this.buttonLoading = false
            this.loading = false
            break
        }
      }

      if (this.paymentType === 'shopifysubscription') {
        this.text = 'Upgrade succeeded!'
        this.subtext = 'Your upgrade was successful, continue to your dashboard.'
        this.label = this.t('continue')
        this.buttonLoading = false
        this.paymentFailed = false
        this.loading = false
      }
    },
    setReturnUrl() {
      if (this.params.has('frompage')) {
        switch (this.params.get('frompage')) {
          case 'teams':
            this.returnUrl = '/team'
            break
          case 'integrations':
            this.returnUrl = '/add-impact/integrations'
            break
          case 'partners':
            this.returnUrl = '/network'
            break
          default:
            this.returnUrl = '/'
            break
        }
      } else {
        this.returnUrl = '/'
      }
    },
    async goHome() {
      if (this.paymentFailed) {
        await this.$router.push(`/checkout?${this.params}`)
      } else {
        await this.$router.push(this.returnUrl)
      }
      await this.setRequiredActionAlert()
    },
    setRequiredActionAlert(): Promise<void> {
      return this.$store.dispatch('setRequiredActionAlert')
    },
    setAccount(): Promise<void> {
      return this.$store.dispatch('setAccount')
    },
    setSubscriptionItems(items) {
      return this.$store.commit('setSubscriptionItems', items)
    },
  },
  computed: {
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
    accountId(): string {
      return this.$store.getters['getAccountId']
    },
    getCurrentUserFullName(): string {
      return this.$store.getters['getCurrentUserFullName']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.outer-wrapper {
  padding: 8vh 2%;
  // min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../assets/backgrounds/signup-background.svg');
  background-size: cover;
  background-position: center;
  // position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  max-height: 100vh;
}

.success-wrapper {
  width: 100%;
  display: flex;
  padding: 50px;
  flex-direction: column;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #212121;
  background: #f9f9f9;
  box-shadow: 0 42px 76px rgba(0, 0, 0, 0.05), 0 27.2222px 44.5093px rgba(0, 0, 0, 0.037963),
    0 16.1778px 24.2074px rgba(0, 0, 0, 0.0303704), 0 8.4px 12.35px rgba(0, 0, 0, 0.025),
    0 3.42222px 6.19259px rgba(0, 0, 0, 0.0196296), 0 0.777778px 2.99074px rgba(0, 0, 0, 0.012037);
  border-radius: 8px;
}

.header {
  margin-bottom: 25px;
  width: 100%;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: #212121;
}

.subheader {
  text-align: left;
  color: #212121;
  opacity: 0.7;
  max-width: 600px;
}

.button-wrapper {
  margin-top: 45px;
}

@media #{map-get($display-breakpoints, 'md')} {
  .success-t {
    padding: 8vh 5%;
  }
}

@media #{map-get($display-breakpoints, 'sm')} {
  .success-t {
    padding: 8vh 4%;
  }
}

@media #{map-get($display-breakpoints, 'xs')} {
  .outer-wrapper {
    padding: 0;
  }

  .success-t {
    padding: 0;
    height: 100vh;
  }

  .success-wrapper {
    justify-content: center;
    height: 100vh;
  }
}
</style>
