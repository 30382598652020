<template>
  <div v-if="loaded" class="dashboard">
    <div v-if="!hasImpactActions" class="greeting">
      <h2 class="greeting-name">
        {{ t('hey', { name: displayName }) }}
      </h2>
      <p class="greeting-text">
        {{ t('greeting') }}
      </p>
    </div>
    <div class="first-row-container">
      <TopStats :class="['top-stats', { 'only-child': !isBusiness }]" />
      <UpcomingEvents
        v-if="isBusiness"
        class="upcoming-events"
        @see-more="() => (showYearlyEventCalendar = true)"
      />
    </div>
    <PublicMap :projects="projects" />
    <ImpactComparison />
    <Achievements />
    <v-dialog
      class="yearly-calendar-dialog"
      v-model="showYearlyEventCalendar"
      max-width="1200px"
      scrollable
      :fullscreen="isMobile || isMobileLarge"
    >
      <YearlyCalendar
        year="2024"
        :calendar="calender2024"
        is-closable
        @close="() => (showYearlyEventCalendar = false)"
      />
    </v-dialog>
    <v-dialog
      class="tree-planter-overlay-dialog"
      v-model="isFromOnboarding"
      fullscreen
      transition="slide-y-transition"
    >
      <TreePlanterOverlay />
    </v-dialog>
    <img
      :src="require('@/assets/backgrounds/dashboard-background-blob.svg')"
      class="background-blob"
    />
  </div>
</template>

<script lang="ts">
import Achievements from '@/components/your-impact/Achievements.vue'
import UpcomingEvents from '@/components/event-calendar/UpcomingEvents.vue'
import { getRelationsAndImpactActionsData } from '@api/index'
import { Utils } from '@/helpers/mixins/utilsMixin'
import TreePlanterOverlay from '@/components/onboarding/TreePlanterOverlay.vue'
import type { Account } from '@/store'
import type { User } from '@/store/users'
import TopStats from '@/components/your-impact/TopStats.vue'
import PublicMap from '@/components/your-impact/PublicMap.vue'
import type { Project } from '@/store/projects'
import ImpactComparison from '@/components/your-impact/ImpactComparison.vue'
import type { SubscriptionItem } from '@/store/subscriptions'
import { includes } from '@/helpers/parsers'
import { PAID_BUSINESS_SUBSCRIPTION_TYPES } from '@/helpers/constants'
import { defineComponent } from 'vue'
import { CALENDAR_2024 } from '@/helpers/event-calendar'
import YearlyCalendar from '../event-calendar/YearlyCalendar.vue'

export default defineComponent({
  name: 'Home',
  components: {
    PublicMap,
    TopStats,
    UpcomingEvents,
    TreePlanterOverlay,
    Achievements,
    ImpactComparison,
    YearlyCalendar,
  },
  mixins: [Utils],
  data() {
    return {
      loaded: false,
      isFromOnboarding: false,
      showYearlyEventCalendar: false,
      calender2024: CALENDAR_2024,
    }
  },
  async created() {
    await this.getRelationsAndImpactActionsData()
    this.setIsFromOnboarding()
    await this.setChecklist()
    this.loaded = true
  },
  methods: {
    t(key: string, params?: { [key: string]: string }) {
      return this.$t(`HomeView.${key}`, params ?? {})
    },
    async getRelationsAndImpactActionsData() {
      await getRelationsAndImpactActionsData()
    },
    setIsFromOnboarding() {
      const isPlantingTreesWithSubscription = includes(
        PAID_BUSINESS_SUBSCRIPTION_TYPES.filter((t) => t !== 'freeBusiness'),
        this.getActiveSubscriptionItem?.product,
      )
      // if the user comes from the onboarding flow, we show the tree planter overlay based on the query param
      this.isFromOnboarding =
        this.$route.query.from === 'onboarding' && isPlantingTreesWithSubscription
      if (this.isFromOnboarding) {
        // we remove the query param after 5 seconds, in case the user refreshes the page
        setTimeout(() => {
          this.isFromOnboarding = false
          this.$router.replace({ query: {} })
        }, 5000)
      }
    },
    setChecklist(): Promise<void> {
      return this.$store.dispatch('setChecklist')
    },
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
    displayName(): string {
      return this.$store.getters['getAccountDisplayName']
    },
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
    hasImpactActions(): boolean {
      return this.$store.getters['getHasImpactActions']
    },
    projects(): Project[] {
      return this.$store.getters['getProjectsWithLink']
    },
    getActiveSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getActiveSubscriptionItem']
    },
    isBusiness(): SubscriptionItem {
      return this.$store.getters['getAccountTypeIsBusiness']
    },
  },
})
</script>

<style lang="scss">
:root {
  --dashboard--add-more-impact-button-width: 104px;
}
</style>

<style lang="scss" scoped>
@import '~vuetify/settings';

.greeting-name {
  font-size: 32px;
  line-height: 38px;
  color: var(--ui-black);
  font-weight: 700;
  margin-bottom: 16px;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: 48px;
    line-height: 58px;
  }
}

.greeting-text {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 0;
}

.first-row-container {
  $upcoming-event-max-width: 380px;

  display: flex;
  flex-direction: column;
  position: relative;
  gap: 24px;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    display: block;
  }

  .top-stats {
    width: 100%;
    height: 100%;

    &:not(.only-child) {
      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        width: auto;
        max-width: calc(100% - 32px - #{$upcoming-event-max-width});
      }
    }
  }

  .upcoming-events {
    max-width: unset;
    height: auto;
    overflow-y: visible;
    position: relative;

    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      max-width: $upcoming-event-max-width;
      overflow-y: hidden;
      position: absolute;
      top: 0;
      left: auto;
      right: 0;
      bottom: 0;
    }
  }
}

.yearly-calendar-dialog {
  border-radius: 8px;
}

.dashboard {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    gap: 32px;
  }

  > * {
    z-index: 1;
  }
}

.background-blob {
  position: absolute;
  z-index: 0;
  right: 0;
  top: 50%;
}

.tree-planter-overlay-dialog {
  z-index: 9999 !important;
}
</style>
