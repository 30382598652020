import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3df84b63"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "canceled-wrapper" }
const _hoisted_2 = { class: "canceled-inner" }
const _hoisted_3 = { class: "cancel-title" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.t('canceled')) + " ", 1),
        _createElementVNode("img", {
          src: require('@/assets/icons/sad-emote.svg'),
          loading: "lazy",
          height: "40",
          width: "50"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", {
        class: "body-text",
        innerHTML: _ctx.t('canceled_text')
      }, null, 8, _hoisted_5),
      (_ctx.isLoggedIn)
        ? (_openBlock(), _createBlock(_component_gs_button, {
            key: 0,
            size: "large",
            class: "restart-button",
            loading: _ctx.isLoading,
            onClick: _ctx.restartSubscription
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('restart')), 1)
            ]),
            _: 1
          }, 8, ["loading", "onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_gs_button, {
        href: _ctx.feedback,
        size: "large",
        type: "secondary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('feedback')), 1)
        ]),
        _: 1
      }, 8, ["href"])
    ])
  ]))
}