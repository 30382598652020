<!-- TODO: Remove these eslint comments. We need to fix this prop mutation ASAP. -->
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div v-if="loaded">
    <div class="title-wrapper">
      <img
        class="info-icon"
        :src="require('@/assets/icons/partners/funded_impact.svg')"
        alt="info"
      />
      <p class="subheader">
        {{ t('funded') }}
      </p>
    </div>

    <div class="input-outer">
      <div class="select">
        <div class="title-wrapper">
          <p class="subheader">
            {{ t('plan_type_label') }}
          </p>
          <button class="open-popup-button" @click.prevent="openComparisonPopup">
            {{ t('plan_type_help_link') }}
          </button>
        </div>

        <v-select
          ref="planType"
          v-model="fundingSetting.planType"
          class="input-field"
          :items="planSelectOptions"
          variant="outlined"
          clearable
          color="#3B755F"
          style="margin-bottom: 25px"
        />
      </div>
      <div class="select">
        <div class="title-wrapper">
          <p class="subheader">
            {{ t('impact') }}
          </p>
        </div>

        <v-select
          ref="impactType"
          v-model="fundingSetting.fundingType"
          :items="typeItems"
          variant="outlined"
          color="#3B755F"
          item-color="#3B755F"
          clearable
          class="dropdown"
          item-disabled.disabled="disable"
          @update:model-value="onTypeChange"
        />
      </div>

      <div v-if="isEdit" class="edit-help-text">
        {{ t('edit_help_text') }}
      </div>

      <transition-group name="fade" tag="div" style="width: 100%">
        <div v-if="showImpactInput" key="0" class="impact-input">
          <ImpactInput
            :impact-array-set="fundingSetting.impactArray"
            :is-edit="isEdit"
            :reset-type="resetType"
            @setImpactType="setImpactType"
          />
        </div>
        <div key="1" class="allowance-input">
          <v-text-field
            v-if="
              fundingSetting.fundingType === 'monthlyAllowance' ||
              fundingSetting.fundingType === 'oneTimeAllowance'
            "
            v-model.number="fundingSetting.monthlyAllowance"
            class="input-field"
            :label="t('monthly_amount_label', { currency: userCurrencySymbol })"
            variant="outlined"
            type="number"
            min="0"
            flat
            color="#3B755F"
            style="margin-top: 25px"
            :rules="[rules.required, rules.maxTwoDecimal]"
          />
        </div>
      </transition-group>

      <!-- Funding Length -->
      <transition name="fade">
        <div
          v-if="
            !!fundingSetting.planType ||
            fundingSetting.fundingType === 'monthlyImpact' ||
            fundingSetting.fundingType === 'monthlyAllowance'
          "
          class="select"
          style="margin-top: 12px"
        >
          <div class="title-wrapper">
            <p class="subheader">
              {{ t('length') }}
            </p>
          </div>
          <v-select
            v-model="fundingSetting.fundingLength"
            :items="lengthItems"
            variant="outlined"
            color="#3B755F"
            item-color="#3B755F"
            class="dropdown"
            :rules="[rules.required]"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { eventBus } from '@/main'
import ImpactInput from '@/components/Network/ImpactInput.vue'
import type { FundingSetting } from '@/components/Network/Partners.vue'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import { getPlanPrice } from '@/helpers/pricing'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { Account } from '@/store'
import type { TranslateResult } from 'vue-i18n'

export default defineComponent({
  name: 'FundedImpact',
  emits: ['resetTotals'],
  components: {
    ImpactInput,
  },
  mixins: [RulesMixin],
  data() {
    return {
      loaded: false,
      lengthItems: [],
      showImpactInput: false,
      resetType: false,
    } as {
      loaded: boolean
      lengthItems: { title: string; value: number | string }[]
      showImpactInput: boolean
      resetType: boolean
    }
  },
  computed: {
    typeItems(): (
      | {
          title: string
          value: string
          disable?: undefined
        }
      | {
          title: string
          value: string
          disable: boolean
        }
    )[] {
      if (this.isEdit) {
        return [
          { title: 'None', value: '' },
          { title: 'Monthly impact', value: 'monthlyImpact' },
          { title: 'One-time impact', value: 'oneTimeImpact', disable: true },
          { title: 'Monthly allowance', value: 'monthlyAllowance' },
          { title: 'One-time allowance', value: 'oneTimeAllowance', disable: true },
        ]
      } else {
        return [
          { title: 'None', value: '' },
          { title: 'Monthly impact', value: 'monthlyImpact' },
          { title: 'One-time impact', value: 'oneTimeImpact' },
          { title: 'Monthly allowance', value: 'monthlyAllowance' },
          { title: 'One-time allowance', value: 'oneTimeAllowance' },
        ]
      }
    },
    planSelectOptions(): {
      title: TranslateResult
      value: string
    }[] {
      return [
        { title: this.t('none'), value: '' },
        {
          title: this.t('starter_business', {
            currency: this.userCurrencySymbol,
            price: getPlanPrice('starterBusiness', this.account.currency).toString(),
          }),
          value: 'starterBusiness',
        },
        {
          title: this.t('growth_business', {
            currency: this.userCurrencySymbol,
            price: getPlanPrice('growthBusiness', this.account.currency).toString(),
          }),
          value: 'growthBusiness',
        },
        {
          title: this.t('premium_business', {
            currency: this.userCurrencySymbol,
            price: getPlanPrice('premiumBusiness', this.account.currency).toString(),
          }),
          value: 'premiumBusiness',
        },
      ]
    },
    userCurrencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
  },
  created() {
    this.setLengthItems()
    if (
      this.isEdit &&
      (this.fundingSetting?.fundingType === 'monthlyImpact' ||
        this.fundingSetting?.fundingType === 'oneTimeImpact')
    ) {
      this.showImpactInput = true
    }
    this.loaded = true
  },
  methods: {
    t(key: string, params?: { [key: string]: string }) {
      return this.$t(`FundedImpact.${key}`, params ?? {})
    },
    onTypeChange() {
      this.$emit('resetTotals')
      // TODO: Remove these eslint comments. We need to fix this prop mutation ASAP.
      // eslint-disable-next-line vue/no-mutating-props
      if (this.fundingSetting) this.fundingSetting.impactArray = []
      this.showImpactInput = false
      if (
        this.fundingSetting?.fundingType === 'monthlyImpact' ||
        this.fundingSetting?.fundingType === 'oneTimeImpact'
      ) {
        this.resetType = !this.resetType
        this.showImpactInput = true
      }
    },
    setLengthItems() {
      this.lengthItems = []
      this.lengthItems.push({ title: 'Ongoing', value: 'ongoing' })

      for (let i = 1; i < 49; i++) {
        if (i === 1) {
          this.lengthItems.push({ title: i + ' month', value: i })
        } else {
          this.lengthItems.push({ title: i + ' months', value: i })
        }
      }
    },
    setImpactType(payload) {
      // TODO: Remove these eslint comments. We need to fix this prop mutation ASAP.
      // eslint-disable-next-line vue/no-mutating-props
      this.fundingSetting.impactArray = payload
    },
    openComparisonPopup() {
      eventBus.emit('openComparisonPopup')
    },
  },
  props: {
    account: {
      type: Object as PropType<Account>,
      required: true,
    },
    fundingSetting: {
      type: Object as PropType<FundingSetting>,
      required: true,
    },
    isEdit: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
.title-wrapper {
  display: flex;
  margin-top: 24px;
}

.subheader {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--font-color-primary);
  margin-bottom: 5px;
}

img + .subheader {
  margin-left: 5px;
}

.input-outer {
  margin-top: 16px;
}

.impact-input {
  margin-bottom: 24px;
}

.allowance-input {
  margin-bottom: 24px;
}

.edit-help-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--ui-red);
  margin-top: 6px;
}

.open-popup-button {
  color: var(--ui-green);
  text-decoration: underline;
  margin-left: 5px;
}
</style>
