<template>
  <div class="account-type" @click="$emit('typeClick')">
    <img
      v-if="text === 'Business'"
      class="image"
      :src="require('@/assets/icons/main-shape.svg')"
      alt="e-commerce"
    />
    <img
      v-if="text === 'Team'"
      class="image"
      :src="require('@/assets/icons/main-shape.svg')"
      alt="team"
    />
    <img
      v-if="text === 'Personal'"
      class="image"
      :src="require('@/assets/icons/main-shape.svg')"
      alt="personal"
    />
    <img
      v-if="text === 'Family'"
      class="image"
      :src="require('@/assets/icons/main-shape.svg')"
      alt="family"
    />
    <div class="type-text">
      {{ text }}
    </div>
    <div role="button" class="select">
      {{ t('select') }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AccountType',
  emits: ['typeClick'],
  methods: {
    t(key: string) {
      return this.$t(`AccountType.${key}`)
    },
  },
  props: {
    text: { type: String as () => string },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.account-type {
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  width: auto;
  min-width: 242px;
  height: 253px;
  cursor: pointer;
  transform: translate(0, 0);
  transition: box-shadow 200ms ease, transform 200ms ease, background-color 200ms ease,
    -webkit-transform 200ms ease;
}

.account-type:hover {
  -webkit-box-shadow: 0 0 35px 7px rgb(0 0 0 / 6%);
  box-shadow: 0 0 35px 7px rgb(0 0 0 / 6%);
  -webkit-transform: translate(0, -4px);
  -ms-transform: translate(0, -4px);
  transform: translate(0, -4px);
}

.type-text {
  text-align: center;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #3b755f;
}

.image {
  height: 73px;
  margin-bottom: 12px;
}

.select {
  background-color: #3b755f;
  padding: 12px 24px;
  border-radius: 99px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #f2ebdb;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .account-type {
    width: auto;
  }

  .image {
    width: 120px;
  }
}
</style>
