import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0732a354"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "topstat-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "stat referral-card"
}
const _hoisted_3 = { class: "text-number-wrapper" }
const _hoisted_4 = { class: "number" }
const _hoisted_5 = { class: "text-number-wrapper" }
const _hoisted_6 = { class: "number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.referralsCount !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.referralsCount), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.t(_ctx.type)), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.impacts, (value, key) => {
      return (_openBlock(), _createElementBlock("div", {
        key: key,
        class: _normalizeClass(['stat', key])
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.formatNumberByLocale(value)), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.t(key)), 1)
        ])
      ], 2))
    }), 128))
  ]))
}