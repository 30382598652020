<template>
  <div class="upcoming-events">
    <h3 class="title">{{ t('title') }}</h3>
    <div class="event-list-container">
      <div class="event-list">
        <UpcomingEventCard
          v-for="event in upcomingEvents.slice(0, 3)"
          :calendar-event="event"
          :key="event.name"
        />
      </div>
    </div>
    <a class="see-more" @click.prevent="$emit('see-more')">{{ t('see_more') }}</a>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

import { CALENDAR_2024 } from '@/helpers/event-calendar'
import { formatDateAsYYYYMMDD } from '@/helpers/parsers'
import UpcomingEventCard from '@/components/event-calendar/UpcomingEventCard.vue'

import type { CalendarEvent } from '@/helpers/event-calendar'

const i18n = useI18n()
defineEmits(['see-more'])

const { Q1, Q2, Q3, Q4 } = CALENDAR_2024
const upcomingEvents: CalendarEvent[] = [...Q1, ...Q2, ...Q3, ...Q4].filter(
  (e) => formatDateAsYYYYMMDD(new Date()) <= formatDateAsYYYYMMDD(new Date(e.endDate)),
)

const t = (key: string) => i18n.t(`UpcomingEvents.${key}`)
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.upcoming-events {
  width: 100%;
  background: var(--ui-white);
  border-radius: 8px;
  padding: 16px 8px;
  box-shadow: 0px 4px 4px 0px #0000001a;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
  }

  .event-list-container {
    flex-grow: 0;
    flex-shrink: 1;
    max-height: 100%;
    overflow-y: hidden;
  }

  .event-list {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    gap: 16px;
  }

  .see-more {
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: var(--font-color-link);
    text-decoration: underline;
    cursor: pointer;
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding: 24px;
  }
}
</style>
