import { defineComponent } from 'vue'
import type { Account } from '@/store'
import type { User } from '@/store/users'
import { isProductionEnvironment } from '@/helpers/constants'

export interface RateHighlySettings {
  app_id: string
  name: string
  email: string
  user_id: string
  account_name: string
  created_at: string
}

export const LoadRateHighlyMixin = defineComponent({
  computed: {
    userCreatedAtUnix(): number {
      // getTime returns milliseconds, so we divide by 1000 to get seconds for unix time
      return Math.round(new Date(this.currentUser.createdAt).getTime() / 1000)
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
    isBusiness(): boolean {
      return this.$store.getters['getAccountTypeIsBusiness']
    },
  },
  methods: {
    loadRateHighly() {
      const APP_ID = process.env.VUE_APP_RATE_HIGHLY_APP_ID

      if (isProductionEnvironment && APP_ID && this.isBusiness) {
        window.ratehighlySettings = {
          app_id: APP_ID,
          name: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
          email: `${this.currentUser.email}`,
          user_id: `${this.currentUser.id}`,
          account_name: `${this.account.companyName}`,
          created_at: this.userCreatedAtUnix.toString(),
        }

        if (typeof window.ratehighly === 'object') {
          window.ratehighly.init()
          return
        }

        const l = function () {
          const s = document.createElement('script')
          s.type = 'text/javascript'
          s.async = true
          s.src = 'https://v.ratehighly.com/widget/' + APP_ID
          const x = document.getElementsByTagName('script')[0]
          if (!x?.parentNode) throw new Error('RateHighly: Could not find script tag')
          x.parentNode.insertBefore(s, x)
        }
        if (document.readyState === 'complete') {
          l()
        } else if (window.attachEvent) {
          window.attachEvent('onload', l)
        } else {
          window.addEventListener('load', l, false)
        }
      }
    },
  },
})
