<template>
  <div class="pop-up">
    <v-btn icon @click="close" variant="plain" class="floating-close-button">
      <v-icon color="black"> mdi-close </v-icon>
    </v-btn>
    <div class="overlay-header">
      {{ t('cancel_header') }}
    </div>
    <div v-if="shouldAllowCancel">
      <div class="overlay-text">
        {{ t('cancel_text') }} <br /><br />
        {{ t('cancel_text1') }}
      </div>

      <div class="button-wrapper">
        <v-btn
          class="button button-delete"
          height="35"
          width="230px"
          variant="outlined"
          :disabled="dialogLoading"
          @click="deleteUser()"
        >
          <div v-if="!dialogLoading" class="button-text">
            {{ t('cancel_subscription') }}
          </div>
          <v-progress-circular v-else class="progress" indeterminate />
        </v-btn>
        <v-btn
          class="button"
          color="#757d82"
          height="35"
          width="230px"
          style="margin-left: 5px"
          variant="outlined"
          :disabled="dialogLoading"
          @click="close"
        >
          <div class="button-update">
            {{ t('back') }}
          </div>
        </v-btn>
      </div>

      <div v-if="error" class="cancel-error">
        {{ t('cancel_error') }}
      </div>
    </div>
    <div v-if="!shouldAllowCancel" class="email-us" v-html="t('email_us')" />
  </div>
</template>

<script lang="ts">
import type { Dialog } from '@/store/dialog'
import { canceledSubscriptionLogout, deleteSubscription } from '@api/index'
import type { Account } from '@/store'
import type { SubscriptionItem } from '@/store/subscriptions'
import { includes } from '@/helpers/parsers'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CancelSubscriptionDialog',
  data() {
    return {
      error: false,
      dialogLoading: false,
    }
  },
  computed: {
    shouldAllowCancel(): boolean {
      if (!this.isBusiness) return true
      return includes(['starterBusiness', 'freeBusiness'], this.getActiveSubscriptionItem?.product)
    },
    email(): string {
      return this.getEmailByUserId(this.account.accountId)
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    getEmailByUserId(): (userId: string) => string {
      return this.$store.getters['getEmailByUserId']
    },
    getActiveSubscriptionItem(): SubscriptionItem {
      return this.$store.getters['getActiveSubscriptionItem']
    },
    isActiveSubscriptionOnTrial(): boolean {
      return this.$store.getters['isActiveSubscriptionOnTrial']
    },
    isBusiness(): boolean {
      return this.$store.getters['getAccountTypeIsBusiness']
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`CancelSubscriptionDialog.${key}`)
    },
    async deleteUser() {
      this.error = false
      this.dialogLoading = true
      try {
        const isActiveSubscriptionOnTrial = this.isActiveSubscriptionOnTrial
        await deleteSubscription()
        await canceledSubscriptionLogout()
        window.location.href = `/canceled?isActiveSubscriptionOnTrial=${isActiveSubscriptionOnTrial}&product=${this.getActiveSubscriptionItem?.product}&email=${this.email}&name=${this.account.companyName}&user_id=${this.account.accountId}`
      } catch (e) {
        this.error = true
        console.error(e)
      } finally {
        this.dialogLoading = false
      }
    },
  },
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
    close: {
      type: Function as PropType<() => void>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.pop-up {
  background: var(--ui-white);
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: scroll;
}

.floating-close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.overlay-header {
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
  color: red;
  font-size: 28px;
  font-weight: 500;
}

.overlay-text {
  color: var(--ui-black);
  font-size: 16px;
  margin-bottom: 16px;
}

.button-wrapper {
  display: flex;
}

.button {
  margin-top: 10px;
}

.button-text {
  color: red;
  font-weight: 500;
  font-size: 14px;
}

.cancel-error {
  font-size: 16px;
  font-weight: 500;
  padding: 15px;
  background: red;
  color: var(--ui-white);
  margin-top: 20px;
}

.progress {
  color: red;
}

.button-delete {
  color: red;
  margin-right: 5px;
  border-color: red;
}

.email-us {
  width: 100%;
}

.email-us:deep(.cancel_email) {
  font-weight: bold;
  text-decoration: none;
  color: var(--ui-black);
  padding: 3px;
  background-color: var(--ui-beige);
  border-radius: var(--border-radius-small);
}

@media screen and (max-width: 585px) {
  .button-wrapper {
    flex-direction: column;
    width: 100%;
  }

  .button {
    width: 100% !important;
    margin-top: 10px;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>
