<template>
  <aside class="sidebar">
    <v-navigation-drawer
      v-model="sidebarMenu"
      floating
      :permanent="sidebarMenu"
      :rail="toggleMini"
      elevation="1"
    >
      <template v-slot:prepend>
        <div class="logo-wrapper">
          <img
            v-if="!toggleMini"
            class="logo"
            :src="require('@/assets/greenspark-logo.svg')"
            alt="greenspark-app"
          />

          <img
            v-if="toggleMini"
            class="logo-small"
            :src="require('@/assets/greenspark-logo.svg')"
            alt="greenspark-app"
          />
        </div>
        <v-divider />
      </template>

      <div
        v-if="account.accountType === 'personal' || account.accountType === 'family'"
        class="business-logo"
        @click="
          () => {
            $router.push('/account?tab=profile')
            setBurgerMenuClick()
          }
        "
      >
        <img
          :style="
            account.imageURL === ''
              ? 'border-radius: 50%; width: 100%; height: 100%;'
              : png
              ? 'max-width: 100%; object-fit: contain;'
              : ''
          "
          :src="
            !account.imageURL ? require('@/assets/icons/menu/upload-picture.svg') : account.imageURL
          "
          alt="logo"
        />
      </div>

      <div
        v-if="account.accountType !== 'personal' && account.accountType !== 'family'"
        class="business-logo-business"
        @click="
          () => {
            $router.push('/account?tab=profile')
            setBurgerMenuClick()
          }
        "
      >
        <img
          :style="
            account.imageURL === ''
              ? 'border-radius: 50%; width: 100%; height: 100%;'
              : png
              ? 'max-width: 100%; object-fit: contain;'
              : ''
          "
          :src="
            !account.imageURL ? require('@/assets/icons/menu/upload-logo.svg') : account.imageURL
          "
          alt="logo"
        />
      </div>

      <v-tooltip
        :open-on-click="isTouch"
        location="top"
        color="black"
        :disabled="disableAccountNameTooltip"
      >
        <template v-slot:activator="{ props }">
          <div
            id="accountName"
            class="business-name"
            @mouseover="disableAccountNameTooltip = !isAccountNameTruncated()"
            @mouseleave="disableAccountNameTooltip = !isAccountNameTruncated()"
            v-bind="props"
          >
            {{ accountName }}
          </div>
        </template>
        <span>{{ accountName }}</span>
      </v-tooltip>

      <div class="member-since">Member Since: {{ memberSince }}</div>

      <v-divider />

      <nav class="central-menu-items">
        <ul class="menu-item-list">
          <li v-if="carbonFootprint" @click="setBurgerMenuClick()">
            <router-link class="menu-item" to="/carbon-report">
              <v-icon class="icon component"> mdi-shoe-print </v-icon>
              <span class="menu-item-text">
                {{ t('carbon_footprint') }}
              </span>
            </router-link>
          </li>
          <li @click="setBurgerMenuClick()">
            <router-link class="menu-item" to="/">
              <v-icon v-if="hasImpactActions && !getAccountTypeIsBusiness" class="icon component"
                >mdi-shopping</v-icon
              >
              <img
                v-else
                class="icon"
                alt="your-impact"
                :src="require('@/assets/icons/menu/greenspark-icon.svg')"
              />
              <span class="menu-item-text">
                {{
                  hasImpactActions && !getAccountTypeIsBusiness
                    ? t('impact_wallet')
                    : t('your_impact')
                }}
              </span>
            </router-link>
          </li>
          <li v-if="hasImpactActions && !getAccountTypeIsBusiness" @click="setBurgerMenuClick()">
            <router-link class="menu-item" to="/your-impact">
              <img
                class="icon"
                alt="your-impact"
                :src="require('@/assets/icons/menu/greenspark-icon.svg')"
              />
              <span class="menu-item-text">
                {{ t('your_impact') }}
              </span>
            </router-link>
          </li>
          <li @click="setBurgerMenuClick()">
            <router-link
              class="menu-item"
              :class="{
                'router-link-active':
                  $route.name === 'Integrations' ||
                  $route.name === 'Automations' ||
                  $route.name === 'tailoredImpact',
              }"
              :to="getAccountTypeIsBusiness ? '/add-impact' : '/add-impact/tailored-impact'"
            >
              <img
                class="icon"
                alt="your-impact"
                :src="require('@/assets/icons/menu/add-impact-icon.svg')"
              />
              <span class="menu-item-text">
                {{ t('add_impact') }}
              </span>
            </router-link>
          </li>

          <li @click="setBurgerMenuClick()">
            <router-link class="menu-item" to="/shareables">
              <img
                class="icon"
                alt="your-impact"
                :src="require('@/assets/icons/menu/shareables-icon.svg')"
              />
              <span class="menu-item-text">
                {{ getAccountTypeIsBusiness ? t('engagement_hub') : t('shareables') }}
              </span>
            </router-link>
          </li>

          <li v-if="getAccountTypeIsBusiness" @click="setBurgerMenuClick()">
            <router-link class="menu-item" to="/analytics">
              <v-icon class="icon component"> mdi-chart-box-outline </v-icon>
              <span class="menu-item-text">
                {{ t('analytics') }}
              </span>
            </router-link>
          </li>

          <li v-if="getAccountTypeIsBusiness" @click="setBurgerMenuClick()">
            <router-link class="menu-item" to="/team">
              <img
                class="icon"
                alt="your-impact"
                :src="require('@/assets/icons/menu/your_team.svg')"
              />
              <span class="menu-item-text">
                {{ t('team') }}
              </span>
            </router-link>
          </li>

          <li v-if="getAccountTypeIsBusiness" @click="setBurgerMenuClick()">
            <router-link class="menu-item" to="/network">
              <img
                class="icon"
                alt="your-impact"
                :src="require('@/assets/icons/menu/share-icon.svg')"
              />
              <span class="menu-item-text">
                {{ t('network') }}
              </span>
            </router-link>
          </li>

          <li @click="setBurgerMenuClick()">
            <router-link class="menu-item" to="/projects">
              <img
                class="icon"
                alt="your-impact"
                :src="require('@/assets/icons/menu/supported-projects-icon.svg')"
              />
              <span class="menu-item-text">
                {{ t('supported_projects') }}
              </span>
            </router-link>
          </li>

          <li @click="setBurgerMenuClick()">
            <router-link class="menu-item" to="/ledger">
              <img
                class="icon"
                alt="your-impact"
                :src="require('@/assets/icons/menu/public.svg')"
              />
              <span class="menu-item-text">
                {{ t('public_ledger') }}
              </span>
            </router-link>
          </li>
        </ul>
      </nav>

      <template v-slot:append>
        <v-divider />
        <div @click="setBurgerMenuClick()">
          <router-link class="menu-item" to="/account?tab=profile">
            <img
              class="icon"
              alt="resources"
              :src="require('@/assets/icons/menu/new-account-icon.svg')"
            />
            <span class="menu-item-text">
              {{ t('account') }}
            </span>
          </router-link>
        </div>
        <v-menu
          v-model="isSupportContextMenuOpen"
          close-delay="250"
          content-class="elevation-3"
          transition="slide-y"
          location="right"
          eager
        >
          <template v-slot:activator="{ props }">
            <button
              :class="[
                'menu-item',
                'menu-item-button',
                { 'router-link-active': isSupportContextMenuOpen },
              ]"
              v-bind="props"
            >
              <v-icon class="icon"> mdi-tooltip-question </v-icon>
              <span class="menu-item-text">
                {{ t('support') }}
              </span>
            </button>
          </template>
          <ul class="drawer-menu">
            <p class="segment-header">
              {{ t('documentation') }}
            </p>
            <li class="drawer-menu__list-item">
              <a :href="KNOWLEDGE_BASE_URL" target="_blank" class="drawer-menu__link">
                <v-icon class="icon material-icons-outlined"> mdi-book-outline </v-icon>
                {{ t('knowledge_base') }}
              </a>
            </li>
            <li class="drawer-menu__list-item">
              <a :href="API_DOCUMENTATION_URL" target="_blank" class="drawer-menu__link">
                <v-icon class="icon"> mdi-link-variant </v-icon>
                {{ t('api_docs') }}
              </a>
            </li>
            <template v-if="getAccountTypeIsBusiness">
              <hr class="divider" />

              <li class="drawer-menu__list-item">
                <a class="beamer-trigger-button drawer-menu__link">
                  <v-icon class="icon"> mdi-bell-outline </v-icon>
                  <span class="notification-item-text">{{ t('whats_new') }}</span>
                </a>
              </li>
            </template>

            <hr class="divider" />
            <p class="segment-header">
              {{ t('contact') }}
            </p>
            <li class="drawer-menu__list-item">
              <button class="drawer-menu__link" @click="openChat()">
                <v-icon class="icon material-icons-outlined"> mdi-message-text-outline </v-icon>
                {{ t('open_chat') }}
              </button>
            </li>
            <li class="drawer-menu__list-item">
              <a :href="SUPPORT_EMAIL_URL" target="_blank" class="drawer-menu__link">
                <v-icon class="icon material-icons-outlined"> mdi-email-outline </v-icon>
                {{ t('email') }}
              </a>
            </li>
          </ul>
          <v-menu />
        </v-menu>
      </template>
    </v-navigation-drawer>
  </aside>
</template>

<script lang="ts">
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { API_DOCUMENTATION_URL, KNOWLEDGE_BASE_URL, SUPPORT_EMAIL_URL } from '@/helpers/constants'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Sidebar',
  mixins: [LoadCrispMixin, Utils],
  data() {
    return {
      API_DOCUMENTATION_URL: '',
      KNOWLEDGE_BASE_URL: '',
      SUPPORT_EMAIL_URL: '',
      sidebarMenu: true,
      toggleMini: false,
      png: false,
      disableAccountNameTooltip: true,
      isSupportContextMenuOpen: false,
    }
  },
  computed: {
    accountName(): string {
      return this.account.accountType === 'personal' || this.account.accountType === 'family'
        ? this.currentUser.firstName
        : this.account.companyName
    },
    loading(): boolean {
      return this.$store.getters['getLoading']
    },
    burgerMenuOpen(): boolean {
      return this.$store.getters['getBurgerMenuOpen']
    },
    hasImpactActions(): boolean {
      return this.$store.getters['getHasImpactActions']
    },
    carbonFootprint(): boolean {
      return this.$store.getters['getCarbonFootprintFeatureFlag']
    },
    getAccountTypeIsBusiness(): boolean {
      return this.$store.getters['getAccountTypeIsBusiness']
    },
  },
  async created() {
    this.API_DOCUMENTATION_URL = API_DOCUMENTATION_URL
    this.KNOWLEDGE_BASE_URL = KNOWLEDGE_BASE_URL
    this.SUPPORT_EMAIL_URL = SUPPORT_EMAIL_URL
    this.toggleChatBubbleVisibility(false)
    if (this.account.imageURL) this.png = this.account.imageURL.endsWith('.png')
  },
  methods: {
    t(key: string) {
      return this.$t(`Sidebar.${key}`)
    },
    setBurgerMenuClick() {
      if (this.burgerMenuOpen) {
        this.setBurgerMenu(false)
      } else {
        this.setBurgerMenu(true)
      }
    },
    isAccountNameTruncated() {
      const accountName = document.querySelector('#accountName') as HTMLElement
      if (accountName?.offsetWidth && accountName?.scrollWidth) {
        return this.isEllipsisActive(accountName)
      }
    },
    isEllipsisActive(e: HTMLElement) {
      return e.offsetWidth < e.scrollWidth
    },
    onImageChange() {
      if (this.account.imageURL) this.png = this.account.imageURL.endsWith('.png')
    },
    onWindowSizeChange() {
      // when the window is resized to tablet or mobile, close the support context menu
      if (!this.isDesktop || !this.isDesktopLarge) {
        this.isSupportContextMenuOpen = false
      }
    },
    setBurgerMenu(active: boolean) {
      return this.$store.commit('setBurgerMenu', active)
    },
  },
  props: {
    breakpoint: {
      type: String,
    },
    memberSince: {
      type: String,
    },
  },
  watch: {
    '$store.state.account.imageURL': [
      {
        handler: 'onImageChange',
      },
    ],
    windowWidth: [
      {
        handler: 'onWindowSizeChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.sidebar {
  display: none;
}

.v-navigation-drawer {
  z-index: 5 !important;
}

.logo-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.logo {
  max-width: 150px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.logo-small {
  width: 45px;
  margin-top: 20px;
  margin-bottom: 12px;
}

.business-logo {
  margin-top: 15px;
  margin-left: 52px;
  width: 150px;
  height: 150px;
  margin-right: 35px;
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.business-logo-business {
  margin-top: 15px;
  margin-left: 52px;
  width: 150px;
  height: 150px;
  margin-right: 35px;
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.upload {
  position: relative;
  top: -50px;
  left: -40px;
  width: 20px;
}

.business-name {
  text-align: center;
  margin-top: 12px;
  font-size: 32px;
  line-height: 32px;
  padding: 5px 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.member-since {
  text-align: center;
  font-size: 11px;
  margin-bottom: 18px;
  margin-top: 7px;
  color: var(--ui-green-light);
}

.menu-item-list {
  list-style-type: none;
  padding-left: 0;
}

.menu-item {
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 8px;
  font-weight: 500;
  cursor: pointer;
  color: var(--ui-black);
  text-decoration: none;
}

.beamer-trigger-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .notification-item-text {
    flex-grow: 1;
    flex-shrink: 0;
  }

  :deep(.beamer_icon.active) {
    align-self: flex-end;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    padding: 4px 8px;
    border-radius: 4px;
    color: var(--ui-black);
    background-color: var(--ui-emerald-green) !important;
    position: relative;
    height: auto;
    width: auto;
  }
}

.menu-item.router-link-active {
  background-color: var(--ui-green);
  color: var(--ui-white);
  fill: var(--ui-white);
}

.menu-item:hover:not(.router-link-active) {
  background-color: #afc6bd;
}

.icon {
  margin-right: 15px;
  max-width: 20px;
  color: var(--font-color-primary);
  fill: var(--font-color-primary);
}

.menu-item.router-link-active .icon {
  filter: invert(96%) sepia(100%) saturate(19%) hue-rotate(216deg) brightness(102%) contrast(104%);
}

.icon.component {
  color: var(--ui-black);
  fill: var(--ui-black);
  filter: none;
}

.menu-item.router-link-active .icon.component {
  color: var(--ui-white);
  fill: var(--ui-white);
  filter: none;
}

.menu-item.menu-item-button {
  width: 100%;
}

.drawer-menu {
  width: 256px;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: var(--ui-white);
}

.drawer-menu__link {
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
  font-weight: 400;
  cursor: pointer;
  color: var(--ui-black);
  text-decoration: none;
  width: 100%;
}

.drawer-menu__link:hover {
  background-color: var(--ui-green-light);
}

.segment-header {
  font-size: 16px;
  padding: 14px 24px 0;
  margin: 0;
}

.divider {
  margin: 0 24px;
  background-color: var(--gray-light-ED);
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .sidebar {
    display: block;
  }
}
</style>
