<template>
  <div class="mobile-menu-wrapper">
    <div :class="menuItem === 0 ? 'menu-item-active' : 'menu-item'" @click="$emit('homeClick')">
      <div class="menu-item-text">
        {{ t('impact') }}
      </div>
    </div>

    <div :class="menuItem === 1 ? 'menu-item-active' : 'menu-item'" @click="$emit('projectsClick')">
      <div class="menu-item-text">
        {{ t('projects') }}
      </div>
    </div>

    <div :class="menuItem === 2 ? 'menu-item-active' : 'menu-item'" @click="$emit('ledgerClick')">
      <div class="menu-item-text">
        {{ t('public_ledger') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PublicImpactMobileMenu',
  emits: ['projectsClick', 'homeClick', 'ledgerClick'],
  components: {},
  methods: {
    t(key: string) {
      return this.$t(`PublicImpactMobileMenu.${key}`)
    },
  },
  props: {
    menuItem: { required: true, type: Number },
  },
})
</script>

<style lang="scss" scoped>
.mobile-menu-wrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 4;
  background-color: #3b755f;
}

.menu-item {
  width: 33%;
  display: flex;
  padding-top: 22px;
  padding-bottom: 18px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.menu-item:active {
  background-color: #afc6bd;
}

.menu-item-active {
  width: 33%;
  display: flex;
  padding-top: 14px;
  padding-bottom: 14px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border-top: 5px solid #f2ebdb;
  filter: none;
  color: #212121;
}

.menu-item-active:active {
  background-color: #afc6bd;
}

.menu-item-text {
  font-size: 14px;
  font-weight: 700;
  color: #f2ebdb;
}

.icon {
  width: 20px;
}
</style>
