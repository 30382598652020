import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'

// Comment this out deploy
// import * as env from '../../env.json'

// Comment this out deploy
// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_FIREBASE || env.VUE_APP_FIREBASE,
//   authDomain: process.env.VUE_APP_AUTH_DOMAIN || env.VUE_APP_AUTH_DOMAIN,
//   projectId: process.env.VUE_APP_PROJECT_ID || env.VUE_APP_PROJECT_ID,
//   storageBucket: process.env.VUE_APP_STORAGEBUCKET || env.VUE_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID || env.VUE_APP_MESSAGINGSENDERID,
//   appId: process.env.VUE_APP_APPID || env.VUE_APP_APPID,
//   measurementId: process.env.VUE_APP_MEASUREMENTID || env.VUE_APP_MEASUREMENTID
// }

// Comment this in to deploy
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
const firebaseAnalytics = firebase.analytics()
firebaseAnalytics.setAnalyticsCollectionEnabled(true)

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

export default firebase
export { firebaseAnalytics }
