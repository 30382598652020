<template>
  <div class="public-ledger-view">
    <public-ledger />
  </div>
</template>

<script lang="ts">
import type { Account } from '@/store'
import PublicLedger from '@/components/common/PublicLedger.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PublicLedgerView',
  components: {
    PublicLedger,
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.description {
  margin: 35px 0;
  display: flex;
  justify-content: flex-start;
}

.description-header {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  text-align: left;
  color: #212121;
  margin-bottom: 16px;
}

.description-text {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  color: #212121;
  margin-bottom: 0;
  max-width: 70%;
}

.accordion-content {
  padding-bottom: 10px;
}

.accordion-wrapper {
  padding: 70px 80px;
  background-image: url('../assets/gifs/comparisons/public_ledger_background.svg');
  border-radius: 8px;
  margin-top: -30px;
  background-size: cover;
}

.menu-bar {
  padding: 8px 30px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-item {
  display: flex;
  cursor: pointer;
  margin: 0 10px;
  background-color: #afc6bd;
  padding: 12px 24px;
  border-radius: 99px;
}

.menu-item:hover {
  background-color: #3b755f;
}

.menu-item-active {
  display: flex;
  cursor: pointer;
  margin: 0 10px;
  background-color: #3b755f;
  padding: 12px 24px;
  border-radius: 99px;
}

.menu-item-label {
  font-size: 18px;
  font-weight: 700;
  color: #f2ebdb;
}

.v-expansion-panel {
  margin: 4px 0;
  background: #f9f9f9;
  border-radius: 4px;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .menu-item,
  .menu-item-active {
    padding: 10px 14px;
  }

  .menu-item-label {
    font-size: 14px;
  }

  .accordion-wrapper {
    padding: 40px 30px 20px 30px;
  }

  .description-header {
    font-size: 22px;
    margin-bottom: 8px;
  }

  .description-text {
    font-size: 16px;
    max-width: none;
  }
}
</style>
