import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6360040b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "description" }
const _hoisted_2 = { class: "description-header" }
const _hoisted_3 = { class: "description-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectCard = _resolveComponent("ProjectCard")!
  const _component_ProjectCardList = _resolveComponent("ProjectCardList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('header')), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('text')), 1)
    ]),
    _createVNode(_component_ProjectCardList, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project, a) => {
          return (_openBlock(), _createBlock(_component_ProjectCard, {
            key: a,
            title: project.name,
            subtitle: project.description,
            image: project.imageUrl,
            link: project.link
          }, null, 8, ["title", "subtitle", "image", "link"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}