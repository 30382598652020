<template>
  <a class="navigate-back" @click="handleClick">
    <v-icon class="navigate-back__icon">mdi-chevron-left</v-icon>
    {{ t('back') }}
  </a>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export type Mode = 'router' | 'programmatic'

export default defineComponent({
  name: 'NavigateBack',
  emits: ['back'],
  methods: {
    t(key: string) {
      return this.$t(`NavigateBack.${key}`)
    },
    handleClick(e: MouseEvent): void {
      if (this.mode === 'router') {
        this.$router.go(-1)
      } else {
        this.$emit('back', e)
      }
    },
  },
  props: {
    mode: {
      default: 'router',
      type: String as PropType<Mode>,
    },
  },
})
</script>

<style lang="scss" scoped>
.navigate-back {
  text-decoration: none;
  color: var(--ui-green);
  display: flex;
  align-items: center;
  margin-left: -4px;
  margin-bottom: 12px;
}

.navigate-back__icon {
  font-size: 20px;
  line-height: 20px;
  color: var(--ui-green);
}
</style>
