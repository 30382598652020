<template>
  <div v-if="loaded" class="dialog-wrapper">
    <div>
      <div class="close-wrapper">
        <div class="dialog-title">
          {{ t('edit') }}
        </div>

        <img
          style="width: 25px"
          class="close-icon"
          :src="require('@/assets/icons/close_icon.svg')"
          alt="close"
          @click="$emit('dialogClick')"
        />
      </div>

      <Partners
        :is-edit="isEdit"
        :is-contact-edit="isContactEdit"
        :edit-items="editItems"
        :relations-id="relationsId"
        @getFreshDataClick="$emit('getFreshDataClick')"
        @dialogClick="$emit('dialogClick')"
        @showErrorAlert="$emit('showErrorAlert')"
        @showSuccessAlert="$emit('showSuccessAlert')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Partners from '@/components/Network/Partners.vue'
import type { Account } from '@/store'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { PartnerData } from '@api/index'

export default defineComponent({
  name: 'Edit',
  emits: ['getFreshDataClick', 'dialogClick', 'showErrorAlert', 'showSuccessAlert'],
  components: {
    Partners,
  },
  data() {
    return {
      loaded: true,
    }
  },
  methods: {
    t(key: string) {
      return this.$t(`Edit.${key}`)
    },
  },
  props: {
    editItems: { type: Array as PropType<PartnerData[]> },
    relationsId: {
      type: String,
    },
    isEdit: { type: Boolean, required: true },
    isContactEdit: { type: Boolean, required: true },
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.dialog-wrapper {
  background: #f9f9f9;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .dialog-wrapper {
    height: auto;
    justify-content: flex-start;
  }
}

.close-wrapper {
  margin-top: -10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.close-icon {
  cursor: pointer;
}

.dialog-title {
  color: #212121;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  width: 100%;
}

.dialog-text {
  color: #212121;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  width: 100%;
}
</style>
