<template>
  <div v-if="loaded" class="signup">
    <div class="signup-wrapper">
      <img alt="" class="greenspark-logo" :src="require('@/assets/greenspark-logo.svg')" />
      <SignupEcommerce @backClick="navigateBack" />
    </div>
  </div>
</template>

<script lang="ts">
import SignupEcommerce from '@/components/register/SignupEcommerce.vue'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import type { Account, StripeURLs } from '@/store'
import { SetUpSocialMediaTracking } from '@/helpers/mixins/setupSocialMediaTracking'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SignupEcommerceView',
  components: {
    SignupEcommerce,
  },
  mixins: [LoadCrispMixin, SetUpSocialMediaTracking],
  data() {
    return {
      progressItem: 0,
      stripe: null,
      loaded: false,
      accountType: '',
    }
  },
  async created() {
    this.setUpFacebookTracking('InitiateCheckout')
    this.toggleChatBubbleVisibility(true)
    this.loaded = true
  },
  methods: {
    t(key: string) {
      return this.$t(`SignupView.${key}`)
    },
    navigateBack() {
      if (this.$route.query.from === 'landingPage')
        window.location.href = 'https://www.getgreenspark.com/pricing/business'
      else this.$router.push('/select-plan')
    },
    setSignupId() {
      return this.$store.commit('setSignupId')
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
  },
  computed: {
    stripeURLs(): StripeURLs {
      return this.$store.getters['getStripeURLs']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.signup {
  min-height: 100vh;
  background-image: url('../../assets/backgrounds/welcome-background.svg');
  background-size: cover;
  background-position: center;
  display: grid;
}

.signup-wrapper {
  margin: auto;
  max-width: 1300px;
}

.greenspark-logo {
  display: none;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .greenspark-logo {
    display: block;
    margin-bottom: 40px;
  }

  .signup-wrapper {
    width: 100%;
  }

  .signup {
    padding: 0 25px;
  }
}
</style>
