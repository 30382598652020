<template>
  <v-card class="dialog-card">
    <div class="card-texts-wrapper">
      <v-card-title
        class="text-red font-weight-bold text-h5 text-h6 d-flex py-6 pt-4 pb-3 align-center"
      >
        {{ dialog?.texts.title }}
        <v-spacer />
        <v-btn icon @click="onClose" variant="plain">
          <v-icon color="black"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-black text-body-1">
        <p class="description" v-html="dialog?.texts.description" />
        <v-checkbox v-model="isDeleteCheckboxChecked" class="delete checkbox">
          <template v-slot:label>
            <span class="text-black">{{ dialog?.texts.deleteCheckboxLabel }}</span>
          </template>
        </v-checkbox>
      </v-card-text>
    </div>
    <v-card-actions class="card-actions">
      <gs-button
        class="delete button"
        size="large"
        type="delete"
        :loading="isLoading"
        :disabled="isLoading || !isDeleteCheckboxChecked"
        @click="onDelete"
      >
        {{ dialog?.texts.primaryButtonText }}
      </gs-button>
      <gs-button class="cancel button" size="large" type="outlined" @click="onCancel">
        {{ dialog?.texts.secondaryButtonText }}
      </gs-button>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import type { Dialog } from '@/store/dialog'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DeleteConfirmationDialogTemplate',
  data() {
    return {
      isLoading: false,
      isDeleteCheckboxChecked: false,
    }
  },
  methods: {
    onClose() {
      if (this.dialog?.actions?.onCloseButtonClick) {
        this.dialog.actions.onCloseButtonClick()
      }
      if (this.close) this.close()
    },
    async onDelete() {
      if (this.dialog?.actions?.onPrimaryButtonClick) {
        this.isLoading = true
        try {
          await this.dialog.actions.onPrimaryButtonClick()
          this.isLoading = false
          if (this.close) this.close()
        } catch (error) {
          // error handled in store action
          this.isLoading = false
        }
      } else {
        if (this.close) this.close()
      }
    },
    onCancel() {
      if (this.dialog?.actions?.onSecondaryButtonClick) {
        this.dialog?.actions.onSecondaryButtonClick()
      }
      if (this.close) this.close()
    },
  },
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
    close: {
      type: Function as PropType<() => void>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.dialog-card {
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-texts-wrapper {
  padding: 0 8px;
}

.title {
  display: flex;
  flex-wrap: nowrap;
  word-break: break-word;
}

.delete.button {
  position: relative;
  flex: 1 1 50%;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
}

.cancel.button {
  position: relative;
  flex: 1 1 50%;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
}

.description:deep(span) {
  background: var(--ui-beige);
  padding: 0 5px 0;
  border-radius: 6px;
  font-weight: 600;
  white-space: nowrap;
}

.card-actions {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .card-actions {
    flex-wrap: nowrap;
  }

  .delete.button {
    margin-right: 20px;
  }

  .cancel.button {
    margin-left: 0;
  }
}
</style>
