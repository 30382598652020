<template>
  <div class="comparison-dialog">
    <button class="close-button" @click="$emit('close')" />
    <features-table class="features-table" />
  </div>
</template>

<script lang="ts">
import FeaturesTable from '@/components/checkout/FeaturesTable.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ComparisonDialog',
  emits: ['close'],
  components: { FeaturesTable },
  methods: {
    t(key: string) {
      return this.$t(`ComparisonDialog.${key}`)
    },
  },
})
</script>

<style lang="scss" scoped>
.comparison-dialog {
  background-color: var(--ui-white);
  padding: 90px 0 40px;
  position: relative;
}

.features-table {
  max-width: 770px;
  margin: 0 auto;
}
</style>
