<template>
  <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.2292 17C24.3138 17 17.0671 24.7643 17.0671 34.3165C17.0671 43.8687 24.3138 51.633 33.2292 51.633C42.1446 51.633 49.3913 43.8588 49.3913 34.3165C49.3913 24.7741 42.1446 17 33.2292 17Z"
      fill="#1691F8"
    />
    <path
      d="M31.4959 24.4473C30.9612 25.4055 30.159 26.0278 29.1172 26.2945C26.0102 27.0847 25.6322 28.5764 25.374 30.9372C25.0421 34.0785 21.1975 38.4941 18.1551 38.4941C18.0536 38.4941 17.9522 38.4842 17.8508 38.4743C17.7494 38.4644 17.6388 38.4447 17.5281 38.4249C17.2239 37.1012 17.0671 35.7282 17.0671 34.3156C17.0671 25.3264 23.4748 17.9277 31.6619 17.0781H31.6711L31.8739 17.9079C31.9108 18.0758 32.8604 21.9777 31.4959 24.4473Z"
      fill="#2DF494"
    />
    <path
      d="M49.3912 34.3165C49.3912 36.6576 48.9487 38.9 48.1558 40.9448C48.0359 40.9547 47.9161 40.9547 47.7962 40.9547C46.3579 40.9547 45.187 40.5003 44.3019 39.9471C41.407 38.1493 39.812 34.2572 39.9411 31.5309C39.9872 30.5035 39.4985 30.0096 38.5581 29.17C37.673 28.3698 36.5851 27.382 36.4007 25.5644C36.0596 21.9984 40.2269 19.4301 40.402 19.3214L40.8446 19.0547H40.8538C45.9338 21.9688 49.3912 27.7179 49.3912 34.3165Z"
      fill="#2DF494"
    />
    <path
      d="M39.2958 44.3433C39.1114 45.9534 37.3689 47.4648 34.7505 48.3044C32.464 49.0354 32.5931 50.2208 32.6208 50.3492L32.925 51.6235C28.1861 51.5346 23.9543 49.2528 21.0593 45.6867L21.1515 45.2126C21.5572 43.1974 22.2007 41.5004 23.6007 42.5004C25.4674 44.0004 25.4674 44.3725 25.4674 46.0004C25.4674 47.5004 25.934 47.5004 27.8007 47.5004C28.151 47.619 30.8949 46.6852 32.0007 45.5004C34.334 43.0004 36.0228 40.4612 37.7838 41.5873C39.2682 42.5455 39.3696 43.7308 39.2958 44.3433Z"
      fill="#2DF494"
    />
    <g clip-path="url(#clip0_5145_3117)">
      <path
        d="M67.9252 31.5682C67.7765 31.9613 67.4046 32.227 66.9796 32.2482L63.569 32.397C63.4521 40.0895 59.9458 47.4207 54.049 52.1807L53.3371 52.7545C52.9546 53.0626 52.4233 53.0626 52.0302 52.7757C51.6371 52.4888 51.499 51.9682 51.6796 51.522L52.0621 50.5763C54.3571 44.9663 55.5471 38.8251 55.5365 32.7582L52.2002 32.907C51.754 32.907 51.3715 32.6838 51.1908 32.3013C51.0102 31.9188 51.074 31.462 51.3608 31.1432L58.554 23.0895C58.7452 22.877 59.0215 22.7495 59.3083 22.7388C59.319 22.7388 59.3296 22.7388 59.3402 22.7388C59.6165 22.7388 59.8821 22.8451 60.084 23.0363L67.6596 30.4313C67.9677 30.7288 68.0633 31.1751 67.9146 31.5682H67.9252Z"
        fill="white"
      />
      <path
        d="M35.2323 55.5463L35.0836 52.2101C35.0623 51.7851 35.3067 51.3813 35.6892 51.2007C35.8379 51.1369 35.9867 51.0944 36.1461 51.0944C36.4011 51.0944 36.6561 51.1901 36.8579 51.3601L44.9117 58.5532C45.1242 58.7444 45.2517 59.0207 45.2623 59.3076C45.2623 59.5944 45.1667 59.8813 44.9648 60.0832L37.5698 67.6801C37.2723 67.9882 36.8261 68.0838 36.4329 67.9351C36.0398 67.7863 35.7742 67.4144 35.7529 66.9894L35.6042 63.5788C27.9117 63.4619 20.5804 59.9557 15.8204 54.0588L15.2467 53.3469C14.9386 52.9644 14.9279 52.4332 15.2254 52.0401C15.5123 51.6469 16.0329 51.5088 16.4792 51.6894L17.4248 52.0719C23.0136 54.3563 29.1336 55.5463 35.1792 55.5463H35.2429H35.2323Z"
        fill="white"
      />
      <path
        d="M9.43496 44.9143C9.24371 45.1268 8.96746 45.2543 8.68058 45.2649C8.39371 45.2755 8.10683 45.1693 7.90496 44.9674L0.318707 37.5724C0.0105821 37.2749 -0.0850429 36.8286 0.0637071 36.4355C0.212457 36.0424 0.584332 35.7768 1.00933 35.7555L4.41996 35.6068C4.53683 27.9143 8.04308 20.583 13.94 15.823L14.6518 15.2493C14.8431 15.0899 15.0875 15.0155 15.3212 15.0155C15.5443 15.0155 15.7675 15.0899 15.9587 15.228C16.3518 15.5149 16.49 16.0355 16.3093 16.4818L15.9268 17.4274C13.6318 23.0374 12.4418 29.1786 12.4525 35.2455L15.7887 35.0968C16.2456 35.0755 16.6175 35.3199 16.7981 35.7024C16.9787 36.0849 16.915 36.5418 16.6281 36.8605L9.43496 44.9143Z"
        fill="white"
      />
      <path
        d="M51.5309 16.3085L50.5853 15.926C44.9966 13.6416 38.8766 12.4516 32.8309 12.4516H32.7672L32.9159 15.7878C32.9372 16.2128 32.6928 16.6166 32.3103 16.7972C31.9278 16.9778 31.4709 16.9141 31.1522 16.6272L23.0984 9.43408C22.8859 9.24283 22.7584 8.96658 22.7478 8.6797C22.7478 8.39283 22.8434 8.10596 23.0453 7.90408L30.4403 0.328457C30.6422 0.115957 30.9184 0.00970459 31.2053 0.00970459C31.3328 0.00970459 31.4603 0.0309582 31.5878 0.0840816C31.9809 0.232832 32.2466 0.604708 32.2678 1.02971L32.4166 4.44033C40.1091 4.55721 47.4403 8.06346 52.2003 13.9603L52.7741 14.6722C53.0822 15.0547 53.0928 15.586 52.7953 15.9791C52.5084 16.3722 51.9878 16.5103 51.5416 16.3297L51.5309 16.3085Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5145_3117">
        <rect width="68" height="68" fill="white" transform="matrix(1 0 0 -1 -0.000244141 68)" />
      </clipPath>
    </defs>
  </svg>
</template>
