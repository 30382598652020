<template>
  <section class="manage-integrations-automations">
    <h1 class="integrations-title">
      {{ t('title') }}
    </h1>
    <navigate-back mode="programmatic" @back="$router.push({ path: '/add-impact' })" />
    <div class="text-content">
      <p class="description">
        {{ t('description') }}
      </p>
      <gs-button
        v-if="hasAnyAutomation"
        size="large"
        width="200"
        @click="$router.push({ name: 'AddIntegrationsAndAutomations' })"
      >
        {{ t('add_automation') }}
      </gs-button>
    </div>
    <div class="tab-wrapper">
      <router-link to="/add-impact/manage-integrations-automations/automations" class="tab-pill">
        {{ t('automations') }}
      </router-link>
      <router-link to="/add-impact/manage-integrations-automations/integrations" class="tab-pill">
        {{ t('integrations') }}
      </router-link>
    </div>

    <router-view :key="$route.fullPath" />
  </section>
</template>

<script lang="ts">
import NavigateBack from '@/components/common/NavigateBack.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ManageIntegrationsAutomationsView',
  components: { NavigateBack },
  async created() {
    await this.setIntegrations()
  },
  methods: {
    t(key: string) {
      return this.$t(`ManageIntegrationsAutomationsView.${key}`)
    },
    setIntegrations(): Promise<void> {
      return this.$store.dispatch('setIntegrations')
    },
  },
  computed: {
    hasAnyAutomation(): boolean {
      return this.$store.getters['hasAnyAutomation']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.tab-wrapper {
  display: flex;
  margin-bottom: 30px;
  gap: 10px;
}

.tab-pill {
  align-items: center;
  display: flex;
  cursor: pointer;
  background-color: var(--ui-green-light);
  padding: 12px 24px;
  border-radius: 99px;
  transition: all linear 0.2s;
  font-size: 18px;
  font-weight: 700;
  color: var(--ui-beige);
  margin: 0;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
}

.tab-pill.router-link-exact-active {
  scroll-snap-align: end;
  background-color: var(--ui-green);
}

.tab-pill:hover,
.tab-pill:focus {
  box-shadow: inset 290px 0 0 0 var(--ui-green);
}

h1.integrations-title {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 20px;
}

.text-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.description {
  max-width: 44ch;
  font-size: 18px;
  line-height: 22px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .text-content {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
</style>
