import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, mergeProps as _mergeProps, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd4addfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "data-table-wrapper" }
const _hoisted_2 = { class: "data-table-header" }
const _hoisted_3 = { class: "data-table-header-filters" }
const _hoisted_4 = {
  key: 0,
  class: "data-table-header-actions"
}
const _hoisted_5 = {
  key: 0,
  class: "not-found"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "impact-pill-wrapper" }
const _hoisted_9 = { class: "impact-pill-row" }
const _hoisted_10 = { class: "product-sync" }
const _hoisted_11 = { class: "product-sync-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImpactSettingsPopup = _resolveComponent("ImpactSettingsPopup")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_impact_pill = _resolveComponent("impact-pill")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_LoaderBlock = _resolveComponent("LoaderBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.impactSettingsPopupIsOpen)
      ? (_openBlock(), _createBlock(_component_ImpactSettingsPopup, {
          key: 0,
          "selected-products": _ctx.selectedItems,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.impactSettingsPopupIsOpen = false)),
          onSave: _ctx.saveImpactSettings
        }, null, 8, ["selected-products", "onSave"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_text_field, {
            modelValue: _ctx.search,
            "onUpdate:modelValue": [
              _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
              _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchProducts()))
            ],
            "append-inner-icon": "mdi-magnify",
            placeholder: _ctx.t('search'),
            variant: "outlined",
            "hide-details": "",
            color: "green",
            class: "product-search"
          }, null, 8, ["modelValue", "placeholder"]),
          _createVNode(_component_v_select, {
            modelValue: _ctx.impactFilter,
            "onUpdate:modelValue": [
              _cache[3] || (_cache[3] = ($event: any) => ((_ctx.impactFilter) = $event)),
              _cache[4] || (_cache[4] = ($event: any) => (_ctx.filterProducts()))
            ],
            label: _ctx.t('filter_by_impact'),
            items: _ctx.impactTypes,
            multiple: "",
            variant: "outlined",
            "hide-details": "",
            color: "green",
            class: "product-filter"
          }, null, 8, ["modelValue", "label", "items"])
        ]),
        (_ctx.selectedItems.length && _ctx.items.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_gs_button, {
                onClick: _withModifiers(_ctx.addBulkImpact, ["prevent"]),
                capitalized: "",
                uppercased: false
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('add_bulk_impact')) + " ", 1),
                  _createElementVNode("span", null, "(" + _toDisplayString(_ctx.selectedItems.length) + ")", 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_dialog, { "max-width": "600" }, {
                activator: _withCtx(({ props: activatorProps }) => [
                  _createVNode(_component_gs_button, _mergeProps(activatorProps, {
                    capitalized: "",
                    uppercased: false,
                    type: "monochrome"
                  }), {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('clear_impact.label')), 1)
                    ]),
                    _: 2
                  }, 1040)
                ]),
                default: _withCtx(({ isActive }) => [
                  _createVNode(_component_v_card, {
                    title: _ctx.t('clear_impact.title')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('clear_impact.description')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_card_actions, { class: "pa-4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_gs_button, {
                            onClick: _withModifiers(($event: any) => (isActive.value = false), ["prevent"]),
                            capitalized: "",
                            uppercased: false,
                            "full-width": "",
                            outlined: "",
                            class: "mr-3",
                            type: "monochrome"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('CommonUi.cancel')), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_component_v_spacer),
                          _createVNode(_component_gs_button, {
                            onClick: _withModifiers(
                      () => {
                        _ctx.clearImpact()
                        isActive.value = false
                      }
                    , ["prevent"]),
                            capitalized: "",
                            uppercased: false,
                            "full-width": "",
                            type: "monochrome"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('clear_impact.label')), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["title"])
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      ((!!_ctx.search || _ctx.impactFilter.length) && !_ctx.items.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_v_icon, { color: "black" }, {
              default: _withCtx(() => [
                _createTextVNode("mdi-magnify-expand")
              ]),
              _: 1
            }),
            _createElementVNode("p", {
              class: "not-found-text",
              innerHTML: _ctx.t('not_found')
            }, null, 8, _hoisted_6)
          ]))
        : _createCommentVNode("", true),
      (_ctx.showTable)
        ? (_openBlock(), _createBlock(_component_v_data_table_server, {
            key: _ctx.tableRender,
            modelValue: _ctx.selectedItems,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedItems) = $event)),
            "sort-by": _ctx.sortBy,
            "onUpdate:sortBy": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.sortBy) = $event)),
            headers: _ctx.headers,
            items: _ctx.items,
            "checkbox-color": "#3B755F",
            "show-select": "",
            class: "elevation-0 data-table",
            "item-value": "productId",
            "items-per-page": _ctx.itemsPerPage,
            "onUpdate:itemsPerPage": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
            "items-per-page-options": [5, 10, 20, 50],
            color: "#3B755F",
            height: "423",
            "items-length": _ctx.itemsCount,
            "onUpdate:options": _ctx.setItems,
            onToggleSelectAll: _ctx.setAllSelected
          }, {
            [`item.image`]: _withCtx(({ item }) => [
              _createElementVNode("img", {
                class: "product-image",
                src: item.image
              }, null, 8, _hoisted_7)
            ]),
            [`item.offsetType`]: _withCtx(({ item }) => [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  (_ctx.getProductOffsets(item.productId).length <= 3)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getProductOffsets(item.productId), (offset, index) => {
                        return (_openBlock(), _createBlock(_component_impact_pill, {
                          key: index,
                          class: "ma-0",
                          impact: { type: offset.type, amount: offset.amount || 0 }
                        }, null, 8, ["impact"]))
                      }), 128))
                    : (_openBlock(), _createBlock(_component_impact_pill, {
                        key: 1,
                        impact: {
                  type: 'multiple',
                  amount: _ctx.getTotalProductOffset(_ctx.getProductOffsets(item.productId)),
                }
                      }, null, 8, ["impact"]))
                ]),
                _createVNode(_component_gs_button, {
                  onClick: _withModifiers(($event: any) => (_ctx.addImpact(item.productId)), ["prevent"]),
                  class: _normalizeClass({ icon: _ctx.getProductOffsets(item.productId)?.length }),
                  size: "small",
                  type: "monochrome",
                  outlined: "",
                  icon: "mdi-plus",
                  capitalized: "",
                  uppercased: false
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getProductOffsets(item.productId)?.length ? '' : _ctx.t('add_impact')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick", "class"])
              ])
            ]),
            _: 2
          }, 1032, ["modelValue", "sort-by", "headers", "items", "items-per-page", "items-length", "onUpdate:options", "onToggleSelectAll"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.t('sync_products.title')), 1),
        _createVNode(_component_gs_button, {
          onClick: _withModifiers(_ctx.fetchProducts, ["prevent"]),
          type: "monochrome",
          icon: "mdi-sync",
          disabled: _ctx.getProductFetchStatusByIntegrationId(_ctx.integrationId) === 'fetching',
          loading: _ctx.getProductFetchStatusByIntegrationId(_ctx.integrationId) === 'fetching',
          capitalized: "",
          uppercased: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('sync_products.button')), 1)
          ]),
          _: 1
        }, 8, ["onClick", "disabled", "loading"])
      ])
    ], 512), [
      [_vShow, !_ctx.showLoader]
    ]),
    _withDirectives(_createVNode(_component_LoaderBlock, null, null, 512), [
      [_vShow, _ctx.showLoader]
    ])
  ]))
}