<template>
  <div
    v-if="loaded"
    :class="
      footerItems && footerItems.length > 1 ? 'table-footer-outer' : 'table-footer-outer center'
    "
  >
    <div v-if="footerItems && footerItems.length > 1" class="arrow" @click="arrowClick('back')">
      <img class="arrow-icon" :src="require('@/assets/icons/partners/arrow-left.svg')" alt="left" />
    </div>

    <div class="pages">
      <div
        v-for="(item, a) in firstFooterItems"
        :key="a"
        :class="item.active ? 'active table-footer-item' : 'table-footer-item'"
        @click=";(activePage && activePage + 1) === item.value ? '' : footerClick(item.value)"
      >
        <div>{{ item.label }}</div>
      </div>

      <div v-if="firstDotsActive" class="table-footer-item-dots">
        <div>...</div>
      </div>

      <div
        v-for="(item, a) in secondFooterItems"
        :key="a"
        :class="item.active ? 'active table-footer-item' : 'table-footer-item'"
        @click=";(activePage && activePage + 1) === item.value ? '' : footerClick(item.value)"
      >
        <div>{{ item.label }}</div>
      </div>

      <div v-if="secondDotsActive" class="table-footer-item-dots">
        <div>...</div>
      </div>

      <div
        v-for="(item, a) in thirdFooterItems"
        :key="a"
        :class="item.active ? 'active table-footer-item' : 'table-footer-item'"
        @click=";(activePage && activePage + 1) === item.value ? '' : footerClick(item.value)"
      >
        <div>{{ item.label }}</div>
      </div>
    </div>

    <div v-if="footerItems && footerItems.length > 1" class="arrow" @click="arrowClick('next')">
      <img
        class="arrow-icon"
        :src="require('@/assets/icons/partners/arrow-right.svg')"
        alt="right"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { MenuItem } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TableFooter',
  emits: ['footerClick', 'arrowClick'],
  components: {},
  data() {
    return {
      thirdFooterItems: [],
      secondFooterItems: [],
      firstFooterItems: [],
      firstDotsActive: false,
      secondDotsActive: false,
      loaded: false,
    } as {
      thirdFooterItems: MenuItem[]
      secondFooterItems: MenuItem[]
      firstFooterItems: MenuItem[]
      firstDotsActive: boolean
      secondDotsActive: boolean
      loaded: boolean
    }
  },
  created() {
    this.setPages()
  },
  methods: {
    t(key: string) {
      return this.$t(`TableFooter.${key}`)
    },
    setPages() {
      this.firstFooterItems = []
      this.secondFooterItems = []
      this.thirdFooterItems = []
      this.firstDotsActive = false
      this.secondDotsActive = false

      if (this.footerItems.length <= 5) {
        for (let i = 0; i < this.footerItems.length; i++) {
          this.firstFooterItems.push(this.footerItems[i])
        }
      }

      if (this.activePage < 3 && this.footerItems.length > 5) {
        for (let i = 0; i < 4; i++) {
          this.firstFooterItems.push(this.footerItems[i])
        }
        this.secondFooterItems.push(this.footerItems[this.footerItems.length - 1])
        this.firstDotsActive = true
      }

      if (
        this.activePage >= 3 &&
        this.activePage <= this.footerItems.length - 3 &&
        this.footerItems.length > 5
      ) {
        this.firstFooterItems.push(this.footerItems[0])
        for (let i = this.activePage - 1; i < this.activePage + 2; i++) {
          this.secondFooterItems.push(this.footerItems[i])
        }
        this.thirdFooterItems.push(this.footerItems[this.footerItems.length - 1])
        this.firstDotsActive = true
        this.secondDotsActive = true
      }

      if (this.activePage > this.footerItems.length - 3 && this.footerItems.length > 5) {
        this.firstFooterItems.push(this.footerItems[0])
        for (let i = this.footerItems.length - 3; i < this.footerItems.length; i++) {
          this.secondFooterItems.push(this.footerItems[i])
        }
        this.firstDotsActive = true
      }
      this.loaded = true
    },
    footerClick(value) {
      this.$emit('footerClick', value - 1)
    },
    arrowClick(value) {
      this.$emit('arrowClick', value)
    },
    onFooterItemsChange() {
      this.setPages()
    },
  },
  props: {
    footerItems: {
      type: Array as PropType<MenuItem[]>,
      required: true,
    },
    activePage: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  watch: {
    footerItems: [
      {
        handler: 'onFooterItemsChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.table-footer-outer {
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 18px;
  padding-bottom: 16px;
  background: #f2ebdb;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

// .space-between {
//   justify-content: space-between;
// }

.center {
  justify-content: center;
}

.pages {
  display: flex;
}

.table-footer-item {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3b755f;
  display: flex;
  flex-direction: row;
  background: #f9f9f9;
  border-radius: 16px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #f2ebdb;
  margin-left: 2px;
  margin-right: 2px;
}

.table-footer-item:hover {
  opacity: 0.8;
  border: 2px solid #3b755f;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.active {
  border: 2px solid #3b755f;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.table-footer-item-dots {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3b755f;
  display: flex;
  flex-direction: row;
  background: #f9f9f9;
  border-radius: 16px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #f2ebdb;
  margin-left: 2px;
  margin-right: 2px;
}

.arrow {
  background: #f9f9f9;
  border-radius: 16px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #f2ebdb;
}

.arrow:hover {
  opacity: 0.8;
  border: 2px solid #3b755f;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
</style>
