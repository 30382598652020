import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-496a0eb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "latest-order-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderBlock = _resolveComponent("LoaderBlock")!
  const _component_LatestImpactActionsListItem = _resolveComponent("LatestImpactActionsListItem")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_panel = _resolveComponent("v-panel")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_LoaderBlock, { key: 0 }))
    : (_openBlock(), _createBlock(_component_v_panel, {
        key: 1,
        padding: "small"
      }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t('latest_orders')), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("ul", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.impactActions, (impactAction) => {
              return (_openBlock(), _createBlock(_component_LatestImpactActionsListItem, {
                key: impactAction._id,
                "impact-action": impactAction
              }, null, 8, ["impact-action"]))
            }), 128))
          ]),
          _createVNode(_component_v_btn, {
            class: "see-all button",
            height: "56",
            width: "100%",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('impact-actions', 'impact_actions')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('see_all')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}