import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-123601f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "outer-wrapper" }
const _hoisted_2 = { class: "panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Stats = _resolveComponent("Stats")!
  const _component_ComparisonCardPublic = _resolveComponent("ComparisonCardPublic")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Stats, { "total-impacts-array": _ctx.totalImpactsArray }, null, 8, ["total-impacts-array"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalImpactsArray, (totalImpact, index) => {
        return (_openBlock(), _createBlock(_component_ComparisonCardPublic, {
          key: index,
          impact: totalImpact.amount,
          "impact-type": totalImpact.type,
          "live-page": true
        }, null, 8, ["impact", "impact-type"]))
      }), 128))
    ])
  ]))
}