<template>
  <div class="referral-grid-outer">
    <!-- if there are referrals -->
    <div v-if="!empty">
      <MainTable
        :menu-items="menuItems"
        :rows="rowItems"
        :column-width="columnWidth"
        :footer-items="footerItems"
        :hide-partner-image="false"
        :hide-search="false"
        :no-results="noSearchResults"
        :table-loading="tableLoading"
        :active-page="activePage"
        @quickMenuClick="quickMenuClick"
        @emitSearch="search"
        @emitSort="sort"
        @footerClick="footerClick"
        @arrowClick="arrowClick"
      />

      <!-- Quick menu items -->
      <v-dialog
        v-model="quickMenuDialog"
        width="600"
        persistent
        :fullscreen="isMobile ? true : false"
      >
        <div class="pop-up">
          <!-- Reward -->
          <Reward
            v-if="activeQuickMenuItem === 'reward'"
            :relations-id="relationsIdQuickMenuItem"
            :connectee-id-quick-menu-item="connecteeIdQuickMenuItem"
            @dialogClick="dialogClick()"
            @showErrorAlert="$emit('showErrorAlert')"
            @showSuccessAlert="$emit('showSuccessAlert')"
            @getFreshDataClick="$emit('getFreshDataClick')"
          />

          <!-- History -->
          <History
            v-if="activeQuickMenuItem === 'history'"
            :row-items="historyRowItems"
            :relations-id="relationsIdQuickMenuItem"
            :partner-name="partnerName"
            :window-width="windowWidth"
            @dialogClick="dialogClick()"
          />
        </div>
      </v-dialog>
    </div>

    <!-- Loading spinner -->
    <LoadingOpaque v-if="loading" />

    <!-- No referrals -->
    <div v-if="empty" class="empty">
      <Empty
        :text="t('empty')"
        :text1="t('empty1')"
        :button-text="t('invite')"
        @buttonClick="$emit('emptyButtonClick')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import MainTable, { type TableItem, type RowItem } from '@/components/tools/Table/MainTable.vue'
import type { MenuItem } from '@/helpers/interfaces'
import Reward from '@/components/Network/Reward.vue'
import History from '@/components/Network/History.vue'
import LoadingOpaque from '@/components/tools/LoadingOpaque.vue'
import Empty from '@/components/Network/Empty.vue'
import { listReferrals } from '@api/index'
import { Utils } from '@/helpers/mixins/utilsMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ReferralGrid',
  emits: [
    'showErrorAlert',
    'showSuccessAlert',
    'getFreshDataClick',
    'emptyButtonClick',
    'emptyClick',
    'setCardDialogClick',
    'dialogClick',
    'hideButtonClick',
  ],
  mixins: [Utils],
  components: {
    MainTable,
    Reward,
    History,
    LoadingOpaque,
    Empty,
  },
  data() {
    const footerItems: MenuItem[] = []
    const historyRowItems: RowItem[] = []
    const rowItems: RowItem[] = []
    const menuItems: TableItem[] = []

    return {
      menuItems,
      rowItems,
      rowItemsAmount: 0,
      historyRowItems,
      footerItems,
      tableWidth: 0,
      columnWidth: 0,
      quickMenuDialog: false,
      activeQuickMenuItem: '',
      relationsIdQuickMenuItem: '',
      connecteeIdQuickMenuItem: '',
      partnerName: '',
      loading: false,
      empty: false,
      searchString: '',
      noSearchResults: false,
      sortDirection: 'desc',
      sortBy: 'added',
      skip: 0,
      tableLoading: false,
      referredUserCount: 0,
      activePage: 0,
    }
  },
  computed: {
    accountId(): string {
      return this.$store.getters['getAccountId']
    },
    hasSubscription(): boolean {
      return this.$store.getters['hasPaidSubscriptionItem']
    },
  },
  async created() {
    this.loading = true
    await this.getReferredUsers()
    this.setMenuItems()
    // this.setRowItems()
    this.reportTableSize()
    if (this.rowItems.length < 1) {
      this.empty = true
      this.$emit('emptyClick', 'referral')
    }
    this.setFooterItems()
    this.loading = false
  },
  methods: {
    t(key: string) {
      return this.$t(`ReferralGrid.${key}`)
    },
    async getReferredUsers() {
      this.rowItems = []
      // get the list of referred relations
      const {
        data: { data: referredUsers, count },
      } = await listReferrals(this.sortBy, this.sortDirection, this.skip, 10, this.searchString)
      this.referredUserCount = count
      // sort partners by date
      // referredUsers.sort((a, b) => new Date(b.inviteSentAt) - new Date(a.inviteSentAt))

      // iterate over referred relations to set up proper data for the referrals
      for (let i = 0; i < referredUsers.length; i++) {
        this.rowItemsAmount = 0
        const payload: RowItem = {
          id: '',
          relationsId: '',
          image: '',
          name: '',
          labels: [], // object with either type: text, impact
          status: { text: '', customWidth: undefined },
          quickMenuItems: [
            { label: 'Reward', value: 'reward' },
            { label: 'Funding history', value: 'history' },
          ],
        }

        // check if partner is connector or connectee
        let userIsConnectee = false
        if (referredUsers[i].connectee.id === this.accountId) {
          userIsConnectee = true
        }

        // set the id of the user
        payload.id = userIsConnectee ? referredUsers[i].connector.id : referredUsers[i].connectee.id

        // set the id of the relationship
        payload.relationsId = referredUsers[i]._id

        // set the image URL
        payload.image = userIsConnectee
          ? referredUsers[i].connector.imageURL
          : referredUsers[i].connectee.imageURL

        // set the name
        payload.name = userIsConnectee
          ? 'Referred you:' + ' ' + referredUsers[i].connector.displayName
          : referredUsers[i].connectee.displayName
        this.rowItemsAmount++

        // set labels
        // added date
        const date = new Date(referredUsers[i].inviteAcceptedAt)
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        const addedDate = `${day}.${month}.${year}`
        payload.labels.push({ text: addedDate, type: 'text', labels: [] })
        this.rowItemsAmount++

        // impacts
        const impactObject: { type: string; labels: { text: string; type: string }[] } = {
          type: 'impact',
          labels: [],
        }

        for (let j = 0; j < referredUsers[i].totalImpacts.length; j++) {
          if (referredUsers[i].totalImpacts[j].userId === referredUsers[i].connectee.id) {
            impactObject.labels.push({
              text: `${
                referredUsers[i].totalImpacts[j].type === 'carbon'
                  ? referredUsers[i].totalImpacts[j].amount + 'kg'
                  : referredUsers[i].totalImpacts[j].amount
              } ${
                referredUsers[i].totalImpacts[j].type === 'carbon'
                  ? 'CO2'
                  : referredUsers[i].totalImpacts[j].type
              }`,
              type: `${referredUsers[i].totalImpacts[j].type}`,
            })
          }
        }
        payload.labels.push(impactObject)
        this.rowItemsAmount++

        // set status (this is always 'active' for referrals)
        payload.status.text = 'active'
        this.rowItemsAmount++

        // push the row into the array for rendering
        this.rowItems.push(payload)
      }
      if (this.rowItems.length === 0) {
        this.noSearchResults = true
      } else {
        this.noSearchResults = false
      }
    },
    setMenuItems() {
      this.menuItems = []
      this.menuItems.push(
        {
          label: this.t('partner_name'),
          value: 'name',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
        {
          label: this.t('added'),
          value: 'added',
          sortDirection: 'desc',
          sortedBy: true,
          sortable: true,
        },
        {
          label: this.t('impact'),
          value: 'impact',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
        {
          label: this.t('status'),
          value: 'status',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
      )
    },
    resetMenuItems() {
      this.menuItems = []
      this.menuItems.push(
        {
          label: this.t('partner_name'),
          value: 'name',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
        {
          label: this.t('added'),
          value: 'added',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
        {
          label: this.t('impact'),
          value: 'impact',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
        {
          label: this.t('status'),
          value: 'status',
          sortDirection: 'desc',
          sortedBy: false,
          sortable: true,
        },
      )
    },
    setFooterItems() {
      const totalPages = this.referredUserCount / 10
      this.footerItems = []

      if (totalPages > 1) {
        for (let i = 0; i < totalPages; i++) {
          if (i === this.activePage) {
            this.footerItems.push({ label: `${i + 1}`, value: i + 1, active: true })
          } else {
            this.footerItems.push({ label: `${i + 1}`, value: i + 1, active: false })
          }
        }
      } else {
        this.footerItems.push({ label: '1', value: 1, active: true })
      }
    },
    reportTableSize() {
      const table = document.querySelector<HTMLElement>('.menu-bar')
      if (!table) return
      this.tableWidth = table.offsetWidth
      this.setColumnWidth()
    },
    setColumnWidth() {
      this.columnWidth = (this.tableWidth - 160) / this.rowItemsAmount
    },
    async quickMenuClick(relationsId, id, name, value) {
      if (value === 'reward' && !this.hasSubscription) {
        this.$emit('setCardDialogClick')
      } else {
        this.$emit('dialogClick')
        this.activeQuickMenuItem = value
        this.relationsIdQuickMenuItem = relationsId
        this.connecteeIdQuickMenuItem = id
        this.partnerName = name
        this.quickMenuDialog = true
      }
    },
    dialogClick() {
      if (this.quickMenuDialog) {
        this.quickMenuDialog = false
        this.activeQuickMenuItem = ''
      } else {
        this.quickMenuDialog = true
      }
    },
    async search(value) {
      this.searchString = value
      this.skip = 0
      setTimeout(async () => {
        if (value.length >= 3) {
          this.tableLoading = true
          await this.getReferredUsers()
          this.tableLoading = false
        }
        if (value.length < 1) {
          this.tableLoading = true
          await this.getReferredUsers()
          this.tableLoading = false
        }
      }, 500)
    },
    async sort(value, sortDirection, index) {
      this.tableLoading = true
      this.resetMenuItems()
      this.menuItems[index].active = !sortDirection
      this.menuItems[index].sortedBy = true
      if (sortDirection === 'asc') {
        this.menuItems[index].sortDirection = 'desc'
        this.sortDirection = 'desc'
      } else {
        this.menuItems[index].sortDirection = 'asc'
        this.sortDirection = 'asc'
      }
      this.sortBy = value
      if (this.activePage > 0) {
        this.activePage = 0
        this.skip = 0
        this.setFooterItems()
      }
      await this.getReferredUsers()
      this.tableLoading = false
    },
    async footerClick(value) {
      this.tableLoading = true
      this.activePage = value
      this.skip = this.activePage * 10
      this.setFooterItems()
      await this.getReferredUsers()
      this.tableLoading = false
    },
    async arrowClick(value) {
      const maxPages = this.referredUserCount / 10
      if (value === 'next' && this.activePage + 1 < maxPages) {
        this.tableLoading = true
        this.activePage++
        this.skip = this.activePage * 10
        this.setFooterItems()
        await this.getReferredUsers()
        this.tableLoading = false
      }

      if (value === 'back' && this.activePage > 0) {
        this.tableLoading = true
        this.activePage--
        this.skip = this.activePage * 10
        this.setFooterItems()
        await this.getReferredUsers()
        this.tableLoading = false
      }
    },
    onWindowWidthChange() {
      this.reportTableSize()
    },
    async onGetFreshDataChange() {
      // if (this.getFreshData) {
      this.loading = true
      await this.getReferredUsers()
      this.setMenuItems()
      this.setFooterItems()
      this.reportTableSize()
      this.loading = false
    },
    onLoadingEnd() {
      if (this.empty) {
        this.$emit('hideButtonClick', true)
      } else {
        this.$emit('hideButtonClick', false)
      }
    },
  },
  props: {
    getFreshData: {
      type: Boolean,
    },
  },
  watch: {
    windowWidth: [
      {
        handler: 'onWindowWidthChange',
      },
    ],
    getFreshData: [
      {
        handler: 'onGetFreshDataChange',
      },
    ],
    loading: [
      {
        handler: 'onLoadingEnd',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.referral-grid-outer {
  margin-top: 24px;
}

.empty {
  margin-top: 48px;
}

.pop-up {
  background: #f9f9f9;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  height: 100%;
}
</style>
