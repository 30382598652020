<script lang="ts">
import type {
  Clearable,
  OffsetType,
  KelpComparison as KelpComparisonType,
  CarbonComparison as CarbonComparisonType,
  PlasticComparison as PlasticComparisonType,
  TreeComparison as TreeComparisonType,
} from '@/helpers/interfaces'
import { OFFSET_TYPES } from '@/helpers/interfaces'
import type { ValueSelectorValue } from '@/components/your-impact/ValueSelector.vue'
import ValueSelector from '@/components/your-impact/ValueSelector.vue'
import type { TranslateResult } from 'vue-i18n'
import TreesComparison from '@/components/your-impact/TreesComparison.vue'
import CarbonComparison from '@/components/your-impact/CarbonComparison.vue'
import { getComparisons } from '@/helpers/pricing'
import { IMPACT_TYPE_TOP_STAT_CARD_COLOR_MAP } from '@/helpers/projects'
import PlasticComparison from '@/components/your-impact/PlasticComparison.vue'
import KelpComparison from '@/components/your-impact/KelpComparison.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImpactComparison',
  components: {
    ValueSelector,
    TreesComparison,
    CarbonComparison,
    PlasticComparison,
    KelpComparison,
  },
  data() {
    return {
      selectedImpactType: null,
    } as {
      selectedImpactType: Clearable<OffsetType>
    }
  },
  computed: {
    valuesForValueSelector(): ValueSelectorValue[] {
      return OFFSET_TYPES.map((type) => ({
        value: type,
        label: this.t(`${type}.value_selector_label`),
      }))
    },
    title(): TranslateResult {
      return this.t(`${this.selectedImpactType}.title`)
    },
    selectedComparisonComponent(): Clearable<string> {
      return this.selectedImpactType
        ? `${
            this.selectedImpactType.slice(0, 1).toUpperCase() + this.selectedImpactType.slice(1)
          }Comparison`
        : null
    },
    colorSettings(): Record<OffsetType, string> {
      return IMPACT_TYPE_TOP_STAT_CARD_COLOR_MAP
    },
    comparisonsBySelectedType():
      | CarbonComparisonType
      | PlasticComparisonType
      | TreeComparisonType
      | KelpComparisonType
      | null {
      if (!this.selectedImpactType) return null
      const impact =
        this.getTotalImpactsByType.find(({ type }) => type === this.selectedImpactType)?.amount || 0
      return getComparisons(impact, this.selectedImpactType, this.userLocale)
    },
    getTotalImpactsByType(): { type: OffsetType; amount: number }[] {
      return this.$store.getters['getTotalImpactsByType']
    },
    userLocale(): string {
      return this.$store.getters['getUserLocale']
    },
  },
  created() {
    this.selectedImpactType = OFFSET_TYPES[0]
  },
  methods: {
    t(key: string) {
      return this.$t(`ImpactComparison.${key}`)
    },
    onValueSelect(value: OffsetType) {
      this.selectedImpactType = value
    },
  },
})
</script>

<template>
  <section class="impact-comparison-v2-container section section--lg">
    <v-container>
      <div>
        <h2 class="section-title">
          {{ title }}
        </h2>
      </div>
      <ValueSelector
        :values="valuesForValueSelector"
        :selected-value="selectedImpactType ?? ''"
        @select="onValueSelect"
      />
      <component
        :is="selectedComparisonComponent"
        v-if="selectedComparisonComponent && comparisonsBySelectedType"
        :color-settings="colorSettings"
        :comparisons="comparisonsBySelectedType"
      />
    </v-container>
  </section>
</template>

<style scoped lang="scss">
@import '~vuetify/settings';

.impact-comparison-v2-container {
  width: 100%;
  background: var(--ui-white);
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 4px 4px 0px #0000001a;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media #{map-get($display-breakpoints, 'xs')} {
  .impact-comparison-v2-container {
    padding: 0px;
  }
}
</style>
