import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-896acdfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "no-results"
}
const _hoisted_2 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_TableHeader = _resolveComponent("TableHeader")!
  const _component_LoadingTable = _resolveComponent("LoadingTable")!
  const _component_Row = _resolveComponent("Row")!
  const _component_TableFooter = _resolveComponent("TableFooter")!

  return (_openBlock(), _createElementBlock("div", {
    class: "table-wrapper",
    style: _normalizeStyle(_ctx.tableMaxWidth ? `max-width: ${_ctx.tableMaxWidth}px` : '')
  }, [
    (!_ctx.hideSearch)
      ? (_openBlock(), _createBlock(_component_v_text_field, {
          key: 0,
          modelValue: _ctx.search,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
          "append-inner-icon": "mdi-magnify",
          label: "Search",
          "single-line": "",
          "hide-details": "",
          color: "#3B755F",
          class: "search"
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_TableHeader, {
      "menu-items": _ctx.menuItems,
      "column-width": _ctx.columnWidth,
      scroll: _ctx.scroll,
      "hide-partner-image": _ctx.hidePartnerImage,
      onEmitScroll: _ctx.setScroll,
      onEmitSort: _ctx.sort
    }, null, 8, ["menu-items", "column-width", "scroll", "hide-partner-image", "onEmitScroll", "onEmitSort"]),
    (_ctx.noResults && !_ctx.tableLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "No search results"))
      : _createCommentVNode("", true),
    (_ctx.tableLoading)
      ? (_openBlock(), _createBlock(_component_LoadingTable, { key: 2 }))
      : _createCommentVNode("", true),
    (!_ctx.tableLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, a) => {
            return (_openBlock(), _createBlock(_component_Row, {
              key: a,
              "user-id": row.id,
              "relations-id": row.relationsId,
              image: row.image,
              labels: row.labels,
              name: row.name,
              status: row.status,
              numbers: a,
              "column-width": _ctx.columnWidth,
              scroll: _ctx.scroll,
              "total-rows": _ctx.rows?.length,
              "quick-menu-items": row.quickMenuItems,
              "hide-partner-image": _ctx.hidePartnerImage,
              onEmitScroll: _ctx.setScroll,
              onQuickMenuClick: _ctx.quickMenuClick
            }, null, 8, ["user-id", "relations-id", "image", "labels", "name", "status", "numbers", "column-width", "scroll", "total-rows", "quick-menu-items", "hide-partner-image", "onEmitScroll", "onQuickMenuClick"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_TableFooter, {
      "footer-items": _ctx.footerItems,
      "active-page": _ctx.activePage,
      onFooterClick: _ctx.footerClick,
      onArrowClick: _ctx.arrowClick
    }, null, 8, ["footer-items", "active-page", "onFooterClick", "onArrowClick"])
  ], 4))
}