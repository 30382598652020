<template>
  <div class="table-wrapper" :style="tableMaxWidth ? `max-width: ${tableMaxWidth}px` : ''">
    <!-- SEARCH -->
    <v-text-field
      v-if="!hideSearch"
      v-model="search"
      append-inner-icon="mdi-magnify"
      label="Search"
      single-line
      hide-details
      color="#3B755F"
      class="search"
    />

    <TableHeader
      :menu-items="menuItems"
      :column-width="columnWidth"
      :scroll="scroll"
      :hide-partner-image="hidePartnerImage"
      @emitScroll="setScroll"
      @emitSort="sort"
    />

    <div v-if="noResults && !tableLoading" class="no-results">No search results</div>

    <!-- Loading spinner -->
    <LoadingTable v-if="tableLoading" />

    <div v-if="!tableLoading">
      <Row
        v-for="(row, a) in rows"
        :key="a"
        :user-id="row.id"
        :relations-id="row.relationsId"
        :image="row.image"
        :labels="row.labels"
        :name="row.name"
        :status="row.status"
        :numbers="a"
        :column-width="columnWidth"
        :scroll="scroll"
        :total-rows="rows?.length"
        :quick-menu-items="row.quickMenuItems"
        :hide-partner-image="hidePartnerImage"
        @emitScroll="setScroll"
        @quickMenuClick="quickMenuClick"
      />
    </div>

    <TableFooter
      :footer-items="footerItems"
      :active-page="activePage"
      @footerClick="footerClick"
      @arrowClick="arrowClick"
    />
  </div>
</template>

<script lang="ts">
import TableHeader from '@/components/tools/Table/TableHeader.vue'
import Row from '@/components/tools/Table/Row.vue'
import TableFooter from '@/components/tools/Table/TableFooter.vue'
import LoadingTable from '@/components/tools/Table/LoadingTable.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { MenuItem } from '@/helpers/interfaces'

export interface RowLabel {
  customWidth?: number
  text?: string
  type: string
  labels: { type: string; text: string }[]
}

export interface RowItem {
  name: string
  id?: string
  labels: RowLabel[]
  relationsId?: string
  image?: string
  firstName?: string
  lastName?: string
  status: { customWidth?: number; text: string }
  quickMenuItems: MenuItem[]
}

export interface TableItem {
  sortable: boolean
  customWidth?: number
  sortDirection: 'asc' | 'desc'
  value: string
  label: string
  sortedBy: boolean
  active?: boolean
}

export default defineComponent({
  name: 'MainTable',
  emits: ['quickMenuClick', 'emitSort', 'footerClick', 'arrowClick', 'emitSearch'],
  components: {
    TableHeader,
    Row,
    TableFooter,
    LoadingTable,
  },
  data() {
    return {
      search: '',
      scroll: 0,
    }
  },
  methods: {
    t(key: string) {
      return this.$t(`MainTable.${key}`)
    },
    setScroll(value) {
      this.scroll = value
    },
    quickMenuClick(relationsId, id, name, value) {
      this.$emit('quickMenuClick', relationsId, id, name, value)
    },
    sort(value, active, index) {
      this.$emit('emitSort', value, active, index)
    },
    footerClick(value) {
      this.$emit('footerClick', value)
    },
    arrowClick(value) {
      this.$emit('arrowClick', value)
    },
    onSearchChange() {
      this.$emit('emitSearch', this.search)
    },
  },
  props: {
    menuItems: {
      type: Array as PropType<TableItem[]>,
    },
    rows: {
      type: Array as PropType<RowItem[]>,
    },
    columnWidth: {
      type: Number,
    },
    footerItems: {
      type: Array as PropType<MenuItem[]>,
      default: () => [],
    },
    hidePartnerImage: {
      type: Boolean,
    },
    hideSearch: {
      type: Boolean,
    },
    tableMaxWidth: {
      type: Number,
    },
    noResults: {
      type: Boolean,
    },
    tableLoading: {
      type: Boolean,
    },
    activePage: {
      type: Number,
    },
  },
  watch: {
    search: [
      {
        handler: 'onSearchChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.search {
  margin-bottom: 5px;
}

.table-content {
  overflow: scroll;
}

.no-results {
  padding-top: 12px;
  padding-bottom: 10px;
  padding-right: 24px;
  padding-left: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
  color: #1a252e;
  overflow: scroll;
  min-height: 70px;
}
</style>
