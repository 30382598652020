import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7929e2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-wrapper" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_gs_input = _resolveComponent("gs-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "input-button",
      disabled: !_ctx.localValue || _ctx.localValue < 0,
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.decrement && _ctx.decrement(...args)), ["prevent"]))
    }, [
      _createVNode(_component_v_icon, null, {
        default: _withCtx(() => [
          _createTextVNode("mdi-minus")
        ]),
        _: 1
      })
    ], 8, _hoisted_2),
    _createVNode(_component_gs_input, {
      modelValue: _ctx.localValue,
      "onUpdate:modelValue": [
        _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue) = $event)),
        _ctx.update
      ],
      modelModifiers: { number: true },
      type: "number",
      label: "Amount",
      min: "0",
      rules: _ctx.localValue ? [_ctx.rules.positiveInteger, _ctx.rules.maxValue(_ctx.maxValue)] : [],
      clearable: ""
    }, null, 8, ["modelValue", "rules", "onUpdate:modelValue"]),
    _createElementVNode("button", {
      disabled: _ctx.localValue >= _ctx.maxValue,
      class: "input-button",
      onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.increment && _ctx.increment(...args)), ["prevent"]))
    }, [
      _createVNode(_component_v_icon, null, {
        default: _withCtx(() => [
          _createTextVNode("mdi-plus")
        ]),
        _: 1
      })
    ], 8, _hoisted_3)
  ]))
}