import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35ab07ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "progress-bar-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['loading-wrapper', { 'no-shadow': _ctx.backgroundColor }]),
    style: _normalizeStyle({ backgroundColor: _ctx.backgroundColor })
  }, [
    _createElementVNode("img", {
      class: "loading-logo",
      alt: "loading",
      src: require('@/assets/icons/main-shape.svg')
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_progress_linear, {
        color: "#3B755F",
        indeterminate: "",
        rounded: "",
        height: "6"
      })
    ])
  ], 6))
}