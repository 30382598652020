<template>
  <div class="frequently-asked-questions">
    <h2 class="faq-title">
      {{ t('title') }}
    </h2>
    <v-expansion-panels multiple flat>
      <v-expansion-panel
        v-for="(item, key) in faqList"
        :key="key"
        class="v-expansion-panel-headers"
      >
        <v-expansion-panel-title class="widget-header">
          <img
            :src="require('@/assets/icons/main-shape.svg')"
            alt="shape"
            height="20"
            width="20"
            class="header-logo"
          />
          <div>{{ item.question }}</div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="accordion-content" v-html="item.answer" />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <p class="demo">
      {{ t('questions') }}
      <button class="link" @click="openChat()">
        {{ t('get_in_touch') }}
      </button>
    </p>
  </div>
</template>

<script lang="ts">
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { defineComponent } from 'vue'
import messages from '@/i18n/langs'

export default defineComponent({
  name: 'FrequentlyAskedQuestions',
  mixins: [LoadCrispMixin],
  computed: {
    faqList() {
      const accountType = this.isBusiness ? 'business' : 'personal'
      return messages[this.$i18n.locale].FrequentlyAskedQuestions[accountType]
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`FrequentlyAskedQuestions.${key}`)
    },
  },
  props: {
    isBusiness: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.faq-title {
  text-align: center;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 30px;
}

.v-expansion-panel-headers {
  margin: 8px 0;
  background: var(--ui-white);
  border-radius: 4px;
  box-shadow: var(--box-shadow-light);
}

.accordion-header-wrapper {
  display: flex;
}

.widget-header {
  border-radius: var(--border-radius-big);
  color: var(--font-color-primary);
  font-size: 20px;
  font-weight: 600;
  background: var(--ui-white);
  padding: 28px 24px;
}

.header-logo {
  flex: 0 1 auto;
  margin-right: 5px;
}

.accordion-content {
  padding-top: 20px;
  margin-bottom: 4px;
}

.accordion-content:deep(p:only-child) {
  margin-bottom: 0;
}

.accordion-content:deep(p:not(:only-child):first-of-type) {
  margin-bottom: 20px;
}

.accordion-content:deep(p:not(first-of-type)) {
  margin-bottom: 0;
}

.accordion-content:deep(a) {
  color: var(--ui-green);
  text-decoration: none;
}

.link {
  color: var(--ui-green);
  text-decoration: underline;
  cursor: pointer;
}

.demo {
  text-align: center;
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .demo {
    text-align: left;
  }
}
</style>
