<template>
  <div class="alert-wrapper">
    <v-alert v-if="alert" prominent :type="!error ? 'success' : 'error'">
      <v-row align="center">
        <v-col class="flex-grow-1">
          {{ text }}
          <p v-if="!!details" class="details">{{ details }}</p>
        </v-col>
        <v-col v-if="showButtons" class="flex-shrink-1 flex-grow-0">
          <v-btn
            class="button"
            width="100%"
            style="margin-bottom: 8px"
            @click="$emit('closeAlertClick')"
          >
            <span class="button-text">
              {{ buttonText || t('again') }}
            </span>
          </v-btn>
          <v-btn class="button" width="100%" @click="openChatAndTurnOffAlert()">
            {{ t('support') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script lang="ts">
import type { TranslateResult } from 'vue-i18n'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Alert',
  emits: ['closeAlertClick'],
  mixins: [LoadCrispMixin],
  methods: {
    t(key: string) {
      return this.$t(`Alert.${key}`)
    },
    openChatAndTurnOffAlert() {
      this.$emit('closeAlertClick')
      this.openChat()
    },
  },
  props: {
    text: { type: String as () => TranslateResult, required: true },
    details: { type: String as () => TranslateResult, required: false },
    error: { type: Boolean, required: true },
    alert: { type: Boolean, required: true },
    showButtons: { type: Boolean, required: true, default: true },
    buttonText: { type: String, required: false },
  },
})
</script>

<style lang="scss" scoped>
.alert-wrapper {
  position: fixed;
  bottom: 0;
  z-index: 10000;
  left: 285px;
  right: 30px;

  .button {
    color: var(--ui-green);
    background-color: var(--ui-white);
  }

  .details {
    margin: 8px 0px 0px;
    font-size: 12px;
    font-weight: 200;
    line-height: 14px;
  }
}

@media screen and (max-width: 860px) {
  .alert-wrapper {
    left: 10px;
    right: 10px;
  }
}
</style>
