<template>
  <section class="add-integration-automation">
    <button class="close-button" @click="navigateBack" />
    <div
      v-if="isSimplifiedCustomIntegration && hasCustomIntegration"
      class="full-width-panel-wrapper"
    >
      <full-width-panel
        v-if="isSimplifiedCustomIntegration"
        :description="getSimplifiedCustomerIntegrationDescription"
        :title="getSimplifiedCustomerIntegrationTitle"
      >
        <edit-simplified-custom-integration
          :custom-integration-type="selectedIntegration.platform"
          @back="navigateBack"
        />
      </full-width-panel>
    </div>
    <stepper v-else v-model="activeStep" :max-value="getActiveStepCount" :show-logo="false">
      <stepper-content :is-visible="activeStep === 1">
        <full-width-panel :title="stepOneTitle" :description="stepOneDescription">
          <select-existing-integration
            v-if="isStepOneIsExistingIntegration"
            @integration-selected="integrationSelected"
          />
          <select-integration v-else @integration-selected="integrationSelected" />
        </full-width-panel>
      </stepper-content>
      <stepper-content :is-visible="activeStep === 2">
        <full-width-panel :title="stepTwoTitle" :description="stepTwoDescription">
          <custom-integration
            v-if="isCustomIntegrationSelected"
            :custom-integration-settings="selectedCustomIntegrationSettings"
            :type="getCustomIntegrationType(selectedIntegration.platform)"
            :image-url="selectedCustomIntegrationSettings.imageUrl"
            @back="stepBack"
            @next="setCustomIntegrationSettings"
          />
          <select-trigger
            v-else
            :selected-integration="selectedIntegration"
            @back="activeStep = 1"
            v-model:name="selectedTrigger.automationName"
            v-model:active-trigger="selectedTrigger.trigger"
            v-model:shopify-order-origin="selectedTrigger.shopifyOrderOrigin"
            v-model:form-impact-source="selectedTrigger.formImpactSource"
            @submit="submitTriggerSelection"
          />
        </full-width-panel>
      </stepper-content>
      <stepper-content :is-visible="activeStep === 3">
        <full-width-panel :title="stepThreeTitle" :description="stepThreeDescription">
          <CreateApiKey
            v-if="isCustomIntegrationSelected && !apiKey"
            :custom-integration-setting="selectedCustomIntegrationSettings"
            :custom-integration-type="getCustomIntegrationType(selectedIntegration.platform)"
            @back="stepBack"
            @keyGenerated="handleKeyGeneration"
            v-model:api-key-name="apiKeyName"
          />
          <CopyApiKey
            v-else-if="isCustomIntegrationSelected && apiKey"
            :custom-integration-type="getCustomIntegrationType(selectedIntegration.platform)"
            :api-key="apiKey"
            :api-key-name="apiKeyName"
          />
          <select-projects
            v-else
            :selected-trigger="selectedTrigger.trigger"
            :selected-integration="selectedIntegration"
            :automation-name="selectedTrigger.automationName"
            :shopify-order-origin="selectedTrigger.shopifyOrderOrigin"
            :form-impact-source="selectedTrigger.formImpactSource"
            @back="stepBack"
            @revert-trigger-selection="revertTriggerSelection"
          />
        </full-width-panel>
      </stepper-content>
    </stepper>
  </section>
</template>

<script lang="ts">
import StepperContent from '@/components/onboarding/StepperContent.vue'
import Stepper from '@/components/onboarding/Stepper.vue'
import type { SelectedIntegration } from '@/components/onboarding/SelectIntegration.vue'
import SelectIntegration from '@/components/onboarding/SelectIntegration.vue'
import FullWidthPanel from '@/layouts/FullWidthPanel.vue'
import type { CustomIntegrationSetting } from '@/components/onboarding/CustomIntegration.vue'
import CustomIntegration from '@/components/onboarding/CustomIntegration.vue'
import SelectExistingIntegration from '@/components/integration/SelectExistingIntegration.vue'
import type { Integration, IntegrationOption, IntegrationTableData } from '@/store/integrations'
import type {
  AutomationTrigger,
  CustomIntegrationType,
  IntegrationPlatform,
  ShopifyOrderOrigin,
} from '@/helpers/interfaces'
import {
  CUSTOM_INTEGRATION_TYPES,
  SIMPLIFIED_CUSTOM_INTEGRATION_SOURCES,
} from '@/helpers/interfaces'
import SelectTrigger from '@/components/onboarding/SelectTrigger.vue'
import SelectProjects from '@/components/onboarding/SelectProjects.vue'
import CreateApiKey from '@/components/onboarding/CreateApiKey.vue'
import CopyApiKey from '@/components/onboarding/CopyApiKey.vue'
import type { CustomIntegrationResponse } from '@api/index'
import { getApiKeys } from '@api/index'
import { includes } from '@/helpers/parsers'
import type { Notification } from '@/store/notification'
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'
import type { NavigationFailure } from 'vue-router'
import { IntegrationsMixin } from '@/helpers/mixins/integrationsMixin'
import EditSimplifiedCustomIntegration from '@/components/integration/EditSimplifiedCustomIntegration.vue'

export interface SelectedTrigger {
  trigger?: AutomationTrigger
  automationName: string
  shopifyOrderOrigin?: ShopifyOrderOrigin
  formImpactSource: string | 'all'
}

export default defineComponent({
  name: 'AddIntegrationAutomationView',
  components: {
    CopyApiKey,
    CreateApiKey,
    CustomIntegration,
    SelectProjects,
    SelectTrigger,
    FullWidthPanel,
    SelectIntegration,
    Stepper,
    StepperContent,
    SelectExistingIntegration,
    EditSimplifiedCustomIntegration,
  },
  mixins: [IntegrationsMixin],
  data() {
    const initialCustomIntegrationSettings: CustomIntegrationSetting = {
      triggerDetails: {
        triggerName: '',
        triggerType: '',
        triggerId: '',
      },
      sourceDetails: {
        sourceName: '',
        sourceId: '',
      },
      imageUrl: '',
    }
    return {
      activeStep: 1,
      apiKey: '',
      apiKeyName: '',
      customIntegrationSettings: CUSTOM_INTEGRATION_TYPES.reduce((acc, type) => {
        acc[type] = { ...initialCustomIntegrationSettings }
        return acc
      }, {} as Record<CustomIntegrationType, CustomIntegrationSetting>),
      initialCustomIntegrationSettings,
      selectedTrigger: {
        trigger: undefined,
        automationName: '',
        shopifyOrderOrigin: undefined,
        formImpactSource: 'all',
      },
      selectedIntegration: {},
    } as {
      activeStep: number
      apiKey: string
      apiKeyName: string
      customIntegrationSettings: Record<CustomIntegrationType, CustomIntegrationSetting>
      initialCustomIntegrationSettings: CustomIntegrationSetting
      selectedTrigger: SelectedTrigger
      selectedIntegration: SelectedIntegration | IntegrationTableData
    }
  },
  computed: {
    isStepOneIsExistingIntegration(): boolean {
      return this.getIsActiveIntegration && this.$route.query.addNew !== 'true'
    },
    stepOneTitle(): TranslateResult {
      return this.getIsActiveIntegration
        ? this.t('select_integration_title')
        : this.t('add_integration_title')
    },
    stepOneDescription(): TranslateResult {
      return this.getIsActiveIntegration
        ? this.t('select_integration_description')
        : this.t('add_integration_description')
    },
    isCustomIntegrationSelected(): boolean {
      return CUSTOM_INTEGRATION_TYPES.some(
        (customIntegrationType) => customIntegrationType === this.selectedIntegration.platform,
      )
    },
    isSimplifiedCustomIntegration(): boolean {
      return SIMPLIFIED_CUSTOM_INTEGRATION_SOURCES.some(
        (customIntegrationType) => customIntegrationType === this.selectedIntegration.platform,
      )
    },
    getActiveStepCount(): number {
      return SIMPLIFIED_CUSTOM_INTEGRATION_SOURCES.some(
        (customIntegrationType) => customIntegrationType === this.selectedIntegration.platform,
      )
        ? 2
        : 3
    },
    hasCustomIntegration(): boolean {
      return (
        this.getCustomIntegrationsByPlatform(
          this.selectedIntegration.platform as CustomIntegrationType,
        ).length > 0
      )
    },
    selectedCustomIntegrationSettings(): CustomIntegrationSetting {
      return this.customIntegrationSettings[
        this.getCustomIntegrationType(this.selectedIntegration.platform)
      ]
    },
    stepTwoTitle(): TranslateResult {
      if (includes(CUSTOM_INTEGRATION_TYPES, this.selectedIntegration.platform as unknown)) {
        return this.t('custom_integration_title', {
          source: this.getIntegrationPlatformName(this.selectedIntegration.platform) || '',
        })
      }
      return this.t('add_automation_title')
    },
    stepTwoDescription(): TranslateResult {
      if (this.selectedIntegration.platform === 'custom') {
        return this.t('api_integration_description')
      }
      if (includes(CUSTOM_INTEGRATION_TYPES, this.selectedIntegration.platform as unknown)) {
        return this.t('custom_integration_description', {
          source: this.getIntegrationPlatformName(this.selectedIntegration.platform) || '',
          documentationUrl: this.getDocumentationUrl(
            this.selectedIntegration.platform as CustomIntegrationType,
          ),
        })
      }
      return this.t('add_automation_description')
    },
    stepThreeTitle(): TranslateResult {
      if (this.isCustomIntegrationSelected && this.apiKey) {
        if (this.selectedIntegration.platform === 'custom') {
          return this.t('create_api_key_title_custom')
        }
        if (includes(CUSTOM_INTEGRATION_TYPES, this.selectedIntegration.platform as unknown)) {
          return this.t('copy_api_key_title', {
            source: this.getIntegrationPlatformName(this.selectedIntegration.platform) || '',
          })
        }
        return this.t('choose_impact_title')
      } else {
        if (this.selectedIntegration.platform === 'custom') {
          return this.t('create_api_key_title_custom')
        }
        if (includes(CUSTOM_INTEGRATION_TYPES, this.selectedIntegration.platform as unknown)) {
          return this.t('create_api_key_title', {
            source: this.getIntegrationPlatformName(this.selectedIntegration.platform) || '',
          })
        }
        return this.t('choose_impact_title')
      }
    },
    stepThreeDescription(): TranslateResult {
      if (this.isCustomIntegrationSelected && this.apiKey) {
        if (this.selectedIntegration.platform === 'custom') {
          return this.t('copy_api_key_description')
        }
        if (includes(CUSTOM_INTEGRATION_TYPES, this.selectedIntegration.platform as unknown)) {
          return this.t('copy_custom_integration_key_description', {
            source: this.getIntegrationPlatformName(this.selectedIntegration.platform) || '',
            documentationUrl: this.getDocumentationUrl(
              this.selectedIntegration.platform as CustomIntegrationType,
            ),
          })
        }
        return this.t('choose_impact_description')
      } else {
        if (this.selectedIntegration.platform === 'custom') {
          return this.t('create_api_key_description')
        }
        if (includes(CUSTOM_INTEGRATION_TYPES, this.selectedIntegration.platform as unknown)) {
          return this.t('create_custom_integration_key_description', {
            source: this.getIntegrationPlatformName(this.selectedIntegration.platform) || '',
            documentationUrl: this.getDocumentationUrl(
              this.selectedIntegration.platform as CustomIntegrationType,
            ),
          })
        }
        return this.t('choose_impact_description')
      }
    },
    getIsActiveIntegration(): boolean {
      return this.$store.getters['getIsActiveIntegration']
    },
    getIsActiveCustomIntegration(): boolean {
      return this.$store.getters['getIsActiveCustomIntegration']
    },
    getIntegrationsByPlatform(): (platform: IntegrationPlatform) => Integration[] {
      return this.$store.getters['getIntegrationsByPlatform']
    },
    getIntegrationOptionByPlatform(): (platform: IntegrationPlatform) => IntegrationOption {
      return this.$store.getters['getIntegrationOptionByPlatform']
    },
    getCustomIntegrationsByPlatform(): (
      platform: CustomIntegrationType,
    ) => CustomIntegrationResponse[] {
      return this.$store.getters['getCustomIntegrationsByPlatform']
    },
    getCustomIntegrationBySourceId(): (sourceId: string) => CustomIntegrationResponse | undefined {
      return this.$store.getters['getCustomIntegrationBySourceId']
    },
    getIntegrationById(): (id: string) => Integration {
      return this.$store.getters['getIntegrationById']
    },
    integrationList(): (id: string) => Integration[] {
      return this.$store.getters['getIntegrationList']
    },
    integrationOptionList(): (id: string) => IntegrationOption[] {
      return this.$store.getters['getIntegrationOptionList']
    },
    getSimplifiedCustomerIntegrationTitle(): string {
      return this.t('edit_integration_title', {
        source:
          this.getIntegrationPlatformName(
            this.selectedIntegration.platform as IntegrationPlatform,
          ) || '',
      })
    },
    getSimplifiedCustomerIntegrationDescription(): string {
      return this.t('edit_integration_description', {
        source:
          this.getIntegrationPlatformName(
            this.selectedIntegration.platform as IntegrationPlatform,
          ) || '',
      })
    },
  },
  async created() {
    // if user comes from the subscription restart flow, set the subscription items
    if (this.$route.query.subscriptionRestart) {
      await this.setSubscriptionItems(false)
    }

    if (!this.integrationList.length) {
      await this.setIntegrations()
    }

    if (!this.integrationOptionList.length) {
      await this.fetchIntegrationOptions()
    }

    // if there is a platform query param, select the integration with the first integration by that platform
    if (this.$route.query.platform) {
      const integration = this.getIntegrationsByPlatform(
        this.$route.query.platform as IntegrationPlatform,
      )[0]
      const selectedIntegrationOption = this.getIntegrationOptionByPlatform(
        this.$route.query.platform as IntegrationPlatform,
      )
      const selectedIntegration = {
        ...integration,
        category: selectedIntegrationOption?.category,
        activeNumber: selectedIntegrationOption?.activeNumber,
        icon: selectedIntegrationOption.icon,
        provider: selectedIntegrationOption.provider,
        triggers: selectedIntegrationOption.triggers,
        platform: this.$route.query.platform,
      }
      this.integrationSelected(selectedIntegration)
    }
    // coming from the manage integrations page
    // if there is an integrationId query param, select the integration with that id
    if (this.$route.query.integrationId) {
      const integration = this.getIntegrationById(this.$route.query.integrationId as string)
      const customIntegration = this.getCustomIntegrationBySourceId(
        this.$route.query.integrationId as string,
      )
      const selectedIntegrationOption = this.getIntegrationOptionByPlatform(
        integration.platform || customIntegration?.type,
      )
      const selectedIntegration = {
        ...integration,
        category: selectedIntegrationOption?.category,
        activeNumber: selectedIntegrationOption?.activeNumber,
        icon: selectedIntegrationOption.icon,
        provider: selectedIntegrationOption.provider,
        triggers: selectedIntegrationOption.triggers,
        platform: selectedIntegrationOption.slug,
      }
      this.integrationSelected(selectedIntegration)
    }
  },
  methods: {
    t(key: string, params?: { [k: string]: string }) {
      return this.$t(`AddIntegrationAutomationView.${key}`, params || {})
    },
    integrationSelected(integration): void {
      this.selectedIntegration = integration
      if (includes(CUSTOM_INTEGRATION_TYPES, integration.platform)) {
        this.initialCustomIntegrationSettings.sourceDetails.sourceName = integration.name
        this.initialCustomIntegrationSettings.sourceDetails.sourceId = integration.id
        this.initialCustomIntegrationSettings.imageUrl = integration.icon
      }
      if (this.isSimplifiedCustomIntegration) {
        this.activeStep = 3
      } else {
        this.activeStep = 2
      }
    },
    submitTriggerSelection(): void {
      this.activeStep = 3
    },
    revertTriggerSelection(preselectedTrigger: AutomationTrigger): void {
      // TODO: form pre-filled values should be handled with a router
      // but this form does not have specific route for each step.
      // Remove this when we move these form steps to be controled by the router
      if (!preselectedTrigger) return
      this.selectedTrigger.trigger = preselectedTrigger
      this.activeStep = 2
    },
    stepBack(): void {
      this.activeStep--
    },
    navigateBack(): void {
      this.$router.push('/add-impact/manage-integrations-automations/automations')
    },
    async setCustomIntegrationSettings(payload: CustomIntegrationSetting): Promise<void> {
      const { data: apiKeys } = await getApiKeys()
      const isApiKeyAlreadyCreated = apiKeys.some(
        ({ subType }) =>
          subType ===
          this.getApiKeyType(this.getCustomIntegrationType(this.selectedIntegration.platform)),
      )
      if (isApiKeyAlreadyCreated) {
        await this.setCustomIntegrationList()
        this.$store.dispatch('notification/notify', {
          text: this.$t('AddIntegrationAutomationView.source_created'),
        } as Notification)
        await this.$router.push({ path: '/add-impact/manage-integrations-automations/automations' })
      } else if (payload) {
        this.customIntegrationSettings[
          this.getCustomIntegrationType(this.selectedIntegration.platform)
        ] = payload
        this.$nextTick(() => {
          this.activeStep = 3
        })
      } else {
        this.$nextTick(() => {
          this.activeStep = 3
        })
      }
    },

    handleKeyGeneration(apiKey: string): void {
      this.apiKey = apiKey
    },
    setCustomIntegrationList(): Promise<void> {
      return this.$store.dispatch('setCustomIntegrationList')
    },
    setSubscriptionItems(withNavigation: boolean): Promise<void | NavigationFailure> {
      return this.$store.dispatch('setSubscriptionItems', withNavigation)
    },
    setIntegrations(): Promise<void> {
      return this.$store.dispatch('setIntegrations')
    },
    fetchIntegrationOptions(): Promise<void> {
      return this.$store.dispatch('fetchIntegrationOptions')
    },
  },
  watch: {
    // TODO: Changing steps should be controlled by the router,
    // which would make this unnecessary
    activeStep(updated, previous) {
      if (updated !== previous) window.scrollTo(0, 0)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.add-integration-automation {
  height: 100%;
  min-height: 100vh;
  background: url('@/assets/backgrounds/full-width-popup-background.svg') no-repeat top left/cover
    var(--gray-light-F1);
  padding-bottom: 20px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-bottom: 40px;
  }
}

.close-button {
  z-index: 2;
}

.full-width-panel-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 84px;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-top: 124px;
  }
}
</style>
