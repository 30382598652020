<template>
  <div>
    <div class="empty">
      <div class="reath-left" />
      <div class="empty-text">
        {{ text }} <br />
        {{ text1 }}
      </div>
      <div class="reath-right" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ReferralEmpty',
  components: {},
  methods: {
    t(key: string) {
      return this.$t(`ReferralEmpty.${key}`)
    },
  },
  props: {
    text: {
      type: String,
    },
    text1: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.empty-text {
  margin-left: 5px;
  margin-right: 5px;
}

.reath-left {
  background-image: url('../../assets/icons/referrals/reath-left.svg');
  min-height: 79px;
  min-width: 79px;
}

.reath-right {
  background-image: url('../../assets/icons/referrals/reath-right.svg');
  min-height: 79px;
  min-width: 79px;
}
</style>
