<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'MonthEarthPositiveIconIsometric',
})
</script>

<template>
  <h1>MonthEarthPositiveIconIsometric</h1>
</template>

<style scoped lang="scss"></style>
