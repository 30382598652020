<template>
  <div class="input-wrapper">
    <button
      class="input-button"
      :disabled="!localValue || localValue < 0"
      @click.prevent="decrement"
    >
      <v-icon>mdi-minus</v-icon>
    </button>
    <gs-input
      v-model.number="localValue"
      type="number"
      label="Amount"
      min="0"
      :rules="localValue ? [rules.positiveInteger, rules.maxValue(maxValue)] : []"
      clearable
      @update:model-value="update"
    />
    <button :disabled="localValue >= maxValue" class="input-button" @click.prevent="increment">
      <v-icon>mdi-plus</v-icon>
    </button>
  </div>
</template>

<script lang="ts">
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImpactAmountStepper',
  emits: ['update:model-value'],
  mixins: [RulesMixin],
  data() {
    return {
      localValue: 0,
    }
  },
  created() {
    this.localValue = this.modelValue ?? 0
  },
  methods: {
    increment() {
      this.localValue = Number.isSafeInteger(this.localValue) ? this.localValue : 0
      this.localValue++
      this.$nextTick(() => {
        this.$emit('update:model-value', this.localValue)
      })
    },
    decrement() {
      this.localValue--
      this.$nextTick(() => {
        this.$emit('update:model-value', this.localValue)
      })
    },
    update() {
      this.$emit('update:model-value', this.localValue)
    },
    onValueChange() {
      this.localValue = this.modelValue ?? 0
    },
  },
  props: {
    modelValue: {
      type: Number,
    },
    maxValue: {
      default: Number.MAX_SAFE_INTEGER,
      type: Number,
    },
  },
  watch: {
    modelValue: [
      {
        handler: 'onValueChange',
      },
    ],
  },
})
</script>

<style scoped lang="scss">
@import '~vuetify/settings';

.input-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.input-button {
  height: 32px;
  width: 32px;
  background-color: var(--ui-green);
  color: var(--ui-beige);
  font-size: 18px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-button:disabled {
  background-color: var(--gray-light);
  color: var(--font-color-primary);
}

.input-button i {
  color: var(--ui-beige);
}

.input-button:disabled i {
  color: var(--font-color-primary);
}
</style>
