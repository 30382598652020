<template>
  <div class="nav-wrapper">
    <img class="logo" :src="require('@/assets/greenspark-logo.svg')" alt="greenspark-logo" />
    <img
      v-if="$route.path !== '/finish-subscription'"
      style="width: 30px; cursor: pointer"
      class="close-icon"
      :src="require('@/assets/icons/close_icon.svg')"
      alt="close"
      @click="backClick"
    />
    <button v-else class="logout" @click="logOut()">
      {{ $t('CommonUi.logout') }}
    </button>
  </div>
</template>

<script lang="ts">
import { logOut } from '@api/index'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CloseNavigation',
  methods: {
    backClick() {
      if (this.$route.name === 'DirectMarketplaceSignup') {
        this.$parent?.$emit('backClick')
      } else if (this.$route.query.tab) {
        this.$router.push({ path: '/account', query: { tab: this.$route.query.tab } })
      } else if (this.$route.path === '/upgrade-plan') {
        this.$router.back()
      } else if (this.$route.query.restartSubscription) {
        this.$router.push({ path: 'canceled', query: this.$route.query })
      } else {
        this.$router.push('/choose-signup')
      }
    },
    logOut() {
      logOut()
    },
  },
})
</script>

<style lang="scss" scoped>
.nav-wrapper {
  width: 100%;
  height: 60px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}

.logout {
  font-weight: 600;
  color: var(--ui-green);
  font-size: 20px;
}
</style>
