<template>
  <div class="user-component">
    <div class="user-component-wrapper">
      <img class="user-image" :src="require('@/assets/login-image.svg')" alt="signup" />
      <div class="user-wrapper">
        <div class="back" @click="$emit('backClick')">
          <img
            class="chevron"
            alt="chevron"
            :src="require('@/assets/icons/chevron_left.svg')"
            @click="$emit('backClick')"
          />
          <div>
            {{ t('back') }}
          </div>
        </div>
        <div class="form-wrapper">
          <div class="header">
            {{ t('create_account') }}
          </div>

          <div class="name-wrapper">
            <div class="text-field-wrapper">
              <v-text-field
                v-model="userObject.firstName"
                :label="t('first_name')"
                variant="outlined"
                type="text"
                flat
                disabled
                color="#3B755F"
                class="left-input"
                hide-details
              />

              <v-text-field
                v-model="userObject.lastName"
                :label="t('last_name')"
                variant="outlined"
                type="text"
                flat
                disabled
                color="#3B755F"
                class="right-input"
                hide-details
              />
            </div>
          </div>

          <div class="name-wrapper">
            <div class="text-field-wrapper">
              <v-text-field
                id="Email"
                v-model="userObject.email"
                class="input-field"
                name="Email"
                type="text"
                :label="t('email')"
                variant="outlined"
                disabled
                flat
                color="#3B755F"
                hide-details
              />
            </div>
          </div>

          <div class="name-wrapper">
            <div class="text-field-wrapper">
              <v-text-field
                v-model="userObject.companyName"
                :label="t('company_name')"
                variant="outlined"
                type="text"
                flat
                color="#3B755F"
                class="left-input"
                hide-details
              />

              <v-text-field
                v-model="userObject.vat"
                :label="t('vat_id')"
                variant="outlined"
                type="text"
                flat
                color="#3B755F"
                class="right-input"
                hide-details
              />
            </div>
          </div>

          <div class="name-wrapper">
            <div class="text-field-wrapper">
              <v-text-field
                id="Password"
                v-model="userObject.password"
                class="input-field"
                name="password"
                autocomplete="password"
                :type="showPassword ? 'text' : 'password'"
                :label="t('password')"
                :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                variant="outlined"
                flat
                color="#3B755F"
                hide-details
                @click:append-inner="showPassword = !showPassword"
                @keyup.enter="buttonActive && !loading ? signup : ''"
              />
            </div>
          </div>

          <div
            class="password-hint"
            :style="passwordError ? 'background: red; color: #F9F9F9' : ''"
          >
            {{ t('password_hint') }}
          </div>

          <div class="checkbox-wrapper">
            <v-checkbox v-model="checkbox" color="#3B755F" hide-details style="margin: 0" />
            <div class="terms">
              Accept our <span class="link" @click="openUrl('terms')">Terms & Conditions</span> &
              <span class="link" @click="openUrl('privacy')">Privacy Policy</span>
            </div>
          </div>

          <div class="button-wrapper">
            <gs-button
              :disabled="!buttonActive || loading"
              :loading="loading"
              :full-width="true"
              size="large"
              @click.prevent="signup"
            >
              {{ t('continue') }}
            </gs-button>
          </div>
          <div v-if="showError" class="error-message">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
    <!-- Loading -->
    <transition name="fade">
      <Loading v-if="pageLoading" />
    </transition>
  </div>
</template>

<script lang="ts">
import Loading from '@/components/tools/Loading.vue'
import { createAccount, createAccountWidgets, userRegistration } from '@api/index'
import { PRIVACY_POLICY_URL, TERMS_CONDITIONS_URL } from '@/helpers/constants'
import type { Account } from '@/store'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { User } from '@/store/users'
import type { Currency } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

interface UserObject {
  firstName: string
  lastName: string
  vat: string
  companyName: string
  name: string
  email: string
  password: string
}

export default defineComponent({
  name: 'SignupInfoPartners',
  emits: ['backClick'],
  components: { Loading },
  mixins: [Utils],
  data() {
    return {
      userObject: {
        firstName: '',
        lastName: '',
        vat: '',
        companyName: '',
        name: '',
        email: '',
        password: '',
      },
      location: '',
      currency: 'euro',
      showPassword: false,
      loading: false,
      buttonActive: false,
      checkbox: false,
      passwordError: false,
      userAlreadyHasAccount: false,
      showError: false,
      errorMessage: '',
      pageLoading: false,
    } as {
      userObject: UserObject
      location: string
      currency: Currency
      showPassword: boolean
      loading: boolean
      buttonActive: boolean
      checkbox: boolean
      passwordError: boolean
      userAlreadyHasAccount: boolean
      showError: boolean
      errorMessage: string
      pageLoading: boolean
    }
  },
  async created() {
    try {
      this.location = await this.getLocation()
    } catch (error) {
      this.location = ''
    }
    this.currency = this.getCurrencyByLocation(this.location)
    if (this.userObjects) this.userObject = this.userObjects
    await this.getLocation()
  },
  methods: {
    t(key: string) {
      return this.$t(`SignupInfoPartners.${key}`)
    },
    checkPassword() {
      if (this.userObject.password.length >= 6) {
        if (
          /^(?=.{6,}$)(?=.*[a-z])((?=.*\W)).*$/.test(this.userObject.password) === true &&
          this.checkbox &&
          this.userObject.firstName !== '' &&
          this.userObject.lastName !== '' &&
          this.userObject.companyName !== ''
        ) {
          this.buttonActive = true
          this.passwordError = false
        } else if (
          /^(?=.{6,}$)(?=.*[a-z])((?=.*\W)).*$/.test(this.userObject.password) === true ||
          !this.checkbox ||
          this.userObject.firstName === '' ||
          this.userObject.lastName === '' ||
          this.userObject.companyName === ''
        ) {
          this.buttonActive = false
          this.passwordError = false
        } else {
          this.buttonActive = false
          this.passwordError = true
        }
      } else {
        this.buttonActive = false
        this.passwordError = false
      }
    },
    async signup() {
      try {
        this.showError = false
        this.loading = true

        const firebasePayload = {
          email: this.userObject.email,
          password: this.userObject.password,
          name: this.userObject.companyName,
        }

        await userRegistration(firebasePayload)

        if (this.account.emailAlreadyUsed) {
          throw new Error('this.account.emailAlreadyUsed')
        } else {
          await this.createAccount()
        }
      } catch (error) {
        this.loading = false
        this.errorMessage = 'Email already used. Please use a different one or get in touch.'
        this.showError = true
        setTimeout(() => {
          this.setEmailAlreadyUsed(false)
          this.showError = false
        }, 2000)
        console.error('error:', error)
      }
    },
    async createAccount() {
      try {
        this.pageLoading = true
        const livePageId = Date.now() + Math.floor(Math.random() * 1000)
        let widgetFolderName =
          this.userObject.companyName + Math.floor(Math.random() * 100).toString()
        widgetFolderName = widgetFolderName.replace(/\s/g, '-').toLowerCase()

        const userPayload = {
          user: {
            accountType: 'business',
            email: this.userObject.email,
            vatId: this.userObject.vat,
            companyName: this.userObject.companyName,
            firstName: this.userObject.firstName,
            lastName: this.userObject.lastName,
            accountId: this.currentUser.id,
            signupDate: Date.now(),
            termsAccept: this.checkbox,
            currency: this.currency,
            livePageId: livePageId,
            widgetFolderName: widgetFolderName,
            address: {
              country: this.location,
            },
          },
          relationId: this.relationsId,
        }

        await createAccount(userPayload)
        const { data: account } = await createAccountWidgets({ id: this.account.accountId })
        this.setAccount(account)
        await this.setOnboardingSkipped()
        await this.$router.push(`/partner/success/${this.relationsId}`)
      } catch (error) {
        this.loading = false
        this.errorMessage =
          'An error has occured, please try again or get in touch and we would be happy to help.'
        this.showError = true
        this.pageLoading = false
        console.error('error:', error)
      }
    },
    openUrl(value) {
      switch (value) {
        case 'terms':
          window.open(TERMS_CONDITIONS_URL, '_blank')
          break
        case 'privacy':
          window.open(PRIVACY_POLICY_URL, '_blank')
          break
      }
    },
    onUserEmailChange() {
      this.updateCurrentUser({ email: this.userObject.email })
      this.checkPassword()
      this.setEmailAlreadyUsed(false)
    },
    onUserPasswordChange() {
      this.checkPassword()
    },
    onUserFirstNameChange() {
      this.updateCurrentUser({ firstName: this.userObject.firstName })
      this.checkPassword()
    },
    onUserLastNameChange() {
      this.updateCurrentUser({ lastName: this.userObject.lastName })
      this.checkPassword()
    },
    onUserCompanyNameChange() {
      this.setAccount({ companyName: this.userObject.companyName })
      this.checkPassword()
    },
    onUserVatChange() {
      this.setAccount({ vatId: this.userObject.vat })
      this.checkPassword()
    },
    onCheckboxChange() {
      this.checkPassword()
    },
    onEmailAlreadyUsedChange() {
      this.loading = false
      if (this.account.emailAlreadyUsed) {
        setTimeout(() => {
          this.setEmailAlreadyUsed(false)
        }, 2000)
      }
    },
    setOnboardingSkipped(): Promise<void> {
      return this.$store.dispatch('setOnboardingSkipped')
    },
    updateCurrentUser(user: Partial<User>): Promise<void> {
      return this.$store.dispatch('updateCurrentUser', user)
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
    setEmailAlreadyUsed(emailAlreadyUsed) {
      return this.$store.commit('setEmailAlreadyUsed', emailAlreadyUsed)
    },
    setSignupComplete(signupComplete) {
      return this.$store.commit('setSignupComplete', signupComplete)
    },
  },
  props: {
    relationsId: {
      type: String,
    },
    userObjects: {
      type: Object as PropType<UserObject>,
    },
  },
  watch: {
    'userObject.email': [
      {
        handler: 'onUserEmailChange',
      },
    ],
    'userObject.password': [
      {
        handler: 'onUserPasswordChange',
      },
    ],
    'userObject.firstName': [
      {
        handler: 'onUserFirstNameChange',
      },
    ],
    'userObject.lastName': [
      {
        handler: 'onUserLastNameChange',
      },
    ],
    'userObject.companyName': [
      {
        handler: 'onUserCompanyNameChange',
      },
    ],
    'userObject.vat': [
      {
        handler: 'onUserVatChange',
      },
    ],
    checkbox: [
      {
        handler: 'onCheckboxChange',
      },
    ],
    'account.emailAlreadyUsed': [
      {
        handler: 'onEmailAlreadyUsedChange',
      },
    ],
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.user-component {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.user-component-wrapper {
  width: 100%;
  border-radius: 8px;
  display: flex;
  box-shadow: 0 42px 76px rgba(0, 0, 0, 0.05), 0 27.2222px 44.5093px rgba(0, 0, 0, 0.037963),
    0 16.1778px 24.2074px rgba(0, 0, 0, 0.0303704), 0 8.4px 12.35px rgba(0, 0, 0, 0.025),
    0 3.42222px 6.19259px rgba(0, 0, 0, 0.0196296), 0 0.777778px 2.99074px rgba(0, 0, 0, 0.012037);
}

.user-image {
  width: 400px;
  max-width: 45%;
  max-height: 640px;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.user-wrapper {
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

.chevron {
  width: 23px;
  filter: invert(39%) sepia(15%) saturate(1175%) hue-rotate(106deg) brightness(96%) contrast(87%);
}

.back {
  padding-top: 20px;
  padding-left: 28px;
  display: flex;
  font-weight: 500;
  color: #3b755f;
  cursor: pointer;
  width: 100px;
}

.form-wrapper {
  padding: 12px 36px 24px 36px;
}

.header {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: #212121;
  margin-bottom: 24px;
}

.left-input {
  margin-right: 4px;
}

.right-input {
  margin-left: 4px;
}

.name-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.input-label {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
  margin-bottom: 4px;
}

.input-description {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #b0b0b0;
  margin-bottom: 12px;
}

.text-field-wrapper {
  display: flex;
  width: 100%;
}

.error-message {
  font-size: 16px;
  color: #f9f9f9;
  font-weight: 500;
  text-align: left;
  width: 100%;
  padding: 8px 20px;
  background: red;
  margin-top: 16px;
}

.password-hint {
  text-align: left;
  width: 100%;
  padding: 8px 20px;
  background: rgba(176, 176, 176, 0.2);
  border-radius: 4px;
  margin-bottom: 12px;
  margin-top: 5px;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
}

.checkbox-wrapper {
  display: flex;
  margin: 24px 0 25px 0;
  width: 100%;
}

.terms {
  display: flex;
  align-items: center;
  color: #212121;
  padding-top: 3px;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  flex-wrap: wrap;
}

.link {
  margin-left: 3px;
  margin-right: 3px;
  color: #3b755f;
  font-weight: bold;
}

.button-wrapper {
  margin-top: 20px;
}

.login-link {
  margin-left: 3px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
}

@media #{map-get($display-breakpoints, 'md')} {
  .user-image {
    display: none;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .user-wrapper {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding-left: 24px;
    padding-right: 24px;
  }

  .user-image {
    display: none;
  }

  .back {
    padding-left: 0px;
  }

  .user-image {
    display: none;
  }

  .text-field-wrapper {
    justify-content: space-between;
  }

  .form-wrapper {
    padding: 10px;
  }
}
</style>
