<template>
  <div class="demo-alert-bar">
    <p class="demo-alert-bar-text">
      {{ $t('DemoAlertBar.title') }}
      <a href="https://www.getgreenspark.com/pricing/business" target="_blank">
        {{ $t('DemoAlertBar.action') }}
      </a>
    </p>
  </div>
</template>

<style lang="scss" scoped>
@import '~vuetify/settings';

.demo-alert-bar {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  background-color: var(--ui-black);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-left: 226px;
  }
}

.demo-alert-bar-text {
  color: var(--ui-white);
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    font-size: 24px;
  }

  a {
    color: var(--ui-emerald-green);
    text-decoration: underline;
  }
}
</style>
