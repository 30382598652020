import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-038a20bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "banner" }
const _hoisted_2 = { class: "banner-content-wrapper" }
const _hoisted_3 = { class: "banner-logo" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "user-name" }
const _hoisted_6 = { class: "member-since" }
const _hoisted_7 = { class: "description-wrapper" }
const _hoisted_8 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: require('@/assets/test-logo.png'),
          alt: "banner-image"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.t('greenspark')), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('since')), 1),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.t('description')), 1)
      ])
    ])
  ]))
}