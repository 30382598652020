<template>
  <div class="billing-wrapper">
    <div class="subheader">
      {{ t('your_invoices') }}
    </div>
    <!-- Loading spinner -->
    <LoadingTable v-if="loading" />

    <!-- no stripe customer id -->
    <div v-if="!hasPaidSubscriptionItem">
      {{ t('no_invoices') }}
    </div>
    <div v-if="isShopifyUser" class="invoices-wrapper">
      {{ t('shopify') }}
    </div>
    <div
      v-if="invoices.length"
      :class="invoices.length <= 5 ? 'invoices-wrapper' : 'invoices-wrapper-scroll'"
    >
      <Invoice v-for="(invoice, a) in invoices" :key="a" :date="invoice.date" :url="invoice.url" />
    </div>

    <div v-if="isBusiness || getIfB2B2C" class="subheader" style="margin-top: 40px">
      {{ t('manage_plan') }}
    </div>
    <gs-button
      v-if="isBusiness || getIfB2B2C"
      width="230"
      :loading="buttonLoading"
      @click="() => $router.push('/upgrade-plan?tab=billing')"
    >
      {{ t('manage') }}
    </gs-button>
    <p v-if="isTrial" class="trial-disclaimer">
      <v-icon size="20px">mdi-information-outline</v-icon>
      <span class="disclaimer-text">{{ t('trial_disclaimer') }}</span>
    </p>
    <div
      v-if="!isShopifyUser && hasPaidSubscriptionItem"
      class="subheader"
      style="margin-top: 40px"
    >
      {{ t('update_payment') }}
    </div>
    <gs-button
      v-if="!isShopifyUser && hasPaidSubscriptionItem"
      type="secondary"
      width="230"
      :loading="buttonLoading"
      @click="openPortal"
    >
      {{ t('update') }}
    </gs-button>

    <div v-if="hasPaidSubscriptionItem" class="cancel-div">
      <div class="subheader">
        {{ t('cancel_subscriptions') }}
      </div>
      <gs-button type="delete" width="230" @click="onCancelClick">
        {{ t('cancel') }}
      </gs-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Invoice from '@/components/account/Invoice.vue'
import LoadingTable from '@/components/tools/Table/LoadingTable.vue'
import { userInvoices, getStripePortal, type InvoiceItem } from '@api/index'
import type { Account } from '@/store'
import type { DialogUpdatePayload } from '@/store/dialog'
import { Dialogs } from '@/store/dialog'
import { Utils } from '@/helpers/mixins/utilsMixin'

export default defineComponent({
  name: 'Billing',
  components: {
    Invoice,
    LoadingTable,
  },
  mixins: [Utils],
  data() {
    return {
      invoices: [],
      isShopifyUser: false,
      loading: false,
      buttonLoading: false,
      windowWidth: 0,
    } as {
      invoices: InvoiceItem[]
      isShopifyUser: boolean
      loading: boolean
      buttonLoading: boolean
      windowWidth: number
    }
  },
  computed: {
    isBusiness(): boolean {
      return this.account.accountType === 'business' || this.account.accountType === 'ecommerce'
    },
    isTrial(): boolean {
      return this.$store.getters['isActiveSubscriptionOnTrial']
    },
    hasPaidSubscriptionItem(): boolean {
      return this.$store.getters['hasPaidSubscriptionItem']
    },
    hasOwnPaidSubscriptionItem(): boolean {
      return this.$store.getters['hasOwnPaidSubscriptionItem']
    },
    getIfB2B2C(): boolean {
      return this.$store.getters['getIfB2B2C']
    },
  },
  async created() {
    this.loading = true
    window.addEventListener('resize', this.reportWindowSize)
    this.$store.dispatch('setSubscriptionItems', false)

    this.reportWindowSize()
    if (this.hasOwnPaidSubscriptionItem) {
      await this.getInvoices()
    }

    this.loading = false
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize)
  },
  methods: {
    t(key: string) {
      return this.$t(`Billing.${key}`)
    },
    reportWindowSize() {
      this.windowWidth = window.innerWidth
    },
    async getInvoices() {
      try {
        const invoices = await userInvoices()
        this.isShopifyUser = invoices.data === 'SHOPIFY_USER'
        this.invoices = invoices.data === 'SHOPIFY_USER' ? [] : invoices.data
      } catch (error) {
        console.error('error:', error)
      }
    },
    async openPortal() {
      this.buttonLoading = true
      const url = await getStripePortal()
      window.open(url.data, '_self')
    },
    onCancelClick() {
      this.openDialog({
        name: Dialogs.CUSTOM.CANCEL_SUBSCRIPTION,
        options: {
          persistent: false,
        },
      })
    },
    openDialog(dialog: DialogUpdatePayload): Promise<void> {
      return this.$store.dispatch('openDialog', dialog)
    },
    setAccount(account: Account): void {
      return this.$store.commit('setAccount', account)
    },
  },
  props: {
    account: { required: true, type: Object as () => Account },
  },
})
</script>

<style lang="scss" scoped>
.button-upgrade {
  color: var(--ui-white);
}

.subheader {
  color: #212121;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.invoices-wrapper {
  max-height: 325px;
  overflow-y: scroll;
  padding: 12px;
}

.invoices-wrapper-scroll {
  max-height: 325px;
  overflow-y: scroll;
  -webkit-box-shadow: inset 0 0 5px 2px rgb(0 0 0 / 5%);
  box-shadow: inset 0 0 5px 2px rgb(0 0 0 / 5%);
  padding: 12px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.cancel-div {
  margin-top: 55px;
}

.button {
  margin-top: 10px;
}

.button-text {
  color: red;
  font-weight: 500;
  font-size: 14px;
}

.close-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
  cursor: pointer;
}

.widget-overlay {
  width: 70%;
}

.trial-disclaimer {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--ui-blue);
  margin-top: 16px;
  gap: 8px;

  .disclaimer-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }
}

@media screen and (max-width: 585px) {
  .button-wrapper {
    flex-direction: column;
    width: 100%;
  }

  .button {
    width: 100% !important;
    margin-top: 10px;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>
