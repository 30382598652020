<template>
  <div
    :id="`number${elementId}`"
    :class="hidePartnerImage ? 'table-header-outer' + ' ' + 'no-padding' : 'table-header-outer'"
  >
    <div
      v-for="(item, a) in menuItems"
      :key="a"
      :class="!item.sortable ? 'table-header-item' : 'table-header-item pointer'"
      :style="
        item.customWidth
          ? `width: ${item.customWidth}px; min-width: ${item.customWidth}px`
          : `width: ${columnWidth}px; min-width: 115px;`
      "
      @click="item.sortable ? sort(item.value, item.sortDirection, a) : ''"
    >
      <div>{{ item.label }}</div>
      <div v-if="item.sortedBy" class="sort">
        <img
          v-if="item.sortDirection === 'asc'"
          style="width: 10px; margin-bottom: 1px"
          class="top"
          :src="require('@/assets/icons/arrow-up.svg')"
          alt="up"
        />
        <img
          v-if="item.sortDirection === 'desc'"
          style="width: 10px; margin-top: 1px"
          class="bottom"
          :src="require('@/assets/icons/arrow-down.svg')"
          alt="down"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import { type TableItem } from '@/components/tools/Table/MainTable.vue'

export default defineComponent({
  name: 'TableHeader',
  emits: ['emitScroll', 'emitSort'],
  components: {},
  data() {
    return {
      elementId: 0,
    }
  },
  created() {
    this.elementId = Math.floor(Math.random() * 1000000 * 2 + 2)
  },
  mounted() {
    const element = document.querySelector<HTMLDivElement>(`#number${this.elementId}`)
    element?.addEventListener('scroll', this.scrollDetect)
  },
  methods: {
    t(key: string) {
      return this.$t(`Header.${key}`)
    },
    beforeDestroy() {
      const element = document.querySelector<HTMLDivElement>(`#number${this.elementId}`)
      if (element) element.removeEventListener('scroll', this.scrollDetect)
    },
    scrollDetect() {
      const element = document.querySelector<HTMLDivElement>(`#number${this.elementId}`)
      const left = element?.scrollLeft
      this.$emit('emitScroll', left)
    },
    setScroll() {
      const element = document.querySelector<HTMLDivElement>(`#number${this.elementId}`)
      if (element) element.scrollLeft = this.scroll ?? 0
    },
    sort(value, active, index) {
      this.$emit('emitSort', value, active, index)
    },
    onScrollElementChange() {
      this.setScroll()
    },
  },
  props: {
    menuItems: {
      type: Array as PropType<TableItem[]>,
    },
    columnWidth: {
      type: Number as PropType<number>,
    },
    scroll: {
      type: Number as PropType<number>,
    },
    hidePartnerImage: {
      type: Boolean as PropType<boolean>,
    },
  },
  watch: {
    scroll: [
      {
        handler: 'onScrollElementChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
.table-header-outer {
  padding-left: 100px;
  padding-right: 50px;
  padding-top: 18px;
  padding-bottom: 16px;
  background: #f2ebdb;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // justify-content: space-between;
  overflow: scroll;
}

.no-padding {
  padding-left: 18px;
  padding-right: 18px;
}

.table-header-outer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.table-header-outer {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.table-header-item {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
  display: flex;
  flex-direction: row;
  padding: 0px 5px;
}

.pointer {
  cursor: pointer;
}

.sort {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.top:hover {
  opacity: 0.8;
}

.bottom:hover {
  opacity: 0.7;
}
</style>
