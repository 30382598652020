import type { ActionContext } from 'vuex'
import type { StateInterface } from '@/store'
import { Decimal } from 'decimal.js'
import type { ProjectId } from '@/helpers/constants'
import {
  IMPACT_LOWER_PRICE_LIMIT,
  PROJECT_IDS,
  TAILORED_IMPACT_MINIMUM_LIMIT_WITHOUT_SUBSCRIPTION,
} from '@/helpers/constants'
import { PROJECT_ID_IMPACT_TYPE_MAP } from '@/helpers/projects'
import router from '@/router'
import type { PaymentPayload, PaymentPayloadImpact } from '@api/index'
import { payment } from '@api/index'
import type { Notification } from '@/store/notification'
import VueI18n from '@/i18n'
import type { AutomationOffset } from '@/store/integrations'

export interface CartModule {
  cart: Omit<Required<AutomationOffset>, 'source'>[]
  total: number
}

const defaultCart: Omit<Required<AutomationOffset>, 'source'>[] = PROJECT_IDS.map((projectId) => ({
  type: PROJECT_ID_IMPACT_TYPE_MAP[projectId],
  amount: null,
  projectId,
}))

export const cartModule = {
  state: () =>
    ({
      cart: [...defaultCart],
      total: 0,
    } as CartModule),
  mutations: {
    setCartByProjectId(
      state: CartModule,
      { projectId, amount }: { projectId: ProjectId; amount: number },
    ) {
      const cartItem = state.cart.find((item) => item.projectId === projectId)
      const restCartItem = state.cart.filter((item) => item.projectId !== projectId)
      if (!cartItem) throw new Error(`Cart item with projectId ${projectId} not found`)
      state.cart = [...restCartItem, { ...cartItem, amount }]
    },
    setTotal(state: CartModule, total: number) {
      state.total = total
    },
    resetCart(state: CartModule) {
      state.cart = [...defaultCart]
      state.total = 0
    },
  },
  actions: {
    async setCartByProjectId(
      { commit, dispatch }: ActionContext<CartModule, StateInterface>,
      { projectId, amount }: { projectId: ProjectId; amount: number },
    ) {
      commit('setCartByProjectId', { projectId, amount })
      await dispatch('setTotal')
    },
    resetCart({ commit }: ActionContext<CartModule, StateInterface>) {
      commit('resetCart')
    },
    async setTotal({ commit, getters, state }: ActionContext<CartModule, StateInterface>) {
      let total = new Decimal(0)
      state.cart.forEach(({ projectId, amount }) => {
        if (amount) {
          total = total.add(new Decimal(getters.getProjectPriceById(projectId)).times(amount || 0))
        }
      })
      commit('setTotal', total.toNumber())
    },
    async saveCart({
      commit,
      getters,
      state,
      dispatch,
    }: ActionContext<CartModule, StateInterface>) {
      commit('setLoading', true)
      if (getters.hasOwnPaidSubscriptionItem) {
        try {
          const newImpacts: PaymentPayloadImpact[] = []

          // prices from stripe by currency
          state.cart.forEach(({ type, amount, projectId }) => {
            if (amount) {
              const newImpact: PaymentPayloadImpact = { type, amount }
              newImpact.projectId = projectId
              newImpacts.push(newImpact)
            }
          })

          const sentPayload: PaymentPayload = {
            impacts: newImpacts,
          }

          const { data } = await payment(sentPayload)
          commit('setAccount', data)
          commit('resetCart')
          dispatch(
            'notification/notify',
            {
              text: VueI18n.global.t('CommonUi.success_generic_payment'),
              isError: false,
              isClosable: false,
            } as Notification,
            { root: true },
          )
        } catch (e) {
          console.error(e)
          dispatch(
            'notification/notify',
            {
              text: VueI18n.global.t('CommonUi.error_generic'),
              isError: true,
              isClosable: true,
              buttonText: 'close',
            } as Notification,
            { root: true },
          )
        }
      } else {
        const query = getters.getCheckoutUrlQueryParams
        commit('resetCart')
        await router.push({ path: '/checkout', query })
      }
      commit('setLoading', false)
    },
  },
  getters: {
    getIsCartOpen(state: CartModule): boolean {
      return state.cart.some(({ amount }) => !!amount)
    },
    getCheckoutUrlQueryParams(state: CartModule, getters) {
      const params = {
        type: 'onetime',
        total: state.total,
        currency: getters.getUserCurrencyCode.toLowerCase(),
      }
      state.cart
        .filter(({ amount }) => !!amount)
        .forEach(({ projectId, amount }) => {
          params[projectId] = amount
        })
      return params
    },
    getMinimumCheckoutAmount(state: CartModule, getters): number {
      if (
        !getters.hasOwnPaidSubscriptionItem &&
        state.total < TAILORED_IMPACT_MINIMUM_LIMIT_WITHOUT_SUBSCRIPTION
      ) {
        return TAILORED_IMPACT_MINIMUM_LIMIT_WITHOUT_SUBSCRIPTION
      } else if (getters.hasOwnPaidSubscriptionItem && state.total < IMPACT_LOWER_PRICE_LIMIT) {
        return IMPACT_LOWER_PRICE_LIMIT
      } else {
        return 0
      }
    },
    getCurrentCart(state: CartModule): Omit<Required<AutomationOffset>, 'source'>[] {
      return state.cart
    },
    getCartTotal(state: CartModule): number {
      return state.total
    },
  },
}
