<template>
  <div class="add-month-wrapper">
    First add the month & receipt type to then attach the appropriate receipts. Please follow our
    standard naming convention of: <strong>Month, Year (eg: May, 2021)</strong>
    <div class="button-text-wrapper">
      <v-text-field
        v-model="month"
        class="input-field"
        label="Add Month"
        variant="outlined"
        flat
        color="#3B755F"
        style="margin-right: 5px"
      />
      <v-select v-model="type" :items="items" label="Receipt Type" variant="outlined" />
      <gs-button class="button" depressed @click="saveMonth()" :disabled="loading">
        Save
        <v-progress-circular v-if="loading" indeterminate color="#F9F9F9" />
      </gs-button>
    </div>
  </div>
</template>

<script lang="ts">
import type { OffsetType } from '@/helpers/interfaces'
import { OFFSET_TYPES } from '@/helpers/interfaces'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AddMonth',
  emits: ['addMonthToArray'],
  components: {},
  data() {
    return {
      month: '',
      type: undefined,
      items: OFFSET_TYPES,
    } as {
      month: string
      type?: OffsetType
      items: typeof OFFSET_TYPES
    }
  },
  methods: {
    t(key: string) {
      return this.$t(`AddMonth.${key}`)
    },
    saveMonth() {
      this.$emit('addMonthToArray', this.month, this.type)
    },
  },
  props: {
    loading: { required: true, type: Boolean, default: false },
  },
})
</script>

<style lang="scss" scoped>
.add-month-wrapper {
  background: #f9f9f9;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px -1px 10px -1px rgba(0, 0, 0, 0.1);
  margin-top: 35px;
  margin-bottom: 25px;
}

.button-text-wrapper {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.button {
  width: 200px;
  margin-left: 10px;
}
</style>
