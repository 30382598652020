<template>
  <div class="impact-setting-card-wrapper">
    <div
      :id="'impact-setting-card-' + projectId"
      :class="['impact-setting-card', impactType, { 'is-active': !!modelValue }]"
    >
      <ImpactSettingCardHeader :project-id="projectId ?? ''" />

      <div :class="['project-content']">
        <div class="project-detail name">
          <v-icon class="project-icon"> mdi-hand-heart </v-icon>
          {{ t(`${projectId}.project`) }}
        </div>
        <div class="project-detail cost">
          <v-icon class="project-icon"> mdi-cash-100 </v-icon>
          {{
            t(`${getProjectOffsetTypeById(projectId ?? '')}_price`, {
              price: projectId ? getFormattedProjectPriceById(projectId) : '',
            })
          }}
        </div>

        <gs-button
          v-if="singleSelection"
          size="large"
          type="secondary"
          class="single-select-button"
          :active="isButtonActive"
          @click.prevent="() => onUpdate(1)"
        >
          {{ selectButtonText }}
        </gs-button>
        <ImpactAmountStepper
          v-else
          :model-value="modelValue"
          :max-value="maxValue"
          @update:model-value="onUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Utils } from '@/helpers/mixins/utilsMixin'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import ImpactAmountStepper from '@/components/onboarding/ImpactAmountStepper.vue'
import type { ProjectId } from '@/helpers/constants'
import ImpactSettingCardHeader from '@/components/onboarding/ImpactSettingCardHeader.vue'
import type { OffsetType } from '@/helpers/interfaces'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { TranslateResult } from 'vue-i18n'
import type { Project } from '@/store/projects'

export default defineComponent({
  name: 'ImpactSettingCard',
  emits: ['update:model-value'],
  components: {
    ImpactSettingCardHeader,
    ImpactAmountStepper,
  },
  mixins: [Utils, RulesMixin],
  data() {
    return {
      headerExpanded: false,
    }
  },
  computed: {
    imageUrl(): string | undefined {
      return require(`@/assets/images/projects/${this.projectId}.png`)
    },
    selectButtonText(): TranslateResult {
      return this.t(this.isButtonActive ? 'selected' : 'select')
    },
    getProjectOffsetTypeById(): (projectId: string) => OffsetType {
      return this.$store.getters['getProjectOffsetTypeById']
    },
    getFormattedProjectPriceById(): (projectId: ProjectId) => string {
      return this.$store.getters['getFormattedProjectPriceById']
    },
    getMinimumCheckoutAmount(): number {
      return this.$store.getters['getMinimumCheckoutAmount']
    },
    projects(): Project[] {
      return this.$store.getters['getApprovedProjects']
    },
    impactType(): string {
      return this.projects.find(({ projectId }) => projectId === this.projectId)?.type as string
    },
  },
  mounted() {
    window.addEventListener('resize', this.reportWindowSize)
    this.reportWindowSize()
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize)
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`ImpactSettingCard.${key}`, params ?? {})
    },
    onUpdate(amount: number) {
      this.$emit('update:model-value', amount)
    },
    toggleHeaderExpanded() {
      this.headerExpanded = !this.headerExpanded
    },
  },
  props: {
    modelValue: {
      type: Number,
    },
    projectId: {
      type: String as PropType<ProjectId>,
    },
    position: {
      type: Number,
    },
    singleSelection: {
      type: Boolean,
    },
    isButtonActive: {
      type: Boolean,
    },
    maxValue: {
      type: Number,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

$border-width: 1px;
$border-radius: 10px;

.impact-setting-card-wrapper {
  padding: $border-width $border-width 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  max-width: unset;
  flex-grow: 1;
  flex-shrink: 0;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    max-width: calc(350px - 2 * #{$border-width});
  }
}

.impact-setting-card {
  flex-shrink: 0;
  border-radius: $border-radius;
  scroll-snap-align: center;
  gap: 10px;
  box-shadow: var(--box-shadow-card);
  max-width: unset;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  overflow: hidden;
  position: relative;

  &.is-active:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-width: 2px;
    border-style: solid;
    border-radius: $border-radius;
    transition: opacity 0.25s ease-out;
  }

  &.is-active {
    &.trees:before {
      border-color: #47d18f;
    }

    &.carbon:before {
      border-color: #ffb339;
    }

    &.plastic:before {
      border-color: #1faef0;
    }

    &.kelp:before {
      border-color: #49a29f;
    }
  }
}

.project-content {
  padding: 18px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.impact-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 15px;
}

.impact-description {
  font-size: 18px;
  line-height: 24px;
  height: 144px;
  margin-bottom: 15px;
  color: var(--gray);
}

.project-detail {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;

  &.name {
    margin-bottom: 12px;
  }

  &.cost {
    margin-bottom: 16px;
  }
}

.project-icon {
  color: inherit;
  font-size: 24px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .project-header.expanded {
    height: 300px;
  }
}
</style>
