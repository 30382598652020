<template>
  <div class="feature-table-wrapper">
    <div class="feature-table">
      <div class="table-header">
        <div class="table-head">
          {{ t('starter') }}
        </div>
        <div class="table-head">
          {{ t('growth') }}
        </div>
        <div class="table-head">
          {{ t('premium') }}
        </div>
      </div>
      <section class="table-row category">
        <div class="row-label category">
          {{ t('features') }}
        </div>
      </section>
      <div class="table-row">
        <div class="row-label">
          {{ t('users') }}
          <info-tooltip>
            {{ t('users_tooltip') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds">1</div>
          <div class="row-value-thirds">3</div>
          <div class="row-value-thirds">8</div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('dashboard') }}
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('team_offset') }}
          <info-tooltip>
            {{ t('tooltip_team_offset') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('manual_impact') }}
          <info-tooltip>
            {{ t('tooltip_manual_impact') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('automatic_trees') }}
          <info-tooltip>
            {{ t('tooltip_automatic_trees') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            {{ t('starter_plan_trees') }}
          </div>
          <div class="row-value-thirds">
            {{ t('growth_plan_trees') }}
          </div>
          <div class="row-value-thirds">
            {{ t('premium_plan_trees') }}
          </div>
        </div>
      </div>
      <section class="table-row category">
        <div class="row-label category">
          {{ t('engagement_hub') }}
        </div>
      </section>
      <div class="table-row">
        <div class="row-label">
          {{ t('public_profile') }}
          <info-tooltip>
            {{ t('tooltip_public_profile') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            {{ t('basic') }}
          </div>
          <div class="row-value-thirds">
            {{ t('advanced') }}
          </div>
          <div class="row-value-thirds">
            {{ t('advanced') }}
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('badges_widgets') }}
          <info-tooltip>
            {{ t('tooltip_badges_widgets') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('impact_wallet') }}
          <info-tooltip>
            {{ t('tooltip_impact_wallet') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds" />
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('qr_code') }}
          <info-tooltip>
            {{ t('tooltip_qr_code') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds" />
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('media_pack') }}
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            {{ t('basic') }}
          </div>
          <div class="row-value-thirds">
            {{ t('basic') }}
          </div>
          <div class="row-value-thirds">
            {{ t('full') }}
          </div>
        </div>
      </div>
      <section class="table-row category">
        <div class="row-label category">
          {{ t('integrations_automations') }}
        </div>
      </section>
      <div class="table-row">
        <div class="row-label">
          {{ t('active_integrations') }}
          <info-tooltip>
            {{ t('tooltip_active_integrations') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds">1</div>
          <div class="row-value-thirds">3</div>
          <div class="row-value-thirds">
            {{ t('unlimited') }}
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('basic_automations') }}
          <info-tooltip>
            {{ t('tooltip_basic_automations') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('advanced_automations') }}
          <info-tooltip>
            {{ t('tooltip_advanced_automations') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds" />
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>
      <section class="table-row category">
        <div class="row-label category">
          {{ t('network') }}
        </div>
      </section>
      <div class="table-row">
        <div class="row-label">
          {{ t('refer_partners') }}
          <info-tooltip>
            {{ t('tooltip_refer_partners') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('reward_partners') }}
          <info-tooltip>
            {{ t('tooltip_reward_partners') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds" />
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>
      <section class="table-row category">
        <div class="row-label category">
          {{ t('analytics') }}
        </div>
      </section>
      <div class="table-row">
        <div class="row-label">
          {{ t('basic_analytics') }}
          <info-tooltip>
            {{ t('tooltip_basic_analytics') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('advanced_analytics') }}
          <info-tooltip>
            {{ t('tooltip_advanced_analytics') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds" />
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>
      <section class="table-row category">
        <div class="row-label category">API</div>
      </section>
      <div class="table-row">
        <div class="row-label">
          {{ t('widget_api') }}
          <info-tooltip>
            {{ t('tooltip_widget_api') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('greenspark_api') }}
          <info-tooltip>
            {{ t('tooltip_greenspark_api') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds" />
          <div class="row-value-thirds" />
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>

      <section class="table-row category">
        <div class="row-label category">
          {{ t('support') }}
        </div>
      </section>
      <div class="table-row">
        <div class="row-label">
          {{ t('chat_email') }}
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('technical') }}
        </div>
        <div class="row-values">
          <div class="row-value-thirds" />
          <div class="row-value-thirds">Standard</div>
          <div class="row-value-thirds">Priority</div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('campaign_support') }}
          <info-tooltip>
            {{ t('tooltip_campaign_support') }}
          </info-tooltip>
        </div>
        <div class="row-values">
          <div class="row-value-thirds" />
          <div class="row-value-thirds" />
          <div class="row-value-thirds">
            <v-icon class="icon"> mdi-check </v-icon>
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('developer_success') }}
        </div>
        <div class="row-values">
          <div class="row-value-thirds" />
          <div class="row-value-thirds" />
          <div class="row-value-thirds">+Add-on</div>
        </div>
      </div>
      <section class="table-row category">
        <div class="row-label category">
          {{ t('impact_pricing') }}
        </div>
      </section>
      <div class="table-row">
        <div class="row-label">
          {{ t('trees') }}
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            {{
              t('tree_price', {
                price: getDefaultPaidProjectPriceByType('trees'),
                currencySymbol: getUserCurrencySymbol,
              })
            }}
          </div>
          <div class="row-value-thirds">
            {{
              t('tree_price', {
                price: getDefaultPaidProjectPriceByType('trees'),
                currencySymbol: getUserCurrencySymbol,
              })
            }}
          </div>
          <div class="row-value-thirds">
            {{
              t('tree_price', {
                price: getDefaultPaidProjectPriceByType('trees'),
                currencySymbol: getUserCurrencySymbol,
              })
            }}
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('plastic') }}
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            {{
              t('plastic_price', {
                price: getDefaultPaidProjectPriceByType('plastic'),
                currencySymbol: getUserCurrencySymbol,
              })
            }}
          </div>
          <div class="row-value-thirds">
            {{
              t('plastic_price', {
                price: getDefaultPaidProjectPriceByType('plastic'),
                currencySymbol: getUserCurrencySymbol,
              })
            }}
          </div>
          <div class="row-value-thirds">
            {{
              t('plastic_price', {
                price: getDefaultPaidProjectPriceByType('plastic'),
                currencySymbol: getUserCurrencySymbol,
              })
            }}
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('carbon') }}
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            {{
              t('carbon_price', {
                price: getDefaultPaidProjectPriceByType('carbon'),
                currencySymbol: getUserCurrencySymbol,
              })
            }}
          </div>
          <div class="row-value-thirds">
            {{
              t('carbon_price', {
                price: getDefaultPaidProjectPriceByType('carbon'),
                currencySymbol: getUserCurrencySymbol,
              })
            }}
          </div>
          <div class="row-value-thirds">
            {{
              t('carbon_price', {
                price: getDefaultPaidProjectPriceByType('carbon'),
                currencySymbol: getUserCurrencySymbol,
              })
            }}
          </div>
        </div>
      </div>
      <div class="table-row">
        <div class="row-label">
          {{ t('kelp') }}
        </div>
        <div class="row-values">
          <div class="row-value-thirds">
            {{
              t('kelp_price', {
                price: getDefaultPaidProjectPriceByType('kelp'),
                currencySymbol: getUserCurrencySymbol,
              })
            }}
          </div>
          <div class="row-value-thirds">
            {{
              t('kelp_price', {
                price: getDefaultPaidProjectPriceByType('kelp'),
                currencySymbol: getUserCurrencySymbol,
              })
            }}
          </div>
          <div class="row-value-thirds">
            {{
              t('kelp_price', {
                price: getDefaultPaidProjectPriceByType('kelp'),
                currencySymbol: getUserCurrencySymbol,
              })
            }}
          </div>
        </div>
      </div>
    </div>
    <i18n-t tag="p" keypath="FeaturesTable.questions" class="demo">
      <button target="_blank" class="link" @click="openChat()">
        {{ t('get_in_touch') }}
      </button>
      <a href="http://getgreenspark.com/book-a-demo" target="_blank" class="link">{{
        t('book_demo')
      }}</a>
    </i18n-t>
  </div>
</template>

<script lang="ts">
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { OffsetType } from '@/helpers/interfaces'
import InfoTooltip from '@/components/common/InfoTooltip.vue'
import { LoadCrispMixin } from '@/helpers/mixins/loadCrispMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FeaturesTable',
  components: { InfoTooltip },
  mixins: [Utils, LoadCrispMixin],
  methods: {
    t(key: string, params?: { [k: string]: string | number }) {
      return this.$t(`FeaturesTable.${key}`, params ?? {})
    },
    getDefaultPaidProjectPriceByType(type: OffsetType): number {
      return this.$store.getters['getDefaultPaidProjectPriceByType'](type)
    },
  },
  computed: {
    getFormattedDefaultProjectPriceByType(): (type: OffsetType) => string {
      return this.$store.getters['getFormattedDefaultProjectPriceByType']
    },
    getUserCurrencySymbol(): string {
      return this.$store.getters['getUserCurrencySymbol']
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.feature-table-wrapper {
  padding-bottom: 40px;
}

.feature-table {
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: var(--box-shadow-wide);
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid var(--ui-green);
  position: sticky;
  top: 0;
  z-index: 2;
  border-radius: 8px 8px 0 0;
  background-color: white;
}

.table-head {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.table-row {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  padding: 10px 0;
}

.table-row.category {
  background-color: var(--ui-green-light);
}

.table-row.category ~ .table-row:not(.category):nth-of-type(even) {
  background-color: var(--ui-white);
}

.table-row:last-child {
  border-radius: 0 0 8px 8px;
}

.row-label {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  margin: 0 20px 10px;
  border-bottom: 1px solid var(--gray-light);
}

.row-label.category {
  padding: 0;
  border: none;
  margin: 0;
  font-weight: 600;
}

.row-values {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 0 20px;
}

.row-value-thirds {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
}

.icon {
  color: var(--ui-green);
}

.demo {
  text-align: center;
}

.link {
  color: var(--ui-green);
  text-decoration: underline;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .table-header {
    justify-content: flex-end;
  }

  .table-head {
    flex: 0 1 20%;
  }

  .table-row {
    padding: 15px 0;
    justify-content: space-between;
  }

  .row-label {
    flex: 0 1 30%;
    justify-content: flex-start;
    margin: 0 0 0 20px;
    border: none;
    padding: 0;
  }

  .row-label.category {
    margin: 0 0 0 20px;
  }

  .row-values {
    flex: 0 1 60%;
    margin: 0;
  }

  .demo {
    text-align: left;
  }
}
</style>
