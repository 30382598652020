<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CarbonImpactIconIsometric',
})
</script>

<template>
  <h1>CarbonImpactIconIsometric</h1>
</template>

<style scoped lang="scss"></style>
