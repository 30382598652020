import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4abd7a60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "partner-grid-outer" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 2,
  class: "empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainTable = _resolveComponent("MainTable")!
  const _component_Reward = _resolveComponent("Reward")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_History = _resolveComponent("History")!
  const _component_StopPartnership = _resolveComponent("StopPartnership")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_LoadingOpaque = _resolveComponent("LoadingOpaque")!
  const _component_Empty = _resolveComponent("Empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.empty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_MainTable, {
            "menu-items": _ctx.menuItems,
            rows: _ctx.rowItems,
            "column-width": _ctx.columnWidth,
            "footer-items": _ctx.footerItems,
            "hide-partner-image": false,
            "hide-search": false,
            "no-results": _ctx.noSearchResults,
            "table-loading": _ctx.tableLoading,
            "active-page": _ctx.activePage,
            onQuickMenuClick: _ctx.quickMenuClick,
            onEmitSearch: _ctx.search,
            onEmitSort: _ctx.sort,
            onFooterClick: _ctx.footerClick,
            onArrowClick: _ctx.arrowClick
          }, null, 8, ["menu-items", "rows", "column-width", "footer-items", "no-results", "table-loading", "active-page", "onQuickMenuClick", "onEmitSearch", "onEmitSort", "onFooterClick", "onArrowClick"]),
          _createVNode(_component_v_dialog, {
            modelValue: _ctx.quickMenuDialog,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.quickMenuDialog) = $event)),
            width: "510",
            persistent: "",
            fullscreen: _ctx.isMobile
          }, {
            default: _withCtx(() => [
              (_ctx.activeQuickMenuItem === 'reward')
                ? (_openBlock(), _createBlock(_component_Reward, {
                    key: 0,
                    "relations-id": _ctx.relationsIdQuickMenuItem,
                    "connectee-id-quick-menu-item": _ctx.connecteeIdQuickMenuItem,
                    onDialogClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogClick())),
                    onShowErrorAlert: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('showErrorAlert'))),
                    onShowSuccessAlert: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('showSuccessAlert'))),
                    onGetFreshDataClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('getFreshDataClick')))
                  }, null, 8, ["relations-id", "connectee-id-quick-menu-item"]))
                : _createCommentVNode("", true),
              (_ctx.activeQuickMenuItem === 'edit' || _ctx.activeQuickMenuItem === 'editContact')
                ? (_openBlock(), _createBlock(_component_Edit, {
                    key: 1,
                    "edit-items": _ctx.editItems,
                    "is-edit": _ctx.activeQuickMenuItem === 'edit',
                    "is-contact-edit": _ctx.activeQuickMenuItem === 'editContact',
                    "relations-id": _ctx.relationsIdQuickMenuItem,
                    onDialogClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.dialogClick())),
                    onShowErrorAlert: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('showErrorAlert'))),
                    onShowSuccessAlert: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('showSuccessAlert'))),
                    onGetFreshDataClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('getFreshDataClick')))
                  }, null, 8, ["edit-items", "is-edit", "is-contact-edit", "relations-id"]))
                : _createCommentVNode("", true),
              (_ctx.activeQuickMenuItem === 'history')
                ? (_openBlock(), _createBlock(_component_History, {
                    key: 2,
                    "window-width": _ctx.windowWidth,
                    "relations-id": _ctx.relationsIdQuickMenuItem,
                    "partner-name": _ctx.partnerName,
                    onDialogClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.dialogClick()))
                  }, null, 8, ["window-width", "relations-id", "partner-name"]))
                : _createCommentVNode("", true),
              (_ctx.activeQuickMenuItem === 'stop')
                ? (_openBlock(), _createBlock(_component_StopPartnership, {
                    key: 3,
                    "relations-id": _ctx.relationsIdQuickMenuItem,
                    onGetFreshDataClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('getFreshDataClick'))),
                    onDialogClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.dialogClick()))
                  }, null, 8, ["relations-id"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "fullscreen"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingOpaque, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.empty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Empty, {
            text: _ctx.t('empty'),
            text1: _ctx.t('empty1'),
            "button-text": _ctx.t('invite'),
            onButtonClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('emptyButtonClick')))
          }, null, 8, ["text", "text1", "button-text"])
        ]))
      : _createCommentVNode("", true)
  ]))
}