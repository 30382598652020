<template>
  <div v-if="loaded" class="referrals-wrapper">
    <HowItWorksUtility
      :subheader="
        account.accountType === 'business' || account.accountType === 'ecommerce'
          ? `${t('description')}`
          : `${t('description_personal')}`
      "
      :text="`${t('how_it_works_1')}`"
      :text1="`${t('how_it_works_2')}`"
      :text2="
        account.accountType === 'personal' || account.accountType === 'family'
          ? `${t('how_it_works_3_personal')}`
          : `${t('how_it_works_3')}`
      "
    />
    <div class="select-impact">
      <div class="subheader">
        {{ t('choose') }}
      </div>
      <v-form v-model="isFormValid">
        <v-select
          v-model="referralImpact"
          :rules="[rules.required]"
          :items="items"
          :label="t('choose_short')"
          variant="outlined"
          color="green"
          item-color="green"
          class="dropdown"
          @update:model-value="onTypeChange"
        />
      </v-form>
    </div>

    <div class="share-impact">
      <div class="subheader">
        {{ t('share') }}
      </div>

      <div class="share-icons">
        <ReferralShareIcon
          v-for="(platform, index) in referralSocials"
          :key="index"
          :platform="platform"
          :disabled="!isFormValid"
          :referral-link="referralLink"
        />
      </div>

      <div class="referral-link-wrapper">
        <div
          class="referral-link"
          :style="isFormValid ? 'background: #AFC6BD; border: 1px solid #F2EBDB;' : ''"
        >
          {{ referralLink }}
        </div>

        <gs-button @click="onCopy" class="copy-button" :disabled="!isFormValid" size="large">
          {{ !copied ? t('copy') : t('copied') }}
        </gs-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import HowItWorksUtility from '@/components/shareables/HowItWorksUtility.vue'
import ReferralShareIcon from '@/components/referrals/ReferralShareIcon.vue'
import { getReferralInvite, upsertReferralInvite } from '@api/index'
import type { ReferralSocialPlatform } from '@/helpers/constants'
import {
  REFERRAL_LINK_BUSINESS,
  REFERRAL_LINK_PERSONAL,
  REFERRAL_SOCIAL_PLATFORMS,
} from '@/helpers/constants'
import type { Notification } from '@/store/notification'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { Clearable, OffsetType } from '@/helpers/interfaces'
import { SUBSCRIPTION_OFFSET_TYPES } from '@/helpers/interfaces'
import type { TranslateResult } from 'vue-i18n'
import type { Account } from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Referrals',
  components: {
    ReferralShareIcon,
    HowItWorksUtility,
  },
  mixins: [RulesMixin],
  data() {
    const referralImpact: Clearable<OffsetType> = null
    const referralSocials: ReferralSocialPlatform[] = []

    return {
      referralSocials,
      copied: false,
      loaded: false,
      referralImpact,
      relationsId: '',
      isFormValid: false,
    }
  },
  computed: {
    items(): { title: string | TranslateResult; value: OffsetType }[] {
      return SUBSCRIPTION_OFFSET_TYPES.map((type) => {
        return {
          title: this.t(`${type}`),
          value: type,
        }
      })
    },
    referralLink(): string {
      return this.getAccountTypeIsBusiness
        ? `${REFERRAL_LINK_BUSINESS}?i=${this.relationsId}`
        : `${REFERRAL_LINK_PERSONAL}?i=${this.relationsId}`
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    getAccountTypeIsBusiness(): boolean {
      return this.$store.getters['getAccountTypeIsBusiness']
    },
    getReferralAmountByOffsetType(): (type: OffsetType) => number {
      return this.$store.getters['getReferralAmountByOffsetType']
    },
  },
  async created() {
    this.loaded = false
    this.referralSocials = REFERRAL_SOCIAL_PLATFORMS
    await this.getReferralImpact()
    this.loaded = true
  },
  methods: {
    t(key: string) {
      return this.$t(`Referrals.${key}`)
    },
    onCopy() {
      try {
        navigator.clipboard.writeText(this.referralLink)
        this.copied = true
        setTimeout(() => {
          this.copied = false
        }, 1500)
      } catch (error) {
        this.onError(error)
      }
    },
    async saveReferral() {
      if (this.referralImpact) {
        const payload = {
          activeImpact: {
            type: this.referralImpact,
            amount: this.getReferralAmountByOffsetType(this.referralImpact),
          },
        }
        await upsertReferralInvite(payload)
      }
    },
    async onTypeChange() {
      await this.saveReferral()
      await this.getReferralImpact()
    },
    async getReferralImpact() {
      const { data } = await getReferralInvite(this.account.accountId)
      if (data) {
        this.referralImpact = data.impact.type
        this.relationsId = data._id
      } else if (this.referralImpact) {
        const payload = {
          activeImpact: {
            type: this.referralImpact,
            amount: this.getReferralAmountByOffsetType(this.referralImpact),
          },
        }
        await upsertReferralInvite(payload)
      }
    },
    onError(error) {
      console.error(error)
      this.$store.dispatch('notification/notify', {
        text: this.$t('CommonUi.error_generic'),
        isError: true,
        isClosable: true,
        buttonText: 'close',
      } as Notification)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.referrals-wrapper {
  width: 100%;
  margin-top: -12px;
  overflow-y: scroll;
}

.select-impact {
  max-width: 547px;
}

.subheader {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  margin-top: 40px;
  margin-bottom: 18px;
}

.share-icons {
  display: flex;
}

.referral-link-wrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 6px;
  margin-top: 18px;
  height: auto;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    height: 54px;
    flex-direction: row;
  }
}

.referral-link {
  background: #b0b0b0;
  border: 1px solid #b0b0b0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px;
  height: 54px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #21212190;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: 60%;
  }
}

.copy-button {
  width: 100%;
  flex-grow: 1;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    width: auto;
  }
}
</style>
