<template>
  <div class="user-component-wrapper">
    <CarouselCard class="carousel" />
    <div class="user-wrapper">
      <button v-if="!isInvite" class="back" @click="onBackClick">
        <v-icon class="chevron"> mdi-chevron-left </v-icon>
        {{ t('back') }}
      </button>
      <div class="form-wrapper">
        <v-form v-model="isFormValid">
          <div class="header">
            {{ t(isInvite ? 'create_account_invite' : 'create_account') }}
          </div>

          <div class="name-wrapper">
            <div class="text-field-wrapper">
              <v-text-field
                v-model="userObject.firstName"
                :label="t('first_name')"
                variant="outlined"
                :rules="[rules.required]"
                type="text"
                flat
                hide-details
                color="green"
                class="left-input"
              />

              <v-text-field
                v-model="userObject.lastName"
                :label="t('last_name')"
                variant="outlined"
                :rules="[rules.required]"
                type="text"
                flat
                hide-details
                color="green"
                class="right-input"
              />
            </div>
          </div>

          <div v-if="!isInvite" class="name-wrapper">
            <div class="text-field-wrapper">
              <v-text-field
                v-model="userObject.companyName"
                :label="t('company_name')"
                variant="outlined"
                :rules="[rules.required]"
                type="text"
                flat
                hide-details
                color="green"
                class="left-input"
              />

              <v-text-field
                v-model="userObject.vatId"
                :label="t('vat_id')"
                variant="outlined"
                type="text"
                flat
                hide-details
                color="green"
                class="right-input"
              />
            </div>
          </div>

          <div class="name-wrapper">
            <div class="text-field-wrapper">
              <v-text-field
                id="Email"
                v-model="userObject.email"
                class="input-field"
                name="Email"
                :rules="[rules.required, rules.email]"
                autocomplete="email"
                type="email"
                hide-details
                :label="t('email')"
                variant="outlined"
                flat
                color="green"
                :disabled="isInvite"
                @update:model-value="onEmailInput"
              />
            </div>
          </div>

          <div class="name-wrapper">
            <div class="text-field-wrapper">
              <v-text-field
                id="Password"
                v-model="userObject.password"
                class="input-field"
                name="password"
                :rules="[rules.required, rules.password]"
                autocomplete="password"
                :type="showPassword ? 'text' : 'password'"
                :label="t('password')"
                :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                variant="outlined"
                flat
                hide-details
                color="green"
                @click:append-inner="showPassword = !showPassword"
              />
            </div>
          </div>
          <template v-if="error.length">
            <template v-if="error === 'emailAlreadyUsed'">
              <div class="error-message">
                {{ t('already_used') }}
              </div>
              <div class="error-message" :style="'background: #2E3A8C'">
                {{ t('ask_to_login') }}
                <span class="login-link" @click="$router.push('/login')">
                  {{ t('login') }}
                </span>
              </div>
            </template>

            <template v-else>
              <div class="error-message">
                {{ t(snakeCase(error)) }}
              </div>
            </template>
          </template>

          <div
            :class="['password-hint', { invite: isInvite }]"
            :style="hasPasswordError ? 'background: red; color: #F9F9F9' : ''"
          >
            {{ t('password_hint') }}
          </div>

          <div class="checkbox-wrapper">
            <v-checkbox
              v-model="checkbox"
              color="green"
              hide-details
              :rules="[rules.required]"
              style="margin: 0"
            />
            <i18n-t tag="p" keypath="Signup.accept" class="mb-0 terms">
              <a :href="TERMS_CONDITIONS_URL" target="_blank" class="link">{{ t('terms') }}</a>
              <a :href="PRIVACY_POLICY_URL" target="_blank" class="link">{{ t('privacy') }}</a>
            </i18n-t>
          </div>

          <div class="button-wrapper">
            <gs-button
              :disabled="!isFormValid || loading"
              :loading="loading"
              :full-width="true"
              size="large"
              @click.prevent="signup"
            >
              {{ t('create') }}
            </gs-button>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { createAccount, createUser, userRegistration } from '@api/index'
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import { PRIVACY_POLICY_URL, TERMS_CONDITIONS_URL } from '@/helpers/constants'
import { Utils } from '@/helpers/mixins/utilsMixin'
import CarouselCard from '@/components/register/CarouselCard.vue'
import type { Account } from '@/store'
import type { User } from '@/store/users'
import { defineComponent } from 'vue'

type SignupEcommerceError =
  | 'emailAlreadyUsed'
  | 'invalidInviteUrl'
  | 'inviteNotFound'
  | 'inviteIsAlreadyAccepted'
  | 'inviteEmailMismatch'
  | 'inviteIsDeleted'
  | 'somethingWentWrong'

const createUserErrorMapping: { [key: string]: SignupEcommerceError } = {
  ALREADY_REGISTERED: 'emailAlreadyUsed',
  INVITE_NOT_FOUND: 'inviteNotFound',
  INVITE_IS_ALREADY_ACCEPTED: 'inviteIsAlreadyAccepted',
  INVITE_EMAIL_MISMATCH: 'inviteEmailMismatch',
  INVITE_IS_DELETED: 'inviteIsDeleted',
  SOMETHING_WENT_WRONG: 'somethingWentWrong',
}

export default defineComponent({
  name: 'SignupEcommerce',
  emits: ['backClick'],
  components: { CarouselCard },
  mixins: [RulesMixin, Utils],
  data() {
    return {
      userObject: {
        firstName: '',
        lastName: '',
        vatId: '',
        companyName: '',
        email: '',
        password: '',
      },
      isFormValid: false,
      showPassword: false,
      loading: false,
      checkbox: false,
      userAlreadyHasAccount: false,
      PRIVACY_POLICY_URL: '',
      TERMS_CONDITIONS_URL: '',
      error: '',
      location: '',
      isInvite: false,
    } as {
      userObject: {
        firstName: string
        lastName: string
        vatId: string
        companyName: string
        email: string
        password: string
      }
      isFormValid: boolean
      showPassword: boolean
      loading: boolean
      checkbox: boolean
      userAlreadyHasAccount: boolean
      PRIVACY_POLICY_URL: string
      TERMS_CONDITIONS_URL: string
      error: SignupEcommerceError | ''
      location: string
      isInvite: boolean
    }
  },
  async created() {
    try {
      this.location = await this.getLocation()
    } catch (error) {
      this.location = ''
    }
    this.PRIVACY_POLICY_URL = PRIVACY_POLICY_URL
    this.TERMS_CONDITIONS_URL = TERMS_CONDITIONS_URL
    this.setUserObject()
    this.setEmailAlreadyUsed(false)
    this.setSignupComplete(false)
    if (this.currentUser.email !== '') {
      this.userObject.email = this.currentUser.email
    }

    this.isInvite = this.$route.path === '/signup/user'

    if (this.isInvite) {
      this.validateQueryParams()

      if (!this.error.length) {
        this.userObject.email = this.$route.query.email as string
      }
    }
  },
  methods: {
    t(key: string) {
      return this.$t(`SignupEcommerce.${key}`)
    },
    async signup() {
      this.loading = true
      this.userAlreadyHasAccount = false

      const payload = {
        email: this.userObject.email,
        password: this.userObject.password,
        name: this.userObject.companyName,
      }

      await userRegistration(payload)

      if (this.account.signupComplete) {
        if (this.isInvite) {
          await this.createUser()
        } else {
          await this.createAccount()
        }
      }

      this.loading = false
    },
    openUrl(value) {
      switch (value) {
        case 'terms':
          window.open(TERMS_CONDITIONS_URL, '_blank')
          break
        case 'privacy':
          window.open(PRIVACY_POLICY_URL, '_blank')
          break
      }
    },
    setUserObject() {
      if (this.currentUser.id && this.account.accountId) {
        this.userObject = {
          firstName: this.currentUser.firstName,
          lastName: this.currentUser.lastName,
          vatId: this.account.vatId,
          companyName: this.account.companyName,
          email: this.currentUser.email,
          password: '',
        }
      }
    },
    onEmailInput() {
      if (this.account.emailAlreadyUsed) {
        this.clearError()
      }
    },
    onBackClick() {
      this.clearError()
      this.$emit('backClick')
    },
    clearError() {
      this.setEmailAlreadyUsed(false)
      this.error = ''
    },
    async createUser() {
      try {
        const userInviteId = this.$route.query.userInviteId as string
        await createUser(this.userObject.firstName, this.userObject.lastName, userInviteId)
        await this.loadAccount()
        await this.setOnboardingSkipped()
        await this.$router.push('/')
      } catch (error) {
        console.error(error)
        const { message } = error.response.data
        this.error = createUserErrorMapping[message] || createUserErrorMapping.SOMETHING_WENT_WRONG
      }
    },
    async createAccount() {
      const livePageId = Date.now() + Math.floor(Math.random() * 1000)
      let widgetFolderName = this.account.companyName + Math.floor(Math.random() * 100).toString()
      widgetFolderName = widgetFolderName.replace(/\s/g, '-').toLowerCase()

      const payload = {
        user: {
          accountType: 'ecommerce',
          email: this.userObject.email,
          vatId: this.userObject.vatId,
          companyName: this.userObject.companyName,
          firstName: this.userObject.firstName,
          lastName: this.userObject.lastName,
          widgets: [''],
          accountId: this.authUserId,
          signupDate: Date.now(),
          termsAccept: this.checkbox,
          currency: this.getCurrencyByLocation(this.location),
          livePageId: livePageId,
          widgetFolderName: widgetFolderName,
          accountFromMarketplace: false,
          address: {
            country: this.location,
          },
        },
      }
      const { data } = await createAccount(payload)
      this.setAccount(data)
      await this.setCurrentUser()
      await this.$router.push({ path: '/checkout', query: { ...this.$route.query } })
    },
    validateQueryParams() {
      const email = this.$route.query.email
      const userInviteId = this.$route.query.userInviteId
      const emailValidationResult = this.rules.email(email as string)

      if (!userInviteId || !email || typeof emailValidationResult !== 'boolean') {
        this.error = 'invalidInviteUrl'
      }
    },
    async onEmailAlreadyUsedChange() {
      this.loading = false
      if (this.account.emailAlreadyUsed) {
        // If email has already used at firebase side we still need to try creating user at our side
        // There could be a state when the user had successfully registered at firebase but could not finish
        // the user creation at our side
        if (this.isInvite) {
          await this.createUser()
        } else {
          this.error = 'emailAlreadyUsed'
        }
      }
    },
    loadAccount(): Promise<void> {
      return this.$store.dispatch('setAccount')
    },
    setOnboardingSkipped(): Promise<void> {
      return this.$store.dispatch('setOnboardingSkipped')
    },
    setCurrentUser(): Promise<void> {
      return this.$store.dispatch('setCurrentUser')
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
    setEmailAlreadyUsed(emailAlreadyUsed) {
      return this.$store.commit('setEmailAlreadyUsed', emailAlreadyUsed)
    },
    setSignupComplete(signupComplete) {
      return this.$store.commit('setSignupComplete', signupComplete)
    },
  },
  watch: {
    'account.emailAlreadyUsed': [
      {
        handler: 'onEmailAlreadyUsedChange',
      },
    ],
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
    authUserId(): string {
      return this.$store.getters['getAuthUserId']
    },
    hasPasswordError(): boolean {
      return Boolean(this.userObject.password) && !this.rules.password(this.userObject.password)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.user-component-wrapper {
  width: 100%;
  border-radius: var(--border-radius-big);
  display: flex;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
}

.user-wrapper {
  padding: 30px 24px 0;
  width: 100%;
  background-color: var(--ui-white);
}

.chevron {
  font-size: 24px;
  color: inherit;
}

.back {
  display: flex;
  font-weight: 600;
  color: var(--ui-green);
  cursor: pointer;
  margin-bottom: 16px;
}

.header {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: var(--font-color-primary);
  margin-bottom: 24px;
}

.left-input {
  margin-right: 4px;
}

.right-input {
  margin-left: 4px;
}

.name-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.input-label {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #212121;
  margin-bottom: 4px;
}

.input-description {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #b0b0b0;
  margin-bottom: 12px;
}

.text-field-wrapper {
  display: flex;
  width: 100%;
}

.error-message {
  font-size: 16px;
  color: #f9f9f9;
  font-weight: 500;
  text-align: left;
  width: 100%;
  padding: 8px 20px;
  background: red;
  margin-bottom: 5px;
  border-radius: 4px;
}

.password-hint {
  text-align: left;
  width: 100%;
  padding: 8px 20px;
  background: rgba(176, 176, 176, 0.2);
  border-radius: 4px;
  margin-bottom: 22px;
  font-size: 14px;
  line-height: 17px;
  color: var(--font-color-primary);
}

.checkbox-wrapper {
  display: flex;
  margin-bottom: 25px;
  width: 100%;
}

.terms {
  display: flex;
  align-items: center;
  color: var(--font-color-primary);
  padding-top: 3px;
  cursor: pointer;
  font-size: 14px;
  line-height: 17px;
  flex-wrap: wrap;
}

.link {
  margin-left: 3px;
  margin-right: 3px;
  color: var(--ui-green);
  font-weight: bold;
}

.button-wrapper {
  margin-top: 20px;
}

.login-link {
  margin-left: 3px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
}

.carousel {
  display: none;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .user-component-wrapper {
    min-height: auto;
  }

  .carousel {
    flex: 1 1 calc((100% / 5) * 2);
    display: block;
    height: auto;
    width: calc((100% / 5) * 2);
  }

  .user-wrapper {
    padding: 30px 24px 44px;
    flex: 1 1 calc((100% / 5) * 3);
  }

  .password-hint {
    margin-bottom: 40px;
  }

  .password-hint.invite {
    margin-bottom: 148px;
  }

  .checkbox-wrapper {
    display: flex;
    margin-bottom: 25px;
    width: 100%;
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .carousel {
    flex: 1 1 calc(100% / 3);
    width: calc(100% / 3);
  }

  .user-wrapper {
    flex: 1 1 calc((100% / 3) * 2);
    padding: 30px 86px 44px;
  }
}
</style>
