<!-- TODO: Remove these eslint comments. We need to fix this prop mutation ASAP. -->
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <div class="title-wrapper">
      <img
        class="info-icon"
        :src="require('@/assets/icons/partners/partner_info.svg')"
        alt="info"
      />
      <div class="subheader">
        {{ t('info') }}
      </div>
    </div>

    <div class="input-outer">
      <!-- First name -->
      <v-text-field
        v-model="partnerCredential.firstName"
        :label="t('first_name')"
        variant="outlined"
        type="text"
        flat
        color="#3B755F"
        :disabled="isEdit"
        :rules="[rules.required]"
      />

      <!-- Last name -->
      <v-text-field
        v-model="partnerCredential.lastName"
        :label="t('last_name')"
        variant="outlined"
        type="text"
        flat
        color="#3B755F"
        :disabled="isEdit"
        :rules="[rules.required]"
      />

      <!-- Partner & Email -->
      <v-text-field
        v-model="partnerCredential.partnerName"
        :label="t('partner_name')"
        variant="outlined"
        type="text"
        flat
        color="#3B755F"
        :rules="[rules.required]"
      />

      <v-text-field
        v-model="partnerCredential.email"
        :label="t('email')"
        variant="outlined"
        type="text"
        flat
        color="#3B755F"
        :disabled="isEdit"
        :rules="[rules.email, rules.required]"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { RulesMixin } from '@/helpers/mixins/RulesMixin.vue'
import type { PartnerCredential } from '@/components/Network/Partners.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PartnerInformation',
  mixins: [RulesMixin],
  methods: {
    t(key: string) {
      return this.$t(`PartnerInformation.${key}`)
    },
  },
  props: {
    partnerCredential: {
      type: Object as PropType<PartnerCredential>,
      required: true,
    },
    isEdit: {
      type: Boolean,
    },
  },
})
</script>

<style lang="scss" scoped>
.title-wrapper {
  display: flex;
}

.subheader {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  margin-left: 5px;
}

.input-outer {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 8px;
  grid-row-gap: 12px;
}
</style>
