import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8010189a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "schedule-input-wrapper" }
const _hoisted_2 = { class: "numbers" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_gs_button = _resolveComponent("gs-button")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_menu, {
    ref: "dayMenu",
    modelValue: _ctx.dayMenu,
    "onUpdate:modelValue": [
      _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dayMenu) = $event)),
      _ctx.handleInput
    ],
    "close-on-content-click": true,
    "return-value": _ctx.selectedDay,
    "onUpdate:returnValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedDay) = $event)),
    transition: "scale-transition",
    "min-width": "auto"
  }, {
    activator: _withCtx(({ props }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_text_field, _mergeProps({
          modelValue: _ctx.selectedDay,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDay) = $event)),
          modelModifiers: { number: true },
          color: "green",
          "hide-details": "",
          "prepend-inner-icon": "mdi-calendar-range-outline",
          flat: "",
          variant: "outlined",
          type: "number"
        }, props, { onChange: _ctx.handleInput }), null, 16, ["modelValue", "onChange"]),
        (
            _ctx.currentPeriodSettings &&
            _ctx.indexOfInput &&
            !(_ctx.currentPeriodSettings - 2 > _ctx.indexOfInput) &&
            !(_ctx.currentPeriodSettings === 4 && _ctx.indexOfInput === 2)
          )
          ? (_openBlock(), _createBlock(_component_gs_button, {
              key: 0,
              size: "large",
              icon: _ctx.iconName,
              type: "secondary",
              disabled: !_ctx.selectedDay && _ctx.maxSelectablePeriods - 1 !== _ctx.indexOfInput,
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.handleClick()), ["prevent"]))
            }, null, 8, ["icon", "disabled"]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.maxDays, (day, index) => {
          return (_openBlock(), _createElementBlock("p", {
            key: index,
            class: _normalizeClass([
          'number',
          { selected: day === _ctx.selectedDay },
          { disabled: _ctx.disabledRange && day <= _ctx.disabledRange && _ctx.indexOfInput },
        ]),
            onClick: 
          () =>
            _ctx.disabledRange && day <= _ctx.disabledRange && _ctx.indexOfInput
              ? undefined
              : _ctx.$emit('update:model-value', day)
        
          }, _toDisplayString(day), 11, _hoisted_3))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "return-value", "onUpdate:modelValue"]))
}