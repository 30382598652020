<template>
  <div>
    <transition name="fade">
      <div class="submenu">
        <LivePage :account="account" :current-user="currentUser" />

        <SubMenuTile
          v-if="account.accountType === 'business' || account.accountType === 'ecommerce'"
          :header="t('engagement')"
          :text="t('engagement_text')"
          :button-text="t('manage')"
          @actionClick="$router.push({ name: 'Post purchase engagement' })"
        />

        <SubMenuTile
          v-if="account.accountType === 'business' || account.accountType === 'ecommerce'"
          :header="t('badges')"
          :text="t('badges_text')"
          :button-text="t('manage')"
          @actionClick="$router.push({ name: 'badges' })"
        />

        <SubMenuTile
          v-if="account.accountType === 'business' || account.accountType === 'ecommerce'"
          :header="t('qr')"
          :text="
            account.accountType === 'business' || account.accountType === 'ecommerce'
              ? t('qr_text')
              : t('qr_text_personal')
          "
          :button-text="t('manage')"
          @actionClick="$router.push({ name: 'qr' })"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import LivePage from '@/components/shareables/LivePage.vue'
import SubMenuTile from '@/components/tools/SubMenuTile.vue'
import type { Account } from '@/store'
import type { User } from '@/store/users'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Shareables',
  components: {
    LivePage,
    SubMenuTile,
  },
  data() {
    return {
      progressItem: 0,
    }
  },
  methods: {
    t(key: string) {
      return this.$t(`Shareables.${key}`)
    },
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
  },
})
</script>

<style lang="scss" scoped>
.submenu {
  padding-top: 1px;
  animation-delay: 0.25s;
  animation: fadeInAnimation ease 0.99s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
