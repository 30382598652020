<template>
  <button
    :class="[
      'floating-button',
      { 'is-open': isChecklistOpen },
      { 'is-mobile': isMobile },
      `theme-${theme}`,
      { progress: hasChecklistBeenOpened && !isChecklistOpen },
    ]"
    :disabled="loading"
    @click="openChecklist()"
  >
    <v-progress-circular v-if="loading" id="loader" :size="26" indeterminate :color="color" />
    <template v-else>
      <span class="label-wrapper">
        <v-icon style="color: inherit" size="md">
          {{ buttonIcon }}
        </v-icon>
        <span v-if="!isChecklistOpen">Setup checklist</span>
      </span>

      <template v-if="hasChecklistBeenOpened && !isChecklistOpen">
        <div class="progress-wrapper">
          <span class="progress-percent">{{ getProgressPercent + '%' }}</span>
          <div class="progress-bar">
            <div class="progress-value" :style="`width: ${getProgressPercent}%`" />
          </div>
        </div>
      </template>
    </template>
  </button>
</template>

<script lang="ts">
import type { SetupChecklistItem } from '@/store/checklist'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

type Theme = 'green' | 'red' | 'yellow'

export default defineComponent({
  name: 'OnboardingChecklistButton',
  computed: {
    buttonIcon(): string {
      return this.isChecklistOpen ? 'mdi-close' : 'mdi-check-circle-outline'
    },
    color(): string {
      return this.isMobile ? 'grey' : this.themeColor
    },
    themeColor(): string {
      switch (this.theme) {
        case 'yellow':
          return 'green'
        case 'green':
          return 'beige'
        case 'red':
          return 'beige'
        default:
          return 'green'
      }
    },
    isChecklistOpen(): boolean {
      return this.$store.getters['getChecklistOpenState']
    },
    setupChecklist(): SetupChecklistItem[] {
      return this.$store.getters['getSetupChecklistItems']
    },
    hasChecklistBeenOpened(): boolean {
      return this.$store.getters['getHasChecklistBeenOpened']
    },
    loading(): boolean {
      return this.$store.getters['getChecklistLoading']
    },
    getProgressPercent(): () => number {
      return this.$store.getters['getProgressPercent']
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`OnboardingChecklistButton.${key}`)
    },
    openChecklist() {
      this.setOpenState(!this.isChecklistOpen)
      if (!this.hasChecklistBeenOpened) this.setHasChecklistBeenOpenedState(true)
    },
    setOpenState(isChecklistOpen: boolean): void {
      return this.$store.commit('setOpenState', isChecklistOpen)
    },
    setHasChecklistBeenOpenedState(hasChecklistBeenOpened: boolean): void {
      return this.$store.commit('setHasChecklistBeenOpenedState', hasChecklistBeenOpened)
    },
  },
  props: {
    isMobile: {
      type: Boolean,
    },
    theme: {
      default: 'yellow',
      type: String as PropType<Theme>,
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.floating-button {
  color: inherit;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 15px 0;
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  display: block;
  width: 100%;
}

.floating-button.progress {
  padding: 15px 0 0;
}

.floating-button:disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.label-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.progress-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 5px;
  margin-bottom: 15px;
}

.progress-bar {
  width: 100%;
  background-color: var(--gray-light);
  height: 10px;
  border-radius: 20px;
  overflow: hidden;
}

.progress-value {
  background-color: var(--ui-green);
  height: 100%;
}

.progress-percent {
  font-size: 12px;
  line-height: 16px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .floating-button {
    border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
    border: none;
    box-shadow: var(--box-shadow-wide);
    font-size: 24px;
    gap: 5px;
    top: 50%;
    bottom: 50%;
    padding: 15px;
    position: fixed;
    transform: rotate(-90deg) translate(50%, -100%);
    transform-origin: 100% 0;
    height: fit-content;
    right: 0;
    width: 222px;
    z-index: 6;
  }

  .floating-button.progress {
    padding: 15px;
  }

  .floating-button:disabled {
    background-color: var(--gray-light);
  }

  .floating-button.is-open {
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .floating-button.theme-green {
    background-color: var(--ui-green);
    color: var(--ui-beige);

    & .progress-value {
      background-color: var(--ui-beige);
    }
  }

  .floating-button.theme-yellow {
    background-color: var(--ui-yellow);
    color: var(--ui-green);

    & .progress-value {
      background-color: var(--ui-green);
    }
  }

  .floating-button.theme-red {
    background-color: var(--ui-red);
    color: var(--ui-beige);

    & .progress-value {
      background-color: var(--ui-beige);
    }
  }

  .label-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    line-height: 30px;
  }

  .progress-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 5px;
  }

  .progress-wrapper {
    margin-bottom: 0;
    margin-top: 5px;
  }

  .progress-bar {
    width: 100%;
    background-color: #ffffff50;
    height: 15px;
    border-radius: 20px;
    overflow: hidden;
  }

  .progress-value {
    height: 100%;
  }

  .progress-wrapper .progress-percent {
    font-size: 12px;
  }
}

@media #{map-get($display-breakpoints, 'md')} {
  .progress-wrapper {
    display: none;
  }
}
</style>
