import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_chip_group = _resolveComponent("v-chip-group")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_chip_group, {
      "selected-class": "selected-value",
      column: "",
      "model-value": _ctx.selectedValueIndex,
      "onUpdate:modelValue": _ctx.onValueChange
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (v) => {
          return (_openBlock(), _createBlock(_component_v_chip, {
            key: v.value,
            label: "",
            class: "value-chip"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(v.label), 1)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["model-value", "onUpdate:modelValue"])
  ]))
}