import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-97637f04"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title-wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "subheader" }
const _hoisted_4 = { class: "input-outer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "info-icon",
        src: require('@/assets/icons/partners/partner_info.svg'),
        alt: "info"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('info')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_v_text_field, {
        modelValue: _ctx.partnerCredential.firstName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.partnerCredential.firstName) = $event)),
        label: _ctx.t('first_name'),
        variant: "outlined",
        type: "text",
        flat: "",
        color: "#3B755F",
        disabled: _ctx.isEdit,
        rules: [_ctx.rules.required]
      }, null, 8, ["modelValue", "label", "disabled", "rules"]),
      _createVNode(_component_v_text_field, {
        modelValue: _ctx.partnerCredential.lastName,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.partnerCredential.lastName) = $event)),
        label: _ctx.t('last_name'),
        variant: "outlined",
        type: "text",
        flat: "",
        color: "#3B755F",
        disabled: _ctx.isEdit,
        rules: [_ctx.rules.required]
      }, null, 8, ["modelValue", "label", "disabled", "rules"]),
      _createVNode(_component_v_text_field, {
        modelValue: _ctx.partnerCredential.partnerName,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.partnerCredential.partnerName) = $event)),
        label: _ctx.t('partner_name'),
        variant: "outlined",
        type: "text",
        flat: "",
        color: "#3B755F",
        rules: [_ctx.rules.required]
      }, null, 8, ["modelValue", "label", "rules"]),
      _createVNode(_component_v_text_field, {
        modelValue: _ctx.partnerCredential.email,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.partnerCredential.email) = $event)),
        label: _ctx.t('email'),
        variant: "outlined",
        type: "text",
        flat: "",
        color: "#3B755F",
        disabled: _ctx.isEdit,
        rules: [_ctx.rules.email, _ctx.rules.required]
      }, null, 8, ["modelValue", "label", "disabled", "rules"])
    ])
  ]))
}