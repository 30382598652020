<template>
  <div class="receipt-wrapper">
    <div class="date">
      {{ name }}
    </div>
    <div class="download" @click="openUrl()">
      {{ t('download') }}
      <img class="download-icon" :src="require('@/assets/icons/download.svg')" alt="download" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProjectReceipt',
  components: {},
  methods: {
    t(key: string) {
      return this.$t(`ProjectReceipt.${key}`)
    },
    openUrl() {
      window.open(this.imageUrl, '_blank')
    },
  },
  props: {
    imageUrl: {
      type: String,
    },
    name: {
      type: String,
    },
  },
})
</script>

<style lang="scss" scoped>
.receipt-wrapper {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 18px;
  padding-bottom: 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
}

.download {
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}

.download-icon {
  filter: invert(7%) sepia(5%) saturate(6266%) hue-rotate(166deg) brightness(91%) contrast(83%);
  margin-left: 5px;
  max-width: 22px;
}
</style>
