<template>
  <section>
    <button class="back" @click="navigateBack">
      <img class="chevron" alt="chevron" :src="require('@/assets/icons/chevron_left.svg')" />
      <div>
        {{ $t('CommonUi.back') }}
      </div>
    </button>
    <panel>
      <div class="content-wrapper">
        <h1 class="page-title">
          {{ t('title') }}
        </h1>
        <p class="subtitle">
          {{ t('subtitle') }}
        </p>

        <h2 class="section-title">{{ t('manage_campaign.title') }}</h2>

        <v-expansion-panels
          v-model="selectedCampaignPlatform"
          class="platform-accordion"
          flat
          selected-class="active"
        >
          <v-expansion-panel value="email_platform">
            <v-expansion-panel-title>
              <h3>{{ t('manage_campaign.email_platform') }}</h3>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <SettingCard
                :button-text="
                  getActiveEmailSyncAmount > 0
                    ? `${$t('CommonUi.edit')}`
                    : `${$t('CommonUi.manage')}`
                "
                :is-active="getActiveEmailSyncAmount > 0"
                :active-amount="getActiveEmailSyncAmount"
                @button-click="openSyncDialog()"
              >
                <template v-slot:title>
                  {{ t('email_sync_title') }}
                </template>
                <template v-slot:desc>
                  {{ t('email_sync_text') }}
                </template>
              </SettingCard>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel value="greenspark">
            <v-expansion-panel-title>
              <h3>{{ t('manage_campaign.greenspark') }}</h3>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <SettingCard
                :button-text="
                  !!customDomainAmount ? `${$t('CommonUi.edit')}` : `${$t('CommonUi.manage')}`
                "
                :has-error="isCustomDomainInvalid"
                :is-required="!customDomainAmount"
                :is-active="!!customDomainAmount && !isCustomDomainInvalid"
                :active-amount="customDomainAmount"
                @button-click="openPopup('custom_domain')"
              >
                <template v-if="isCustomDomainInvalid" v-slot:alert>
                  <BaseAlert variant="error" :text="t('custom_domain_alert.not_valid')" />
                </template>
                <template v-slot:title>
                  {{ t('custom_domain_title') }}
                </template>
                <template v-slot:desc>
                  {{ t('custom_domain_text') }}
                </template>
              </SettingCard>

              <SettingCard
                :button-text="
                  !!activeEmailTemplateAmount
                    ? `${$t('CommonUi.edit')}`
                    : `${$t('CommonUi.manage')}`
                "
                :is-active="!!activeEmailTemplateAmount"
                :active-amount="activeEmailTemplateAmount"
                @button-click="openPopup('notification_template')"
              >
                <template v-slot:alert>
                  <BaseAlert
                    v-if="
                      !!activeEmailTemplateAmount &&
                      isTemplateDomainInvalid &&
                      !!customDomainAmount &&
                      isBeforeCustomDomainGracePeriodEnd
                    "
                    variant="error"
                    :text="
                      t('custom_domain_alert.update_domain_settings', {
                        date: getCustomDomainGraciePeriodDate,
                      })
                    "
                  />
                  <BaseAlert
                    v-if="
                      !!activeEmailTemplateAmount &&
                      !customDomainAmount &&
                      isBeforeCustomDomainGracePeriodEnd
                    "
                    variant="info"
                    :text="
                      t('custom_domain_alert.grace_period_warning', {
                        date: getCustomDomainGraciePeriodDate,
                      })
                    "
                  />
                  <BaseAlert
                    v-if="!customDomainAmount && !activeEmailTemplateAmount"
                    variant="info"
                    :text="t('custom_domain_alert.activate_email_campaigns')"
                  />
                </template>
                <template v-slot:title>
                  {{ t('notification_template_title') }}
                </template>
                <template v-slot:desc>
                  {{ t('notification_template_text') }}
                </template>
              </SettingCard>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>

        <h2 class="section-title">{{ t('impact_wallet') }}</h2>

        <SettingCard
          v-if="!account.imageURL"
          :button-text="`${t('upload_logo')}`"
          @button-click="uploadLogo"
        >
          <template v-slot:title>
            {{ t('upload_logo_title') }}
          </template>
          <template v-slot:desc>
            {{ t('upload_logo_text') }}
          </template>
        </SettingCard>

        <SettingCard
          :button-text="
            !!account.profileDescription ? `${$t('CommonUi.edit')}` : `${$t('CommonUi.add')}`
          "
          :is-active="!!account.profileDescription"
          @button-click="openPopup('intro')"
        >
          <template v-slot:title>
            {{ t('intro_title') }}
          </template>
          <template v-slot:desc>
            {{ t('set_intro_text') }}
          </template>
        </SettingCard>

        <SettingCard
          :button-text="
            !!sustainabilityGoals.length ? `${$t('CommonUi.edit')}` : `${t('set_targets')}`
          "
          :is-active="!!sustainabilityGoals.length"
          :active-amount="sustainabilityGoals.length"
          @button-click="openPopup('target_goal')"
        >
          <template v-slot:title>
            {{ t('target_goal_title') }}
          </template>
          <template v-slot:desc>
            {{ t('set_target_goal_text') }}
          </template>
        </SettingCard>

        <SettingCard>
          <template v-slot:title>
            {{ t('reengage_title') }}
          </template>
          <template v-slot:desc>
            {{ t('reengage_text') }}
          </template>
          <template v-slot:button>
            <p class="coming-soon">
              {{ t('coming_soon') }}
            </p>
          </template>
        </SettingCard>
      </div>

      <full-width-popup ref="popup" :popup-title="popupTitle">
        <IntroSettingDialog v-if="activeContent === 'intro'" />
        <TargetGoalSettingDialog v-if="activeContent === 'target_goal'" />
        <Certificate v-if="activeContent === 'certificate'" />
        <CustomDomain v-if="activeContent === 'custom_domain'" />
        <NotificationTemplates
          v-if="activeContent === 'notification_template'"
          @openCustomDomains="activeContent = 'custom_domain'"
        />
      </full-width-popup>

      <button
        v-if="getIfAtLeastOneAutomationActive"
        class="animated-link view-certificate"
        @click="openPopup('certificate')"
      >
        {{ t('certificate_button') }}
      </button>
    </panel>
    <call-to-upgrade-popup
      v-if="isCallToUpgradePopupOpen"
      :is-premium-only="false"
      @close="isCallToUpgradePopupOpen = false"
    />
    <loading-opaque v-if="isLoading" />
  </section>
</template>

<script lang="ts">
import SettingCard from '@/components/CustomerEngagementPortal/SettingCard.vue'
import FullWidthPopup from '@/components/common/FullWidthPopup.vue'
import type { Account } from '@/store'
import type { CustomIntegrationResponse } from '@api/index'
import { getCustomIntegrations, updateAccount } from '@api/index'
import IntroSettingDialog from '@/components/CustomerEngagementPortal/IntroSettingDialog.vue'
import TargetGoalSettingDialog from '@/components/CustomerEngagementPortal/TargetGoalSettingDialog.vue'
import Certificate from '@/components/CustomerEngagementPortal/Certificate.vue'
import type { TranslateResult } from 'vue-i18n'
import Panel from '@/components/common/Panel.vue'
import CallToUpgradePopup from '@/components/common/CallToUpgradePopup.vue'
import type { SustainabilityGoal } from '@/helpers/interfaces'
import CustomDomain from '@/components/CustomerEngagementPortal/EmailTemplates/CustomDomain.vue'
import NotificationTemplates from '@/components/CustomerEngagementPortal/EmailTemplates/NotificationTemplates.vue'
import LoadingOpaque from '@/components/tools/LoadingOpaque.vue'
import type { Notification } from '@/store/notification'
import { defineComponent } from 'vue'
import type { IntegrationTableData, IntegrationOption } from '@/store/integrations'
import type { SelectedIntegration } from '@/components/onboarding/SelectIntegration.vue'
import { isDemoEnvironment } from '@/helpers/constants'
import BaseAlert from '@/components/tools/BaseAlert.vue'
import type { Domain, EmailTemplateWithStatus } from '@/store/customer-engagement'
import { Utils } from '@/helpers/mixins/utilsMixin'

type CustomerEngagementAction =
  | 'intro'
  | 'target_goal'
  | 'certificate'
  | 'custom_domain'
  | 'notification_template'
type CampaignPlatform = 'email_platform' | 'greenspark'

export default defineComponent({
  name: 'CustomerEngagementPortal',
  mixins: [Utils],
  components: {
    LoadingOpaque,
    NotificationTemplates,
    CustomDomain,
    CallToUpgradePopup,
    Panel,
    TargetGoalSettingDialog,
    IntroSettingDialog,
    SettingCard,
    FullWidthPopup,
    Certificate,
    BaseAlert,
  },
  data() {
    return {
      isCallToUpgradePopupOpen: false,
      customIntegrations: [],
      popupTitle: '',
      activeContent: 'intro',
      isLoading: false,
      selectedCampaignPlatform: ['email_platform'],
    } as {
      isCallToUpgradePopupOpen: boolean
      customIntegrations: CustomIntegrationResponse[]
      popupTitle: string | TranslateResult
      activeContent: CustomerEngagementAction
      isLoading: boolean
      selectedCampaignPlatform: CampaignPlatform[]
    }
  },
  async created() {
    this.isLoading = true

    await this.setIntegrations()
    await this.setSustainabilityGoals()
    if (this.maxCustomDomainAmount) await this.setDomainList()
    await this.setEmailTemplateList()
    await this.setDefaultValues()
    await this.loadCustomIntegrations()

    if (this.getActiveEmailSyncAmount > 0) {
      this.selectedCampaignPlatform = ['email_platform']
    }

    if (this.getActiveEmailSyncAmount === 0) {
      this.selectedCampaignPlatform = ['greenspark']
    }

    this.isLoading = false
  },
  methods: {
    navigateBack() {
      return this.$router.push({
        name: 'Shareables',
      })
    },
    t(key: string, params?: { [key: string]: string }) {
      return this.$t(`CustomerEngagementPortal.${key}`, params ?? {})
    },
    openPopup(content: CustomerEngagementAction) {
      if (!this.impactWallet) {
        this.isCallToUpgradePopupOpen = true
      } else if (content === 'custom_domain' && !this.maxCustomDomainAmount) {
        this.isCallToUpgradePopupOpen = true
      } else {
        const popup = this.$refs.popup as HTMLElement & { isOpen: boolean }
        this.activeContent = content
        this.popupTitle = this.t(content + '_title')
        popup.isOpen = true
      }
    },
    async openSyncDialog() {
      if (this.getActiveEmailSyncAmount > 1) {
        return await this.$router.push({
          name: 'ManageIntegrations',
        })
      }

      if (this.getActiveEmailSyncAmount === 1) {
        this.setSelectedIntegrationForDataSync(this.syncedIntegrations[0])
        return await this.$router.push({
          name: 'EmailDataSync',
          query: { from: 'engagement' },
        })
      }

      if (this.getActiveEmailSyncAmount === 0) {
        return await this.$router.push({
          name: 'EmailIntegrationSyncAlert',
          query: { from: 'engagement' },
        })
      }
    },
    uploadLogo() {
      if (window.cloudinary) {
        window.cloudinary
          .createUploadWidget(
            {
              cloud_name: process.env.VUE_APP_CLOUDNAME,
              upload_preset: process.env.VUE_APP_UPLOAD_PRESET,
              folder: 'profilePics',
              sources: ['local', 'url', 'camera'],
              cropping: true,
              cropping_aspect_ratio: 1,
              croppingShowDimensions: true,
              showSkipCropButton: true,
              showPoweredBy: false,
              clientAllowedFormats: ['png', 'jpg', 'jpeg'],
            },
            (error, result) => {
              if (!error && result && result.event === 'success') {
                this.uploadImage(result.info.url)
                this.setAccount({ imageURL: result.info.url })
              }
            },
          )
          .open()
      }
    },
    async uploadImage(imageUrl: string) {
      await updateAccount({ userId: this.account.accountId, imageURL: imageUrl })
    },
    async loadCustomIntegrations() {
      try {
        const { data } = await getCustomIntegrations()
        this.customIntegrations = data
      } catch (e) {
        this.$store.dispatch('notification/notify', {
          text: e?.message,
          isError: true,
        } as Notification)
      }
    },
    setSustainabilityGoals() {
      return this.$store.dispatch('setSustainabilityGoals')
    },
    setDomainList() {
      return this.$store.dispatch('setDomainList')
    },
    setDefaultValues() {
      return this.$store.dispatch('setDefaultValues')
    },
    setEmailTemplateList() {
      return this.$store.dispatch('setEmailTemplateList')
    },
    setIntegrations(): Promise<void> {
      return this.$store.dispatch('setIntegrations')
    },
    setAccount(account: Partial<Account>): void {
      return this.$store.commit('setAccount', account)
    },
    setSelectedIntegrationForDataSync(
      integration: SelectedIntegration | IntegrationTableData | undefined,
    ) {
      return this.$store.commit('setSelectedIntegrationForDataSync', integration)
    },
  },
  computed: {
    account(): Account {
      return this.$store.getters['getAccount']
    },
    impactWallet(): boolean {
      return this.$store.getters['getImpactWalletFeatureSetting']
    },
    maxCustomEmailTemplateAmount(): number {
      return this.$store.getters['getMaxCustomEmailTemplateAmount']
    },
    maxCustomDomainAmount(): number {
      return this.$store.getters['getMaxCustomDomainAmount']
    },
    sustainabilityGoals(): SustainabilityGoal[] {
      return this.$store.getters['getSustainabilityGoals']
    },
    getIfAtLeastOneAutomationActive(): boolean {
      return this.$store.getters['getIfAtLeastOneAutomationActive']
    },
    customDomainAmount(): number {
      return this.$store.getters['getCustomDomainAmount']
    },
    emailTemplateList(): EmailTemplateWithStatus[] {
      return this.$store.getters['getCustomerEngagementEmailTemplateList']
    },
    activeEmailTemplateAmount(): number {
      return this.$store.getters['getActiveEmailTemplateAmount']
    },
    integrationList(): IntegrationTableData[] {
      return this.getIntegrationsForTable()
    },
    getIntegrationsForTable(): () => IntegrationTableData[] {
      return this.$store.getters['getIntegrationsForTable']
    },
    integrationOptionList(): IntegrationOption[] {
      return this.$store.getters['getIntegrationOptionList']
    },
    getSelectedIntegrationForDataSync(): SelectedIntegration {
      return this.$store.getters['getSelectedIntegrationForDataSync']
    },
    syncedIntegrations(): (IntegrationTableData | SelectedIntegration)[] {
      const integrations = isDemoEnvironment
        ? [this.getSelectedIntegrationForDataSync]
        : this.integrationList
      return integrations.filter(
        (integration) => integration.writeDirection?.status?.status === 'active',
      )
    },
    getActiveEmailSyncAmount(): number {
      return this.syncedIntegrations ? this.syncedIntegrations.length : 0
    },
    domainList(): Domain[] {
      return this.$store.getters['getSavedDomainList']
    },
    isCustomDomainInvalid(): boolean {
      return (
        this.domainList.length > 0 &&
        !this.domainList.every((domain) => domain?.validationStatus?.valid)
      )
    },
    isTemplateDomainInvalid(): boolean {
      return this.emailTemplateList
        .filter((template) => template?.status?.status === 'active')
        .some((template) => {
          const domainDetails = this.domainList.find(
            (domain) => domain.domain === template.settings.from.domain,
          )

          return !domainDetails?.validationStatus?.valid
        })
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.back {
  display: flex;
  font-weight: 500;
  color: var(--ui-green);
  cursor: pointer;
  margin-bottom: 7px;
  width: 70px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 3px;
  text-transform: capitalize;
}

.toggle {
  align-self: flex-end;
}

.chevron {
  width: 23px;
  filter: invert(39%) sepia(15%) saturate(1175%) hue-rotate(106deg) brightness(96%) contrast(87%);
}

.customer-engagement-portal {
  border-radius: var(--border-radius-big);
  box-shadow: var(--box-shadow-wide);
  padding: 20px;
}

.page-title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
}

.subtitle {
  font-size: 18px;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 40px;
}

.section-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 22px;
}

.coming-soon {
  font-weight: 600;
  font-size: 18px;
  color: var(--ui-green);
}

.view-certificate {
  display: none;
  margin: 0 auto;
}

.platform-accordion {
  gap: 22px;
  padding-bottom: 22px;
  margin-bottom: 22px;
  border-bottom: 1px solid var(--gray-light-CC);

  .v-expansion-panel {
    border-radius: var(--border-radius-big);
    border: 2px solid rgba(0, 0, 0, 0.12);

    &--active {
      .v-expansion-panel-title {
        color: var(--ui-black);
      }

      .checkmark {
        background-color: var(--ui-green);
        border: none;
      }

      .checkmark-icon {
        color: white;
      }
    }

    :deep(.v-expansion-panel-title__icon) {
      font-size: 25px;
    }
  }

  .v-expansion-panel--active:not(:first-child),
  .v-expansion-panel--active + .v-expansion-panel {
    margin-top: 0;
  }

  .v-expansion-panel-title {
    color: var(--gray-light-A9);

    h3 {
      font-size: 18px;
      font-weight: 700;
    }

    &:hover > :deep(.v-expansion-panel-title__overlay) {
      opacity: 0;
    }
  }
}

.checkmark-icon {
  color: transparent;
  font-size: 16px;
  line-height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 50%;
  right: 50%;
  transform: translate(-50%, calc(-50% + 1px));
}

.checkmark {
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  height: 20px;
  margin-right: 10px;
  position: relative;
  width: 20px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .toggle {
    align-self: auto;
  }

  .view-certificate {
    display: block;
  }

  .subtitle {
    width: 90%;
  }
}
</style>
