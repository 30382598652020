<template>
  <svg width="80" height="84" viewBox="0 0 80 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M60.178 17.8553C59.3206 17.809 58.6148 18.5566 58.1426 19.0569C57.0746 20.1891 56.8149 21.7067 56.9387 22.7786C56.9692 23.0408 56.7807 23.0056 57.1623 23.9052C57.7074 25.1912 57.7185 25.8694 57.434 26.2289C56.8463 26.9728 55.3514 26.1853 53.7216 27.1757C52.7487 27.7668 52.3126 28.6915 52.1611 29.012C51.2843 30.8715 52.4392 31.836 51.955 33.5639C51.2362 36.1312 48.1244 36.007 47.8149 38.1778C47.5802 39.8232 49.2257 40.5774 48.9698 42.3182C48.8478 43.1484 48.2334 44.0536 46.6267 45.0375C46.0326 46.4003 48.2242 47.4769 48.1438 49.2493C48.0727 50.8021 46.3218 51.4756 45.2907 52.7644C45.1466 52.9849 44.9553 53.3314 44.8426 53.79C44.7567 54.1356 44.7299 54.4895 44.7585 54.8768C44.8537 56.1377 45.518 57.3857 45.6954 58.0889C45.9088 58.9348 45.7379 60.2643 43.8891 62.3257C43.7754 62.5184 43.6258 62.8464 43.6572 63.2429C43.6618 63.3059 43.6627 63.4662 43.7523 63.6487C44.1302 64.4112 45.2999 65.1284 45.8506 66.1632C45.9892 66.406 46.0899 66.6691 46.1278 66.9628C46.1398 67.0527 46.1435 67.1388 46.1426 67.2222V67.2361C46.1749 68.7333 44.6827 69.0548 44.7271 70.7771C44.7622 72.1243 45.9014 72.4745 45.7693 73.8003C45.5845 75.6551 42.6529 76.6733 42.6833 76.7289C42.7185 76.7928 46.7893 75.7829 49.8928 72.0409C52.3061 69.1308 52.9205 66.0335 53.109 64.641C52.9926 60.823 58.5528 60.3551 55.6508 56.0117C52.1906 50.601 59.583 52.0667 56.8842 47.5881C53.0102 44.4909 59.5294 44.6233 57.3517 41.0193C56.9665 40.381 57.1023 39.9288 57.1087 39.7732C57.1642 38.5669 59.073 38.4604 60.1919 37.1271C61.6388 35.4039 60.0505 33.6723 61.2775 30.3647C61.8753 28.7536 62.4759 28.5608 62.9674 27.7789C62.9674 27.7789 63.1956 27.3953 63.3721 26.8746C63.9265 25.2449 62.7715 17.9952 60.179 17.8544L60.178 17.8553Z"
      fill="#3B755F"
      stroke="#1E6562"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M40.7725 66.5246C40.8492 66.6515 40.9795 66.8627 41.1375 67.15C41.568 67.931 42.8625 70.3556 42.8717 73.3713C42.8782 75.4689 42.2591 76.5019 42.6851 76.7307C43.1544 76.9828 44.2936 75.9367 44.8942 75.0038C45.1316 74.636 45.443 74.2116 45.4864 73.6002C45.5631 72.5162 44.7491 72.2002 44.6974 70.8596C44.6272 69.0215 46.1165 68.5397 46.1433 67.238V67.2241C46.1323 66.834 46.0214 66.4847 45.8514 66.1651C45.2933 65.1886 44.1033 64.5456 43.7531 63.6506C43.7042 63.5265 43.6367 63.3569 43.633 63.141C43.6256 62.6741 43.901 62.3257 44.1125 62.0506C44.6817 61.3112 45.1058 60.7007 45.1603 60.621C45.4716 60.1652 45.7812 59.4277 45.7156 58.2131C45.6481 58.0833 45.5317 57.8573 45.3987 57.5719C44.8785 56.4546 44.4738 55.5624 44.5616 54.5405C44.5921 54.1875 44.6724 53.9234 44.7011 53.8345C44.8323 53.4278 45.0402 53.0794 45.2906 52.7672C46.0639 51.1486 46.8363 49.6708 44.1347 47.7354C37.5748 42.9807 49.0288 45.0078 43.6071 39.649C40.6505 37.4959 41.5634 36.1729 43.1156 35.3103C48.1252 33.1173 44.9607 31.9601 44.9607 31.9601C41.1615 30.273 44.1818 27.513 44.1809 27.513C49.2431 21.7363 43.2293 24.8049 44.1698 21.8966C44.1698 21.8966 46.5305 17.7145 43.8335 17.9563C39.4328 18.1156 44.7205 11.6747 38.5846 10.0015C36.6915 10.0015 36.7867 11.52 37.195 12.7615C37.9111 14.2096 35.0783 16.2154 37.4463 16.6546C40.5498 17.6311 38.5246 19.2821 38.5246 19.2821C36.0244 20.5875 39.0678 21.5529 39.4005 22.9936C39.7007 23.6514 40.0417 24.6798 39.6398 25.5859C38.6881 27.7298 34.5674 26.9451 33.88 28.7758C33.2766 30.3832 36.1168 31.8906 35.5514 33.9345C35.058 35.717 32.6262 35.5493 32.2714 37.2272C31.8667 39.1376 34.7983 40.3912 34.3299 42.4526C34.025 43.7978 32.6234 43.9415 32.5985 45.2293C32.5901 45.6323 32.3185 46.3772 34.2948 48.0022C35.3675 48.8843 36.1159 50.11 36.3894 50.8716C36.6739 51.6619 36.8282 53.1489 35.1578 55.6717C35.5384 56.0516 36.102 56.6853 36.5714 57.584C37.5803 59.5129 37.147 60.7248 37.7512 62.5203C38.4035 64.4585 39.2258 63.9841 40.7707 66.5292L40.7725 66.5246Z"
      fill="#3B755F"
      stroke="#1E6562"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M42.6853 76.7298C42.8377 75.4837 42.8923 74.3691 42.8719 73.3704C42.8137 71.5619 42.4682 70.1258 41.9656 68.8862C41.9637 68.8816 41.9628 68.8778 41.9628 68.8778C41.7457 68.2923 41.3798 67.4455 40.7728 66.5236C39.6206 64.7726 38.9147 64.7476 38.1691 63.4292C37.2951 61.8838 38.1054 61.6355 37.1555 59.1414C36.5956 57.672 36.1873 56.5973 35.1598 55.6661C34.6646 55.2168 34.2202 54.9768 33.8553 54.2885C33.3951 53.4194 33.7194 53.0766 33.336 52.2502C33.0339 51.5998 32.7022 51.5303 31.222 50.2545C30.1623 49.341 29.6319 48.8833 29.4804 48.5943C28.7117 47.1267 30.0329 46.1317 29.5137 44.6057C28.8734 42.7231 26.4675 43.0715 26.0462 41.3788C25.6627 39.838 27.4339 38.6614 26.7382 37.2198C26.4933 36.713 26.2365 36.7102 25.7126 35.9616C25.3929 35.5049 25.0779 34.8248 24.8755 33.6315C25.2211 31.7424 28.1989 30.6723 27.0338 28.244C25.5703 24.3388 28.8272 24.11 27.5725 20.7987C27.2759 18.9384 26.0425 12.2945 23.558 12.263C20.436 12.7531 26.4841 14.1114 23.6171 21.9281C23.6134 22.0708 23.5746 22.8592 23.2235 23.0408C19.0917 22.0995 22.0446 27.0618 20.7954 28.1161C20.4619 28.3978 20.1745 28.0411 19.5186 28.219C18.3941 28.5247 17.5302 29.9015 17.3639 31.104C17.047 33.3925 19.3947 34.0549 19.2257 36.3118C19.0778 38.2917 17.1237 38.9755 17.5811 40.3217C17.9811 41.4992 19.4206 41.3121 19.9297 42.5832C20.5109 44.0341 18.9088 45.1505 19.2303 46.7218C19.44 47.7475 20.4369 48.862 23.3242 50.1554C24.5133 52.2066 20.3427 56.0062 26.6255 55.7338C27.3064 55.8088 28.417 56.0349 29.2014 56.8808C31.0058 58.8273 28.7708 61.5614 30.2113 64.1815C31.8087 67.0888 35.8408 66.0548 37.1565 69.2234C37.7164 70.5715 37.0493 70.9087 37.8134 72.6811C37.8346 72.7292 37.8633 72.7969 37.9021 72.8803C39.1919 75.6773 41.6376 76.4917 41.6376 76.4917C41.8621 76.5677 42.2206 76.6715 42.6853 76.7298Z"
      fill="#3B755F"
      stroke="#1E6562"
      stroke-width="2"
      stroke-miterlimit="10"
    />
  </svg>
</template>
