<template>
  <div>
    <header class="header">
      <section>
        <h2>{{ t('title') }}</h2>
        <p>{{ t('description') }}</p>
      </section>
      <gs-button class="invite-btn" type="primary" size="large" @click="onInviteUserClick">
        {{ t('invite_user') }}
      </gs-button>
    </header>
    <LoaderBlock v-if="loading" background-color="transparent" />
    <v-table v-else fixed-header :height="tableHeight" class="table">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ t('name') }}
            </th>
            <th class="text-left">
              {{ t('email') }}
            </th>
            <th class="text-left hidden">
              {{ t('role') }}
            </th>
            <th class="text-left">
              {{ t('status') }}
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="teamMember in sortedTeamMembers" :key="teamMember.email">
            <td class="text-body-1 text-no-wrap">
              <span>{{ teamMember.fullName }}</span>
              <span v-if="currentUser.id === teamMember.id" class="current-user-indicator">{{
                t('you')
              }}</span>
            </td>
            <td>{{ teamMember.email }}</td>
            <td class="hidden">
              <span>{{ t(teamMember.role.toLowerCase()) }}</span>
            </td>
            <td>
              <span :class="['status', teamMember.status.toLowerCase()]">
                {{ t(teamMember.status.toLowerCase()) }}
              </span>
            </td>
            <td class="text-body-1 pr-0 text-no-wrap d-flex flex-row-reverse align-center">
              <QuickMenu
                v-if="isUserInvite(teamMember) && !isCurrentUser(teamMember.id)"
                class="pr-2"
                style="height: 25px"
                :quick-menu-items="getMenuItems(teamMember.status)"
                @quickMenuClick="(itemName) => onMenuItemClick(itemName, teamMember)"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-table>
    <call-to-upgrade-popup
      v-if="isCallToUpgradePopupOpen"
      popup-type="team"
      :is-premium-only="false"
      @close="isCallToUpgradePopupOpen = false"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import QuickMenu from '@/components/tools/Table/QuickMenu.vue'
import type { TeamMember, TeamMemberStatus, User } from '@/store/users'
import { UserInvite } from '@/store/users'
import LoaderBlock from '@/components/tools/LoaderBlock.vue'
import type { DialogUpdatePayload } from '@/store/dialog'
import { Dialogs } from '@/store/dialog'
import CallToUpgradePopup from '@/components/common/CallToUpgradePopup.vue'

export default defineComponent({
  name: 'Users',
  components: {
    CallToUpgradePopup,
    LoaderBlock,
    QuickMenu,
  },
  data() {
    return {
      tableHeadHeight: 56,
      tableRowHeight: 48,
      maxRowNumber: 10,
      isCallToUpgradePopupOpen: false,
    }
  },
  computed: {
    tableHeight(): string {
      const maxTableBodyHeight = this.maxRowNumber * this.tableRowHeight
      const actualTableBodyHeight = this.teamMembers.length * this.tableRowHeight
      return `${this.tableHeadHeight + Math.min(maxTableBodyHeight, actualTableBodyHeight)}px`
    },
    sortedTeamMembers(): Array<TeamMember> {
      return [...this.teamMembers].sort(
        (member1, member2) =>
          new Date(member2.createdAt).getTime() - new Date(member1.createdAt).getTime(),
      )
    },
    currentUser(): User {
      return this.$store.getters['getCurrentUser']
    },
    loading(): boolean {
      return this.$store.getters['getLoading']
    },
    maxTeamMemberCount(): number {
      return this.$store.getters['getMaxTeamMemberCount']
    },
    teamMembers(): Array<TeamMember> {
      return this.$store.getters['getTeamMembers']
    },
  },
  mounted() {
    this.loadUsersData()
  },
  methods: {
    t(key: string, params?: { [key: string]: string | number }) {
      return this.$t(`Users.${key}`, params ?? {})
    },
    isUserInvite(teamMember: TeamMember) {
      return teamMember instanceof UserInvite
    },
    isCurrentUser(teamMemberId: string) {
      return teamMemberId === this.currentUser.id
    },
    getMenuItems(status: TeamMemberStatus): Array<{ value: string; label: string }> {
      return [
        {
          value: 'delete',
          label: this.t(status === 'pending' ? 'delete_invitation' : 'delete').toString(),
        },
      ]
    },
    onMenuItemClick(menuItemName: string, teamMember: TeamMember) {
      if (menuItemName === 'delete') {
        this.openDeleteDialog(teamMember)
      } else {
        throw new Error(`${menuItemName} click is not implemented`)
      }
    },
    openCannotDeleteLastTeamMemberDialog() {
      this.openDialog({
        name: Dialogs.TEMPLATE.SIMPLE,
        texts: {
          title: this.t('delete_dialog_title'),
          description: this.t('cannot_delete_last_member_description'),
          primaryButtonText: this.t('understood'),
        },
        options: {
          titleColor: 'red',
          persistent: true,
        },
      })
    },
    openDeleteDialog(teamMember: TeamMember) {
      if (teamMember instanceof UserInvite) {
        this.openDeleteUserInviteDialog(teamMember)
      } else {
        throw new Error('User deletion is not implemented')
      }
    },
    openDeleteUserInviteDialog(teamMemberInvite: TeamMember) {
      this.openDialog({
        name: Dialogs.TEMPLATE.DELETE,
        texts: {
          title: this.t('delete_dialog_title'),
          description: this.t('delete_invitation_description', {
            userEmail: teamMemberInvite.email,
          }),
          primaryButtonText: this.t('delete_invitation'),
          secondaryButtonText: this.t('cancel'),
          deleteCheckboxLabel: this.t('delete_dialog_checkbox_text'),
        },
        options: {
          persistent: true,
        },
        actions: {
          onPrimaryButtonClick: async () => await this.deleteUserInvite(teamMemberInvite.id),
        },
      })
    },
    onInviteUserClick() {
      if (this.maxTeamMemberCount === this.teamMembers.length) {
        this.isCallToUpgradePopupOpen = true
      } else {
        this.openDialog({
          name: Dialogs.CUSTOM.INVITE_USER,
          options: {
            persistent: true,
          },
        })
      }
    },
    loadUsersData(): Promise<void> {
      return this.$store.dispatch('loadUsersData')
    },
    deleteUserInvite(inviteId: string): Promise<Promise<void>> {
      return this.$store.dispatch('deleteUserInvite', inviteId)
    },
    openDialog(dialog: DialogUpdatePayload): Promise<void> {
      return this.$store.dispatch('openDialog', dialog)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: wrap;
  margin-bottom: 16px;
}

.invite-btn {
  margin: 0;
  width: 100%;
  flex-shrink: 0;
}

.table {
  border: 1px solid var(--gray-light);
  border-radius: 10px;
  overflow: hidden;
  margin-top: 6px;
}

.table th {
  padding: 16px 16px !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: var(--ui-black) !important;
}

.table td {
  font-size: 16px !important;
  color: var(--ui-black) !important;
}

.table tr {
  border: 1px solid var(--gray-light) !important;
}

.table tr:hover {
  background: none !important;
}

.current-user-indicator {
  border: 2px solid var(--gray);
  color: var(--gray);
  padding: 2px 7px;
  border-radius: 5px;
  margin-left: 6px;
}

.status {
  border-radius: 9px;
  padding: 4px 9px;
}

.status.pending {
  background-color: var(--ui-beige);
  color: var(--font-color-primary);
}

.status.accepted {
  background-color: var(--ui-green);
  color: var(--ui-white);
}

.hidden {
  display: none;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .header {
    flex-flow: nowrap;
  }

  .invite-btn {
    width: 227px;
    margin-left: 40px;
    flex-shrink: 0;
  }
}
</style>
