<template>
  <div class="schedule-picker-wrapper">
    <ScheduleInput
      v-for="(item, index) in selectedValues"
      :key="index"
      v-model="item.value"
      :index-of-input="index"
      :disabled-range="disabledRange"
      :current-period-settings="selectedValues.length"
      class="input-wrapper"
      @increment="handleIncrement"
      @decrement="handleDecrement"
    />
  </div>
</template>

<script lang="ts">
import ScheduleInput from '@/components/form/ScheduleInput.vue'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

type ScheduleSetting = { value: undefined | number }

export default defineComponent({
  name: 'SchedulePicker',
  emits: ['update:model-value'],
  components: { ScheduleInput },
  data() {
    const selectedValues: ScheduleSetting[] = [{ value: undefined }]

    return {
      disabledRange: 0,
      selectedValues,
    }
  },
  created() {
    this.selectedValues = this.modelValue
      ? this.modelValue.map((number) => {
          return { value: number }
        })
      : []
  },
  methods: {
    t(key: string) {
      return this.$t(`SchedulePicker.${key}`)
    },
    handleIncrement() {
      this.selectedValues.push({ value: undefined })
    },
    handleDecrement(index: number) {
      this.selectedValues.splice(index, 1)
    },
    onSelectedValueChange() {
      this.disabledRange = Math.max(
        ...this.selectedValues.map((item) => (item.value ? item.value : 0)),
      )
      this.$emit(
        'update:model-value',
        this.selectedValues.map((item) => item.value),
      )
    },
  },
  props: {
    modelValue: {
      type: Array as PropType<number[]>,
    },
  },
  watch: {
    selectedValues: [
      {
        deep: true,
        handler: 'onSelectedValueChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped></style>
