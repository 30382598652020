<template>
  <v-menu
    ref="dayMenu"
    v-model="dayMenu"
    :close-on-content-click="true"
    v-model:return-value="selectedDay"
    transition="scale-transition"
    min-width="auto"
    @update:model-value="handleInput"
  >
    <template v-slot:activator="{ props }">
      <div class="schedule-input-wrapper">
        <v-text-field
          v-model.number="selectedDay"
          color="green"
          hide-details
          prepend-inner-icon="mdi-calendar-range-outline"
          flat
          variant="outlined"
          type="number"
          v-bind="props"
          @change="handleInput"
        />
        <gs-button
          v-if="
            currentPeriodSettings &&
            indexOfInput &&
            !(currentPeriodSettings - 2 > indexOfInput) &&
            !(currentPeriodSettings === 4 && indexOfInput === 2)
          "
          size="large"
          :icon="iconName"
          type="secondary"
          :disabled="!selectedDay && maxSelectablePeriods - 1 !== indexOfInput"
          @click.prevent="handleClick()"
        />
      </div>
    </template>
    <div class="numbers">
      <p
        v-for="(day, index) in maxDays"
        :key="index"
        :class="[
          'number',
          { selected: day === selectedDay },
          { disabled: disabledRange && day <= disabledRange && indexOfInput },
        ]"
        @click="
          () =>
            disabledRange && day <= disabledRange && indexOfInput
              ? undefined
              : $emit('update:model-value', day)
        "
      >
        {{ day }}
      </p>
    </div>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ScheduleInput',
  emits: ['update:model-value', 'increment', 'decrement'],
  data() {
    return {
      maxSelectablePeriods: 4,
      selectedDay: this.modelValue,
      maxDays: 30,
      dayMenu: false,
    }
  },
  computed: {
    iconName() {
      if (
        this.currentPeriodSettings &&
        this.indexOfInput === this.currentPeriodSettings - 1 &&
        this.indexOfInput < this.maxSelectablePeriods - 1
      ) {
        return 'mdi-bell-plus-outline'
      } else {
        return 'mdi-minus'
      }
    },
  },
  methods: {
    t(key: string) {
      return this.$t(`ScheduleInput.${key}`)
    },
    handleInput() {
      this.selectedDay && this.selectedDay > 30
        ? this.$emit('update:model-value', 30)
        : this.$emit('update:model-value', this.selectedDay)
    },
    handleClick() {
      if (
        this.currentPeriodSettings &&
        this.indexOfInput === this.currentPeriodSettings - 1 &&
        this.indexOfInput < this.maxSelectablePeriods - 1
      ) {
        this.$emit('increment', this.indexOfInput)
      } else {
        this.$emit('decrement', this.indexOfInput)
      }
    },
    onValueChange() {
      this.selectedDay = this.modelValue
    },
  },
  props: {
    indexOfInput: {
      type: Number,
    },
    disabledRange: {
      type: Number,
    },
    currentPeriodSettings: {
      type: Number,
    },
    modelValue: {
      type: Number,
    },
  },
  watch: {
    modelValue: [
      {
        handler: 'onValueChange',
      },
    ],
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.schedule-input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.schedule-input-wrapper:not(:last-child) {
  margin-bottom: 20px;
}

.numbers {
  display: grid;
  background-color: white;
  grid-template-columns: repeat(7, 1fr);
  padding: 20px;
  gap: 5px;
}

.number {
  margin: 0;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
}

.number:hover {
  background-color: var(--ui-green-light-20);
  color: var(--ui-green);
}

.number.disabled {
  background-color: var(--gray-light-20);
  cursor: not-allowed;
}

.number.selected {
  border: 2px solid var(--ui-green);
  padding: 8px;
  color: var(--ui-green);
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .number {
    height: 40px;
    width: 40px;
  }
}
</style>
