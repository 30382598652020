import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6439fe66"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "checklist-overlay"
}
const _hoisted_2 = { class: "checklist-wrapper" }
const _hoisted_3 = { class: "checklist-title" }
const _hoisted_4 = { class: "progress-wrapper" }
const _hoisted_5 = { class: "progress-percent" }
const _hoisted_6 = { class: "progress-bar" }
const _hoisted_7 = { class: "checklist-list" }
const _hoisted_8 = ["disabled", "onClick"]
const _hoisted_9 = ["disabled", "onClick"]
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_ctx.isChecklistOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isMobileLarge || _ctx.isMobile)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "close-checklist-button",
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["prevent"]))
              }, [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-chevron-left")
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.t('title')), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getProgressPercent + '%'), 1),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", {
                class: "progress-value",
                style: _normalizeStyle(`width: ${_ctx.getProgressPercent}%`)
              }, null, 4)
            ])
          ]),
          _createElementVNode("ul", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.setupChecklist, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "checklist-list-item"
              }, [
                _createElementVNode("button", {
                  class: _normalizeClass(['checklist-list-item__button', { 'is-ready': item.value }]),
                  disabled: item.value,
                  onClick: _withModifiers(($event: any) => (_ctx.navigate(item.type)), ["prevent"])
                }, [
                  _createVNode(_component_v_icon, { class: "checklist-list-item__icon material-icons-outlined" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getIconNane(item.type)), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createElementVNode("span", null, _toDisplayString(_ctx.t(_ctx.snakeCase(item.type))), 1),
                  _createElementVNode("button", {
                    class: "status-button",
                    disabled: item.value,
                    onClick: _withModifiers(($event: any) => (_ctx.updateStatus(item.type)), ["prevent","stop"])
                  }, [
                    _createVNode(_component_v_icon, {
                      class: "status-icon",
                      variant: "plain"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.value ? 'mdi-check-circle' : 'mdi-close'), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ], 8, _hoisted_9)
                ], 10, _hoisted_8)
              ]))
            }), 128))
          ]),
          (_ctx.isFreeSubscription)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "free-business-subscription",
                onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openPriceComparisonPopup && _ctx.openPriceComparisonPopup(...args)), ["prevent"]))
              }, [
                _createElementVNode("span", {
                  innerHTML: _ctx.t('free_business_subscription')
                }, null, 8, _hoisted_10)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("button", {
            class: "dismiss-button",
            onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dismiss && _ctx.dismiss(...args)), ["prevent"]))
          }, _toDisplayString(_ctx.t('dismiss')), 1)
        ])), [
          [_directive_click_outside, _ctx.close]
        ])
      ]))
    : _createCommentVNode("", true)
}