import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, TransitionGroup as _TransitionGroup, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4fa60be"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "outer-network-wrapper"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "text-button-wrapper" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = {
  key: 0,
  class: "button-wrapper"
}
const _hoisted_6 = { class: "button-text" }
const _hoisted_7 = {
  key: 1,
  class: "outer-network-wrapper",
  style: {"margin-top":"24px","min-height":"unset"}
}
const _hoisted_8 = {
  key: 2,
  class: "outer-network-wrapper",
  style: {"margin-top":"24px","min-height":"unset"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_CustomMenu = _resolveComponent("CustomMenu")!
  const _component_ReferralGrid = _resolveComponent("ReferralGrid")!
  const _component_PartnerGrid = _resolveComponent("PartnerGrid")!
  const _component_ReferralImpact = _resolveComponent("ReferralImpact")!
  const _component_PartnerImpact = _resolveComponent("PartnerImpact")!
  const _component_InvitePopUp = _resolveComponent("InvitePopUp")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_comparison_dialog = _resolveComponent("comparison-dialog")!
  const _component_call_to_upgrade_popup = _resolveComponent("call-to-upgrade-popup")!
  const _component_AddCardPopUp = _resolveComponent("AddCardPopUp")!
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('header')), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t('text')), 1),
            (
            !_ctx.hideButton &&
            ((_ctx.activeMenuItem === 'referral' && !_ctx.referralEmpty) ||
              (_ctx.activeMenuItem === 'partner' && !_ctx.partnerEmpty))
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_v_btn, {
                    class: "button",
                    color: "#3B755F",
                    height: "54",
                    width: "100%",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogClick()))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.t('invite')), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_CustomMenu, {
            "menu-items": _ctx.menuItems,
            onMenuClick: _ctx.menuClick
          }, null, 8, ["menu-items", "onMenuClick"]),
          _createVNode(_TransitionGroup, {
            name: "fade",
            tag: "div",
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              (_ctx.activeMenuItem === 'referral')
                ? (_openBlock(), _createBlock(_component_ReferralGrid, {
                    key: "0",
                    "window-width": _ctx.windowWidth,
                    "get-fresh-data": _ctx.getFreshData,
                    onDialogClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialog = false)),
                    onShowErrorAlert: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showErrorAlert())),
                    onShowSuccessAlert: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showSuccessAlert())),
                    onGetFreshDataClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.getFreshDataClick())),
                    onEmptyClick: _ctx.emptyClick,
                    onEmptyButtonClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.emptyDialogClick('referral'))),
                    onHideButtonClick: _ctx.hideButtonClick,
                    onSetCardDialogClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setCardDialogClick()))
                  }, null, 8, ["window-width", "get-fresh-data", "onEmptyClick", "onHideButtonClick"]))
                : _createCommentVNode("", true),
              (_ctx.activeMenuItem === 'partner')
                ? (_openBlock(), _createBlock(_component_PartnerGrid, {
                    key: "1",
                    "get-fresh-data": _ctx.getFreshData,
                    onDialogClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.dialog = false)),
                    onShowErrorAlert: _cache[8] || (_cache[8] = ($event: any) => (_ctx.showErrorAlert())),
                    onShowSuccessAlert: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showSuccessAlert())),
                    onGetFreshDataClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.getFreshDataClick())),
                    onEmptyClick: _ctx.emptyClick,
                    onEmptyButtonClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.emptyDialogClick('partner'))),
                    onHideButtonClick: _ctx.hideButtonClick,
                    onSetCardDialogClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.setCardDialogClick()))
                  }, null, 8, ["get-fresh-data", "onEmptyClick", "onHideButtonClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeMenuItem === 'referral' && !_ctx.referralEmpty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_ReferralImpact, {
            "get-fresh-data": _ctx.getFreshData,
            onGetFreshDataClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.getFreshDataClick()))
          }, null, 8, ["get-fresh-data"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.activeMenuItem === 'partner' && !_ctx.partnerEmpty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_PartnerImpact, {
            "get-fresh-data": _ctx.getFreshData,
            onGetFreshDataClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.getFreshDataClick()))
          }, null, 8, ["get-fresh-data"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.dialog,
      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.dialog) = $event)),
      width: "600",
      persistent: "",
      fullscreen: _ctx.isMobile,
      scrollable: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_InvitePopUp, {
          "progress-item": _ctx.dialogProgressItem,
          onDialogClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.dialogClick())),
          onGetFreshDataClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.getFreshDataClick())),
          onDialogProgressItemClick: _ctx.dialogProgressItemClick,
          onShowErrorAlert: _cache[17] || (_cache[17] = ($event: any) => (_ctx.showErrorAlert())),
          onShowSuccessAlert: _cache[18] || (_cache[18] = ($event: any) => (_ctx.showSuccessAlert()))
        }, null, 8, ["progress-item", "onDialogProgressItemClick"])
      ]),
      _: 1
    }, 8, ["modelValue", "fullscreen"]),
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.isComparisonPopupOpen,
      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.isComparisonPopupOpen) = $event)),
      fullscreen: "",
      "scroll-strategy": "none"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_comparison_dialog, {
          onClose: _cache[20] || (_cache[20] = ($event: any) => (_ctx.isComparisonPopupOpen = false))
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.isCallToUpgradePopupOpen)
      ? (_openBlock(), _createBlock(_component_call_to_upgrade_popup, {
          key: 3,
          onClose: _cache[22] || (_cache[22] = ($event: any) => (_ctx.isCallToUpgradePopupOpen = false)),
          "is-premium-only": false
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_AddCardPopUp, {
      dialog: _ctx.isAddCardPopupOpen,
      type: 'partner',
      "from-page": 'partner',
      "has-subscription": _ctx.hasSubscription,
      onCloseDialogClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.isAddCardPopupOpen = false))
    }, null, 8, ["dialog", "has-subscription"]),
    _createVNode(_component_Alert, {
      alert: _ctx.showAlert,
      error: _ctx.isErrorAlert,
      "show-buttons": _ctx.isErrorAlert,
      text: _ctx.alertText,
      onCloseAlertClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.showAlert = false))
    }, null, 8, ["alert", "error", "show-buttons", "text"])
  ]))
}