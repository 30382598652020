import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c507aade"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "public-map-container" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "map-container" }
const _hoisted_4 = { class: "carousel-container" }
const _hoisted_5 = { class: "carousel-item-wrapper" }
const _hoisted_6 = { class: "markers" }
const _hoisted_7 = { class: "about-our-projects" }
const _hoisted_8 = {
  class: "projects-link",
  href: "https://www.getgreenspark.com/projects",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_ProjectDetailsCard = _resolveComponent("ProjectDetailsCard")!
  const _component_v_carousel_item = _resolveComponent("v-carousel-item")!
  const _component_v_carousel = _resolveComponent("v-carousel")!
  const _component_PublicMapPin = _resolveComponent("PublicMapPin")!
  const _component_PublicMapIcon = _resolveComponent("PublicMapIcon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.t('title')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_v_icon, {
          class: "chevron carousel-chevron left",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onCarouselChevronClick('left')), ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" mdi-chevron-left ")
          ]),
          _: 1
        }),
        (_ctx.projects.length)
          ? (_openBlock(), _createBlock(_component_v_carousel, {
              key: 0,
              id: "public-map-carousel",
              ref: "carousel",
              modelValue: _ctx.currentSlide,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentSlide) = $event)),
              "hide-delimiters": "",
              "show-arrows": false,
              height: _ctx.carouselHeight
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
                  return (_openBlock(), _createBlock(_component_v_carousel_item, {
                    key: project.projectId
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_ProjectDetailsCard, {
                          class: "carousel-item",
                          project: project
                        }, null, 8, ["project"])
                      ])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "height"]))
          : _createCommentVNode("", true),
        _createVNode(_component_v_icon, {
          class: "chevron carousel-chevron right",
          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onCarouselChevronClick('right')), ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" mdi-chevron-right ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (_, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(['marker-dot', { visible: _ctx.currentSlide === index }])
          }, null, 2))
        }), 128))
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mapPins, (pin, index) => {
        return (_openBlock(), _createBlock(_component_PublicMapPin, {
          class: "map-pin",
          key: index,
          pin: pin,
          "is-active": _ctx.currentSlide === pin.index,
          onClick: () => _ctx.handlePinClick(pin.index)
        }, null, 8, ["pin", "is-active", "onClick"]))
      }), 128)),
      _createVNode(_component_PublicMapIcon, {
        class: "global-map",
        id: "public-map"
      })
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.t('about_our_projects')), 1)
    ])
  ]))
}